import ApiService from '@/services/api.service'

const ReportByCategoriesService = {
    getAll( parameter) { 
         
      const requestData = {
        method: 'get',
        url: "/ReportByCategories",
        headers: {
        },
        params: { 
          SearchCriteria: parameter.SearchCriteria,
          PageSize: parameter.PageSize,          
          PageNumber : parameter.PageNumber,
          CategoryCode : parameter.CategoryCode,
        }
      }     
      return ApiService.customRequest(requestData);         
    },
  
}
  
export default ReportByCategoriesService;