<template>
  <div>
      <b-row>
        <b-col md="12">
            <!--
              NOTE:
                Properties SMALL and STICKY-HEADER
                must be passed as parameter on
                Clinical Notes' popup windows.
            // -->           
            <b-table
                :ref="refTable"
                selectable
                
                :small="small"
                :sticky-header="stickyHeader"
                class="medezTables cutescroll"
                :select-mode="fMultiSelect"
                :items="items"
                :fields="fFields"
                :sort-compare="Compare"
                :current-page="cPage"
                :show-empty="showMessageIsEmpty"
                @row-selected="rowSelected"
                @row-clicked="rowClicked"
                responsive
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="filtered"
                @row-dblclicked="ondblClicked"
                :tbody-tr-class="trClassFn"
            >     
                <template v-if="editIconEvent || downloadIconEvent || deleteIconEvent || viewIconEvent || otherIconEvent || otherIconEvent2 || IconEvent1 || IconEvent2 || IconEvent3" #cell(actions)="row">              
                 
                  <b-tooltip v-if="viewIconEvent" :target="'ViewIcon-'+row.index"  placement="top" :delay="{ show: 1500, hide: 50 }">
                    View
                  </b-tooltip>
                  <b-tooltip v-if="downloadIconEvent" :target="'DownIcon-'+row.index"  placement="top" :delay="{ show: 1500, hide: 50 }">
                    Download
                  </b-tooltip>
                  <b-tooltip v-if="VisibleEditIcon" :target="'EditIcon-'+row.index"  placement="top" :delay="{ show: 1500, hide: 50 }">
                    Edit
                  </b-tooltip>
                  <b-tooltip v-if="deleteIconEvent" :target="'DelIcon-'+row.index"  placement="top" :delay="{ show: 1500, hide: 50 }">
                    Delete
                  </b-tooltip>
                  <b-tooltip v-if="otherIconEvent" :target="'PrintIcon-'+row.index"  placement="top" :delay="{ show: 1500, hide: 50 }">
                   {{otherIcon === 'printer-fill' ? 'Print' : 'Other Icon' }}
                  </b-tooltip>

                  <b-icon v-if="editIconEvent && isReadOnly" icon="eye-fill" @click="editButton(row.item)" class="rounded-circle tableActionBTN mr-2" scale=".5" font-scale="1.8" variant="light"></b-icon>
                    <b-icon :id="'ViewIcon-'+row.index" v-if="viewIconEvent" icon="play-fill" @click="viewButton(row.item, row.index)" class="rounded-circle tableActionBTN mr-2" scale=".5" font-scale="1.8" variant="light"></b-icon>
                    <b-icon :id="'DownIcon-'+row.index"  v-if="VisibleDownloadIcon && downloadIconEvent && !isReadOnly"  icon="cloud-download-fill" @click="DownloadButton(row.item)"  class="rounded-circle tableActionBTN mr-2" scale=".5" font-scale="1.8" variant="light"></b-icon>
                    <b-icon :id="'EditIcon-'+row.index"  v-if="VisibleEditIcon && editIconEvent && !isReadOnly" icon="pencil-fill" @click="editButton(row.item)" :class="(BtnValidateEmployee && row.item.employeeId.toUpperCase() != EmployeeCode.toUpperCase()) ? 'rounded-circle tableActionBTN mr-2 iconDisabled' : 'rounded-circle tableActionBTN mr-2'" scale=".5" font-scale="1.8" variant="light"></b-icon>
                    <b-icon :id="'PrintIcon-'+row.index"  v-if="otherIconEvent" :icon="otherIcon" @click="otherButton(row.item, $event.target)" class="rounded-circle tableActionBTN mr-2" scale=".5" font-scale="1.8" variant="light"></b-icon>
                    <b-icon v-if="otherIconEvent2" :icon="otherIcon2" @click="otherButton2(row.item, $event.target)" class="rounded-circle tableActionBTN mr-2" scale=".5" font-scale="1.8" variant="light"></b-icon> 
                    <b-icon :id="'DelIcon-'+row.index" v-if="VisibleDeleteIcon && deleteIconEvent && !isReadOnly"  icon="trash-fill" @click="deleteButton(row.item)" :class="(BtnValidateEmployee && row.item.employeeId.toUpperCase() != EmployeeCode.toUpperCase()) ? 'rounded-circle tableActionBTN-danger mr-2 iconDisabled' :'rounded-circle tableActionBTN-danger mr-2'" scale=".5" font-scale="1.8" variant="light"></b-icon>
                    <b-icon v-if="IconEvent1" icon="file-earmark-play" @click="ButtonIconEvent1(row.item, $event.target)" class="rounded-circle tableActionBTN mr-2" scale=".5" font-scale="1.8" variant="light"></b-icon> 
                    <b-icon v-if="IconEvent2" icon="file-pdf" @click="ButtonIconEvent2(row.item, $event.target)" class="rounded-circle tableActionBTN mr-2" scale=".5" font-scale="1.8" variant="light"></b-icon> 
                    <b-icon v-if="IconEvent3" icon="file-earmark-arrow-up" @click="ButtonIconEvent3(row.item, $event.target)" class="rounded-circle tableActionBTN mr-2" scale=".5" font-scale="1.8" variant="light"></b-icon>                     
                </template>

                <template v-if="ShowDetails" #cell(show_details)="row">
                  <b-form-checkbox class="expandNotes" v-model="row.detailsShowing" @change="row.toggleDetails" @input="Check($event,row)"></b-form-checkbox> 
                </template>

                <template v-if="IsDocumentManagement" #cell(expirationDate)="row" class="dateNotes">                             
                  <div>
                    {{row.item.expirationDate}} <span v-if="row.item.expired === 'Document Expired'" class="DocumentExpired">{{$t('MdTable.ExpiredTxt')}}</span>
                  </div>
               </template>

                <template #cell(report)="row">                             
                <b-button v-if="row.item.customFrame !== 'TFRMDOCUMENTNOTES' && row.item.showReport" v-on:click="DoReport('chartreviewbydate', row, row.item)" size="sm" class="repDateNotesBtn">{{ $t('Shared.Report') }}</b-button>
                </template>

                 <template v-if="IsChartReview && IsViewDate" #cell(transdate)="row" class="dateNotes">            
                  {{row.item.transdate}}
                    <div class="pushItDown" v-if="row.item.noteStatus === 'Finalized'"></div>
                    <div v-bind:class="row.item.noteStatus === 'Finalized' ? 'chRstatusfinished':'chRstatus mt-2'">
                     {{row.item.noteStatus}}
                    </div>
                 </template>


                 <template v-if="IsChartReview && IsViewDate" #cell(therapistName)="row" class="dateNotes">   
                  <div v-if="row.item.isSigned">
                    {{row.item.signedDate}}       
                    <div class="pushItDown"></div>
                    </div> 
                  <div> {{row.item.therapistName}}</div>                   
                 </template>

                 <template v-if="IsChartReview && IsViewDate" #cell(coTherapistName)="row" class="dateNotes">  
                  <div v-if="row.item.isCoSigned">
                    {{row.item.coSignedDate}}        
                    <div class="pushItDown"></div>
                </div> 
                  <div> {{row.item.coTherapistName}}</div>                   
                 </template>

                 <template v-if="this.IsAppointments" #cell(startDate)="row" class="dateNotes">            
                  {{row.item.startDate}}
                  <div v-if="row.item.status === 'Confirmed'">
                    <div class="pushItDown" v-if="row.item.status === 'Confirmed' || row.item.status === 'Showed Up'"></div>
                    <div v-bind:class="row.item.status === 'Confirmed' ? 'chRstatusfinished':'chRstatus mt-2'">
                     {{row.item.status}}
                    </div>
                  </div>
                  <div v-else>
                    <div class="pushItDown" v-if="row.item.status === 'Showed Up'"></div>
                    <div v-bind:class="row.item.status === 'Showed Up' ? 'chRstatusfinished':'chRstatus mt-2'">
                     {{row.item.status}}
                    </div>
                  </div>
                 </template>

                 <template v-if="this.IsIncident || this.IsIncidentWorkflow" #cell(incidentDate)="row" class="dateNotes">            
                  {{row.item.incidentDate}}
                  <div v-if="row.item.status === 'Finalized'">
                    <div class="pushItDown" v-if="row.item.status === 'Finalized'"></div>
                    <div v-bind:class="row.item.status === 'Finalized' ? 'chRstatusfinished':'chRstatus mt-2'">
                     {{row.item.status}}
                    </div>
                  </div>
                  <div v-else>
                    <div class="pushItDown" v-if="row.item.status === 'Completed Draft' || row.item.status === 'Completed'"></div>
                    <div v-bind:class="row.item.status === 'Completed Draft' || row.item.status === 'Completed' ? 'chRstatusfinished completeDraft':'chRstatus mt-2'">
                     {{row.item.status}}
                    </div>
                  </div>
                 </template>


                 <template v-if="this.IsGroupNotes" #cell(transDate)="row" class="dateNotes">            
                  {{row.item.transDate}}
                  <div v-if="row.item.status === 'FINALIZED'">
                    <div class="pushItDown" v-if="row.item.status === 'FINALIZED'"></div>
                    <div v-bind:class="row.item.status === 'FINALIZED' ? 'chRstatusfinished':'chRstatus mt-2'">
                     {{row.item.status}}
                    </div>
                  </div>  
                  <div v-else>
                    <div class="pushItDown" v-if="row.item.status != 'FINALIZED'"></div>
                    <div v-bind:class="'chRstatus mt-2'">
                     {{row.item.status}}
                    </div>
                  </div>                                 
                 </template>

                 <template v-if="this.IsGroupNotes" #cell(therapistName)="row">            
                    {{row.item.therapistName}}
                    <span class="NoInfo" v-if="row.item.therapistName === '' || row.item.therapistName == null">{{$t('MdTable.NoTherapistTxt')}}</span>
                 </template>

                <template v-if="ShowDetails" #row-details="row">                   
                  <b-card v-if="row.item.frequency !== 'DAILY MULTIPLES'">                    
                    <b-row>                           
                      <div class="card mb-3 w-100">                      
                        <div v-for="(i,indexDaily) in row.item.SubItems" :key="i.headerid">                  
                          <b-row class='dateNotes cutescroll'>                            
                            <b-col  v-on:click="SearchDataNoteDaily(row,i.Id,i)">                            
                              <i :class="i.icon"></i> {{i.date}} <br>
                              <div v-bind:class="i.description === noteStatus.Finalized ? 'status finished':'status'"> 
                                {{i.description}}
                              </div>
                            </b-col>
                            <b-col :id="i.Id" v-if="IsSchedulerNotes" v-on:click="SearchDataNoteDaily(row,i.Id,i)">                            
                              <div>{{cutNoteName(i.noteName)}}</div>                             
                            </b-col>
                            <b-tooltip :target="i.Id" triggers="hover" v-if="IsSchedulerNotes && i.noteName && i.noteName.length > 30">
                                  {{i.noteName}}
                            </b-tooltip>                         
                             <b-col v-on:click="SearchDataNoteDaily(row,i.Id,i)">                            
                              <div v-if="i.isSigned">{{i.signedDate}}<br></div>                             
                              {{i.therapistName}}
                                <span class="NoInfo" v-if="i.therapistName === ''">{{$t('MdTable.NoTherapistTxt')}}</span>
                            </b-col>
                             <b-col  v-on:click="SearchDataNoteDaily(row,i.Id,i)">                                                 
                              <div v-if="i.isCoSigned">{{i.coSignedDate}}<br></div>
                             {{i.coTherapistName}}
                             <span class="NoInfo" v-if="i.coTherapistName === ''">{{$t('MdTable.NoCoTherapistTxt')}}</span>
                            </b-col>
                             <b-col v-on:click="SearchDataNoteDaily(row,i.Id,i)" v-if="!i.isBillable">                                                 
                             <span v-if="!i.isBillable">{{$t('ChartReview.NotBillable')}}</span>                             
                            </b-col>
                            <b-col v-on:click="SearchDataNoteDaily(row,i.Id,i)" v-else>                                                 
                              <span>{{$t('ChartReview.Billable')}}</span>                             
                             </b-col>                                                                                   
                            <b-col class="text-right mt-2" v-if="row.item.SubItems[indexDaily].noteStatus !== 'New' && row.item.SubItems[indexDaily].customFrame !== 'TFRMDOCUMENTNOTES' && row.item.SubItems[indexDaily].showReport">                            
                              <b-button v-on:click="DoReport('daily', row.item, row.item.SubItems[indexDaily])" size="sm" class="repDailyNotesBtn">{{ $t('Shared.Report') }}</b-button>                     
                             </b-col>
                             <b-col class="text-right mt-2" style="visibility: hidden;" v-else>                            
                              <b-button v-on:click="DoReport('daily', row.item, row.item.SubItems[indexDaily])" size="sm" class="repDailyNotesBtn">{{ $t('Shared.Report') }}</b-button>                     
                             </b-col>                              
                          </b-row>
                        </div>                        
                      </div>
                    </b-row>  
                    <b-button class="btn-login" size="sm" @click="row.toggleDetails">{{$t('MdTable.HideDetailsTxt')}}</b-button>
                  </b-card>

                  <b-card v-if="row.item.frequency === 'DAILY MULTIPLES'">                                                   
                    <b-row>                           
                      <div class="card mb-3 w-100">
                        <div class="dateNotes cutescroll" v-for="(i,index) in row.item.SubItems" :key="index">                         
                          <b-button v-on:click="AddTime(i.ArrTimes)" size="sm" class="addNotesBtn" v-if="useAddTime">{{ $t('Shared.Add')}}</b-button>
                          {{i.date}}   
                          <b-row v-for="(t, indexTimes) in i.ArrTimes" v-show="!t.isBase" :key="indexTimes" class="TimeNotes">                         
                             <b-col v-on:click="SearchDataNoteDailyMultiple(row,t.Id,i,t)">
                               <i :class="t.icon"></i>
                                {{t.Time}} <br>
                                <div v-bind:class="t.description === noteStatus.Finalized ? 'mx-2 status finished dmultiplefo':'mx-2 status dmultipleo'"> 
                                  {{t.description}}
                                </div>                             
                               <!--<small v-bind:class="t.description === noteStatus.Finalized ? 'mr-2 status finished dmultiplefo':'mr-2 status dmultipleo'">{{t.description}}</small>--> 
                             </b-col>


                             <b-col  v-on:click="SearchDataNoteDailyMultiple(row,t.Id,i,t)">
                              <div v-if="t.isSigned">{{t.signedDate}}<br></div>
                              {{t.therapistName}}
                                <span class="NoInfo" v-if="t.therapistName === ''">{{$t('MdTable.NoTherapistTxt')}}</span>
                            </b-col>
                             <b-col  v-on:click="SearchDataNoteDailyMultiple(row,t.Id,i,t)">                             
                              <div v-if="t.isCoSigned">{{t.coSignedDate}}<br></div>
                             {{t.coTherapistName}}
                             <span class="NoInfo" v-if="t.coTherapistName === ''">{{$t('MdTable.NoCoTherapistTxt')}}</span>
                            </b-col>
                            <b-col v-on:click="SearchDataNoteDailyMultiple(row,t.Id,i,t)" v-if="!t.isBillable">                                                 
                              <span v-if="!t.isBillable">{{$t('ChartReview.NotBillable')}}</span>                             
                             </b-col>
                             <b-col v-on:click="SearchDataNoteDailyMultiple(row,t.Id,i,t)" v-else>                                                 
                               <span>{{$t('ChartReview.Billable')}}</span>                             
                              </b-col>                                
                             <b-col class="text-right" v-if="row.item.SubItems[index].ArrTimes[indexTimes].noteStatus !== 'New' && row.item.SubItems[index].ArrTimes[indexTimes].customFrame !== 'TFRMDOCUMENTNOTES' && row.item.SubItems[index].ArrTimes[indexTimes].showReport">                                                  
                              <b-button v-on:click="DoReport('multiples', row.item, row.item.SubItems[index].ArrTimes[indexTimes])" size="sm" class="repMultiNotesBtn">{{$t('Shared.Report')}}</b-button>
                             </b-col>
                          </b-row>
                        </div>
                      </div>
                    </b-row>                
                    <b-button class="btn-login" size="sm" @click="row.toggleDetails">{{ $t('MdTable.HideDetailsTxt')}}</b-button>
                  </b-card>
                
                 <MdPaginationItems v-if="row.item.totalRowsSubItems" 
                    :key="row.item.notesId"
                    :id="row.item.notesId"
                    v-bind:totalRows="row.item.totalRowsSubItems"                  
                    v-bind:pageChanged="SubItemsPageChanged"
                    v-bind:NotesId="IsSchedulerNotes ? row.item.activityId : row.item.notesId"
                    v-bind:perPageFunction="perPageFunctionSubItems"
                    v-bind:perPageSubItems="perPageSubItems"
                  >
                </MdPaginationItems>
                </template>
            </b-table>
        </b-col>    
    </b-row>
   
    <b-row>
      <b-col v-if="pageOptions" sm="7" md="6" class="my-1">
        <b-pagination 
          v-model="cPage"
          :total-rows="totalRows"
          :per-page="pPage"
          align="fill"
          size="sm"
          class="my-0"
          @change="fpageChanged"
        ></b-pagination>
      </b-col>
      <b-col v-if="pageOptions" align="right" class="mr-2">
          <b-form-group
            >{{ $t('Shared.Show') }}
              <b-form-select
                v-model="pPage"
                id="perPageSelect"
                size="sm"
                :options="pageOptions"
                style="width:auto;"
                @change="pageFunction($event)"
              ></b-form-select>
              of {{totalRows}}
            </b-form-group>
        </b-col>
      </b-row>
      <NotesRep v-if="isReportVisible" 
        :closeWindow="closeRepWindow"
        :HeaderId="this.repHeaderId"
        :FormId="this.repFormId"
        :PatientCode="this.repPatientCode"
        :isReportVisible="isReportVisible"
        :ReportTitle="this.repTitle"
        :Transdate="this.repTransdate" 
        :Notetime="this.repNotetime" 
        :Void="this.repVoid"
        :NoteName="this.repNoteName"
    />
  </div>
</template>

<script>


import ShowMessage from '@/components/messages/ShowMessage.js'
import moment from 'moment'
import GlobalService from '@/services/global.service'
import { EventBus } from '@/event-bus.js';

  export default {
    mixins: [ShowMessage],
    props: {
     items:  Array,   
     fields: Array,
     totalRows: Number,
     totalRowsSubItems:Number, 
     editIconEvent: Function,
     VisibleEditIcon:Boolean,
     deleteIconEvent: Function,
     downloadIconEvent: Function,
     VisibleDeleteIcon:Boolean,
     VisibleDownloadIcon:Boolean,
     showMessageIsEmpty: Boolean,
     ShowDetails:Boolean,
     onRowSelected: Function,
     onRowClicked: Function,
     EnabledRowClicked:Boolean,
     ondblClick:Function,
     currentPage: Number,
     currentPageSubItems:Number,
     onFiltered: Function,
     perPage: Number,
     perPageSubItems:Number,
     pageChanged: Function,
     pageChangedSubItems:Function,
     pageOptions: Array,
     pageOptionsSubItems: Array,
     perPageFunction: Function,
     perPageFunctionSubItems: Function,
     otherIconEvent: Function,
     otherIcon: String,
     otherIconEvent2: Function,
     otherIcon2: String,
     IconEvent1:Function,
     IconEvent2:Function,
     IconEvent3:Function,
     viewIconEvent: Function,
     refTable: String,
     subItems: Array,
     multiSelect: Boolean,
     avisar:Function,
     CheckClick:Function,
     small: Boolean,
     stickyHeader: [Boolean,String],
     isReadOnly:Boolean,
     trClassFn: Function,
     useAddTime:[Boolean],
     IsChartReview:[Boolean],
     IsIncident:[Boolean],
     IsGroupNotes:[Boolean],
     IsViewDate:[Boolean],
     IsDocumentManagement:[Boolean],
     FieldsDate:Array,
     NotePatientInfo: Object,
     EventScheduler:Boolean,
     BtnValidateEmployee:Boolean,
     IsIncidentWorkflow:[Boolean],
     IsSchedulerNotes:[Boolean],
     IsAppointments:[Boolean],
   },
    data() {
      return {
          filter: null,
          EmployeeCode:"",
          filterOn: [],          
          pPage: this.perPage,
          pPageSubItems: this.perPageSubItems,
          cPage: this.currentPage,
          cPageSubItems: this.currentPageSubItems,         
          fFields: this.fields,
          fMultiSelect: 'single',
          CountNotesOpened:0,
          CountSubItems:0,
          DateTimeNow:null,
          TRSubItems:0,
          CPSubItems:0,
          CurrentPage: 1,
          isReportVisible: false, 
          repHeaderId: '',
          repFormId: '',
          repPatientCode: '',
          repTransdate: '',
          repNotetime: '',
          repVoid: false,
          repTitle: '',
          repNoteName: '',
          noteStatus:{
            "Finalized":this.$t('Shared.IconDescF')
          }

      }
    },
    
    methods:{

      cutNoteName(noteName) {
        let name = noteName
        if (noteName && noteName.length > 30 ) {
          name = noteName.substr(0, 30) + '...'
        }
        return name
      },

      DoReport(location, row, item) {
        this.repPatientCode = this.NotePatientInfo.patientCode
        this.repTransdate = item.transDate
        this.repVoid = item.isVoid
        this.repNotetime = item.noteTime ? item.noteTime : null

        if (location === 'chartreviewbydate') {
          this.repHeaderId = item.headerID
          this.repFormId = item.notesID
          this.repNoteName = item.description
          this.repTitle = this.getReportTitle(item.description, this.NotePatientInfo.firstName, this.NotePatientInfo.lastName, this.NotePatientInfo.patientCode)
        } else {
          this.repHeaderId = item.Id,
          this.repFormId = row.notesId,
          this.repNoteName = row.description
          this.repTitle = this.getReportTitle(row.description, this.NotePatientInfo.firstName, this.NotePatientInfo.lastName, this.NotePatientInfo.patientCode)
        }
        this.isReportVisible = true
      },

    editButton(row) {
        this.editIconEvent(row);
        this.sendDataForFavorite();
    },

    deleteButton(row) {
        this.deleteIconEvent(row);
    },

    rowSelected(items) {     
        this.onRowSelected(items);
    },

    rowClicked(item ,index) {
      if(this.EnabledRowClicked)
        this.onRowClicked(item,index);
    },

    filtered(filteredItems) {
        this.onFiltered(filteredItems);
    },

    fpageChanged(page) {
       //this.$refs[this.refTable].refresh();
        this.pageChanged(page);
    },
     fpageChangedSubItems(page) {
        this.pageChangedSubItems(page);
    },

    SubItemsPageChanged(e){     
    this.pageChangedSubItems(e);
    },

    pageFunction(perpage) {
        this.perPageFunction(perpage);
    },
    pageFunctionSubItems(perpage) {
        this.perPageFunctionSubItems(perpage);
    },

    otherButton(row) {
      this.otherIconEvent(row);
    },

    otherButton2(row) {
      this.otherIconEvent2(row);
    },

    ButtonIconEvent1(row){
      this.IconEvent1(row);
    },

    ButtonIconEvent2(row){
      this.IconEvent2(row);
    },

    DownloadButton(row){
      this.downloadIconEvent(row);
    },

    ButtonIconEvent3(row){
      this.IconEvent3(row);
    },

    viewButton(row,index) {
      this.viewIconEvent(row,index);
      this.sendDataForFavorite();
    },


    AddTime(SubItems){       
      this.getheader(SubItems);     
      
    },

    ondblClicked(item){    
       this.ondblClick(item);
    },



    Compare(itemA, itemB, key) {
      if(this.FieldsDate !== undefined && this.FieldsDate !== null){
        if(!this.FieldsDate.includes(key)){
          return false;
        }else {  
          /*let a = (itemA[key] === null || itemA[key] === ""?'00/00/0000':itemA[key]).split('/');
          let b = (itemB[key] === null || itemA[key] === ""?'00/00/0000':itemB[key]).split('/');         
          a = (parseInt(a[2], 10) * 10000) + (parseInt(a[0], 10) * 100) + parseInt(a[1]);
          b = (parseInt(b[2], 10) * 10000) + (parseInt(b[0], 10) * 100) + parseInt(b[1]);
          return a - b*/
          let a = (itemA[key] === null || itemA[key] === ""?'00/00/0000 00:00:00':itemA[key]);
          let b = (itemB[key] === null || itemA[key] === ""?'00/00/0000 00:00:00':itemB[key]); 
          const dateA = new Date(a);
          const dateB = new Date(b);
          return dateA - dateB;
        }
      }
    },

getheader(SubItems){
     GlobalService.getheaderId()
                      .then((response) => {
                        if(response.status == '200'){
                            var Header = response.data;                         
                            this.getDateTime(SubItems,Header);
                        }
                      })
                      .catch((error) => {
                        if(error.response) {
                            this.showMessage(this.$t('MdTable.GetHeaderIdMsg'),error,"error");
                        }
                      });
},

GetObjectIfIsBase(arr){
  for(var i = 0; i < arr.length; i++){
    if(arr[i].isBase === true){
      return arr[i];      
    }
  }
  return null;
},
    
        getDateTime(SubItems,Header) {
          let BaseMultiple = this.GetObjectIfIsBase(SubItems);
          if(BaseMultiple === null){
            BaseMultiple = SubItems[SubItems.length-1];        
          }
           
          //SubItems.pop(); 
            GlobalService.getDateTime()
                          .then((response) => {
                            if(response.status == '200' && response.data){
                            this.DateTimeNow = response.data; 
                             var o = {};
                            if(this.DateTimeNow){
                               var newTime = moment(this.DateTimeNow).format("hh:mm:ss"); 
                              o.icon = "icon-doc mr-2";
                              o.description = "New Note";
                              o.Time = newTime;
                              o.isBase = false;                             
                              o.Id = Header.toString();
                              o.patientCode = BaseMultiple.patientCode;
                              o.isVoid = BaseMultiple.isVoid;
                              o.chartno = BaseMultiple.chartno;
                              o.defaultUnit = BaseMultiple.defaultUnit;
                              o.coTherapistCode = BaseMultiple.coTherapistCode;
                              o.disableBillable = BaseMultiple.disableBillable;
                              o.showBilling = BaseMultiple.showBilling;
                              o.frequency = BaseMultiple.frequency;                             
                              o.userHasReadOnlyAccess = BaseMultiple.userHasReadOnlyAccess;
                              o.coSignedDate = BaseMultiple.coSignedDate;
                              o.endTime = BaseMultiple.endTime;
                              o.facilityCode = BaseMultiple.facilityCode;
                              o.isCoSigned = BaseMultiple.isCoSigned;                            
                              o.isPendingCoSignature = BaseMultiple.isPendingCoSignature;
                              o.isPendingSignature = BaseMultiple.isPendingSignature;
                              o.isSigned = BaseMultiple.isSigned;                              
                              o.noteStatus = 'New';
                              o.noteTime = BaseMultiple.noteTime;
                              o.serviceDate = BaseMultiple.serviceDate;
                              o.signedDate = BaseMultiple.signedDate;
                              o.startTime = BaseMultiple.startTime;
                              o.therapistCode = BaseMultiple.therapistCode;
                              o.transCode = BaseMultiple.transCode;
                              o.transDate = BaseMultiple.transDate;
                              o.notesContents = BaseMultiple.notesContents;
                              o.isBillable = BaseMultiple.isBillable;                              
                              o.customFrame = BaseMultiple.customFrame;
                              var new_startDate= new Date(o.transDate);
                              var date_result = moment(new_startDate).format('YYYY-MM-DD');
                              var InternationalTime =  new Date(date_result+'T'+o.Time);
                              o.noteTime = InternationalTime;
                              SubItems.push(BaseMultiple);
                              SubItems.push(o);
                            }
                        }
                      })
                      .catch((error) => {
                        if(error.response) {
                            this.showMessage(this.$t('ChartReview.GetDate'),error,"error");
                        }
                      });



                                   
          },
  


    Check(e,row){
      if(e){
         this.IsCheckBoxClicked(row);
      }
    },

      async IsCheckBoxClicked(row) {
        let SendObject = {}     

        if (this.IsSchedulerNotes) {
          SendObject = {       
            activityId:row.item.activityId,
            currentPage:1,
            perPage:10 
          } 
        } else {
          SendObject = {       
            notesId:row.item.notesId,
            currentPage:1,
            perPage:10 
          } 
        }       
        this.CheckClick(SendObject);
      },




    async SearchDataNoteDaily(row,headerid,item) {
     var new_startDate= new Date(item.date);
     var date_result = moment(new_startDate).format('YYYY-MM-DD');
     var SendObject = {
        activityId: item.activityId ? item.activityId : null,
        appointmentTypeCode: row.item.appointmentTypeCode ? row.item.appointmentTypeCode : null,       
        notesId:row.item.notesId ? row.item.notesId : item.notesId,
        noteName: item.noteName ? item.noteName : null, 
        description:row.item.description,
        Id:headerid,
        date:date_result,
        defaultUnit:item.defaultUnit,
        patientCode:item.patientCode,
        userHasReadOnlyAccess:item.userHasReadOnlyAccess,
        isBillable:item.isBillable,
        isVoid:item.isVoid,
        coTherapistCode:item.coTherapistCode,     
        coSignedDate:item.coSignedDate,
        endTime:item.endTime,
        customFrame:item.customFrame,
        facilityCode:item.facilityCode,
        isCoSigned:item.isCoSigned,
        isPendingCoSignature:item.isPendingCoSignature,
        isPendingSignature:item.isPendingSignature,
        isSigned:item.isSigned,
        noteStatus:item.noteStatus,
        noteTime:item.noteTime,       
        frequency:item.frequency,
        serviceDate:item.serviceDate,
        signedDate:item.signedDate,
        startTime:item.startTime,
        therapistCode:item.therapistCode,
        transCode:item.transCode,
        chartno:item.chartno,
        transDate:item.transDate,
        notesContents:item.notesContents,
        disableBillable:item.disableBillable,
        showBilling:item.showBilling,
        allowCosignNotesDoneByCosigners:item.allowCosignNotesDoneByCosigners,
        isEditing:false
      }
        this.avisar(SendObject);
       
      },
      
      async SearchDataNoteDailyMultiple(row,headerid,item,T) {  
        var new_startDate= new Date(item.date);
        var date_result = moment(new_startDate).format('YYYY-MM-DD');
        var InternationalTime =  new Date(date_result+'T'+T.Time);
        var HeaderID = "";
        if(T.description === 'New Note'){
          HeaderID = T.Id;
        }else{  
          HeaderID = T.Id;
        }
        var SendObject = {       
        notesId:row.item.notesId,
        description:row.item.description,
        Id:HeaderID,
        date:date_result,
        defaultUnit:T.defaultUnit,
        patientCode:T.patientCode,
        userHasReadOnlyAccess:T.userHasReadOnlyAccess,
        Time:moment(InternationalTime).format(),
        isBillable:T.isBillable,
        isVoid:T.isVoid,
        frequency:T.frequency,
        coTherapistCode:T.coTherapistCode,       
        coSignedDate:T.coSignedDate,
        endTime:T.endTime,
        facilityCode:T.facilityCode,
        isCoSigned:T.isCoSigned,
        isPendingCoSignature:T.isPendingCoSignature,
        isPendingSignature:T.isPendingSignature,
        isSigned:T.isSigned,
        noteStatus:T.noteStatus,
        noteTime:moment(InternationalTime).format('hh:mm:ss'),       
        serviceDate:T.serviceDate,
        signedDate:T.signedDate,
        startTime:T.startTime,
        therapistCode:T.therapistCode,
        transCode:T.transCode,
        chartno:T.chartno,
        transDate:T.transDate,
        therapyCode: T.transCode,       
        notesContents:T.notesContents,
        disableBillable:T.disableBillable,
        showBilling:T.showBilling,
        customFrame:T.customFrame,
        allowCosignNotesDoneByCosigners:item.allowCosignNotesDoneByCosigners,
      }
        this.avisar(SendObject);
       
      },

      getReportTitle(note, firstname, lastname, patientcode) {
        let reportTitle = `${note.length > 50 ? note.substring(0,50) + '...' : note} - ${firstname} ${lastname}`
        reportTitle = reportTitle.length + patientcode.length <= 100 ? `${reportTitle} - ${patientcode}` : `${reportTitle}`
        return reportTitle
      },

      UpdateNoteStatus(objNote){           
       if(!this.EventScheduler){
        for(var i = 0; i<this.items.length; i++){
        if(this.items[i].notesId === objNote.Notesid || this.items[i].activityId === objNote.activityId){
          if(this.items[i].SubItems !== undefined){
            for(var j = 0; j < this.items[i].SubItems.length; j++){
            if(objNote.frequency !== 'DAILY MULTIPLES'){                            
              if(this.items[i].SubItems[j].Id === objNote.Headerid){
                  this.items[i].SubItems[j].signedDate = objNote.DatesAndNames.signeddate;
                  this.items[i].SubItems[j].therapistName = objNote.DatesAndNames.therapistName;
                  this.items[i].SubItems[j].coTherapistName = objNote.DatesAndNames.cotherapistName;
                  this.items[i].SubItems[j].coSignedDate = objNote.DatesAndNames.cosigneddate;
                  this.items[i].SubItems[j].isSigned = objNote.DatesAndNames.isSigned;
                  this.items[i].SubItems[j].isCoSigned = objNote.DatesAndNames.isCoSigned;
                  this.items[i].SubItems[j].therapistCode = objNote.DatesAndNames.therapistCode;
                  this.items[i].SubItems[j].coTherapistCode = objNote.DatesAndNames.coTherapistCode;

                if(objNote.NoteStatus === "New"){
                  this.items[i].SubItems[j].noteStatus = "New";                  
                  this.items[i].SubItems[j].icon = "icon-doc mr-2";
                  this.items[i].SubItems[j].description = this.$t('Shared.IconDescN');
                }
                else if(objNote.NoteStatus === "Voided"){
                  this.items[i].SubItems[j].noteStatus = "Voided";
                  this.items[i].SubItems[j].icon = "icon-ban mr-2";
                  this.items[i].SubItems[j].description = this.$t('Shared.IconDescVN');
                }
                else if(objNote.NoteStatus === "Pending Signature"){
                  this.items[i].SubItems[j].noteStatus = "Pending Signature";
                  this.items[i].SubItems[j].icon = "icon-note  mr-2";
                  this.items[i].SubItems[j].description = this.$t('Shared.IconDescPS');
                }
                else if(objNote.NoteStatus === "Pending Cosigner Signature"){
                  this.items[i].SubItems[j].noteStatus = "Pending Cosigner Signature";
                  this.items[i].SubItems[j].icon = "icon-user mr-2";
                  this.items[i].SubItems[j].description = this.$t('Shared.IconDescPCS');
                }else{
                  this.items[i].SubItems[j].noteStatus = "Finalized";
                  this.items[i].SubItems[j].icon = "icon-check mr-2";
                  this.items[i].SubItems[j].description = this.$t('Shared.IconDescF');
                }
              }
            }else{
              for(var k = 0; k < this.items[i].SubItems[j].ArrTimes.length; k++){
                if(this.items[i].SubItems[j].ArrTimes[k].Id === objNote.Headerid){
                  this.items[i].SubItems[j].ArrTimes[k].signedDate = objNote.DatesAndNames.signeddate;
                  this.items[i].SubItems[j].ArrTimes[k].therapistName = objNote.DatesAndNames.therapistName;
                  this.items[i].SubItems[j].ArrTimes[k].coTherapistName = objNote.DatesAndNames.cotherapistName;
                  this.items[i].SubItems[j].ArrTimes[k].coSignedDate = objNote.DatesAndNames.cosigneddate;
                  this.items[i].SubItems[j].ArrTimes[k].therapistCode = objNote.DatesAndNames.therapistCode;
                  this.items[i].SubItems[j].ArrTimes[k].coTherapistCode = objNote.DatesAndNames.coTherapistCode;

                  this.items[i].SubItems[j].ArrTimes[k].isCoSigned = objNote.DatesAndNames.isCoSigned;
                  this.items[i].SubItems[j].ArrTimes[k].isCoSigned = objNote.DatesAndNames.isCoSigned;
                if(objNote.NoteStatus === "New"){
                  this.items[i].SubItems[j].ArrTimes[k].noteStatus = "New";
                  this.items[i].SubItems[j].ArrTimes[k].icon = "icon-doc mr-2";
                  this.items[i].SubItems[j].ArrTimes[k].description = this.$t('Shared.IconDescN');
                }
                else if(objNote.NoteStatus === "Voided"){
                  this.items[i].SubItems[j].ArrTimes[k].noteStatus = "Voided";
                  this.items[i].SubItems[j].ArrTimes[k].icon = "icon-ban mr-2";
                  this.items[i].SubItems[j].ArrTimes[k].description = this.$t('Shared.IconDescVN');
                }
                else if(objNote.NoteStatus === "Pending Signature"){
                  this.items[i].SubItems[j].ArrTimes[k].noteStatus = "Pending Signature";
                  this.items[i].SubItems[j].ArrTimes[k].icon = "icon-note  mr-2";
                  this.items[i].SubItems[j].ArrTimes[k].description = this.$t('Shared.IconDescPS');
                }
                else if(objNote.NoteStatus === "Pending Cosigner Signature"){
                  this.items[i].SubItems[j].ArrTimes[k].noteStatus = "Pending Cosigner Signature";
                  this.items[i].SubItems[j].ArrTimes[k].icon = "icon-user mr-2";
                  this.items[i].SubItems[j].ArrTimes[k].description = this.$t('Shared.IconDescPCS');
                }else{
                  this.items[i].SubItems[j].ArrTimes[k].noteStatus = "Finalized";
                  this.items[i].SubItems[j].ArrTimes[k].icon = "icon-check mr-2";
                  this.items[i].SubItems[j].ArrTimes[k].description = this.$t('Shared.IconDescF');
                }
              }
              }
            }
             
          }
          }
        
        }
       }
       }       
      },
      closeRepWindow() {
        this.isReportVisible = false
      },

      selectRow(row) {
        this.$refs[this.refTable].selectRow(row)
      },
        /**
       * Only for favorites button action
       */
      sendDataForFavorite(){
        var send = {
          isVisible: false,
          isFav:false
        };
        this.$store.commit('setBtnFavoriteState', send.isVisible);
        EventBus.$emit('shwoHideFavorite', send);
        EventBus.$emit('onUpdatedMenu', '');
      } /**End */
 },
  created() {    
    this.EmployeeCode = this.$store.state.employeeCode;
    if(this.subItems) {
      this.fFields.unshift({ key: 'show_details'});
    }    

    if(this.VisibleEditIcon && this.editIconEvent || this.VisibleDownloadIcon && this.downloadIconEvent || this.VisibleDeleteIcon && this.deleteIconEvent || this.viewIconEvent || this.otherIconEvent || this.otherIconEvent2 || this.IconEvent1 || this.IconEvent2 || this.IconEvent3) {
      if(!this.isReadOnly && this.fFields){
        this.fFields.unshift({ key: 'actions', label: this.$tc('Shared.ActionsTxt',2),thClass: 'ActionsClass'});
      }      
    }

    EventBus.$on("onChangeNoteStatus", function (objNote) {        
        this.UpdateNoteStatus(objNote);
     }.bind(this)); 

     EventBus.$on("onSetField", function (f) {          
        this.fFields = f;
      
    if(this.subItems) {
      this.fFields.unshift({ key: 'show_details'});
    } 

    if(this.VisibleEditIcon && this.editIconEvent || this.VisibleDownloadIcon && this.downloadIconEvent || this.VisibleDeleteIcon && this.deleteIconEvent || this.viewIconEvent || this.otherIconEvent || this.otherIconEvent2 || this.IconEvent1 || this.IconEvent2 || this.IconEvent3) {
      if(!this.isReadOnly && this.fFields){
        this.fFields.unshift({ key: 'actions', label: this.$tc('Shared.ActionsTxt',2),thClass: 'ActionsClass'});
      }      
    }
     }.bind(this)); 

    EventBus.$on("onSetReadOnly", function (readOnly) {       
      if(!readOnly){
        this.fFields.unshift({ key: 'actions', label: this.$tc('Shared.ActionsTxt',2),thClass: 'ActionsClass'});
      } 
     }.bind(this));  
    this.fMultiSelect = this.multiSelect ? 'multi' : 'single';
  }, 
    /*beforeDestroy () {     
      EventBus.$off('onChangeNoteStatus');       
    },*/
}

</script>

<style>

.ActionsClass {
   width: 150px !important;
}
.TimeNotes{
 border: 1px solid #F3F3F3;
 background-color: #f9fbfc;
 color: #999;
 padding: 8px;
 border-radius: 6px;
 margin: 4px;
 cursor: pointer;
 transition: all 0.1s ease-out;
}
.addNotesBtn{
  background-color:#19BEE8;
  margin-right: 4px;
	border-radius:10px 2px;
	cursor:pointer;
  vertical-align: top;
	color:#ffffff;
	padding:4px 11px;
	text-decoration:none;
  border: 0;
}
.addNotesBtn:focus{
	background-color: #19BEE8 !important;
  color: #FFF !important;
}

.iconDisabled{
  opacity: 0.2;
  filter: grayscale(100%);
  }
</style>
