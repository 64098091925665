import ApiService from '@/services/api.service'


const ContactService = {
    getAll( parameter) { 
         
      const requestData = {
        method: 'get',
        url: "/PatientContacts/",
        headers: {
        },
        params: { 
          SearchCriteria: parameter.SearchCriteria,
          PageSize: parameter.PageSize,          
          PageNumber : parameter.PageNumber,
          PatientCode : parameter.PatientCode
        }
      }     
      return ApiService.customRequest(requestData);         
    },

    getByCode(Code) {
      return ApiService.get(`/PatientContacts/?PatientCode=${Code}`);
    },

  
    get( id) {
      return ApiService.get(`/PatientContacts/${id}`);
    },

    addContact(data){
      return ApiService.post(`/PatientContacts`,data)
    },

    deleteContact(id){
      return ApiService.delete(`/PatientContacts/${id}`)
    },
  update(RecordId,Contact){
    return ApiService.put(`/PatientContacts/${RecordId}`,Contact)
  }
    
  }
  
  export default ContactService;