<template>
<div>
  <b-modal :id="modalId" scrollable  :visible="true" title="Notify" @ok="this.emitEventOK" @cancel="this.emitEventCancel" @hide="emitEventHide"  size="xl" centered> 
    <Loading v-bind:IsLoading="this.Load" />       
    <b-row>
            <b-col lg="12" md="12" sm="12">
              <MdSelect
                v-bind:Label="$t('scheduleNotify.sTo')"
                v-bind:Options="options"
                v-bind:FieldDescription="'description'"
                v-bind:FieldCode="'value'"
                v-bind:Model="valueOption1"                       
                v-bind:Multiple="false"
                v-bind:AllowEmpty="false"
                :Input="ChangeDropDownOptions"
                v-bind:isRequired="true"
                v-bind:isValid="this.isSendToValid"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="12" md="12" sm="12">
              <MdSelect
                ref="option2"
                v-bind:Label="$t('scheduleNotify.mType')"
                v-bind:Options="options2"
                v-bind:FieldDescription="'description'"
                v-bind:FieldCode="'recID'"
                v-bind:Model="valueOption2"                       
                v-bind:Multiple="false"
                v-bind:AllowEmpty="false"
                :Input="ChangeDropDownOption2"
                :CustomLabel="customLabelMessageType"
                v-bind:isRequired="true"
                v-bind:isValid="this.isMessageTypeValid"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="12" md="12" sm="12">
              <p v-html="body"></p>
            </b-col>
          </b-row>
</b-modal>
 </div>
</template>

<script>
import ShowMessage from '@/components/messages/ShowMessage.js'
import DBFunctions from '@/common/DBFunctions.js'
import MessageTemplateSetupsService from '@/services/messagetemplatesetups.service'
import MessageNotificationService from '@/services/messagenotification.service'
import Patient from '@/services/patient.service'
import Employee from '@/services/employee.service'
import Loading from '@/components/template/loading/Loading'

export default {
  name: 'ScheduleNotify', 
  mixins: [ShowMessage, DBFunctions],
  components: {
    Loading
  },
  props: {
    onClose: Function,
    activityId: String,
    employeeCode: String,
    patientCode: String,
    sysUser: String,
    module: String,
    showIn: String

   },
  data() {
    return {
      options: [
         { description: this.$tc('Shared.Patient'), value: 'patient'},
         { description: this.$tc('Shared.Employee'), value: 'employee'},
         { description: this.$tc('Contact.PatientContact'), value: 'patientcontact'},
      ],
      options2: [],

      valueOption1: null,
      valueOption2: null,
      subject: null,
      body: null,
      patient: null,
      employee: null,
      modalId: 'modalNotify',
      closeModal: false,
      isSendToValid: true,
      isMessageTypeValid: true,
      isBodyValid: true,
      Load: false,
    }
  },

  methods: {

    customLabelMessageType ({ description, notificationType }) {
      return `${description} – ${notificationType}`
    },

    ChangeDropDownOptions(item) {
      this.Load = true;
      this.valueOption1 = item
      
      this.isSendToValid = item ? true : false
      this.$refs.option2.clearSelection()
      this.subject = null
      this.body = null
      
      if (item.value == 'patient') {
        this.getPatient()
        this.getOptions('PATIENT')
      }
      else if (item.value == 'employee') {
        this.getEmployee()
        this.getOptions('EMPLOYEE')
      }       
      else if (item.value == 'patientcontact') {
        //this.getEmployee()
        this.getOptions('PATIENTCONTACT')
      }
    },

    ChangeDropDownOption2(item) {
      this.Load = true;
      this.valueOption2 =  item
      this.isMessageTypeValid = item ? true : false
      this.CreateNotificationMessage(item.messageType,item.notificationType)
    },

    async getPatient() {
      await Patient.get(this.patientCode)
        .then((response) => {
          if(response.status == '200'){
            this.patient = response.data
          }
        })
        .catch((error) => {
          if(error.response) {
            this.showMessage(this.$t('scheduleNotify.noti'),error,"error");
          }
        });
    },

    async getEmployee() {
      let param = {
        code: this.employeeCode
      }
      await Employee.getEmployeeByCode(param)
        .then((response) => {
          if(response.status == '200'){
            this.employee = response.data
          }
        })
        .catch((error) => {
          if(error.response) {
            this.showMessage(this.$t('scheduleNotify.noti'),error,"error");
          }
        });
    },

    async CreateNotificationMessage(MessageType, NotificationType) {
      let params = {
        MessageType : MessageType, 
        NotificationType: NotificationType,
        EntityCode: this.activityId
      }

      await MessageTemplateSetupsService.CreateNotificationMessage(params)
        .then((response) => {
          if(response.status == '200'){
            this.body = response.data.body
            this.subject = response.data.subject

            this.isBodyValid = response.data.body ? true : false
            this.Load = false;
          }
        })
        .catch((error) => {
          this.Load = false;
          if(error.response) {
            this.showMessage(this.$t('scheduleNotify.noti'),error,"error");
          }
        });
    },

    emitEventOK(){   
      if (this.validateInput()) {
        this.Load = true;
        this.SendNotificationMessage()
      }
    },

    validateInput() {
      this.isSendToValid = this.valueOption1 ? true : false
      this.isMessageTypeValid = this.valueOption2 ? true : false
      this.isBodyValid = this.body ? true : false

      return this.valueOption1 && this.valueOption2 && this.body
    },

    emitEventCancel(){
      this.onClose()
      this.closeModal = true
    },

    emitEventHide(handled) {
      handled.preventDefault()
    },

    async SendNotificationMessage() {
      let params = {
        recipientCode: this.patientCode,
        recipientType: this.valueOption1.value == 'patient' ? 'PATIENT' : this.valueOption1.value == 'employee' ? 'EMPLOYEE' : 'PATIENTCONTACT' ,
        entityCode: this.activityId,
        entityType: this.activityId,
        messageType: this.valueOption2.messageType,
        notificationType: this.valueOption2.notificationType.toUpperCase(),
        cellPhoneNumber:  this.valueOption1.value == 'patient' ? this.patient.cellPhone : this.employee.cellPhone,
        email: this.valueOption1.value == 'patient' ? this.patient.email : this.employee.eMail,
        module: this.module,
        action: "SendByUser",
        sysUser: this.sysUser,
        subject: this.subject,
        body: this.body
      }
      
      await MessageNotificationService.SendNotificationMessage(params)
        .then((response) => {
          if(response.status == '200'){
            this.Load = false;
            if (response.data.success) {
              this.showMessage(this.$t('scheduleNotify.noti'),this.$t('scheduleNotify.msgSent'));
              this.closeModal = true
              this.onClose() 
            } else {
              this.showMessage(this.$t('scheduleNotify.noti'),response.data.errorMessage,"error");
            }	
          }
        })
        .catch((error) => {
          this.Load = false;
          if(error.response) {
            this.showMessage(this.$t('scheduleNotify.noti'),error,"error");
          }
        });
      },

    async getOptions(SendTo) {
      let params = {
        SendTo: SendTo,
        ShowIn: this.showIn
      }

      await MessageTemplateSetupsService.getAll(params)
        .then((response) => {
          if(response.status == '200'){
              this.options2 = response.data;
              this.Load = false;
          }
        })
        .catch((error) => {
          this.Load = false;
          if(error.response) {
            this.showMessage(this.$t('scheduleNotify.noti'),error,"error");
          }
        });
      },    
  },

  async mounted() {

  },

  created () {
    },
};

</script>
<style>
  .tab-content-no-padding {
    padding: 0 0 !important;
  }
</style>