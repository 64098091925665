import ApiService from '@/services/api.service'

const UserPreferences = {

    getUserPreference(id) {
      const requestData = {
        method: 'get',
        url: "/UserPreferences/",
        headers: {
        },
        params: { 
          UserId: id          
        }
      }     
      return ApiService.customRequest(requestData);         
    },

    delete(id){
      return ApiService.delete(`/UsersPreferences/${id}`)
    },

    add(data){
      return ApiService.post(`/UsersPreferences`,data)
    },

    update(id,data){
      return ApiService.put(`/Users/${id}`,data)
    },

    UpdateFullScreenStatus(id,data){
      return ApiService.put(`/UserPreferences/FullScreen/${id}`,data)
    },

    UpdateProviderCodeStatus(id,data){
      return ApiService.put(`/UserPreferences/ProviderCode/${id}`,data)
    },

    UpdateLocalePreference(id,data){
      return ApiService.put(`/UserPreferences/Locale/${id}`,data)
    }
    ,
    UpdateShowTimePreference(id,data){
      return ApiService.put(`/UserPreferences/ShowTime/${id}`,data)
    },

    ChangeTimeFormat(id,data){
      return ApiService.put(`/UserPreferences/TimeFormat/${id}`,data)
    },

    ChangeDateFormat(id,data){
      return ApiService.put(`/UserPreferences/DateFormat/${id}`,data)
    },

    getUserPreferenceWebBoxes(){
      return ApiService.get(`/MdWebBoxes`);
    },
    
    getUserPreferenceWebBoxesVisible(id) {
      const requestData = {
        method: 'get',
        url: "/MdWebBoxByUsers/",
        headers: {
        },
        params: { 
          UserId: id          
        }
      }     
      return ApiService.customRequest(requestData);         
   
    },
    deleteUserWebBox(id){
      return ApiService.delete(`/MdWebBoxByUsers/${id}`)
    },

    addUserWebBox(data){
      return ApiService.post(`/MdWebBoxByUsers`,data)
    },

}

export default UserPreferences;