import DBFunctions from '@/common/DBFunctions.js'
import EHRFeatures from '@/services/ehrfeatures.service'
import LocalStorage from '@/services/storage.service'

export default {
    mixins: [DBFunctions],
    data() {
        return {
            isUseAsam: false,
            EHRReadOnlyList: []
        }
    },
    methods: {
         async setPermissions() {
            this.EHRReadOnlyList = await this.getIsReadOnly('');
            if(this.EHRReadOnlyList) {
               this.$store.commit('setASAMEHRReadOnly',this.isNoteReadOnly('ASAM'));
               this.$store.commit('setPatientInfoEHRReadOnly',this.isNoteReadOnly('PATIENTINFO'));
               this.$store.commit('setPatientInsuranceEHRReadOnly',this.isNoteReadOnly('PATIENTINSURANCE'));
               this.$store.commit('setPatientContactsEHRReadOnly',this.isNoteReadOnly('PATIENTCONTACTS'));
               this.$store.commit('setClinicalNotesEHRReadOnly',this.isNoteReadOnly('CLINICAL_NOTESUS'));
               this.$store.commit('setVitalSignEHRReadOnly',this.isNoteReadOnly('VITAlSIGNS'));
               this.$store.commit('setPatientAllergiesEHRReadOnly',this.isNoteReadOnly('PATIENTALLERGIES'));
               this.$store.commit('setPatientAdmissionEHRReadOnly',this.isNoteReadOnly('ADMISSION'));
               this.$store.commit('setClinicalTransferEHRReadOnly',this.isNoteReadOnly('PROGTRANSFER'));
               this.$store.commit('setClinicalMedicationsEHRReadOnly',this.isNoteReadOnly('MEDICATIONS'));
               this.$store.commit('setClinicalDischargeEHRReadOnly',this.isNoteReadOnly('PATDISCHARGE'));
               this.$store.commit('setPatientDiagnosisEHRReadOnly',this.isNoteReadOnly('PATIENTDIAGNOSIS'));
               this.$store.commit('setDailyNotesEHRReadOnly',this.isNoteReadOnly('DAILY NOTES'));
               this.$store.commit('setPatientDocumentEHRReadOnly',this.isNoteReadOnly('PATIENTDOCUMENTS'));
               this.$store.commit('setLogNotesEHRReadOnly',this.isNoteReadOnly('LOGNOTES'));
               this.$store.commit('setSchedulerClinicalEHRReadOnly',this.isNoteReadOnly('SCHED_CLINICAL'));
               this.$store.commit('setAppointmentsEHRReadOnly',this.isNoteReadOnly('APPOINTMENTS'));
               this.$store.commit('setTreatmentPlanEHRReadOnly',this.isNoteReadOnly('TREATMENTPLAN'));
               this.$store.commit('setEPrescribingEHRReadOnly',this.isNoteReadOnly('NEWCROP'));
            } else {
               this.setAllReadOnly(false)
            }
            this.isUseAsam = await this.getUseASAM();
            this.parameter.UserId = LocalStorage.getUserId();
            EHRFeatures.getByUsers(this.parameter)
            .then((response) => {
               if(response.status == '200' && response.data){
                const ehrFeaturesList = response.data; 
                if(ehrFeaturesList && ehrFeaturesList.length > 0) {
                    if(ehrFeaturesList[0].ehrFeatures.includes(',')){
                         let arr = ehrFeaturesList[0].ehrFeatures.split(',');
                         if(arr.indexOf('ADMISSION') !== -1){
                             this.$store.commit('setUsePatientAdmission',true);
                         }else{
                             this.$store.commit('setUsePatientAdmission',false);
                         } 
                    }else{
                         if(ehrFeaturesList[0].ehrFeatures.includes('ADMISSION')) {
                              this.$store.commit('setUsePatientAdmission',true);
                         } else {
                              this.$store.commit('setUsePatientAdmission',false);
                         }
                    }
                    if(ehrFeaturesList[0].ehrFeatures.includes('PROGTRANSFER')) {
                         this.$store.commit('setUseTransfer',true);
                    } else {
                         this.$store.commit('setUseTransfer',false);
                    }
                   
                    if(ehrFeaturesList[0].ehrFeatures.includes('MEDICATIONS')) {
                         this.$store.commit('setUseMedications',true);
                    } else {
                         this.$store.commit('setUseMedications',false);
                    }

                    if(ehrFeaturesList[0].ehrFeatures.includes('DAILY NOTES')) {
                         this.$store.commit('setDailyNotes',true);
                    } else {
                         this.$store.commit('setDailyNotes',false);
                    }
                    if(ehrFeaturesList[0].ehrFeatures.includes('LOGNOTES')) {
                         this.$store.commit('setLogNotes',true);
                    } else {
                         this.$store.commit('setLogNotes',false);
                    }
                    if(ehrFeaturesList[0].ehrFeatures.includes('PATIENTDIAGNOSIS')) {
                         this.$store.commit('setUsePatientDiagnosis',true);
                    } else {
                         this.$store.commit('setUsePatientDiagnosis',false);
                    }
                    if(ehrFeaturesList[0].ehrFeatures.includes('PATDISCHARGE')) {
                         this.$store.commit('setUseDischarge',true);
                    } else {
                         this.$store.commit('setUseDischarge',false);
                    }
                   if(ehrFeaturesList[0].ehrFeatures.includes('PATIENTINFO')) {
                        this.$store.commit('setUsePatientInfo',true);
                   } else {
                        this.$store.commit('setUsePatientInfo',false);
                   }
                   if(ehrFeaturesList[0].ehrFeatures.includes('PATIENTINSURANCE')) {
                        this.$store.commit('setUsePatientInsurance',true);
                   } else {
                        this.$store.commit('setUsePatientInsurance',false);
                   }
                   if(ehrFeaturesList[0].ehrFeatures.includes('PATIENTCONTACTS')) {
                        this.$store.commit('setUsePatientContact',true);
                   } else {
                        this.$store.commit('setUsePatientContact',false);
                   }
                   if(ehrFeaturesList[0].ehrFeatures.includes('ASAM') && this.isUseAsam) {
                        this.$store.commit('setUseASAM',true);                      
                   } else {
                        this.$store.commit('setUseASAM',false);
                   }
                   if(ehrFeaturesList[0].ehrFeatures.includes('CLINICAL_NOTESUS')) {                    
                        this.$store.commit('setUseClinicalNotes',true);
                   } else {
                        this.$store.commit('setUseClinicalNotes',false);
                   }
                   if(ehrFeaturesList[0].ehrFeatures.includes('VITAlSIGNS')) {                    
                         this.$store.commit('setUseVitalSign',true);
                   } else {
                         this.$store.commit('setUseVitalSign',false);
                   }
                   if(ehrFeaturesList[0].ehrFeatures.includes('PATIENTALLERGIES')) {                    
                         this.$store.commit('setUsePatientAllergies',true);
                   } else {
                         this.$store.commit('setUsePatientAllergies',false);
                   }
                   if(ehrFeaturesList[0].ehrFeatures.includes('CHART')){
                         this.$store.commit('setUseChartReview',true);
                   }else{
                         this.$store.commit('setUseChartReview',false);
                   }
                   if(ehrFeaturesList[0].ehrFeatures.includes('PATIENTDOCUMENT')){
                    this.$store.commit('setUsePatientDocument',true);
                    }else{
                         this.$store.commit('setUsePatientDocument',false);
                    }

                    if(ehrFeaturesList[0].ehrFeatures.includes('NEWCROP')){
                         this.$store.commit('setUseEPharmacyNewCrop',true);
                         }else{
                              this.$store.commit('setUseEPharmacyNewCrop',false);
                         }
                    if(ehrFeaturesList[0].ehrFeatures.includes('COMPLETEDRAFT')) {
                         this.$store.commit('setCompleteDraft',true);
                    } else {
                         this.$store.commit('setCompleteDraft',false);
                    }
                    if(ehrFeaturesList[0].ehrFeatures.includes('SCHED_CLINICAL')) {
                         this.$store.commit('setSchedulerClinical',true);
                    } else {
                         this.$store.commit('setSchedulerClinical',false);
                    }
                    if(ehrFeaturesList[0].ehrFeatures.includes('APPOINTMENTS')) {
                         this.$store.commit('setAppointments',true);
                    } else {
                         this.$store.commit('setAppointments',false);
                    }
                    if(ehrFeaturesList[0].ehrFeatures.includes('TREATMENTPLAN')){
                         this.$store.commit('setTreatmentPlanClinical',true);
                    }else{
                         this.$store.commit('setTreatmentPlanClinical',false);
                    }
                } else {
                    this.setAllPermissions(true)
                }
               }
                 })
                    .catch((error) => {
                  if(error.response) {
                   this.showMessage("Permissions",error,"error");
                  }
                });
               //un-comment to see all local state
               // console.log(`state`, this.$store.state) 
         },

         setAllPermissions(value){
            this.$store.commit('setUsePatientInfo',value);
            this.$store.commit('setUsePatientInsurance',value);
            this.$store.commit('setUsePatientContact',value);
            this.$store.commit('setUseASAM',value);                                         
            this.$store.commit('setUseClinicalNotes',value);
            this.$store.commit('setUseVitalSign',value);
            this.$store.commit('setUsePatientAllergies',value);
            this.$store.commit('setUsePatientAdmission',value);
            this.$store.commit('setUseChartReview',value);
            this.$store.commit('setUseTransfer',value);
            this.$store.commit('setUseDischarge',value);
            this.$store.commit('setUsePatientDiagnosis',value);
            this.$store.commit('setUsePatientDocument',value);      
            this.$store.commit('setDailyNotes',value); 
            this.$store.commit('setLogNotes',value); 
            this.$store.commit('setUseEPharmacyNewCrop',value); 
            this.$store.commit('setUseMedications',value);
            this.$store.commit('setCompleteDraft',value);
            this.$store.commit('setAppointments',value); 
            this.$store.commit('setSchedulerClinical',value); 
            this.$store.commit('setTreatmentPlanClinical',value);            
         },

         setAllReadOnly(value){
            this.$store.commit('setASAMEHRReadOnly',value);
            this.$store.commit('setPatientInfoEHRReadOnly',value);
            this.$store.commit('setPatientInsuranceEHRReadOnly',value);
            this.$store.commit('setPatientContactsEHRReadOnly',value);
            this.$store.commit('setClinicalNotesEHRReadOnly',value);
            this.$store.commit('setVitalSignEHRReadOnly',value);
            this.$store.commit('setPatientAllergiesEHRReadOnly',value);
            this.$store.commit('setPatientAdmissionEHRReadOnly',value);
            this.$store.commit('setClinicalTransferEHRReadOnly',value);
            this.$store.commit('setClinicalMedicationsEHRReadOnly',value);
            this.$store.commit('setClinicalDischargeEHRReadOnly',value);
            this.$store.commit('setPatientDiagnosisEHRReadOnly',value);
            this.$store.commit('setPatientDocumentEHRReadOnly',value);
            this.$store.commit('setSchedulerClinicalEHRReadOnly',value);
            this.$store.commit('setAppointmentsEHRReadOnly',value);
            this.$store.commit('setTreatmentPlanEHRReadOnly',value);
            this.$store.commit('setEPrescribingEHRReadOnly',value);              
         },

         isNoteReadOnly(NotesId){
          let isReadOnly = false;
          this.EHRReadOnlyList.forEach(element => {
               if(element.notesid === NotesId){
                 isReadOnly = element.isReadOnly
               }
          });
          return isReadOnly
         }
    }
}