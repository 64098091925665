import ApiService from '@/services/api.service'

const EHRFeatures = {
    getByUsers( parameter) { 
      const requestData = {
        method: 'get',
        url: "/EHRFeaturesByUsers/",
        headers: {
        },
        params: { 
          SearchCriteria: parameter.SearchCriteria,
          PageSize: parameter.PageSize,          
          PageNumber : parameter.PageNumber,
          UserId : parameter.UserId
        }
      }     
      return ApiService.customRequest(requestData);         
    },
    
    getReadOnlyByUsers( parameter) { 
      const requestData = {
        method: 'get',
        url: "/EHRFeaturesReadOnlyByUsers/",
        headers: {
        },
        params: { 
          UserId : parameter.UserId,
          NoteId : parameter.NoteId
        }
      }     
      return ApiService.customRequest(requestData);         
    },
  }
  
  export default EHRFeatures;