import ApiService from '@/services/api.service'

const PriorAuthorizationService = {
    getAll( parameter) {          
      const requestData = {
        method: 'get',
        url: "/PriorAuthorizations",
        headers: {
        },
        params: { 
          SearchCriteria: parameter.SearchCriteria,
          PatientCode:parameter.PatientCode,
          InsuranceCode:parameter.InsuranceCode,
        /*  PageSize: parameter.PageSize,          
          PageNumber : parameter.PageNumber,*/
        }
      }     
      return ApiService.customRequest(requestData);         
    },

    getPrior() {
        return ApiService.get(`/PriorAuthorizations`);
    },
  
    get(id) {
      return ApiService.get(`/PriorAuthorizations/${id}`);
    },

  
}
  
export default PriorAuthorizationService;