<template>	
    <div id="DivModalClaimDetail1">
    <b-modal id="modalIdClaimDetail1" size="xl" centered scrollable  :title="TitleWindow" @ok="this.emitEventOK" @cancel="this.emitEventCancel"> 
        <MdTableClaimBatchDetail
              v-bind:fields="fields"
              v-bind:items="items"
              v-bind:totalRows="totalRows"  
              v-bind:refTable="refTable"           
              v-bind:showMessageIsEmpty="ShowMessageIsEmpty"
              v-bind:currentPage="currentPage"
              v-bind:perPage="perPage" 
              :RowClick="RowClickedDetail"              
              v-bind:pageOptions="pageOptions" 
              :onRowSelected="onRowSelected" 
              :onFiltered="onFiltered"
              :pageChanged="pageChanged"
              :perPageFunction="PerPageFunction">
            </MdTableClaimBatchDetail>
    </b-modal>   
    </div>   
</template>

<script>
//import EnumService from '@/services/enum.service'
//import Utils from '../../../common/utils'
import ClaimBatch from '@/services/claimbatch.service';
import DBFunctions from '@/common/DBFunctions.js'
import { EventBus } from '../../../event-bus.js';
import Utils from '@/common/utils'
export default {
  name: 'ModalClaimDetail',
    props: {     
     TitleWindow:String,
     AttendanceDetailList:Array
   },
    mixins: [DBFunctions],
    data() {
		return {
      RecId:null,
      parameter:{
        SearchCriteria:'',
        PageNumber : null,
        PageSize:null , 
        RecNoList:0      
      },
      SelectAllRecords:false,
      isSelected:false,
      refTable:'refTableModalClaimDetail11',
      ShowMessageIsEmpty:true,
      pageOptions: [5, 10, 15 ],
      totalRows: 0,      
      perPage: 10,
      filter: null,
      VisibleTest:true,
       selected: 0,
       options: ['Yes','No'],
        resultList: [],
        anwers: '',		
      fields: [  
        { key: 'selected', sortable: true}, 
        { key: 'transdate', sortable: true, label: this.$t('Shared.Date'), class: 'text-rigth' },
        { key: 'coursecode', sortable: true, label: this.$t('MdNote.Therapy'), class: 'text-rigth' },      
        { key: 'unit', sortable: true, label: this.$t('HouseFile.Unit'), class: 'text-rigth' },
        { key: 'Code',  sortable: true,label: this.$t('Shared.Code'), class: 'hidden text-rigth' },
        { key: 'attendance', sortable: true, label: this.$t('modalClaimDetail.Attendance'), class: 'text-rigth',formatter: value => {return value ? 'Y' : 'N'} },
        { key: 'billed', sortable: true, label: this.$t('modalClaimDetail.Billed'), class: 'text-rigth',formatter: val => {return val ? 'Y' : 'N'} },
        { key: 'therapistcode', sortable: true, label: this.$t('modalClaimDetail.TherapistCode'), class: 'hidden text-rigth' },       
      ],
      items: [],
      currentPage: 1,
		}
	},

	async mounted() {    
   this.getCallApi();	
      /*this.$root.$on('bv::modal::shown', () => {   
        if(this.AttendanceDetailList.length > 0){
          this.$refs[this.refTable].selectAllRows();
        }    
        
      });*/
      this.$root.$on('bv::modal::show', () => {
          
        //console.log('Se Mostro el modal detail');
        //this.currentPage = 1;     
        this.$nextTick(() => {
        // this.$refs[this.refTable].selectAllRows();
      
        });
      });
   
	},

  created(){ 
    EventBus.$on("onAttendanceDetail", function (data) { 
     // console.log(data.row.selected);
     // console.log(data.AttendanceDetailListSelected);
      this.isSelected = data.row.selected;
      this.RecId = data.RecId;
      this.getDetail(data.AttendanceDetailListSelected);         
     }.bind(this));
  },
	methods:{

    ViewIcon(){

    },

    RowClickedDetail(item){     
        if(item.selected){      
          this.$set(item, 'selected', false);         
        }else{
          this.$set(item, 'selected', true);       
        }
        EventBus.$emit('rowWasClicked', item);
    },

    getAllRecNo(arr){
    var l = arr.length;
     var recnolist = '';
     if(l>0){
       var i = 0;
        arr.forEach(function(e) {         
        if(i<l-1){
          recnolist += 'RecNoList='+e.recNo+"&";
        }else{
          recnolist += 'RecNoList='+e.recNo;
        }
        i++;                    
        });
        this.parameter.RecNoList = recnolist;
    }else{
      this.parameter.RecNoList = '';
    } 
    },

    MergeSelectedLists(arrList){
      for(var i = 0; i < arrList.length; i++){
        this.items[i].selected = arrList[i].selected;
      }
    },

    getDetail(attendanceList){
     // this.parameter.PageSize = this.perPage;
      this.getAllRecNo(attendanceList);
       ClaimBatch.getAllAttendances(this.parameter)
                .then((response) => {
                     if(response.status == '200' && response.data){
                       this.items = response.data;                      
                        let headerString = response.headers["x-pagination"];
                        this.Header = JSON.parse(headerString);
                        this.totalRows = this.Header.TotalCount;  
                        
                        this.items.forEach(function(element) {
                          if (element.transdate)	
                              element.transdate = Utils.formatterDateToString(element.transdate); 
                     
                        });
                        this.MergeSelectedLists(attendanceList);  
                        
                     }               
                })
                .catch((error) => {
                    if (error.response) {
                        this.showMessage(this.$t('modalClaimDetail.Attendance'),error,"error");
                    }
                });  
    },

    getCallApi(){
       //this.items = [{'Code':1,'Description':'Error Test Error Test Error Test Error Test'},{'Code':2,'Description':'Error Test Error Test Error Test Error Test'}];
       //this.fillResultList(this.items);       
    },

    handleOk(bvModalEvt) {      
        bvModalEvt.preventDefault();      
        this.handleSubmit()
      },

    onRowSelected(items) {      
   console.log(items);
    },

    onFiltered() {
    },

    pageChanged(page){ 
       this.currentPage = page;  
       //this.parameter.PageNumber = page;
       this.getDetail(this.AttendanceDetailList);  
    },

    PerPageFunction(page){ 
      this.perPage = page;        
      this.currentPage = 1;
      this.getDetail(this.AttendanceDetailList);   
    },

    fillResultList(items){
      this.resultList = ( items != undefined) ? items.map(function(item){ 
            let optionItem = {};
            optionItem['Code'] = item.Code;
            optionItem['sectiondesc'] = item.Description;
            optionItem['value'] = -1;
            return optionItem;
      }): null;
    },

     SetTest(){
      let itemSel = this.selected;
      let answered = 0 ; 
      this.resultList.map(function(item){ 
        if(item.Code == itemSel){
            item.value = Number(1);
        }
        if(item.value != -1) {
          answered = answered + 1;         
        }
       // return item;
      })
      this.answered = answered;
   
    },

    getAction(){
     
    },

    isAnswered(Code){
      let answeredItem = false;
      if(this.resultList.length > 0){
        this.resultList.forEach(element => {
        if(element.Code == Code && element.value > -1) {
          answeredItem = true
        }
      });
      }      
      return answeredItem;
    },

     trClassFn(item, rowType){
      if (rowType === 'row' && this.isAnswered(item.Code)) {
        return "answered";
      }
    },

    emitEventCancel(){
        //setTimeout(() => this.ResetObjOccurrence(),200); 
      }, 
      handleSubmit() {
        // Exit when the form isn't valid
        if (!this.checkFormValidity()) {
          this.emitEventOK();
          return
        }
        this.emitEventOK();
      },
     emitEventOK(){   
         var data = {
           items:this.items,
           recId:this.RecId
         }     
         EventBus.$emit('onUpdatedListbyRecNo', data);
      },
	},

}


</script>


