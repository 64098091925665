<template>
  <div id="ContainerEmployeeInbox">
    <div class="row">
      <div class="col-12">
      <h4 class="page-title text-truncate">
					{{ $t('EmployeeInbox.EmployeeInbox') }}
			</h4>
        <div class="row">
          <b-col md="3" lg="3" sm="12">
            <MdEdit 
                v-bind:Label="$t('Shared.datefrom')"
                v-bind:TypeProp="'date'" 
                v-bind:Name="'DateFrom'"
                v-model="DateFrom"
                v-bind:Min="'1900-01-01'"
                v-bind:Max="'2200-12-31'"
                v-on:change="DateFromChange($event)">                                     
            </MdEdit>
          </b-col>
          <b-col md="3" lg="3" sm="12">
            <MdEdit 
                v-bind:Label="$t('Shared.dateTo')"
                v-bind:TypeProp="'date'" 
                v-bind:Name="'DateTo'"
                v-model="DateTo"
                v-bind:Min="'1900-01-01'"
                v-bind:Max="'2200-12-31'"
                v-on:change="DateToChange($event)">                                     
            </MdEdit>
          </b-col>
          <b-col md="3" lg="3" sm="12">  
            <MdSelect
              v-bind:Label="$t('Shared.Type')"
              v-bind:Options="NoteTypesList"
              v-bind:FieldDescription="'name'"
              v-bind:FieldCode="'code'"
              v-bind:Model="ModelNoteType"
              v-bind:Multiple="false"              
              :Input="ChangeDropDownNodeType"
              v-bind:isValid="this.isNoteTypeValid"
              />
          </b-col>
          <b-col md="3" lg="3" sm="12">  
            <MdSelect
              v-bind:Label="$tc('Shared.Patient')"
              v-bind:Options="patientOptions"
              v-bind:FieldDescription="'PatientName'"
              v-bind:FieldCode="'patientcode'"
              v-bind:Model="patient"
              v-bind:Multiple="true"              
              :Input="ChangeDropDownPatients"
              />
          </b-col>
        </div>
        <div class="row">
          <b-col md="3" lg="3" sm="12">
            <MdSelect
              v-bind:Label="$tc('Shared.Employee')"
              v-bind:Options="employeeOptions"
              v-bind:FieldDescription="'fullName'"
              v-bind:FieldCode="'code'"
              v-bind:Model="employee"
              v-bind:Multiple="true"              
              :Input="ChangeDropDownEmployee"
              />
          </b-col>
          <b-col md="3" lg="3" sm="12">
            <MdSelect
              v-bind:Label="$tc('Shared.Program')"
              v-bind:Options="programOptions"
              v-bind:FieldDescription="'name'"
              v-bind:FieldCode="'code'"
              v-bind:Model="program"
              v-bind:Multiple="true"              
              :Input="ChangeDropDownProgram"
              />
          </b-col>
          <b-col md="3" lg="3" sm="12">  
            <MdSelect
              v-bind:Label="$t('EmployeeInbox.NoteName')"
              v-bind:Options="noteNameOptions"
              v-bind:FieldDescription="'description'"
              v-bind:FieldCode="'notesid'"
              v-bind:Model="noteName"
              v-bind:Multiple="true"              
              :Input="ChangeDropDownNoteName"
              />
          </b-col>
          <b-col md="3" lg="3" sm="12">  
            <MdSelect
              v-bind:Label="$t('Shared.Status')"
              v-bind:Options="statusOptions"
              v-bind:FieldDescription="'name'"
              v-bind:FieldCode="'code'"
              v-bind:Model="ModelNoteType"
              v-bind:Multiple="false"              
              :Input="ChangeDropDownNodeType"
              v-bind:isValid="this.isNoteTypeValid"
              />
          </b-col>
        </div>            
        </div>
    </div>
    <div class="row">
      <div class="col-12 mt-2">
        <MdTitle v-bind:Text="$t('Shared.SearchResult')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
        <div v-if="showEmptyRecords" v-bind:style="emptyRecordsStyle" role="alert" aria-live="polite"><div class="text-center my-2">There are no records to show</div></div>
        <div v-for="(item,index) in this.DailyNotesList" :key="index">
          <div class="row dailyNotes">
            <div class="col-2 mt-1">
              <b-icon v-if="item.employeeCode == UserId" @click="Edit(item)" icon="pencil-fill" v-b-tooltip.hover.top="$t('Shared.Edit')" class="rounded-circle tableActionBTN mr-2" scale=".5" font-scale="1.6" variant="light"></b-icon> 
              <b-icon icon="printer-fill" @click="showReport(item)" v-b-tooltip.hover.top="$t('Shared.Report')" class="rounded-circle tableActionBTN mr-2" scale=".5" font-scale="1.6" variant="light"></b-icon> 
              <b-icon icon="slash-circle" @click="Void(item.recNo, item.isVoid)" v-b-tooltip.hover.top="$t('EmployeeInbox.UnVoid')" class="rounded-circle tableActionBTN mr-2" scale=".5" font-scale="1.6" variant="light"></b-icon> 
            </div>
            <div class="col-4">
              <small>{{ $t('EmployeeInbox.DateAndTime') }}</small><br>{{item.serviceDate}}
            </div>
            <div class="col-3">
              <small>{{ $tc('Shared.Employee') }}</small><br>{{item.employeeName }}
            </div>
            <div class="col-3">
              <small>{{ $t('Shared.NoteType') }}</small><br>{{item.noteTypeName }}
            </div>
          </div>
          
          <div v-if="item.isVoid" class="alert alert-fill-warning" style="margin:10px 20px" role="alert">
                <i class="mdi mdi-alert-circle"></i> {{ $t('Msg.NoteVoied') }} {{item.reasonName}}
          </div>
          <div class="row dailyNotesContent">
            <div v-html="item.comments" class="col-12"></div>
          </div>
        </div>
      </div>
    </div>
    <hr/>
    <div class="row mt-4">
      <div class="my-1 col-sm-7 col-md-6">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          @change="onChange"
          pills
          size="lg"
        ></b-pagination>
      </div>
      <div align="right" class="mr-2 col">
        {{ $t('Shared.Show') }}
        <b-form-select 
          v-model="perPage" 
          :options="options" 
          size="sm" 
          id="perPageSelect"
          v-bind:style="perPageStyle"
          @change="perPageChange">
        </b-form-select>
        of {{totalRows}}
      </div>
    </div>

    <div class="space-actions"></div>

  </div>
</template>



<script>
import EnumService from '@/services/enum.service' 
import ShowMessage from '@/components/messages/ShowMessage.js'
import DBFunctions from '@/common/DBFunctions.js'
import DailyNotesService from '@/services/dailynotes.service'
import Utils from '@/common/utils'
import NotesTitlesService from '@/services/notestitles.service'
import LocalStorage from '@/services/storage.service';
import UserPreference from "@/services/quickhelp.service"

import PatientService from '@/services/patient.service'
import TherapistService from '@/services/therapist.service'
import ProgramService from '@/services/programs.services'
import GlobalService from '@/services/global.service'
export default {

  beforeRouteEnter(to, from, next) {
        next(async vm => {
            const iUA = await vm.$store.state.isUseDailyNotes;
            if(iUA) next()
            else next('/accessdenied');
        }) 
  },

  mixins: [ShowMessage, DBFunctions],
  name: 'EmployeeInbox',

  data() {
    return {
      Loading:true,
      OptionsRightMenu:{
            visible:true,
            ItemsList:[],
            btnvisible:true,
            itemdisabled: false,
            PatientCode:''         
      },
      NotesTitlesParams:{
        SearchCriteria:'',
        PageNumber : 0,
        PageSize:0,      
      },
      DailyNotesParams:{
        SearchCriteria:'',
        PageNumber : 3,
        PageSize: 3,
        RecordId:null,
        NoteType:null        
      },                        

      isReadOnly: false,
      RegistrationInfo:{},
      NoteTypesList: [],
      ModelNoteType: null,
      DailyNotesList: [],
      UserId: LocalStorage.getUserId(),
      DailyNotesPreference: {'lastDailyNote':''},
      isNoteTypeValid: true,
      DailyNotesIsVoid: {'isVoid':'','reasonName':''},
      showReason: false,
      idDailyNote: null,
      canInsert: false,
      AddButtonDisabled: false,
      emptyRecordsStyle: {
        backgroundColor: '#CF0',
        fontWeight: 'bold !important',
        textTransform: 'uppercase',
        padding: '2px',
        borderRadius: '50px',
        animation: 'fadeOut 10s ease-in-out infinite'
      },
      showEmptyRecords: false,
      currentPage: 1,
      perPage: 5,
      totalRows: 0, 
      Header:{},
      
      options: [
        { value: 5, text: '5' },
        { value: 10, text: '10' },
        { value: 15, text: '15' },
      ],
      statusOptions: [
        { code: 'new', name: this.$t('EmployeeInbox.StOpts.new') },
        { code: 'read', name: this.$t('EmployeeInbox.StOpts.read') },
        { code: 'newnotes', name: this.$t('EmployeeInbox.StOpts.newnotes') },
        { code: 'all', name: this.$t('Shared.All') },
      ],
      parameter:{
        SearchCriteria:'',
        PageNumber : 0,
        PageSize:0,
        Status: true,       
      },
      patient: null,
      employee: null,
      program: null,
      noteName: null,
      patientOptions: [{}],
      employeeOptions: [{}],
      programOptions: [{}],
      noteNameOptions: [{}],
      perPageStyle: {
        width: 'auto'
      },
      recNoToReport: null
    }
  },

  methods: {
    
    ChangeDropDownPatients() {

    },

    ChangeDropDownEmployee() {

    },

    ChangeDropDownProgram() {

    },

    ChangeDropDownNoteName() {

    },

    perPageChange(value) {
      this.perPage = value
      this.getDailyNotes()
    },
    
    onChange(page) {
      this.currentPage = page
      this.getDailyNotes()
    },

    getNoteTypeName() {
      return this.findNoteTypeName(this.NoteTypesList, this.ModelNoteType).name
    },

    Edit(item) {
      this.$router.push({ name: "dailynote-details", params: { id: this.$route.params.id, recordid: this.$route.params.recordid, dailynoteid: item.recNo, noteTypeName: item.noteTypeName } });
    },

    Void(id, isVoid) {
      this.idDailyNote = id

      if (!isVoid)
        this.showReason = true;
      if (isVoid)
        this.setIsVoid('N','')
    },

    findNoteTypeName (array, code) {
      return array.find(element => element.code === code) ?? -1;
    },

    getListing() {
      this.$emit('load', true);
      this.currentPage = 1
      this.getDailyNotes()
    },

    async setIsVoid(value, reason) {
      this.DailyNotesIsVoid.isVoid = value
      this.DailyNotesIsVoid.reasonName = reason
      this.$emit('load', true);
      await this.UpdateIsVoid(this.idDailyNote)
      this.getDailyNotes()
    },

    ChangeDropDownNodeType(value) {
      this.ModelNoteType = value ? value.code : ''
      this.DailyNotesParams.NoteType = value ? value.code : ''
      this.UpdatePreference(value ? value.code : null)
      this.isNoteTypeValid = value ? true : ''
      this.$emit('load', true);
      this.AddButtonDisabled = value && value.canInsert == true ? false : true
      this.getDailyNotes()
    },

    async getUserPreference() {
      await UserPreference.getQhUserPreference(this.UserId)
        .then((response) => {
          if (response.data && response.data.length > 0) {
            const LastDailyNote = response.data[0].lastDailyNote ;
            const NoteTypeObject = this.findNoteTypeName(this.NoteTypesList, LastDailyNote)
            this.AddButtonDisabled = LastDailyNote && NoteTypeObject.canInsert == true ? false : true
            if (LastDailyNote) {
              this.DailyNotesParams.NoteType = LastDailyNote
              this.ModelNoteType = LastDailyNote
            }
          } 
        })
        .catch((error) => {
          if (error.errorMessage) {
            this.showMessage(this.$t('DailyNoteList.DailyNotesTitle'), error, "error");
          }
        });
    },
    
    async getNotesTitles() {
      await NotesTitlesService.getAll(this.NotesTitlesParams)
        .then((response) => {
          if (response.status == "200" && response.data && response.data.length > 0) {
            this.NoteTypesList = response.data;
          }
        })
        .catch((error) => {
          if (!error.response) {
            // network error
            this.showMessage(
              this.$t('DailyNoteList.DailyNotesTitle'),
              this.$t('Msg.retrieveInfo'),
              "error"
            );
          } else {
            this.showMessage(this.$t('DailyNoteList.DailyNotesTitle'), error, "error");
          }
        });
    },

    async getDailyNotes() {
      this.DailyNotesParams.PageNumber = this.currentPage;
      this.DailyNotesParams.PageSize = this.perPage;
      this.DailyNotesParams.RecordId = this.$route.params.recordid;
      await DailyNotesService.getAll(this.DailyNotesParams)
        .then((response) => {
          if (response.status == "200" && response.data) {
            this.DailyNotesList = response.data;
            let headerString = response.headers["x-pagination"];
            this.Header = JSON.parse(headerString);
            this.totalRows = this.Header.TotalCount;
            this.showEmptyRecords = response.data && response.data.length == 0 ? true : false
            this.DailyNotesList.forEach(function (element) {
              if (element.serviceDate)
                element.serviceDate = Utils.formatterDateToString(element.serviceDate) + ' ' +
                                      Utils.formatTime(element.serviceTime); 
            });
            this.$emit("load", false);
          }
          this.$emit("load", false);
        })
        .catch((error) => {
          this.$emit("load", false);
          if (!error.response) {
            // network error
            this.showMessage(
              this.$t('DailyNoteList.DailyNotesTitle'),
              this.$t('Msg.retrieveInfo'),
              "error"
            );
          } else {
            this.showMessage(this.$t('DailyNoteList.DailyNotesTitle'), error, "error");
          }
        });
    },

    UpdatePreference(value){
      this.DailyNotesPreference.lastDailyNote = value
      UserPreference.UpdateLastDailyNotes(this.UserId, this.DailyNotesPreference)
        .catch((error) => {
          if (!error.response) {
            this.showMessage(this.$t('DailyNoteList.DailyNotesTitle'),this.$t('Msg.NotAbleToUpdate'),"error");
          }          
          else if (error.response.data && error.response.data.errorMessage) {
            this.showMessage(this.$t('DailyNoteList.DailyNotesTitle'),error.response.data.errorMessage,"error");
          }
          else {
            this.showMessage(this.$t('DailyNoteList.DailyNotesTitle'),this.$t('Msg.ThereWereSomeError'),"error");
          }
        });
    },

    async UpdateIsVoid(id){
      await DailyNotesService.updateIsVoid(id, this.DailyNotesIsVoid)
        .catch((error) => {
          if (!error.response) {
            this.showMessage(this.$t('DailyNoteList.DailyNotesTitle'),this.$t('Msg.NotAbleToUpdate'),"error");
          }          
          else if (error.response.data && error.response.data.errorMessage) {
            this.showMessage(this.$t('DailyNoteList.DailyNotesTitle'),error.response.data.errorMessage,"error");
          }
          else {
            this.showMessage(this.$t('DailyNoteList.DailyNotesTitle'),this.$t('Msg.ThereWereSomeError'),"error");
          }
        });
    },

    getPatients() { 
      PatientService.getAll(this.parameter)
        .then((response) => {
          if(response.status == '200' && response.data && response.data.length > 0){
            this.patientOptions = response.data;
            this.patientOptions.forEach(function(element) {
              element.PatientName = element.firstname + " " + element.lastname;   
            });
          }
        })
        .catch(error => {
          if (!error.response) {
            // network error
            this.showMessage(this.$tc('Shared.Patient',2),this.$t('Msg.RetrieveInformation'),"error");
          } else {
            this.showMessage(this.$tc('Shared.Patient',2),error,"error");
          }
        });
    },

    getTherapists(){
      TherapistService.getAllTherapist()
          .then((response) => { 
            if(response.data && response.data.length > 0){   
              let arr = response.data;
              for(var i = 0; i<arr.length; i++){
                arr[i].fullName = arr[i].firstName+" "+arr[i].lastName;
              }
              this.employeeOptions = arr;
            }
          })
          .catch(error => {
            if (!error.response) {
            // network error
              this.showMessage(this.$tc('Shared.Employee',2),this.$t('Msg.RetrieveInformation'),"error");
            } else {
              this.showMessage(this.$tc('Shared.Employee',2),error,"error");
            }
          });  
    },

    getPrograms(){
      ProgramService.getAll(this.parameter)
        .then((response) => { 
          if(response.data && response.data.length > 0){   
            this.programOptions = response.data;
          }
        })
        .catch(error => {
            if (!error.response) {
            // network error
              this.showMessage(this.$tc('Shared.Program',2),this.$t('Msg.RetrieveInformation'),"error");
            } else {
              this.showMessage(this.$tc('Shared.Program',2),error,"error");
            }
        });  
    },

    getNoteTypes(){
        GlobalService.getNoteTypes(true)
          .then((response) => { 
            if(response.data && response.data.length > 0){   
              this.noteNameOptions = response.data;             
            }
          })
          .catch(error => {
            if (!error.response) {
            // network error
              this.showMessage(this.$t('Shared.NoteType'),this.$t('Msg.RetrieveInformation'),"error");
            } else {
              this.showMessage(this.$t('Shared.NoteType'),error,"error");
            }
          });  
      },

  },

  async mounted() {
    this.$emit('load', true);
    this.OptionsRightMenu.visible = true;
    this.OptionsRightMenu.ItemsList = EnumService.ClinicalItems
    this.$emit('getVisibleMenu', this.OptionsRightMenu);
    this.$emit('getBtnRightMenu', this.OptionsRightMenu);
    this.isReadOnly = this.$store.state.isDailyNotesEHRReadOnly;
    this.getPatients()
    this.getTherapists()
    this.getPrograms()
    this.getNoteTypes()
    await this.getNotesTitles()
    await this.getUserPreference()
    await this.getDailyNotes() 
  },
};

</script>