import store from '@/globalvar.js'

// const TOKEN_KEY = 'access_token'
// const PROVIDER_KEY = 'provider'
// const PROVIDERCODE_KEY = 'providercode'
// const COMPANIES = 'Companies'
// const EMPLOYEECODE = 'EmployeeCode'
//const ETHNICITYLIST = 'EthnicityList'

const StorageService = {
    getToken() {
      const token = store.state.access_token
      // const token = localStorage.getItem(TOKEN_KEY); 
      return token;  
    },

    // saveToken(accessToken) {
    //   localStorage.setItem(TOKEN_KEY, accessToken);
    // },

    // saveEmployeeCode(data) {
    //   localStorage.setItem(EMPLOYEECODE, data);      
    // },

    // getEmployeeCode() {
    //   return localStorage.getItem(EMPLOYEECODE);      
    // },

    // removeToken() {
    //     localStorage.removeItem(TOKEN_KEY)
    // },

    //not used
    // getRefreshToken() {
    //     return localStorage.getItem(REFRESH_TOKEN_KEY)
    // },

    getOnlyToken(){
      var tokenObj = this.getToken();
      if (tokenObj) {
        var JsonParse = JSON.parse(tokenObj);
        if (JsonParse && JsonParse.token) {
          return JsonParse.token.authToken;
        }
      }
      return null;
    },

    getName(){
      var tokenObj = this.getToken();
      if (tokenObj) {
        var JsonParse = JSON.parse(tokenObj);
        if (JsonParse) {
          return  JsonParse.firstname + " "+ JsonParse.lastname;
        }
      }
      return null;
    },

    IsSupervisor(){     
      var tokenObj = this.getToken();
      var JsonParse = JSON.parse(tokenObj);
      return JsonParse ? JsonParse.isSupervisor : false;
    },

    getUserId(){
      var tokenObj = this.getToken();
      if (tokenObj) {
        var JsonParse = JSON.parse(tokenObj);
        if (JsonParse) {
            return JsonParse.userid;
        }
      }
      return null;        
    },

    //not used
    // saveRefreshToken(refreshToken) {
    //     localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken)
    // },
    //not used
    // removeRefreshToken() {
    //     localStorage.removeItem(REFRESH_TOKEN_KEY)
    // },

    // saveProvider(providercode, provider) {
    //     localStorage.setItem(PROVIDERCODE_KEY, providercode);
    //     localStorage.setItem(PROVIDER_KEY, provider);
    // },

    // getProvider() {
    //     return localStorage.getItem(PROVIDER_KEY);      
    // },

    // getProviderCode(){
    //   return localStorage.getItem(PROVIDERCODE_KEY);      
    // },

    getClientId(){
      var tokenObj = this.getToken();
      if (tokenObj) {
        var JsonParse = JSON.parse(tokenObj);
        if (JsonParse) {
            return JsonParse.clientId;
        }
      }
      return null;        
    },

}

export default StorageService