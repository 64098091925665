<template>
    <div>
    <b-row>
      <b-col md="12">
        <div class="text-center text-md-right">
					<button v-on:click="Clone()"
						type="button"
						class="btn btn-primary btn-login mx-2 pull-right">
						{{$t('MdTableTypeOfBill.CloneTypeOfBill')}}
					</button>
				</div>
      </b-col>
    </b-row>
      <b-row >
    <b-col md="12"> 
         <!--<div class="table-notes-title">
              <div class="text-truncate t-header">{{this.Title}}</div>
              <div class="btn-group btn-group-sm float-sm-right" role="group" aria-label="Table options">               
                <b-button size="sm" @click="Clone()" class="table-title-btn">Clone Type Of Bill</b-button>                      
              </div>
            </div> --> 

           
			

    <b-table
    :items="items"
    :fields="fFields"
    responsive
    :per-page="perPage"
    class="medezTables cutescroll mt-1"    
    :show-empty="showMessageIsEmpty"
    :current-page="currentPage"
    @row-selected="rowSelected"                  
    :tbody-tr-class="tbodyRowClass"
    :id="refTable"

    >

    <template v-slot:cell(typeOfBill)="row">
              <input 
                type="number" 
                v-on:input="Input" 
                v-model="row.item.typeOfBill" 
                pattern="/^-?\d+\.?\d*$/" 
                onKeyPress="if(this.value.length==3) return false;" 
                style="width:100%;" 
              />
            </template>

    </b-table>
       <!-- <b-row>
      <b-col v-if="pageOptions" sm="7" md="6" class="my-1">
        <b-pagination 
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
          @change="fpageChanged"
        ></b-pagination>
      </b-col>
      <b-col v-if="pageOptions" align="right" class="mr-2">
          <b-form-group
            >{{ $t('Shared.Show') }}
              <b-form-select
                v-model="perPage"
                id="perPageSelect"
                size="sm"
                :options="pageOptions"
                style="width:auto;"
                @change="pageFunction($event)"
              ></b-form-select>
              of {{totalRows}}
            </b-form-group>
        </b-col>
      </b-row>-->
        </b-col>
      </b-row>
    </div>
   

  
</template>

<script>

import { EventBus } from '../../../event-bus.js';
export default {
  name: 'MdTablePaginationClient',
   props: {
     fields:Array,    
     items:  Array, 
     perPageFunction: Function,
     pageChanged: Function,   
     totalRows:Number,  
     onRowSelected: Function, 
     editIconEvent: Function,
     deleteIconEvent: Function, 
     ShowDetails:Boolean,
     showMessageIsEmpty:Boolean,
     otherIconEvent: Function,
     otherIcon: String,
     viewIconEvent: Function,
     Error: Function,
     Warning: Function,
     refTable: String,
     RowClick:Function,   
   },
    mixins: [EventBus],
  data(){
    return{  
        pageOptions: [5,10,15,20,30,40,50],
        perPage: 10,
        itemlocal:this.items,
        cPage:1,
        currentPage: 1,       
         filter: null,
          filterOn: [],
          Title:'',
          pPage: this.perPage,         
          fFields:this.fields,  
          lastitem:"",      
          ModelSelect:{
            
          },
          TableIsReadOnly:false,
          TableIsrequired:false,       
          fMultiSelect: 'single',
        
        
          PositionsByRows:[],
          ColumnsCalulated:[],         
          isNewRecord: false,
          index: -1   
    }
  },

  methods: {
    tbodyRowClass(item) { 
      if(item != null){
         if(item.selected){
          return ["b-table-row-selected", "table-active", "cursor-pointer"]
        } else {
          return ["cursor-pointer"]
        }
      }
      },
      
    rowSelected(items) {     
      this.onRowSelected(items);
    },

    LocalrowClicked(item){
      this.RowClick(item);
    },

    viewButton(row) {   
      this.viewIconEvent(row);
    },
  Input(w){
    this.lastitem = w.target.value;
    this.$emit('input', w.target.value);
  },

    Clone(){
      var l = this.items.length;
      for(var i = 0; i < l; i++){
        if(this.lastitem){
          this.items[i].typeOfBill = this.lastitem;
        }       
      }
    },

    ErrorLocal(row){
      this.Error(row);
    },

    WarningLocal(row){
      this.Warning(row);
    },


     editButton(row) {
        this.editIconEvent(row);
    },

    deleteButton(row) {
        this.deleteIconEvent(row);
    },
    selectRow(index){
      this.onSelectRow(index);
    },
    filtered(filteredItems) {
        this.onFiltered(filteredItems);
    },

    fpageChanged(page) {
        this.pageChanged(page);
    },
     fpageChangedSubItems(page) {
        this.pageChangedSubItems(page);
    },
    pageFunction(perpage) {
        this.perPageFunction(perpage);
    },
    },
  created() { 
     //this.fFields.unshift({ key: 'show_details'}); 
    if(this.editIconEvent || this.deleteIconEvent || this.viewIconEvent || this.otherIconEvent) {
      if(!this.isReadOnly){
        this.fFields.unshift({ key: 'actions', label: this.$tc('Shared.ActionsTxt',2)});
      }      
    }

    EventBus.$on("rowWasClicked", function (e) { 
      this.tbodyRowClass(e);      
     }.bind(this));
  }
}
</script>

