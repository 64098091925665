import ApiService from '@/services/api.service'

const DoctorService = {

    getAll(parameter){
        const requestData = {
            method: 'get',
            url: "/Doctors/",
            headers: {
            },
            params: { 
              SearchCriteria: parameter.SearchCriteria,
              PageSize: parameter.PageSize,          
              PageNumber : parameter.PageNumber            
            }
          }     
          return ApiService.customRequest(requestData);       
    },

    getDoctorByCode(parameter){
      const requestData = {
        method: 'get',
        url: "/Doctors/",
        headers: {
        },
        params: {          
          code: parameter.code           
        }
      }     
      return ApiService.customRequest(requestData);       
    },

    getDoctorByUserId(parameter){
      const requestData = {
        method: 'get',
        url: "/Doctors/",
        headers: {
        },
        params: {          
          userId: parameter.UserId           
        }
      }     
      return ApiService.customRequest(requestData);       
    },

    get(id) {
      return ApiService.get(`/Doctors/${id}`);
    },

    delete(id){
      return ApiService.delete(`/Doctors/${id}`)
    },

    add(data){
      return ApiService.post(`/Doctors`,data)
    },

    update(id,data){
      return ApiService.put(`/Doctors/${id}`,data)
    },

    getDoctorSpecialties(){
        return ApiService.get(`/DoctorSpecialties`);        
    },  
    
    getUserByCode(id){
      //return ApiService.get(`/Users/${id}`);     
      const requestData = {
        method: 'get',
        url: "/Users/",
        headers: {
        },
        params: {          
          Code: id        
        }
      }     
      return ApiService.customRequest(requestData);        
    }
}

export default DoctorService;