import ApiService from '@/services/api.service'


const MedicalSignNotesService = {
    getAll( parameter) { 
         
      const requestData = {
        method: 'get',
        url: "/MedicalSignNotes/",
        headers: {
        },
        params: { 
          SearchCriteria: parameter.SearchCriteria,
          PageSize: parameter.PageSize,          
          PageNumber : parameter.PageNumber,
          HeaderID : parameter.HeaderID,
          NotesID : parameter.NotesID,
          IntakeDate : parameter.IntakeDate
        }
      }     
      return ApiService.customRequest(requestData);         
    },
  
    get( id) {
      return ApiService.get(`/MedicalSignNotes/${id}`);
    },

    add(data){
      return ApiService.post(`/MedicalSignNotes`,data)
    },

    delete(IntakeDate, HeaderId) {
      return ApiService.delete(`/MedicalSignNotes/${IntakeDate}/${HeaderId}`)
    },
    
    update(HeaderId,IntakeDate,MedicalSignNote){
      return ApiService.put(`/MedicalSignNotes/${HeaderId}/${IntakeDate}`,MedicalSignNote)
    },
}
  
  export default MedicalSignNotesService;