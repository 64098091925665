import ApiService from '@/services/api.service'

const ChartReview = {

  getPatientRegistrationsDefaultDates(RecordId){
    return ApiService.get(`/PatientRegistrations/DefaultDates?RecordId=${RecordId}&IsChartReview=true`);  
  },
  

  // Note Type View Listing
  getNoteList(parameter) {
    var param = {};
    var _Url = '/NotesLists/ChartReviewByNote/';
    param.PageSize = parameter.PageSize;
    param.PageNumber = parameter.PageNumber;
    param.PatientCode = parameter.PatientCode;
    param.DateFrom = parameter.DateFrom;
    param.DateTo = parameter.DateTo;   
    param.UserId = parameter.UserId;

    param.NotesIdList = parameter.NotesIdList;
    param.AdmissionRecordIdList = parameter.AdmissionRecordIdList;
    param.TherapistCodesList = parameter.TherapistCodesList;
    param.CoTherapistCodesList = parameter.CoTherapistCodesList;

    if(parameter.NoteStatus !== ""){
      param.NoteStatus = parameter.NoteStatus
    }
   
   
  const requestData = {
    method: 'get',
    url: _Url,
    params: param
  }
  return ApiService.customRequest(requestData);
  },

  getAllDate(parameter) {
    var param = {};
    var _Url = '/NotesListDates/ChartReview/';
    param.PageSize = parameter.PageSize;
    param.PageNumber = parameter.PageNumber;
    param.PatientCode = parameter.PatientCode;
    param.DateFrom = parameter.DateFrom;
    param.DateTo = parameter.DateTo;
    param.UserId = parameter.UserId;
    param.NotesIdList = parameter.NotesIdList;
    param.TherapistCodesList = parameter.TherapistCodesList;
    param.CoTherapistCodesList = parameter.CoTherapistCodesList;

    if(parameter.NoteStatus !== ""){
      param.NoteStatus = parameter.NoteStatus
    }
   
  const requestData = {
    method: 'get',
    url: _Url,
    params: param
  }
  return ApiService.customRequest(requestData);
  },


    
  }
  
  export default ChartReview;