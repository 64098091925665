<template>
  <div>
      <b-row>
        <b-col md="12">     
            <b-table
                :ref="refTable"
                selectable                
                :small="small"
                :fixed="true"
                :sticky-header="stickyHeader"
                class="medezTables cutescroll"
                :select-mode="fMultiSelect"
                :items="items"
                :fields="fFields"
                :sort-compare="Compare"
                :current-page="cPage"
                :show-empty="showMessageIsEmpty"
                @row-selected="rowSelected"
                responsive
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="filtered"
                @row-dblclicked="ondblClicked"
                :tbody-tr-class="trClassFn"
            >     
                <template v-if="editIconEvent || deleteIconEvent || viewIconEvent || otherIconEvent || IconEvent1 || IconEvent2 || IconEvent3" #cell(actions)="row">              
                    <b-icon v-if="editIconEvent && isReadOnly" icon="eye-fill" @click="editButton(row.item)" v-b-tooltip.hover.top="$t('Shared.Edit')" class="rounded-circle tableActionBTN mr-2" scale=".5" font-scale="1.8" variant="light"></b-icon>
                    <b-icon v-if="VisibleEditIcon && editIconEvent && !isReadOnly" icon="pencil-fill" @click="editButton(row.item)" v-b-tooltip.hover.top="$t('Shared.Edit')" class="rounded-circle tableActionBTN mr-2" scale=".5" font-scale="1.8" variant="light"></b-icon>
                    <b-icon v-if="VisibleDeleteIcon && deleteIconEvent && !isReadOnly"  icon="trash-fill" @click="deleteButton(row.item)" v-b-tooltip.hover.top="$t('Shared.Delete')" class="rounded-circle tableActionBTN-danger ml-1" scale=".5" font-scale="1.8" variant="light"></b-icon>
                    <b-icon v-if="viewIconEvent" icon="play-fill" @click="viewButton(row.item, $event.target)" v-b-tooltip.hover.top="$t('Shared.View')" class="rounded-circle tableActionBTN mr-2" scale=".5" font-scale="1.8" variant="light"></b-icon>
                    <b-icon v-if="otherIconEvent" :icon="otherIcon" @click="otherButton(row.item, $event.target)" v-b-tooltip.hover.top="$t('Shared.Report')" class="rounded-circle tableActionBTN mr-2" scale=".5" font-scale="1.8" variant="light"></b-icon> 

                    <b-icon v-if="IconEvent1" icon="file-earmark-play" @click="ButtonIconEvent1(row.item, $event.target)" class="rounded-circle tableActionBTN mr-2" scale=".5" font-scale="1.8" variant="light"></b-icon> 
                    <b-icon v-if="IconEvent2" icon="file-pdf" @click="ButtonIconEvent2(row.item, $event.target)" class="rounded-circle tableActionBTN mr-2" scale=".5" font-scale="1.8" variant="light"></b-icon> 
                    <b-icon v-if="IconEvent3" icon="file-earmark-arrow-up" @click="ButtonIconEvent3(row.item, $event.target)" class="rounded-circle tableActionBTN mr-2" scale=".5" font-scale="1.8" variant="light"></b-icon> 
                    
                </template>


            <!--<template v-slot:cell(Code)="row">      
               <select v-if="row.field.type === 'dropdown'">
                <option>123456</option>             
              </select> 
               <b-form-input  v-if="row.field.type === 'edit'"
               :min="1"
               v-model.number="row.item.value"            
               type="number"  
               />
            </template>-->

            <template v-for="(field,index) in this.fields" v-slot:[`cell(${field.key})`]="{item}"> 
           
                <MdLookup v-if="field.type == 'Lookup'"
                    :key="field.key"
                    v-bind:Label="''"
                    v-bind:ModelCode="item[field.key]"
                    v-bind:ModelDescription="data.description"
                    v-bind:RefCode="'programs'"
                    v-bind:Api="'programs'"
                    v-bind:FieldCode="'code'"
                    v-bind:FieldDescription="'name'"                              
                    :SearchTitle="$t('menu.Users')"
                    :GetValues="onCodeConditionOk"
                    :InvalidFeedback="$t('Msg.InvalidUCode')"
                    :SearchFieldCode="'Code'" />
                
                <MdSelect v-if="field.type === 'DropDown'"
                    :key="field.key"
                    v-bind:Label="''"
                    v-bind:Options="field.options"
                    v-bind:Model="item[field.key]"
                    v-bind:FieldDescription="'name'"
                    v-bind:FieldCode="'code'" 
                    style="width:100%!important;"                   
                    v-bind:Multiple="field.isMultiple"             
                    :Input="ChangeDropDown"/>

                <MdEdit v-if="field.type === 'Edit'"
                    :key="field.key"
                    v-bind:TypeProp="field.Mode"
                    v-model="item[field.key]"                    
                    v-bind:Label="''" 
                    v-bind:MaxLengthProp="field.MaxLength"/>
            </template>

      
            </b-table>
        </b-col>    
    </b-row>
   
    <b-row>
      <b-col v-if="pageOptions" sm="7" md="6" class="my-1">
        <b-pagination 
          v-model="cPage"
          :total-rows="totalRows"
          :per-page="pPage"
          align="fill"
          size="sm"
          class="my-0"
          @change="fpageChanged"
        ></b-pagination>
      </b-col>
      <b-col v-if="pageOptions" align="right" class="mr-2">
          <b-form-group
            >{{ $t('Shared.Show') }}
              <b-form-select
                v-model="pPage"
                id="perPageSelect"
                size="sm"
                :options="pageOptions"
                style="width:auto;"
                @change="pageFunction($event)"
              ></b-form-select>
              of {{totalRows}}
            </b-form-group>
        </b-col>
      </b-row>
  </div>
</template>

<script>


import ShowMessage from '@/components/messages/ShowMessage.js'
import Utils from '@/common/utils.js'
import { EventBus } from '@/event-bus.js';

  export default {
    mixins: [ShowMessage],
    props: {
     items:  Array,   
     fields: Array,
     totalRows: Number,
     totalRowsSubItems:Number, 
     editIconEvent: Function,
     VisibleEditIcon:Boolean,
     deleteIconEvent: Function,
     VisibleDeleteIcon:Boolean,
     showMessageIsEmpty: Boolean,
     ShowDetails:Boolean,
     onRowSelected: Function,
     ondblClick:Function,
     currentPage: Number,
     currentPageSubItems:Number,
     onFiltered: Function,
     perPage: Number,
     perPageSubItems:Number,
     pageChanged: Function,
     pageChangedSubItems:Function,
     pageOptions: Array,
     pageOptionsSubItems: Array,
     perPageFunction: Function,
     perPageFunctionSubItems: Function,
     otherIconEvent: Function,
     otherIcon: String,
     IconEvent1:Function,
     IconEvent2:Function,
     IconEvent3:Function,
     viewIconEvent: Function,
     refTable: String,
     subItems: Array,
     multiSelect: Boolean,
     avisar:Function,
     CheckClick:Function,
     small: Boolean,
     stickyHeader: [Boolean,String],
     isReadOnly:Boolean,
     trClassFn: Function,
     FieldsDate:Array       
   },
   name: 'MdTableGenericFields',
    data() {
      return {
          filter: null,
          filterOn: [],   
          data:{
            type:"",
            value:"",
            description:""
          },  
          itemslocal:this.items,     
          pPage: this.perPage,
          pPageSubItems: this.perPageSubItems,
          cPage: this.currentPage,
          cPageSubItems: this.currentPageSubItems,         
          fFields: this.fields,
          fMultiSelect: 'single',
          CountNotesOpened:0,
          CountSubItems:0,
          DateTimeNow:null,
          TRSubItems:0,
          CPSubItems:0,
          CurrentPage : 1          
      }
    },
    
    methods:{

    editButton(row) {
        this.editIconEvent(row);
    },

     formatterDate(value){
         return Utils.formatterDate(value);
      },

    deleteButton(row) {
        this.deleteIconEvent(row);
    },

    rowSelected(items) {     
        this.onRowSelected(items);
    },

    ChangeDropDown(e){
      console.log(e);
    },

    onCodeConditionOk(result){
         var type = result.FieldName;         
             switch (type) {
                case "programs": 
                    this.data.value = result.code; 
                    this.itemslocal[0].code =  result.code;
                    this.itemslocal[0].name =  result.description;
                    this.data.description = result.description;   
                    break;
                }
           
    },

    filtered(filteredItems) {
        this.onFiltered(filteredItems);
    },

    fpageChanged(page) {
        this.pageChanged(page);
    },
     fpageChangedSubItems(page) {
        this.pageChangedSubItems(page);
    },

    SubItemsPageChanged(e){     
    this.pageChangedSubItems(e);
    },

    pageFunction(perpage) {
        this.perPageFunction(perpage);
    },
    pageFunctionSubItems(perpage) {
        this.perPageFunctionSubItems(perpage);
    },

    otherButton(row) {
      this.otherIconEvent(row);
    },

    ButtonIconEvent1(row){
      this.IconEvent1(row);
    },

    ButtonIconEvent2(row){
      this.IconEvent2(row);
    },

    ButtonIconEvent3(row){
      this.IconEvent3(row);
    },

    viewButton(row) {
      this.viewIconEvent(row);
      EventBus.$emit('shwoHideFavorite', false);
      EventBus.$emit('onUpdatedMenu', '');
    },


    AddTime(SubItems){        
      this.getheader(SubItems);     
      
    },

    ondblClicked(item){    
       this.ondblClick(item);
    },

    Compare(itemA, itemB, key) {     
      if(!this.FieldsDate.includes(key)){
        return false;
      } else {  
        let a = (itemA[key] === null || itemA[key] === ""?'00/00/0000':itemA[key]).split('/');
        let b = (itemB[key] === null || itemA[key] === ""?'00/00/0000':itemB[key]).split('/');         
        a = (parseInt(a[2], 10) * 10000) + (parseInt(a[0], 10) * 100) + parseInt(a[1]);
        b = (parseInt(b[2], 10) * 10000) + (parseInt(b[0], 10) * 100) + parseInt(b[1]);       
        return a - b
      }
    },



    Check(e,row){
      if(e){
         this.IsCheckBoxClicked(row);
      }
    },

      async IsCheckBoxClicked(row) {     
      var SendObject = {       
        notesId:row.item.notesId,
        currentPage:1,
        perPage:10      
      }       
        this.CheckClick(SendObject);
      },

  
 },
  created() {    
    if(this.subItems) {
      this.fFields.unshift({ key: 'show_details'});
    }    

   /* if(this.VisibleEditIcon && this.editIconEvent || this.VisibleDeleteIcon && this.deleteIconEvent || this.viewIconEvent || this.otherIconEvent || this.IconEvent1 || this.IconEvent2 || this.IconEvent3) {
      if(!this.isReadOnly){
        this.fFields.unshift({ key: 'actions', label: 'Actions',thClass: 'ActionsClass'});
      }      
    }*/

    EventBus.$on("onSetReadOnly", function () {       
     /* if(!readOnly){
        this.fFields.unshift({ key: 'actions', label: 'Actions',thClass: 'ActionsClass'});
      } */
     }.bind(this));  
    this.fMultiSelect = this.multiSelect ? 'multi' : 'single';
  }, 
}

</script>

<style>

.ActionsClass {
   width: 150px !important;
}
.TimeNotes{
 border: 1px solid #F3F3F3;
 background-color: #f9fbfc;
 color: #999;
 padding: 8px;
 border-radius: 6px;
 margin: 4px;
 cursor: pointer;
 transition: all 0.1s ease-out;
}
.addNotesBtn{
  background-color:#19BEE8;
  margin-right: 4px;
	border-radius:10px 2px;
	cursor:pointer;
  vertical-align: top;
	color:#ffffff;
	padding:4px 11px;
	text-decoration:none;
  border: 0;
}
.addNotesBtn:focus{
	background-color: #19BEE8 !important;
  color: #FFF !important;
}
</style>
