import ApiService from '@/services/api.service'

const UserFavoritesService = {

  getAll(userid) { 
         
    const requestData = {
      method: 'get',
      url: "/UserFavorites/",
      headers: {
      },
      params: { 
        Userid: userid,
      }
    }     
    return ApiService.customRequest(requestData);         
  },
    AddToFavorites(data, order,userid){
      if(order){
        return ApiService.post(`/UserFavorites/Order/${userid}`, data);
      }
      return ApiService.post(`/UserFavorites`, data);
    },
    UpdateUserFavorite(Id,data){
        return ApiService.put(`/UserFavorites/${Id}`,data);
    }, 
    GetUserFavorite(userid, url){
      const encodedUrlParameter = encodeURIComponent(url).replace(/\//g, '%2F');     
      return ApiService.get(`/UserFavorites/getFavorite/${userid}:${encodedUrlParameter}`);
    },
    RemoveFavorite(userid, url){
      const encodedUrlParameter = encodeURIComponent(url).replace(/\//g, '%2F');
      return ApiService.delete(`/UserFavorites/DeleteUserFavorite/${userid}:${encodedUrlParameter}`)
    },
}

export default UserFavoritesService;