<template>
<div id="ContainerEmployeeCredentialList">
       <h4 class="page-title text-truncate">
            {{$t('employeecredentialList.EmplCredSetupTitle')}}         
        </h4>
  <div class="row">
              <div class="col-sm-12 col-md-3"  v-if="permission.length > 0 && permission[0].roleCanAdd">                      
                    <MdButton
                      v-bind:Label="$t('employeecredential.Add')" 
                      v-bind:ClassProp="'btn btn-primary my-3 btn-block btn-login'"
                      v-bind:IconClass="'ti-plus pr-1'"
                      v-on:click="AddEmployeeCredential"                   
                      v-bind:Name="'btn_add_EmployeeCredential'">
                    </MdButton>
                </div>
                <div v-bind:class="permission.length > 0 && permission[0].roleCanAdd ? 'col-sm-12 col-md-9 mb-4':'col-sm-12 col-md-12 mb-4'">
                    <div class="textlabel" :data-label="this.$t('Shared.SearchElements')">
                        <div class="input-group">
                            <input 
                              type="text" 
                              class="form-control"
                              v-model="parameter.SearchCriteria"   
                              @keyup.enter="SearchEmployeeCredential"                            
                            >
                            <div class="input-group-append">
                                <div class="input-group-append">
                                <MdButton 
                                  v-bind:Name="'btnsearchinput'"
                                  v-bind:VariantProp="'btn btn-sm search-attached'"
                                  v-bind:IconClass="'mdi mdi-magnify'"
                                  v-on:click="SearchEmployeeCredential">
                                </MdButton>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 

      <div class="row">
        <div class="col-12 mt-2">
        <MdTitle v-bind:Text="$t('Shared.SearchResult')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
          <div class="row">
            <div class="col-md-12">
              <MdTable 
                v-bind:fields="fields"
                v-bind:items="items"
                v-bind:totalRows="totalRows"
                v-bind:isReadOnly="false"
                v-bind:showMessageIsEmpty="ShowMessageIsEmpty"
                v-bind:currentPage="currentPage"
                v-bind:perPage="perPage"
                v-bind:pageOptions="pageOptions"
                :editIconEvent="EditEmployeeCredential"
                v-bind:VisibleEditIcon="true"
                :deleteIconEvent="RemoveEmployeeCredential"
                v-bind:VisibleDeleteIcon="true"
                :onRowSelected="onRowSelected"
                :onFiltered="onFiltered"
                :pageChanged="pageChanged"
                :perPageFunction="PerPageFunction">
              </MdTable>
            </div>
          </div>
        </div>
      </div>
</div>
</template>

<script>


import DBFunctions from '@/common/DBFunctions.js';
import ShowMessage from '@/components/messages/ShowMessage.js'
import EmployeeCredentialService from '@/services/employeecredential.service'
//import { EventBus } from '../../../event-bus.js';
//import Utils from '@/common/utils'

export default {
    beforeRouteEnter(to, from, next) {
        next(async vm => {
            const iPI = await vm.CanView();
            if(iPI) next()
            else next('/accessdenied');
        }) 
  },
  name: 'EmployeeCredentialList',
 mixins: [ShowMessage, DBFunctions/*,EventBus*/],
  data(){
    return{
        OptionsRightMenu:{
          visible:false,
          ItemsList:[],
          btnvisible:false,
          itemdisabled: false,
          PatientCode:'',
          Mode:this.edit_mode,
          favButtonAtion:false          
        },

      permission: [],
      Menu : this.$store.state.leftMenu,
      parameter:{
        SearchCriteria:'',
        PageNumber : 0,
        PageSize:0,         
      },
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      ShowMessageIsEmpty:false,
      pageOptions: [5, 10, 15 ],
      items:[],
      fields: [
        { key: 'description', sortable: true, label: this.$t('Shared.Description'), class: 'text-rigth' },     
        { key: 'code', sortable: true, label: this.$t('Shared.Code'), class: 'text-rigth'},       
        ],

    }
  },

  methods:{

    CanView(){
      return this.permission[0].roleCanView;
    },
  

    SearchEmployeeCredential(){
       this.$emit('load', true);
      this.parameter.PageNumber = this.currentPage;           
      this.parameter.PageSize = this.perPage;      
      EmployeeCredentialService.getAll(this.parameter)
        .then((response) => {
            if(response.status == '200' && response.data){
              this.items = response.data;                        
              this.$emit('load', false);
              let headerString = response.headers["x-pagination"];
              this.totalRows = JSON.parse(headerString).TotalCount;
              }               
        })
        .catch((error) => {                    
            if (!error.response) {
              this.showMessage(this.$t('employeecredential.EmplCredenTitle'),this.$t('Msg.retrieveInfo'),"error");
            }                      
            else if(error.response.status === 400 ){
              this.showMessage(this.$t('employeecredential.EmplCredenTitle'),error.response.data.errorMessage,"error");
            }
            else if(error.response.status === 405 ){             
                  this.$router.push({name: "Main"});    
                    setTimeout(() => 
                        this.showPermissionMessage()
                        , 50);            
            } 
            else if(error.response.status === 500){
              this.showMessage(this.$t('employeecredential.EmplCredenTitle'),this.$t('Msg.ThereWereSomeError'),"error");
            } else{
              this.showMessage(this.$t('employeecredential.EmplCredenTitle'),error.response.data.errorMessage,"error");
            } 
            this.$emit('load', false);            
        }); 
    },

    AddEmployeeCredential(){
       this.emitDataforFavorites(); 
       this.$router.push({ name: "employeecredentials-add"}).catch(()=>{});
    },
    EditEmployeeCredential(row){
      this.$router.push({ name: "employeecredentials-details", params: { id: row.code } });    
    },
    RemoveEmployeeCredential(row){
      const Id = row.code;
           this.$bvModal.msgBoxConfirm(this.$t('Msg.DeleteRecord'), {
					title: this.$tc('Shared.DelRecord'),
					okTitle: this.$t('Shared.TitleDelete'),
					cancelTitle: this.$t('Shared.BtnCancel'),
					footerClass: 'p-2',
					titleClass: 'modal-title modal-type-warning',
          id: 'modal_delete_EmployeeCredential'
				})
          .then(value => {
            if(value){
              this.$emit('load', true);  
               EmployeeCredentialService.Delete(Id)
                .then(() => {
                  const index = this.items.findIndex(item => item.code === Id) 
                  if (~index) {
                    this.items.splice(index, 1) 
                    this.SearchEmployeeCredential(); 
                  }
                })
                .catch((error) => {
                  this.$emit('load', false);  
                    if(error.response.status === 400 ){
                     this.showMessage(this.$t('employeecredential.EmplCredenTitle'),error.response.data.errorMessage,"error");
                     }else{
                        this.showMessage(this.$t('employeecredential.EmplCredenTitle'),error,"error");
                     }           
                });
            }
          })
    },

    onRowSelected(){

    },

    onFiltered(){

    },
    pageChanged(page){
      this.currentPage = page;    
      this.SearchEmployeeCredential();  
    },
    PerPageFunction(perpage){
      this.perPage = perpage;  
      this.currentPage = 1;
      this.SearchEmployeeCredential();  
    }
  },

  created(){
    this.getPermissionLeftMenu(this.Menu,this.$route.path,this.permission);
  },

  mounted(){
       this.OptionsRightMenu.ItemsList = [];
       let layoutStatus = this.$store.state.layoutStatus;
       switch(layoutStatus) {
        case 'L0R1':
          this.OptionsRightMenu.favButtonAtion = false;
          this.$emit('getVisibleMenu', this.OptionsRightMenu);
        break;
        case 'L1R0':
        case 'L1R1':
            this.OptionsRightMenu.btnvisible = false;
            this.$emit('getVisibleMenu', this.OptionsRightMenu);
        break;
        default:
            this.OptionsRightMenu.favButtonAtion = false;
            this.$emit('getVisibleMenu', this.OptionsRightMenu);
      }

      this.SearchEmployeeCredential();
    
  },

    destroyed(){
      this.OptionsRightMenu.ItemsList = [];
      this.OptionsRightMenu.Mode = false;
      this.OptionsRightMenu.visible = false; 
      this.$emit('getVisibleMenu', this.OptionsRightMenu);
    }
   
};
</script>


