<template>
    <div>
        <b-modal id="PasswordModal" size="md" :visible="true" :no-close-on-backdrop="true" :no-close-on-esc="true" :hide-header-close="true" centered :title="$t('insuranceSetup.Password')" @ok="this.emitEventOK" @cancel="this.emitEventCancel">
        <div v-if="errorMessage" class="pt-3">
            <div id="alert" class="alert alert-fill-danger" role="alert">
              <i class="mdi mdi-alert-circle"></i>
              {{ errorMessage }}
            </div>
        </div>      
        <b-row>
            <b-col lg="12" md="12" sm="12">
                <MdSelect
                    v-bind:Label="$t('Shared.WrittenBy')"
                    data-field="therapistcode"
                    v-bind:Options="TherapistsList"
                    v-bind:FieldDescription="'fullName'"
                    v-bind:FieldCode="'code'"
                    v-bind:Model="therapistcode"                       
                    v-bind:Multiple="false"
                    v-bind:IsDisabled="NoteIsReadOnly || IsGroupNote && IsCommonPart === false"
                    :Input="ChangeDropDownTherapistSignandSave"
                    v-bind:Tag="Id"
                  />
            </b-col>
        </b-row>
        <b-row>
           <b-col lg="12" md="612" sm="12">
              <MdPassword 
                 v-bind:Label="$t('MdPasswordDlg.EnterPassword')"
                 v-bind:TypeProp="'password'" 
                 v-bind:Name="'password'"
                 v-bind:Autocomplete="true"
                 v-bind:NoValidateChanges="NoValidateChanges"
                 v-bind:MaxLengthProp="45"
                 v-model="password"
                 ref="inputPassword"
                 v-bind:Id="'inputPassword'"
                 v-bind:isEndControl="true"
                 v-bind:EndControlFunction="checkPassword"
                 >                                     
              </MdPassword>
           </b-col>
        </b-row>
      </b-modal>
    </div>
  </template>
  
  <script>
  import ShowMessage from '@/components/messages/ShowMessage.js'
  import UserService from '@/services/user.service'
  import StorageService from '@/services/storage.service'
    export default {
    props: {
      Operation:String,
      Type: String,
      VerificationType:String,
      ShowPopup: Boolean,
      NoValidateChanges: Boolean,  
      TherapistsList:Array,
      NoteIsReadOnly:Boolean,
      IsGroupNote:Boolean,
      IsCommonPart:Boolean,
      Id:String,
      therapistcode:[Object,String]
    },
    
      mixins: [ShowMessage],
      data() {
        return { 
          password: '',
          errorMessage: null,         
        }
      },
      
      methods:{
        emitEventOK(){
          this.checkPassword()
        },
  
        emitEventCancel(){
          this.$emit('getModalPasswordSignandSave', {"status":false,"type":this.Type,"Event":"Cancel"});
        }, 

        ChangeDropDownTherapistSignandSave(){

        },
  
        checkPassword() {
          const password = this.password;
          if(this.VerificationType === 'SupervisorPassword'){
            if(password !== ''){
              var data = {
                SupervisorPassword : password
              };
              UserService.verifySupervisorPassword(data)
                 .then((response) => {
                   if(response.status == '200'){   
                    this.$emit('getModalPasswordSignandSave', {"status":response.data,"type":this.Type,"Event":"Ok","Operation":this.Operation});             
                    /*if(response.data === true){
                      this.$emit('getModalPassword', {"status":true});
                    }else{
                      this.$emit('getModalPassword', {"status":false});
                    }*/              
                     
                   }
                 })
                  .catch((error) => {
                     if (!error.response) {
                        this.showMessage(this.$t('Shared.VerifyPassword'),this.$t('MdPasswordDlg.NoAblePasswordMsg'),"error");
                    } else {
                        this.$emit('getModalPasswordSignandSave',{"status":false,"type":this.Type,"Event":"Ok","Operation":this.Operation,"message":error.response.data.message});
                    }
                  });
            }else{             
              this.$emit('getModalPasswordSignandSave',{"status":false,"type":this.Type,"Event":"Ok","Operation":this.Operation});        
            }
          }else{
            const clientId = StorageService.getClientId();         
          if( clientId !== '' && this.therapistcode.code !== '' && password !== '' ){
           UserService.verifyPassword(clientId, null, this.therapistcode.code, password)
                 .then((response) => {
                   if(response.status == '200'){
                     this.$emit('getModalPasswordSignandSave', {"status":true,"type":this.Type,"Event":"Ok","Operation":this.Operation});
                   }
                 })
                  .catch((error) => {
                     if (!error.response) {
                        this.showMessage(this.$t('Shared.VerifyPassword'),this.$t('MdPasswordDlg.NoAblePasswordMsg'),"error");
                    } else {
                        this.$emit('getModalPasswordSignandSave',{"status":false,"type":this.Type,"Event":"Ok","Operation":this.Operation,"message":error.response.data.message});
                    }
                  });
          } else {
            this.$emit('getModalPasswordSignandSave',{"status":false,"type":this.Type,"Event":"Ok","Operation":this.Operation});
          }
          }
  
         
  
  
        }
  
  
  
      },
  
      mounted(){
        this.$nextTick(() => {
          setTimeout(() =>  this.$refs.inputPassword.setFocus(), 500);
        });
  
        /*window.addEventListener('keyup', event => {
          if (event.keyCode === 13) { 
            this.emitEventOK()
          }
        })*/
      }
    }
  
  </script>