<template>
  <div id="ContainerProvider">
    <div class="container-scroller">
      <div class="container-fluid page-body-wrapper full-page-wrapper">
        <div class="content-wrapper d-flex align-items-center auth px-0">
          <div class="row w-100 mx-0">
            <div class="col-lg-4 mx-auto">
              <div class="auth-form-light text-left py-5 px-4 px-sm-5">
                <div class="brand-logo">
                  <img src="../../../assets/images/logo.svg" alt="logo">
                  <a v-on:click="logout" role="button" class="btn btn-light btn-sm float-right text-truncate mt-2">{{$t('AccountExpired.BtLogin')}}</a>
                </div>
                  <noscript>
                    <div class="alert alert-fill-warning" role="alert">
                      <i class="mdi mdi-alert-circle"></i>
                     {{$t('Msg.EnableJsMsg')}}
                    </div>
                  </noscript>
                  <h6 class="font-weight-light">{{$t('SelectProvider.SelectProv')}}</h6>
                  <div class="pt-3">
                    <div id="alert" class="alert alert-fill-danger hidden" role="alert">
                      <i class="mdi mdi-alert-circle"></i>
                      {{$t('SelectProvider.NoWorkingMsg')}}
                  </div>
                  <div class="btn-group btn-block mb-4">
                    <button id="provider" type="button" class="btn btn-light btn-block text-truncate" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                      <i class="mdi mdi-playlist-check text-muted"></i> {{$t('SelectProvider.NoProvSelectMsg')}}
                    </button>
                    <button type="button" class="btn btn-success dropdown-toggle dropdown-toggle-split" id="providers" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <input ref="uprovider" id="provider-value" type="hidden">
                      <span class="sr-only">{{$t('SelectProvider.ToggleDropdown')}}</span>
                    </button>
                    <div class="dropdown-menu dropdown-menu-right shadow scrollmenu cutescroll" aria-labelledby="providers">
                      <h6 class="dropdown-header font-weight-bold">{{$t('SelectProvider.Providers')}}</h6>
                    <div class="dropdown-divider"></div>
                        <span v-for="provider in providerList" 
                          :key="provider.code" 
                          class="dropdown-item text-success font-weight-bold text-truncate pointer" 
                          v-on:click="passItem('provider', provider.code +'-'+ provider.company )">
                          {{ provider.code }} -
                          {{ provider.company }}                        
                        </span>                       
                    </div>
                  </div>
                  <div class="mt-3">
                    <MdButton 
                       v-bind:Label="$t('SelectProvider.ENTER')"
                       v-bind:Name="'btnEnter'"
                       v-bind:ClassProp="'btn btn-block btn-primary btn-login btn-lg font-weight-medium auth-form-btn text-truncate'"
                       v-on:click="main">
                    </MdButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import ProviderService from '@/services/provider.service' 
import UserService from '@/services/user.service'
import UserPreferences from '@/services/userpreferences.service' 
import StorageService from '@/services/storage.service'
import ShowMessage from '@/components/messages/ShowMessage.js'
import PermFunctions from '@/common/PermFunctions.js'
import DBFunctions from '@/common/DBFunctions.js'

export default {
  name: 'Provider',
  mixins: [ShowMessage,PermFunctions,DBFunctions],
  data() {
    return {
      Loading:true,
      selected: null,
      CodeSelected:{
        "ProviderId":""
      },
      providerList: this.$store.state.ProvidersList,
    };
  },
  
  methods: { 
    
    logout(){
         this.$bvModal.msgBoxConfirm(this.$t('SelectProvider.CloseSessionMsg'), {
          title: this.$t('SelectProvider.LogOutTitle'),
          okTitle: this.$t('Login.LogOut'),
          cancelTitle: this.$t('Shared.BtnCancel'),
          footerClass: 'p-2',
          titleClass: 'modal-title modal-type-warning'
       })
          .then(async value => {
            this.IsOk = value;
            if(this.IsOk){
               UserService.logout()
              .then((response) => {
                if(response.status == '200'){
                  this.$store.commit('setAccessToken','');
                  localStorage.removeItem("vuex");
                  localStorage.removeItem("_secure__ls__metadata");
                  this.$router.push('/login').catch(()=>{});
                }
              })
            .catch((error) => {
              if (!error.response) {
                this.showMessage(this.$t('SelectProvider.Providers'),this.$t('SelectProvider.NoAbleToLogOutMsg'),"error");
              } else {
                 this.showMessage(this.$t('SelectProvider.Providers'),error,"error");
              }
            });
            }
          })
    },

    SelectProvider(){      
      ProviderService.SelectProvider(this.CodeSelected)
            .then((response) => {
                if (response.status == '200'){
                   this.setPermissions();
                  // this.PupulateSelects(); 
                   this.$router.push('/app/main');
                }
            })
            .catch((error) => {
              if (error.response) {
                this.showMessage(this.$t('SelectProvider.Providers'),error,"error");
              }
            });
    },

  main(){
      const p = this.$refs.uprovider.value; 
      if (p) {
        const providercode =  p.split("-")[0];
        const provider = p.split("-")[1];
        this.$store.commit('setProviderCode',providercode);
        this.$store.commit('setProvider',provider);
        this.CodeSelected.ProviderId = providercode;
        this.SelectProvider();
      }      
    },

    passItem(toMenu,fromItem){
			document.getElementById(toMenu).innerHTML = '<i class="mdi mdi-check text-success"></i> '+fromItem;	
			document.getElementById(toMenu+'-value').value = fromItem;
    },

    getUserPreference(){
      
        const userid = StorageService.getUserId();
        UserPreferences.getUserPreference(userid)
          .then((response) => { 
            if(response.data && response.data.length > 0){
              var dataResponse = response.data[0];
              if (dataResponse.providerCode && dataResponse.providerCode != 0){
                this.passItem('provider', dataResponse.providerCode +'-'+ dataResponse.company )
              }
            }     
          })
          .catch(error => {
            if (error.response) {
              this.showMessage(this.$t('UserPreferences.UPTitle'),error,"error");
            } 
          });  
      },
  },

  mounted() {
    this.providerList = this.$store.state.ProvidersList;
    this.getUserPreference();
  } 
};

</script>


