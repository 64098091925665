import ApiService from '@/services/api.service'

const VitalSignService = {
    getAll( parameter) { 
      const requestData = {
        method: 'get',
        url: "/PatientVitalSigns/",
        headers: {
        },
        params: { 
          SearchCriteria: parameter.SearchCriteria,
          PageSize: parameter.PageSize,          
          PageNumber : parameter.PageNumber,
          PatientCode : parameter.PatientCode
        }
      }     
      return ApiService.customRequest(requestData);         
    },
  
    get(id) {
      return ApiService.get(`/PatientVitalSigns/${id}`);
    },

    addVital(data){
      return ApiService.post(`/PatientVitalSigns`,data)
    },

    deleteVital(id){
      return ApiService.delete(`/PatientVitalSigns/${id}`)
    },

    update(recNo,vital){
      return ApiService.put(`/PatientVitalSigns/${recNo}`,vital)
    }
}
  
  export default VitalSignService;