<template>	
    <div id="DivModalClaimWarning">
    <b-modal id="modalIdClaimWarning" size="xl" centered scrollable  :title="TitleWindow" @ok="this.emitEventOK" @cancel="this.emitEventCancel"> 
        <TableClaimWarningError
              v-bind:fields="fields"
              v-bind:items="items"
              v-bind:totalRows="totalRows"  
              v-bind:refTable="refTable"           
              v-bind:showMessageIsEmpty="ShowMessageIsEmpty"
              v-bind:currentPage="currentPage"
              v-bind:perPage="perPage"  
              v-bind:viewIconEvent="ViewIcon"                       
              v-bind:pageOptions="pageOptions" 
              :onRowSelected="onRowSelected" 
              :onFiltered="onFiltered"
              :pageChanged="pageChanged"
              :perPageFunction="PerPageFunction">
            </TableClaimWarningError>
    </b-modal>   
    </div>   
</template>

<script>
//import EnumService from '@/services/enum.service'
//import Utils from '../../../common/utils'
//import ClaimBatch from '@/services/claimbatch.service';
import DBFunctions from '@/common/DBFunctions.js'
import { EventBus } from '../../../event-bus.js';
//import Utils from '@/common/utils'
export default {
  name: 'ModalClaimWarning',
    props: {     
     TitleWindow:String,
     WarningList:Array
   },
    mixins: [DBFunctions],
    data() {
		return {
      RecId:null,
      parameter:{
        SearchCriteria:'',
        PageNumber : null,
        PageSize:null , 
        RecNoList:0      
      },
      SelectAllRecords:false,
      isSelected:false,
      refTable:'refTableModalClaimError',
      ShowMessageIsEmpty:true,
      pageOptions: [5, 10, 15 ],
      totalRows: 0,      
      perPage: 10,
      filter: null,
      VisibleTest:true,
       selected: 0,
       options: ['Yes','No'],
        resultList: [],
        anwers: '',		
      fields: [  
        { key: 'description', sortable: true}       
      ],
      items: [],
      currentPage: 1,
		}
	},

	mounted() {  
      this.$root.$on('bv::modal::show', () => {
 
      });  
  /* this.getCallApi();	
   
      this.$root.$on('bv::modal::show', () => {
   
      });
   */
      EventBus.$on("onClaimWarning", function (data) { 
        this.items = data;
        this.totalRows = this.items.length; 
     }.bind(this));
	},

  created(){ 

  },
	methods:{

  
    FillItems(arr){
          for(var i = 0; i <arr.length; i++){
            console.log(arr[i]);
            this.items[i].description = arr[i];
        }
    },

    ViewIcon(){

    },

    getCallApi(){
       //this.items = [{'Code':1,'Description':'Error Test Error Test Error Test Error Test'},{'Code':2,'Description':'Error Test Error Test Error Test Error Test'}];
       //this.fillResultList(this.items);       
    },

    handleOk(bvModalEvt) {      
        bvModalEvt.preventDefault();      
        this.handleSubmit()
      },

    onRowSelected(items) {      
   console.log(items);
    },

    onFiltered() {
    },

    pageChanged(page){ 
       this.currentPage = page;  
    },

    PerPageFunction(page){ 
      this.perPage = page;        
      this.currentPage = 1;
   
    },


    getAction(){
     
    },

    isAnswered(Code){
      let answeredItem = false;
      if(this.resultList.length > 0){
        this.resultList.forEach(element => {
        if(element.Code == Code && element.value > -1) {
          answeredItem = true
        }
      });
      }      
      return answeredItem;
    },

     trClassFn(item, rowType){
      if (rowType === 'row' && this.isAnswered(item.Code)) {
        return this.$t('modalClaimDetail.answered');
      }
    },

    emitEventCancel(){
        //setTimeout(() => this.ResetObjOccurrence(),200); 
      }, 
      handleSubmit() {
        // Exit when the form isn't valid
        if (!this.checkFormValidity()) {
          this.emitEventOK();
          return
        }
        this.emitEventOK();
      },
     emitEventOK(){   
        
      },
	},

}


</script>


