import ApiService from '@/services/api.service'

const NewCropService = { 
    getAll(parameter){
        const requestData = {
            method: 'get',
            url: "/NewCropAccounts/",       
            params: {
              SearchCriteria: parameter.SearchCriteria,
              PageSize: parameter.PageSize,
              PageNumber: parameter.PageNumber,  
            }
          }
          return ApiService.customRequest(requestData);
    },
    
    sendRequest(code){
        const requestData = {
          method: 'post',
          url: '/NewCropGateway/RX',
          data: {
            patientCode: code
          }
        }
        return ApiService.customRequest(requestData);
    }
  }
  
  export default NewCropService;