<template>
<div>
  <body class="sidebar-fixed">    
      <div class="container-scroller">
      <div class="container-fluid page-body-wrapper full-page-wrapper">
        <div class="content-wrapper d-flex align-items-center auth px-0">
          <div class="row w-100 mx-0">
            <div class="col-lg-4 mx-auto">
              <div class="auth-form-light text-left py-5 px-4 px-sm-5">
                <div class="brand-logo">
                  <img src="../../../assets/images/logo.svg" alt="logo"><a hidden href="#" role="button" class="btn btn-light btn-sm float-right text-truncate mt-2">Forgot password</a>
                </div>
          <noscript>
            <div class="alert alert-fill-warning" role="alert">
                    <i class="mdi mdi-alert-circle"></i>
                    {{$t('Msg.EnableJsMsg')}}.
                  </div>
          </noscript>
                
                <div v-if="errorMessage" class="pt-3">
                    <div id="alert" class="alert alert-fill-danger" role="alert">
                      <i class="mdi mdi-alert-circle"></i>
                      {{ errorMessage }}
                    </div>
                </div>
      <Loading v-bind:IsLoading="this.Load" />    
                <div id="ContainerLogin" >
                  <div class="form-group">
                    <input 
                      type="text" 
                      ref="uClient" 
                      class="form-control form-control-lg" 
                      id="uClientId" 
                      :placeholder="$t('Login.cId')" 
                      autofocus>
                  </div>
            
                  <div class="form-group">
                    <input 
                      type="text" 
                      ref="uName" 
                      class="form-control form-control-lg" 
                      id="uName" 
                      :placeholder="$t('Login.UserName')" 
                      maxlength="6">
                  </div>
                  <div class="form-group">

                  <div id="passCtrl" class="input-group">
                        <input class="form-control form-control-lg"        
                          v-on:keyup.enter="onEnter" 
                          v-on:focus="onFocus"        
                          ref="uPass"
                          :type="showPassword ? 'text' : 'password'"
                          id="uPass" :placeholder="$t('insuranceSetup.Password')"
                          autocomplete="true"
                          maxlength="45"/>
                        <div class="input-group-append">
                            <div :class="{'cursor-pointer':showPointer}"
                                  @click="showPassword = !showPassword"
                                  @mouseout="showPointer = false"
                                  @mouseover="showPointer = true"
                                  class="input-group-text">
                              <b-icon-eye v-if="! showPassword"/>
                              <b-icon-eye-fill v-if="showPassword"/>
                            </div>
                        </div>
                    </div>

                    <!--<input v-on:keyup.enter="onEnter" v-on:focus="onFocus" type="password" ref="uPass" class="form-control form-control-lg" id="uPass" placeholder="Password"></input></input>
-->                  </div>
                  <div class="mt-3">
                    <MdButton 
                       v-bind:Label="$t('Login.SIGNIN')"
                       v-bind:Name="'btnSignIn'"
                       v-bind:ClassProp="'btn btn-block btn-primary btn-login btn-lg font-weight-medium auth-form-btn text-truncate'"
                       v-on:click="login">
                    </MdButton>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>     
      </div>
      <!-- VERTICAL WARNING -->
      <!--
      <div class="verticalWarning">
        <i class="ikon med-isologo"></i> best in landscape mode. please, rotate your device.
      </div>
      -->
      <!-- VERTICAL WARNING END -->
  </body>
</div>
</template>


<script>
import ProviderService from '@/services/provider.service' 
import UserService from '@/services/user.service'
import Companies from '@/services/companies.service'
import Loading from '@/components/template/loading/Loading'
import StorageService from '@/services/storage.service'
import { BIconEye, BIconEyeFill } from 'bootstrap-vue'
import ShowMessage from '@/components/messages/ShowMessage.js'
import PermFunctions from '@/common/PermFunctions.js'
import DBFunctions from '@/common/DBFunctions.js'
import UserPreferences from '@/services/userpreferences.service'

export default {
  name: 'Login',
  mixins: [ShowMessage,PermFunctions,DBFunctions],
  data() {
    return {
      IsOk:false,
      Load:false,
      showPassword: false,
      showPointer: false,
      errorMessage: null,
      providerList: [],
      CodeSelected:{
        "ProviderId":""
      },
    };
  },
  components: {
    BIconEye,
    BIconEyeFill,
    Loading
  },

  methods: {

    onFocus(){
      this.errorMessage = ''
    },

    async onEnter(){
      this.login();
    },

    loginForce(){
          if(UserService.IsLoged()) {
                this.$router.push('/provider');
          }
         else {              
              this.errorMessage = this.$t('Login.LoginIncMsg');
          }
    },

    async login() {
      this.Load = true;
      this.errorMessage = '';
      const clientid = this.$refs.uClient.value;
      const user = this.$refs.uName.value;
      const password = this.$refs.uPass.value;
      if( clientid !== '' && user !== '' && password !== '' ){
       var result = await UserService.login( clientid, user, password,false);
       if(result.expired){
          this.Load = false;
          this.$router.push({name: 'AccountExpired', params: {clientId: this.$refs.uClient.value, userId: this.$refs.uName.value}})
       }
       else if(result.disabled){         
         this.errorMessage = this.$t('Login.AccDisabled');
         this.Load = false;
       }
       else if(result.neterror){
         this.errorMessage = this.$t('AccountExpired.SerdownMsg');
         this.Load = false;
       }
       else if(result.InUse){
         this.Load = false;
          this.$bvModal.msgBoxConfirm(this.$t('Login.OtherSessOpenMsg'), {
            id:'modal_session_is_open',
            title: this.$t('Login.LoginTitle'),
            okTitle: this.$t('Login.LogOut'),
            cancelTitle: this.$t('Shared.BtnCancel'),
            footerClass: 'p-2',
            titleClass: 'modal-title modal-type-warning'
          })
              .then(async value => {
                  this.IsOk = value;
                    if(this.IsOk){ 
                      this.Load = true;                     
                      await UserService.login( clientid, user, password,true); 
                      if (UserService.IsLoged()) { 
                         this.Load = false;                       
                        this.SaveCompanies();
                        this.getProviders();
                        // this.$router.push('/provider'); 
                        this.$store.commit('setGroupNotesFiltersInitialize',[]);               
                      }
                    else {  
                       this.Load = false;             
                      this.errorMessage = this.$t('Login.LoginIncMsg');
                  }
            }
          })
       }       
       else{
          if (UserService.IsLoged()) {
             this.Load = false;
             this.SaveCompanies();
             this.getProviders();
             this.$store.commit('setGroupNotesFiltersInitialize',[]);
            //  this.$router.push('/provider');
        }
        else { 
           this.Load = false;         
          this.errorMessage = this.$t('Login.LoginIncMsg');
        }
       }
      }
      else {
         this.Load = false;
      this.errorMessage = this.$t('Login.AllRequiredMsg');
      }
    },
    
    SaveCompanies(){
       Companies.getAll()
                .then((response) => {
                  var data=response.data;
                  if(data && data.length > 0){
                    var companies = {
                    "Country":data[0].country  
                    };
                     this.$store.commit('setCompanies',companies);
                    //  StorageService.saveCompanie(JSON.stringify(companies));
                  }
                })
                .catch((error) => {
                    if (error.response) {
                      this.showMessage(this.$t('Login.LoginTitle'),error,"error");
                  }
                });  
    },

    getPrviderCode(List){
      var obj = {
        "code":"",
        "company":""
      }
      for(var i = 0; i < List.length; i++){
          if(!this.providerList[i].isTraining){
              obj.code = this.providerList[i].code;
              obj.company = this.providerList[i].company;
          }
      }
      return obj;
    },

    getProviders(){
      const userid = StorageService.getUserId();
      ProviderService.getAll(userid)
      .then((response) => {      
          this.providerList = response.data;
          this.$store.commit('setProviderList',this.providerList);
          
          let countProviderNotTraining = 0; 
          var l = this.providerList.length;
          for(var i = 0; i < l; i++){
              if(!this.providerList[i].isTraining){
                countProviderNotTraining++;
              }
          }
          this.getUserPreference();
          if(countProviderNotTraining === 1){
              var obj = this.getPrviderCode(this.providerList);
              this.CodeSelected.ProviderId = obj.code;
              this.$store.commit('setProviderCode',obj.code);
              this.$store.commit('setProvider',obj.company);
              this.SelectProvider();
          }else{
              this.$router.push('/provider');
          }
      })
      .catch(error => {
        if (error.response) {
          this.showMessage(this.$t('Login.LoginTitle'),error,"error");
        }
      });  
    },

    SelectProvider(){      
      ProviderService.SelectProvider(this.CodeSelected)
            .then((response) => {
                if (response.status == '200'){
                   this.setPermissions();
                   //this.PupulateSelects();
                   this.$router.push('/app/main');   
                }
            })
            .catch((error) => {
              if (error.response) {
                this.showMessage(this.$t('Login.LoginTitle'),error,"error");
              }
            });
    },
    
    getUserPreference(){
        const userid = StorageService.getUserId();
        UserPreferences.getUserPreference(userid)
          .then((response) => { 
            if(response.data && response.data.length > 0){
              var dataResponse = response.data[0];
              if (dataResponse.fullScreenStatus){
                this.openFullscreen();
              }
              if(dataResponse.locale && dataResponse.locale != 'nan'){
                  this.$root.$options.i18n.locale = dataResponse.locale;
              } 
            }     
          })
          .catch(error => {
            if (error.response) {
              this.showMessage(this.$t('UserPreferences.UPTitle'),error,"error");
            } 
          });  
      },
  },
};
</script>
