<template>
	<div class="row">
		<div class="col-12">
			<div class="accordion" role="tablist">
				<b-card no-body class="noteSearchPanel mb-0">
					<b-card-header
						header-tag="header"
						class="p-0"
						role="tab">
						<b-button
							block						
							class="collapseBTN text-truncate"
							v-on:click="Accordion1Click"
							><b-icon
								icon="arrow-down-right-circle"
								aria-hidden="true"
							></b-icon>
							{{ $t('Scheduler Notes') }} - 
							<span v-if="this.RegistrationInfo.patientName">{{this.RegistrationInfo.patientName+' - '}}</span>
							<span v-if="this.$route.params.id">{{this.$route.params.id + ' - '}}</span>
							<span v-if="this.RegistrationInfo.dob"> {{ $t('Shared.Dob') }} {{this.RegistrationInfo.dob}}</span>
							<span v-if="this.RegistrationInfo.ageFormatted"> {{ $t('Shared.Age') }} {{this.RegistrationInfo.ageFormatted }}</span>
							<span v-if="this.RegistrationInfo.programName"> - {{this.RegistrationInfo.programName}}  </span>
							<span v-if="this.RegistrationInfo.dateFrom"> - {{this.RegistrationInfo.dateFrom}}  </span>
							<span v-if="this.RegistrationInfo.dateTo"> - {{this.RegistrationInfo.dateTo}}  </span>
							<span v-else>- {{ $t('Shared.Active') }}  </span>
							</b-button>
					</b-card-header>
					<b-collapse
						id="accordion-1"
						visible
						accordion="ClinicalNotes"
						:class="this.ClassAccordion1"
						role="tabpanel"
						v-show="ShowFilter && !ShowNote"
						>
						<b-card-body class="p-1">
							<b-card-text class="notesPanelsClinical cutescroll">
								<b-row>
									<b-col md="3" lg="3" sm="12">
										<MdEdit 
											v-bind:Label="$t('Shared.datefrom')"
											v-bind:TypeProp="'date'" 
											v-bind:Name="'DateFrom'"
											v-model="DateFrom"
											v-bind:Min="'1900-01-01'"
											v-bind:Max="'2200-12-31'"
											v-on:blur="DateFromChange($event)"
											v-bind:Id="'input_date_from'">                                     
										</MdEdit>
									</b-col>
									<b-col md="3" lg="3" sm="12">
										<MdEdit 
											v-bind:Label="$t('Shared.dateTo')"
											v-bind:TypeProp="'date'" 
											v-bind:Name="'DateTo'"
											v-model="DateTo"
											v-bind:Min="'1900-01-01'"
											v-bind:Max="'2200-12-31'"
											v-on:blur="DateToChange($event)"
											v-bind:Id="'input_date_to'">                                     
										</MdEdit>
									</b-col>
									<b-col md="3" lg="3" sm="12">													
											<div class="form-group">
												<MdSelect
													v-bind:Label="$t('Shared.AppointmentType')"
													v-bind:Options="AppointmentTypesOptions"
													v-bind:FieldDescription="'description'"
													v-bind:FieldCode="'code'"
													v-bind:Model="appointmentTypes"                       
													v-bind:Multiple="true"
													:Input="ChangeDropDownAppointmentType"
												/>
											</div>												
									</b-col>
									<b-col md="3" lg="3" sm="12">
										<div class="form-group">
											<MdSelect
												v-bind:Label="$tc('Shared.Employee',2)"
												v-bind:Options="employeesOptions"
												v-bind:FieldDescription="'employeeName'"
												v-bind:FieldCode="'employeeCode'"
												v-bind:Model="employees"                       
												v-bind:Multiple="true"
												:Input="ChangeDropDownEmployees"
											/>
											</div>		
									</b-col>											
								</b-row>
								<b-row>
									<b-col md="12">
										<div class="text-center text-md-right mt-3">
											<button 
												v-on:click="SearchNotes"
												type="button"
												class="btn btn-primary btn-login mx-2 pull-right"												
												id="btnsearchinput">
												{{ $t('Shared.Search') }}
											</button>
										</div>
									</b-col>
								</b-row>
								<b-row>
									<b-col sm="12" lg="12" md="12">
										<MdTitle v-bind:Text="$t('Shared.SearchResult')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
										<MdTable
											v-bind:fields="fields"
											v-bind:items="items"
											v-bind:totalRows="totalRows"
											v-bind:useAddTime="true"
											v-bind:totalRowsSubItems="totalRowsSubItems"
											v-bind:showMessageIsEmpty="ShowMessageIsEmpty"
											v-bind:isReadOnly="false"
											v-bind:selectMode="selectMode"
											v-bind:currentPage="currentPage"
											v-bind:currentPageSubItems="currentPageSubItems"
											v-bind:perPage="perPage"
											v-bind:perPageSubItems="perPageSubItems"
											v-bind:pageOptions="pageOptions"
											v-bind:pageOptionsSubItems="pageOptionsSubItems"
											v-bind:refTable="refTable"
											v-bind:IsSchedulerNotes="true"
											v-bind:FieldsDate="FieldsDateArr"
											:onRowSelected="onRowSelected"
											:onFiltered="onFiltered"
											:pageChanged="pageChanged"
											:pageChangedSubItems="pageChangedSubItems"
											v-bind:ShowDetails="true"
											:perPageFunction="PerPageFunction"
											:perPageFunctionSubItems="PerPageFunctionSubItems"
											:avisar="onResultados"
											:CheckClick="onCheck"
											:NotePatientInfo="PatientInfo"
											:ondblClick="ondblClick"
										>
										</MdTable>
									</b-col>
								</b-row>
							</b-card-text>
						</b-card-body>
					</b-collapse>
				</b-card>

				<b-card no-body class="openNotesPanel mb-0">
					<b-card-header
						header-tag="header"
						class="p-0"
						role="tab">
						<b-button
							block							
							class="collapseBTN"
							v-on:click="ClickBtnAccordion2"
							><b-icon
								icon="arrow-down-right-circle"
								aria-hidden="true"
							></b-icon>
							{{ $t('ClinicalNotes.OPENNOTES') }}</b-button>
					</b-card-header>
					<b-collapse
						id="accordion-2"
						accordion="ClinicalNotes"
						role="tabpanel"
						:class="this.ClassAccordion2"
						v-show="ShowNote && !ShowFilter"
						ref="a2">
						<b-card-body class="p-1">
							<b-card-text class="notesPanels">
								<!-- OPEN NOTES CODE -->
								<div :class="this.ClassContainerNotes" ref="ContainerNotes">
								<MdNote
									v-for="(model, index) in this.ClinicalNotesListOpened"
									v-bind:NOTE="model.noteName"										
									v-bind:TransDate="model.date"
									v-bind:Time="model.Time"
									v-bind:EventScheduler="false"
									v-bind:Location="'SchedulerNotes'"
									v-bind:Header="model"
									v-bind:IsChartReview="false"
									v-bind:ProgramCode="ProgramCode"
									v-bind:CategoryCode="TherapiesVModel"									
									:key="model.Id"
									:id="model.Id"
									v-on:remove="CloseNote(index)"
									:model="model"
									v-bind:NotePatientInfo="PatientInfo"
									v-bind:IsSchedulerNote="true">
								</MdNote>
								</div>
								<!-- END OPEN NOTES CODE -->
							</b-card-text>
						</b-card-body>
					</b-collapse>
				</b-card>
			</div>
		</div>
	</div>
</template>

<script>
import EnumService from "@/services/enum.service";
import moment from "moment";
import DBFunctions from '@/common/DBFunctions.js'
import ShowMessage from '@/components/messages/ShowMessage.js'
import Utils from "@/common/utils";
import { EventBus } from '@/event-bus.js';

import ChartReview from '@/services/chartreview.service.js';
import AppointmentTypes from '@/services/appointmenttypes.service'
import CalendarResourcesService from '@/services/calendarresources.service'
import AppointmentService from '@/services/appointment.service'
export default {

	beforeRouteEnter(to, from, next) {
        next(async vm => {
            const iPI = await vm.CanView();
            if(iPI) next()
            else next('/accessdenied');
	}) 
  },
	
	beforeRouteLeave(to, from, next) {
	if (this.pendingChanges()) {
		this.$bvModal.msgBoxConfirm(this.$t('Msg.LeavingWithoutSaving'), {
          title: this.$t('ClinicalNotes.ClinicalNotes'),
          okTitle: this.$t('Shared.BtnSave'),
          cancelTitle: this.$t('Shared.BtnDismiss'),
          footerClass: 'p-2',
          titleClass: 'modal-title modal-type-warning'
        })
          .then(value => {
            if(!value){
              next();
            } else {
				EventBus.$emit('saveFromClinicalNotes', to);
              //return;
            }
          })		
	} else {
		next()
	}
    },
	mixins: [DBFunctions,ShowMessage],
	name: "SchedulerClinical",
	data() {
		return {
			NoteOptions:{},
			PatientInfo:{},
			permission:[],
			isValidCategories:true,
			Menu : this.$store.state.leftMenu,
			ClinicalNotesListOpened:[],
			ProgramCode:'',
			LabelTherapies:'',
			EmployeeCode:'',			
			OptionTherapies: [],
			TherapiesVModel: "",
			OptionNoteTypes: [],
			NoteTypesVModel: "",
			selectPlaceholder: this.$t('ClinicalNotes.SelectOptions'),			
			ClassContainerNotes:'',
			ClassAccordion1:'',
			ClassAccordion2:'',
            ShowNote:false,
			ShowFilter:true,
			ExistsNote:false,
			Note1:{
				Visible:false,
				Visited:false
			},
			pageOptions: [5, 10, 15],
			pageOptionsSubItems: [5, 10, 15],
			totalRows: 0,
			totalRowsSubItems: 0,
			currentPage: 1,
			currentPageSubItems: 1,
			perPage: 10,
			perPageSubItems: 5,
			filter: null,
			filterOn: [],
			activityId:'',
			items: [],
			selectMode: "single",
			selected: [],
			Header: {},			
			refTable: "selectableTable",
			CountNotesOpened:0,
			DateFrom: '',
			DateTo: '',
			dateArr: [],
			NoteDates:[],
			RegistrationInfo:{},
			parameter:{},
			OptionsRightMenu:{
			visible:true,
			ItemsList:[],
			btnvisible:true,
			itemdisabled: false,		
			PatientCode:''			
		},
			ShowMessageIsEmpty: false,
			fields: [
				{ key: 'show_details', label: this.$t('ClinicalNotes.ShowDetails'), sortable: true},
				{ key: 'appointmentType', label: 'Appointment Type', sortable: true},
				{ key: 'startDate', label: 'Start Date', sortable: true},
				{ key: 'endDate', label: 'End Date', sortable: true},
				{ key:'therapistName', label: 'Therapist', sortable: true},
			],

			//Use by Scheduler Notes
			AppointmentTypesOptions: [{}],
			appointmentTypes: null,
			appointmentTypesParam: null,
			employeesOptions: [{}],
            employees: null,
			employeesParam: null,
			FieldsDateArr:['startDate','endDate'],
		};
	},

	methods: {

	//Methods use by Scheduler Notes
	
	ondblClick() {

	},
	
	getAppointmentTypes() { 
        AppointmentTypes.getAllLinkedWithNotes()
            .then((response) => {
                if(response.status == '200' && response.data && response.data.length > 0){
                  this.AppointmentTypesOptions = response.data;
                }
            })
            .catch(error => {
                if (!error.response) {
                // network error
                  /* this.showMessage(this.$t('schedulerDailyRep.ReportTitle'),this.$t('Msg.RetrieveInformation'),"error");
                } else {
                  this.showMessage(this.$t('schedulerDailyRep.ReportTitle'),error,"error"); */
                }
            });
    },

	getEmployees() { 
        CalendarResourcesService.getAll()
            .then((response) => {
				if(response.status == '200' && response.data && response.data.length > 0){
                  this.employeesOptions = response.data;
                }
            })
            .catch(error => {
                if (!error.response) {
                // network error
                  this.showMessage(this.$t('schedulerDailyRep.ReportTitle'),this.$t('Msg.RetrieveInformation'),"error");
                } else {
                  this.showMessage(this.$t('schedulerDailyRep.ReportTitle'),error,"error");
                }
              });
	},

	ChangeDropDownAppointmentType(value) {
        this.appointmentTypes = value
		let tmp = [];
        value.forEach(function(item) {   
			tmp.push(item.code.trim());               
        });
        this.appointmentTypesParam = tmp;
		this.getNoteList() 
    },

	ChangeDropDownEmployees(value) {
        this.employees = value
        let tmp= [];
        value.forEach(function(item) {   
            tmp.push(item.employeeCode.trim());               
        });
        this.employeesParam = tmp; 
		this.getNoteList()
    },

	getNoteList(){
		this.parameter.PageNumber = this.currentPage;
		this.parameter.PageSize = this.perPage;
		this.parameter.PatientCode = this.$route.params.id;
		this.parameter.DateFrom = this.DateFrom;
		this.parameter.DateTo = this.DateTo;
		this.parameter.EmployeeCodeList = this.employeesParam
		this.parameter.AppointmentTypeList = this.appointmentTypesParam
		this.parameter.OrderBy = 'DateDesc'

		if(this.DateFrom === ''){
			this.showMessage(this.$t('ClinicalNotes.ClinicalListing'),this.$t('ClinicalNotes.DateFromRequired'),"error");
		}else if(this.DateTo === ''){
			this.showMessage(this.$t('ClinicalNotes.ClinicalListing'),this.$t('ClinicalNotes.DateToRequired'),"error");
		}else{
			this.$emit('load', true);	
			AppointmentService.getSchedulerNotes(this.parameter)
                .then((response) => {
                     if(response.status == '200' && response.data){
						this.items = response.data;
						this.items.forEach(function(item) {
						if (item.startDate)	
							item.startDate = `${Utils.formatterDateTimeToString(item.startDate)}`;
						if (item.endDate)	
							item.endDate = `${Utils.formatterDateTimeToString(item.endDate)}`;
						});
                       if(response.data.length > 0){                
                        let headerString = response.headers["x-pagination"];					
                        this.Header = JSON.parse(headerString);
                        this.totalRows = this.Header.TotalCount;
                       } 
                     }                     
						this.$emit('load', false);
						if (this.items.length === 0) {
							this.ShowMessageIsEmpty = true;
						}
                })
                .catch((error) => {                    
                    this.$emit('load', false);
                    if (!error.response) {
                      this.showMessage(this.$t('ClinicalNotes.ClinicalListing'),this.$t('Msg.retrieveInfo'),"error");
                    } else {
                      this.showMessage(this.$t('ClinicalNotes.ClinicalListing'),error,"error");
                    }
                }); 
		}
    },

	SearchNotes() {			
		this.dateArr = [];
		this.items = [];
		this.totalRows = this.items.length;    
		this.currentPage = 1
		
		var DF = moment(this.DateFrom).format('YYYY/MM/DD');
		var DT = moment(this.DateTo).format('YYYY/MM/DD');
		var DateTo = "";
		var DD = null;
		var AD = moment(this.RegistrationInfo.dateFrom).format('YYYY/MM/DD');

		if(this.RegistrationInfo.dateTo){
			DD = moment(this.RegistrationInfo.dateTo).format('YYYY/MM/DD');
			DateTo = this.RegistrationInfo.dateTo;
		}
		if(DF < AD){			
			this.showMessage(this.$t('ClinicalNotes.ValidationForm'),this.$t('ClinicalNotes.DateFromBefore', {DateFrom: moment(this.DateFrom).format('MM/DD/YYYY'), AdmitDate: moment(AD).format('MM/DD/YYYY')}),"error");				
		}
		else if(this.RegistrationInfo.dateTo && DD !== null && DT > DD){				
			this.showMessage(this.$t('ClinicalNotes.ValidationForm'),this.$t('ClinicalNotes.DateToAfter', {DateTo1: moment(this.DateTo).format('MM/DD/YYYY'), DateTo2: DateTo}),"error");			
		}else{
			this.parameter.PageNumber = this.currentPage;        
			this.getNoteList();
		}	
	},

	//Methods of Clinical Notes component

	CanView(){
      return this.permission[0].roleCanView;
	},

	async getPatientInfo(){
		this.PatientInfo = await this.getData(this.$route.params.id);
	},
	async getProgramCodeByRecordId(){
		this.RegistrationInfo = await this.getRegistrationInfoTitle(this.$route.params.recordid);
		if(this.RegistrationInfo.dateFrom)
			this.RegistrationInfo.dateFrom = moment(this.RegistrationInfo.dateFrom).format('MM/DD/YYYY');
		if(this.RegistrationInfo.dateTo)
			this.RegistrationInfo.dateTo = moment(this.RegistrationInfo.dateTo).format('MM/DD/YYYY');


		if(this.RegistrationInfo.dob)
			this.RegistrationInfo.dob =  moment(this.RegistrationInfo.dob).format('MM/DD/YYYY');

		this.RegistrationInfo.age = this.RegistrationInfo.age === 0 ? "0" : this.RegistrationInfo.age;	
		
		this.ProgramCode = this.RegistrationInfo.programCode;
	},

	async getNoteOption(){
		this.NoteOptions = await this.getNoteOptions();   
		this.LabelTherapies = this.NoteOptions.useCategory?this.$t('ClinicalNotes.CATEGORIES'):this.$t('ClinicalNotes.THERAPIES');
    },

	ChangeDropDownNoteTypes(types){
     var l = types.length;
     var NoteTypes = [];
     if(l>0){
       var i = 0;
        types.forEach(function(e) {   
        if(i<l-1){
          NoteTypes += e.notesid+",";
        }else{
          NoteTypes += e.notesid;
        }
        i++;                    
        });
        this.parameter.NoteTypes = NoteTypes;
    }else{
      this.parameter.NoteTypes = [];
    }    
    },

	DateFromChange(){
		this.getNoteList()	
	},
	DateToChange(){		
		this.getNoteList()
	},

	AdjustNoteContainer(Operation){
		var CountNotes = 0;		
		CountNotes = Operation === 'ADD'?this.$refs.ContainerNotes.children.length+1:this.$refs.ContainerNotes.children.length-1;
		
			if(CountNotes === 1){
				this.ClassContainerNotes = 'cutescroll';
			}

			if(CountNotes > 1){
				this.ClassContainerNotes = 'notesHolder cutescroll';
			}
	},

	Accordion1Click(){
		this.ShowFilter = !this.ShowFilter;
		this.ShowNote = !this.ShowNote;	
	},

	ClickBtnAccordion2(){
		this.ShowFilter = !this.ShowFilter;
		this.ShowNote = !this.ShowNote;	
	},

	onResultados(obj) {
		this.$emit('load', true);		
		obj.DateFrom = this.RegistrationInfo.dateFrom ? moment(this.RegistrationInfo.dateFrom).format(): null;
		this.ExistsNote = this.ClinicalNotesListOpened.some(nItem => ( ((nItem.notesId === obj.notesId) && (nItem.Id === obj.Id)) ||
																		(((nItem.frequency != 'DAILY MULTIPLES') && (nItem.notesId === obj.notesId) && (nItem.transDate === obj.transDate)) ||
																		((nItem.frequency === 'DAILY MULTIPLES') && (nItem.notesId === obj.notesId) && (nItem.transDate === obj.transDate) && (nItem.noteTime === obj.noteTime)))));																	  
		
		if (!this.ExistsNote) {
			this.AdjustNoteContainer("ADD");
			this.ClinicalNotesListOpened.push(obj);
		}
		
		this.ClassAccordion2 = 'show';
        this.ShowNote = true;
		this.ShowFilter = false;
		// a short time for loading wheel
		setTimeout(() => this.$emit('load', false), 500);
	},
	CloseNote(index){		
		this.ClinicalNotesListOpened.splice(index, 1)
		this.AdjustNoteContainer("REMOVE");
		if(this.ClinicalNotesListOpened.length == 0){
			this.ShowFilter = true;
			this.ShowNote = false;
		}
	},
	selectRow(row) {
		row.toggleDetails();
	},

	ShowDetail(row) {
		row.toggleDetails();
	},

	pageChanged(page) {            
      this.currentPage = page;
		this.parameter.PageNumber = this.currentPage;        
      this.getNoteList();
    },
	pageChangedSubItems(e) {            
      this.currentPageSubItems = e.page;	
      this.getNoteAllDate(e.NoteId,this.currentPageSubItems,this.perPageSubItems);
    },


    PerPageFunction(perpage){
      this.perPage = perpage;
		this.parameter.PageNumber = 1;
      this.getNoteList();
    },
	PerPageFunctionSubItems(e){
		this.perPageSubItems = e.page;     
		this.getNoteAllDate(e.NoteId,1,this.perPageSubItems);		
    },
	onRowSelected(items) {
      this.selected = items
    },

    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
    },

    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },

    selectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.selectRow(2)
    },
	unselectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.unselectRow(2)
    },

	ChangeDropDownTherapies(value){
            this.TherapiesVModel = value ? value.code : '';
        },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

	onCheck(param){
		this.activityId = param.activityId;
		this.currentPageSubItems = param.currentPage;
		this.perPageSubItems = 5;
		this.getNoteAllDate(this.activityId,this.currentPageSubItems,this.perPageSubItems);
	},

	NoteIsDailyMultiple(NoteId){
		var result = false;
		this.items.forEach(element => {
			if(element.notesId === NoteId){	
				result = element.frequency.toLowerCase() === 'DAILY MULTIPLES'.toLowerCase();		
			}
        });
		return result;
	},

	ExistDate(arr,TransDate){
		for(var i = 0; i < arr.length; i++){
			if(arr[i].date === TransDate){
				return true;
			}
		}
		return false;
		//return arr.some(i => ((i.date === TransDate)));
	},

	async getDefaultDateFromAndDateTo(){		
		await ChartReview.getPatientRegistrationsDefaultDates(this.$route.params.recordid)
            .then((response) => {
              if(response.data){
                let defaultDate = response.data;
				this.DateFrom = moment(defaultDate.dateFrom).format('YYYY-MM-DD');
				this.DateTo = moment(defaultDate.dateTo).format('YYYY-MM-DD');				
              }             
            })
            .catch((error) => {
              if (error.response) {
               this.showMessage(this.$t('ClinicalNotes.GetNoteTypes'),error,"error");
              }
            });
	},

	getTimesByDate(arr,date){
		var temparr = [];
		for(var i = 0; i < arr.length; i++){
			if(moment(arr[i].transDate).format("ddd, ll") === date){
				//if(arr[i].noteTime){					
					var obj = {};
					if(arr[i].noteStatus == 'New'){								
								obj.icon = 'ikon med-doc-new mr-2';
								obj.description = this.$t('Shared.IconDescN');				
							}
							else if(arr[i].noteStatus == 'Voided'){
								obj.icon = 'ikon med-doc-cancelled mr-2';
								obj.description = this.$t('Shared.IconDescVN');
							}
							else if(arr[i].noteStatus == 'Pending Signature'){
								obj.icon = 'ikon med-doc-pen-sup-signature  mr-2';
								obj.description = this.$t('Shared.IconDescPS');
							}
							else if(arr[i].noteStatus == 'Pending CosignerSignature'){
								obj.icon = 'ikon med-doc-pen-sup-signature mr-2';
								obj.description = this.$t('Shared.IconDescPCS');
							}else{
								obj.icon = 'ikon med-doc-signed mr-2';
								obj.description = this.$t('Shared.IconDescF');
							}
							obj.Id = arr[i].headerID;
							obj.Time = moment(arr[i].noteTime).format("HH:mm:ss");
							obj.patientCode = this.NoteDates[i].patientCode;	
							obj.isBillable = this.NoteDates[i].isBillable;
							obj.isVoid = this.NoteDates[i].isVoid;
							obj.therapistName = this.NoteDates[i].therapistName;
							obj.coTherapistName = this.NoteDates[i].coTherapistName;
							obj.chartno = this.NoteDates[i].chartno;
							obj.defaultUnit = this.NoteDates[i].defaultUnit;
							obj.patientCode = this.NoteDates[i].patientCode;
							obj.coTherapistCode = this.NoteDates[i].coTherapistCode;
							obj.disableBillable = this.NoteDates[i].disableBillable;
							obj.showBilling = this.NoteDates[i].showBilling;
							obj.frequency = this.NoteDates[i].frequency;
							obj.enableEdit = this.NoteDates[i].enableEdit;
							obj.userHasReadOnlyAccess = this.NoteDates[i].userHasReadOnlyAccess;
							obj.customFrame = this.NoteDates[i].customFrame;
							obj.allowCosignNotesDoneByCosigners = this.NoteDates[i].allowCosignNotesDoneByCosigners;
							if(this.NoteDates[i].coSignedDate){
								//obj.coSignedDate = Utils.formatterDateTimeToString(this.NoteDates[i].coSignedDate);
								obj.coSignedDate = moment(this.NoteDates[i].coSignedDate).format("ddd, ll");
							}else{
								obj.coSignedDate = null;
							}
							
							obj.endTime = this.NoteDates[i].endTime;
							obj.facilityCode = this.NoteDates[i].facilityCode;
							obj.isCoSigned = this.NoteDates[i].isCoSigned;
							obj.isPendingCoSignature = this.NoteDates[i].isPendingCoSignature;
							obj.isPendingSignature = this.NoteDates[i].isPendingSignature;
							obj.isSigned = this.NoteDates[i].isSigned;
							obj.noteStatus = this.NoteDates[i].noteStatus;
							obj.noteTime = this.NoteDates[i].noteTime;
							//obj.therapyCode = 
							if(this.NoteDates[i].serviceDate){
								obj.serviceDate = Utils.formatterDate(this.NoteDates[i].serviceDate);
							}else{
								obj.serviceDate = null;
							}
							if(this.NoteDates[i].signedDate){
								//obj.signedDate = Utils.formatterDateTimeToString(this.NoteDates[i].signedDate);
								obj.signedDate = moment(this.NoteDates[i].signedDate).format("ddd, ll");
							}else{
								obj.signedDate = null;
							}

							if(this.NoteDates[i].noteTime === null)
								obj.isBase = true;
							
							//obj.signedDate = this.NoteDates[i].signedDate;
							obj.startTime = this.NoteDates[i].startTime;
							obj.therapistCode = this.NoteDates[i].therapistCode;
							obj.transCode = this.NoteDates[i].transCode;
							obj.transDate = this.NoteDates[i].transDate;
							obj.notesContents = this.NoteDates[i].notesContents;
					temparr.push(obj);
				//}
				
			}
		}
		return temparr;
	},

	async getNoteAllDate(activityId,currentPageSubItems,perPageSubItems){
		let parameters = {}
		parameters.PageNumber = currentPageSubItems;           
        parameters.PageSize = perPageSubItems;
		parameters.PatientCode = this.$route.params.id;
		parameters.ActivityId = activityId;

		var SubItems = [];
		var Exist = false;
		this.$emit('load', true);
      await AppointmentService.getNotesByActId(parameters)
                .then((response) => {
                     if(response.status == '200' && response.data){
                        this.NoteDates = response.data;	
						var l = this.NoteDates.length;
						let headerString = response.headers["x-pagination"];
						this.Header = JSON.parse(headerString);
						var totalRowsSubItems = this.Header.TotalCount;					
						var Pagination = {};				
						var obj = {};
					
						Pagination.currentPageSubItems = currentPageSubItems;
						Pagination.perPageSubItems = perPageSubItems;
						Pagination.totalRowsSubItems = totalRowsSubItems;
						if(l > 0){
							if(this.NoteIsDailyMultiple(activityId)){
								for(var i = 0; i<l; i++){
							
							var TransD = moment(this.NoteDates[i].transDate).format("ddd, ll");
							Exist = this.ExistDate(SubItems,TransD);
							obj.ArrTimes = this.getTimesByDate(this.NoteDates, TransD);		
							obj.allowCosignNotesDoneByCosigners = this.NoteDates[i].allowCosignNotesDoneByCosigners;	
							obj.date = TransD;

							if(!Exist)
								SubItems.push(obj);

							obj = {};
						}
							}else{
								for(var j = 0; j<l; j++){
							obj = {
								patientCode:'',
								date:'',
								therapistName:"",
								coTherapistName:"",
								icon:'',
								allowCosignNotesDoneByCosigners:false,
								description:'',
								Id:'',
								isBillable:false,
								defaultUnit:0,
								isVoid:false,
								coTherapistCode:null,
								coSignedDate:null,
								frequency:"",
								endTime:null,
								facilityCode:"",
								isCoSigned:null,
								isPendingCoSignature:null,
								isPendingSignature:null,
								isSigned:null,
								noteStatus:"",
								noteTime:null,							
								chartno:"",
								serviceDate:null,
								signedDate:null,
								startTime:null,
								therapistCode:"",
								transCode:"",
								transDate:null,
								notesContents:"",
								disableBillable:false,
								showBilling:false,
								userHasReadOnlyAccess:false,
								enableEdit:true,
								customFrame:"",
								notesId:'',
								activityId:'',
								noteName:''
							};

							obj.patientCode = this.NoteDates[j].patientCode;
							obj.therapistName = this.NoteDates[j].therapistName;
							obj.coTherapistName = this.NoteDates[j].coTherapistName;
							obj.date = moment(this.NoteDates[j].transDate).format("ddd, ll");
							obj.Id = this.NoteDates[j].headerID;
							obj.allowCosignNotesDoneByCosigners = this.NoteDates[j].allowCosignNotesDoneByCosigners;
							obj.isBillable = this.NoteDates[j].isBillable;
							obj.isVoid = this.NoteDates[j].isVoid;
							obj.coTherapistCode = this.NoteDates[j].coTherapistCode;
							obj.disableBillable = this.NoteDates[j].disableBillable;
							obj.defaultUnit = this.NoteDates[j].defaultUnit;
							obj.showBilling = this.NoteDates[j].showBilling;
							obj.chartno = this.NoteDates[j].chartno;
							obj.frequency = this.NoteDates[j].frequency;
							obj.enableEdit = this.NoteDates[j].enableEdit;
							obj.customFrame = this.NoteDates[j].customFrame;
							obj.userHasReadOnlyAccess = this.NoteDates[j].userHasReadOnlyAccess;							
							if(this.NoteDates[j].coSignedDate){
								//obj.coSignedDate = Utils.formatterDateTimeToString(this.NoteDates[j].coSignedDate);
								obj.coSignedDate = moment(this.NoteDates[j].coSignedDate).format("ddd, ll");
							}
							obj.endTime = this.NoteDates[j].endTime;
							obj.facilityCode = this.NoteDates[j].facilityCode;
							obj.isCoSigned = this.NoteDates[j].isCoSigned;
							obj.isPendingCoSignature = this.NoteDates[j].isPendingCoSignature;
							obj.isPendingSignature = this.NoteDates[j].isPendingSignature;
							obj.isSigned = this.NoteDates[j].isSigned;
							obj.noteStatus = this.NoteDates[j].noteStatus;
							obj.noteTime = this.NoteDates[j].noteTime;
							if(this.NoteDates[j].serviceDate){
								obj.serviceDate = Utils.formatterDate(this.NoteDates[j].serviceDate);
							}
							if(this.NoteDates[j].signedDate){
								//obj.signedDate = Utils.formatterDateTimeToString(this.NoteDates[j].signedDate);
								obj.signedDate = moment(this.NoteDates[j].signedDate).format("ddd, ll");
							}							
							
							obj.startTime = this.NoteDates[j].startTime;
							obj.therapistCode = this.NoteDates[j].therapistCode;
							obj.transCode = this.NoteDates[j].transCode;
							obj.transDate = this.NoteDates[j].transDate;
							obj.notesContents = this.NoteDates[j].notesContents;
							obj.notesId = this.NoteDates[j].notesID;
							obj.activityId = activityId
							obj.noteName = this.NoteDates[j].description;
							if(this.NoteDates[j].noteStatus == 'New'){								
								obj.icon = 'icon-doc mr-2';
								obj.description = this.$t('Shared.IconDescN');				
							}
							else if(this.NoteDates[j].noteStatus == 'Voided'){
								obj.icon = 'icon-ban mr-2';
								obj.description = this.$t('Shared.IconDescVN');
							}
							else if(this.NoteDates[j].noteStatus == 'Pending Signature'){
								obj.icon = 'icon-note  mr-2';
								obj.description = this.$t('Shared.IconDescPS');
							}
							else if(this.NoteDates[j].noteStatus == 'Pending Cosigner Signature'){
								obj.icon = 'icon-user mr-2';
								obj.description = this.$t('Shared.IconDescPCS');
							}else{
								obj.icon = 'icon-check mr-2';
								obj.description = this.$t('Shared.IconDescF');
							}
							SubItems.push(obj);
						}
							}
						} 
						this.AddSubItemsbyRow(activityId,SubItems,Pagination);                     
                     }                     
                      this.$emit('load', false);
                })
                .catch((error) => {                    
                    this.$emit('load', false);
                    if (!error.response) {
                      this.showMessage(this.$t('ClinicalNotes.ClinicalGetAllDates'),this.$t('Msg.retrieveInfo'),"error");
                    } else {
                      this.showMessage(this.$t('ClinicalNotes.ClinicalGetAllDates'),error,"error");
                    }
                }); 
     },

	

	AddSubItemsbyRow(activityId,SubItems,Pagination){		
		this.items.forEach(element => {
			if(element.activityId === activityId){				
				this.$set(element, 'currentPageSubItems', Pagination.currentPageSubItems);
				this.$set(element, 'perPageSubItems', Pagination.perPageSubItems);
				this.$set(element, 'totalRowsSubItems', Pagination.totalRowsSubItems);
				this.$set(element, 'SubItems', SubItems);
			}
        });		
	},

		

		pendingChanges(){
			let notesEditing = 0
			this.ClinicalNotesListOpened.forEach(element => {				
				notesEditing = element.isEditing && !element.userHasReadOnlyAccess? notesEditing + 1 : notesEditing
			});
			return notesEditing > 0 ? true : false
		},

		beforeWindowUnload(e) {
			if (this.pendingChanges()) {
				e.preventDefault()
				e.returnValue = ''	
			}
		}
	},

	updated() {
	},

	async created(){
		this.getPermissionLeftMenu(this.Menu,this.$route.path,this.permission);
		//this.$emit('load', true);
		await this.getDefaultDateFromAndDateTo();
		this.getAppointmentTypes()
		this.getEmployees()
		this.EmployeeCode = this.$store.state.employeeCode;
		await this.getProgramCodeByRecordId();
		this.getNoteOption();
		this.SearchNotes()		
		EventBus.$on("onNoteIsEditing", function (e) {     
			this.ClinicalNotesListOpened.map(function(note){
			if(note.Id == e.NoteId){
				note.isEditing = e.Status;
			}
            return note;
          });
		}.bind(this));
		window.addEventListener('beforeunload', this.beforeWindowUnload)
	},

	beforeDestroy() {
		window.removeEventListener('beforeunload', this.beforeWindowUnload)
	},

	mounted() {	
		moment.suppressDeprecationWarnings = true; 
		this.parameter.PageNumber = this.currentPage;
		this.getPatientInfo();
		this.OptionsRightMenu.visible = true;
		this.OptionsRightMenu.btnvisible = true;
		this.OptionsRightMenu.itemdisabled = false;
		this.$emit('getVisibleMenu', this.OptionsRightMenu);
		this.$emit('getBtnRightMenu', this.OptionsRightMenu);
		this.OptionsRightMenu.ItemsList = EnumService.ClinicalItems
		this.$emit('getVisibleMenu', this.OptionsRightMenu);
	},

	destroyed(){
      this.OptionsRightMenu.ItemsList = [];
      this.OptionsRightMenu.Mode = false;
      this.OptionsRightMenu.visible = false; 
      this.$emit('getVisibleMenu', this.OptionsRightMenu);

  }
};
</script>

<style src="@/assets/css/clinical/multiselect.css">
.showNote{
	display: auto !important;
}

@import "../../../assets/css/flex.css";
</style>

