<template>
<div>
   <b-modal :id="ModalId" size="lg" :no-close-on-backdrop="true" hide-header :no-close-on-esc="true" ok-only ok-title="Close" centered :title="TitleWindow" :visible="true" @ok="this.emitEventOK">
   <div id="ContainerIWFModal" class="row">
        <div class="col">
          <MdNote
            v-bind:Location="'ClinicalNotes'"
            v-bind:Header="Header"
            v-bind:IsChartReview="false"
            v-bind:TransDate="Header.transDate"
            v-bind:AdmissionRecordId="Header.admissionRecordID"
            v-bind:IsWorkFlow="true"
            v-bind:VisibleNoteHeader="true"
            v-bind:NOTE="TitleWindow"
            v-bind:IncidentID="IncidentID"
            v-bind:IncidentDate="IncidentDate"
            v-bind:ProgramCode="ProgramCode"
            v-bind:CategoryCode="CategoryCode"
            v-bind:NotePatientInfo="NotePatientInfo">
        </MdNote>
      </div>
          
        <div v-if="showIWFSummary" class="col-12 col-lg-6 col-md-6">
          <IncidentWorkflowSummary 
            v-bind:IncidentId="IncidentID">
          </IncidentWorkflowSummary>
        </div>
    </div>
  </b-modal>
 </div>
</template>

<script>
import ShowMessage from '@/components/messages/ShowMessage.js'
import DBFunctions from '@/common/DBFunctions.js'
import { EventBus } from '../../../event-bus.js';

export default {
  name: 'IncidentWorkflowNotesModal', 
  mixins: [ShowMessage, DBFunctions],
  props: {
     TitleWindow : String,
     Header : Object,
     IncidentID : String,
     IncidentDate : String,
     ProgramCode : [String, Number],
     CategoryCode : String,
     NotePatientInfo : Object,
   },
  data() {
    return {
       parameter:{
        SearchCriteria:'',
        PageNumber : 0,
        PageSize:999 ,
        TestCode:'',
        SectionCode:''             
      },
      ModalId: 'IWFModal',
      changesPending: false,
      showIWFSummary: false
    }
  },

  methods: {
      emitEventOK(bvModalEvent) {
        if (this.changesPending) {
          bvModalEvent.preventDefault()
          this.$bvModal.msgBoxConfirm(this.$t('Msg.LeavingWithoutSaving'), {
            title: this.$t('MdNote.SaveChanges'),
            okTitle: this.$t('Shared.BtnSave'),
            cancelTitle: this.$t('Shared.BtnDismiss'),
            footerClass: 'p-2',
            titleClass: 'modal-title modal-type-warning',
            id:'confirmNotes'
          })
            .then(value => {
              if(value){
                EventBus.$emit('saveFromIWF');
                EventBus.$emit('showSummary',false);
                //this.changesPending = false
              } else {
                this.$nextTick(() => {
                  this.$bvModal.hide(this.ModalId)
                })
              }
            })
        }
      },
  },

  created () {
    EventBus.$on("onControlWasChanged", function () { 
      this.changesPending = true
     }.bind(this));

     EventBus.$on("onChanged", function () { 
      this.changesPending = true
     }.bind(this));

     EventBus.$on("updateChangesIWF", function () { 
        this.changesPending = false
     }.bind(this));

     EventBus.$on("showSummary", function (data) { 
        this.showIWFSummary = data
     }.bind(this));
  },

  beforeDestroy() {
    EventBus.$off('onControlWasChanged');
    EventBus.$off('onChanged');
    EventBus.$off('updateChangesIWF');
    EventBus.$off('showSummary');

    this.$root.$off("bv::modal::shown", null);
  },
};

</script>
<style>
  #IWFModal___BV_modal_body_ {
    padding: 5px;
   
  }

  #IWFModal{
    margin-top:42px;
  }
</style>