<template>

<div class="col-md-12 grid-margin stretch-card">
		<div class="card activeCard">
			<div class="card-body">
				<div v-if="Header" class="d-flex align-items-start justify-content-between pointer">
					<h6 class="card-title flex-grow lower-line text-truncate">{{$t('Incident.IncEmeRoom')}}</h6>
				</div>
				<div class="float-left col-md-3 text-muted p-item" v-if="this.Header.ervisit">
					<small>{{$t('Incident.IncPartVisit')}}</small><br>
					<b>{{this.Header.ervisit ? $t('Shared.TitleYes') : 'No'}}</b>
				</div>
				<div class="float-left col-md-3 text-muted p-item" v-if="this.Header.ervisit">
					<small>{{$t('PatientDocument.Comments')}}</small><br>
					<b>{{this.Header.comments}}</b>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

  export default {
    props: {
		Header: Object,
	},

    data() {
      return {
      }
    },
    
  methods:{
  },

  mounted() {
  }
}

</script>