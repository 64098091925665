<template>
   <div id="SchedulerDBActivities">
    <div class="row">
        <div class="col-12">
        <h4 class="page-title text-truncate">
            {{$t('ActList.AddActivities')}} 
              <!--<span v-if="this.RegistrationInfo.patientName">{{this.RegistrationInfo.patientName+' - '}}</span>
              <span v-if="this.$route.params.id">{{this.$route.params.id + ' - '}}</span>
              <span v-if="this.RegistrationInfo.dob"> Dob {{this.RegistrationInfo.dob}}</span>
              <span v-if="this.RegistrationInfo.age"> Age {{this.RegistrationInfo.age}}</span>
              <span v-if="this.RegistrationInfo.programName"> - {{this.RegistrationInfo.programName}}  </span>
              <span v-if="this.RegistrationInfo.dateFrom">- {{this.RegistrationInfo.dateFrom}}  </span>
              <span v-if="this.RegistrationInfo.dateTo">- {{this.RegistrationInfo.dateTo}}  </span>
              <span v-else>- {{ $t('DailyNoteList.Active') }} </span>-->
              </h4>
          <div class="row">
            <div class="col-sm-12 col-md-3">
              <MdButton
                v-bind:Label="$t('ActList.AddActivities')" 
                v-bind:ClassProp="this.isReadOnly ? 'btn btn-primary my-3 btn-block' : 'btn btn-primary my-3 btn-block btn-login'"
                v-bind:IconClass="'ti-plus pr-1'"
                v-on:click="Add"                
                v-bind:Name="'btn_add_activities'">
              </MdButton>  
            </div>
            <div class="col-sm-12 col-md-9 mb-4">
                <div class="textlabel" :data-label="this.$t('Shared.SearchElements')">
                    <div class="input-group">
                        <input 
                          type="text" 
                          class="form-control"
                          v-model="parameter.SearchCriteria"
                          @keyup.enter="getListing"
                        >
                        <div class="input-group-append">
                              <MdButton 
                              v-bind:Name="'btnsearchinput'"
                              v-bind:VariantProp="'btn btn-sm search-attached'"
                              v-bind:IconClass="'mdi mdi-magnify'"
                              v-on:click="getListing">
                            </MdButton>
                        </div>
                    </div>
                </div>
            </div>
          </div>            
          </div>
    </div>

    <div class="row">
        <div class="col-12 mt-2">
          <MdTitle v-bind:Text="$t('Shared.SearchResult')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
          <div v-if="showEmptyRecords" v-bind:style="emptyRecordsStyle" role="alert" aria-live="polite"><div class="text-center my-2">There are no records to show</div></div>
          <div v-for="(item,index) in this.activitiesList" :key="index">
            <div class="row dailyNotes">
              <div class="col-2 mt-1">
                <b-icon @click="Edit(item)" icon="pencil-fill" v-b-tooltip.hover.top="$t('Shared.Edit')" class="rounded-circle tableActionBTN mr-2" scale=".5" font-scale="1.6" variant="light"></b-icon> 
                <b-icon icon="trash-fill" @click="Remove(item.recId)" v-b-tooltip.hover.top="$t('Shared.Delete')" class="rounded-circle tableActionBTN mr-2" scale=".5" font-scale="1.6" variant="light"></b-icon> 
                <b-icon icon="printer-fill" @click="showReport(item)" v-b-tooltip.hover.top="$t('Shared.Report')" class="rounded-circle tableActionBTN mr-2" scale=".5" font-scale="1.6" variant="light"></b-icon>                
              </div>
              <div class="col-4">
                <small>{{ $t('DailyNoteList.DateAndTime') }}</small><br>{{item.datechanged}}
              </div>
              <div class="col-3">
                <small>{{ $t('ActList.User') }}</small><br>{{item.userid }}
              </div>
              <div class="col-3">
                <small>{{ $t('ActList.Alert') }}</small><br>{{item.isAlert }}
              </div>
            </div>
  
            <div class="row dailyNotesContent">
              <div v-html="item.memoinfo" class="col-12"></div>
            </div>
          </div>
        </div>
    </div>

    <hr/>
    <div class="row mt-4">
      <div class="my-1 col-sm-7 col-md-6">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          @change="onChange"
          pills
          size="lg"
        ></b-pagination>
      </div>
      <div align="right" class="mr-2 col">
        {{ $t('Shared.Show') }}
        <b-form-select 
          v-model="perPage" 
          :options="options" 
          size="sm" 
          id="perPageSelect"
          v-bind:style="perPageStyle"
          @change="perPageChange">
        </b-form-select>
        of {{totalRows}}
      </div>
    </div>


   </div>
</template>

<script>
import ActivitiesService from '@/services/activities.service'
import Utils from '@/common/utils'
import ShowMessage from '@/components/messages/ShowMessage.js'
export default {
  name: 'ActivityList',
  mixins: [ShowMessage],
  data(){
    return {
        isReadOnly:false,
        emptyRecordsStyle: {
        backgroundColor: '#CF0',
        fontWeight: 'bold !important',
        textTransform: 'uppercase',
        padding: '2px',
        borderRadius: '50px',
        animation: 'fadeOut 10s ease-in-out infinite'
      },
      perPageStyle: {
        width: 'auto'
      },
      options: [
          { value: 5, text: '5' },
          { value: 10, text: '10' },
          { value: 15, text: '15' },
      ],
      showEmptyRecords: false,
        parameter:{
        SearchCriteria:'',
        PageNumber : 1,
        PageSize:10,        
        PatientCode:'',    
      },
      Header:{},
      currentPage: 1,
      perPage: 10,
      totalRows: 0,      
      activitiesList:[],
    }
  },

  methods:{
    getListing(){
        this.$emit('load', true);
      this.parameter.PageNumber = this.currentPage;
      this.parameter.PageSize = this.perPage;
      this.parameter.PatientCode = this.$route.params.id;
        ActivitiesService.getAll(this.parameter)
              .then((response) => {
                if(response.status == '200' && response.data && response.data.length > 0){
                  this.activitiesList = response.data;
                  this.activitiesList.forEach(function(element) {            
                    if (element.datechanged)	
                        element.datechanged = Utils.formatterDateTimeToString(element.datechanged);                  
                    });

                  let headerString = response.headers["x-pagination"];
                  this.Header = JSON.parse(headerString);
                  this.totalRows = this.Header.TotalCount;                  
                }

                this.showEmptyRecords = response.data && response.data.length == 0 ? true : false;

                if(response.data.length === 0){
                  this.totalRows = 0;
                }
                this.$emit('load', false);
              })
              .catch(error => {
                this.$emit('load', false);
                if (!error.response) {
                // network error
                  this.showMessage(this.$t('ActList.Activities'),this.$t('Msg.RetrieveInformation'),"error");
                } else {
                  this.showMessage(this.$t('ActList.Activities'),error,"error");
                }
              });
    },
    onChange(page) {
      this.currentPage = page;   
      this.getListing();
    },
    perPageChange(value) {
      this.perPage = value;    
      this.getListing();
    },
    Add(){
      this.$router.push({ name: "schedulerdb-activitiesAdd", params: { id: this.$route.params.id,actId:this.$route.params.actId } }).catch(()=>{});
    },

    Edit(row){     
      this.$router.push({ name: "schedulerdb-activitiesEdit", params: { id: this.$route.params.id,actId:this.$route.params.actId,code:row.recId }}).catch(()=>{});
    },

    Remove(id){     
        this.$bvModal.msgBoxConfirm(this.$t('Msg.DeleteRecord'), {
					title: this.$t('ActList.Activities'),
					okTitle: this.$t('Shared.TitleDelete'),
					cancelTitle: this.$t('Shared.BtnCancel'),
					footerClass: 'p-2',
					titleClass: 'modal-title modal-type-warning',
          id:'modal_delete_activity'
				})
          .then(value => {
            if(value){
              this.$emit('load', true);
              ActivitiesService.delete(id)
            .then((response) => {
               if(response.status == '200'){
                const index = this.activitiesList.findIndex(item => item.recId === id); 
                  if (~index) {
                    this.activitiesList.splice(index, 1) 
                    this.getListing(); 
                  }
               }
               this.$emit('load', false);
            })
            .catch((error) => {
               this.$emit('load', false);
               if (!error.response) {                 
                  this.showMessage(this.$t('ActList.Activities'),this.$t('Msg.retrieveInfo'),"error");
               } else {
                  this.showMessage(this.$t('ActList.Activities'),error,"error");
               }
            });
            }
          })
    
    },

    showReport(){

    },
  },

  created(){

  },

  mounted(){
    this.getListing();
  }
   
};
</script>

<style scoped>
/*.row ul{
  background-color: coral;
}*/




</style>


