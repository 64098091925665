import ApiService from '@/services/api.service'

const ClaimBatch = {

    getAll(parameter){      
        var param = {};
        var _Url = '/ClaimBatches/';
        param.DateFrom = parameter.DateFrom;
        param.DateTo = parameter.DateTo;
        param.CreateAClaimForEveryServiceDay = parameter.CreateAClaimForEveryServiceDay; 
        param.BillAcrossMonth = parameter.BillAcrossMonth;       
        //if(parameter.PatientCodeList !== ''){
          //_Url +="?"+parameter.PatientCodeList;
          param.PatientCodeList = parameter.PatientCodeList;
        //}   
        //if(parameter.ProgramCodeList !== ''){
         // _Url +="?"+parameter.ProgramCodeList;
         param.ProgramCodeList = parameter.ProgramCodeList;
       // } 
        //if(parameter.TherapistCodeList !== ''){
         // _Url +="?"+parameter.TherapistCodeList;
          param.TherapistCodeList = parameter.TherapistCodeList;
       // } 
        //if(parameter.TherapyCodeList !== ''){
         // _Url +="?"+parameter.TherapyCodeList;
          param.TherapyCodeList = parameter.TherapyCodeList;
        //} 
        //if(parameter.InsuranceCodeList !== ''){
         // _Url +="?"+parameter.InsuranceCodeList;
          param.InsuranceCodeList = parameter.InsuranceCodeList;
       // } 
      const requestData = {
        method: 'get',
        url: _Url,
        params: param
      }
      return ApiService.customRequest(requestData);
    }, 

    getPatients(){
        return ApiService.get('/ClaimBatches/PatientFilter');
    },

    getInsurances(){
        return ApiService.get('/ClaimBatches/InsuranceFilter');
    },

    ApplyBatch(data){     
        return ApiService.post(`/ClaimBatches`,data);  
    },

    getAllAttendances(parameter) {
      var param = {};
      var _Url = '/Attendances/';
        if(parameter.RecNoList !== ''){
          _Url +="?"+parameter.RecNoList;
        }  
        param.SearchCriteria = parameter.SearchCriteria;
        param.PageNumber = parameter.PageNumber;
        param.PageSize = parameter.PageSize;

        const requestData = {
          method: 'get',
          url: _Url,
          params: param,
          headers: {
            
          },
      }      
      return ApiService.customRequest(requestData);
    },
}

export default ClaimBatch;