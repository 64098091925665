<template>
<div id="ContainerRooms"> 
      <h4 v-if="this.$attrs.editMode" class="page-title text-truncate">
        {{$t('rooms.Edit')}}
      </h4>
      <h4 v-if="!this.$attrs.editMode" class="page-title text-truncate">
        {{$t('rooms.Add')}}      
      </h4> 
        <b-row>
            <b-col lg="3" md="3" sm="12">
                 <MdEdit
                     v-model="Rooms.code"                    
                     v-bind:Label="$t('Shared.Code')"          
                     v-bind:isReadOnly="this.$attrs.editMode"
                     v-bind:isRequired="true"
                     v-bind:MaxLengthProp="2"
                     v-bind:isValid="isCodeValid"
                     v-on:blur="OnCodeBlur(Rooms.code)"
                     :InvalidFeedback="$t('rooms.roomRequired')"
                     :SearchFieldCode="'code'"
                     v-bind:Id="'input_r_code'"
                  />
            </b-col>
            <b-col lg="3" md="3" sm="12">
                <MdEdit 
                  v-bind:Label="$t('Shared.Name')"
                  v-bind:TypeProp="'text'" 
                  v-bind:Name="'Name'"  
                  v-bind:MaxLengthProp="32"  
                  v-model="Rooms.name"
                  v-bind:isReadOnly="isReadOnlyRooms"
                  v-bind:Id="'input_r_name'"
                  >                                     
               </MdEdit>
            </b-col>
             <b-col lg="3" md="3" sm="12">
                 <MdLookup
                     v-bind:ModelCode="Rooms.buildingcode"
                     v-bind:ModelDescription="dBuilding"
                     v-bind:Label="$t('Shared.Building')"
                     v-bind:RefCode="'Building'"                     
                     v-bind:Api="'Buildings'"
                     v-bind:FieldCode="'code'"                    
                     v-bind:FieldDescription="'name'"
                     :IsDisabled="isReadOnlyRooms"    
                     :SearchTitle="$t('Shared.Building')"
                     :GetValues="onCodeConditionOk"
                      v-bind:isRequired="true"
                     v-bind:isValid="isbuildingValid"
                     :InvalidFeedback="$t('building.BuildCodeRequired')"
                     :SearchFieldCode="'code'"
                  />
            </b-col>         
            <b-col lg="3" md="3" sm="12">
                 <MdEdit
                     v-model.number="Rooms.capacity"                    
                     v-bind:Label="$t('Shared.Capacity')"     
                     v-bind:TypeProp="'number'"     
                     v-bind:isReadOnly="isReadOnlyRooms"   
                     v-bind:isValid="isCodeValid"                   
                     :SearchFieldCode="'code'"
                  />
            </b-col> 
        </b-row>

        <b-row>

        <b-col lg="3" md="3" sm="12">
                 <MdLookup
                     v-bind:ModelCode="Rooms.cpt"
                     v-bind:ModelDescription="this.dcpt"
                     v-bind:Label="$t('Shared.CPT')"
                     v-bind:RefCode="'cpt'"                     
                     v-bind:Api="'cpts'"
                     v-bind:FieldCode="'code'"                    
                     v-bind:FieldDescription="'description'"
                     :IsDisabled="isReadOnlyRooms"    
                     :SearchTitle="$t('Shared.CPT')"
                     :GetValues="onCodeConditionOk"
                     :InvalidFeedback="$t('rooms.cptRequired')"
                     :SearchFieldCode="'code'"
                  />
            </b-col>  
             <b-col lg="3" md="3" sm="12">
                 <MdLookup
                     v-bind:ModelCode="Rooms.hcpcscode"
                     v-bind:ModelDescription="dHcpcs"
                     v-bind:Label="$t('Shared.HCPCS')"
                     v-bind:RefCode="'Hcpcs'"                     
                     v-bind:Api="'cpts'"
                     v-bind:FieldCode="'code'"                    
                     v-bind:FieldDescription="'description'"
                     :IsDisabled="isReadOnlyRooms"    
                     :SearchTitle="'CPT'"
                     :GetValues="onCodeConditionOk"
                     :InvalidFeedback="$t('rooms.HcpcsRequired')"
                     :SearchFieldCode="'code'"
                  />
            </b-col> 
            <b-col lg="3" md="3" sm="12">
                 <MdLookup
                     v-bind:ModelCode="Rooms.modifier1"
                     v-bind:ModelDescription="dmodifier1"
                     v-bind:Label="$t('rooms.Modifier1')"
                     v-bind:RefCode="'modifiers1'"                     
                     v-bind:Api="'modifiers'"
                     v-bind:FieldCode="'code'"                    
                     v-bind:FieldDescription="'description'"
                     :IsDisabled="isReadOnlyRooms"    
                     :SearchTitle="$t('menu.Modifiers')"
                     :GetValues="onCodeConditionOk"
                     :InvalidFeedback="$t('rooms.MoCodeRequired')"
                     :SearchFieldCode="'code'"
                  />
            </b-col> 
            <b-col lg="3" md="3" sm="12">
                <MdEdit
                     v-model="Rooms.roomPrice"                    
                     v-bind:Label="$t('rooms.RoomPrice')"     
                     v-bind:TypeProp="'number'"     
                     v-bind:isReadOnly="isReadOnlyRooms" 
                     :SearchFieldCode="'code'"
                  />
            </b-col>    
        </b-row>

        <b-row>
         <b-col lg="3" md="3" sm="12">
              <MdCheckBox
                  v-bind:Label="$t('rooms.IsPrivate')"
                  v-bind:Name="'isPrivate'"
                  v-bind:ClassProp="'AlignCheck'"
                  v-bind:CheckedValue="true"
                  v-bind:UncheckedValue="false"
                  v-model="Rooms.isprivate"                 
               />
            </b-col>   
           <b-col lg="3" md="3" sm="12">
              <MdCheckBox
                  v-bind:Label="$t('Shared.Inactive')"
                  v-bind:Name="'Inactive'"
                  v-bind:ClassProp="'AlignCheck'"
                  v-bind:CheckedValue="true"
                  v-bind:UncheckedValue="false"
                  v-model="Rooms.inactive"                 
               />
            </b-col>   
        </b-row>
      


    <div class="page-actions">      
     <MdButton
               v-bind:Label="$t('doctor.List')"
               v-bind:Name="'btnroomslist'"
               v-bind:ClassProp="'secondary mx-2 btn-login'"
               v-on:click="DiscardChanges"/>    
        <MdButton
            v-bind:Label="$t('Shared.Save')"
            v-bind:ClassProp="(this.isReadOnlyRooms)?'primary mx-2':'primary btn-login mx-2'"
            v-on:click="SaveOrEdit"
            v-bind:isReadOnly="isReadOnlyRooms"
            v-bind:Name="'btn_save_rooms'"/>
    </div>

</div>
</template>

<script>


import DBFunctions from '@/common/DBFunctions.js';
import ShowMessage from '@/components/messages/ShowMessage.js'
import RoomsService from '@/services/rooms.service'
import CPTService from '@/services/cpt.service'
import ModifierService from '@/services/modifier.service'
import BuildingService from '@/services/building.service'
import { EventBus } from '../../../../event-bus.js';
//import Utils from '@/common/utils'

let elemSaveBtn = '';
export default {
    beforeRouteEnter(to, from, next) {
        next(async vm => {
            const iPI = await vm.CanView();
            if(iPI) next()
            else next('/accessdenied');
        }) 
  },
  name: 'ROOMS',
 mixins: [ShowMessage, DBFunctions/*,EventBus*/],
  data(){
    return{
        parameter:{
            SearchCriteria:'',
            PageNumber : 1,
            PageSize:0,         
        },
        pendingChanges:false,
     Rooms:{
        code:'',
        buildingcode:'',
        name:'',
        hcpcscode:'',
        isprivate:false,
        inactive:false,
        capacity:0,
        cpt:'',
        modifier1:'',
        roomPrice:'',
     },
     dcpt:'',
     dHcpcs:'',
     dmodifier1:'',
        isReadOnlyRooms:false,
        dBuilding:'',
        permission:[],
        Menu : this.$store.state.leftMenu,
        isbuildingValid:true,   
        isCodeValid:true,   
        CodeExist:false,      
        OptionsRightMenu:{
          visible:false,
          ItemsList:[],
          btnvisible:false,
          itemdisabled: false,
          PatientCode:'',
          Mode:this.edit_mode,
          favButtonAtion:false          
        },

    }
  },

  methods:{
    DiscardChanges(){
       this.checkExistFavorite('/app/rooms');
       this.$router.push({ name: "roomsList" });
    },
    CanView(){
      return this.permission[0].roleCanView;
  },

  onCodeConditionOk(result){
     var type = result.FieldName;
             switch (type) {
                case "Building": 
                  this.Rooms.buildingcode = result.code;                    
                  this.dBuilding = result.description;                    
                  break;
                case "cpt": 
                  this.Rooms.cpt = result.code;                    
                  this.dcpt = result.description;                    
                  break;
                case "Hcpcs": 
                  this.Rooms.hcpcscode = result.code;                    
                  this.dHcpcs = result.description;                    
                  break;
                case "modifiers1": 
                  this.Rooms.modifier1 = result.code;                    
                  this.dmodifier1 = result.description;                    
                  break;
             }
       
  },

  getAllDescription(){
    if(this.Rooms.cpt){
      var CptParam = {
        code:this.Rooms.cpt
      };
      this.$emit('load', true);
      CPTService.getCPTByCode(CptParam)
                .then((response) => {
                    if(response.status == '200' && response.data.length > 0){
                        this.dcpt = response.data[0].description;
                    }                             
                })
                .catch((error) => {   
                    if (!error.response) {
                   this.showMessage("CPT",this.$t('Msg.retrieveInfo'),"error");
                      }                      
                     else if(error.response.status === 400 ){
                        this.showMessage(this.$t('Shared.CPT'),error.response.data.errorMessage,"error");
                     } else if(error.response.status === 500){
                        this.showMessage(this.$t('Shared.CPT'),this.$t('Msg.ThereWereSomeError'),"error");
                     } else{
                         this.showMessage(this.$t('Shared.CPT'),error.response.data.errorMessage,"error");
                     } 
                    this.$emit('load', false);
                }); 
    }
    if(this.Rooms.hcpcscode){
      var hcpcsParam = {
        code:this.Rooms.hcpcscode
      };
      this.$emit('load', true);
      CPTService.getCPTByCode(hcpcsParam)
                .then((response) => {
                    if(response.status == '200' && response.data.length > 0){
                        this.dHcpcs = response.data[0].description;
                    }                             
                })
                .catch((error) => {   
                    if (!error.response) {
                   this.showMessage("hcpcs",this.$t('Msg.retrieveInfo'),"error");
                      }                      
                     else if(error.response.status === 400 ){
                        this.showMessage("hcpcs",error.response.data.errorMessage,"error");
                     } else if(error.response.status === 500){
                        this.showMessage("hcpcs",this.$t('Msg.ThereWereSomeError'),"error");
                     } else{
                         this.showMessage("hcpcs",error.response.data.errorMessage,"error");
                     } 
                    this.$emit('load', false);
                }); 
    }
    if(this.Rooms.modifier1){
      var ModifierParam = {
        code:this.Rooms.modifier1
      };
      this.$emit('load', true);
      ModifierService.getModifierByCode(ModifierParam)
                .then((response) => {
                    if(response.status == '200' && response.data.length > 0){
                        this.dmodifier1 = response.data[0].description;
                    }                             
                })
                .catch((error) => {   
                    if (!error.response) {
                   this.showMessage(this.$t('modifier.ModifierTitle'),this.$t('Msg.retrieveInfo'),"error");
                      }                      
                     else if(error.response.status === 400 ){
                        this.showMessage(this.$t('modifier.ModifierTitle'),error.response.data.errorMessage,"error");
                     } else if(error.response.status === 500){
                        this.showMessage(this.$t('modifier.ModifierTitle'),this.$t('Msg.ThereWereSomeError'),"error");
                     } else{
                         this.showMessage(this.$t('modifier.ModifierTitle'),error.response.data.errorMessage,"error");
                     } 
                    this.$emit('load', false);
                }); 
    }
    if(this.Rooms.buildingcode){
      var BuildingParam = {
        code:this.Rooms.buildingcode
      };
      this.$emit('load', true);
      BuildingService.getBuildingsByCode(BuildingParam)
                .then((response) => {                  
                    if(response.status == '200' && response.data.length > 0){                      
                        this.dBuilding = response.data[0].name;
                    }                             
                })
                .catch((error) => {   
                    if (!error.response) {
                   this.showMessage(this.$t('Shared.Building'),this.$t('Msg.retrieveInfo'),"error");
                      }                      
                     else if(error.response.status === 400 ){
                        this.showMessage(this.$t('Shared.Building'),error.response.data.errorMessage,"error");
                     } else if(error.response.status === 500){
                        this.showMessage(this.$t('Shared.Building'),this.$t('Msg.ThereWereSomeError'),"error");
                     } else{
                         this.showMessage(this.$t('Shared.Building'),error.response.data.errorMessage,"error");
                     } 
                    this.$emit('load', false);
                }); 
    }
  },

    getRooms(){
      if(this.$attrs.editMode){
      this.$emit('load', true);     
      RoomsService.Get(this.$route.params.id)
        .then((response) => {
            if(response.status == '200' && response.data){
              this.Rooms = response.data;  
              this.getAllDescription();
            }   
              this.$emit('load', false);                     
        })
        .catch((error) => {                    
            if (!error.response) {
              this.showMessage(this.$t('Shared.Rooms'),this.$t('Msg.retrieveInfo'),"error");
            }                      
            else if(error.response.status === 400 ){
              this.showMessage(this.$t('Shared.Rooms'),error.response.data.errorMessage,"error");
            }
            else if(error.response.status === 405 ){             
                  this.$router.push({name: "Main"});    
                    setTimeout(() => 
                        this.showPermissionMessage()
                        , 50);            
            } 
            else if(error.response.status === 500){
              this.showMessage(this.$t('Shared.Rooms'),this.$t('Msg.ThereWereSomeError'),"error");
            } else{
              this.showMessage(this.$t('Shared.Rooms'),error.response.data.errorMessage,"error");
            } 
            this.$emit('load', false);            
        }); 
    
      }
    },

    AddRooms(){
      if(!this.CodeExist){
      this.$emit('load', true);
      RoomsService.Add(this.Rooms)
                .then((response) => {
                    if(response.status == '200'){
                        this.Rooms = response.data; 
                        this.pendingChanges = false;   
                        this.$emit('load', false);
                        /*if(!this.OffRoute){
                           this.GotoAdmissionList();
                        }*/
                        this.$router.push({name: "roomsList"}).catch(()=>{});
                      
                        setTimeout(() => 
                        this.$bvToast.toast(this.$t('Msg.SavedSuccessfully'), {
                         title: this.$t('Msg.Saving'),
                          autoHideDelay: 5000,
                          variant: "success"
                        })
                        , 50); 
                    }                             
                })
                .catch((error) => {   
                    if (!error.response) {
                      this.showMessage(this.$t('Shared.Rooms'),this.$t('Msg.retrieveInfo'),"error");
                    }                      
                    else if(error.response.status === 400 ){
                      this.showMessage(this.$t('Shared.Rooms'),error.response.data.errorMessage,"error");
                    } else if(error.response.status === 500){
                      this.showMessage(this.$t('Shared.Rooms'),this.$t('Msg.ThereWereSomeError'),"error");
                    } else{
                        this.showMessage(this.$t('Shared.Rooms'),error.response.data.errorMessage,"error");
                    }
                    elemSaveBtn.removeAttribute("disabled");   
                    this.$emit('load', false);
                }); 
      }else{
         this.showMessage(this.$t('Shared.Rooms'),this.$t('rooms.RoomCodeExist'),"error");
      }
    },


    EditRooms(){
      this.$emit('load', true);
      RoomsService.Update(this.$route.params.id,this.Rooms)
                .then((response) => {
                    if(response.status == '200'){
                        this.Rooms = response.data; 
                        this.pendingChanges = false;   
                        this.$emit('load', false);                        
                        this.$router.push({name: "roomsList"}).catch(()=>{});                      
                        setTimeout(() => 
                        this.$bvToast.toast(this.$t('Msg.SavedSuccessfully'), {
                         title: this.$t('Msg.Saving'),
                          autoHideDelay: 5000,
                          variant: "success"
                        })
                        , 50); 
                    }                             
                })
                .catch((error) => {   
                    if (!error.response) {
                      this.showMessage(this.$t('Shared.Rooms'),this.$t('Msg.retrieveInfo'),"error");
                    }                      
                    else if(error.response.status === 400 ){
                      this.showMessage(this.$t('Shared.Rooms'),error.response.data.errorMessage,"error");
                    } else if(error.response.status === 500){
                      this.showMessage(this.$t('Shared.Rooms'),this.$t('Msg.ThereWereSomeError'),"error");
                    } else{
                        this.showMessage(this.$t('Shared.Rooms'),error.response.data.errorMessage,"error");
                    }
                    elemSaveBtn.removeAttribute("disabled");   
                    this.$emit('load', false);
                }); 
    },

     DoAction(){       
        !this.$attrs.editMode ? this.AddRooms() : this.EditRooms(); 
      },

      SaveOrEdit(){ 
         if (this.validate_required()) {
            this.DoAction();
         } else {
            this.showMessage(this.$t('Shared.Rooms'), this.$t('Msg.CheckRedLabeledFields'), "errorAutoHide");          
         }
      },

    validate_required() {
        this.isbuildingValid = this.Rooms.buildingcode.length > 0 ? true : false;  
        this.isCodeValid = this.Rooms.code.length > 0 ? true : false;  
        return this.isbuildingValid && this.isCodeValid;
      },

    OnCodeBlur(value){           
        if(!this.$attrs.editMode){           
          if (value.trim()!== '') {          
          var parameter = {
              "code":value
          };
          RoomsService.getRoomsByCode(parameter)
              .then((response) => {
                  if(response.status == '200' && response.data){
                    // this.$emit('load', false);  
                      if(response.data.length > 0){
                        this.showMessage(this.$t('Shared.Rooms'),this.$t('rooms.RoomCodeExist'),"error");
                        this.CodeExist = true;
                      }else{
                          this.CodeExist = false;
                      } 
                  }  
                    this.$emit('load', false);                          
              })
              .catch((error) => {
                  this.$emit('load', false);                  
                if(error.response.status !== 404 && error.response.status !== 500){
                    if(error.response) {
                      this.showMessage(this.$t('Shared.Rooms'),error,"error");
                  }
                }
                  
              });  
          }
        }
      }
      ,
      checkUserAction() {
      if (elemSaveBtn.disabled) {
        elemSaveBtn.removeAttribute('disabled');
      }
    }
  },

  created(){
    EventBus.$on("onChanged", function () {       
          this.checkUserAction();
     }.bind(this));
    this.getPermissionLeftMenu(this.Menu,this.$route.path,this.permission);
  },

  mounted(){
      this.OptionsRightMenu.ItemsList = [];
       let layoutStatus = this.$store.state.layoutStatus;
       switch(layoutStatus) {
        case 'L0R1':
          this.OptionsRightMenu.favButtonAtion = false;
          this.$emit('getVisibleMenu', this.OptionsRightMenu);
        break;
        case 'L1R0':
        case 'L1R1':
            this.OptionsRightMenu.btnvisible = false;
            this.$emit('getVisibleMenu', this.OptionsRightMenu);
        break;
        default:
            this.OptionsRightMenu.favButtonAtion = false;
            this.$emit('getVisibleMenu', this.OptionsRightMenu);
      }
      this.getRooms();
      setTimeout( function() {
         elemSaveBtn = document.getElementById('btn_save_rooms');
         elemSaveBtn.setAttribute('disabled','');
      }, 50, this );        
  },

    destroyed(){
      this.OptionsRightMenu.ItemsList = [];
      this.OptionsRightMenu.Mode = false;
      this.OptionsRightMenu.visible = false; 
      this.$emit('getVisibleMenu', this.OptionsRightMenu);
    }
   
};
</script>


