<template>
    <div id="wrapper__right" class="d-lg-block d-md-block" style="min-Width:245px;"> 
        <div class="rm-header">
            <div id="rm-info" class="textlabel"  :data-label="this.$t('RigthBar.patInfo')">
                <p id="rm-name" class=" text-truncate"><span>{{this.DataHeader.title}}</span> {{this.DataHeader.firstName}}</p>
                <p id="rm-surname" class=" text-truncate">{{this.DataHeader.lastName}}</p>
            </div>

            <div :id="targetId" oncontextmenu="return false;" tabindex="0">
              <div id="rm-frame">
                <ejs-contextmenu :target="this.DataHeader.patientCode ? menuTargetId : null" :id="targetId" :items='menuItems' :select='onSelect'></ejs-contextmenu>
                <input type="file" ref="input" hidden value="" @change="addImage($event)"/>
                <img v-on:click="OnCamera" v-if="this.Photo.contents !== null && this.Photo.contents !== ''" id="rm-photo" v-bind:src="'data:image/'+this.Photo.extension+';base64,'+this.Photo.contents" data-toggle="modal" data-target="#exampleModalScrollable">
                <img v-on:click="OnCamera" v-if="this.Photo.contents === null || this.Photo.contents === ''" id="rm-photo" src="@/assets/images/faces/nopic-male.jpg" data-toggle="modal" data-target="#exampleModalScrollable">             
                <div v-on:click="GoToPatientSummary" class="rm-more">{{ $t('Shared.Details') }}</div>
              </div>
            </div>
  
  
                <p v-if="this.DataHeader.patientCode" id="rm-summary" class="text-truncate pull-left">ID {{this.DataHeader.patientCode}}</p>
                    <div class="bottomShadow"></div>
                      <i v-if="this.DataHeader.allergies" id="rm-allergic" class="mdi mdi-alert text-warning allergies" v-b-tooltip.hover.bottomleft="this.$t('RigthBar.pPhoto')+' ' + this.DataHeader.allergies" variant="primary"><div>{{$t('RigthBar.ALLERGIC')}}</div></i>
                </div>
                   <marquee v-on:click="GoToPatientSummary" data-toggle="modal" data-target="#exampleModalScrollable" id="rm-marquee" scrollamount="4" truespeed="true" >
                    {{this.DataHeader.extraInfo}}
                    <small v-if="this.DataHeader.dob">{{ $t('Shared.Dob') }}:</small> {{this.DataHeader.dob}}<span v-if="this.DataHeader.dob">///</span>
                    <small v-if="this.DataHeader.ageFormatted">{{ $t('Shared.Age') }}:</small> {{this.DataHeader.ageFormatted}}<span v-if="this.DataHeader.ageFormatted">///</span>
                    <small v-if="this.DataHeader.gender">{{$t('Patient.Sex')}}:</small> {{this.DataHeader.gender}}<span v-if="this.DataHeader.gender">///</span>            
                    <small v-if="this.DataHeader.cellPhone">{{ $t('Shared.CellPhone')}}:</small> {{this.DataHeader.cellPhone}}<span v-if="this.DataHeader.cellPhone">///</span>             
                    <small v-if="this.DataHeader.allergies">{{$t('MdPatientAllergies.Allergies')}}:</small>{{this.DataHeader.allergies}}<span v-if="this.DataHeader.allergies">///</span>
                    <small v-if="this.DataHeader.email">{{ $t('Shared.Email') }}:</small> {{this.DataHeader.email}}
                  </marquee>
                  <div class="rm-options">
                    <div>
                      <input class="typeahead menu-search" type="text"
                      onfocus="this.removeAttribute('readonly');"
                      onblur="this.setAttribute('readonly',true);" readonly
                      placeholder="Search patient" v-model="patient"
                      v-on:input="onSearchPatient">
                    </div>
                      <div  id="rm-items">
                        <item />
                      </div>                     
                  </div>
        
      <ModalCamera
        v-bind:TitleWindow="$t('GroupNotesRightBar.TakePic')"
        v-bind:ModalId="this.modalIdCamera">
      </ModalCamera>
    </div>
</template>
  
  <script>
  import Item from '@/components/pages/groupnotes/GroupNotesItemRightBar'
  import DBFunctions from '@/common/DBFunctions.js'
  import { EventBus } from '../../../event-bus.js'; 
  import DocumentManagementService from '@/services/documentmanagement.service' 
  
  export default {  
    name: 'GroupNotesRigthBar',
    mixins: [DBFunctions],
    components:{Item},
    data(){
      return{
        DataHeader:{},
        modalIdCamera:"modalIdCamera",
        menuTargetId:"#target-menu-righmenu",
        targetId:"target-menu-righmenu",
        NotPhoto:true,
        OptionsRightMenu:{
          visible:true,
          btnGroupNotesvisible:true,
          ItemsList:[],
          btnvisible:false,
          itemdisabled: false,
          PatientCode:''         
        },
        menuItems:[],
      
        Photo:{      
          contents:"",
          extension:"", 
          fileName:"",   
        },
        patient: '',
        msg: this.$t('Msg.SavedSuccessfully'),
        message:this.$t('Layout.displayedScreen')
      }
    },
   
methods: {
  loadMenuItems() {
      this.menuItems = []
      this.menuItems.push({text: this.$t('GroupNotesRightBar.LoadPic'),id:"LoadPicture"})
      this.menuItems.push({text: this.$t('GroupNotesRightBar.TakePic'),id:"TakePicture"})

      if(this.Photo.contents){
        this.menuItems.push({text: this.$t('GroupNotesRightBar.RemovePic'), id: "RemoveUserPicture"})
      }
    },

    GoToPatientSummary(){
      if(!this.$store.state.isUsePatientInfo) return;// Early return if condition is not met

      const { id, recordid } = this.$route.params; // Destructure for easier access
      const currentRouteName = this.$router.currentRoute.name;
      const pRouteNames = ['patient', 'contact', 'allergy'];
      const cRouteNames = ['Clinical', 'vs', 'lognote-add', 'dailynote-add'];

      // Handle patient-related routes
      if (pRouteNames.some(route => this.$route.name.includes(route))) {
        if( currentRouteName == "patient-details") {
            this.showToast(
              this.$t('MdPatientInfoBox.PatientInfo'),
              this.message
            );
        } else {
            this.$router.push({ name: "patient-details", params: { id: id } })
              .catch(err => console.error('Navigation error:', err)); // Improved error handling
        }
        return;
      }
      // Handle clinical-related routes
      if(cRouteNames.some(route => this.$route.name.includes(route))){ 
          if( currentRouteName == "Clinical-PatientInfo") {
              this.showToast(
                this.$t('MdPatientInfoBox.PatientInfo'),
                this.message
              );
          } else {
              this.$router.push({ 
                name: 'Clinical-PatientInfo', 
                params: { id, recordid } 
            }).catch(err => console.error('Navigation error:', err)); // Improved error handling
          }
      }
    },

    async GetDataHeader(patientCode) {
      if (patientCode) {
        this.DataHeader = await this.getData(patientCode);
        this.DataHeader.age = this.DataHeader.age === 0 ? "0" : this.DataHeader.age;
      }
    },

    OnCamera(){ 
      if (this.DataHeader.firstName)
        this.$bvModal.show(this.modalIdCamera);   
    },

    LoadPicture(){       
      if (this.DataHeader.firstName) {
        this.$refs.input.click();
        this.$refs.input.value = "";
      } 
    },

    onSelect: function(args) {
      const actions = {
          'LoadPicture': this.LoadPicture,
          'TakePicture': this.OnCamera,
          'RemoveUserPicture': this.RemovePicture,
      };

      const action = actions[args.item.id];
      if (action) {
          action.call(this); // Call the method in the context of the component
      } else {
          console.warn(`Unknown action: ${args.item.id}`);
      }
    },

    SavePhoto(){  
      try {
        !this.NotPhoto ? this.UpdatePhoto() : this.InsertPhoto();
      } catch (error) {
          console.error("Error saving photo:", error); // Error handling
      } finally {
          this.loadMenuItems(true); // Ensure this is always called
      }
    },
    InsertPhoto(){
      var data = {
        Entitycode:this.PatientCode,
        contents:this.Photo.contents,
        extension:this.Photo.extension, 
        fileName:this.Photo.fileName  
      };
      DocumentManagementService.AddPhoto(data)
        .then((response) => {
           if(response.status == '200'){
            this.Photo = response.data;
            this.NotPhoto = false;
            setTimeout(() => 
                this.showToast(
                  this.$t('RigthBar.pPhoto'),
                  this.msg
              ), 50); 
            }                           
        })
        .catch((error) => {
            if (error.response) {
                this.showMessage(this.$t('RigthBar.pPhoto'),error,"error");
            }
        });  
    },

    UpdatePhoto() {
        var data = {
          Entitycode:this.PatientCode,
          contents:this.Photo.contents,
          extension:this.Photo.extension, 
          fileName:this.Photo.fileName,   
        };

        DocumentManagementService.UpdatePatientPhoto(this.Photo.seqno,data)
          .then((response) => {
            if(response.status == '200'){
               setTimeout(() => 
                  this.showToast(
                    this.$t('RigthBar.pPhoto'),
                    this.msg
                ), 50); 
              }                            
          })
          .catch((error) => {
              if (error.response) {
                  this.showMessage(this.$t('RigthBar.pPhoto'),error,"error");
              }
          });  
    },
  
    async GetPhoto(patientCode){
      await DocumentManagementService.GetPatientPhoto(patientCode)
        .then((response) => {
          if(response.status == '200' && response.data){
            this.Photo = response.data;
            this.NotPhoto = (this.Photo.contents === null || this.Photo.contents === '') ? true : false;
          }
        })
        .catch((error) => {
          if (error.response) {
            this.showMessage(this.$t('HeaderLeftMenu.uPhoto'),error,"error");
          }
        });  
    },
  
    async addImage(e){
      this.msg = this.$t('Msg.LoadedSuccessfully')
      if (this.DataHeader.firstName) {
        let droppedFiles = e.type === 'drop'?e.dataTransfer.files:e.target.files;     
        if(!droppedFiles) return;     
        if(droppedFiles.length === 1){
          var filename = droppedFiles[0].name;
          this.Photo.fileName = filename;
          const fileReader = new this.SyncFileReader(droppedFiles[0]);
          const arrayBuffer = await fileReader.readAsDataURL();
          if(arrayBuffer){
            const img = document.getElementById("rm-photo");
            img.src = arrayBuffer;
          }    

          if(arrayBuffer.indexOf(',') !== -1){
            var b = arrayBuffer.split(',');    
            this.Photo.contents = b[1];  
          }
          
          var pos = filename.lastIndexOf('.');
          if(pos !== -1){          
            this.Photo.extension = filename.substring(pos).toUpperCase();
          }
          this.SavePhoto();        
        }
      }
    },

    SyncFileReader(file) {
      let self = this;
      let ready = false;
      let result = '';

      const sleep = function (ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      }

      self.readAsDataURL = async function() {
          while (ready === false) {
            await sleep(100);
          }
          return result;
      }    

      const reader = new FileReader();
      reader.onloadend = function(evt) {
          result = evt.target.result;
          ready = true;
      };
      reader.readAsDataURL(file);
    },
    
    cleanPatientInfo() {
      this.DataHeader = {
        age : '',
        allergies : '',
        attendingDoctor : '',
        cellPhone: '',
        dob: '',
        email: '',
        firstName: '',
        gender: '',
        lastName: '',
        patientAddress : '',
        patientCode: '',
        primaryInsurance : '',
        title: this.$t('GroupNotesRightBar.title'),
        extraInfo: this.$t('GroupNotesRightBar.extraInfo')
      }
      this.Photo.contents = null
    },
    
    RemovePicture(){
      if(this.NotPhoto)return;      
      this.Photo.contents = '',
      this.Photo.extension = '', 
      this.Photo.fileName = '', 
      this.msg = this.$t('Msg.RemoveSuccessfully')
      this.SavePhoto() 
    },

    onSearchPatient(evt) {
      EventBus.$emit('onGNSearchPatient', evt.target.value);
    },
    // Helper function to display toast
    showToast(titleKey, msgKey, variant = "success") {
        this.$bvToast.toast( msgKey, {
            title: titleKey,
            autoHideDelay: 4000,
            variant: variant
        });
    },
  },
  
  created(){
      EventBus.$on("PhotoWasTaken", function (data) {
      const img = document.getElementById("rm-photo");
      img.src = data;
      if(data.indexOf(',') !== -1){
           var b = data.split(',');              
           if(b[0].indexOf(';') !== -1){
            var c = b[0].split(';');  
            if(c[0].indexOf('/') !== -1){
              var d = c[0].split('/');
              this.Photo.extension = "."+d[1]; 
              this.Photo.contents = b[1]; 
              this.Photo.fileName = "";
              this.msg = this.$t('Msg.SavedSuccessfully')             
              this.SavePhoto();
            }
           } 
          
      }
    }.bind(this));

    EventBus.$on("onPatientClickGroupNotes", function (code) {       
      if (code) {
        this.PatientCode = code.trim()
        this.GetDataHeader(this.PatientCode)
        this.GetPhoto(this.PatientCode)
        .then(() => {
          this.loadMenuItems();     
        });
      } else {
        this.cleanPatientInfo()
      }
    }.bind(this));  
    
    EventBus.$on("loadRightMenu", function () {       
      document.getElementById('rightLoader').style.display = 'none';
    }.bind(this));  
  },
  
  destroyed(){
    EventBus.$off('onPatientClickGroupNotes');
    EventBus.$off('loadRightMenu');
  },
  
  mounted(){       
    let layoutStatus = this.$store.state.layoutStatus;
    this.OptionsRightMenu.btnGroupNotesvisible = true;
    this.OptionsRightMenu.ItemsList = this.$store.state.itemListGroupNotes;     
    switch(layoutStatus) {
      case 'L0R0':
        this.$store.commit('setlayoutStatus', 'L0R1');
        break;
      default:
        this.$store.commit('setlayoutStatus', 'L1R1');
    }
    //this.$emit('onMenuRightBarGroupNotes', this.OptionsRightMenu);  
  }
};

</script>
  
<style>
  .m-rightmenu {
    color: white;
    left: 0;
    width: 250px;
    /* iOS height fix */
    height: 95%;
    height: calc(100vh - 40px);
    overflow: hidden;
    background-color: transparent;
    transition-duration: 0.3s;
    transition-easing: cubic-bezier(0.215, 0.61, 0.355, 1);
    box-shadow: 0 0 15px #cccccc44 !important;
  }
  #rightLoader {
    position: absolute;
    top: 125px;
    width: 100%;
    height: 20px;
    z-index: 1000;
    display: flex;
    justify-content: center;
  }
  #rightLoader span div {
    background-color: #19bee8;
    border: 1px solid rgba(255, 255, 255, 0.7);
    width: 6px;
    height: 6px;
    margin: 5px;
    border-radius: 4px;
    animation: loadNow 1s linear infinite;
  }
  #rightLoader .ll01{
      animation-delay: 0.1s;
  }
  #rightLoader .ll02{
      animation-delay: 0.2s;
  }
  #rightLoader .ll03{
      animation-delay: 0.3s;
  }
</style>
