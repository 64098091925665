import ApiService from '@/services/api.service'

const AllergyService = {
    getAll( parameter) { 
      const requestData = {
        method: 'get',
        url: "/PatientAllergies/",
        headers: {
        },
        params: { 
          SearchCriteria: parameter.SearchCriteria,
          PageSize: parameter.PageSize,          
          PageNumber : parameter.PageNumber,
          PatientCode : parameter.PatientCode
        }
      }     
      return ApiService.customRequest(requestData);         
    },
  
    get( id) {
      return ApiService.get(`/PatientAllergies/${id}`);
    },

    addAllergy(data){
      return ApiService.post(`/PatientAllergies`,data)
    },

    deleteAllergy(id){
      return ApiService.delete(`/PatientAllergies/${id}`)
    },

    update(RecordId,Allergy){
      return ApiService.put(`/PatientAllergies/${RecordId}`,Allergy)
    }
}
  
  export default AllergyService;