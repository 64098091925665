import ApiService from '@/services/api.service'
import StorageService from '@/services/storage.service'
import store from '@/globalvar.js'


class AuthenticationError extends Error {
    constructor(errorCode, message) {
        super(message)
        this.name = this.constructor.name
        this.message = message
        this.errorCode = errorCode
    }
}

const UserService = {
    //Login the user and store the access token to TokenService. 
    async login(clientId, username, password,forceLogin) {
        
        const requestData = {
            method: 'post',
            url: "/Auth/login/",
            data: {
              clientId: clientId,
              userName: username,
              password: password,
              force:forceLogin
            },
        }

        var result = {
            "Success": false,
            "InUse":false,
            "expired":false,
            "disabled":false,
            "neterror":false
        };        
        try {
            await ApiService.customRequest(requestData)
            .then((response) => {
                if (response.status == '200'){                    
                     store.commit('setVip',response.data.isVIP);
                     store.commit('setAccessToken',JSON.stringify(response.data));
                     result.Success = true;

                }
            })
            .catch(error => {
                if (!error.response) {
                    result.neterror = true;
                }              
                if (error.response && 400 === error.response.status) {
                    result.InUse = error.response.data.inUse;
                    result.expired = error.response.data.expired;
                    result.disabled = error.response.data.disabled;
                }               
            });
            
        } catch(error) {
         result.Success = error;
        }

        return result;
    },

    //Refresh the access token.
    // refreshToken: async function() {
    //     const refreshToken = StorageService.getRefreshToken()

    //     const requestData = {
    //         method: 'post',
    //         url: "/o/token/",
    //         data: {
    //             grant_type: 'refresh_token',
    //             refresh_token: refreshToken
    //         },
    //         auth: {
    //             username: process.env.VUE_APP_CLIENT_ID,
    //             password: process.env.VUE_APP_CLIENT_SECRET
    //         }
    //     }

    //     try {
    //         const response = await ApiService.customRequest(requestData)

    //         StorageService.saveToken(response.data.access_token)
    //         StorageService.saveRefreshToken(response.data.refresh_token)
    //         // Update the header in ApiService
    //         ApiService.setHeader()

    //         return response.data.access_token
    //     } catch (error) {
    //         throw new AuthenticationError(error.response.status, error.response.data.detail)
    //     }

    // },

    // logout user removing token from storage
    // remove header for futures request
     logout() { 
        return ApiService.post(`/auth/logout/`);
        //StorageService.removeToken();
       // StorageService.removeProvider();

        // Esto lo habilitamos despues que la api funcione la autenticacion con token
        //TokenService.removeRefreshToken()
        //ApiService.removeHeader()
        
        //ApiService.unmount401Interceptor()
    },

    get(id) {
        return ApiService.get(`/Users/${id}`);
      },
  
      getRols(){
        return ApiService.get('/UserRoleHeaders');      
      },
  
      delete(id){
        return ApiService.delete(`/Users/${id}`)
      },
  
      add(data){
        return ApiService.post(`/Users`,data)
      },
  
      update(id,data){
        return ApiService.put(`/Users/${id}`,data)
      },

      verifySupervisorPassword(data){
        return ApiService.post(`/Users/VerifySupervisorPassword`,data)
      },

      resetPassword(id,data){
        return ApiService.put(`Users/ResetPassword/${id}`,data)
      },

    IsLoged() {
        const token = StorageService.getToken();
        return token !== null && token !== "";
    },

    IsAuthenticated(){
      const token = StorageService.getToken();
    //   const provider = StorageService.getProvider();
      const provider = store.state.provider;
      return token !== null && token !== "" && provider !== null && provider !== "";
    },

    //not used
    // getToken(){
    //     try{
    //         return JSON.stringify(StorageService.getToken());
    //     }
    //     catch{
    //         return null;
    //     }
    // },

    // getProvider(){
    //     return StorageService.getProvider();
    // },

    // saveProvider(providercode, provider){ 
    //     StorageService.saveProvider(providercode, provider);
    // },

    getUserByCode(id){
      //return ApiService.get(`/Users/${id}`);     
      const requestData = {
        method: 'get',
        url: "/Users/",
        headers: {
        },
        params: {          
          Code: id        
        }
      }     
      return ApiService.customRequest(requestData);        
    },

    changePassword(clientId, userId, password, newPassword) {
        const requestData = {
            method: 'post',
            url: "/Auth/password/",
            data: {
              clientId: clientId,
              userId: userId,
              password: password,
              newPassword:newPassword
            },
        }
        return ApiService.customRequest(requestData)
    },

    verifyPassword(clientId, userId, employeeCode, password) {
        const requestData = {
            method: 'post',
            url: "/Auth/verifypassword/",
            data: {
              clientId: clientId,
              userId: userId,
              employeeCode: employeeCode,
              password: password,
            },
        }
        return ApiService.customRequest(requestData)
    }
}

export default UserService
export { UserService, AuthenticationError }