<template>
   <div id="schedulerNotes">
	<h4 class="page-title text-truncate"> {{$t('SchedulerNotes.SNTitle')}} -
		<span v-if="this.PatientTitle.fullName">{{this.PatientTitle.fullName+' '}}</span> -
		<span v-if="this.$route.params.id">{{this.$route.params.id}}</span>
		<span v-if="this.PatientTitle.dob"> - {{ $t('Shared.Dob') }} {{this.PatientTitle.dob}}</span>
		<span v-if="this.PatientTitle.ageFormatted"> {{ $t('Shared.Age') }} {{this.PatientTitle.ageFormatted}}</span>
	</h4>

	<b-row>
		<b-col lg="10" md="10" sm="12">
			<!--<MdSelect
				v-bind:Label="LabelNotes"
				v-bind:Options="ListNotes"
				v-bind:Placeholder="selectPlaceholder"
				v-bind:FieldDescription="'description'"
				v-bind:FieldCode="'notesID'"
				v-bind:Model="noteVModel" 
				v-bind:SelectLabel="'Test Test'"				
				v-bind:RequireEvent="true"
				v-bind:IfUsevalidation="false"                      
				v-bind:Multiple="false"
				:IsDisabled="false"
				:Input="ChangeDropDownNotes"
			/>-->

			
				<b-col md="12">
				<b-dropdown
					id="startMenuButton"
					:html="trademark()"
					variant="primary"
					align-h="start"
					size="xl"
					no-caret					
					block
					class="m-2"
					menu-class="w-100"
				>				
			
					<b-dropdown-item v-for="(item, index) in ListNotes" :key="index" v-on:click="OnCLickMenuNotes(item.notesID)">
								<i :class="item.icon"></i>{{item.description}}
								<b-row>
									<b-col md="4">
										<div class="pushItDown" v-if="item.noteStatus === 'Finalized'"></div>
											<div v-bind:class="item.noteStatus === 'Finalized' ? 'chRstatusfinished':'chRstatus mt-2'">
											{{item.noteStatus}}
										</div>
									</b-col>
									<b-col md="4">
										{{item.therapistName}}
										<span class="NoInfo" v-if="item.therapistName === ''">{{$t('MdTable.NoTherapistTxt')}}</span>
									</b-col>
									<b-col md="2">
										{{item.coTherapistName}}
										<span class="NoInfo" v-if="item.coTherapistName === ''">{{$t('MdTable.NoCoTherapistTxt')}}</span>
									</b-col>
									<b-col md="2">
										<span v-if="!item.isBillable">{{$t('ChartReview.NotBillable')}}</span>     
									</b-col>
								</b-row>
					</b-dropdown-item>	
				</b-dropdown>		
				
				</b-col>			
		</b-col>		
		<b-col lg="2" md="2" sm="12">
			<MdButton
				v-bind:Label="$t('SchedulerNotes.HisNotes')" 
				v-bind:ClassProp="'btn btn-primary btn-block btn-login my-2'"
				v-bind:IconClass="'ti-plus pr-1'"
				v-on:click="OpenHistoricalNotes"				
				v-bind:Name="'btn_add_hisNotes'">
			</MdButton>
		</b-col>
	</b-row>

	<b-row v-if="this.ClinicalNotesListOpened.length > 0">
		<b-col md="12">
			<b-card-body class="p-1">
				<div class="notesPanels1">					
					<div :class="this.ClassContainerNotes" ref="SchedulerContainerNotes">
					<MdNote
						v-for="(model, index) in this.ClinicalNotesListOpened"
						v-bind:NOTE="model.description"										
						v-bind:TransDate="model.date"
						v-bind:Time="model.Time"
						v-bind:Location="'SchedulerNotes'"
						v-bind:Header="model"						
						v-bind:IsChartReview="false"					
						v-bind:CategoryCode="TherapiesVModel"									
						:key="model.Id"
						:id="model.Id"
						v-bind:ProgramCode="ProgramCode"
						v-on:remove="CloseNote(index)"
						v-bind:VisibleIconinTitle="true"
						:model="model"
						v-bind:NotePatientInfo="PatientInfo">
					</MdNote>
					</div> 
				</div>
			</b-card-body>
		</b-col>
	</b-row>

	<ModalHistoricalAppointment
		v-bind:TitleWindow="$t('SchedulerNotes.HisAppt')"  
		v-bind:ModalId="this.modalApptHistoricalId">
	</ModalHistoricalAppointment>
		



   </div>
</template>

<script>
import moment from "moment";
import EnumService from "@/services/enum.service";
import DBFunctions from '@/common/DBFunctions.js';
import { EventBus } from '@/event-bus.js';
//import Utils from '@/common/utils'
import AppointmentService from '@/services/appointment.service'
import ShowMessage from '@/components/messages/ShowMessage.js'



export default {
  name: 'SchedulerNotes',
  mixins: [DBFunctions,ShowMessage],
  data(){
	return{
		Menu : this.$store.state.leftMenu,
		PatientInfo:{},
		modalApptHistoricalId:"modalHistoricalAppointment",
		TherapiesVModel:'',
		ProgramCode:'',		
		ListNotes:[],
		ListNotesHistorical:[],		
		ExistsNote:false,
		LabelNotes:'Selected Appointment Notes from ',
		selectPlaceholder:"Select a Note",
		noteVModel:{
			notesID:"",
			description:""
		},
		noteHistoricalVmodel:{
			code:"",
			description:""
		},
		ClassContainerNotes:'',
		ClinicalNotesListOpened:[],
		currentAppointmentDB:{
			guid:'',
			activitytypecode:'',
        employee:{
            firstname:"",
            lastname:""
        }
     },
		

		OptionsRightMenu:{
			visible:true,
			ItemsList:[],
			btnvisible:true,
			itemdisabled: false,
			
		},
		PatientTitle:{      
			programName:'',
			fullName:'',
			admitDate:'',
			dischargeDate:'',
			dob:''
		}, 
	}
  }, 

  methods:{
	/*ChangeDropDownNotes(n){
		this.noteVModel= n;
		console.log(n);
		if(n !== null){
			this.OpenNoteDefault(n.notesID);
		}
	},*/

	OpenHistoricalNotes(){
		EventBus.$emit('onOpenHistoricalAppt', true);
		this.$bvModal.show(this.modalApptHistoricalId);
	},

	OnCLickMenuNotes(Id){
		//v-on:click="launchPage('HomePage', '/app/main', 'Main')"
		this.OpenNoteDefault(Id);
	},

	trademark() {
      //return "MedEZ<sup>®</sup>";
      //return "<i class='ikon med-isologo'></i> Estas son las notas a mostrar";
		return this.$t('SchedulerNotes.OpenSelectApptMsg');
    },

	ChangeDropDownNotesHistorical(nh){
		this.noteHistoricalVmodel = nh;
	},

	AdjustNoteContainer(Operation){
		var CountNotes = 0;			
			if(this.$refs.SchedulerContainerNotes !== undefined){
				CountNotes = Operation === 'ADD'?this.$refs.SchedulerContainerNotes.children.length+1:this.$refs.SchedulerContainerNotes.children.length-1;		
			}
			if(CountNotes === 1){
				this.ClassContainerNotes = 'cutescroll';
			}
			if(CountNotes > 1){
				this.ClassContainerNotes = 'notesHolder cutescroll';
			}
	},

	CloseNote(index){		
		this.ClinicalNotesListOpened.splice(index, 1)
		this.AdjustNoteContainer("REMOVE");
		if(this.ClinicalNotesListOpened.length == 0){
			this.ShowFilter = true;
			this.ShowNote = false;
		}
	},

	PopulateListNotesDropDown(arr){

		var temp = [];
		arr.forEach(element => {
			temp.push({code:element.notesID.trim(),description:element.description.trim()})
		});
		this.ListNotes = temp;

		EventBus.$on("onNoteSchedulerClicked", function (e) {
			this.noteVModel.code = e.Clicked.code;
			this.noteVModel.description = e.Clicked.description;	
		}.bind(this));

	},

	SetIcon(noteStatus){
		var obj = {
			icon:"",
			description:"",
		}
		if(noteStatus == 'New'){	
			obj.icon = "icon-doc mr-2";	
			obj.description = this.$t('Shared.IconDescN');
		}
		else if(noteStatus == 'Voided'){			
			obj.icon = "icon-ban mr-2";	
			obj.description = this.$t('Shared.IconDescVN');
		}
		else if(noteStatus == 'Pending Signature'){			
			obj.icon = "icon-note mr-2";	
			obj.description = this.$t('Shared.IconDescPS');							
		}
		else if(noteStatus == 'Pending Cosigner Signature'){
			obj.icon = "icon-user mr-2";	
			obj.description = this.$t('Shared.IconDescPCS');			
		}else{
			obj.icon = "icon-check mr-2";	
			obj.description = this.$t('Shared.IconDescF');									
		}
		return obj;
    },

	async getNotes() { 
      var params = {
          PageSize: 9999999,          
          PageNumber : 1,       
          PatientCode: this.$route.params.id,   
          ActivityId: this.$route.params.actId,
          ActivityTypeCodeList:"",
          NoteStatus:0, 
          NoteIdList:"",
          TherapistCode:"",       
          DateFrom:null,
          DateTo:null,
      };
		this.$emit('load', true);	
      await AppointmentService.getNotesByActId(params)
              .then((response) => {
                if(response.status == '200' && response.data && response.data.length > 0){
                  this.ListNotes = response.data;

				this.ListNotes.forEach(element => { 
					element.Id = element.headerID;
					element.date = element.transDate;
					element.Time = element.noteTime;
					element.notesId = element.notesID;
					var obj = this.SetIcon(element.noteStatus);
					element.icon = obj.icon;
					element.descriptionIcon = obj.description;
				});

				}
				this.$emit('load', false);	
              })
              .catch(error => {
				this.$emit('load', false);	
                if (!error.response) {                
                  this.showMessage(this.$t('MdNotesByAppt.AppoinNotesTitle'),this.$t('Msg.RetrieveInformation'),"error");
                } else {
                  this.showMessage(this.$t('MdNotesByAppt.AppoinNotesTitle'),error,"error");
                }
              });
            },

	async PatientInfoData(){
		this.PatientInfo = await this.getData(this.$route.params.id);
	},

	getObjectByNoteId(Id){
		for(var i = 0; i < this.ListNotes.length; i++){			
			if(this.ListNotes[i].notesID === Id){
				return this.ListNotes[i];
			}	
		}
		return null;
	},

	OpenNoteDefault(Id){
		var obj = this.getObjectByNoteId(Id);		
		this.$emit('load', true);
		this.ExistsNote = this.ClinicalNotesListOpened.some(nItem => ( ((nItem.notesID === obj.notesID) && (nItem.Id === obj.Id)) ||
																		(((nItem.frequency != 'DAILY MULTIPLES') && (nItem.notesID === obj.notesID) && (nItem.transDate === obj.transDate)) ||
																		((nItem.frequency === 'DAILY MULTIPLES') && (nItem.notesID === obj.notesID) && (nItem.transDate === obj.transDate) && (nItem.noteTime === obj.noteTime)))));																	  
		
		if (!this.ExistsNote) {
			this.ClinicalNotesListOpened.push(obj);
			this.AdjustNoteContainer("ADD");			
		}
		
		this.ClassAccordion2 = 'show';
        this.ShowNote = true;
		this.ShowFilter = false;		
		setTimeout(() => this.$emit('load', false), 500);

	},

	OpenNoteHistorical(obj){
		//var obj = this.getObjectByNoteId(Obj.notesID);		
		this.$emit('load', true);
		this.ExistsNote = this.ClinicalNotesListOpened.some(nItem => ( ((nItem.notesID === obj.notesID) && (nItem.Id === obj.Id)) ||
																		(((nItem.frequency != 'DAILY MULTIPLES') && (nItem.notesID === obj.notesID) && (nItem.transDate === obj.transDate)) ||
																		((nItem.frequency === 'DAILY MULTIPLES') && (nItem.notesID === obj.notesID) && (nItem.transDate === obj.transDate) && (nItem.noteTime === obj.noteTime)))));																	  
		
		if (!this.ExistsNote) {
			this.AdjustNoteContainer("ADD");
			this.ClinicalNotesListOpened.push(obj);
		}
		
		this.ClassAccordion2 = 'show';
        this.ShowNote = true;
		this.ShowFilter = false;		
		setTimeout(() => this.$emit('load', false), 500);
	},

	UpdateNoteStatus(objNote){       
       for(var i = 0; i<this.ListNotes.length; i++){
		if(this.ListNotes[i].notesID === objNote.Notesid && this.ListNotes[i].headerID === objNote.Headerid){
			var obj = this.SetIcon(objNote.NoteStatus);
			this.ListNotes[i].icon = obj.icon;
			this.ListNotes[i].descriptionIcon = obj.description;
			this.ListNotes[i].noteStatus = objNote.NoteStatus;
		}
       }
      },

	async getPatientInfo(){
	var infoList = await this.getInfoTitle(this.$route.params.id);
      if(infoList.length > 0){
          this.PatientTitle.programName = '';
          this.PatientTitle.admitDate = '';
          this.PatientTitle.dischargeDate = '';
          this.PatientTitle.age = infoList[0].age;
          this.PatientTitle.ageFormatted = infoList[0].ageFormatted;
          if(infoList[0].dob){
              this.PatientTitle.dob = moment(infoList[0].dob).format('MM/DD/YYYY');
          }   
          this.PatientTitle.fullName = infoList[0].firstname + ' '+ infoList[0].lastname;
      }
	},
  },

 async created(){	
	await this.getNotes();
	/*EventBus.$on("onNoteSchedulerClicked", function (e) {					
		this.noteVModel.code = e.Clicked.code;
		this.noteVModel.description = e.Clicked.description;
		this.OpenNoteDefault(e.Clicked.code);	
	}.bind(this));*/
	

	EventBus.$on("onSelectNoteinModalHistorical", function (noteSelected) { 
		if(noteSelected.serviceDate){		
			noteSelected.serviceDate = new Date(noteSelected.serviceDate).toISOString();  
		}
		if(noteSelected.transDate){
			noteSelected.transDate = new Date(noteSelected.transDate).toISOString();  
		}

		if(noteSelected.date){
			noteSelected.date = new Date(noteSelected.date).toISOString(); 
		}
		
       this.OpenNoteHistorical(noteSelected);
     }.bind(this));

	EventBus.$on("onChangeNoteStatus", function (objNote) {       
        this.UpdateNoteStatus(objNote);
     }.bind(this));
	

  },

  async mounted(){
	
	this.OptionsRightMenu.visible = true;    
	this.OptionsRightMenu.btnvisible = true;
	this.OptionsRightMenu.itemdisabled = false;
	this.OptionsRightMenu.ItemsList = EnumService.AppointmentItems;
	this.$emit('getVisibleMenu', this.OptionsRightMenu); 

	this.PatientInfoData();

	//this.currentAppointmentDB = await this.geCurrentAppointment(this.$route.params.actId);	
	//var NotesList = await this.getNotesByAppt(this.currentAppointmentDB.activitytypecode);		
	//this.PopulateListNotesDropDown(this.ListNotes);

	moment.suppressDeprecationWarnings = true; 		
		this.getPatientInfo();		
		
		this.ClinicalNotesListOpened = [];		
  },

  destroyed(){
      this.OptionsRightMenu.ItemsList = [];
      this.OptionsRightMenu.Mode = false;
      this.OptionsRightMenu.visible = false; 
      this.$emit('getVisibleMenu', this.OptionsRightMenu);
	EventBus.$off('onNoteSchedulerClicked');

  }
   
};
</script>

<style scoped>
.m-pagecontent {
    overflow-y: hidden!important;
    overflow-x: hidden!important;
    padding: 5px;
    height: 100%;
    height: calc(100vh - 60px);
    height: calc(100dvh - 60px);
    background-color: #fff;
    border-radius: 7px;
}

</style>

