<template>
  <!-- BOTTOM NAVBAR FAV IN MOVILE -->
  <span>
    <b-dropdown
      :id="`favMenuButton`"
      variant="outline-dark"
      text="FAV"
      size="sm"
      no-caret
      class="showMobile"
    >
      <b-dropdown-item-button aria-describedby="dropdown-header-label"
        v-if="isVisibleBtnFavorite"
        variant="outline-dark"
        v-on:click="AcFavorite()"
        :class="[isFavorite ? iconFavOn : '']"
        class="showMobile" 
      >
        <i :class="iconFav" 
          aria-hidden="true"
        ></i>
        <span class="text-primary">
          {{ titleFavorite }}
        </span>
      </b-dropdown-item-button>
      <b-dropdown-divider v-if="isVisibleBtnFavorite"></b-dropdown-divider>
      <draggable 
        v-model="optionsButton" 
        :move="onMove"
        @end="onDragEnd"
        ghost-class="favghosteffect"
        animation="100"
      >
        <b-dropdown-item
          v-for="(item, index) in optionsButton" 
          :key="index" 
          :id="`favMenuButton-${index}`" 
          @click="launchFavPage(item.title, item.url, item.routerName, item.icon)">
          <i :class="item.icon" aria-hidden="true"></i>
          {{ item.title }}
        </b-dropdown-item>
      </draggable>
    </b-dropdown>
    <!-- END BOTTOM NAVBAR FAV IN MOVILE -->

    <!-- BOTTOM NAVBAR FAV IN DESKTOP -->
    <b-button
      v-if="isVisibleBtnFavorite"
        variant="outline-dark"
        v-b-tooltip.hover
        :title="titleFavorite"
        v-on:click="AcFavorite()"
        :class="[isFavorite ? iconFavOn : '']"
        class="hideMobile" 
      >
        <i :class="iconFav" 
          class="center-icons" 
          aria-hidden="true"
      ></i>
    </b-button>
    
    <b-button-group
      size="sm" 
      class="hideMobile"
      id="favbbuttongroup"
    >
    <!-- Add this properties to b-button and remove draggable module
         to return to native html drag and drop

        draggable="true" 
        @dragstart="startDrag($event, index)" 
        @dragover.prevent="handleDragOver($event, index)"   
        @drop="endDrag($event, index)"
      >
    -->
     <draggable 
        v-model="optionsButton" 
        :move="onMove"
        @end="onDragEnd"
        ghost-class="favghosteffect"
        animation="100"
      >
        <b-button v-for="(Sub, index) in optionsButton" :key="index"
          v-b-tooltip.hover
          variant="outline-dark"
          v-on:click="launchFavPage(Sub.title, Sub.url, Sub.routerName, Sub.icon)"
          :title="Sub.title"
          :style="styleAnimation ? { animationDelay: `${index * .05}`+ 's' } : {}" 
          :class="styleAnimation ? 'iconfavfadeIn':''"
        >
          <i :class="Sub.icon" 
              class="center-icons" 
              aria-hidden="true"
          ></i>
        </b-button>
      </draggable>
    </b-button-group>
    <!-- END BOTTOM NAVBAR FAV IN DESKTOP -->
  </span>
</template>

<script>
import { EventBus } from '@/event-bus.js';
import UsersFavorites from '@/services/userfavorites.service'
import StorageService from "@/services/storage.service";
import ShowMessage from "@/components/messages/ShowMessage.js";
import draggable from 'vuedraggable';

export default {
  name: 'Favorites',
  mixins: [ShowMessage],
  components: {
    draggable,
  },
  data(){
    return{
      optionsButton: [],
      iconFav:'ikon med-favorites',
      iconFavOn:'favON',
      isFavorite: false,
      isVisibleBtnFavorite: true,
      msg: this.$t('Layout.Favorites.msgSavedSuccessfully'),
      userId : StorageService.getUserId(),
      titleFavorite:this.$t('Layout.Favorites.Add'),
      lastFavOrder:0,
      draggingIndex: null, 
      targetIndex: null,
      dataMenuItems:{
        entries:[]
      },
      isDragging: false, // New property to track drag state
      styleAnimation: true
    }
  },
  methods:{
    AcFavorite(){
      if(this.isFavorite) {
        this.RemoveFromFavorites();
        this.lastFavOrder -=1;
      } else {
          if(this.optionsButton.length < 12){
            this.InsertToFavorite();
          } else {
            this.showMessage(this.$t('Layout.Favorites.title'), this.$t('Layout.Favorites.only12Msg'), "error")
          }
      }
    },
     
    loadFavorites(){
      UsersFavorites.getAll(this.userId)
        .then((response) => {
          var data = response.data;
          if(response.status == '200' && data && data.length > 0){
             data.forEach(function(e) {
                this.optionsButton.push({ 
                    title: this.$t(e.title),
                    url: e.url,
                    routerName:e.routerName, 
                    icon:e.icon,
                    favOrder:e.favOrder,
                    userId:e.userid
                  });
                this.lastFavOrder = e.favOrder + 1  
              },this)
              
              this.optionsButton.filter(function(elem){
                  if(elem.url == this.$router.currentRoute.path) {
                    this.isFavorite = true;
                    this.isVisibleBtnFavorite = true
                    this.titleFavorite = this.$t('Layout.Favorites.Del');
                    this.$store.commit('setBtnFavoriteState', true);
                  } 
              },this);
              this.isVisibleBtnFavorite = this.$store.state.btnFavoriteState;
          }
          if('/app/main' == this.$router.currentRoute.path){
            var send = {
              isVisible: false,
              isFav:false
            };
            this.$store.commit('setBtnFavoriteState', send.isVisible);
            EventBus.$emit('shwoHideFavorite', send);
          }
        })
        .catch(error => {
          if (!error.response) {
              // network error
              this.showMessage(this.$t('Layout.Favorites.title'),this.$t('Msg.RetrieveInformation'),"error");
          } else {
              this.showMessage(this.$t('Layout.Favorites.title'),error,"error");
          }
        });
    },

    InsertToFavorite(){
      this.msg = this.$t('Layout.Favorites.msgSavedSuccessfully', {
          'favTitle': this.$t('menu.'+ this.$store.state.menuItemSelected.originalTitle)
      });
      var data = {
        Url: this.$router.currentRoute.path,
        Icon:this.$store.state.menuItemSelected.parentIcon,  
        Userid : this.userId,
        Title: 'menu.'+ this.$store.state.menuItemSelected.originalTitle,
        RouterName: this.$router.currentRoute.name,
        FavOrder:this.lastFavOrder
      };
      UsersFavorites.AddToFavorites(data, false)
        .then((response) => {
            var data = response.data;
            if(data && data.userid !== null){
              this.isFavorite = true;
              this.titleFavorite = this.$t('Layout.Favorites.Del');
              this.optionsButton.push(
                { 
                  title: this.$t(data.title),
                  url: data.url,
                  routerName:data.routerName, 
                  icon:data.icon,
                  favOrder:data.favOrder,
                  userId:data.userid
                }
              )
              setTimeout(() => 
                  this.$bvToast.toast(this.msg, {
                    title: this.$t('Layout.Favorites.title'),
                    autoHideDelay: 5000,
                    variant: "success"
                  }),
               50); 
              }                           
          })
          .catch((error) => {
              if (error.response) {
                  this.showMessage(this.$t('Layout.Favorites.title'),error,"error");
              }
          });  
    },

    RemoveFromFavorites(){
      this.msg = this.$t('Layout.Favorites.msgRemoveSuccessfully', {
          'favTitle': this.$t('menu.'+ this.$store.state.menuItemSelected.originalTitle)
      });
      const url = this.$router.currentRoute.path;
      UsersFavorites.RemoveFavorite(this.userId, url)
        .then((response) => {
          if(response.status == '200'){
            this.optionsButton =  this.optionsButton.filter( fav => fav.url !== url)
            this.isFavorite = false;
            this.titleFavorite = this.$t('Layout.Favorites.Add');
            setTimeout(() => 
                this.$bvToast.toast(this.msg, {
                  title: this.$t('Layout.Favorites.title'),
                  autoHideDelay: 5000,
                  variant: "success"
                }),
            50); 
          }                           
        })
        .catch((error) => {
          if (error.response) {
            this.showMessage(this.$t('Layout.Favorites.title'),error,"error");
          }
        })  
    },

    async checkExistFavorite(e) {
      const isChildPresent = e.child && e.child.length > 0;
      this.isVisibleBtnFavorite = true;
      this.titleFavorite = this.$t('Layout.Favorites.Add');
      this.styleAnimation = false;

      EventBus.$off('getClickMenuItemData');

      if (isChildPresent && !this.$store.state.btnFavoriteState) {
          this.isVisibleBtnFavorite = false;
      } else {
          this.checkUserFavorite(e.href);
      }
      this.$store.commit('setBtnFavoriteState', this.isVisibleBtnFavorite);
      this.createdEventBus();
    },

    checkUserFavorite(href) {
      if(href === null) { return false ;}
      UsersFavorites.GetUserFavorite(this.userId, href)
        .then((response) => {
            if (response.data && response.data.userid !== null) {
                this.isFavorite = true;
                this.titleFavorite = this.$t('Layout.Favorites.Del');
            } else {
                this.isFavorite =  false;
            }
        })
        .catch((error) => {
            this.handleError(error);
        });
    },

    handleError(error) {
      if (error.response) {
          this.showMessage(this.$t('Layout.Favorites.title'), error, "error");
      }
    },

    /** Methods for native html drag and drop */
    startDrag(event, index) {
      this.draggingIndex = index;
    },

    endDrag(event) {
      const buttonGroup = document.getElementById("favbbuttongroup"); 
      const buttons = buttonGroup.querySelectorAll('button'); 
      this.targetIndex = Array.from(buttons).findIndex(button => {
        const rect = button.getBoundingClientRect();
        return event.clientX >= rect.left && 
              event.clientX <= rect.right && 
              event.clientY >= rect.top &&
              event.clientY <= rect.bottom;
      }); 
      if (this.draggingIndex !== null && this.targetIndex !== null) { 
        this.reorderItems(this.draggingIndex, this.targetIndex); 
      } 
      this.draggingIndex = null; 
      this.targetIndex = null;
    },

    reorderItems(fromIndex, toIndex) {
      if( fromIndex !== toIndex) {
        const [item] = this.optionsButton.splice(fromIndex, 1); 
        this.optionsButton.splice(toIndex, 0, item);
        this.optionsButton.forEach((item, index) => {
          item.favOrder = index;
        });
        this.$nextTick(() => {
          this.AddFavOrder(this.optionsButton);
        });
      } else {
        return false;
      }
    },

    PrepareInsertOrder(e){
      const modifiedSource = e.map(item => {
          return { ...item, title: ('menu.' + item.title) };
      });
      this.dataMenuItems.entries = modifiedSource;
    },

    AddFavOrder(e){
      this.PrepareInsertOrder(e);
      UsersFavorites.AddToFavorites(this.dataMenuItems, true, this.userId)
        .then((response) => {
            if(response.status == '200'){
              this.styleAnimation = false;
            }
        })
        .catch((error) => {
            if (error.response) {
                this.showMessage(this.$t('Layout.Favorites.title'),error,"error");
            }
        });     
    },

    handleDragOver(event) {
      event.preventDefault(); // Allow drop
    },
    /** END */
    launchFavPage(title, url, page, icon) {
        this.styleAnimation = false;
        var send = {
          isVisible: true,
          isFav:true
        };
        if (this.$router.currentRoute.name == page ) {
          this.$bvToast.toast(
            this.$t('Layout.displayedScreen'),
            {
              title: title,
              autoHideDelay: 4000,
              variant: "success",
            }
          );
        } else {
            this.titleFavorite = this.$t('Layout.Favorites.Del');
            this.$router.push(url).catch(() => {});
            var e = {
                "title": title,
                "href": url,
                "child": [],
                "parentIcon": icon,
                "originalTitle": title
              }
            this.$store.commit('setMenuItemSelected', e); 
            this.$store.commit('setBtnFavoriteState', true);
            EventBus.$emit('shwoHideFavorite', send);
        }
        EventBus.$emit('onUpdatedMenu', page);
    },

    async createdEventBus(){
       // Getting Data from clicked menu item
      EventBus.$on("getClickMenuItemData", async (e) => {
        // Check if menu element is a favorite
        this.$store.commit('setMenuItemSelected', e); 
        await this.checkExistFavorite(e);
      });
    },

    onMove() {
      // Implement logic if needed to handle moving items (can return true/false)
      return true; 
    },

    onDragEnd(evt) {
      // Logic after dragging is finished
      const { oldIndex, newIndex } = evt;
      if (oldIndex === newIndex) { return false;}

      this.optionsButton.forEach((item, index) => {
        item.favOrder = index;
      });
      
      this.$nextTick(() => {
        this.AddFavOrder(this.optionsButton);
      });
    }
  }, 
  mounted() {
    // Implement logic if needed
  },
  created(){
    this.loadFavorites();
    this.createdEventBus();
    /** Send info to hide favorite button */
    EventBus.$on("shwoHideFavorite", function (e) {
      this.isVisibleBtnFavorite = e.isVisible;
      this.isFavorite = e.isFav;
    }.bind(this));

    /** To get organized favorites from user modal preferences  
     *  in order to work is necessary to uncomment the EventBus below
    */

  /*  EventBus.$on("reloadFavorite", function () {
      this.optionsButton = [];
      this.loadFavorites();
    }.bind(this));
   */ 
  },
  beforeDestroy () {     
    EventBus.$off('getClickMenuItemData');
  },
};
</script>
<style scoped>
/* Add your styles here */
.favghosteffect {
  opacity: 0.5; /* Visual effect for dragged item */
}
</style>