import ApiService from '@/services/api.service'

const DocumentManagementService = {

    getAll(parameter){
        const requestData = {
            method: 'get',
            url: "/DocumentManagements/",
            headers: {
            },
            params: { 
              SearchCriteria: parameter.SearchCriteria,  
              PageSize: parameter.PageSize,          
              PageNumber : parameter.PageNumber,
              EntityCode: parameter.EntityCode,  
              DocumentType: parameter.DocumentType,
              DateFrom: parameter.DateFrom,  
              DateTo: parameter.DateTo, 
              Expired: parameter.Expired,           
            }
          }     
          return ApiService.customRequest(requestData);       
    },

    getDocument(Id){     
        return ApiService.get(`/DocumentManagements/${Id}`);
    },
   
    GetPatientPhoto(Id){     
        return ApiService.get(`/DocumentManagements/Patientphoto/${Id}`);
    },
    GetPatientUrl(Id){     
        return ApiService.get(`/DocumentManagements/PatientUrl/${Id}`);
    },

    delete(id){
        return ApiService.delete(`/DocumentManagements/${id}`)
    },

    Add(data){
        return ApiService.post(`/DocumentManagements`, data);
    },

    AddPhoto(data){
        return ApiService.post(`/DocumentManagements/PatientPhoto`, data);
    },

    Update(Id,data){
        return ApiService.put(`/DocumentManagements/${Id}`,data);
    }, 
  
    UpdatePatientPhoto(Id,data){
        return ApiService.put(`/DocumentManagements/PatientPhoto/${Id}`,data);
    }, 

}

export default DocumentManagementService;