<template>
    <b-modal id="bv-modal-therapiesNotes" 
    size="xl"
      @hide="hideModal" 
      @ok="this.emitEventOK"  :title="$t('Shared.Therapies')"
      :no-close-on-esc="true" 
      :hide-header-close="true"
      :no-close-on-backdrop="true"
     > 
     <form autocomplete="off" role="form">   
      <b-row>
        <b-col md="10">
            <MdSelect
                v-bind:Label="$t('Shared.Therapies')"
                v-bind:Options="TList"
                v-bind:Model="VmodelTherapy"
                v-bind:FieldDescription="'name'"
                v-bind:FieldCode="'code'"
                v-bind:isRequired="true" 
                v-bind:Tag="this.Tag" 
                v-bind:isValid="isTherapyRequired"               
                v-bind:IfUsevalidation="false"
                :Input="ChangeDropDownTherapy"
                v-bind:Name="'modal_therapies_select'"
                id="modal_therapies_select"/>
        </b-col>
        <b-col>
            <MdEdit 
                v-bind:Label="$t('HouseFile.Unit')"
                v-bind:TypeProp="'number'" 
                v-bind:Name="'Unit'" 
                v-bind:isRequired="true"
                v-bind:Min="'1'"
                v-bind:Tag="this.Tag"
                @keypress="InputUnit"              
                v-bind:StepProp="'1'"
                v-bind:isValid="IsUnitRequired"             
                v-model="VModelUnit"
                v-bind:Id="'input_unit'"/>  
        </b-col>
      </b-row>
      </form>
  
    <Loading v-bind:IsLoading="this.Loading" />
  </b-modal>
</template>

<script>
import ShowMessage from '@/components/messages/ShowMessage.js'
import Loading from '@/components/template/loading/Loading'
import { EventBus } from '@/event-bus.js';


export default {
    props:{
        TList:Array,
        TUnit:String,
        ModeEdit:Boolean,
        Selected:Object,
        Tag:String  
    },
  components: { 
    Loading
  },
  mixins: [ShowMessage],
  name: 'TherapiesNotes',  
  data() {
      return {  
          Loading:false,
          isTherapyRequired:true, 
          IsUnitRequired:true,
          VmodelTherapy:{code:"",name:"",units:""},   
          VModelUnit:this.TUnit,          
      }
  },
  methods: {
      emitEventOK(evt){
        if(this.validate_required()) { 
          if(!this.IsDecimal(this.VModelUnit)){
            this.AddTherapy();
          }else{
            evt.preventDefault();
            this.showMessage(this.$t('MdNote.Therapy'), 'Decimal numbers are not allowed in the unit field', "errorAutoHide");
          }         
         }else{
            evt.preventDefault();
           this.showMessage(this.$t('MdNote.Therapy'), this.$t('Msg.CheckRedLabeledFields'), "errorAutoHide");
         }
      },

      IsDecimal(value){
        let val = String(value)
        return (val.indexOf(".") > -1 || val.indexOf(",") > -1) ? true : false;
      },

      InputUnit(event){ 
            let keyCode = (event.keyCode ? event.keyCode : event.which);          
            // only allow number
            if ((keyCode < 48 || keyCode > 57)) {
                event.preventDefault();
            }
      },
      ///*isFinite(this.VModelUnit) && */
      validate_required() {       
        this.isTherapyRequired = this.VmodelTherapy.code !== "" && this.VmodelTherapy.code !== undefined ? true : false;  
        this.IsUnitRequired = this.VModelUnit !== null && this.VModelUnit !== undefined && this.VModelUnit !== "" ? true : false;  
        return this.isTherapyRequired && this.IsUnitRequired;
      },

      ChangeDropDownTherapy(t){ 
        if(t !== null && t !== undefined){
            this.VmodelTherapy.code = t.code;
            this.VmodelTherapy.name = t.name;
            this.VmodelTherapy.units = t.defaultunit;
        }else{
            this.VmodelTherapy.code = "";
            this.VmodelTherapy.name = "";
            this.VmodelTherapy.units = "";
        }
      },
      AddTherapy(){
        this.VmodelTherapy.units = this.VModelUnit;
        var param = {
          code:this.VmodelTherapy.code,
          name:this.VmodelTherapy.name,
          units:this.VmodelTherapy.units
        };

        if(!this.ModeEdit){
            EventBus.$emit('onActionTherapies', {state:"Add",model:param});           
        }            
        else{
            EventBus.$emit('onActionTherapies', {state:"Edit",model:param,selected:this.Selected});           
        }
                  
      },

    hideModal() {
     
    },

  },

  mounted(){
     this.$root.$on('bv::modal::shown', () => {
      if(!this.ModeEdit){      
        this.VmodelTherapy.code = "";
        this.VmodelTherapy.name = "";
        this.VmodelTherapy.units = "";
        this.VModelUnit = "";
      }else{        
           this.VmodelTherapy.code =  this.Selected.code;
           this.VmodelTherapy.name =  this.Selected.name;
           this.VmodelTherapy.units =  this.Selected.units;
           this.VModelUnit = this.Selected.units;        
      }
        
        
      /*  this.VmodelTherapy.code = "";
        this.VmodelTherapy.name = "";
        this.VmodelTherapy.units = "";
        this.VModelUnit = "";
       // this.List = [...this.TherapyList];
        if(this.Selected){
           this.VmodelTherapy.code =  this.Selected.code;
           this.VmodelTherapy.name =  this.Selected.name;
           this.VmodelTherapy.units =  this.Selected.units;
           this.VModelUnit = this.Selected.units;
        }*/
    });
  }
}
</script>


