<template>

<div class="col-md-12 grid-margin stretch-card">
		<div v-if="this.CPS.isCpsinvolved" class="card activeCard">
			<div class="card-body">
				<div  class="d-flex align-items-start justify-content-between pointer">
					<h6 class="card-title flex-grow lower-line text-truncate">{{$t('Incident.IncChild')}}</h6>
				</div>
				<div class="float-left col-md-4 text-muted p-item" v-if="this.CPS.isCpsinvolved">
					<small>{{$t('Incident.IncCPSInvolved')}}</small><br>
					<b>{{this.CPS.isCpsinvolved ? $t('Shared.TitleYes') : 'No'}}</b>
				</div>
				<div class="float-left col-md-4 text-muted p-item" v-if="this.CPS.isCpsinvolved">
					<small>{{$t('Incident.IncScreenOut')}}</small><br>
					<b>{{this.CPS.screenOut ? $t('Shared.TitleYes'): 'No'}}</b>
				</div>
				<div class="float-left col-md-4 text-muted p-item" v-if="this.CPS.isCpsinvolved">
					<small>{{$t('Incident.IncScreenIn')}}</small><br>
					<b>{{this.CPS.screenIn ? $t('Shared.TitleYes') : 'No'}}</b>
				</div>
				<div class="float-left col-md-4 text-muted p-item" v-if="this.CPS.representativeName">
					<small>{{$t('Shared.Name')}}</small><br>
					<b>{{this.CPS.representativeName}}</b>
				</div>
				<div class="float-left col-md-4 text-muted p-item" v-if="this.CPS.reportNumber">
					<small>{{$t('Incident.IncReportNo')}}</small><br>
					<b>{{this.CPS.reportNumber}}</b>
				</div>
				<div class="float-left col-md-4 text-muted p-item" v-if="this.CPS.phoneNumber">
					<small>{{$t('Shared.Phone')}}</small><br>
					<b>{{this.CPS.phoneNumber}}</b>
				</div>
				<div class="float-left col-md-4 text-muted p-item" v-if="this.CPS.comments">
					<small>{{$t('PatientDocument.Comments')}}</small><br>
					<b>{{this.CPS.comments}}</b>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
  export default {
    props: {
		CPS: Object,
	},

    data() {
      return {
      }
    },
    
  methods:{
  },

  mounted() {
  }
}

</script>