<template>
   <fragment>
     <div :id="targetId" oncontextmenu="return false;">
        <div v-if="!isReadOnly && cmpLoaded">
          <ejs-contextmenu :id="targetId" :target="menuTargetId" :items='menuItems' :select='onSelect'></ejs-contextmenu>
        </div>
        <div v-if="VisibleLabel" class="textlabel" :data-label="Label"></div>
        <b-form-textarea
            :class="UseDinamicClass ? 'cutescroll '+DynamicClass : 'cutescroll'"
            :id="Name"
            :name="Name"                        
            :rows="Rows"
            :max-rows="MaxRows"
            :maxlength="MaxLength"
            :disabled=this.isReadOnly
            :placeholder="Placeholder"
            v-bind:value="value"
            v-on:change="OnChange"
            v-on:input="onInput"
            v-bind:style="style"
        ></b-form-textarea>
      </div>
      <!-- <MdTextSpeech></MdTextSpeech> -->
   </fragment>
</template>

<script>
  import { EventBus } from '@/event-bus.js';
  // import Utils from "@/common/utils";
  import Vue from 'vue';
  import { ContextMenuPlugin } from "@syncfusion/ej2-vue-navigations";

  Vue.use(ContextMenuPlugin);
  export default {
    name: 'MdMemo',
   props: {     
     MaxRows: String,
     Rows: String,
     MaxLength: String,
     Label:String,
     isReadOnly:Boolean,
     value:[String, Number],
     Name:String,
     Placeholder:String,
     Tag: String,
     isRequired: Boolean,
     isValid:Boolean,
     UseDinamicClass:Boolean,
     DynamicClass:String,
     VisibleLabel:Boolean,
     OnAuxFunc:Function,
     IsAux:Boolean,
   },
    watch: {
      isValid: function (newValue) {
        this.setValid(newValue)
      }
    },
    data() {
      return {
        style: {
          border: 'solid 1px #e0e0e0 !important',
          borderColor: '#e0e0e0 !important',
          padding: '5px'
        },
        Invalid: false,
        menuItems:[],
        targetId: "target-"+this.Name,
        menuTargetId:"#target-"+this.Name,
        cmpLoaded: false
      }
    },

    methods:{
    OnChange(value){
      this.$emit('input',value)
    },

    onInput(value){
      this.setChangedStatus(value)
      this.Invalid = value.length == 0 && this.isRequired ? true : false;
      this.style.borderColor = this.Invalid ? '#dc3545 !important': '#e0e0e0 !important';
      if(this.IsAux){
        this.OnAuxFunc();
      }
    },

    setValid(state) {
      this.style.borderColor = state ? '#e0e0e0 !important' : '#dc3545 !important'
    },

    setChangedStatus( sp ){
      this.$emit('input', sp)
      var changed = {
        "Changed":true,
        "ComponentName":this.Name,
        "Tag": this.Tag,
        "Value" : sp 
      }
      EventBus.$emit('onChanged', changed);
    },

    onSelect: function(args ) {
      if(args.item.id === 'recording') {
        EventBus.$emit('speechBarLabel', 'stop recording');
        this.$root.$refs.MdTextSpeech.toggleSpeechBar()
        this.$root.$refs.MdTextSpeech.startSpeechToText(this.Name);
      }
      if(args.item.id === 'speech') {
        EventBus.$emit('speechBarLabel', 'stop speech');
        this.$root.$refs.MdTextSpeech.toggleSpeechBar()
        this.$root.$refs.MdTextSpeech.startSpeech(this.value);
      }
    },
    loadMenuItems() {
      if(this.$root.$refs.MdTextSpeech.isSpeechRecognitionSupported) {
        this.menuItems.push({text: this.$t('MdTextSpeech.record'), id: "recording"})
      }
      this.menuItems.push({text: this.$t('MdTextSpeech.speech'), id: "speech"})
    },
    setValueS2T( sp ){
      let sp2t = ''
      if(this.value) {
        sp2t += Array.from (this.value +""+ sp).join("");
      } else {
        sp2t = Array.from (sp).join("");
      }
      this.Value = sp2t
      this.setChangedStatus( sp2t )
      
    }

  },
   mounted(){
     this.cmpLoaded = 'MdTextSpeech' in Vue.options.components
     if (this.cmpLoaded) this.loadMenuItems();
  },
  created() {
      EventBus.$on("receivedS2T", function (event) {
        if( event.ControlId == this.Name) { 
            this.setValueS2T( event.finalSentence )
        }
      }.bind(this))
  }
}
</script>