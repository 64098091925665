import ApiService from '@/services/api.service'

const SingleSignOnService = {
    getAll( parameter) { 
      const requestData = {
        method: 'get',
        url: "/MdSingleSignOns/",
        headers: {
        },
        params: { 
          SearchCriteria: parameter.SearchCriteria,
          PageSize: parameter.PageSize,          
          PageNumber : parameter.PageNumber         
        }
      }     
      return ApiService.customRequest(requestData);         
    },

    getSingleSignOn() {
        return ApiService.get(`/MdSingleSignOns/`);
      },
  
    get(id) {
      return ApiService.get(`/MdSingleSignOns/${id}`);
    },

    add(data){
      return ApiService.post(`/MdSingleSignOns`,data)
    },

    delete(id){
      return ApiService.delete(`/MdSingleSignOns/${id}`)
    },

    update(id,data){
      return ApiService.put(`/MdSingleSignOns/${id}`,data)
    }
}
  
  export default SingleSignOnService;