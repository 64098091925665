<template>
  <div class="col-md-12 grid-margin stretch-card">
					<div class="card activeCard">
						<div class="card-body">
							<div class="d-flex align-items-start justify-content-between">
							<h6 class="card-title flex-grow lower-line text-truncate">{{ $t('MdPatientRegistrationInfo.RegistrationInfo') }}</h6>
							</div>
							<!--<div class="row">
							<div class="float-left col text-muted p-item" v-if="RegistrationInfo.programName">
							<small>{{ $t('MdPatientRegistrationInfo.ProgramName') }}</small><br>
							<b>{{RegistrationInfo.programName}}</b>
							</div>
							<div class="float-left col text-muted p-item" v-if="RegistrationInfo.dateFrom">
							<small>{{ $t('MdPatientRegistrationInfo.AdmissionDate') }}</small><br>
							<b>{{RegistrationInfo.dateFrom}}</b>
							</div>
							<div class="float-left col text-muted p-item"> 
							<small>{{ $t('MdPatientRegistrationInfo.DischargeDate') }}</small><br>
							<b>{{RegistrationInfo.dateTo}}</b>
							</div>
							</div>
							<div class="row">
							<div class="float-left col text-muted p-item" v-if="RegistrationInfo.status">
							<small>{{ $t('MdPatientRegistrationInfo.Status') }}</small><br>
							<b>{{RegistrationInfo.status}}</b>
							</div>
							<div class="float-left col text-muted p-item" v-if="RegistrationInfo.chartNo">
							<small>{{ $t('MdPatientRegistrationInfo.CharNo') }}</small><br>
							<b>{{RegistrationInfo.chartNo}}</b>
							</div>
							</div>
							<div class="row">
							<div class="float-left col text-muted p-item" v-if="RegistrationInfo.roomName">
							<small>{{ $t('MdPatientRegistrationInfo.RoomName') }}</small><br>
							<b>{{RegistrationInfo.roomName}}</b>
							</div>
							<div class="float-left col text-muted p-item" v-if="RegistrationInfo.buildingName">
							<small>{{ $t('Shared.BuildingName') }}</small><br>
							<b>{{RegistrationInfo.buildingName}}</b>
							</div>
							</div>-->
							<div class="flex-container">
								<div class="row">
									<div class="col-md-4" v-if="RegistrationInfo.programName">
										<div class="float-left col text-muted p-item">
											<small>{{ $t('MdPatientRegistrationInfo.ProgramName') }}</small><br>
											<b>{{RegistrationInfo.programName}}</b>
										</div>
									</div>
									<div class="col-md-4" v-if="RegistrationInfo.dateFrom">
										<div class="float-left col text-muted p-item">
											<small>{{ $t('MdPatientRegistrationInfo.AdmissionDate') }}</small><br>
											<b>{{RegistrationInfo.dateFrom}}</b>
										</div>
									</div>
									<div class="col-md-4">
										<div class="float-left col text-muted p-item"> 
											<small>{{ $t('MdPatientRegistrationInfo.DischargeDate') }}</small><br>
											<b>{{RegistrationInfo.dateTo}}</b>
											</div>
									</div>
									<div class="col-md-4" v-if="RegistrationInfo.status">
										<div class="float-left col text-muted p-item">
											<small>{{ $t('Shared.Status') }}</small><br>
											<b>{{RegistrationInfo.status}}</b>
										</div>
									</div>
									<div class="col-md-4" v-if="RegistrationInfo.chartNo">
										<div class="float-left col text-muted p-item">
											<small>Chart No</small><br>
											<b>{{RegistrationInfo.chartNo}}</b>
										</div>
									</div>
									<div class="col-md-4" v-if="RegistrationInfo.buildingName">
										<div class="float-left col text-muted p-item">
											<small>{{ $t('Shared.BuildingName') }}</small><br>
											<b>{{RegistrationInfo.buildingName}}</b>
										</div>
									</div>	
									<div class="col-md-4" v-if="RegistrationInfo.roomName">
										<div class="float-left col text-muted p-item">
											<small>{{ $t('MdPatientRegistrationInfo.RoomName') }}</small><br>
											<b>{{RegistrationInfo.roomName}}</b>
										</div>
									</div>																
								</div>
							</div>
						</div>
						</div>
					</div>
</template>

<script>
import DBFunctions from '@/common/DBFunctions.js'
import moment from "moment";
  export default {
    props: {
     RecordId: String
   },
    mixins: [DBFunctions],
    data() {
      return {
		RegistrationInfo:{}
      }
    },
    
  methods:{	  
	async getPatientInfo(){
		this.RegistrationInfo = await this.getRegistrationInfo(this.RecordId);
		if(this.RegistrationInfo.dateFrom)
			this.RegistrationInfo.dateFrom = moment(this.RegistrationInfo.dateFrom).format('MM/DD/YYYY');
		if(this.RegistrationInfo.dateTo)
			this.RegistrationInfo.dateTo = moment(this.RegistrationInfo.dateTo).format('MM/DD/YYYY');	
	
	},
  },
   mounted() {
	this.getPatientInfo();
   }
}

</script>