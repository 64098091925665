import ApiService from '@/services/api.service'


const IncidentReportInterventionsSetupsService = {
    getAll( parameter) { 
         
      const requestData = {
        method: 'get',
        url: "/IncidentReportInterventionsSetups/",
        headers: {
        },
        params: { 
          Code : parameter.Code,
         }
      }     
      return ApiService.customRequest(requestData);         
    },

    getInterventions(Code){     
      return ApiService.get(`/IncidentReportInterventionsSetups/GetInterventions/${Code}`);
    },
  }
  
  export default IncidentReportInterventionsSetupsService;