<template>
  <div>
            <div class="table-notes-title">
              <div class="text-truncate t-header">{{this.Title}}</div>
              <div class="btn-group btn-group-sm float-sm-right" role="group" aria-label="Table options">
               <!-- <b-button :hidden="TableIsReadOnly" size="sm" @click="Save" class="table-title-btn">Save</b-button>
                <b-button :hidden="TableIsReadOnly" size="sm" @click="Clear" class="table-title-btn">Clear</b-button>-->
                <b-button :hidden="TableIsReadOnly || isReadOnly" size="sm" @click="addNewEmptyRecord(false)" class="table-title-btn">{{$t('Shared.Add')}}</b-button>
                <b-button :hidden="TableIsReadOnly || isReadOnly" size="sm" @click="DeleteRecord" class="table-title-btn">{{$t('Shared.Delete')}}</b-button>               
              </div>
            </div>
            <b-table
                sticky-header
                selectable
                class="table table-sm  medezTables noteTable cutescroll"
                :select-mode="fMultiSelect"
                :items="items"
                :fields="fFields"
                :current-page="cPage"                
                @row-selected="rowSelected"
                responsive
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="filtered"               
            >     
         
            <template v-slot:cell(Therapies)="row">                    
             <select class="cutescroll" v-on:change="ChangeTerapies($event,row)" v-on:focus="OnFocusRow(row)" style="width:100%;" :disabled="isReadOnly || row.item.billed" v-model="row.item.code" v-on:input="onInput">
                <option v-for="val in ObjectItems.ArrayValues" :key="val.code" :value="val.code">{{val.name}}</option>             
            </select>                       
            </template>
            <template v-slot:cell(Units)="row">
               <b-form-input
               :min="1"
               v-model.number="row.item.units"
               :readonly="isReadOnly || row.item.billed"
               type="number"  
               v-on:focus="OnFocusRow(row)"
               v-on:input="onInput"/>
            </template>
            </b-table>
  </div>
</template>

<script>

import ShowMessage from '@/components/messages/ShowMessage.js'
import Utils from '@/common/utils'
import { EventBus } from '@/event-bus.js';
  export default {
    name:"MdTableProvider",
    mixins: [ShowMessage],
    props: {  
     fields: Array,
     items:Array,
     ObjectItems:[Object],     
     totalRows: Number,    
     isReadOnly: Boolean,
     deleteIconEvent: Function,
     onRowSelected: Function,
     currentPage: Number,
     onFiltered: Function,
     perPage: Number,
     pageChanged: Function,
     perPageFunction: Function,
     value: [String, Number, Object, Array],
     noteStatus: String,
     defaultUnit: Number,
     transCode: String,
     Tag: String
   },
    data() {
      return {
          filter: null,
          filterOn: [],
          Title:'',
          pPage: this.perPage,
          cPage: this.currentPage,
          fFields:this.fields,
          ModelSelect:{
            
          },
          TableIsReadOnly:false,
          TableIsrequired:false,       
          fMultiSelect: 'single',
          pageOptions: [5, 10, 15 ],
          lookUpValues:{},
          MaxRecId:-1,
          PositionsByRows:[],
          ColumnsCalulated:[],
          cal:"",
          isNewRecord: false,
          index: -1
      }
    },
    
  methods:{
    OnFocusRow(row){       
        this.index = row.index;      
    },

    ChangeTerapies(/*e,row*/){
      //console.log(e);
     /* var defaultunit = this.FindDefaultUnit(e.target.value);
      if(defaultunit !== -1){
        row.item.units = defaultunit;
        this.$set(row.item, 'units', defaultunit);
      }*/
      //console.log('units');
      //console.log(row);
      //row.item.units = 6;
      //this.$set(row.item, 'units', 6);
     // this.$emit('input', 8);
       //this.$set(row.item, 'units', 8);
      //row.item.units
    },

    FindDefaultUnit(code){
      var dU = -1;
      this.ObjectItems.ArrayValues.forEach(function(element) {
           if(element.code === code){           
            dU = element.defaultunit;             
           }
          }); 

          return dU;
    },

    ResetPositions(){
        for(var i = 0; i < this.PositionsByRows.length; i++){
          if(this.PositionsByRows[i] !== -1){
            this.PositionsByRows[i] = -1;
            break;
          }
        }
    },

    IfContaintColumn(){
        var e = this.cal;
        var result = false;
         this.fFields.forEach(function(element) {
           if(e.includes(element.key)){
             result = true;            
           }
          }); 
          return result;
    },

    OnChange(){
 
    },

    HasFieldTypeDateTime(){
        var temparr = this.fFields;
        var result = false;         
           temparr.forEach(function(element) { 
             if(element.dataType !== null){
                if(element.dataType.toLowerCase() === 'datetime'.toLowerCase()){
                  result = true;
                }
             }
             
           })
        return result;
    },

    GetFieldsTypeDateTime(){
        var temparr = this.fFields;
        var result = [];         
           temparr.forEach(function(element) { 
             if(element.dataType !== null){
                 if(element.dataType.toLowerCase() === 'datetime'.toLowerCase()){
                      result.push(element.key);
                  }
             }
             
           })
        return result;
    },

    FormatToDateTime(){
        var l = this.value.length;       
        var listcolumns = this.GetFieldsTypeDateTime();
        var ll = listcolumns.length;
        var currentRow = [];
        for(var i = 0; i < l; i++){
          currentRow = this.value[i];
          for(var j = 0; j < ll; j++){
              var column = listcolumns[j];
            if(Utils.isValidDate(currentRow[column])){                 
                currentRow[column] = Utils.formatterDateTimeToString(currentRow[column]);
              }          
          }
          
        }
    },

    InitializeVariables(){
      if(this.items.length==0)
          this.addNewEmptyRecord(true)
      if(this.noteStatus == 'New'){
        this.items[0].units = this.defaultUnit;
        const transCode = this.ObjectItems.ArrayValues.find( ({ code }) => code === this.transCode )
        if (transCode) {
          this.items[0].code = this.transCode
        } else {
          this.items[0].code = "";
        }
      }
    },

    editButton(row) {
      this.editIconEvent(row);
    },

    deleteButton(row) {
      this.deleteIconEvent(row);
    },

    rowSelected() {

    },

    filtered(filteredItems) {
      this.onFiltered(filteredItems);
    },

    fpageChanged(page) {
      this.pageChanged(page);
    },

    pageFunction(perpage) {
      this.perPageFunction(perpage);
    },

    otherButton(row) {
      this.otherIconEvent(row);
    },

    viewButton(row) {
      this.viewIconEvent(row);
    },

    addNewEmptyRecord(firstTime) {
      if(!firstTime){
        this.setChangedStatus()
      }
      let newFields = {};
      var maxRec = this.MaxRecId;
      this.fFields.forEach(element => {      
        newFields[element.key] = element.defaultValue?element.defaultValue:'';
        if(element.key.toLowerCase() === 'RecID'.toLowerCase()){
           newFields[element.key] = maxRec;
        }
      });
      this.items.unshift(newFields);
    },

    Refresh(){

    },

    Clear(){
      this.$bvModal.msgBoxConfirm(this.$t('Msg.DeleteAllRecord'), {
					title: this.$tc('Shared.DelRecord',2),
					okTitle: this.$t('Shared.TitleDeleteAll'),
					cancelTitle: this.$t('Shared.BtnCancel'),
					footerClass: 'p-2',
					titleClass: 'modal-title modal-type-warning'
				})
          .then(value => {
            if(value)
               this.items = [];
               this.setChangedStatus()
          })
    },

    DeleteRecord(){
      if(this.items.length > 0 && this.items[this.index].billed) {
        this.showMessage(this.$t('Shared.Therapies'),this.$t('Msg.CanNotModifythisEntry'),'error');
      } else {
        if(this.index !== -1){
        this.$bvModal.msgBoxConfirm(this.$t('Msg.DeleteRecord'), {
					title : this.$tc('Shared.DelRecord'),
					okTitle: this.$t('Shared.TitleDelete'),
					cancelTitle: this.$t('Shared.BtnCancel'),
					footerClass: 'p-2',
					titleClass: 'modal-title modal-type-warning'
				})
          .then(value => {
            if(value){
                this.items.splice(this.index, 1);
                this.setChangedStatus()               
            }
          })
        }
      }  
    },

    Save(){

    },

    cellChange() {

    },

    resizeColumn: function () {
      var thElm;
      var startOffset;
      Array.prototype.forEach.call(
      document.querySelectorAll("table th"),
      function (th) {
          var grip = document.createElement('div');
          grip.innerHTML = "&nbsp;";
          grip.style.top = 0;
          grip.style.right = 0;
          grip.style.bottom = 0;
          grip.style.width = '5px';
          grip.style.position = 'absolute';
          grip.style.cursor = 'col-resize';
          grip.addEventListener('mousedown', function (e) {
              thElm = th;
              startOffset = th.offsetWidth - e.pageX;
          });
          th.appendChild(grip);
      });
      document.addEventListener('mousemove', function (e) {
          if (thElm) {
              thElm.style.width = startOffset + e.pageX + 'px';
          }
      });
      document.addEventListener('mouseup', function () {
          thElm = undefined;
      });
    },

    delFirstEmptyRow() {
      let busyCell = 0
      this.fFields.forEach(element => {
        if(element.key != 'RecID')
           if(this.items[0][element.key].length != 0)
            busyCell = busyCell + 1
        });
        if(busyCell == 0) {
          this.items.splice(0, 1)
          this.setFirstCellPos
        }
        this.isNewRecord = false
    },

    setFirstCellPos() {
      this.$refs[this.fFields[0].key+'_'+0].focus()
    },

    keyDown(cellID) {
      let fieldName = cellID.split("_")[0] 
      let currIndex = cellID.split("_")[1]
      let nextIndex = parseInt(currIndex) + 1
      if(this.isNewRecord && currIndex == 0) {
        this.delFirstEmptyRow()
      } else {
        if(this.$refs[fieldName+'_'+nextIndex])
          this.$refs[fieldName+'_'+nextIndex].focus()
        else {
          this.addNewEmptyRecord(false)
          this.isNewRecord = true
          this.setFirstCellPos()
        }
      }
    },

    keyUp(cellID) {
      let fieldName = cellID.split("_")[0] 
      let currIndex = cellID.split("_")[1]
      let prevIndex = parseInt(currIndex) - 1
      if(this.$refs[fieldName+'_'+prevIndex])
          this.$refs[fieldName+'_'+prevIndex].focus()
    },

    keyEsc(cellID) {
      let currIndex = cellID.split("_")[1]
      if(this.isNewRecord && currIndex == 0)
        this.delFirstEmptyRow()
    },

    setChangedStatus(){
      var changed = {
        "Changed":true,
        "ComponentName":'MdEditTable',
        "Tag": this.Tag
        }
        EventBus.$emit('onChanged', changed);
    },

    onInput(){     
      this.setChangedStatus();      
    }
 },
  created() {
    if(this.deleteIconEvent ) {
      this.fFields.unshift({ key: 'actions', label: this.$tc('Shared.ActionsTxt',2)});
    }
  },
  mounted(){
    this.InitializeVariables();
    this.resizeColumn();
  }
}

</script>
<style>
.hidden_header {
  visibility: collapse;
}
</style>