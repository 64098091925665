<template>
  <router-view />
</template>

<script>
// import UserService from '@/services/user.service'

export default {
  name: 'App',
  created() {
    const currentPath = this.$router.history.current.path;

    // if (!UserService.IsAuthenticated()) {
    //   this.$router.push('/login');  
    // }   
   
    if (currentPath === '/' || currentPath === '/app') {
      this.$router.push('/app/main');
    }

  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
