<template>	
	<div class="row page-height">
		<div class="col-12">
			<MdTitle 
			v-bind:Text="$t('claimbatchSummary.CBDashBoard')+' - AMYA BEASLEY - 25448 - DOB 12/10/1981 AGE 40' /*+ this.PatientTitle.fullName + ' - ' + this.$route.params.id*/" 
			/>
			<div class="row">
				<MdPatientInfoBox
					v-bind:PatientCode="this.$route.params.id">
				</MdPatientInfoBox>
			</div>

			<div class="space-actions"></div><div class="page-actions">
				<MdButton 
					v-bind:Label="$t('claimbatchSummary.List')"
					v-bind:Name="'btnpatientlist'"
					v-bind:ClassProp="'btn btn-primary mx-2 btn-login'"
					v-bind:IconClass="'ti-back-left pr-1'"
					v-on:click="GoToPatientList"/>
			</div>
		</div>
	</div>
	
   
</template>

<script>
import EnumService from '@/services/enum.service'
import Utils from '../../../common/utils'
import DBFunctions from '@/common/DBFunctions.js'

export default {
  name: 'PatientSummary',
    mixins: [DBFunctions],
    data() {
		return {		
			InfoPatient:{},
	PatientTitle:{      
       programName:'',
       fullName:'',
       admitDate:'',
       dischargeDate:'',
       dob:''
     }, 
			patient:{},
			VisibleH6:false,
			Allergies:[],
			Diagnosis:[],
			OptionsRightMenu:{
				visible:true,
				ItemsList:[],
				btnvisible:false,
				itemdisabled: false,
				PatientCode:'',
				Mode: this.$attrs.editMode,
			},
		}
	},

	async mounted() {
		this.GetPatientByCode();
		this.OptionsRightMenu.ItemsList = EnumService.ClaimBatchItems;
		this.OptionsRightMenu.Mode = this.edit_mode;
		this.OptionsRightMenu.visible = true;    
		this.OptionsRightMenu.btnvisible = true;
		this.OptionsRightMenu.itemdisabled = false;
		this.$emit('getVisibleMenu', this.OptionsRightMenu);
		this.$emit('getBtnRightMenu', this.OptionsRightMenu);
	},
	methods:{

	async GetPatientByCode(){
      var infoList = await this.getInfoTitle(this.$route.params.id);
      if(infoList.length > 0){
          this.PatientTitle.programName = '';
          this.PatientTitle.admitDate = '';
          this.PatientTitle.dischargeDate = '';
          this.PatientTitle.dob = infoList[0].dob;
          this.PatientTitle.fullName = infoList[0].firstname + ' '+ infoList[0].lastname;
      }
    },

		GoToPatientInfo(){
		this.$router.push({ name: "patient-details", params: { id: this.$route.params.id } }).catch(()=>{});
		},

		GoToPatientList(){
		this.$router.push('/app/claimbatch').catch(()=>{});
		},

		VisibleAsam(){
			if(this.$store.state.isUseASAM) {
				return true;
			}
			return false;
		},
			
		formatterDate(value){
			return Utils.formatterDate(value);
		},
		formatterDateToString(date){
			return Utils.formatterDateToString(date);
		}
	}
}


</script>


