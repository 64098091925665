import ApiService from '@/services/api.service'

const AppointmentTypes = {
    getAll(employeeCode) { 
        const requestData = {
          method: 'get',
          url: "/AppointmentTypes/",
          headers: {
          },
          params: { 
            EmployeeCode: employeeCode,
          }
        }     
        return ApiService.customRequest(requestData);         
      },

      getAllLinkedWithNotes(employeeCode) { 
        const requestData = {
          method: 'get',
          url: "/AppointmentTypes/?LinkedWithNotes=true",
          headers: {
          },
          params: { 
            EmployeeCode: employeeCode,
          }
        }     
        return ApiService.customRequest(requestData);         
      },

      getAllbyParam(param) { 
        const requestData = {
          method: 'get',
          url: "/AppointmentTypes/",
          headers: {
          },
          params: param
        }     
        return ApiService.customRequest(requestData);         
      },

      getListing() { 
        const requestData = {
          method: 'get',
          url: "/AppointmentTypes/",
          headers: {
          },         
        }     
        return ApiService.customRequest(requestData);         
      },

    getAllEmployees(parameter) { 
      const requestData = {
        method: 'get',
        url: "/AppointmentTypes/",       
        params: {
          SearchCriteria: parameter.SearchCriteria,
          PageSize: parameter.PageSize,
          PageNumber: parameter.PageNumber,  
        }
      }    
        return ApiService.customRequest(requestData);         
    },

    Add(data){
        return ApiService.post(`/AppointmentTypes`, data);
    },
    Delete(Id){      
        return ApiService.delete(`/AppointmentTypes/${Id}`);
    },
    Update(Id,data){
        return ApiService.put(`/AppointmentTypes/${Id}`,data);
    },   
    Get(Id){     
        return ApiService.get(`/AppointmentTypes/${Id}`);
    },
    getAppoinmentTypeByCode(parameter){
      const requestData = {
        method: 'get',
        url: "/AppointmentTypes/",
        headers: {
        },
        params: {          
          code: parameter.code           
        }
      }     
      return ApiService.customRequest(requestData);       
    },
}

export default AppointmentTypes;