import ApiService from '@/services/api.service'

const GlobalService = {

    getCustomGlobalCodes(param) {  
          
        const requestData = {
            method: 'get',
            url: "/GlobalCodes",
            headers: {
                
              },
            params: { 
                CodeType: param.CodeType,
                GROUPCREATION:param.GroupCreation
            }
        }
        return ApiService.customRequest(requestData);
    },

    getEthnicity( ) {  
          
        const requestData = {
            method: 'get',
            url: "/GlobalCodes",
            headers: {
                
              },
            params: { 
                CodeType: 'ETHNICITY'
            }
        }
        return ApiService.customRequest(requestData);
    },

    getContactType( ) {  
          
        const requestData = {
            method: 'get',
            url: "/GlobalCodes",
            headers: {
                
              },
            params: { 
                CodeType: 'CONTACTTYPE'
            }
        }
        return ApiService.customRequest(requestData);
    },

    getGender( ) {  
          
        const requestData = {
            method: 'get',
            url: "/GlobalCodes",
            headers: {
                
              },
            params: { 
                CodeType: 'GENDER'
            }
        }
        return ApiService.customRequest(requestData);
    },

    getEyeColor( ) { 
           
        const requestData = {
            method: 'get',
            url: "/GlobalCodes",
            headers: {
                
              },
            params: { 
                CodeType: 'EYECOLOR'
            }
        }
        return ApiService.customRequest(requestData);
    },

    getHairColor( ) {
            
        const requestData = {
            method: 'get',
            url: "/GlobalCodes",
            headers: {
                
              },
            params: { 
                CodeType: 'HAIRCOLOR'
            }
        }
        return ApiService.customRequest(requestData);
    },

    getCommPreference( ) {  
          
        const requestData = {
            method: 'get',
            url: "/GlobalCodes",
            headers: {
                
              },
            params: { 
                CodeType: 'COMMUNICATIONPREF'
            }
        }
        return ApiService.customRequest(requestData);
    },

    getFamilyIncome( ) { 
           
        const requestData = {
            method: 'get',
            url: "/GlobalCodes",
            headers: {
                
              },
            params: { 
                CodeType: 'FAMILYINCOME'
            }
        }
        return ApiService.customRequest(requestData);
    },
    
    getPersonalIncome( ) { 
           
        const requestData = {
            method: 'get',
            url: "/GlobalCodes",
            headers: {
                
              },
            params: { 
                CodeType: 'PERSONALINCOME'
            }
        }
        return ApiService.customRequest(requestData);
    },
    
    getMajorLanguage( ) { 
           
        const requestData = {
            method: 'get',
            url: "/GlobalCodes",
            headers: {
                
              },
            params: { 
                CodeType: 'MAJORLANGUAGE'
            }
        }
        return ApiService.customRequest(requestData);
    },

    getMajorLanguageAbility( ) { 
           
        const requestData = {
            method: 'get',
            url: "/GlobalCodes",
            headers: {
                
              },
            params: { 
                CodeType: 'LANGUAGEABILITYMODE'
            }
        }
        return ApiService.customRequest(requestData);
    },

    getMajorReligion( ) {
            
        const requestData = {
            method: 'get',
            url: "/GlobalCodes",
            headers: {
                
              },
            params: { 
                CodeType: 'MAJORRELIGION'
            }
        }
        return ApiService.customRequest(requestData);
    },

    getDisability( ) { 
           
        const requestData = {
            method: 'get',
            url: "/GlobalCodes",
            headers: {
                
              },
            params: { 
                CodeType: 'DISABILITY'
            }
        }
        return ApiService.customRequest(requestData);
    },
    
    getLegalStatus( ) {  
          
        const requestData = {
            method: 'get',
            url: "/GlobalCodes",
            headers: {
                
              },
            params: { 
                CodeType: 'LEGALSTATUS'
            }
        }
        return ApiService.customRequest(requestData);
    },

    getEducationStatus( ) {
           
        const requestData = {
            method: 'get',
            url: "/GlobalCodes",
            headers: {
                
              },
            params: { 
                CodeType: 'EDUCATIONSTATUS'
            }
        }
        return ApiService.customRequest(requestData);
    },

    getNoteOptions( ) {           
        return ApiService.get(`/NoteOptions/`);
    },
    
    getPrograms(PatientCode){    
        const requestData = {
          method: 'get',
          url: "/PatientRegistrationSummaries/",     
          params: {
            PatientCode: `${PatientCode}`,    
          }
        }
        return ApiService.customRequest(requestData);
      },

      getNotesTherapies(param){    
        const requestData = {
          method: 'get',
          url: "/NotesTherapies/ByProgram",     
          params: {
            SelDateFrom: `${param.DateFrom}`, 
            SelDateTo:`${param.DateTo}`,
            ProgramCode:`${param.ProgramCode}`,
            EmployeeCode:`${param.EmployeeCode}`,
            RecordId:`${param.RecordId}`
          }
        }
        return ApiService.customRequest(requestData);
      },
      getNoteTypes(LinkedWithTherapies) {
        return ApiService.get(`/NotesTypes?LinkedWithTherapies=${LinkedWithTherapies}`);
      },
    
      getNoteTypesOnClinicalNotesReport(LinkedWithTherapies,FormDesingerNotes) {
        const params = {      
            LinkedWithTherapies : LinkedWithTherapies,
            CustomFrame : FormDesingerNotes
        }
        return ApiService.getWithArray('/NotesTypes',params); 
      },

    getHours(NotesId){
        return ApiService.get(`/NoteOptions/Hours/?NoteId=${NotesId}`);
    },     
    
    getDateTime(){
        return ApiService.get(`/Providers/GetDateTime`);
    },
    getheaderId(){
        return ApiService.get(`/Utils/GetTimedKey`);
    },
    getCityAndStateByZipCode(ZipCode){
        return ApiService.get(`/ZipCodes/${ZipCode}`);
    },  
    RtfToHtml(rtf) {
        return ApiService.post(`/Utils/RtfToHtml`,rtf);
    },

    getDoctors(){
        return ApiService.get(`/Doctors`);
    },

    getReferrals(){
        return ApiService.get(`/Referrals`);
    },

    getEmployees(){
        return ApiService.get(`/Employees`);
    },
    getEmployeeActive(){
        return ApiService.get(`/Employees/?Active=true`);
    },
    getPatientContacts(PatientCode){
        return ApiService.get(`/PatientContacts/?PatientCode=${PatientCode}`);
    },

    IsActiveAdmission(recordId){
        return ApiService.get(`/Utils/IsActiveAdmission?recordId=${recordId}`);    
    },
        
    getAdmissionLastTransactionDate(recordId){
        return ApiService.get(`/Utils/AdmissionLastTransactionDate?recordId=${recordId}`);    
    },

    getTherapies(){
        return ApiService.get(`/NotesTherapies/UsedOnNotes`);
    },
    getFormatSelection(FormatCode){       
        return ApiService.get(`/ExportClinicalPdfFormatSelections?FormatCode=${FormatCode}`);    
    },
    getTimeZone(){
        return ApiService.get(`/Providers/GetTimeZone`);
    },
    getIPFSetups(){
        return ApiService.get(`/IPFSetups`);
    },
    
}
  
  export default GlobalService;