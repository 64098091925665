<template>
   <div>
    <b-modal :id="modalId" size="xl" centered scrollable  :title="TitleWindow" @ok="this.emitEventOK" @cancel="this.emitEventCancel" @hide="resetModal"> 
      <b-row style="height:240px;">
        <b-col lg="3" md="3" sm="12">
           <MdSelect
              v-bind:Label="$t('Shared.Doctors')"
              v-bind:Options="DoctorsList"
              v-bind:Model="VmodelDoctors"
              v-bind:FieldDescription="'name'"
              v-bind:FieldCode="'code'"
              v-bind:Multiple="true"
              v-bind:IfUsevalidation="false"
              :Input="ChangeDropDownDoctors"/>
        </b-col>
        <b-col lg="3" md="3" sm="12">
           <MdSelect
              v-bind:Label="$t('ModalAdmissionTeamMembers.Referrals')"
              v-bind:Options="ReferralsList"
              v-bind:Model="VmodelReferrals"
              v-bind:FieldDescription="'name'"
              v-bind:FieldCode="'code'"
              v-bind:Multiple="true"
              v-bind:IfUsevalidation="false"
              :Input="ChangeDropDownReferrals"/>
        </b-col>
        <b-col lg="3" md="3" sm="12">
           <MdSelect
              v-bind:Label="$tc('Shared.Employee',2)"
              v-bind:Options="EmployeesList"
              v-bind:Model="VmodelEmployees"
              v-bind:FieldDescription="'name'"
              v-bind:FieldCode="'code'"
              v-bind:Multiple="true"
              v-bind:IfUsevalidation="false"
              :Input="ChangeDropDownEmployees"/>
        </b-col>
        <b-col lg="3" md="3" sm="12">
           <MdSelect
              v-bind:Label="$tc('Contact.Contact',2)"
              v-bind:Options="ContactsList"
              v-bind:Model="VmodelContacts"
              v-bind:FieldDescription="'name'"
              v-bind:FieldCode="'code'"
              v-bind:Multiple="true"
              v-bind:IfUsevalidation="false"
              :Input="ChangeDropDownContacts"/>
        </b-col>
      </b-row>
    </b-modal>
   </div>
</template>

<script>
import GlobalService from '@/services/global.service' 
export default {
  name: 'ModalAdmissionTeamMembers',
   props: {
     ModalId: String,
     TitleWindow:String
   },
   data(){
       return {
           rowSelected:[],
           modalId:this.ModalId,
           currentPage:1,
           perPage:10,
           test:{},
          selectedItems:[],
          DoctorsList:[],
          ReferralsList:[],
          EmployeesList:[],
          ContactsList:[],
          VmodelDoctors:"",
          VmodelReferrals:"",
          VmodelEmployees:"",
          VmodelContacts:"",

          Doctors:[],
          Referrals:[],
          Employees:[],
          Contacts:[],
           
        Therapies:[
        {value:'A',name: this.$t('Shared.Admissions')},
        {value:'B',name: this.$t('ModalAdmissionTeamMembers.TherapiesDischargeInformation')},
        {value:'C',name: this.$t('ModalAdmissionTeamMembers.TherapiesGroupNotes')},
        {value:'D',name: this.$t('ModalAdmissionTeamMembers.TherapiesNursing')},
        {value:'E',name: this.$t('ModalAdmissionTeamMembers.TherapiesMiscellanous')},
        {value:'F',name: this.$t('ModalAdmissionTeamMembers.TherapiesIntakeForms')},
    ],


       }
   },

   methods:{
     emitEventOK(){  
       var obj = {
          doctors:this.Doctors,
          referrals:this.Referrals,
          employees:this.Employees,
          contacts:this.Contacts,
       }; 
        this.$emit('getCodeConditionOK', obj);
      },

      emitEventCancel(){
       // this.$emit('getCodeConditionCancel');
      }, 

      resetModal(){

      },

      funcDestination(value){
        this.selectedItems = value;
      },

      pageChanged (page) {         
        this.currentPage = page;    
      },

      PerPageFunction(perpage){
       this.perPage = perpage;
       this.currentPage = 1;
      },

      ChangeDropDownDoctors(doctors){
        this.Doctors = [];
        var d = [];
       doctors.forEach(function(e) {   
            d.push(e);               
        });

        this.Doctors = d;
      },

      ChangeDropDownReferrals(referals){
        this.Referrals = [];
        var r = [];
       referals.forEach(function(e) {   
            r.push(e);               
        });

        this.Referrals = r;
      },

      ChangeDropDownEmployees(employees){
        this.Employees = [];
        var em = [];
       employees.forEach(function(e) {   
            em.push(e);               
        });

        this.Employees = em;
      },

      ChangeDropDownContacts(contacts){
        this.Contacts = [];
        var c = [];
       contacts.forEach(function(e) {   
            c.push(e);               
        });

        this.Contacts = c;
      },

      Search(){
        this.currentPage = 1; 
      },

       getDoctors(){
           GlobalService.getDoctors()
                .then((response) => {
                    if(response.status == '200'){
                        this.DoctorsList = response.data;                       
                    }                             
                })
                .catch((error) => {
                    if (error.response) {
                        this.showMessage(this.$t('ModalAdmissionTeamMembers.DoctorList'),error,"error");
                    }
                });
      },

      getReferrals(){
           GlobalService.getReferrals()
                .then((response) => {
                    if(response.status == '200'){
                        this.ReferralsList = response.data;
                    }                             
                })
                .catch((error) => {
                    if (error.response) {
                        this.showMessage(this.$t('ModalAdmissionTeamMembers.ReferralsList'),error,"error");
                    }
                });
      },

       getEmployees(){
           GlobalService.getEmployeeActive()
                .then((response) => {
                    if(response.status == '200'){
                        this.EmployeesList = response.data; 
                    }                             
                })
                .catch((error) => {
                    if (error.response) {
                        this.showMessage(this.$t('ModalAdmissionTeamMembers.EmployeesList'),error,"error");
                    }
                });
      },

      getPatientContacts(){          
           GlobalService.getPatientContacts(this.$route.params.id)
                .then((response) => {
                    if(response.status == '200'){
                        this.ContactsList = response.data;  
                     for(var j = 0; j < this.ContactsList.length; j++){
                        var firstname = this.ContactsList[j].firstName ? this.ContactsList[j].firstName : "";
                        var lastname = this.ContactsList[j].lastName ? this.ContactsList[j].lastName : "";
                        this.ContactsList[j].name = firstname + ' '+lastname;  
                        this.ContactsList[j].code = this.ContactsList[j].recordId;                        
                     }
                             
                    }                             
                })
                .catch((error) => {
                    if (error.response) {
                        this.showMessage(this.$t('ModalAdmissionTeamMembers.PatientContactsList'),error,"error");
                    }
                });
      },
   
   },

   mounted(){
      this.getDoctors();
      this.getReferrals();
      this.getEmployees();
      this.getPatientContacts();
      this.$root.$on('bv::modal::shown', () => { 
       // this.getData();
      
     
      });
      this.$root.$on('bv::modal::show', () => {
        //this.currentPage = 1;     
        this.$nextTick(() => {
          setTimeout(500);
        });
      });
   }
   
};
</script>