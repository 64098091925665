<template>
  <div id="ContainerPatientContacts">
    <div class="row">
      <div class="col-12">
      <h4 class="page-title text-truncate"> {{ $tc('Contact.PatientContact',2) }} - 
                    <span v-if="this.PatientTitle.fullName">{{this.PatientTitle.fullName + ' - '}}</span>
                    <span v-if="this.$route.params.id">{{this.$route.params.id}}</span>
                    <span v-if="this.PatientTitle.dob"> - {{ $t('Shared.Dob') }} {{this.PatientTitle.dob}}</span>
                    <span v-if="this.PatientTitle.ageFormatted"> {{ $t('Shared.Age') }} {{this.PatientTitle.ageFormatted}}</span>
                    <span v-if="this.PatientTitle.programName"> - {{this.PatientTitle.programName}}  </span>
                    <span v-if="this.PatientTitle.dateFrom">- {{this.PatientTitle.dateFrom}}  </span>
                    <span v-if="this.PatientTitle.dateTo">- {{this.PatientTitle.dateTo}}  </span>
                    <span v-else v-show="IsClinical">- {{ $t('Shared.Active') }}  </span>
      </h4>
            <div class="row">
                <div class="col-sm-12 col-md-3">                      
                    <MdButton
                      v-bind:Label= "$t('ContactsList.AddContact')" 
                      v-bind:ClassProp="this.isReadOnly ? 'btn btn-primary my-3 btn-block' : 'btn btn-primary my-3 btn-block btn-login'"
                      v-bind:IconClass="'ti-plus pr-1'"
                      v-on:click="AddContact"
                      v-bind:isReadOnly=this.isReadOnly
                      v-bind:Name="'btn_add_contact'">
                    </MdButton>
                </div>
                <div class="col-sm-12 col-md-9 mb-4">
                    <div class="textlabel" :data-label="this.$t('Shared.SearchElements')">
                        <div class="input-group">
                            <input 
                              type="text" 
                              class="form-control"
                              v-model="parameter.SearchCriteria"   
                                @keyup.enter="SearchContacts"                            
                            >
                            <div class="input-group-append">
                                <div class="input-group-append">
                                <MdButton 
                                  v-bind:Name="'btnsearchinput'"
                                  v-bind:VariantProp="'btn btn-sm search-attached'"
                                  v-bind:IconClass="'mdi mdi-magnify'"
                                  v-on:click="SearchContacts">
                                </MdButton>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>              
        </div>
    </div>
    <div class="row">
      <div class="col-12 mt-2">
        <MdTitle v-bind:Text="$t('Shared.SearchResult')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
        <div class="row">
          <div class="col-md-12">
              <MdTable 
              v-bind:fields="fields"
              v-bind:items="items"
              v-bind:totalRows="totalRows"
              v-bind:isReadOnly="isReadOnly"
              v-bind:showMessageIsEmpty="ShowMessageIsEmpty"
              v-bind:currentPage="currentPage"
              v-bind:perPage="perPage"
              v-bind:pageOptions="pageOptions"
              :editIconEvent="EditContact"
              v-bind:VisibleEditIcon="true"
              :deleteIconEvent="RemoveContact"
              v-bind:VisibleDeleteIcon="true"
              :onRowSelected="onRowSelected"
              :onFiltered="onFiltered"
              :pageChanged="pageChanged"
              :perPageFunction="PerPageFunction"
              :otherIcon="'printer-fill'"
              :otherIconEvent="showReport">
            </MdTable>
          </div>
        </div>
      </div>
    </div>
    <div class="space-actions"></div>
    <div class="page-actions">
      <MdButton
        v-bind:Label="$t('Shared.Report')"
        v-bind:Name="'btnpatientlist'"
        v-bind:ClassProp="'secondary mx-2 btn-login'"
        v-on:click="showReport()"/>
    </div>
    <PatientContactsRep v-if="isReportVisible" 
      :closeWindow="closeParamRepWindow"
      :patientCode="this.$route.params.id"
      :recNo="recNoToReport"
      :patientName="this.PatientTitle.fullName"
      :filterView="this.filterView"
      />
  </div>
</template>



<script>
import ContactService from '@/services/contact.service'
import EnumService from '@/services/enum.service' 
import ShowMessage from '@/components/messages/ShowMessage.js'
import DBFunctions from '@/common/DBFunctions.js'
import moment from 'moment'

export default {

  beforeRouteEnter(to, from, next) {
        next(async vm => {
            const iUA = await vm.$store.state.isUsePatientContact;
            if(iUA) next()
            else next('/accessdenied');
        }) 
  },

  mixins: [ShowMessage, DBFunctions],
  name: 'Contacts',
  /* components:{
Contact
 },*/
  data() {
    return {
      patient:{},
      IsClinical:false,
      RegistrationInfo:{},
      ShowMessageIsEmpty:false,
      Loading:true,
      OptionsRightMenu:{
            visible:true,
            ItemsList:[],
            btnvisible:true,
            itemdisabled: false,
            PatientCode:''         
        },  
        ContactId:'',   
        Mode:'',         
        parameter:{
        SearchCriteria:'',
        PageNumber : 0,
        PageSize:0 ,
        PatientCode:''             
      },
      fields: [
        { key: 'contactname', sortable: true, label: this.$t('ContactsList.ContactName'), class: 'text-rigth' },       
        { key: 'cellPhone', sortable: true, label: this.$t('Shared.CellPhone'), class: 'text-rigth' },      
        { key: 'dayPhone', sortable: true, label: this.$t('Shared.DayPhone'), class: 'text-rigth' },       
        { key: 'email', sortable: true, label: this.$t('Shared.Email'), class: 'text-rigth' },
        { key: 'recordId', sortable: true, label: 'RecorId', class: 'hidden' },
        { key: 'relation', sortable: true, label: this.$t('Shared.Relation'), class: 'text-rigth' },  
        
        ],

      pageOptions: [5, 10, 15 ],
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      filter: null,
      filterOn: [],
      items: [],
      selected: [],
      Header:{},
       PatientTitle:{      
       programName:'',
       fullName:'',
       admitDate:'',
       dischargeDate:'',
       dob:''
     }, 
      isReadOnly: false,
      destination:[],
      isReportVisible: false,
      recNoToReport: null,
      filterView: false
    }
  },

  computed: {
      sortOptions() {       
        return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          });
      }    
  },
    
  methods: {
    
    async GetPatientByCode(){
      var infoList = await this.getInfoTitle(this.$route.params.id);
      if(infoList.length > 0){
          this.PatientTitle.programName = '';
          this.PatientTitle.admitDate = '';
          this.PatientTitle.dischargeDate = '';
          this.PatientTitle.age = infoList[0].age;
          this.PatientTitle.ageFormatted = infoList[0].ageFormatted;
          if(infoList[0].dob){
              this.PatientTitle.dob = moment(infoList[0].dob).format('MM/DD/YYYY');
          }   
          this.PatientTitle.fullName = infoList[0].firstname + ' '+ infoList[0].lastname;
      }
    },

    SearchContacts(){
      this.Loading = true;
      this.$emit('load', this.Loading);
      this.items = [];
      this.currentPage = 1;
      this.parameter.PageNumber = 1;
      this.totalRows = this.items.length;
      this.getContacts();
    },

    funcDestination(value) {
     this.destination=value;
   },

    OnUpdateContactList() {
      this.getContacts();
    },

    getContacts() { 
      this.parameter.PageNumber = this.currentPage;           
      this.parameter.PageSize = this.perPage;
      this.parameter.PatientCode = this.$route.params.id;
      ContactService.getAll(this.parameter)
      .then((response) => {
        if(response.status == '200' && response.data && response.data.length > 0){
         this.items = response.data;
         let headerString = response.headers["x-pagination"];
         this.Header = JSON.parse(headerString);
         this.totalRows = this.Header.TotalCount;
         this.items.forEach(item => {
           item.contactname = (item.firstName ? item.firstName:'') + " " + (item.lastName? item.lastName:''); 
         });
        }
        if(this.items.length === 0){
           this.ShowMessageIsEmpty = true;
        }
        this.Loading = false;
        this.$emit('load', this.Loading);
      })
      .catch(error => {
        this.Loading = false;
        this.$emit('load', this.Loading);
        if (!error.response) {
            // network error
            this.showMessage(this.$tc('Contact.PatientContact',2),this.$t('Msg.retrieveInfo'),"error");
        } else {
            this.showMessage(this.$tc('Contact.PatientContact',2),error,"error");
        }
      });
    },

    EditContact(row){      
       this.ContactId = row.recordId;     
       this.Mode = "Edit";
       this.OptionsRightMenu.btnvisible = true;
     
       if(this.$route.name.includes('patient')){   
          this.$router.push({ name: "contact-details", params: { id: this.$route.params.id,contactid: this.ContactId } });
        }
        else{		
          this.$router.push({ name: "Clinical-cont-details", params: { id: this.$route.params.id,contactid: this.ContactId,recordid:this.$route.params.recordid } });       
        }
      
       
       this.$emit('getVisibleMenu', this.OptionsRightMenu);
    },

    RemoveContact(row){
      const RecordId = row.recordId
      this.$bvModal.msgBoxConfirm(this.$t('Msg.DeleteRecord'), {
					title: this.$t('ContactsList.DeleteContact'),
					okTitle: this.$t('Shared.TitleDelete'),
					cancelTitle: this.$t('Shared.BtnCancel'),
					footerClass: 'p-2',
					titleClass: 'modal-title modal-type-warning',
          id:'modal_delete_contact'
				})
        .then(value => {
          if(value){
            ContactService.deleteContact(RecordId)
            .then(() => {
                const index = this.items.findIndex(item => item.recordId === RecordId) // find the post index 
                if (~index) {// if the post exists in array 
                  this.items.splice(index, 1) //delete the post and refresh view
                  this.getContacts(); //refresh listing
                }
            })
            .catch((error) => {
              if (!error.response) {
                // network error
                this.showMessage(this.$tc('Contact.PatientContact',2),this.$t('Msg.NotAbleToDelete'),"error");
              } else {
                this.showMessage(this.$tc('Contact.PatientContact',2),error,"error");
              }
            });
          }
        })
    },

    AddContact(){
       this.Mode = "Add";      
       if(this.$route.name.includes('patient-contacts')){   
          this.$router.push({ name: "patient-contact-add", params: { id: this.$route.params.id} });
        }
        else{		
          this.$router.push({ name: "Clinical-cont-add", params: { id: this.$route.params.id,recordid:this.$route.params.recordid } });       
        }
      
    },

    pageChanged (page) {            
      this.currentPage = page;    
      this.getContacts();     
    },

    PerPageFunction(perpage){
      this.perPage = perpage;  
      this.currentPage = 1;
      this.getContacts();  
    },

    onRowSelected(items) {    
     this.selected = items;
    },

    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
    },

    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },

    selectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.selectRow(2)
    },

    unselectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.unselectRow(2)
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
 showReport(item) {
      // this.filterView = true;
      if (item && item.recordId) {
        this.recNoToReport = item.recordId
      }
      this.isReportVisible = true
    },

    closeParamRepWindow() {
      this.isReportVisible = false
      this.recNoToReport = null
    },
    PopulatePatientTitle(){
        if(this.RegistrationInfo.dateFrom)
            this.RegistrationInfo.dateFrom = moment(this.RegistrationInfo.dateFrom).format('MM/DD/YYYY');
        if(this.RegistrationInfo.dateTo)
            this.RegistrationInfo.dateTo = moment(this.RegistrationInfo.dateTo).format('MM/DD/YYYY');	
      if(this.$route.name.includes('Clinical-cont')){ 
          this.PatientTitle.programName = this.RegistrationInfo.programName;
          this.PatientTitle.dateFrom = this.RegistrationInfo.dateFrom;
          this.PatientTitle.dateTo = this.RegistrationInfo.dateTo;
          this.PatientTitle.fullName = this.RegistrationInfo.patientName;
          this.PatientTitle.dob = moment(this.RegistrationInfo.dob).format('MM/DD/YYYY');
          this.PatientTitle.age = this.RegistrationInfo.age;
          this.PatientTitle.ageFormatted = this.RegistrationInfo.ageFormatted;
      }
      if(this.$route.name.includes('patient-contacts')){         
          this.PatientTitle.programName = '';
          this.PatientTitle.dateFrom = '';
          this.PatientTitle.dateTo = '';
          this.PatientTitle.dob = this.patient.dob;             
          this.PatientTitle.fullName = this.patient.firstname + ' '+ this.patient.lastname;
      }
    }
  },

  async mounted() {   
    this.OptionsRightMenu.visible = true;   
    this.OptionsRightMenu.ItemsList = this.$route.name.includes('Clinical-cont') ? EnumService.ClinicalItems : EnumService.PatientItems;
    this.$emit("getVisibleMenu", this.OptionsRightMenu); 
    this.$emit('load', this.Loading);    
    this.getContacts();    
    this.isReadOnly = this.$store.state.isPatientContactsEHRReadOnly;
    if(this.$route.name.includes('patient-contacts')){  
        this.GetPatientByCode();
     } 

    if(this.$route.name.includes('Clinical-cont')){      
        this.RegistrationInfo = await this.getRegistrationInfoTitle(this.$route.params.recordid);
         this.IsClinical = this.RegistrationInfo.dateTo?false:true;
         if(this.RegistrationInfo.dob)
			this.RegistrationInfo.dob =  moment(this.RegistrationInfo.dob).format('MM/DD/YYYY');

      this.RegistrationInfo.age = this.RegistrationInfo.age === 0 ? "0" : this.RegistrationInfo.age;

    }

    this.PopulatePatientTitle();
  },
  
    destroyed(){
      this.OptionsRightMenu.ItemsList = [];
      this.OptionsRightMenu.Mode = false;
      this.OptionsRightMenu.visible = false; 
      this.$emit('getVisibleMenu', this.OptionsRightMenu);
    }
};

</script>