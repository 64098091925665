
<template>
  <div></div>
</template>

<script>
    import { EventBus } from '@/event-bus.js';
  export default {
    name: 'MdTextSpeech',
    props: {
      
    },
    data() {
      return {
          isSpeechRecognitionSupported: false,
          recognition: null,
          recognizing : false,
          isSpeaking: false,
          dictChars: {
              'en':[
                { value:/&#32;/g , repl:' '},
                { value:/&#33;/g,  repl:'!'},
                { value:/&#34;/g , repl:'"'},
                { value:/&#35;/g , repl:'#'},
                { value:/&#37;/g , repl:'%'},
                { value:/&#44;/g , repl:','},
                { value:/&#45;/g , repl:'-'},
                { value:/&#46;/g , repl:'.'},
                { value:/&#47;/g , repl:'/'},
                { value:/&#39;/g , repl:"'"}
              ],
              'es':[{}
              ]
            }

      }
    },
    methods:{
     
        SpeechRecognition(){
            this.isSpeechRecognitionSupported = 'SpeechRecognition' in window || 'webkitSpeechRecognition' in window;
            if(this.isSpeechRecognitionSupported){
                window.SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
                this.recognition = new window.SpeechRecognition();
            }
        },
        toggleSpeechBar() {
            var speechBar = document.getElementById('speechBar');
            if(speechBar.classList.contains("active")){
                speechBar.classList.remove('active');
            } else {
                speechBar.classList.add('active');
            }
        },
        stopSpeechToText() {
            this.toggleSpeechBar()
            this.recognition.stop();
            this.recognizing = false;
            this.isSpeaking = false;
        },
      
        startSpeechToText(control) {
            // Stop voice reconigtion if is on
            if( this.isSpeaking ) this.stopSpeech();

            if(this.isSpeechRecognitionSupported) {
                this.recognition.lang = this.$root.$options.i18n.locale;
                this.recognition.continuous = true;
                this.recognition.interimResults = true;
                this.recognition.maxAlternatives = 1;
                this.recognition.onend = () => {
                    if(this.recognizing) {
                        this.toggleSpeechBar()
                    }
                    this.recognizing = false;
                    this.isSpeaking = false;
                };
                
                this.recognition.onresult = (event) => {
                    if (typeof event.results === "undefined") {
                        this.recognizing = false;
                        this.recognition.stop();
                        return;
                    }
                    var changed = {
                        "ControlId":control,
                        "finalSentence":''
                    }
                    
                    for (var i = event.resultIndex; i < event.results.length; ++i) {
                        if (event.results[i].isFinal) {
                            changed = {
                                "ControlId":control,
                                "finalSentence":event.results[i][0].transcript
                            }      
                            EventBus.$emit('receivedS2T', changed);
                        }
                    }
                };
                if (!this.recognizing) {
                    this.recognition.start();
                    this.recognizing = true;
                } else {
                    this.recognition.stop();
                    this.recognizing = false;
                    this.isSpeaking = false;
                }
            }
        },
        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        startSpeech(speech) {
            if( speech.length > 0 ) {
                
                // Stop voice reconigtion if is on
                if( this.recognizing ) this.stopSpeechToText();
                
                if (speechSynthesis.speaking) {
                    // SpeechSyn is currently speaking, cancel the current utterance(s)
                    speechSynthesis.cancel();
                } else {
                    // Good to go
                    this.isSpeaking = true;
                    speech = this.speechReplaceSpecialChars(speech)
                    var binding = new SpeechSynthesisUtterance(speech);
                    binding.lang = this.$root.$options.i18n.locale;
                    speechSynthesis.speak(binding);

                    binding.onend = () => {
                        if( this.isSpeaking) this.stopSpeech();
                    }
                }
            } else {
                this.stopSpeech();
            }
        },
        stopSpeech() {
            this.isSpeaking = false;
            this.recognizing = false;
            // Stop voice reconigtion if is on
            this.toggleSpeechBar()
            // SpeechSyn is currently speaking, cancel the current utterance(s)
            speechSynthesis.cancel();
        },
        speechReplaceSpecialChars( speech) {
            let lang =  this.$root.$options.i18n.locale == 'es-ES' ? 'es' : 'en'
            this.dictChars[lang].forEach((item) => {
                speech = speech.replace(item.value, item.repl);
            });
            return speech;
        }
    },

    mounted(){
        if( !this.recognition ) { this.SpeechRecognition(); }
    },
    created() {
        this.$root.$refs.MdTextSpeech = this;
    },
  }
</script>