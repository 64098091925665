<template>
   <div class="cutescroll px-4" :style="style">  
  <div class="row mb-3">
    <b-col md="6">
      <MdSelect
        v-bind:Label="$t('modalEditIntervention.Modalities')"
        data-field="therapistcode"
        v-bind:Options="TherapyList"
        v-bind:FieldDescription="'name'"
        v-bind:FieldCode="'code'"
        v-bind:AllowEmpty="false"
        v-bind:Model="TherapyModel"                       
        v-bind:Multiple="false"  
        :IsDisabled="ReadOnly"   
        :Input="ChangeDropDownTherapy"
        v-bind:Tag="controlId"
      />
    </b-col>      
    </div>
    
    <div class="text-muted" v-if="NoSelectedModaility">
      <p class="text-justify">{{$t('WileyModalityObCN.sProperMoMsg')}}</p>
    </div>
    
    <div v-if="VisibleNoRecords" class="empty-row-banner m-3">
      <div class="text-center my-2">{{$t('WileyModalityObCN.nPromShow')}}</div>
    </div>

    <div class="row">
      <div class="col-12">
        <div v-for="(item, index) in this.$attrs.value.problems" :key="index" class="IncidentContainerBox b4patch">
          
          <div class="pull-right containerBoxTitle ml-5">
            <!-- <b-icon icon="pencil-fill" @click="EditProblem(item)" v-b-tooltip.hover.top="'Edit Problem'" scale=".5" font-scale="1.6" variant="light"></b-icon>-->
            <b-icon icon="slash-circle-fill" v-b-tooltip.hover.top="$t('WileyModalityObCN.vDetail')" v-on:click="ViewProblem(item.problem.probRecId)"
              scale=".5" font-scale="1.6" variant="light"></b-icon>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="grid-margin stretch-card">
                <div class="card activeCard mb-0 pt-3">
                  <div class="card-body">
                    <div v-b-toggle="'probList' + index + ' probListBanner' + index"
                      class="d-flex align-items-start justify-content-between">
                      <h6 class="card-title flex-grow lower-line text-truncate">
                        {{$t('modalEditProblem.Problem')}}: {{ item.problem.probDesc }}
                      </h6>
                    </div>
                    <b-collapse :id="'probListBanner' + index" class="small-info ml-2" visible><small>{{$t('WileyModalityObCN.cMsg')}}</small></b-collapse>
                    <b-collapse :id="'probList' + index">
                      <div class="flex-container">

                        <div class="row">
                          <div class="col-md-12">
                            <div class="text-muted p-item">
                              <MdTitle v-bind:BlankDescription="'Objectives'" v-bind:Text="$t('modalAddObjective.objectives')" v-bind:isSubtitle="true"
                              v-bind:Name="'T_TitleObjectives'" />
                              <div v-for="(g, goalindex) in item.goals" :key="goalindex">                                  
                                    <div v-for="(o, oindex) in g.objectives" :key="oindex">                                           
                                            <fieldset class="customWells">
                                              <legend>{{ o.objective.objDesc }}</legend>
                                            <b-row class="mt-2">
                                            <b-col md="6">
                                              <MdEdit 
                                                v-bind:IsAux="true" v-bind:isReadOnly="ReadOnly"  v-bind:Label="$t('WileyModalityObCN.aDate')" v-bind:Name="'AchievedDate'" v-bind:TypeProp="'text'"                 
                                                v-bind:Mask="'##/##/#### ##:## AA'" v-bind:Min="'1900-01-01'" v-bind:Max="'2200-12-31'" :OnAuxFunc="AuxFunc"            
                                                v-model="o.interventionReviews.dateAchieve" v-bind:Id="'input_AchievedDate_id'+index+'_'+goalindex+'_'+oindex"/>   
                                            </b-col>
                                            <b-col md="6">
                                              <MdSelect 
                                                v-bind:Label="$t('Shared.Status')" :IsDisabled="ReadOnly" v-bind:Options="StatusList" v-bind:FieldDescription="'description'" 
                                                v-bind:FieldCode="'code'" v-bind:Model="o.interventionReviews.statusModel" v-bind:Multiple="false" 
                                                v-bind:UseParamsAux="true" v-bind:UseResetModel="true" v-bind:AuxParam="o.objective.objRecId" :Input="ChangeDropDownStatus"
                                                v-bind:Name="'status_select_'+index+'_'+goalindex+'_'+oindex" :id="'status_select_'+index+'_'+goalindex+'_'+oindex" />
                                            </b-col>
                                          </b-row>
                                            <MdMemo
                                            v-bind:Label="$t('WileyModalityObCN.iReview')"
                                            v-bind:VisibleLabel="true"
                                            v-bind:isReadOnly="ReadOnly"
                                            v-bind:TypeProp="'text'" 
                                            v-bind:Name="'textarea_intreview_'+index+'_'+goalindex+'_'+oindex"
                                            v-bind:Rows="'3'"
                                            v-bind:MaxRows="'6'"                                           
                                            v-bind:IsAux="true"
                                            :OnAuxFunc="AuxFunc"
                                            v-model="o.interventionReviews.interventionNote"
                                        />
                                            </fieldset>
                                        <!--<hr v-if="g.objectives.length - 1 !== oindex">-->
                                    
                                    </div>
                                 
                              
                              </div>

                              <MdTitle v-bind:BlankDescription="'ProgressNotes'" v-bind:Text="$t('WileyModalityObCN.pNotes')" v-bind:isSubtitle="true"
                              v-bind:Name="'T_TitleGoal'" />
                            
                                    <MdMemo
                                        v-bind:Name="'textarea_notes'"
                                        v-bind:Rows="'3'"
                                        v-bind:MaxRows="'6'" 
                                        v-bind:isReadOnly="ReadOnly"                                       
                                        v-model="item.progressReviews.problemReview"
                                        v-bind:IsAux="true"
                                        :OnAuxFunc="AuxFunc"
                                    />
                             

                            </div>
                          </div>
                        </div>


                      </div>
                    </b-collapse>
                  </div>
                </div>
              </div>
            </div>
          </div>



        </div>
      </div>
    </div>


    <Loading v-bind:IsLoading="this.Loading" />
    <modalProblemDetail @ModalTPProblemDetail="ModalTPProblemDetail" v-if="VisibleDetail" v-bind:ModalId="'modalIdProblemDetail'"
      v-bind:TitleWindow="$t('WileyModalityObCN.pDetail')" v-bind:ProblemDetail="ProblemView" />
   </div>
</template>

<script>
import Utils from "@/common/utils";
import ShowMessage from '@/components/messages/ShowMessage.js'
import Loading from '@/components/template/loading/Loading'
import DBFunctions from '@/common/DBFunctions.js'
import TherapyService from '@/services/therapy.service'
import TreatmentPlanService from '@/services/treatmentplan.service'
import { EventBus } from '@/event-bus.js';

//:disabled="ReadOnly || NoteIsVoid || this.$attrs.control.IsChartReview"
//<option v-for="item in options" :key="item" :value="item">{{item}}</option>
export default {
  name: 'MdWileyModalitytoObjectiveCN',
  mixins: [ShowMessage, DBFunctions],
  props: {   
     StepProp:String,    
     isReadOnly:Boolean,   
     Name:String,
     //value:[String, Number],    
     NoteId: String,
     //id: [String, Number]
     AdmissionRecId:String
   },
  components: { 
    Loading
  },
  data(){
    return{
      tempProblems:[],
      NoteIsVoid:false,
      ReadOnly:this.$attrs.control.isReadOnly || this.$attrs.IsChartReview || this.$attrs.NoteIsVoid,
      StatusList: [
                { code: "INPROGRESS", description: this.$t('modalEditGoal.INPROGRESS') },
                { code: "MET", description: this.$t('modalEditGoal.MET') },
                { code: "UNMET", description: this.$t('modalEditGoal.UNMET') },
                { code: "REVISED", description: this.$t('modalEditGoal.REVISED') },
                { code: "EXTENDED", description: this.$t('modalEditGoal.EXTENDED') },
                { code: "DEFERRED", description: this.$t('modalEditGoal.DEFERRED') },
                { code: "REFERRED", description: this.$t('modalEditGoal.REFERRED') }
            ],
      IntReviewModel:"",
      TherapyList:[],
      ProblemView:{},
      Loading:false,
      VisibleDetail:false,
      NoSelectedModaility:true,
      VisibleEditObjectiveWiley:false,
      VisibleNoRecords:false,
      DropDownTherapy:false,
      TreatmentPlan:{
        problems:[],
        signatures:[],
      },
      ProblemRecId: "",
      groupId: 0,
      headerId: "",
      probCode: "",
      selected:"",
      Hint: this.$attrs.control.controlHint,
      controlId:String(this.$attrs.control.controlID),
      TherapyModel:"",
      ProgressNotesModel:"",     
      style:{
        overflowY:'scroll',
        overflowX: 'hidden',
        position:'absolute', 
        padding: '4px 6px',      
        border: 'solid 1px #e0e0e0 !important',
        borderRadius: '5px',
        backgroundImage: 'linear-gradient(130deg, white, rgb(241, 253, 255))',
        top: this.$attrs.control.controlTop+'px',
        width:this.$attrs.control.controlWidth+'px',
        height:this.$attrs.control.controlHeight+'px',
        left:this.$attrs.control.controlLeft+'px',
      
      },
      NotesTherapiesByNoteList:[],
      ObjectiveEdit: {
        objCode: "",
        objRecId: "",
        dateIdentified: null,
        dateExpected: null,
        dateAchieve: null,
        goalRecId:"",
        status: {
          code: "",
          description: ""
        },
        description: "",
      },
    }
  },

  methods:{
    test(){

    },

    ModalTPProblemDetail(){
      this.VisibleDetail = false;
    },
    
    PopulateStatus(){
      this.StatusList.push({ code: "INPROGRESS", description: this.$t('modalEditGoal.INPROGRESS')});
      this.StatusList.push({ code: "MET", description: this.$t('modalEditGoal.MET') });
      this.StatusList.push({ code: "UNMET", description: this.$t('modalEditGoal.UNMET')});
      this.StatusList.push({ code: "REVISED", description: this.$t('modalEditGoal.REVISED')});
      this.StatusList.push({ code: "EXTENDED", description: this.$t('modalEditGoal.EXTENDED')});
      this.StatusList.push({ code: "DEFERRED", description: this.$t('modalEditGoal.DEFERRED')});
      this.StatusList.push({ code: "REFERRED", description: this.$t('modalEditGoal.REFERRED')});
    },

    UpdateStatusAttrs(ObjRecId,status){
      if(this.$attrs.value.problems.length > 0){
        for(var i = 0; i < this.$attrs.value.problems.length; i++){
          for(var j = 0; j < this.$attrs.value.problems[i].goals.length; j++){
            for(var k = 0; k < this.$attrs.value.problems[i].goals[j].objectives.length; k++){
              if(this.$attrs.value.problems[i].goals[j].objectives[k].objective.objRecId === ObjRecId){
                this.$attrs.value.problems[i].goals[j].objectives[k].interventionReviews.statusModel = {code:status,description:status};
                break;
              }
            }
          }
        }
      }      
    },

    ViewProblem(ProbRecId){  
      this.ProblemView = this.$attrs.value.problems.find(element => element.problem.probRecId === ProbRecId);   
      this.VisibleDetail = true; 
    },

    ChangeDropDownStatus(value,objRecId){       
        if(value){
          this.UpdateStatusAttrs(objRecId, value.code);
          this.setChangedStatus();
        }else{
          this.UpdateStatusAttrs(objRecId, null);
        }        
    },

    AuxFunc(){     
      this.setChangedStatus();
    },

    UpdateDataObjWiley(data){     
       let problem = this.TreatmentPlan.problems.find(element => element.problem.probRecId === data.ProblemRecId);
       let goal = problem ? problem.goals.find(element => element.goal.goalRecId === data.goalRecId):[];
       let obj = goal ? goal.objectives.find(element => element.objective.objRecId === data.objRecId):[];
       if(obj){
        obj.objective.dateAchieve = data.dateAchieve;
        obj.objective.status = data.status.code;
       }      
    },

    ModalTPEditWileyObjective(){
      this.VisibleEditObjectiveWiley = false;
    },

    RefreshGetTP(){
      this.VisibleEditObjectiveWiley = false;
    },

    FormatTreatmentPlanDates(arr) {    
     for(var i = 0; i < arr.problems.length; i++){     
      if(arr.problems[i].problem.dateTimeCreate)
        arr.problems[i].problem.dateTimeCreate = Utils.formatterDateToString(arr.problems[i].problem.dateTimeCreate);
      if (arr.problems[i].problem.probDate)
        arr.problems[i].problem.probDate = Utils.formatterDateToString(arr.problems[i].problem.probDate);

        for(var g = 0; g < arr.problems[i].goals.length; g++){
          if (arr.problems[i].goals[g].goal.dateAchieve)
            arr.problems[i].goals[g].goal.dateAchieve = Utils.formatterDateToString(arr.problems[i].goals[g].goal.dateAchieve);
          if (arr.problems[i].goals[g].goal.dateExp)
            arr.problems[i].goals[g].goal.dateExp = Utils.formatterDateToString(arr.problems[i].goals[g].goal.dateExp);
          if (arr.problems[i].goals[g].goal.dateSet)
            arr.problems[i].goals[g].goal.dateSet = Utils.formatterDateToString(arr.problems[i].goals[g].goal.dateSet);
          if (arr.problems[i].goals[g].goal.dateTimeCreate)
          arr.problems[i].goals[g].goal.dateTimeCreate = Utils.formatterDateToString(arr.problems[i].goals[g].goal.dateTimeCreate);

          arr.problems[i].goals[g].goal.statusobj = { code: arr.problems[i].goals[g].goal.status, description: arr.problems[i].goals[g].goal.status };

          for(var j = 0; j < arr.problems[i].goals[g].objectives.length; j++){
            if (arr.problems[i].goals[g].objectives[j].objective.dateAchieve)
              arr.problems[i].goals[g].objectives[j].objective.dateAchieve = Utils.formatterDateToString(arr.problems[i].goals[g].objectives[j].objective.dateAchieve);
            if (arr.problems[i].goals[g].objectives[j].objective.dateExp)
              arr.problems[i].goals[g].objectives[j].objective.dateExp = Utils.formatterDateToString(arr.problems[i].goals[g].objectives[j].objective.dateExp);
            if (arr.problems[i].goals[g].objectives[j].objective.dateSet)
              arr.problems[i].goals[g].objectives[j].objective.dateSet = Utils.formatterDateToString(arr.problems[i].goals[g].objectives[j].objective.dateSet);
            if (arr.problems[i].goals[g].objectives[j].objective.dateTimeCreate)
              arr.problems[i].goals[g].objectives[j].objective.dateTimeCreate = Utils.formatterDateToString(arr.problems[i].goals[g].objectives[j].objective.dateTimeCreate);

          
            if (arr.problems[i].goals[g].objectives[j].interventionReviews.dateAchieve)
                arr.problems[i].goals[g].objectives[j].interventionReviews.dateAchieve = Utils.formatterDateToString(arr.problems[i].goals[g].objectives[j].interventionReviews.dateAchieve);          
           
          }
        }
     }
      this.$attrs.value = arr;
      this.TreatmentPlan = arr;     
    },

    OnChange(){

    },

    OpenModalEditObjective(item,g,o){  
      this.ProblemRecId = item.problem.probRecId;
      this.groupId = item.problem.groupId;
      this.headerId = item.problem.headerId;
      this.probCode = item.problem.probCode;
      this.ObjectiveEdit.goalRecId = g.goal.goalRecId;
      this.ObjectiveEdit.objRecId = o.objective.objRecId;
      this.ObjectiveEdit.objCode = o.objective.objCode;
      this.ObjectiveEdit.dateAchieve = o.objective.dateAchieve;
      this.ObjectiveEdit.status = { code: o.objective.status, description: o.objective.status };
      this.ObjectiveEdit.description = o.objective.objDesc;
      this.VisibleEditObjectiveWiley = true;
    },

    ResetStatusModel(arr){    
     
      arr.problems.forEach(function (prob) {
        prob.goals.forEach(function (goal) {
          goal.objectives.forEach(function (obj) { 
            obj.interventionReviews.statusModel = {};
            obj.interventionReviews.statusModel.code = "";
            obj.interventionReviews.statusModel.description = "";  
            EventBus.$emit('OnResetModelSelect');   
          })
        })
      });   
      return arr;
    },

    ChangeDropDownTherapy(value){   
      this.NoSelectedModaility = false;
      let TCode = value.code;
      this.DropDownTherapy = true;
      EventBus.$emit('OnAddNewTherapy',value);
      if(this.$attrs.State !== 'New'){
        this.$bvModal.msgBoxConfirm('Are you sure you want to change therapy', {
        title: 'Confirm change',
        okTitle: this.$t('Shared.TitleOK'),
        cancelTitle: this.$t('Shared.BtnCancel'),
        footerClass: 'p-2',
        titleClass: 'modal-title modal-type-warning',
        id: 'therapy'
      })
        .then(value => {
          if (value) {           
           this.getProblems(TCode); 
          }else{              
            this.TherapyModel = this.$attrs.value.therapyCode; 
          }
        })
      }else{  
        this.TherapyModel = value.code;
        this.$attrs.value.TherapyCode = this.TherapyModel;    
        this.getProblems(value.code);  
      }    
      
    },

    setChangedStatus(){
      var changed = {
        "ControlId":this.$attrs.control.controlID,
        "Changed":true,
        "NoteId":this.NoteId
      }
      EventBus.$emit('onControlWasChanged', changed);
    },

    async getProblems(TherapyCode) {      
      this.Loading = true;     
      await TreatmentPlanService.getProblemsByAdmIdTherapyHeader(this.AdmissionRecId, "WileyTreatmentPlanNotes", TherapyCode)
        .then((response) => {
          if (response.status == '200' && response.data) {          
            let TPResponse = response.data; 
            this.FormatTreatmentPlanDates(TPResponse);
            this.VisibleNoRecords = TPResponse.problems.length > 0 ? false : true;           
           EventBus.$emit('TPWileyObjectiveFromComponent',this.controlId,TPResponse);
           this.$attrs.value = TPResponse; 
           this.$attrs.value.TherapyCode = TherapyCode; 
           if(this.DropDownTherapy){
            this.$attrs.value = this.ResetStatusModel(this.$attrs.value);      
           }
          }         
         this.Loading = false;
        })
        .catch(error => {         
          this.Loading = false;
          if (!error.response) {
            this.showMessage("Treatment Plan", this.$t('Msg.retrieveInfo'), "error");
          }
          else if (error.response.status === 400) {
            this.showMessage("Treatment Plan", error.response.data.errorMessage, "error");
          } else if (error.response.status === 500) {
            this.showMessage("Treatment Plan", this.$t('Msg.ThereWereSomeError'), "error");
          } else {
            this.showMessage("Treatment Plan", error.response.data.errorMessage, "error");
          }
        });
    },

    async getTherapies() {
            this.$emit('load', true);
            await TherapyService.getTherapyTreatmentPlan(this.AdmissionRecId)
                .then((response) => {
                    if (response.status == '200' && response.data) {
                        this.TherapyList = response.data;   
                    }
                    this.$emit('load', false);
                })
                .catch(error => {
                    this.$emit('load', false);
                    if (!error.response) {
                        this.showMessage("Wiley Component", this.$t('Msg.retrieveInfo'), "error");
                    }
                    else if (error.response.status === 400) {
                        this.showMessage("Wiley Component", error.response.data.errorMessage, "error");
                    } else if (error.response.status === 500) {
                        this.showMessage("Wiley Component", this.$t('Msg.ThereWereSomeError'), "error");
                    } else {
                        this.showMessage("Wiley Component", error.response.data.errorMessage, "error");
                    }
                });
        },

  },

  async created(){
    await this.getTherapies();

    if(this.$attrs.NoteIsVoid){
      this.NoteIsVoid = this.$attrs.NoteIsVoid;
    }

    if(this.$attrs.State !== 'New'){   
      this.NoSelectedModaility = false;
      let tp = this.$attrs.control.treatmentPlan;      
      this.TherapyModel = tp.therapyCode;    
      this.VisibleNoRecords = tp.problems.length > 0 ? false : true;       
      this.FormatTreatmentPlanDates(tp);
    }    
    
    EventBus.$on("onSetReadOnly", function (e) {
      this.NoteIsVoid = e;
      this.ReadOnly = this.$attrs.control.isReadOnly || this.$attrs.IsChartReview || this.NoteIsVoid;
      }.bind(this));
    
  },
  mounted(){
    this.DropDownTherapy = false;   
  },

  beforeDestroy () {     
    EventBus.$off('OnReceivedTherapyByNote');    
  },

   
};
</script>


