import ApiService from '@/services/api.service'

const ProviderService = {
    
    getAll(UserId){      
        return ApiService.get(`/providers?UserId=${UserId}&IncludeTraining=true`); 
    },
    get(id){      
        return ApiService.get(`/providers/${id}`); 
    },


    SelectProvider(code){
        return ApiService.post(`/auth/provider/`,code)
    }

}

export default ProviderService;