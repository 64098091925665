<template>	
    <div id="DivModalViewFile">
    <b-modal id="modalIdViewFileContents" size="xl" scrollable  :title="TitleWindow"
    hide-footer
    > 
    <form ref="form" @submit.stop.prevent="handleSubmit"> 
       <div class="row" style="height:10% !important;">
          <b-col lg="12" md="12" sm="12">
                <MdMemo
                 v-bind:Name="'viewfile'"
                 v-bind:Rows="'29'"
                
                 v-bind:MaxLength="'1500'"
                 v-model="FileContents"
                 v-bind:isReadOnly="true"
               /> 
          </b-col>   
        </div>        
        </form>
        <b-row>
            <b-col md="12">
                <b-button class="mt-4 pull-right btn-login" @click="$bvModal.hide('modalIdViewFileContents')">Close</b-button>         
            </b-col>
        </b-row>
    </b-modal>   
    </div>   
</template>

<script>
import DBFunctions from '@/common/DBFunctions.js'
import { EventBus } from '../../../event-bus.js';
export default {
  name: 'ModalViewFile',
  props: {     
     TitleWindow:String,
   },
    mixins: [DBFunctions,EventBus],
    data() {
		return {
            FileContents:"",
		}
	},

	mounted() {
   // this.getProviders(); 
      /*this.$root.$on('bv::modal::shown', (bvEvent, modalId) => {
      if(modalId == 'modalIdClaimProviderSelector'){
        if(!this.doCall){
          this.getProviders();
          this.doCall = true;
        } 
      }        
      });  */

     EventBus.$on("onFileContents", function (content) {           
         this.FileContents = content;
      }.bind(this));
    },

  created(){ 
   
  },

    beforeDestroy () {     
      EventBus.$off('onFileContents');      
    },

	methods:{


   resetModal(){

   },


    handleOk() {      
       
      },



    emitEventCancel(){
      
      }, 

    

     emitEventOK(){  

       
      },
	},

}


</script>


