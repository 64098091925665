import ApiService from '@/services/api.service'


const AppointmentService = {
    getAll(parameter) {          
      const requestData = {
        method: 'get',
        url: "/Appointments",
        headers: {
        },
        params: { 
          /*SearchCriteria: parameter.SearchCriteria,
          PageSize: parameter.PageSize,          
          PageNumber : parameter.PageNumber,
          NoteType: parameter.NoteType*/
          SearchCriteria: parameter.SearchCriteria,
          PatientCode: parameter.PatientCode,       
          EmployeeCode:parameter.EmployeeCode,
          AppointmentType:parameter.AppointmentType,
          FacilityCode:parameter.FacilityCode,
          ProgramCode:parameter.ProgramCode,
          NoteStatus:parameter.NoteStatus,
          AppointmentStatus:parameter.AppointmentStatus,
          DateFrom:parameter.DateFrom,
          DateTo:parameter.DateTo,

        }
      }     
      return ApiService.customRequest(requestData);         
    },

    getNotesByActId(parameter) {          
      const requestData = {
        method: 'get',
        url: "/Appointments/NotesByActivityId",
        headers: {
        },
        params: {           
          PageSize: parameter.PageSize,          
          PageNumber : parameter.PageNumber,       
          PatientCode: parameter.PatientCode,   
          ActivityId: parameter.ActivityId,
          ActivityTypeCodeList:parameter.ActivityTypeCodeList,
          NoteStatus:parameter.NoteStatus, 
          NoteIdList:parameter.NoteIdList,
          TherapistCode:parameter.TherapistCode,       
          DateFrom:parameter.DateFrom,
          DateTo:parameter.DateTo,
        }
      }     
      return ApiService.customRequest(requestData);         
    },

    getSchedulerNotes(parameter) {          
      const params= {
          PageSize: parameter.PageSize,          
          PageNumber : parameter.PageNumber, 
          PatientCode: parameter.PatientCode,       
          EmployeeCodeList:parameter.EmployeeCodeList,
          AppointmentTypeList:parameter.AppointmentTypeList,
          DateFrom:parameter.DateFrom,
          DateTo:parameter.DateTo,
          OrderBy:parameter.OrderBy
        }
        return ApiService.getWithArray('/Appointments/Notes',params);
    },

    getNotesByApptType(param) { 
      const requestData = {
        method: 'get',
        url: "/Appointments/NotesByAppType",
        headers: {
        },
        params: param
      }     
      return ApiService.customRequest(requestData);         
    },

    getAppointmentList(parameter){
        const params = {
          PageNumber         : parameter.PageNumber,
          PageSize           : parameter.PageSize,
          DateFrom           : parameter.DateFrom,
          DateTo             : parameter.DateTo,
          PatientCodeList    : parameter.PatientCodeList,
          EmployeeCodeList   : parameter.EmployeeCodeList,
          AppointmentTypeList: parameter.AppointmentTypeList,
          Show               : parameter.Show
        }
        return ApiService.getWithArray('/Appointments/FromTable',params); 
      },
  
    get(id) {
      return ApiService.get(`/Appointments/${id}`);
    },

    ShowUp(data){
      return ApiService.put(`/Appointments/ShowUp`, data);
    },

    ConfirmAppt(data){
      return ApiService.put(`/Appointments/Confirm`, data);
    },

    CancelAppt(data){
      return ApiService.put(`/Appointments/Cancel`, data);
    }

 
    
  }
  
  export default AppointmentService;