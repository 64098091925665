<template>

<div class="col-md-12 grid-margin stretch-card">
		<div class="card activeCard">
			<div class="card-body">
				<div v-if="Header" class="d-flex align-items-start justify-content-between pointer">
					<h6 class="card-title flex-grow lower-line text-truncate">{{$t('ENUM.IncItems.incRepGeneralInf')}}</h6>
				</div>
				<div class="float-left col-md-3 text-muted p-item" v-if="this.Header.incidentDate">
					<small>{{$t('Incident.IncDate')}}</small><br>
					<b>{{this.Header.incidentDate}}</b>
				</div>
				<div class="float-left col-md-3 text-muted p-item" v-if="this.Header.incidentTime">
					<small>{{$t('Incident.IncTime')}}</small><br>
					<b>{{getTime(this.Header.incidentTime)}}</b>
				</div>
				<div class="float-left col-md-3 text-muted p-item" v-if="this.Header.reporterName">
					<small>{{$t('Incident.IncReporter')}}</small><br>
					<b>{{this.Header.reporterName}}</b>
				</div>
				<div class="float-left col-md-3 text-muted p-item" v-if="this.Header.reportedDate">
					<small>{{$t('Incident.IncReportedDate')}}</small><br>
					<b>{{this.Header.reportedDate}}</b>
				</div>
				<div class="float-left col-md-3 text-muted p-item" v-if="this.Header.roomNo">
					<small>Rooms</small><br>
					<b>{{getRoomDescription(this.Header.roomNo)}}</b>
				</div>
				<div class="float-left col-md-3 text-muted p-item" v-if="this.Header.location">
					<small>{{$t('Incident.IncLocation')}}</small><br>
					<b>{{getLocationDescription(this.Header.location)}}</b>
				</div>
				<div class="float-left col-md-3 text-muted p-item" v-if="this.Header.addlLocation">
					<small>{{$t('Incident.IncAditLocation')}}</small><br>
					<b>{{this.Header.addlLocation}}</b>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
 import Utils from '@/common/utils'
  export default {
    props: {
		Header: Object,
		RoomList: Array,
		LocationList: Array    
	},

    mixins: [Utils],
    data() {
      return {
      }
    },
    
  methods:{
		getTime(time) {
			return Utils.formatTime(time)
		},

		getRoomDescription(code) {
			return this.RoomList.find(el => el.code === code).roomAndBuildingName
		},

		getLocationDescription(code) {
			return this.LocationList.find(el => el.code === code).description
		}
  },

  mounted() {
  }
}

</script>