import ApiService from '@/services/api.service'


const CalendarResourcesService = {
    getAll() { 
         
      const requestData = {
        method: 'get',
        url: "/CalendarResources/",
        headers: {
        },
      }     
      return ApiService.customRequest(requestData);         
    },
  
    get( id) {
      return ApiService.get(`/CalendarResources/${id}`);
    },

    getByEmployeeType(parameter) {
      var param = {};
      var _Url = '/CalendarResources/';
      param.EmployeeTypeList = parameter.EmployeeTypeList;

      const requestData = {
        method: 'get',
        url: _Url,
        params: param
      }
      return ApiService.customRequest(requestData);
    },

    getAllCalendarResources(parameter){
      const requestData = {
          method: 'get',
          url: "/CalendarResources/",       
          params: {
            SearchCriteria: parameter.SearchCriteria,
            PageSize: parameter.PageSize,
            PageNumber: parameter.PageNumber,  
          }
        }       
        return ApiService.customRequest(requestData);
  }, 


  getAllCustom(parameter){
    const requestData = {
        method: 'get',
        url: "/CalendarResources/",       
        params: parameter
      }       
      return ApiService.customRequest(requestData);
},

  Add(data){
      return ApiService.post(`/CalendarResources`, data);
  },
  Delete(Id){      
      return ApiService.delete(`/CalendarResources/${Id}`);
  },
  Update(Id,data){
      return ApiService.put(`/CalendarResources/${Id}`,data);
  },   
  Get(Id){     
      return ApiService.get(`/CalendarResources/${Id}`);
  },
  getCalendarResourcesByCode(parameter){
    const requestData = {
      method: 'get',
      url: "/CalendarResources/",
      headers: {
      },
      params: {          
        code: parameter.code           
      }
    }     
    return ApiService.customRequest(requestData);       
  },
  }
  
  export default CalendarResourcesService;