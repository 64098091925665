<template>
  <fragment>
    <b-form-checkbox
       :id="Name"                                           
       :name="Name"                                           
       :class="ClassProp"
       :value="CheckedValue"
       :unchecked-value="UncheckedValue"
       v-bind:checked=value
       :disabled="isReadOnly"
       v-on:change="OnChange">
       {{this.Label}}
    </b-form-checkbox>
  </fragment>
</template>

<script>
  import { EventBus } from '@/event-bus.js';
  export default {
    name: 'MdCheckBox',
    props: {
      Label:         String,
      Name:          String,
      ClassProp:     String,
      isReadOnly:    Boolean,
      value:         [Boolean,String],
      CheckedValue:  [Boolean,String],
      UncheckedValue:[Boolean,String],
      Tag:           String,
      ignoreChangedEmit: Boolean
    },
    data() {
      return {
      }
    },

     methods:{
    OnChange(value){
      this.$emit('change', value);
      this.$emit('input', value);
      var changed = {
        "Changed":true,
        "ComponentName":this.Name,
        "Tag": this.Tag
      }
      if (this.ignoreChangedEmit == false)
        EventBus.$emit('onChanged', changed);
    },
  },
  }
</script>