<template>
<div>        
       <div :style="style">    
      <canvas 
        :id="this.$attrs.HeaderId+'_'+this.controlId"       
        class="signature"
        v-on:mousedown="handleMouseDown" 
        v-on:mouseup="handleMouseUp" 
        v-on:mousemove="handleMouseMove" 
        v-on:mouseout="handleMouseOut" 
        v-on:touchstart="touchstart"      
        v-on:touchend="touchend"      
        v-on:touchmove="touchmove"     
        :width="style.width"         
        :height="style.height">        
      </canvas>
    </div>
      <div class="s-header" v-bind:style="styletitle">
            <span class="s-title">{{this.Title}}</span>
            <div class="btn-group btn-group-sm float-sm-right" role="group" aria-label="Signature options">             
              <b-button v-if="!isReadOnly && !NoteIsVoid" size="sm" @click="Clear" class="table-title-btn">{{$t('MdEditTableCN.Clear')}}</b-button>             
            </div>
        </div>
</div>
 
</template>

<script>
import { EventBus } from '../../event-bus.js';
import NotesControlValues from '@/services/notescontrolvalue.service'  
export default {
  name: 'MdImageEditor',
  props: {   value:[String], NoteId: String },
  data(){
    return{
      controlId:this.$attrs.control.controlID,
      Title:'',
      stylebtn:{
        top: this.$attrs.control.controlTop+1+'px',
        position:'absolute',
        left: ((this.$attrs.control.controlLeft+this.$attrs.control.controlWidth)-69)+'px'
       },
       styletitle:{
        position:'absolute',
        width: this.$attrs.control.controlWidth+2+'px',
        top: (this.$attrs.control.controlTop - 6)+'px',
        left: this.$attrs.control.controlLeft+'px'
       },
       style:{
        position:'absolute',
        top: this.$attrs.control.controlTop+16+'px',
        width:this.$attrs.control.controlWidth+'px',
        height:(this.$attrs.control.controlHeight-16)+'px',
        left:this.$attrs.control.controlLeft+'px',
        pointerEvents: ''
      },
      Hint: this.$attrs.control.controlHint,
      dataUrl:"",
      drawing : false,
      mouse : {x: 0, y: 0},
      previous : {x: 0, y: 0},
      points: [],
      pathsry :[],
      NoteIsVoid:false,
      isReadOnly: this.$attrs.control.isReadOnly,
    }
  },
 computed: {
    currentMouse: function () {
      var c = document.getElementById(this.$attrs.HeaderId+'_'+this.controlId);
      var rect = c.getBoundingClientRect();      
      return {
        x: this.mouse.current.x - rect.left,
        y: this.mouse.current.y - rect.top
      }
    }
  },
methods: {

    drawPaths: function () {        
         var canvas = document.getElementById(this.$attrs.HeaderId+'_'+this.controlId);
         var ctx = canvas.getContext("2d"); 
         ctx.clearRect(0,0,canvas.width,canvas.height);         
        this.pathsry.forEach(path=>{
          ctx.beginPath();
          ctx.moveTo(path[0].x,path[0].y);  
          for(let i = 1; i < path.length; i++){
            ctx.lineTo(path[i].x,path[i].y); 
          }
          ctx.stroke();
        })
    },

    Undo(){  
      this.pathsry.splice(-1,1);         
      this.drawPaths();     
    },

    oMousePos(canvas, evt) {
      var ClientRect = canvas.getBoundingClientRect();
        return {
          x: Math.round(evt.clientX - ClientRect.left),
          y: Math.round(evt.clientY - ClientRect.top)
        }
    },

    handleMouseDown: function (event) {      
      var canvas = document.getElementById(this.$attrs.HeaderId+'_'+this.controlId);     
       event.preventDefault()
      this.drawing = true; 
      this.previous = {x:this.mouse.x,y:this.mouse.y};
      this.mouse = this.oMousePos(canvas, event);
      this.points = [];
      this.points.push({x:this.mouse.x,y:this.mouse.y});
    },

    touchstart: function(event){
      var canvas = document.getElementById(this.$attrs.HeaderId+'_'+this.controlId);
       event.preventDefault();
      //mousePos = getTouchPos(canvas, event);
      var touch = event.touches[0];
      var mouseEvent = new MouseEvent("mousedown", {
          clientX: touch.clientX,
          clientY: touch.clientY
      });

       canvas.dispatchEvent(mouseEvent);
    },

    touchend:function(event){
      var canvas = document.getElementById(this.$attrs.HeaderId+'_'+this.controlId);
       event.preventDefault()
       var mouseEvent = new MouseEvent("mouseup", {});
       canvas.dispatchEvent(mouseEvent);
    },

    touchmove: function(event){
      var canvas = document.getElementById(this.$attrs.HeaderId+'_'+this.controlId);
       event.preventDefault()
       var touch = event.touches[0];
      var mouseEvent = new MouseEvent("mousemove", {
        clientX: touch.clientX,
        clientY: touch.clientY
      });
    canvas.dispatchEvent(mouseEvent);
    },

    handleMouseUp: function (event) {   
      this.drawing = false;      
      this.pathsry.push(this.points);
      this.Save();
      event.preventDefault();
    },

    handleMouseOut: function(event){
       event.preventDefault()
      this.drawing = false;
    },

    handleMouseMove: function (event) {   
      var canvas = document.getElementById(this.$attrs.HeaderId+'_'+this.controlId);
      var ctx = canvas.getContext("2d");
       event.preventDefault()
      if(!this.drawing)
        return;
     
        ctx.lineWidth = 3;
        this.previous = {x:this.mouse.x,y:this.mouse.y};
        this.mouse = this.oMousePos(canvas, event);
        this.points.push({x:this.mouse.x,y:this.mouse.y});
        ctx.beginPath();
        ctx.moveTo(this.previous.x,this.previous.y);
        ctx.lineTo(this.mouse.x,this.mouse.y);
        ctx.stroke();
     
    },

    InitializeVariables(){
        this.Title = this.$attrs.control.controlCaption;     
        this.Extension = this.$attrs.control.extension;
        this.Extension = this.Extension?this.Extension.slice(1):'';
        this.style.pointerEvents = this.$attrs.control.isReadOnly || this.NoteIsVoid ? 'none' : ''
       },

    Save(){	
        var canvas = document.getElementById(this.$attrs.HeaderId+'_'+this.controlId);
        var image = canvas.toDataURL();      
        var objectdata = {
        "ControlId":this.$attrs.control.controlID,
        "base64":image,
         "Extension":".png",
          "Type":"TIssImageEditor"
      };
       EventBus.$emit('onSignatureDone', objectdata);
       
       this.setChangedStatus()
    },

    getImage() {  
      this.$emit('load', true);      
      NotesControlValues.getImage(this.controlId)
            .then((response) => {      
               if (response.data) { 
                  var canvas = document.getElementById(this.$attrs.HeaderId+'_'+this.controlId);
                  var ctx = canvas.getContext('2d');
                  ctx.clearRect(0, 0, canvas.width, canvas.height);
                  var w = canvas.width;
                  canvas.width = 1;
                  canvas.width = w;              
                  let Image = response.data.imageContents;
                  this.dataUrl = "data:image/bmp;base64,"+Image;              
                  this.canvasImage(this.dataUrl);
                  var objectdata = {
                    "ControlId":this.$attrs.control.controlID,
                    "base64":this.dataUrl,
                    "Extension":".bmp"          
                  };    
                  EventBus.$emit('onSignatureDone', objectdata);       
                  this.setChangedStatus();
                 // this.$emit('load', false); 
               }  
         })
         .catch(error => {
        // this.$emit('load', false);         
                if (!error.response) {
                      this.showMessage('Notes Get Image',this.$t('Msg.NotAbleToUpdate'),"error");
                }     
                else if(error.response.status === 400 ){
                    this.showMessage('Notes Get Image',error.response.data.errorMessage,"error");
                }   
                else if(error.response.status === 500){
                    this.showMessage('Notes Get Image',this.$t('Msg.ThereWereSomeError'),"error");
                } else{
                    this.showMessage('Notes Get Image',error.response.data.errorMessage,"error");
                }   
         });         
      },
    
		Clear(){	
      this.getImage();		
      /*var canvas = document.getElementById(this.$attrs.HeaderId+'_'+this.controlId);
      var ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      var w = canvas.width;
      canvas.width = 1;
      canvas.width = w;
      this.canvasImage(this.dataUrl);
      this.setChangedStatus()*/
		},

      InitializeModelCanvas(){      
        if(this.dataUrl){ 
          var objectdata = {
              "ControlId":this.$attrs.control.controlID,
              "base64":this.dataUrl,
              "Extension":".bmp"
          };    
          EventBus.$emit('onSignatureDone', objectdata);                      
        }        
    },

    canvasImage: function(base64){
         var x = document.getElementById(this.$attrs.HeaderId+'_'+this.controlId);
          var canvas = x.getContext('2d'); 
          var image = new Image();
          image.src = base64;
            image.addEventListener("load",function(){
              canvas.drawImage(image,0,0);            
            },false);
          },

    setChangedStatus(){
      var changed = {
          "ControlId":this.$attrs.control.controlID,
          "Changed":true,
          "NoteId":this.NoteId
      }
      EventBus.$emit('onControlWasChanged', changed);
    }
  },

  created(){
    EventBus.$on("onSetReadOnly", function (e) {
      this.NoteIsVoid = e;
      }.bind(this));

    if(this.$attrs.NoteIsVoid){
      this.NoteIsVoid = this.$attrs.NoteIsVoid;
    }
    this.InitializeVariables();
  },

	mounted(){  
    if(this.value){
       this.dataUrl = "data:image/bmp;base64,"+this.value;      
      }else{
        if(this.$attrs.control.extension){
          this.dataUrl = "data:image/"+this.$attrs.control.extension.slice(1)+";base64,"+this.$attrs.control.imageContents;
        }else{
           this.dataUrl = "data:image/bmp;base64,"+this.$attrs.control.imageContents;
        }
        
      }
      this.canvasImage(this.dataUrl);
      this.InitializeModelCanvas();
	}
};
</script>

<style>
.signature {
  border: solid 1px #e0e0e0;
  border-radius: 0 0 5px 5px;
  background-image: linear-gradient(130deg, white, rgb(241, 253, 255));
  background-origin: border-box;
  background-clip: content-box, border-box;
}
.s-header{
  background-color: #c5c5c5;
  padding: 2px 2px 0 15px;
  border: 0;
  border-radius: 5px 5px 0 0;
  text-align: left;
}
.s-title{
  color: #FFF;
  text-transform: capitalize;
  overflow-x: hidden;
  cursor: default;
}
.signature + div button{
  background: #e6e3e3 !important;
  padding: 0px 10px !important;
  color: #999 !important;
  text-transform: capitalize;
  transition: all 0.3s ease-out;
  border: none;
}
.signature + div button:hover{
  background-color: #007EA8 !important;
  color: #FFF !important;
}
</style>
