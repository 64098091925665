<template>
    <div id="ContainerMedications">
      <div class="row">
        <div class="col-12">
          <h4 class="page-title text-truncate">
                     {{ $tc('Shared.Patient') }} {{ $t('Medication.Medications') }} - 
          <span v-if="this.RegistrationInfo.patientName">{{this.RegistrationInfo.patientName+' - '}}</span>
          <span span v-if="this.$route.params.id">{{this.$route.params.id + ' - '}}</span>
          <span v-if="this.RegistrationInfo.dob"> {{ $t('Shared.Dob') }} {{this.RegistrationInfo.dob}}</span>
          <span v-if="this.RegistrationInfo.ageFormatted"> {{ $t('Shared.Age') }} {{this.RegistrationInfo.ageFormatted}}</span>
                  <span v-if="this.RegistrationInfo.programName"> - {{this.RegistrationInfo.programName}}  </span>
                  <span v-if="this.RegistrationInfo.dateFrom">- {{this.RegistrationInfo.dateFrom}}  </span>
                  <span v-if="this.RegistrationInfo.dateTo">- {{this.RegistrationInfo.dateTo}}  </span>
                  <span v-else>- {{ $t('Shared.Active') }}  </span>
                  </h4>
          <div class="row">
            <div class="col-sm-12 col-md-3">                      
              <MdButton
                v-bind:Label="$t('Medication.Add')"                 
                v-bind:ClassProp="this.isReadOnly ? 'btn btn-primary my-3 btn-block' : 'btn btn-primary my-3 btn-block btn-login'"
                v-bind:IconClass="'ti-plus pr-1'"
                v-on:click="AddMedication"
                v-bind:isReadOnly="this.isReadOnly"
                v-bind:Name="'btn_add_medication'">
              </MdButton>
            </div>
            <div class="col-sm-12 col-md-3">  
                <MdSelect
                  v-bind:Label="$t('Medication.RxStatus')"
                  v-bind:Options="RxStatusList"
                  v-bind:FieldDescription="'name'"
                  v-bind:FieldCode="'value'"
                  v-bind:Model="ModelRxStatus"
                  v-bind:Multiple="false"              
                  :Input="ChangeDropDownRxStatus"                 
                  v-bind:Name="'rxstatus_select'"
                  id="rxtatus_select"
                  />
              </div>
            <div class="col-sm-12 col-md-6 mb-4">
              <div class="textlabel" :data-label="this.$t('Shared.SearchElements')">
                <div class="input-group">
                  <input 
                    type="text" 
                    class="form-control"
                    v-model="parameter.SearchCriteria"   
                    @keyup.enter="SearchMedications">
  
                  <div class="input-group-append">
                    <MdButton 
                      v-bind:Name="'btnsearchinput'"
                      v-bind:VariantProp="'btn btn-sm search-attached'"
                      v-bind:IconClass="'mdi mdi-magnify'"
                      v-on:click="SearchMedications">
                    </MdButton>
                  </div>
                </div>
              </div>
            </div>
          </div>              
         
        </div>
      </div>
      <div class="row">
        <div class="col-12 mt-2">
          <MdTitle v-bind:Text="$t('Shared.SearchResult')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
          <div class="row">
            <div class="col-md-12">
              <MdTable 
                v-bind:fields="fields"
                v-bind:items="items"
                v-bind:totalRows="totalRows"
                v-bind:isReadOnly="isReadOnly"
                v-bind:showMessageIsEmpty="ShowMessageIsEmpty"
                v-bind:currentPage="currentPage"
                v-bind:perPage="perPage"
                v-bind:pageOptions="pageOptions"
                :editIconEvent="EditMedication"
                v-bind:VisibleEditIcon="true"
                v-bind:FieldsDate="FieldsDateArr"
                :deleteIconEvent="RemoveMedication"
                v-bind:VisibleDeleteIcon="true"
                :onRowSelected="onRowSelected"
                :onFiltered="onFiltered"
                :pageChanged="pageChanged"
                :perPageFunction="PerPageFunction"
              >
              </MdTable>
            </div>
          </div>
        </div>
      </div>
      <div class="space-actions"></div>
      <!--<div class="page-actions">
        <MdButton
          v-bind:Label="$t('Shared.Report')"
          v-bind:Name="'btnpatientlist'"
          v-bind:ClassProp="'secondary mx-2 btn-login'"
          v-on:click="showReport()"/>
      </div>
  
     -->
    </div>
  </template>
  
  <script>
  import MedicationService from '@/services/medication.service'
  import EnumService from '@/services/enum.service' 
  //import PatientService from '@/services/patient.service'
  import ShowMessage from '@/components/messages/ShowMessage.js'
  import DBFunctions from '@/common/DBFunctions.js'
  import Utils from "@/common/utils";
  import moment from "moment";
  
  export default {
  
    beforeRouteEnter(to, from, next) {
          next(async vm => {
              const iPI = await vm.CanView();
              if(iPI) next()
              else next('/accessdenied');
          }) 
    },
    mixins: [ShowMessage, DBFunctions],
    name: 'MedicationsList',
    data() {
      return {
        patient:{},
        FieldsDateArr:['transDate'],
        ShowMessageIsEmpty:false,
        Loading:true,
        RegistrationInfo:{},
        PatientInfo:{},
        id:"",
        permission:[],
        ModelRxStatus: {value:'ActiveDuration',name: this.$t('MedisList.ActiveDuration')},
        RxStatusList:EnumService.ClinicalMedicatiosRxStatus,
        Menu : this.$store.state.leftMenu,
        OptionsRightMenu:{
          visible:true,
          ItemsList:[],
          btnvisible:true,
          itemdisabled: false,
          PatientCode:''         
        },      
        Mode:'',         
        parameter:{
          SearchCriteria:'',
          PageNumber : 0,
          PageSize:0 ,
          PatientCode:''             
        },
        fields: [
          { key: 'issuedDate', sortable: true, label: this.$t('MedisList.IssuedDate'), class: 'text-rigth' },    
          { key: 'drugName', sortable: true, label:this.$t('Medication.DrugName'), class: 'text-rigth' },       
          { key: 'sig', sortable: true, label: this.$t('MedisList.Sig'), class: 'text-rigth'},       
          { key: 'quantity', sortable: true, label: 'QTY', class: 'text-rigth' },      
          { key: 'prescriber', sortable: true, label: this.$t('Medication.Prescriber'), class: 'text-rigth' },
          { key: 'rxStatus', sortable: true, label: this.$t('Medication.RxStatus'), class: 'text-rigth' }, 
          { key: 'internalStatus', sortable: true, label:this.$t('Shared.Status'), class: 'text-rigth' }, 
          { key: 'stopDate', sortable: true, label:this.$t('Medication.StopDate'), class: 'text-rigth' },
          { key: 'dateTimeExpired',label:this.$t('MedisList.TimeExpired'), sortable: true}, 
          { key: 'recordId', sortable: true, label: 'RecordId', class: 'hidden' },
          { key: 'drupType', sortable: true, label: this.$t('MedisList.DrupType'), class: 'text-rigth' },
          
        ],
        pageOptions: [5, 10, 15 ],
        totalRows: 0,
        currentPage: 1,
        perPage: 10,
        filter: null,
        filterOn: [],
        items: [],
        selected: [],
        Header:{}, 
        isReadOnly: false,       
      }
    },
  
    computed: {
      sortOptions() {       
        return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          });
      }    
    },
      
    methods: {
      SearchMedications(){
        this.Loading = true;
        this.$emit('load', this.Loading);
        this.items = [];
        this.currentPage = 1;
        this.parameter.PageNumber = 1;
        this.totalRows = this.items.length;
        this.getMedications();
      },
  
      CanView(){
        return this.permission[0].roleCanView;
    },

    ChangeDropDownRxStatus(v){
        this.ModelRxStatus = v !== null ? v : {value:"All",name:this.$t('Shared.All')};
        this.getMedications();
    },
  

  
      EditMedication(row){       
         this.id = row.recordId.trim();        
         this.Mode = "Edit";
         this.$router.push({ name: "medications-details", params: { recId: this.id } });
      },
  
      RemoveMedication(/*row*/){
        //const recNo = row.recNo
        this.$bvModal.msgBoxConfirm(this.$t('Msg.DeleteRecord'), {
          title: this.$t('Medication.Medications'),
          okTitle: this.$t('Shared.TitleDelete'),
          cancelTitle: this.$t('Shared.BtnCancel'),
          footerClass: 'p-2',
          titleClass: 'modal-title modal-type-warning',
          id:'modal_delete_vitals'
        })
        .then(value => {
          if(value) {
           /* VitalSignService.deleteVital(recNo)
              .then(() => {
                  const index = this.items.findIndex(item => item.recNo === recNo) // find the post index 
                  if (~index) {// if the post exists in array 
                    this.items.splice(index, 1) //delete the post and refresh view
                    this.getVitalSign(); //refresh listing
                  }
              })
              .catch((error) => {
                if (!error.response) {            
                this.showMessage(this.$t('Medication.Medications'),this.$t('Msg.RetrieveInformation'),"error");
              } else {
                this.showMessage(this.$t('Medication.Medications'),error,"error");
              }
              });*/
          }
        })
      },
  
      AddMedication(){    
         this.Mode = "Add";
         this.$router.push({ name: "medications-add", params: { id: this.$route.params.id } });
      },
  
      pageChanged (page) {            
        this.currentPage = page;    
        this.getMedications();     
      },
  
      PerPageFunction(perpage){
        this.perPage = perpage;  
        this.currentPage = 1;
        this.getMedications();  
      },

      async getMedications() {
        this.items = [];       
        var param = {
            PatientCode:this.$route.params.id,
            Status:this.ModelRxStatus.value,
            PageNumber:this.currentPage,
            PageSize:this.perPage,
            SearchCriteria:this.parameter.SearchCriteria ? this.parameter.SearchCriteria : ""
        }
      await MedicationService.getAll(param)
        .then((response) => {
          if (response.status == "200" && response.data && response.data.length > 0) {
            this.items = response.data;   
            console.log(this.items);       
            this.totalRows =  this.items[0].maxRows;  
            this.items.forEach(function(item) {
              if (item.issuedDate)	
                  item.issuedDate = Utils.formatterDateToString(item.issuedDate);
            });      
          }
          if(this.items.length === 0){
            this.ShowMessageIsEmpty = true;
          }
          this.Loading = false;
          this.$emit('load', this.Loading);
        })
        .catch((error) => {
          this.Loading = false;
          this.$emit('load', this.Loading);
          if (!error.response) {            
              this.showMessage(this.$t('Medication.Medications'),this.$t('Msg.RetrieveInformation'),"error");
          } else {
              this.showMessage(this.$t('Medication.Medications'),error,"error");
          }
        });
    },
  
      onRowSelected(items) {    
       this.selected = items;
      },
  
      selectAllRows() {
        this.$refs.selectableTable.selectAllRows()
      },
  
      clearSelected() {
        this.$refs.selectableTable.clearSelected()
      },
  
      selectThirdRow() {
        this.$refs.selectableTable.selectRow(2)
      },
  
      unselectThirdRow() {
        this.$refs.selectableTable.unselectRow(2)
      },
  
      onFiltered(filteredItems) {
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },
  
    },
  
   async mounted() {
      this.$emit('load', this.Loading);    
      this.getMedications();
      this.OptionsRightMenu.visible = true;
      this.OptionsRightMenu.ItemsList = EnumService.ClinicalItems
      this.$emit('getVisibleMenu', this.OptionsRightMenu);
      this.$emit('getBtnRightMenu', this.OptionsRightMenu);
      this.isReadOnly = this.$store.state.isVitalSignEHRReadOnly; 
      this.RegistrationInfo = await this.getRegistrationInfoTitle(this.$route.params.recordid);
      if(this.RegistrationInfo.dateFrom)
              this.RegistrationInfo.dateFrom = moment(this.RegistrationInfo.dateFrom).format('MM/DD/YYYY');
          if(this.RegistrationInfo.dateTo)
              this.RegistrationInfo.dateTo = moment(this.RegistrationInfo.dateTo).format('MM/DD/YYYY');	
  
      if(this.RegistrationInfo.dob)
              this.RegistrationInfo.dob =  moment(this.RegistrationInfo.dob).format('MM/DD/YYYY');
  
        this.RegistrationInfo.age = this.RegistrationInfo.age === 0 ? "0" : this.RegistrationInfo.age;
    },
  
    created(){
      this.getPermissionLeftMenu(this.Menu,this.$route.path,this.permission);
    },
    
      destroyed(){
        this.OptionsRightMenu.ItemsList = [];
        this.OptionsRightMenu.Mode = false;
        this.OptionsRightMenu.visible = false; 
        this.$emit('getVisibleMenu', this.OptionsRightMenu);
      }
  };
  
  </script>