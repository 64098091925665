<template>
  <div id="CPTLIST">
   <h4 class="page-title text-truncate">
         {{ $t('CptHcpcsRevenueList.Title') }}                 
    </h4>
    <div class="row">
              <div class="col-sm-12 col-md-3"  v-if="permission.length > 0 && permission[0].roleCanAdd">                      
                    <MdButton
                      v-bind:Label="$t('Shared.Add')" 
                      v-bind:ClassProp="'btn btn-primary my-3 btn-block btn-login'"
                      v-bind:IconClass="'ti-plus pr-1'"
                      v-on:click="AddCPT"                   
                      v-bind:Name="'btn_add_cpt'">
                    </MdButton>
                </div>
                <div v-bind:class="permission.length > 0 && permission[0].roleCanAdd ? 'col-sm-12 col-md-6 mb-4':'col-sm-12 col-md-12 mb-4'">
                    <div class="textlabel" :data-label="this.$t('Shared.SearchElements')">
                        <div class="input-group">
                            <input 
                              type="text" 
                              class="form-control"
                              v-model="parameter.SearchCriteria"   
                              @keyup.enter="SearchCPT"                            
                            >
                            <div class="input-group-append">
                                <div class="input-group-append">
                                <MdButton 
                                  v-bind:Name="'btnsearchinput'"
                                  v-bind:VariantProp="'btn btn-sm search-attached'"
                                  v-bind:IconClass="'mdi mdi-magnify'"
                                  v-on:click="SearchCPT">
                                </MdButton>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-3">                      
                  <MdSelect
                  v-bind:Label="$t('Shared.Type')"
                  v-bind:Options="TypeList"
                  v-bind:ModelArray="typeModel"
                  v-bind:FieldDescription="'text'"
                  v-bind:FieldCode="'value'"                      
                  :Input="ChangeType"/>
              </div>
            </div> 

      <div class="row">
        <div class="col-12 mt-2">
        <MdTitle v-bind:Text="$t('Shared.SearchResult')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
          <div class="row">
            <div class="col-md-12">
              <MdTable 
                v-bind:fields="fields"
                v-bind:items="items"
                v-bind:totalRows="totalRows"
                v-bind:isReadOnly="false"
                v-bind:showMessageIsEmpty="ShowMessageIsEmpty"
                v-bind:currentPage="currentPage"
                v-bind:perPage="perPage"
                v-bind:pageOptions="pageOptions"
                :editIconEvent="EditCPT"
                v-bind:VisibleEditIcon="true"
                :deleteIconEvent="RemoveCPT"
                v-bind:VisibleDeleteIcon="true"
                :onRowSelected="onRowSelected"
                :onFiltered="onFiltered"
                :pageChanged="pageChanged"
                :perPageFunction="PerPageFunction">
              </MdTable>
            </div>
          </div>
        </div>
      </div>
        
  </div>
</template>

<script>
import DBFunctions from '@/common/DBFunctions.js'
import ShowMessage from '@/components/messages/ShowMessage.js'
import CPTService from '@/services/cpt.service'

export default {
    beforeRouteEnter(to, from, next) {
        next(async vm => {
            const iPI = await vm.CanView();
            if(iPI) next()
            else next('/accessdenied');
        }) 
  },
  name: 'CPTLIST',
  mixins: [ShowMessage, DBFunctions],
  data(){
    return{
      permission: [],
      Menu : this.$store.state.leftMenu,
      parameter:{
        SearchCriteria:'',
        PageNumber : 0,
        PageSize:0, 
        Type:''        
      },
       OptionsRightMenu:{
          visible:false,
          ItemsList:[],
          btnvisible:false,
          itemdisabled: false,
          PatientCode:'',
          Mode:this.edit_mode,
          favButtonAtion:false          
        },
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      ShowMessageIsEmpty:false,
      pageOptions: [5, 10, 15 ],
      items:[],
      typeModel:{text:this.$t('Shared.All'),value:'All'},
      TypeList:[
        {text:this.$t('Shared.All'),value:'All'},
        {text:this.$t('Shared.CPT'),value:'CPT'},
        {text:this.$t('Shared.HCPCS'),value:'HCPCS'},
        {text:this.$t('cpt.Revenue'),value:'Revenue'},
        {text:this.$t('cpt.UserDefined'),value:'UserDefined'},
      ],
      fields: [
        { key: 'description', sortable: true, label: this.$t('Shared.Description'), class: 'text-rigth' },      
        { key: 'code', sortable: true, label: this.$t('Shared.Code'), class: 'text-rigth'},       
        { key: 'type', sortable: true, label: this.$t('Shared.Type'), class: 'text-rigth' },        
        ],
    }
  },

  methods:{

    ChangeType(type){
      this.typeModel = type;
      this.getCPT();
    },

    AddCPT(){
       this.emitDataforFavorites(); 
       this.$router.push({ name: "cpt-add", params: { id: this.$route.params.id } }).catch(()=>{});
    },

    CanView(){
      return this.permission[0].roleCanView;
  },


    SearchCPT(){
      this.items = []
      this.currentPage = 1;
      this.parameter.PageNumber = 1;
      this.totalRows = this.items.length;
      this.getCPT();
    },

    getCPT(){
      this.$emit('load', true);
      this.parameter.PageNumber = this.currentPage;           
      this.parameter.PageSize = this.perPage;      
      this.parameter.Type = this.typeModel ? this.typeModel.value:'';     
      CPTService.getAll(this.parameter)
        .then((response) => {
            if(response.status == '200' && response.data){
              this.items = response.data;                        
              this.$emit('load', false);
              let headerString = response.headers["x-pagination"];
              this.totalRows = JSON.parse(headerString).TotalCount;
              }               
        })
        .catch((error) => {                    
            if (!error.response) {             
              this.showMessage(this.$t('Shared.CPT'),this.$t('Msg.retrieveInfo'),"error");
          }                      
            else if(error.response.status === 400 ){
              this.showMessage(this.$t('Shared.CPT'),error.response.data.errorMessage,"error");
            }
            else if(error.response.status === 405 ){             
                  this.$router.push({name: "Main"});    
                    setTimeout(() => 
                        this.showPermissionMessage()
                        , 50);            
            } 
            else if(error.response.status === 500){
              this.showMessage(this.$t('Shared.CPT'),this.$t('Msg.ThereWereSomeError'),"error");
            } else{
              this.showMessage(this.$t('Shared.CPT'),error.response.data.errorMessage,"error");
            } 
            this.$emit('load', false);            
        }); 
    },

    EditCPT(row){ 
       this.$router.push({ name: "cpt-details", params: { id: row.code } });    
    },

    RemoveCPT(row){
       const Id = row.code;
           this.$bvModal.msgBoxConfirm(this.$t('Msg.DeleteRecord'), {
					title: this.$tc('Shared.DelRecord'),
					okTitle: this.$t('Shared.TitleDelete'),
					cancelTitle: this.$t('Shared.BtnCancel'),
					footerClass: 'p-2',
					titleClass: 'modal-title modal-type-warning',
          id: 'modal_delete_cpt'
				})
          .then(value => {
            if(value){
              this.$emit('load', true);  
               CPTService.DeleteCPT(Id)
                .then(() => {
                  const index = this.items.findIndex(item => item.code === Id) 
                  if (~index) {
                    this.items.splice(index, 1) 
                    this.getCPT(); 
                  }
                })
                .catch((error) => {
                  this.$emit('load', false);  
                    if(error.response.status === 400 ){
                     this.showMessage(this.$t('Shared.CPT'),error.response.data.errorMessage,"error");
                     }else{
                        this.showMessage(this.$t('Shared.CPT'),error,"error");
                     }  
                  /*if (!error.response) {
                    // network error
                    this.showMessage("Delete Admission",this.$t('Msg.NotAbleToDelete'),"error");
                  } else {
                    this.showMessage("Delete Admission",error,"error");
                  }*/
                });
            }
          })
    },

    onRowSelected(){

    },
    onFiltered(){

    },
    pageChanged(page){
      this.currentPage = page;    
      this.getCPT();  
    },
    PerPageFunction(perpage){
      this.perPage = perpage;  
      this.currentPage = 1;
      this.getCPT();  
    }
  },
  
   created(){
     this.getPermissionLeftMenu(this.Menu,this.$route.path,this.permission);
  },

  mounted(){
        this.OptionsRightMenu.ItemsList = [];
       let layoutStatus = this.$store.state.layoutStatus;
       switch(layoutStatus) {
        case 'L0R1':
          this.OptionsRightMenu.favButtonAtion = false;
          this.$emit('getVisibleMenu', this.OptionsRightMenu);
        break;
        case 'L1R0':
        case 'L1R1':
            this.OptionsRightMenu.btnvisible = false;
            this.$emit('getVisibleMenu', this.OptionsRightMenu);
        break;
        default:
            this.OptionsRightMenu.favButtonAtion = false;
            this.$emit('getVisibleMenu', this.OptionsRightMenu);
      }
    this.getCPT();
  },

   destroyed(){
      this.OptionsRightMenu.ItemsList = [];
      this.OptionsRightMenu.Mode = false;
      this.OptionsRightMenu.visible = false; 
      this.$emit('getVisibleMenu', this.OptionsRightMenu);
    }
   
};
</script>

