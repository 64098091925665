<template>
   <div>
    <b-modal :id="modalId" size="xl" centered scrollable  :title="TitleWindow" @ok="this.emitEventOK" @cancel="this.emitEventCancel" @hide="resetModal"> 
      <b-row>      
          <b-col lg="3" md="3" sm="12">
                  <MdEdit 
                  v-bind:Label="$t('insuranceSetup.Name-Dept')"
                  v-bind:TypeProp="'text'"
                  v-bind:MaxLengthProp="50" 
                  v-bind:Name="'namedept'"
                  v-model="contacts.namedept"
                  v-bind:isReadOnly="isReadOnly"
                  v-bind:Id="'input_insu_Name-Dept'"
                  />     
               </b-col>
                 <b-col lg="3" md="3" sm="12">
                  <MdEdit 
                  v-bind:Label="$t('Shared.Phone')"
                  v-bind:TypeProp="'text'"
                  v-bind:MaxLengthProp="50" 
                  v-bind:Name="'phone'"
                  v-model="contacts.phone"
                  v-bind:isReadOnly="isReadOnly"
                  v-bind:Id="'input_insu_phone'"
                  />     
               </b-col>
                 <b-col lg="3" md="3" sm="12">
                  <MdEdit 
                  v-bind:Label="$t('Patient.LegalFax')"
                  v-bind:TypeProp="'text'"
                  v-bind:MaxLengthProp="50" 
                  v-bind:Name="'fax'"
                  v-model="contacts.fax"
                  v-bind:isReadOnly="isReadOnly"
                  v-bind:Id="'input_insu_legF'"
                  />     
               </b-col>
                 <b-col lg="3" md="3" sm="12">
                  <MdEdit 
                  v-bind:Label="$t('Shared.Email')"
                  v-bind:TypeProp="'email'"
                  v-bind:MaxLengthProp="50" 
                  v-bind:Name="'email'"
                  v-model="contacts.email"
                  v-bind:isReadOnly="isReadOnly"
                  v-bind:Id="'input_insu_Em'"
                  />     
               </b-col>
      </b-row>
      <b-row>
         <b-col lg="3" md="3" sm="12">
                  <MdEdit 
                  v-bind:Label="$t('insuranceSetup.WebSite')"
                  v-bind:TypeProp="'text'"
                  v-bind:MaxLengthProp="50" 
                  v-bind:Name="'website'"
                  v-model="contacts.website"
                  v-bind:isReadOnly="isReadOnly"
                  v-bind:Id="'input_insu_webs'"
                  />     
               </b-col>
                 <b-col lg="3" md="3" sm="12">
                  <MdEdit 
                  v-bind:Label="$t('Shared.Notes')"
                  v-bind:TypeProp="'text'"
                  v-bind:MaxLengthProp="50" 
                  v-bind:Name="'notes'"
                  v-model="contacts.notes"
                  v-bind:isReadOnly="isReadOnly"
                  v-bind:Id="'input_insu_notes'"
                  />     
               </b-col>
      </b-row>
    </b-modal>
   </div>
</template>

<script>

export default {
  name: 'ModalInsuranceConacts',
   props: {
     ModalId: String,
     TitleWindow:String
   },
   data(){
       return {  
         modalId:this.ModalId, 
          TherapiesList:[],
          Vmodeltherapies:"",
          isReadOnly:false,
          contacts:{}
       }
   },

   methods:{

    emitEventOK(){

    },
  
    emitEventCancel(){

    },

    resetModal(){

    },

    ChangeDropDownTherapies(){
        
    }
   
   },

   mounted(){
 
      this.$root.$on('bv::modal::show', () => {     
        this.$nextTick(() => {
          setTimeout(500);
        });
      });
   }
   
};
</script>