import ApiService from '@/services/api.service'

const UserTplService = {

    getAll(parameter){
        const requestData = {
            method: 'get',
            url: "/UserRoleHeaders/",
            headers: {
            },
            params: { 
              SearchCriteria: parameter.SearchCriteria,
              PageSize: parameter.PageSize,          
              PageNumber : parameter.PageNumber            
            }
          }     
          return ApiService.customRequest(requestData);       
    },

    getTplByCode(parameter){
      const requestData = {
        method: 'get',
        url: "/UserRoleHeaders/",
        headers: {
        },
        params: {          
          code: parameter.code           
        }
      }     
      return ApiService.customRequest(requestData);       
    },

    get(id) {
      return ApiService.get(`/UserRoleHeaders/${id}`);
    },

    delete(id){
      return ApiService.delete(`/UserRoleHeaders/${id}`)
    },

    add(data){
      return ApiService.post(`/UserRoleHeaders`,data)
    },

    update(id,data){
      return ApiService.put(`/UserRoleHeaders/${id}`,data)
    },

    addPermision(data){
      return ApiService.post(`/UserRoleDetails`,data)
    },

    updatePermision(id,data){
      return ApiService.put(`/UserRoleDetails/${id}`,data)
    },
    getUserByCode(id){
      //return ApiService.get(`/Users/${id}`);     
      const requestData = {
        method: 'get',
        url: "/Users/",
        headers: {
        },
        params: {          
          Code: id        
        }
      }     
      return ApiService.customRequest(requestData);        
    },


    getDataSource(type,code){
      if (code)
        return ApiService.get(`/UserRoleDetails/Menu?Device=WEB&Module=${type}&RoleCode=${code}&Operation=EditingRole`);
      return ApiService.get(`/UserRoleDetails/Menu?Device=WEB&Module=${type}&Operation=CreatingRole`);
    },
    getProviders(id){
      if (id) {
        return ApiService.get(`/UserRoleHeaders/${id}`); 
      } else {
        return ApiService.get(`/UserRoleHeaders/Providers`); }
    },
    getProvidersTypes(){
      return ApiService.get(`/Providers/Types`);
    }
}

export default UserTplService;