import ApiService from '@/services/api.service'

const AsamService = {

    getListingAsam(parameter){
        
        const requestData = {
          method: 'get',
          url: "/ASAMPatientAssessments/",
          headers: {
            
          },
          params: {
            SearchCriteria: parameter.SearchCriteria,
            PageSize: parameter.PageSize,
            PageNumber: parameter.PageNumber,
            PatientCode:parameter.PatientCode,
            RecordId:parameter.RecordId
          }
        }
        return ApiService.customRequest(requestData);
    },
    getAsamSetup(){
        return ApiService.get(`/ASAMSetups/`);
    },
    getAsamAssessmentTypes(){
     return ApiService.get(`/AsamAssessmentTypes/`);
    },
    AsamAssessment(data){
        return ApiService.post(`/ASAMGateway/assessment/?PatientCode=${data.PatientCode}&AssessmentType=${data.AssesmentType}&RecordId=${data.RecordId}`);
    },
    EditAssessmentSSO(data){
      return ApiService.post(`/ASAMGateway/assessmentsso/?PatientId=${data.PatientId}&AssessmentType=${data.AssessmentType}&AssessmentId=${data.AssessmentId}`);
    },
    getReport(data){
        return ApiService.post(`/ASAMGateway/assessmentreport/?PatientId=${data.PatientId}&AssessmentType=${data.AssessmentType}&AssessmentId=${data.AssessmentId}`);
    },
    CreatePatient(PatientCode){
      return ApiService.post(`/ASAMGateway/patient/?PatientCode=${PatientCode}`);
  }
    
    

}

export default AsamService;