<template>
    <div id="ContainerContact">
       <div class="row page-height">
          <div class="col-12">
      
       <h4 v-if="this.Mode" class="page-title text-truncate">
        {{ $t('PatientDocument.EditDocument') }} -
         <span v-if="this.PatientTitle.fullName">{{this.PatientTitle.fullName + ' - '}}</span>
         <span v-if="this.$route.params.id">{{this.$route.params.id}}</span>
         <span v-if="this.PatientTitle.dob"> - {{ $t('Shared.Dob') }} {{this.PatientTitle.dob}}</span>
         <span v-if="this.PatientTitle.ageFormatted"> {{ $t('Shared.Age') }} {{this.PatientTitle.ageFormatted}}</span>
         <span v-if="this.PatientTitle.programName"> - {{this.PatientTitle.programName}}  </span>
         <span v-if="this.PatientTitle.dateFrom">- {{this.PatientTitle.dateFrom}}  </span>
         <span v-if="this.PatientTitle.dateTo">- {{this.PatientTitle.dateTo}}  </span>
         <span v-else v-show="IsClinical">- {{ $t('Shared.Active') }}  </span>      
       </h4>
       <h4 v-if="!this.Mode" class="page-title text-truncate">
        {{ $t('PatientDocument.AddDocument') }} -
         <span v-if="this.PatientTitle.fullName">{{this.PatientTitle.fullName + ' - '}}</span>
         <span v-if="this.$route.params.id">{{this.$route.params.id}}</span>
         <span v-if="this.PatientTitle.dob"> - {{ $t('Shared.Dob') }} {{this.PatientTitle.dob}}</span>
         <span v-if="this.PatientTitle.ageFormatted"> {{ $t('Shared.Age') }} {{this.PatientTitle.ageFormatted}}</span>
         <span v-if="this.PatientTitle.programName"> - {{this.PatientTitle.programName}}  </span>
         <span v-if="this.PatientTitle.dateFrom">- {{this.PatientTitle.dateFrom}}  </span>
         <span v-if="this.PatientTitle.dateTo">- {{this.PatientTitle.dateTo}}  </span>
         <span v-else v-show="IsClinical">- {{ $t('Shared.Active') }}  </span>    
       </h4>            
             <div class="row"> 
                <div class="col-md-3">
                    <MdSelect
                        v-bind:Label="$t('PatientDocument.DocumentType')"
                        v-bind:FieldDescription="'description'"
                        v-bind:FieldCode="'code'"
                        v-bind:Options="OptionDocumentTypes"
                        v-bind:Model="this.DocumentTypesVModel"	
                        v-bind:isRequired="true" 
                        v-bind:isValid="isDocumentTypeValid"
                        v-bind:Multiple="false"
                        :Input="ChangeDropDownDocumentTypes"
                        id="document_typesDetail_select"                    
                      />
                </div>             
                <b-col class="col-md-3">
                    <MdEdit 
                        v-bind:Label="$t('PatientDocument.ExpirationDate')"
                        v-bind:TypeProp="'date'" 
                        v-bind:Name="'expirationDate'"
                        v-model="ExpirationDate"
                        v-bind:Min="'1900-01-01'"
                        v-bind:Max="'2200-12-31'"
                        v-on:blur="OnExpirationDateBlur"
                        @input="ExpirationDateChange"
                        v-bind:Id="'input_expirationdate'">                                     
                    </MdEdit>
                </b-col>   
                <div class="col-md-6">
                    <MdEdit 
                       v-bind:Label="$t('PatientDocument.Comments')"
                       v-bind:TypeProp="'text'" 
                       v-bind:Name="'comments'"                      
                       v-model="document.comments"
                       @input="CommentsChange"
                       v-bind:isReadOnly="this.isReadOnly"
                       v-bind:Id="'comments'">                                     
                    </MdEdit>
                </div>
                <!--<b-col md="3" lg="3" sm="12">
                    <MdButton v-if="this.files.length > 1"
                    v-bind:Label="'Apply All Files'"
                    v-bind:Name="'btnapplyallfiles'"
                    v-bind:ClassProp="'secondary btn-login mt-3'"
                    v-on:click="ApplyAllFiles"/>
                </b-col> -->               
             </div>

             <LoadDocument 
                v-bind:Records="this.files" 
                v-bind:ModeEdit="this.$attrs.editMode"
                v-bind:DocType="this.DocumentTypesVModel.description" 
                v-bind:DocCode="this.DocumentTypesVModel.code"
                v-bind:UserId="UserId"
                v-bind:DocumentTypeList="OptionDocumentTypes"
                @UpdateFiles="OnUpdateDocumentFile($event)" 
                @FileChanged="OnFileChanged($event)" 
                />
             

                <div style="height:55px;"></div>
             
             <div class="page-actions">
                <MdButton
                   v-bind:Label="$t('Shared.Back')"
                   v-bind:Name="'btndocumentlist'"
                   v-bind:ClassProp="'secondary mx-2 btn-login'"
                   v-on:click="DiscardChanges"/>
             
                <MdButton
                   v-bind:Label="$t('Shared.Save')"
                   v-bind:ClassProp="(this.isReadOnly)?'primary mx-2':'primary btn-login mx-2'"
                   v-on:click="ClickSaveCall"
                   v-bind:isReadOnly="this.isReadOnly"
                   v-bind:Name="'btn_save_document'"/>
             </div>
          </div>
       </div>
    </div>
</template>
 
 <script>
 
 import EnumService from '@/services/enum.service' 
 //import ContactService from '@/services/contact.service'
 //import GlobalService from '@/services/global.service' 
 import Utils from '@/common/utils'
 import DocumentType from '@/services/documenttype.service'
 import DocumentManagement from '@/services/documentmanagement.service'
 import ShowMessage from '@/components/messages/ShowMessage.js'
 import DBFunctions from '@/common/DBFunctions.js'
 import { EventBus } from '@/event-bus.js';
 import LocalStorage from '@/services/storage.service'
 import moment from 'moment'

let elemSaveBtn = '';
  export default {
 
    beforeRouteEnter(to, from, next) {
        next(async vm => {
            const iUA = await vm.$store.state.isUsePatientDocument;
            if(iUA) next()
            else next('/accessdenied');
        }) 
    },
 
    beforeRouteLeave(to, from, next) {
     if (!this.isReadOnly && this.pendingChanges) {
         this.$bvModal.msgBoxConfirm(this.$t('Msg.LeavingWithoutSaving'), {
           title: this.$tc('PatientDocument.PatientDoc'),
           okTitle: this.$t('Shared.BtnSave'),
           cancelTitle: this.$t('Shared.BtnDismiss'),
           footerClass: 'p-2',
           titleClass: 'modal-title modal-type-warning'
         })
           .then(value => {
             if(!value){
               next();
             } else {
               this.SaveOrEdit();
               next();
             }
           })		
     } else {
         next()
     }
     },
 
    mixins: [ShowMessage, DBFunctions],  
    name: 'PatientDocument',
     data() {
       return { 
         Mode:this.$attrs.editMode,  
         Loading:true,
         ClickSave:false,
         RegistrationInfo:{},
        infoList:[],
        IsClinical:false,
         isDocumentTypeValid:true,
         OptionDocumentTypes:[],
         Comments:"",
        
         files:[],
        
         UserId:LocalStorage.getUserId(),
         ExpirationDate:null,
         DocumentTypesVModel:{code:'',description:''},  
         document:{

         },    
         seqNo:'',
         validateform:true,
         changesPending:false,
        OptionsRightMenu:{
             visible:true,
             ItemsList:[],
             btnvisible:true,
             itemdisabled: false,
             PatientCode:''         
         },
         
      PatientTitle:{      
        programName:'',
        fullName:'',
        admitDate:'',
        dischargeDate:'',
        dob:''
      },   
       isReadOnly: false,
       DocumentListChanged:false,
      // isdocumenttypeValid:true,
       pendingChanges: false,
  
       }
     },
 
 
     methods:{

      OnUpdateDocumentFile(file){   
        this.pendingChanges = true;    
      this.files = file;
      this.checkUserAction();
    },

    OnFileChanged(e){     
      this.DocumentListChanged = e;
      this.changesPending = true;
     // this.setNoteIsEditing(this.Id,true);
    },

        /*ApplyAllFiles(){
          this.files.forEach(element => {
            element.comments = this.document.comments;
            element.expirationDate = this.ExpirationDate;
            element.doccode = this.DocumentTypesVModel.code;
            element.description = this.DocumentTypesVModel.description;          
          });         
          EventBus.$emit('onChangedFiles', this.files);
        },*/

        ChangeDropDownDocumentTypes(t){
          this.DocumentTypesVModel = t ? t : {code:'',description:''}; 

          this.files.forEach(element => {
           // this.$set(element, element.doccode, this.DocumentTypesVModel.code);
           /*if(element.doccode == ""){
            element.doccode = this.DocumentTypesVModel.code;            
           }
           if(element.description == ""){
            element.description = this.DocumentTypesVModel.description;
           }*/
           element.doccode = this.DocumentTypesVModel.code; 
           element.description = this.DocumentTypesVModel.description;   
          });
          
        },

        OnExpirationDateBlur(){

        },

        ExpirationDateChange(){
          /*this.files.forEach(element => {          
            element.expirationDate = this.ExpirationDate;  
          });*/
        },

        CommentsChange(){
          /*this.files.forEach(element => {           
            element.comments = this.document.comments; 
          });*/
        },

        PrepareFiles(){
          this.files.forEach(element => {    
            if(this.ExpirationDate === "" ||  this.ExpirationDate === undefined){
              this.ExpirationDate = null;
            } 
            element.expirationDate = this.ExpirationDate;  
            element.comments = this.document.comments;
          });
        },

        UpdateDocument(){        
        this.$emit('load', true);
        elemSaveBtn.setAttribute("disabled",'');
        this.PrepareFiles();
        var data = {
          request : this.files
        } 
        DocumentManagement.Update(this.seqNo,data)
      .then((response) => {
        if(response.status == '200'){
        this.pendingChanges = false;
         this.$emit('load', false);
         if(this.ClickSave){
              if(this.$route.name === 'patient-document-detail'){
          this.$router.push({
                    name: "patient-document",
                    params: { id: this.$route.params.id },
                });
               }else{
                  this.$router.push({
                     name: "Clinical-document",
                     params: { id: this.$route.params.id,recordid:this.$route.params.recordid },
                  });
               }
               
              }
                
                setTimeout(() => 
                        this.$bvToast.toast(this.$t('PatientDocument.DocumentSavedSuccessfully'), {
                         title: this.$t('PatientDocument.SavingDoc'),
                          autoHideDelay: 5000,
                          variant: "success"
                        })
               , 50); 
                                       
                 
        }
   
      })
      .catch(error => {
        this.$emit('load', false);
        elemSaveBtn.removeAttribute("disabled");
        if (!error.response) {                     
                      this.showMessage(this.$t('PatientDocument.UpdDoc'),this.$t('Msg.retrieveInfo'),"error");
                    }                      
                    else if(error.response.status === 400 ){
                      this.showMessage(this.$t('PatientDocument.UpdDoc'),error.response.data.errorMessage,"error");
                    } else if(error.response.status === 500){
                        this.showMessage(this.$t('PatientDocument.UpdDoc'),this.$t('Msg.ThereWereSomeError'),"error");
                     } else{
                         this.showMessage(this.$t('PatientDocument.UpdDoc'),error.response.data.errorMessage,"error");
                     } 
                    this.$emit('load', false);
      });
    
        },

        InsertDocument(){
          this.$emit('load', true);
          elemSaveBtn.setAttribute("disabled",'');
          this.PrepareFiles();
        var data = {
          request : this.files
        } 
        DocumentManagement.Add(data)
      .then((response) => {
        if(response.status == '200'){
          this.pendingChanges = false;
            this.$emit('load', false);
            if(this.ClickSave){
                if(this.$route.name === 'patient-document-add'){
                  this.$router.push({
                    name: "patient-document",
                    params: { id: this.$route.params.id },
                });
               }else{
                  this.$router.push({
                     name: "Clinical-document",
                     params: { id: this.$route.params.id,recordid:this.$route.params.recordid },
                  });
               }     
              }


                setTimeout(() => 
                        this.$bvToast.toast(this.$t('PatientDocument.DocumentSavedSuccessfully'), {
                         title: this.$t('PatientDocument.SavingDoc'),
                          autoHideDelay: 5000,
                          variant: "success"
                        })
               , 50); 
        }
   
      })
      .catch(error => {
        this.$emit('load', false);
        elemSaveBtn.removeAttribute("disabled");
        if (!error.response) {                     
                      this.showMessage(this.$t('PatientDocument.InsertDoc'),this.$t('Msg.retrieveInfo'),"error");
                    }                      
                    else if(error.response.status === 400 ){
                      this.showMessage(this.$t('PatientDocument.InsertDoc'),error.response.data.errorMessage,"error");
                    } else if(error.response.status === 500){
                        this.showMessage(this.$t('PatientDocument.InsertDoc'),this.$t('Msg.ThereWereSomeError'),"error");
                     } else{
                         this.showMessage(this.$t('PatientDocument.InsertDoc'),error.response.data.errorMessage,"error");
                     } 
                    this.$emit('load', false);
      });
        },

      validate_required() {
         // this.isdocumenttypeValid = this.DocumentTypesVModel.code && this.DocumentTypesVModel.code.length > 0 ? true : false;    
         // return this.isdocumenttypeValid; 
        // var count = 0;
         if(this.files.length > 0){
         /* this.files.forEach(element => {
            if(element.doccode != null && element.doccode != ""){
              count++;
            }
          });

          if(count === this.files.length){
            return true;
          }else{
            this.showMessage(this.$tc('PatientDocument.PatientDoc'), this.$t('PatientDocument.RequiredDocTypeField'), "errorAutoHide");
            return false;
            
          }*/
          this.isDocumentTypeValid = this.DocumentTypesVModel.code && this.DocumentTypesVModel.code.length > 0 ? true : false; 
          if(!this.isDocumentTypeValid){
            this.showMessage(this.$tc('PatientDocument.PatientDoc'), this.$t('PatientDocument.RequiredDocTypeField'), "errorAutoHide");
          } 
          return this.isDocumentTypeValid;

         }else{         
          this.showMessage(this.$tc('PatientDocument.PatientDoc'), this.$t('PatientDocument.MandatorySelectOne'), "errorAutoHide");
          return false;
         }
         
      },

      ClickSaveCall(){
        this.ClickSave = true;
        this.SaveOrEdit();
      },

        SaveOrEdit(){
          if(this.validate_required()) {
            this.Mode ? this.UpdateDocument() : this.InsertDocument();               
         } 

          
        },

        DiscardChanges() {       
         if(this.$route.name === 'patient-document-detail' || this.$route.name === 'patient-document-add'){           
            this.$router.push({
            name: "patient-document",
            params: { id: this.$route.params.id },
         });
         }else{
            this.$router.push({
                  name: "Clinical-document",
                  params: { id: this.$route.params.id,recordid:this.$route.params.recordid },
            });
         }       
      },

      

      getDocument() {         
        if(this.Mode){
        this.$emit('load', true);           
        DocumentManagement.getDocument(this.seqNo)
      .then((response) => {
        if(response.status == '200'){           
        this.document = response.data; 
        this.document.IsNew = false;
        this.document.Image = Utils.getType(this.document.extension);      
        this.files.push(this.document);
        this.DocumentTypesVModel.code =  this.document.doccode;   
        this.DocumentTypesVModel.description =  this.document.description; 
        this.ExpirationDate =  this.document.expirationDate ? moment(this.document.expirationDate).format("YYYY-MM-DD") : null;
        }
        this.$emit('load', false);
   
      })
      .catch(error => {
        if (!error.response) {                      
                      this.showMessage(this.$t('PatientDocument.GetDocuments'),this.$t('Msg.retrieveInfo'),"error");
                    }                      
                    else if(error.response.status === 400 ){
                      this.showMessage(this.$t('PatientDocument.GetDocuments'),error.response.data.errorMessage,"error");
                    } else if(error.response.status === 500){
                        this.showMessage(this.$t('PatientDocument.GetDocuments'),this.$t('Msg.ThereWereSomeError'),"error");
                     } else{
                         this.showMessage(this.$t('PatientDocument.GetDocuments'),error.response.data.errorMessage,"error");
                     } 
                    this.$emit('load', false);
      });
    }
    },

      getDocumentTypes() {  
        DocumentType.getAll(this.parameter)
      .then((response) => {
        if(response.status == '200'){           
         this.OptionDocumentTypes = response.data; 
        }
   
      })
      .catch(error => {
        if (!error.response) {                      
                      this.showMessage(this.$t('PatientDocument.DocumentType'),this.$t('Msg.retrieveInfo'),"error");
                    }                      
                    else if(error.response.status === 400 ){
                      this.showMessage(this.$t('PatientDocument.DocumentType'),error.response.data.errorMessage,"error");
                    } else if(error.response.status === 500){
                        this.showMessage(this.$t('PatientDocument.DocumentType'),this.$t('Msg.ThereWereSomeError'),"error");
                     } else{
                         this.showMessage(this.$t('PatientDocument.DocumentType'),error.response.data.errorMessage,"error");
                     } 
      });
    },

    
  
    async GetPatientByCode(){
      this.infoList = await this.getInfoTitle(this.$route.params.id);
      if(this.infoList.length > 0){
          this.PatientTitle.programName = '';
          this.PatientTitle.admitDate = '';
          this.PatientTitle.dischargeDate = '';
          this.PatientTitle.age = this.infoList[0].age;
          this.PatientTitle.ageFormatted = this.infoList[0].ageFormatted;
          if(this.infoList[0].dob){
              this.PatientTitle.dob = moment(this.infoList[0].dob).format('MM/DD/YYYY');
          }   
          this.PatientTitle.fullName = this.infoList[0].firstname + ' '+ this.infoList[0].lastname;
          this.patient = this.infoList[0];
      }
    },
   
      checkUserAction() {
        if (elemSaveBtn.disabled) {
          elemSaveBtn.removeAttribute('disabled');
        }
      },
      PopulatePatientTitle(){
        if(this.RegistrationInfo.dateFrom)
            this.RegistrationInfo.dateFrom = moment(this.RegistrationInfo.dateFrom).format('MM/DD/YYYY');
        if(this.RegistrationInfo.dateTo)
            this.RegistrationInfo.dateTo = moment(this.RegistrationInfo.dateTo).format('MM/DD/YYYY');	
      if(this.$route.name.includes('Clinical')){ 
          this.PatientTitle.programName = this.RegistrationInfo.programName;
          this.PatientTitle.dateFrom = this.RegistrationInfo.dateFrom;
          this.PatientTitle.dateTo = this.RegistrationInfo.dateTo;
          this.PatientTitle.fullName = this.RegistrationInfo.patientName;
          this.PatientTitle.dob = moment(this.RegistrationInfo.dob).format('MM/DD/YYYY');
          this.PatientTitle.age = this.RegistrationInfo.age;
          this.PatientTitle.ageFormatted = this.RegistrationInfo.ageFormatted;
      }
      if(this.$route.name.includes('patient-document-add') || this.$route.name.includes('patient-document-detail')){         
          this.PatientTitle.programName = '';
          this.PatientTitle.dateFrom = '';
          this.PatientTitle.dateTo = '';
          if(this.infoList.length > 0){
            this.PatientTitle.dob = this.infoList[0].dob;             
            this.PatientTitle.fullName = this.infoList[0].firstname + ' '+ this.infoList[0].lastname;
          }          
      }
    },
     },
 
     async mounted(){
      this.OptionsRightMenu.ItemsList = this.$route.name.includes('Clinical') ? EnumService.ClinicalItems : EnumService.PatientItems;
      this.OptionsRightMenu.visible = true;
      this.$emit("getVisibleMenu", this.OptionsRightMenu);
       this.seqNo =  this.$route.params.seqNo;
       this.getDocumentTypes();      
       this.OptionsRightMenu.Mode = this.Mode;       
    if(this.$route.name.includes('patient-document-add') || this.$route.name.includes('patient-document-detail')){  
        this.GetPatientByCode();
     } 

    if(this.$route.name.includes('Clinical')){      
        this.RegistrationInfo = await this.getRegistrationInfoTitle(this.$route.params.recordid);
         this.IsClinical = this.RegistrationInfo.dateTo?false:true;
         if(this.RegistrationInfo.dob)
			this.RegistrationInfo.dob =  moment(this.RegistrationInfo.dob).format('MM/DD/YYYY');

      this.RegistrationInfo.age = this.RegistrationInfo.age === 0 ? "0" : this.RegistrationInfo.age;

    }

    this.PopulatePatientTitle();

        this.getDocument();
        setTimeout( function() {        
          elemSaveBtn = document.getElementById('btn_save_document');
          elemSaveBtn.setAttribute('disabled','');
      }, 50, this );
     },
 
     created () {
        EventBus.$on("onChanged", function (e) {          
          this.pendingChanges = e.Changed
          this.checkUserAction();
      }.bind(this));
      window.addEventListener('beforeunload', this.beforeWindowUnload)
     },
 
     beforeDestroy () {
       window.removeEventListener('beforeunload', this.beforeWindowUnload)
     },
   } 
 </script>
 
 
 