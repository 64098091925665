<template>
    <div id="PowerBIReportList">
        <div class="row">
            <div class="col-12">
                <MdTitle v-bind:Text= "'Power BI Reports'"/>
                <div class="row">
                    <div class="col-sm-12 col-md-8 mb-4">
                        <div class="textlabel" :data-label="this.$t('Shared.SearchElements')">
                            <div class="input-group">
                                <input 
                                    type="text" 
                                    class="form-control"
                                    v-model="paramReports.SearchCriteria"
                                    @keyup.enter="SearchReports()"
                                >
                                <div class="input-group-append">
                                    <MdButton 
                                        v-bind:Name="'btnsearchinput'"
                                        v-bind:VariantProp="'btn btn-sm search-attached'"
                                        v-bind:IconClass="'mdi mdi-magnify'"
                                        v-on:click="SearchReports">
                                    </MdButton>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-4">                      
                        <MdButton
                        v-bind:Label="$t('Reports')"
                        v-bind:VariantProp="'btn btn-primary'"
                        v-bind:id="'reportBtn'"
                        v-on:click="raiseReportFlag"
                        />
                        <MdButton
                        v-bind:Label="$t('Dashboards')"
                        v-bind:VariantProp="'btn btn-primary'"
                        v-bind:id="'dashBtn'"
                        v-on:click="raiseDashFlag"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div>
                <MdTitle v-bind:Text="$t('Shared.SearchResult')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
                    <MdTable
                    v-bind:fields="fields"
                    v-bind:items="items"
                    v-bind:totalRows="totalRows"
                    v-bind:showMessageIsEmpty="ShowMessageIsEmpty"
                    v-bind:currentPage="currentPage"
                    v-bind:perPage="perPage"
                    v-bind:pageOptions="pageOptions"
                    v-bind:refTable="refTable"
                    :viewIconEvent="GoToReport"
                    :onRowSelected="onRowSelected"
                    :onFiltered="onFiltered"
                    :pageChanged="pageChanged"
                    :perPageFunction="PerPageFunction">
                    </MdTable>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PowerBIService from '@/services/powerbi.service'
import ShowMessage from '@/components/messages/ShowMessage.js'
import DBFunctions from '@/common/DBFunctions.js'

export default {
  mixins: [ShowMessage, DBFunctions],
  name: 'PowerBILayout',
  components: {},
  props: {
    category: String
  },
  data() {
    return { 
      OptionsRightMenu:{
          visible:false,
          ItemsList:[],
          btnvisible:false,
          itemdisabled: false,
          PatientCode:'',
          Mode:this.edit_mode,
          favButtonAtion:false          
      },
      ShowMessageIsEmpty:false,  
      parameter:{
        SearchCriteria:'',
        PageNumber : 0,
        PageSize:0,
      },
      paramReports:{
        SearchCriteria:'',
        PageNumber : 0,
        PageSize:0,
        CategoryCode:''
      },
      fields: [
        { key: 'reportName', sortable: true, label: this.$t('Report Name'), class: 'text-rigth' },
        {key: 'description', sortable: true, label: this.$t('Description'), class: 'text-rigth'}
      ],
      reportFlag: true,
      dashboardFlag: false,
      pageOptions: [5, 10, 15 ],
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      items: [],
      selected: [],
      Header:{},
      refTable: 'selectableTable',
    }
  },

  watch: {
    category(newValue) {
      this.loadCategory(newValue)
    }
  },
 
  methods: {
    pageChanged (page) {            
      this.currentPage = page;
      this.getReports();
    },

    PerPageFunction(perpage){
      this.perPage = perpage;
      this.currentPage = 1;
      this.getReports();
    },

    getReports() {
      this.$emit('load', true)               
      this.paramReports.PageSize = this.perPage;
      this.paramReports.PageNumber = this.currentPage;   
      PowerBIService.getPowerBIReports(this.paramReports.SearchCriteria)
      .then((response) => {
       if(response.status == '200' ){
          console.log(response)
          this.items = response.data;
          this.$emit('load', false);
          let headerString = this.items.length;
          console.log(headerString)
          this.Header.TotalCount = headerString;
          this.totalRows = this.Header.TotalCount;
       }
        this.$emit('load', false);
        if(this.items.length === 0){
          this.ShowMessageIsEmpty = true;
        } 
      })
      .catch(error => {
        this.$emit('load', false);
        if (!error.response) {
            // network error
            console.log(error)
            this.showMessage(this.$t('PowerBILayout'),this.$t('Msg.retrieveInfo'),"error");
        } else {
            this.showMessage(this.$t('PowerBILayout'),error,"error");
        }
      });
    },

    getDashboards() {
      this.$emit('load', true)
      this.paramReports.PageSize = this.perPage;
      this.paramReports.PageNumber = this.currentPage;
      PowerBIService.getPowerBIDashboards(this.paramReports.SearchCriteria)
      .then((response) => {
        if(response.status == '200' ){
          this.items = response.data;
          this.$emit('load', false);
          let headerString = this.items.length;
          console.log(headerString)
          this.Header.TotalCount = headerString;
          this.totalRows = this.Header.TotalCount;
       }
        this.$emit('load', false);
        if(this.items.length === 0){
          this.ShowMessageIsEmpty = true;
        } 
      }).catch(error => {
        this.$emit('load', false);
        if (!error.response) {
            // network error
            console.log(error)
            this.showMessage(this.$t('PowerBILayout'),this.$t('Msg.retrieveInfo'),"error");
        } else {
            this.showMessage(this.$t('PowerBILayout'),error,"error");
        }
      });
    },

    GoToReport(item) {
      console.log(this.reportFlag)
        if(this.reportFlag){
          this.$router.push({name: 'PowerBI-Report', params: {type: "report", id: item.reportId, name: item.reportName}}).catch(()=>{});
        }
        else {
          this.$router.push({name: 'PowerBI-Report', params: {type: "dashboard", id: item.reportId, name: item.reportName}}).catch(()=>{});
        }
    },

    onRowSelected(items) {
      this.selected = items
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

    SearchReports(){
      this.items = []
      this.currentPage = 1;
      this.paramReports.PageNumber = 1;
      console.log(this.paramReports)
      this.totalRows = this.items.length;
      this.getReports();
    },

    findNoteTypeName (array, code) {
      return array.find(element => element.code === code) ?? -1;
    },

    raiseReportFlag(){
      if(this.dashboardFlag == true){
        this.dashboardFlag = false
      }
      this.reportFlag = true
      this.getReports()
    },

    raiseDashFlag() {
      if(this.reportFlag == true){
        this.reportFlag = false
      }
      this.dashboardFlag = true
      this.getDashboards()
    }
  },
  created() {
    this.getReports()
  }
};

</script>