<template>
    <fragment>
              <div :class="this.classValue" :data-label="Label + this.invalidFeedback">                    

                  <div class="input-group">
                      <b-form-input
                          type="text"
                          :id="RefCode"
                          :ref="refCode"
                          v-model="modelDescription"
                          @change="lookupFieldChange($event)"
                          @blur="lookupNameByCode($event.target.value)"
                          v-on:keyup.enter="lookupEnterEvent(refCode, $event.target.value)"
                          :disabled="IsDisabled"
                          :aria-describedby="RefCode"
                          :placeholder="Placeholder"
                          v-on:input="onInput"
                          v-on:dblclick="CallModalTable()">
                      </b-form-input>
                      <input type="hidden" :value="modelCode">
                       <div class="input-group-append">
                          <button
                              :disabled="IsDisabled"
                              class="btn btn-sm search-attached"
                              type="button"
                              v-on:click="CallModalTable()">
                              <i class="mdi mdi-magnify"></i>
                          </button>
                      </div>
                      <!-- <b-form-invalid-feedback`
                          :id="RefCode"
                      >{{ invalidFeedback }}</b-form-invalid-feedback> -->
                  </div>
              </div>
  
          <ModalTablePaginationServerMultiple
              v-bind:Resource="this.ResourceParameter"
              v-bind:TypeOption="this.TypeOption"
              @getCodeConditionOK="onCodeConditionOk($event)"
              @getCodeConditionCancel="onCodeConditionCancel($event)"
              v-bind:Fields="this.Fields"
              v-bind:FieldCode="this.fieldCode"
              v-bind:FieldDescription="this.fieldDescription"
              v-bind:TitleWindow="this.SearchTitle"
              v-bind:ModalId="this.modalId"
              v-bind:Stacked="this.Stacked"
              v-bind:OptionParamList="this.OptionParamList"              
              v-bind:UseFilter="true"
              :FilterChange="ChangeFilter"
              v-bind:modelFilter="this.modelFilter"
              v-bind:AuxFields="this.AuxFields"
              v-bind:FieldsDate="this.FieldsDate"
          ></ModalTablePaginationServerMultiple>
    </fragment>
</template>
  
  <script>
  
  import ModalService from '@/services/modal.service'
  import ShowMessage from "@/components/messages/ShowMessage.js";
  import { EventBus } from '@/event-bus.js';
  export default {
      name: 'MdLookupMultiple',
      mixins: [ShowMessage],
      props: {
          Label: String,
          ModelCode: String,
          ModelDescription: String,
          Placeholder: String,
          FieldDescription: String,
          FieldCode: String,
          IsDisabled: Boolean,              
          RefCode: String,
          Api: String,
          GetValues: Function,
          InvalidFeedback: String,
          SearchTitle: String,
          SearchFieldCode: String,
          Fields: Array,
          AuxFields:Array,
          isValid:Boolean,
          isRequired:Boolean,
          Tag: String,
          Stacked:[Boolean],
          FieldsDate: Array,
          OptionParamList:Array,
          WrittenCodeisValid:Boolean,
          modelFilter:Object       
      },
      computed: {
          Valid: function () {
              return this.message.split('').reverse().join('')
          }
      },
      watch: {
          ModelCode: function(newModel) {
              if (newModel) {
                  this.modelCode = newModel;
              }
          },
  
          ModelDescription: function(newModel) {
            //  if (newModel) {
                  this.modelDescription = newModel;
                
            //  }
          },
  
          isValid: function (newValue) {
              this.setValid(newValue)
          }
      },
      data() {
          return {
              IsShowModal:false,
              modelCode: this.ModelCode,  
              ValidWrittenCode:false,         
              modelDescription: this.ModelDescription,
              fieldCode: this.FieldCode ? this.FieldCode : "code",
              fieldDescription: this.FieldDescription ? this.FieldDescription : "description",
              invalidFeedback: "",
              lookupFieldChanged: false,
              FieldsParameter: [],
              ResourceParameter: "",
              TypeOption: "",
              refCode: this.RefCode ? this.RefCode : "",
              modalId: this.RefCode,
             /* parameter: {
                  Code: '',
                  PatientCode: '',
                  InsuranceCode: '',
                  SearchCriteria: '',
                  PageNumber: 1,
                  PageSize: 1,
              },*/
              parameter: {
                 
              },
              classValue:"textlabel",
          }
      },
  
      methods: {
          lookupFieldChange: function() {
              this.lookupFieldChanged = true;
          },
  
          setValid(value) {
              this.classValue= value ? "textlabel" : "textlabel-invalid"
              this.invalidFeedback = value ? "": ": "+this.$t('Msg.InvalidCode')
          },
  
          lookupNameByCode: function(value) {
              if (this.lookupFieldChanged) {
                  this.getLookup(value);
                  this.lookupFieldChanged = false;
              }
          },
  
          getLookup(value) {
              var result = {};
              if (value.length > 0) {
                  if(this.SearchFieldCode) {
                      this.parameter[this.SearchFieldCode] = value;
                  }
                  else
                      this.parameter.SearchCriteria = value;
                  ModalService.getAll("/" + this.Api, this.parameter)
                      .then((response) => {
                          if (response.status == '200' && response.data && response.data.length > 0) {
                              this.modelCode = response.data[0][this.fieldCode];
                              result.code = this.modelCode;
                              result.FieldName = this.refCode;
                              this.ValidWrittenCode = true;
                              EventBus.$emit('IsCorrectCode', true);             
                              this.modelDescription = response.data[0][this.fieldDescription];
                              result.description = this.modelDescription;
                              if(this.AuxFields) {
                                  result.AuxFields =[]
                                  this.AuxFields.forEach(element => {
                                      result.AuxFields.push(response.data[0][element])
                                  });
                              }
                              if (this.GetValues) {
                                  this.GetValues(result);
                              }
                              this.setValid(true)
                          }
                          else{
                              this.setValid(false)
                               this.ValidWrittenCode = false;
                               EventBus.$emit('IsCorrectCode', false);      
                          } 
                          
                      })
                      .catch(error => {
                          if (!error.response) {
                              // network error
                              this.showMessage(this.SearchTitle, this.$t('Msg.RetrieveInformation'), "error");
                          } else {
                              this.showMessage(this.SearchTitle, error, "error");
                          }
                      });
              } 
              else {
                  this.isRequired ? this.setValid(false) : this.setValid(true)
                  result.code = '';
                  result.FieldName = this.refCode;
                  if (this.GetValues) {
                      this.GetValues(result);
                  }
              }
          },
  
          lookupEnterEvent: function(control, value) {
              this.lookupNameByCode(value);
          },

          ChangeFilter(e){
            console.log(e);
            this.ResourceParameter = "/" + e.resource;
          },

          CallModalTable: function() { 
              this.IsShowModal = true;       
              if (this.refCode) {
                  this.$emit('load', true);
                  this.FieldsParameter = [
                      this.fieldCode,
                      this.fieldDescription
                  ];
                  this.ResourceParameter = "/" + this.Api;
                  this.TypeOption = this.refCode;
                  this.$bvModal.show(this.modalId);
                  this.$emit('load', false);
              }
          },
  
          onCodeConditionOk(result) {
              var type = result.FieldName;
              if (type.indexOf(this.refCode) !== -1 && result.code) {
                  this.modelCode = result.code;
                  this.modelDescription = result.description;
                  this.setValid(true)
                  this.setChangedStatus()
                  if (this.GetValues) {
                      this.GetValues(result);
                  }
              }
              this.ResourceParameter = "";
              this.$bvModal.hide(this.modalId);
          },
  
          onCodeConditionCancel(){
              this.ResourceParameter = "";
          },
  
          onInput(){
            this.setChangedStatus()
          },
  
          setChangedStatus(){
            var changed = {
              "Changed":true,
              "ValidCode":true,
              "ComponentName":this.Label,
              "Tag": this.Tag
            }
            EventBus.$emit('onChanged', changed);
          }
      },
  
      mounted() {
         
      },
      
  }
  
  </script>
  <style src="@/assets/css/clinical/multiselect.css">
  @import "../../../assets/css/flex.css";
  </style>