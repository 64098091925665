<template>
      <div :class="UseColumnDinamic ? Column : 'col-md-4'">
					<div class="card activeCard">
						<div class="card-body">
					<div v-if="isUsePatientAllergies" class="d-flex align-items-start justify-content-between pointer">
							<h6 v-on:click="GoToPatientAllergies" class="card-title flex-grow lower-line text-truncate"><i class="mdi mdi-alert text-warning mr-1"></i>{{ $t('MdPatientAllergies.Allergies') }}</h6>
							<i class="mdi mdi-arrow-right-drop-circle text-corporate" v-on:click="GoToPatientAllergies" ></i>
					</div>
					<div v-if="!isUsePatientAllergies" class="d-flex align-items-start justify-content-between">
							<h6 class="card-title flex-grow lower-line text-truncate"><i class="mdi mdi-alert text-warning"></i>{{ $t('MdPatientAllergies.Allergies') }}</h6>
					</div>
					<div :class="NoUseClass ? 'text-muted cutescroll': 'text-muted p-fixed cutescroll'">
						<p v-if="Allergies.length === 0">{{ $t('MdPatientAllergies.NoKnownAllergies') }}</p>
						<ul>							
							<li v-for="(it, index) in Allergies" :key="`it.allergy-${index}`">{{it.allergy}}</li>						
						</ul>
						</div>
						</div>
					</div>
					</div>
</template>

<script>
import DBFunctions from '@/common/DBFunctions.js'
  export default {
    props: {
    PatientCode: String,
	UseColumnDinamic:Boolean,
	Column:String,
	NoUseClass:Boolean,
	},
    mixins: [DBFunctions],
    data() {
      return {
		Allergies:[],
		isUsePatientAllergies: this.$store.state.isUsePatientAllergies
      }
    },
     
  methods:{
	async getAllergies(){
		this.Allergies = await this.getPatientAllergy(this.PatientCode);
	},

	GoToPatientAllergies(){
		if(this.$route.name.includes('patient')){
			this.$router.push({ name: "patient-allergies", params: { id: this.$route.params.id } }).catch(()=>{});
		}
		if(this.$route.name.includes('Clinical')){
			this.$router.push({ name: "Clinical-PatientAllergies", params: { id: this.$route.params.id,recordid:this.$route.params.recordid } }).catch(()=>{});
		}
    },
  },
  mounted() {
	this.getAllergies();
  }
}

</script>