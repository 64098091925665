<template>
  <fragment>
    <ul class="nav nav-pills nav-pills-custom" id="pills-tab-custom" role="tablist">
        <li class="nav-item" v-for="(tab, index) in TabList" :key="index">
            <a :class="tab.active ? 'nav-link active' : 'nav-link'" 
               data-toggle="pill" :href="tab.link" role="tab"  :aria-selected="tab.active" @click="onClick(index)">
               {{tab.title}}
            </a>
        </li>
    </ul>
    <slot></slot>
    <div class="tab-end" v-if="ShowTabEnd">
      <a v-if="ShowTabList">{{this.$t('MdTabList.TabNumber')}}: {{this.tabNumber}} of {{TabList.length}}</a>
    </div>
  </fragment>
</template>

<script>
  export default {
    name: 'MdTabList',    
    props: {
      TabList: Array,
      ShowTabEnd:Boolean,
      ShowTabList: {
        default: true,
        type: Boolean
      }
    },
    data() {
      return {
        tabNumber: 1,
        
      }
    },
     methods:{
       onClick(index){
         this.tabNumber = index + 1
       }
  },
  }
</script>