<template>
    <div>
      <b-row>
    <b-col md="12">   
    <b-table
    :items="items"
    :fields="fFields"
    responsive
    :per-page="pPage"
    class="medezTables cutescroll"    
    :show-empty="showMessageIsEmpty"
    :current-page="currentPage"   
    @row-clicked="LocalrowClicked" 
    @row-selected="rowSelected"                  
    :tbody-tr-class="tbodyRowClass"
    :id="refTable"
    :sort-by.sync="sortBy"
    :sort-desc.sync="sortDesc"
    :tbody-tr-attr="tbodyRowAttr"
    >

    <template v-if="IsInsurancePrior" #cell(status)="row" class="dateNotes">            
      <div>
        <span v-if="row.item.status === 'Inactive'" class="DocumentExpired">{{row.item.status}}</span>
        <span v-else>Active</span>
      </div>     
   </template>
  
     <template v-if="editIconEvent || deleteIconEvent || viewIconEvent || otherIconEvent && this.items.length > 0" #cell(actions)="row">              
        <b-icon v-if="editIconEvent && isReadOnly" icon="eye-fill" @click="editButton(row.item)" class="rounded-circle tableActionBTN mr-1" scale=".5" font-scale="1.8" variant="light"></b-icon>
        <b-icon v-if="editIconEvent && !isReadOnly" icon="pencil-fill" @click="editButton(row.item)" class="rounded-circle tableActionBTN mr-1" scale=".5" font-scale="1.8" variant="light"></b-icon>
        <b-icon v-if="deleteIconEvent && !isReadOnly"  icon="trash-fill" @click="deleteButton(row.item)" class="rounded-circle tableActionBTN-danger ml-1" scale=".5" font-scale="1.8" variant="light"></b-icon>
        <b-icon v-if="viewIconEvent" icon="play-fill" @click="viewButton(row.item, $event.target)" class="rounded-circle tableActionBTN" scale=".5" font-scale="1.8" variant="light"></b-icon>

        <b-icon v-if="Warning && row.item.warningList.length > 0" id="tWarning" icon="exclamation-triangle-fill" @click="WarningLocal(row.item, $event.target)" class="rounded-circle tableActionBTN ml-1" scale=".5" font-scale="1.8" variant="light"></b-icon>

        <b-icon v-if="Error && row.item.errorList.length > 0" id="tError" icon="wrench" @click="ErrorLocal(row.item, $event.target)" class="rounded-circle tableActionBTN ml-1" scale=".5" font-scale="1.8" variant="light"></b-icon>
        
   

        <b-icon v-if="otherIconEvent" :icon="otherIcon" @click="otherButton(row.item, $event.target)" class="rounded-circle tableActionBTN mr-1" scale=".5" font-scale="1.8" variant="light"></b-icon>                     
     </template>

    </b-table>
        <b-row>
      <b-col v-if="pageOptions" sm="7" md="6" class="my-1">
        <b-pagination 
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="pPage"
          align="fill"
          size="sm"
          class="my-0"
          @change="fpageChanged"
        ></b-pagination>
      </b-col>
      <b-col v-if="pageOptions" align="right" class="mr-2">
          <b-form-group
            >{{ $t('Shared.Show') }}
              <b-form-select
                v-model="pPage"
                id="perPageSelect"
                size="sm"
                :options="pageOptions"
                style="width:auto;"
                @change="pageFunction($event)"
              ></b-form-select>
              of {{totalRows}}
            </b-form-group>
        </b-col>
      </b-row>
        </b-col>
      </b-row>
    </div>
   

  
</template>

<script>

import { EventBus } from '../../../event-bus.js';
export default {
  name: 'MdTablePaginationClient',
   props: {
     fields:Array,    
     items:  Array, 
     perPageFunction: Function,
     perPage: Number,
     pageChanged: Function,   
     totalRows:Number,  
     onRowSelected: Function, 
     editIconEvent: Function,
     deleteIconEvent: Function, 
     ShowDetails:Boolean,
     showMessageIsEmpty:Boolean,
     otherIconEvent: Function,
     otherIcon: String,
     viewIconEvent: Function,
     Error: Function,
     Warning: Function,
     refTable: String,
     RowClick:Function,   
     isReadOnly:Boolean,
     IsInsurancePrior:Boolean,
     VisibleDeleteIcon:Boolean,
     VisibleDownloadIcon:Boolean,
     sortBy: String,
     sortDesc: Boolean,
     tbodyRowAttr: Function
   },
    mixins: [EventBus],
  data(){
    return{  
        pageOptions: [5,10,15,20,30,40,50],
        //perPage: 10,
        pPage: this.perPage,
        itemlocal:this.items,
        cPage:1,
        currentPage: 1, 
         fFields: this.fields,      
    }
  },

  methods: {
    tbodyRowClass(item) { 
      if(item != null){
         if(item.selected){
          return ["b-table-row-selected", "table-active", "cursor-pointer"]
        } else {
          return ["cursor-pointer"]
        }
      }
      },
      
    rowSelected(items) {     
      this.onRowSelected(items);
    },

    LocalrowClicked(item){
      this.RowClick(item);
    },

    viewButton(row) {
      this.viewIconEvent(row);
      EventBus.$emit('shwoHideFavorite', false);
      EventBus.$emit('onUpdatedMenu', '');
    },

    ErrorLocal(row){
      this.Error(row);
    },

    WarningLocal(row){
      this.Warning(row);
    },


     editButton(row) {
        this.editIconEvent(row);
    },

    deleteButton(row) {
        this.deleteIconEvent(row);
    },
    selectRow(index){
      this.onSelectRow(index);
    },
    filtered(filteredItems) {
        this.onFiltered(filteredItems);
    },

    fpageChanged(page) {
        this.pageChanged(page);
    },
     fpageChangedSubItems(page) {
        this.pageChangedSubItems(page);
    },
    pageFunction(perpage) {
        this.perPageFunction(perpage);
    },
    },
  created() { 
     //this.fFields.unshift({ key: 'show_details'}); 
    if(this.editIconEvent || this.deleteIconEvent || this.viewIconEvent || this.otherIconEvent) {     
      if(!this.isReadOnly){
        this.fFields.unshift({ key: 'actions', label: this.$tc('Shared.ActionsTxt',2)});
      }      
    }

    EventBus.$on("rowWasClicked", function (e) { 
      this.tbodyRowClass(e);      
     }.bind(this));
  }
}
</script>

