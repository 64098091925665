<template>
<div>
  <body class="sidebar-fixed">    
      <div class="container-scroller">
      <div class="container-fluid page-body-wrapper full-page-wrapper">
        <div class="content-wrapper d-flex align-items-center auth px-0">
          <div class="row w-100 mx-0">
            <div class="col-lg-4 mx-auto">
              <div class="auth-form-light text-left py-5 px-4 px-sm-5">
                <div class="brand-logo">
                  <img src="../../../assets/images/logo.svg" alt="logo">
                   <a v-on:click="GotoLogin" role="button" class="btn btn-light btn-sm float-right text-truncate mt-2">{{$t('AccountExpired.BtLogin')}}</a>
                </div>
          <noscript>
            <div class="alert alert-fill-warning" role="alert">
                    <i class="mdi mdi-alert-circle"></i>
                    {{$t('Msg.EnableJsMsg')}}
                  </div>
          </noscript>
                
                <div v-if="errorMessage" class="pt-3">
                    <div id="alert" class="alert alert-fill-danger" role="alert">
                      <i class="mdi mdi-alert-circle"></i>
                      {{ errorMessage }}
                    </div>
                </div>
      <Loading v-bind:IsLoading="this.Load" />    
                <div id="ContainerLogin" >
                  <div class="form-group">
                              <div id="OldpassCtrl" class="input-group">
                        <input class="form-control form-control-lg" 
                          ref="olduPass"
                          v-on:focus="onFocus" 
                          :type="showPassword ? 'text' : 'password'"
                          id="olduPass" :placeholder="this.$t('AccountExpired.OldPassword')"
                          autocomplete="true"/>
                        <div class="input-group-append">
                            <div :class="{'cursor-pointer':showPointer}"
                                  @click="showPassword = !showPassword"
                                  @mouseout="showPointer = false"
                                  @mouseover="showPointer = true"
                                  class="input-group-text">
                              <b-icon-eye v-if="! showPassword"/>
                              <b-icon-eye-fill v-if="showPassword"/>
                            </div>
                        </div>
                    </div>
                  </div>
            
                  <div class="form-group">
                           <div id="newpassCtrl" class="input-group">
                        <input class="form-control form-control-lg" 
                          ref="newuPass"
                          v-on:focus="onFocus" 
                          :type="showPassword ? 'text' : 'password'"
                          id="newuPass" placeholder="New Password"
                          autocomplete="true"/>
                        <div class="input-group-append">
                            <div :class="{'cursor-pointer':showPointer}"
                                  @click="showPassword = !showPassword"
                                  @mouseout="showPointer = false"
                                  @mouseover="showPointer = true"
                                  class="input-group-text">
                              <b-icon-eye v-if="! showPassword"/>
                              <b-icon-eye-fill v-if="showPassword"/>
                            </div>
                        </div>
                    </div>
                  </div>
                  <div class="form-group">

                  <div id="passCtrl" class="input-group">
                        <input class="form-control form-control-lg" 
                          ref="uPass"
                          v-on:focus="onFocus" 
                          :type="showPassword ? 'text' : 'password'"
                          id="uPass" :placeholder="$t('ChangePassWord.ConfirmPass')"
                          autocomplete="true"/>
                        <div class="input-group-append">
                            <div :class="{'cursor-pointer':showPointer}"
                                  @click="showPassword = !showPassword"
                                  @mouseout="showPointer = false"
                                  @mouseover="showPointer = true"
                                  class="input-group-text">
                              <b-icon-eye v-if="! showPassword"/>
                              <b-icon-eye-fill v-if="showPassword"/>
                            </div>
                        </div>
                    </div>

                    <!--<input v-on:keyup.enter="onEnter" v-on:focus="onFocus" type="password" ref="uPass" class="form-control form-control-lg" id="uPass" placeholder="Password"></input></input>
-->                  </div>
                  <div class="mt-3">
                    <MdButton 
                       v-bind:Label="$t('AccountExpired.ChangePass')"
                       v-bind:Name="'btnChangePassword'"
                       v-bind:ClassProp="'btn btn-block btn-primary btn-login btn-lg font-weight-medium auth-form-btn text-truncate'"
                       v-on:click="ChangePassword">
                    </MdButton>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>     
      </div> 
  </body>
</div>
</template>


<script>

import Loading from '@/components/template/loading/Loading'
import { BIconEye, BIconEyeFill } from 'bootstrap-vue'
import ShowMessage from '@/components/messages/ShowMessage.js'
import UserService from '@/services/user.service'
import Companies from '@/services/companies.service'
import StorageService from '@/services/storage.service'
import ProviderService from '@/services/provider.service' 

export default {
  name: 'Login',
  mixins: [ShowMessage],
  data() {
    return {
      errorMessage:this.$t('AccountExpired.PassExpiredMsg'),
      IsOk:false,
      Load:false,
      showPassword: false,
      showPointer: false,

      providerList: [],
      CodeSelected:{
        "ProviderId":""
      },
      clientId:'',
      userId:''
    };
  },
  components: {
    BIconEye,
    BIconEyeFill,
    Loading
  },
  
  methods: {

      comparePassword() {
      if(this.$refs.newuPass.value === this.$refs.uPass.value) {
        return true
      }
      return false
      },
      
        ChangePassword(){
        if(this.comparePassword()) {
          const clientId = this.clientId;
          const userId = this.userId;
          const password = this.$refs.olduPass.value;
          const newPassword = this.$refs.newuPass.value;
          if( clientId !== '' && userId !== '' && password !== '' && newPassword !== '' ){
           UserService.changePassword( clientId, userId, password, newPassword)
               .then(async (response) => {
                 if(response.status == '200'){
                      await UserService.login( clientId, userId, newPassword,true); 
                      if (UserService.IsLoged()) {                   
                        this.SaveCompanies();
                        this.getProviders();             
                      }
                 }
               })
                 .catch((error) => {
                   if (!error.response) {
                     this.errorMessage = this.$t('AccountExpired.SerdownMsg');
                   } else {
                      this.errorMessage = error.response.data.message;
                   }
                 });
          }
        } else {
          this.errorMessage = this.$t('AccountExpired.PassNoMatchMsg');
        }
      },

      GotoLogin(){
         this.$router.push('/login'); 
      },
      onFocus(){
      this.errorMessage = ''
    },

    SaveCompanies(){
       Companies.getAll()
                .then((response) => {
                  var data=response.data;
                  if(data && data.length > 0){
                    var companies = {
                    "Country":data[0].country  
                    };
                     this.$store.commit('setCompanies',companies);
                  }
                })
                .catch((error) => {
                    if (error.response) {
                      this.showMessage(this.$t('AccountExpired.ChangePass'),error,"error");
                  }
                });  
    },

    getProviders(){
     const userid = StorageService.getUserId();
      ProviderService.getAll(userid)
      .then((response) => {      
          this.providerList = response.data;
          if (this.providerList && this.providerList.length === 1) {
            // UserService.saveProvider(this.providerList[0].code, this.providerList[0].company);
            this.$store.commit('setProviderCode',this.providerList[0].code);
            this.$store.commit('setProvider',this.providerList[0].company);
            this.CodeSelected.ProviderId = this.providerList[0].code;
            this.SelectProvider();
          }else{
            this.$router.push('/provider');
          }
      })
      .catch(error => {
        if (error.response) {
          this.showMessage(this.$t('AccountExpired.ChangePass'),error,"error");
        }
      });  
    },

    SelectProvider(){      
      ProviderService.SelectProvider(this.CodeSelected)
            .then((response) => {
                if (response.status == '200'){
                   this.$router.push('/app/main');   
                }
            })
            .catch((error) => {
              if (error.response) {
                this.showMessage(this.$t('AccountExpired.ChangePass'),error,"error");
              }
            });
    },
  },
  mounted() {
    this.clientId = this.$route.params.clientId;
    this.userId = this.$route.params.userId;
  }
};
</script>
