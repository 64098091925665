import ApiService from '@/services/api.service'


const MessageNotificationService = {
    
  SendNotificationMessage(data) {
    return ApiService.post(`/MessageNotification/SendNotificationMessage`,data)
  },
}
  
  export default MessageNotificationService;