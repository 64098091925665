import ApiService from '@/services/api.service'


const NotesTitlesService = {
    getAll( parameter) { 
         
      const requestData = {
        method: 'get',
        url: "/NotesTitles/CanInsertDailyNote",
        headers: {
        },
        params: { 
          SearchCriteria: parameter.SearchCriteria,
          PageSize: parameter.PageSize,          
          PageNumber : parameter.PageNumber,
        }
      }     
      return ApiService.customRequest(requestData);         
    },
  
    get( id) {
      return ApiService.get(`/NotesTitles/${id}`);
    },

    addNoteTitle(data){
      return ApiService.post(`/NotesTitles`,data)
    },

    deleteNoteTitle(id){
      return ApiService.delete(`/NotesTitles/${id}`)
    },

    update(Code,NotesTitles){
      return ApiService.put(`/NotesTitles/${Code}`,NotesTitles)
  }
    
  }
  
  export default NotesTitlesService;