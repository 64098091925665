import Companies from '@/services/companies.service'
import ShippingAPIS from '@/services/shippingapis.service'
import Utils from '../common/utils'

var parseString = require('xml2js').parseString;

const ExternalAPI = {

    async getCityStateByZipCode(ZipCode) {
        if (!isNaN(parseInt(ZipCode)) && isFinite(ZipCode)) {
            let useZipCodeApi = 'N';
            let zipCodeApiuser = '';
            let zipCodeApiurl = '';
            let shippingData = [];
            ZipCode = ZipCode.slice(0,5);

            await Companies.getAll() 
                .then((response) => {
                    if(response.data){
                        const company = response.data;
                        useZipCodeApi  = company[0].useZipCodeApi;
                        zipCodeApiurl  = company[0].zipCodeApiurl;
                        zipCodeApiuser = company[0].zipCodeApiuser;
                    }
                    
                })
                .catch(() => {
                   
                });

                if (useZipCodeApi == 'Y') {
                    await ShippingAPIS.getCityStateLookup(ZipCode,zipCodeApiurl,zipCodeApiuser)
                        .then((response) => {
                          const shippingDataXML = response.data;
                          parseString(shippingDataXML, function (err, result) {
                            const shippingDataJSON = result ;
                            if (shippingDataJSON.CityStateLookupResponse.ZipCode[0].City != undefined) {
                              shippingData.City = Utils.strToTitleCase(shippingDataJSON.CityStateLookupResponse.ZipCode[0].City[0]);
                              shippingData.State= shippingDataJSON.CityStateLookupResponse.ZipCode[0].State[0];
                            }
                          })
                        })
                        .catch(() => {
                           
                        });
                }
        return shippingData;
        }
    },
}

export default ExternalAPI;