<template>
   <b-modal size="xl" id="bv-modal-whatsnew" 
      @ok="this.emitEventOK" scrollable centered :title="$t('Layout.QuickHelp')"
      :no-close-on-esc="true" 
      :hide-header-close="true"
      :no-close-on-backdrop="true"
      @shown="onShown"
     >
     
    <Loading v-bind:IsLoading="this.Loading" />
    <div class="whatsnew">
    <b-carousel
      id="carousel-1"
      v-model="slide"
      :interval="0"
      controls
      indicators
      background="linear-gradient(#52EAFF,#0272B7)"
      img-width="1024"
      img-height="480"
      style="text-shadow: 2px 2px 4px #000;"
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
    >
      <!-- WELCOME -->
      <b-carousel-slide img-blank img-alt=""><div>
      <b-img class="img-frame" src="../../../assets/images/whatsnew/welcome.png" fluid-grow></b-img></div><div class="mx-3"><h4>
        {{ Name }}</h4><p class="text-justify">
        {{$t('whatsnew.wScreen.WTxt1')}} <span class="bullet">{{$t('whatsnew.wScreen.WTxt2')}}</span>, {{$t('whatsnew.wScreen.WTxt3')}} <span class="bullet">{{$t('whatsnew.wScreen.WTxt4')}}</span> {{$t('whatsnew.wScreen.WTxt5')}} <span class="bullet">{{$t('whatsnew.wScreen.WTxt6')}}</span> {{$t('whatsnew.wScreen.WTxt7')}}</p>
      </div></b-carousel-slide>
      <!-- MENU TOGGLE -->
      <b-carousel-slide  img-blank img-alt=""><div>
      <b-img class="img-frame" src="../../../assets/images/whatsnew/toggle_menu.png" fluid-grow></b-img></div><div class="mx-3"><h4>
        {{$t('whatsnew.wScreen1.WTxt1')}}</h4><p class="text-justify">
        {{$t('whatsnew.wScreen1.WTxt2')}} <span class="bullet">{{$t('whatsnew.wScreen1.WTxt3')}}</span> {{$t('whatsnew.wScreen1.WTxt4')}} <span class="bullet">{{$t('whatsnew.wScreen1.WTxt5')}}</span> {{$t('whatsnew.wScreen1.WTxt6')}}</p>
      </div></b-carousel-slide>
      <!-- WHERE TO START -->
      <b-carousel-slide  img-blank img-alt=""><div>
      <b-img class="img-frame" src="../../../assets/images/whatsnew/mainmenu.png" fluid-grow></b-img></div><div class="mx-3"><h4>
        {{$t('whatsnew.wScreen2.WTxt1')}}</h4><p class="text-justify">
        {{$t('whatsnew.wScreen2.WTxt2')}} <span class="bullet">{{$t('whatsnew.wScreen2.WTxt3')}}</span>, {{$t('whatsnew.wScreen2.WTxt4')}} <span class="bullet">{{$t('whatsnew.wScreen2.WTxt5')}}</span>, {{$t('whatsnew.wScreen2.WTxt6')}}</p>
      </div></b-carousel-slide>
      <!-- OPTIONS MENU -->
      <b-carousel-slide  img-blank img-alt=""><div>
      <b-img class="img-frame" src="../../../assets/images/whatsnew/optionsmenu.png" fluid-grow></b-img></div><div class="mx-3"><h4>
        {{$t('whatsnew.wScreen3.WTxt1')}}</h4><p class="text-justify">
        {{$t('whatsnew.wScreen3.WTxt2')}} <span class="bullet">{{$t('whatsnew.wScreen3.WTxt3')}}</span> {{$t('whatsnew.wScreen3.WTxt4')}} <span class="bullet">{{$t('whatsnew.wScreen3.WTxt5')}}</span>. {{$t('whatsnew.wScreen3.WTxt6')}} <span class="bullet">{{$t('whatsnew.wScreen3.WTxt7')}}</span> {{$t('whatsnew.wScreen3.WTxt8')}} <span class="bullet">{{$t('whatsnew.wScreen3.WTxt9')}}</span> {{$t('whatsnew.wScreen3.WTxt10')}}</p>
      </div></b-carousel-slide>
      <!-- LISTING -->
      <b-carousel-slide  img-blank img-alt=""><div>
      <b-img class="img-frame" src="../../../assets/images/whatsnew/listing.png" fluid-grow></b-img></div><div class="mx-3"><h4>
        {{$t('whatsnew.wScreen4.WTxt1')}}</h4><p class="text-justify">
        {{$t('whatsnew.wScreen4.WTxt2')}} <span class="bullet">{{$t('whatsnew.wScreen4.WTxt3')}}</span> {{$t('whatsnew.wScreen4.WTxt4')}} <span class="bullet">{{$t('whatsnew.wScreen4.WTxt5')}}</span> {{$t('whatsnew.wScreen4.WTxt6')}} <span class="bullet">{{$t('whatsnew.wScreen4.WTxt7')}}</span> {{$t('whatsnew.wScreen4.WTxt8')}}</p>
      </div></b-carousel-slide>
      <!-- DASHBOARDS -->
      <b-carousel-slide  img-blank img-alt=""><div>
      <b-img class="img-frame" src="../../../assets/images/whatsnew/dashboards.png" fluid-grow></b-img></div><div class="mx-3"><h4>
        {{$t('whatsnew.wScreen5.WTxt1')}}</h4><p class="text-justify">
        {{$t('whatsnew.wScreen5.WTxt2')}} <span class="bullet">{{$t('whatsnew.wScreen5.WTxt3')}}</span> {{$t('whatsnew.wScreen5.WTxt4')}}</p>
      </div></b-carousel-slide>
      <!-- SAVING -->
      <b-carousel-slide  img-blank img-alt=""><div>
      <b-img class="img-frame" src="../../../assets/images/whatsnew/saving.png" fluid-grow></b-img></div><div class="mx-3"><h4>
        {{$t('whatsnew.wScreen6.WTxt1')}}</h4><p class="text-justify">
        {{$t('whatsnew.wScreen6.WTxt2')}} <span class="bullet">{{$t('whatsnew.wScreen6.WTxt3')}}</span> {{$t('whatsnew.wScreen6.WTxt4')}} <span class="bullet">{{$t('whatsnew.wScreen6.WTxt5')}}</span> {{$t('whatsnew.wScreen6.WTxt6')}} <span class="bullet">{{ $t('Shared.Save') }}</span> {{$t('whatsnew.wScreen6.WTxt7')}}</p>
      </div></b-carousel-slide>
      <!-- NOTES PANELS -->
      <b-carousel-slide  img-blank img-alt=""><div>
      <b-img class="img-frame" src="../../../assets/images/whatsnew/notes.png" fluid-grow></b-img></div><div class="mx-3"><h4>
        {{$t('whatsnew.wScreen7.WTxt1')}}</h4><p class="text-justify">
        {{$t('whatsnew.wScreen7.WTxt2')}} <span class="bullet">{{$t('ClinicalNotes.ClinicalNotes')}}</span> {{$t('whatsnew.wScreen7.WTxt3')}} <span class="bullet">{{$t('whatsnew.wScreen7.WTxt4')}}</span> {{$t('whatsnew.wScreen7.WTxt5')}} <span class="bullet">{{$t('whatsnew.wScreen7.WTxt6')}}</span>. {{$t('whatsnew.wScreen7.WTxt7')}}</p>
      </div></b-carousel-slide>
      <!-- NOTES SCROLLING -->
      <b-carousel-slide  img-blank img-alt=""><div>
      <b-img class="img-frame" src="../../../assets/images/whatsnew/notesscrolling.png" fluid-grow></b-img></div><div class="mx-3"><h4>
        {{$t('whatsnew.wScreen8.WTxt1')}}</h4><p class="text-justify">
        {{$t('whatsnew.wScreen8.WTxt2')}} <span class="bullet">{{$t('whatsnew.wScreen8.WTxt3')}}</span> {{$t('whatsnew.wScreen8.WTxt4')}} <span class="bullet">{{$t('whatsnew.wScreen8.WTxt5')}}</span>, {{$t('whatsnew.wScreen8.WTxt6')}}</p>
      </div></b-carousel-slide>
      <!-- NOTES SAVING -->
      <b-carousel-slide  img-blank img-alt=""><div>
      <b-img class="img-frame" src="../../../assets/images/whatsnew/savingnotes.png" fluid-grow></b-img></div><div class="mx-3"><h4>
        {{$t('whatsnew.wScreen9.WTxt1')}}</h4><p class="text-justify">
        {{$t('whatsnew.wScreen9.WTxt2')}} <span class="bullet">{{$tc('Shared.ActionsTxt',2)}}</span> {{$t('whatsnew.wScreen9.WTxt21')}} <span class="bullet">{{$t('Shared.Save')}}</span> {{$t('whatsnew.wScreen9.WTxt4')}} <span class="bullet">{{$t('whatsnew.wScreen9.WTxt5')}}</span>. {{$t('whatsnew.wScreen9.WTxt6')}} <span class="bullet">{{$t('Shared.Close')}}</span> {{$t('whatsnew.wScreen9.WTxt7')}}</p>
      </div></b-carousel-slide>      
      {{$t('whatsnew.wScreen10.WTxt1').toUpperCase()}} 
      <b-carousel-slide  img-blank img-alt=""><div>
      <b-img class="img-frame" src="../../../assets/images/whatsnew/notesattachments.png" fluid-grow></b-img></div><div class="mx-3"><h4>
        {{$t('whatsnew.wScreen10.WTxt1')}}</h4><p class="text-justify">
        {{$t('whatsnew.wScreen10.WTxt2')}} <span class="bullet">{{$t('whatsnew.wScreen10.WTxt3')}}</span> {{$t('whatsnew.wScreen10.WTxt4')}} <span class="bullet">{{$t('whatsnew.wScreen10.WTxt5')}}</span> {{$t('whatsnew.wScreen10.WTxt6')}} <span class="bullet">{{$t('whatsnew.wScreen10.WTxt7')}}</span> {{$t('whatsnew.wScreen10.WTxt8')}} <span class="bullet">{{$t('Shared.Save')}}</span> {{$t('whatsnew.wScreen10.WTxt9')}}</p>
      </div></b-carousel-slide>
      <!-- DATE TIME -->
      <b-carousel-slide  img-blank img-alt=""><div>
      <b-img class="img-frame" src="../../../assets/images/whatsnew/datetime.png" fluid-grow></b-img></div><div class="mx-3"><h4>
        {{$t('whatsnew.wScreen11.WTxt1')}}</h4><p class="text-justify">
        {{$t('whatsnew.wScreen11.WTxt2')}} <span class="bullet">{{$t('whatsnew.wScreen11.WTxt3')}}</span> {{$t('whatsnew.wScreen11.WTxt4')}} <span class="bullet">{{$t('whatsnew.wScreen11.WTxt5')}}</span> {{$t('whatsnew.wScreen11.WTxt6')}} <span class="bullet">{{$t('whatsnew.wScreen11.WTxt7')}}</span> {{$t('whatsnew.wScreen11.WTxt8')}} <span class="bullet">{{$t('whatsnew.wScreen11.WTxt9')}}</span> {{$t('whatsnew.wScreen11.WTxt10')}}</p>
      </div></b-carousel-slide>
      <!-- LOGOUT -->
      <b-carousel-slide  img-blank img-alt=""><div>
      <b-img class="img-frame" src="../../../assets/images/whatsnew/logout.png" fluid-grow></b-img></div><div class="mx-3"><h4>
        {{$t('whatsnew.wScreen12.WTxt1')}}</h4><p class="text-justify">
        {{$t('whatsnew.wScreen12.WTxt2')}} <span class="bullet">{{$t('Layout.Start')}}</span> {{$t('whatsnew.wScreen12.WTxt3')}} <span class="bullet">{{$t('SelectProvider.LogOutTitle')}}</span> {{$t('whatsnew.wScreen12.WTxt4')}}</p>
      </div></b-carousel-slide>
      <!-- REPLAY HELP -->
      <b-carousel-slide  img-blank img-alt=""><div>
      <b-img class="img-frame" src="../../../assets/images/whatsnew/replay.png" fluid-grow></b-img></div><div class="mx-3"><h4>
        {{ Name }}</h4><p class="text-justify">
        {{$t('whatsnew.wScreen13.WTxt1')}}<sup>®</sup> {{$t('whatsnew.wScreen13.WTxt2')}} <span class="bullet">{{$t('Layout.QuickHelp')}}</span> {{$t('whatsnew.wScreen13.WTxt3')}} <span class="bullet">{{$t('Layout.Start')}}</span> {{$t('whatsnew.wScreen13.WTxt4')}}</p>
      </div></b-carousel-slide>

    </b-carousel>
  </div>
    <div slot="modal-footer">
      <b-row>
        <b-col style="align-self: center">
          <b-form-checkbox
          id="checkbox-1"
          v-model="status"
          name="checkbox-1"
          value="true"
          unchecked-value="false"
        >{{$t('whatsnew.dShowAgain')}}</b-form-checkbox>
        </b-col>
        <b-col style="text-align: end;"><b-btn id="whatsnew_btn_close" variant="primary" @click="addUpdQhStatus" >{{$t('Shared.Close')}}</b-btn></b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>

import StorageService from '@/services/storage.service'
import Utils from '../../../common/utils';
import Loading from '@/components/template/loading/Loading'
import Quickhelp from '@/services/quickhelp.service'
import ShowMessage from '@/components/messages/ShowMessage.js'

export default {
  components: { 
    Loading
  },
  name: 'WhatsNew',
  mixins: [ShowMessage],  
  data() {
      return {  
        Loading:false,
        status:false,
        loadstatus:false,
        slide: 0,
        Name:'',
        sliding: null,
        qHelpReferenceList :[],
        quickHelpObj:{
            userId: '',
            doNotShowQuickHelp : false,
         },        
      }
  },
  methods: {
    getName(){
      this.Name = Utils.strToTitleCase(StorageService.getName());
    },

    emitEventOK(evt){
      // Prevent modal from closing    
      evt.preventDefault();
      // Trigger changeStatus handler
      this.changeStatus(evt)
    },

    statusToBoolean: function(string){
      switch(string){
          case "true": case true: return true;
          case "false": case false:  case null: return false;
          default: return Boolean(string);
      }
    },

    addUpdQhStatus() {
      if (this.statusToBoolean(this.status) != this.loadstatus){
        this.getUserQhReference(false);
        if(this.qHelpReferenceList.length == 0) {
          this.addUserQhReference();
        } else {
          this.updUserQhReference();
        }
      }
      this.$bvModal.hide('bv-modal-whatsnew');
    },
 
    getUserQhReference(flag){
      const userid = StorageService.getUserId();
      Quickhelp.getQhUserPreference(userid)
        .then((response) => { 
          if(response.data && response.data.length > 0){
            this.qHelpReferenceList = response.data;
            if(flag) {
                this.status = response.data[0].doNotShowQuickHelp
                this.loadstatus = response.data[0].doNotShowQuickHelp
            }
          }     
        })
        .catch(error => {
          if (error.response) {
            this.showMessage(this.$t('Layout.QuickHelp'),error,"error");
          } 
        });  
    },

    addUserQhReference(){
      this.quickHelpObj.userId = StorageService.getUserId();
      this.quickHelpObj.doNotShowQuickHelp = this.statusToBoolean(this.status);

      Quickhelp.addQhUserPreference(this.quickHelpObj)
        .then((response) => { 
          console.log(response)
        })
        .catch(error => {
          if (error.response) {
            this.showMessage(this.$t('Layout.QuickHelp'),error,"error");
          } 
        });  
    },

    updUserQhReference(){
      const userId = StorageService.getUserId();
      const localQuickHelpObj  = {
        doNotShowQuickHelp :this.statusToBoolean(this.status)
      }

      Quickhelp.updateQhUserPreference(userId, localQuickHelpObj)
        .then((response) => { 
          console.log(response)
        })
        .catch(error => {
          if (error.response) {
            this.showMessage(this.$t('Layout.QuickHelp'),error,"error");
          } 
        });  
    },

    onSlideStart(slide) {
      console.log(slide)
      this.sliding = true
    },
    onSlideEnd(slide) {
      console.log(slide)
      this.sliding = false
    },
    onShown(){
      this.getUserQhReference(true);
    }
  },
  mounted() {
      this.getName();
  }
}
</script>
<style>
#bv-modal-whatsnew___BV_modal_body_{
  padding: 0;
}
.modal-footer {
  padding: 5px 10px !important;
}
.modal-footer>div{
  width: 100%;
}
.whatsnew .carousel-caption{
  right: 7%;
  left: 7%;
  top: 10px;
}
.whatsnew .carousel-control-prev, .whatsnew .carousel-control-next{
  width: 7%;
}
.whatsnew .carousel-control-next, .flashing-arrow{
 animation: fadeinout 1s linear infinite;
}
@keyframes fadeinout {
  0%,100% { opacity: 0; }
  50% { opacity: 1; }
}
.whatsnew .img-frame{
    border: 7px solid #FFF;
    border-radius: 20px;
    margin-bottom: 20px;
}
@media screen and (max-width: 768px) {
  .whatsnew .img-frame{
    margin-bottom: 54px;
  }
}
.whatsnew h4{
  text-transform: capitalize;
  background: linear-gradient(90deg, #00000000, #00000077, #00000000 );
  margin:0;
  padding: 8px 0;
}
.whatsnew p{
  font-size: 1em;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  border-top: 1px solid #FFF;
  padding: 10px 10px 0 10px;
}
.modal-footer .custom-checkbox label{
  padding-top: 3px;
}
.bullet{
  background-color: #00000022;
  padding: 0 6px;
  border-radius: 6px;
}
@media screen and (max-width: 768px) {
  .carousel-inner{
    height: 450px !important;
  }
  .whatsnew h4{
    text-transform: capitalize;
    background-color:#00000077;
    padding: 2px 0;
  }
  .whatsnew .mx-3{
    margin: 0 5px !important;
  }
}
</style>