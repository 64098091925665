<template>
<div id="ContainerDrug"> 
      <h4 v-if="this.$attrs.editMode" class="page-title text-truncate">
        {{$t('drug.Edit')}}
      </h4>
      <h4 v-if="!this.$attrs.editMode" class="page-title text-truncate">
        {{$t('drug.Add')}}        
      </h4> 
        <b-row>
            <b-col lg="3" md="3" sm="12">
                 <MdEdit
                     v-model="Drug.drugCode"                    
                     v-bind:Label="$t('drug.DrugCode')"          
                     v-bind:isReadOnly="this.$attrs.editMode"
                     v-bind:isRequired="true"
                     v-bind:MaxLengthProp="50"
                     v-bind:isValid="isCodeValid"
                     v-on:blur="OnCodeBlur(Drug.drugCode)"
                     :InvalidFeedback="$t('drug.DrugRequired')"
                     :SearchFieldCode="'code'"
                     v-bind:Id="'input_drug'"/>
            </b-col>
            <b-col lg="3" md="3" sm="12">
                <MdEdit 
                  v-bind:Label="$t('drug.Drugname')"
                  v-bind:TypeProp="'text'" 
                  v-bind:Name="'drugname'"  
                  v-bind:MaxLengthProp="50"  
                  v-model="Drug.drugname"
                  v-bind:isRequired="true"
                  v-bind:Id="'input_drug_name'"/>
            </b-col>
            <b-col lg="3" md="3" sm="12">
                <MdEdit 
                  v-bind:Label="$t('drug.ActIngre')"
                  v-bind:TypeProp="'text'" 
                  v-bind:Name="'activeingredients'"  
                  v-bind:MaxLengthProp="50"  
                  v-model="Drug.activeingredients"
                  v-bind:isRequired="true"
                  v-bind:Id="'input_actIngre'"/>
            </b-col>
            <b-col lg="3" md="3" sm="12">
                 <MdEdit 
                  v-bind:Label="$t('Medication.Dosage')"
                  v-bind:TypeProp="'text'" 
                  v-bind:Name="'dosage'"  
                  v-bind:MaxLengthProp="10"  
                  v-model="Drug.dosage"
                  v-bind:isRequired="true"
                  v-bind:Id="'input_dosage'"/>
            </b-col>
            <b-col lg="3" md="3" sm="12">
                <MdEdit 
                  v-bind:Label="$t('Medication.Form')"
                  v-bind:TypeProp="'text'" 
                  v-bind:Name="'form'"  
                  v-bind:MaxLengthProp="10"  
                  v-model="Drug.form"
                  v-bind:isRequired="true"
                  v-bind:Id="'input_m_form'"/>
            </b-col>
            <b-col lg="3" md="3" sm="12">
              <MdSelect
                  v-bind:Label="$t('Medication.RouteN')"
                  v-bind:Options="RouteNameList"
                  v-bind:Model="Drug.routename"
                  v-bind:FieldDescription="'text'"
                  v-bind:FieldCode="'value'"
                  v-bind:Multiple="false"
                  :IsDisabled=this.isReadOnly             
                  :Input="ChangeDropDownRouteName"/>
            </b-col>
            <b-col lg="3" md="3" sm="12">         
                 <MdSelect
                  v-bind:Label="$tc('Shared.ActionsTxt')"
                  v-bind:Options="ActionList"
                  v-bind:Model="Drug.action"
                  v-bind:FieldDescription="'text'"
                  v-bind:FieldCode="'value'"
                  v-bind:Multiple="false"
                  :IsDisabled=this.isReadOnly               
                  :Input="ChangeDropDownAction"/>
            </b-col>
            <b-col lg="3" md="3" sm="12">
                 <MdSelect
                  v-bind:Label="$t('Medication.When')"
                  v-bind:Options="WhenList"
                  v-bind:Model="Drug.when"
                  v-bind:FieldDescription="'text'"
                  v-bind:FieldCode="'value'"
                  v-bind:Multiple="false"    
                  :IsDisabled=this.isReadOnly          
                  :Input="ChangeDropDownWhen"/>
            </b-col>
            <b-col lg="3" md="3" sm="12">
                <MdEdit 
                  v-bind:Label="'Rxnorm'"
                  v-bind:TypeProp="'text'" 
                  v-bind:Name="'rxnorm'"  
                  v-bind:MaxLengthProp="10"  
                  v-model="Drug.rxnorm"
                  v-bind:isRequired="false"
                  v-bind:Id="'input_rxnorm'"/>
            </b-col>
            <b-col lg="3" md="3" sm="12">
                <MdEdit 
                  v-bind:Label="'Snomed'"
                  v-bind:TypeProp="'text'" 
                  v-bind:Name="'snomed'"  
                  v-bind:MaxLengthProp="10"  
                  v-model="Drug.snomed"
                  v-bind:isRequired="false"
                  v-bind:Id="'input_snomd'"/>
            </b-col>
            <b-col lg="3" md="3" sm="12">
                <MdEdit 
                  v-bind:Label="$t('drug.OtherCode')"
                  v-bind:TypeProp="'text'" 
                  v-bind:Name="'othercode'"  
                  v-bind:MaxLengthProp="10"  
                  v-model="Drug.othercode"
                  v-bind:isRequired="false"
                  v-bind:Id="'input_ocode'"/>
            </b-col>
            <b-col lg="3" md="3" sm="12">
                <MdEdit 
                  v-bind:Label="$t('drug.DefaultDays')"
                  v-bind:TypeProp="'text'" 
                  v-bind:Name="'defaultdays'"  
                  v-bind:MaxLengthProp="10"  
                  v-model="Drug.defaultdays"
                  v-bind:isRequired="false"
                  v-bind:Id="'input_dd'"/>
            </b-col>
            <b-col lg="3" md="3" sm="12">
                  <MdCheckBox
                    v-bind:Label="$t('drug.ResChange')"
                    v-bind:Name="'checkbox-RestrictChange'"
                    v-bind:ClassProp="'AlignCheck'"
                    v-bind:CheckedValue="true"
                    v-bind:UncheckedValue="false"
                   v-model="Drug.restrictchange"                 
                  />                    
            </b-col>
            <b-col lg="3" md="3" sm="12">             
                <MdRadioGroup
                    v-bind:Label="$t('Shared.Status')"
                    v-model="Drug.statusselect"
                    v-bind:Options="StatusSelectList"
                    v-bind:Name="'Status'"                                   
                />
            </b-col>
            <b-col lg="3" md="3" sm="12">         
                 <MdSelect
                  v-bind:Label="$t('drug.ProductType')"
                  v-bind:Options="ProductTypeSelectList"
                  v-bind:Model="Drug.producttypeselect"
                  v-bind:FieldDescription="'text'"
                  v-bind:FieldCode="'value'"
                  v-bind:Multiple="false"
                  :IsDisabled=this.isReadOnly               
                  :Input="ChangeDropDownProductType"/>
            </b-col>
            <b-col lg="3" md="3" sm="12">         
                 <MdSelect
                  v-bind:Label="$t('drug.ConSub')"
                  v-bind:Options="SubstanceScheduleSelectList"
                  v-bind:Model="Drug.substancescheduleselect"
                  v-bind:FieldDescription="'text'"
                  v-bind:FieldCode="'value'"
                  v-bind:Multiple="false"
                  :IsDisabled=this.isReadOnly               
                  :Input="ChangeDropDownSubstanceSchedule"/>
            </b-col>
            <b-col lg="3" md="3" sm="12">
                  <MdCheckBox
                    v-bind:Label="$t('drug.IsLook')"
                    v-bind:Name="'checkbox-LookAlikeSoundAlike'"
                    v-bind:ClassProp="'AlignCheck'"
                    v-bind:CheckedValue="true"
                    v-bind:UncheckedValue="false"
                    v-model="Drug.lookalikesoundalike"     
                    v-bind:isReadOnly=this.isReadOnly            
                  />                    
            </b-col>
            <b-col lg="3" md="3" sm="12">
                  <MdCheckBox
                    v-bind:Label="$t('drug.IsHigh')"
                    v-bind:Name="'checkbox-HighAlert'"
                    v-bind:ClassProp="'AlignCheck'"
                    v-bind:CheckedValue="true"
                    v-bind:UncheckedValue="false"
                    v-model="Drug.highalert"
                    v-bind:isReadOnly=this.isReadOnly                 
                  />                    
            </b-col>
            <b-col lg="3" md="3" sm="12">
                <MdEdit 
                  v-bind:Label="$t('drug.UnitPrice')"
                  v-bind:TypeProp="'number'" 
                  v-bind:Name="'unitprice'"  
                  v-bind:MaxLengthProp="10"  
                  v-model="Drug.unitprice"
                  v-bind:isRequired="false"/>
            </b-col>
            <b-col lg="3" md="3" sm="12">
                  <MdCheckBox
                    v-bind:Label="$t('drug.UseMetha')"
                    v-bind:Name="'checkbox-MethaSpense'"
                    v-bind:ClassProp="'AlignCheck'"
                    v-bind:CheckedValue="true"
                    v-bind:UncheckedValue="false"
                    v-model="Drug.methaspense"  
                    v-bind:isReadOnly=this.isReadOnly               
                  />                    
            </b-col>
    </b-row>
    <b-row>
      <b-col md="3" lg="3" sm="12">
            <MdEdit 
                v-bind:Label="$t('drug.RequiredAsse')"
                v-bind:TypeProp="'text'" 
                v-bind:Name="'assessment'"
                v-model="Drug.assessment"
                v-bind:Id="'input_RequiredAsse'"/>
        </b-col>
        <b-col md="3" lg="3" sm="12">
            <MdEdit 
                v-bind:Label="$t('PatientDocument.Comments')"
                v-bind:TypeProp="'text'" 
                v-bind:Name="'comments'"
                v-model="Drug.comments"
                v-bind:Id="'input_d_comments'"/>
        </b-col>
    </b-row>    
    <div class="page-actions">      
        <MdButton
               v-bind:Label="$t('drug.DrugList')"
               v-bind:Name="'btndruglist'"
               v-bind:ClassProp="'secondary mx-2 btn-login'"
               v-on:click="DiscardChanges"/>    
        <MdButton
            v-bind:Label="$t('Shared.Save')"
            v-bind:ClassProp="(this.isReadOnlyDrug)?'primary mx-2':'primary btn-login mx-2'"
            v-on:click="SaveOrEdit"
            v-bind:isReadOnly="isReadOnlyDrug"
            v-bind:Name="'btn_save_drug'"/>
    </div>

</div>
</template>

<script>


import DBFunctions from '@/common/DBFunctions.js';
import ShowMessage from '@/components/messages/ShowMessage.js'
import DrugService from '@/services/drug.service.js'
import EnumService from '@/services/enum.service' 
import { EventBus } from '../../../../event-bus.js';
//import Utils from '@/common/utils'

let elemSaveBtn = '';
 export default {
//     beforeRouteEnter(to, from, next) {
//         next(async vm => {
//             const iPI = await vm.CanView();
//             if(iPI) next()
//             else next('/accessdenied');
//         }) 
//   },
  
 name: 'Drug',
 mixins: [ShowMessage, DBFunctions/*,EventBus*/],
  data(){
    return{
        parameter:{
            SearchCriteria:'',
            PageNumber : 1,
            PageSize:0,         
        },
        pendingChanges:false,
     Drug:{
        drugCode:'',
        drugname:'',
        dosage:'',
        form:'',
        activeingredients:'',
        routename:'',
        action:'',
        when:'',
        rxnorm:'',
        snomed:'',
        othercode:'',
        defaultdays:'',
        restrictchange:false,
        statusselect:'',
        producttypeselect:'',
        substancescheduleselect:'',
        lookalikesoundalike:false,
        highalert:false,
        unitprice:'',
        methaspense:false,
        assessment:'',
        comments:''
     },
        isReadOnlyDrug:false,
        dDrug:'',
        permission:[],
        Menu : this.$store.state.leftMenu,
        isdrugValid:true,   
        isCodeValid:true,   
        CodeExist:false,      
        OptionsRightMenu:{
          visible:false,
          ItemsList:[],
          btnvisible:false,
          itemdisabled: false,
          PatientCode:'',
          Mode:this.edit_mode,
          favButtonAtion:false          
        },

        RouteNameList: EnumService.RouteNameList,
        ActionList: EnumService.ActionList,
        WhenList: EnumService.WhenList,
        StatusSelectList: EnumService.YesNoOptions,
        ProductTypeSelectList: EnumService.ProductTypeSelectList,
        SubstanceScheduleSelectList: EnumService.SubstanceScheduleSelectList
    }
  },

  methods:{

    ChangeDropDownRouteName(value) {
        this.Drug.routename = value ? value.value : ''; 
    },

    ChangeDropDownAction(value) {
        this.Drug.action = value ? value.value : ''; 
    },

    ChangeDropDownWhen(value) {
      this.Drug.when = value ? value.value : ''; 
    },

    ChangeDropDownProductType(value) {
      this.Drug.producttypeselect = value ? value.value : ''; 
    },

    ChangeDropDownSubstanceSchedule(value) {
      this.Drug.substancescheduleselect = value ? value.value : ''; 
    },

    DiscardChanges(){
       this.checkExistFavorite('/app/druglist');
       this.$router.push({name: "DrugList"});
    },
    CanView(){
      return this.permission[0].roleCanView;
  },

  onCodeConditionOk(){

  },

    getDrug(){
      if(this.$attrs.editMode){
      this.$emit('load', true);     
      DrugService.Get(this.$route.params.id)
        .then((response) => {
            if(response.status == '200' && response.data){
              this.Drug = response.data;  
            }   
              this.$emit('load', false);                     
        })
        .catch((error) => {                    
            if (!error.response) {
              this.showMessage(this.$t('drug.Drug'),this.$t('Msg.retrieveInfo'),"error");
            }                      
            else if(error.response.status === 400 ){
              this.showMessage(this.$t('drug.Drug'),error.response.data.errorMessage,"error");
            }
            else if(error.response.status === 405 ){             
                  this.$router.push({name: "Main"});    
                    setTimeout(() => 
                        this.showPermissionMessage()
                        , 50);            
            } 
            else if(error.response.status === 500){
              this.showMessage(this.$t('drug.Drug'),this.$t('Msg.ThereWereSomeError'),"error");
            } else{
              this.showMessage(this.$t('drug.Drug'),error.response.data.errorMessage,"error");
            } 
            this.$emit('load', false);            
        }); 
    
      }
    },

    AddDrug(){
      if(!this.CodeExist){
      this.$emit('load', true);
      DrugService.Add(this.Drug)
                .then((response) => {
                    if(response.status == '200'){
                        this.Drug = response.data; 
                        this.pendingChanges = false;   
                        this.$emit('load', false);
                        /*if(!this.OffRoute){
                           this.GotoAdmissionList();
                        }*/
                        this.$router.push({name: "DrugList"}).catch(()=>{});
                      
                        setTimeout(() => 
                        this.$bvToast.toast(this.$t('Msg.SavedSuccessfully'), {
                         title: this.$t('Msg.Saving'),
                          autoHideDelay: 5000,
                          variant: "success"
                        })
                        , 50); 
                    }                             
                })
                .catch((error) => {   
                    if (!error.response) {
                   this.showMessage(this.$t('drug.Drug'),this.$t('Msg.retrieveInfo'),"error");
                      }                      
                     else if(error.response.status === 400 ){
                        this.showMessage(this.$t('drug.Drug'),error.response.data.errorMessage,"error");
                     } else if(error.response.status === 500){
                        this.showMessage(this.$t('drug.Drug'),this.$t('Msg.ThereWereSomeError'),"error");
                     } else{
                         this.showMessage(this.$t('drug.Drug'),error.response.data.errorMessage,"error");
                     }
                     elemSaveBtn.removeAttribute("disabled");  
                    this.$emit('load', false);
                }); 
      }else{
         this.showMessage(this.$t('drug.Drug'),this.$t('drug.DrugCodeExist'),"error");
      }
    },


    EditDrug(){
      this.$emit('load', true);
      DrugService.Update(this.$route.params.id,this.Drug)
                .then((response) => {
                    if(response.status == '200'){
                        this.Drug = response.data; 
                        this.pendingChanges = false;   
                        this.$emit('load', false);                        
                        this.$router.push({name: "DrugList"}).catch(()=>{});                      
                        setTimeout(() => 
                        this.$bvToast.toast(this.$t('Msg.SavedSuccessfully'), {
                         title: this.$t('Msg.Saving'),
                          autoHideDelay: 5000,
                          variant: "success"
                        })
                        , 50); 
                    }                             
                })
                .catch((error) => {   
                    if (!error.response) {
                   this.showMessage(this.$t('drug.Drug'),this.$t('Msg.retrieveInfo'),"error");
                      }                      
                     else if(error.response.status === 400 ){
                        this.showMessage(this.$t('drug.Drug'),error.response.data.errorMessage,"error");
                     } else if(error.response.status === 500){
                        this.showMessage(this.$t('drug.Drug'),this.$t('Msg.ThereWereSomeError'),"error");
                     } else{
                         this.showMessage(this.$t('drug.Drug'),error.response.data.errorMessage,"error");
                     }
                     elemSaveBtn.removeAttribute("disabled");  
                    this.$emit('load', false);
                }); 
    },

     DoAction(){       
        !this.$attrs.editMode ? this.AddDrug() : this.EditDrug(); 
      },

      SaveOrEdit(){ 
         if (this.validate_required()) {
            this.DoAction();
         } else {
            this.showMessage(this.$t('drug.Drug'), this.$t('Msg.CheckRedLabeledFields'), "errorAutoHide");          
         }
      },

    validate_required() {       
        this.isCodeValid = this.Drug.drugCode.length > 0 ? true : false;  
        return this.isCodeValid;
      },

      OnCodeBlur(value){           
          if(!this.$attrs.editMode){           
            if (value.trim()!== '') {          
            var parameter = {
               "drugCode":value
            };
            DrugService.getDrugsByCode(parameter)
                .then((response) => {
                    if(response.status == '200' && response.data){
                      // this.$emit('load', false);  
                       if(response.data.length > 0){
                          this.showMessage(this.$t('drug.Drug'),this.$t('drug.DrugCodeExist'),"error");
                          this.CodeExist = true;
                       }else{
                           this.CodeExist = false;
                       } 
                    }  
                     this.$emit('load', false);                          
                })
                .catch((error) => {
                    this.$emit('load', false);                  
                  if(error.response.status !== 404 && error.response.status !== 500){
                      if(error.response) {
                        this.showMessage(this.$t('drug.Drug'),error,"error");
                    }
                  }
                    
                });  
            }
               }
    
       },
       checkUserAction() {
        if (elemSaveBtn.disabled) {
          elemSaveBtn.removeAttribute('disabled');
        }
      }
  },

  created(){
    EventBus.$on("onChanged", function () {       
          this.checkUserAction();
     }.bind(this));
    this.getPermissionLeftMenu(this.Menu,this.$route.path,this.permission);
  },

  mounted(){
      this.OptionsRightMenu.ItemsList = [];
       let layoutStatus = this.$store.state.layoutStatus;
       switch(layoutStatus) {
        case 'L0R1':
          this.OptionsRightMenu.favButtonAtion = false;
          this.$emit('getVisibleMenu', this.OptionsRightMenu);
        break;
        case 'L1R0':
        case 'L1R1':
            this.OptionsRightMenu.btnvisible = false;
            this.$emit('getVisibleMenu', this.OptionsRightMenu);
        break;
        default:
            this.OptionsRightMenu.favButtonAtion = false;
            this.$emit('getVisibleMenu', this.OptionsRightMenu);
      }
      this.getDrug();
      setTimeout( function() {
         elemSaveBtn = document.getElementById('btn_save_drug');
         elemSaveBtn.setAttribute('disabled','');
      }, 50, this );        
  },

    destroyed(){
      this.OptionsRightMenu.ItemsList = [];
      this.OptionsRightMenu.Mode = false;
      this.OptionsRightMenu.visible = false; 
      this.$emit('getVisibleMenu', this.OptionsRightMenu);
    }
   
};
</script>


