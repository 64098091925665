<template>
<div v-bind:style="style">  
  <img :src="image" alt="">
</div>
</template>

<script>
export default {
  name: 'MdImage',
  data(){
      return{
      style:{
       position:'absolute',
       top: this.$attrs.control.controlTop+'px',
       width:this.$attrs.control.controlWidth+'px',
       height:this.$attrs.control.controlHeight+'px',
       left:this.$attrs.control.controlLeft+'px',
      },
        Extension:"",
        image:"data:image/"+this.Extension+";base64,"+this.$attrs.control.imageContents
      }
  }, 
  created(){
    this.Extension = this.$attrs.control.extension;
    this.Extension = this.Extension?this.Extension.slice(1):'';
  },

   
};
</script>

