import ApiService from '@/services/api.service'


const IncidentWorkflowService = {
    getAll(parameter){
      const params = {
        SearchCriteria: parameter.SearchCriteria,
        PageSize: parameter.PageSize,          
        PageNumber : parameter.PageNumber,
        DateFrom : parameter.DateFrom,
        DateTo : parameter.DateTo,
        PatientCodeList : parameter.PatientCodeList,
        ReporterCodeList: parameter.ReporterCodeList,
        WorkflowIdList : parameter.WorkflowIdList,
        ProgramCodeList : parameter.ProgramCodeList,
        CategoryDescriptionList : parameter.CategoryDescriptionList,
        SeverityCodeList : parameter.SeverityCodeList,
        WorkflowStatus : parameter.WorkflowStatus,
        Note : parameter.Note,
        NoteStatus : parameter.NoteStatus
      }
      return ApiService.getWithArray('/IncidentWorkflow/',params); 
    },

    getIncidentWorkflowAssignBy(){
      return ApiService.get(`/IncidentWorkflow/AssignByListing`); 
    },

    getIncidentWorkflowAssignTo(){
      return ApiService.get(`/IncidentWorkflow/AssignToListing`); 
    },

    getIncidentWorkflow(){
      return ApiService.get(`/IncidentWorkflow/WorkflowListing`); 
    },

    getIncident(){
      return ApiService.get(`/IncidentWorkflow/WorkflowIncident`); 
    },

    getNotesFilter(){
      return ApiService.get(`/IncidentWorkflow/NoteFilters`); 
    },

    getSeverity(){
      return ApiService.get(`/IncidentWorkflow/WorkflowSeverity`); 
    },

    getButtonLabel(RecID){
      return ApiService.get(`/IncidentWorkflow/GetButtonLabel?RecID=${RecID}`); 
    },

    setVoid(RecID,Status){
      return ApiService.get(`/IncidentWorkflow/SetVoid/${RecID}:${Status}`); 
    },

    setForceWorkflowCompletion(RecID){
      return ApiService.get(`/IncidentWorkflow/SetForceWorkflowCompletion/${RecID}`); 
    },

    canSeeIncidentWFAdmin(){
      return ApiService.get(`/IncidentWorkflow/CanSeeIncidentWFAdmin`); 
    },

    isIncidentWFVoided(IncidentID,RecID){
      return ApiService.get(`/IncidentWorkflow/isIncidentWFVoided/${IncidentID}:${RecID}`); 
    },
  }
  
  export default IncidentWorkflowService;