import ApiService from '@/services/api.service'

const DCSService = {
    listing(parameter){
        var param = {}
        if(parameter.Status != 'All'){
            param.SearchCriteria = parameter.SearchCriteria,
            param.PageSize = parameter.PageSize,
            param.PageNumber = parameter.PageNumber
        }else{
            param.SearchCriteria = parameter.SearchCriteria,
            param.PageSize = parameter.PageSize,
            param.PageNumber = parameter.PageNumber       
        }

        const requestData = {
        method: 'get',
        url: "/DCSClaim",
        headers: {
            
        },
        params: param
        }
        return ApiService.customRequest(requestData);
    },
    populateNotes(data){
        return ApiService.post("/DCSClaim/notes", data);
    },

    tableControl(data){
        return ApiService.post("/DCSClaim/tableControls", data);
    },

    dcsReview(data){
        return ApiService.post("/DCSClaim/review", data);
    },

    dcsProduction(data){
        return ApiService.post("/DCSClaim/production", data);
    },

    dcsDelete(data){
        return ApiService.post("/DCSClaim/delete", data);
    },

    dcsException(data){
        return ApiService.getData(`/DCSClaim/exceptions?GroupCreationID=${data.groupCreationID}&Mode=${data.mode}&Version=${data.version}`);
    }
}

export default DCSService;