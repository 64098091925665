<template>
  <div id="ContainerClinicalDashboard">
    <div class="row">
        <div class="col-12">
          <MdTitle v-bind:Text="$t('Clinical.ClinicalDashboard')" />  
          <div class="row">                    
              <div class="col-sm-12 col-md-12 mb-4">
                  <div class="textlabel" :data-label="this.$t('Shared.SearchElements')">
                      <div class="input-group">
                          <input 
                            type="text" 
                            class="form-control"
                              v-model="parameter.SearchCriteria"
                              @keyup.enter="SearchPatients()"
                          >
                          <div class="input-group-append">
                                <MdButton 
                                v-bind:Name="'btnsearchinput'"
                                v-bind:VariantProp="'btn btn-sm search-attached'"
                                v-bind:IconClass="'mdi mdi-magnify'"
                                v-on:click="SearchPatients">
                              </MdButton>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="flex-container">
              <div class="row">
                <div class="col-md-3">
                <MdSelect
                  v-bind:Label="$tc('Shared.Program',2)"
                  v-bind:Options="ClinicalFilter.Program"
                  v-bind:Model="ProgramVmodel"
                  v-bind:FieldDescription="'name'"
                  v-bind:FieldCode="'code'"
                  v-bind:Placeholder="selectPlaceholder"
                  v-bind:Multiple="true"
                  v-bind:RequireEvent="true"
                  v-bind:IfUsevalidation="false"
                  :Input="ChangeDropDownPrograms"/>
              </div>
              <div class="col-md-3">
                <MdSelect
                  v-bind:Label="$t('Shared.Status')"
                  v-bind:Options="OptionStatus"
                  v-bind:ModelArray="StatusVModel"
                  v-bind:FieldDescription="'name'"
                  v-bind:FieldCode="'value'"
                  v-bind:Placeholder="selectPlaceholder"
                  :Input="ChangeDropDownStatus"/>
              </div>
              <div class="col-md-3">
                <MdSelect
                  v-bind:Label="$t('Shared.Type')"
                  v-bind:Options="this.OptionTypes"
                  v-bind:ModelArray="TypeVModel"
                  v-bind:FieldDescription="'name'"
                  v-bind:FieldCode="'value'"
                  v-bind:Placeholder="selectPlaceholder"
                  :Input="ChangeDropDownType"/>
              </div>
              <div class="col-md-3">
                <MdSelect
                  v-bind:Label="'Team Member'"
                  v-bind:Options="this.OptionEmployee"
                  v-bind:Model="EmployeeVModel"
                  v-bind:Multiple="true"
                  v-bind:FieldDescription="'teamMemberName'"
                  v-bind:FieldCode="'teamMemberCode'"
                  v-bind:Placeholder="selectPlaceholder"
                  :Input="ChangeDropDownEmployee"/>
              </div>
              </div>
          </div>
            
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="mt-4">
                <MdTitle v-bind:Text="$t('Shared.SearchResult')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
                <div class="row">
                    <div class="col-md-12">
                      <div>
                        <MdTable 
                          v-bind:fields="fields"
                          v-bind:items="items"
                          v-bind:totalRows="totalRows"
                          v-bind:showMessageIsEmpty="ShowMessageIsEmpty"
                          v-bind:currentPage="currentPage"
                          v-bind:perPage="perPage"
                          v-bind:pageOptions="pageOptions"
                          v-bind:refTable="refTable"
                          v-bind:FieldsDate="FieldsDateArr"
                          :viewIconEvent="GotoClinicalDashboard"
                          :onRowSelected="onRowSelected"
                          :onFiltered="onFiltered"
                          :pageChanged="pageChanged"
                          :perPageFunction="PerPageFunction">
                        </MdTable>
                      </div>  
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
 import EnumService from '@/services/enum.service'
 import ClinicalService from '@/services/clinical.service'
 import Utils from '@/common/utils'
 import DBFunctions from '@/common/DBFunctions.js'
 import ShowMessage from '@/components/messages/ShowMessage.js'
 import { EventBus } from '@/event-bus.js';
 
export default {
beforeRouteEnter(to, from, next) {
        next(async vm => {
            const iPI = await vm.CanView();           
            if(iPI) next()
            else next('/accessdenied');
        }) 
  },
  mixins: [ShowMessage,DBFunctions],
  name: 'ClinicalDashboard',
   data() {
      return { 
        Menu : this.$store.state.leftMenu,
        permission: [],
        OptionEmployee:[],
        EmployeeVModel:[],
     search_criteria: "",
      error_toast: "",
      filter:null,
      Loading:true,
      ProgramsSelected:[],
    ProgramVmodel:[],
    FieldsDateArr:['dob','dateFrom','dateTo'],
     parameter:{
        SearchCriteria:'',
        PageNumber : 0,
        PageSize:0,
        Status: '',
        Programs:'',
        IsInpatient:'',
        HasTreatmentPlan:'',
        HasASAM:'',
        employees:'',
      },
       Header:{},
      OptionsRightMenu:{
            visible:false,
            btnvisible:false,
            ItemsList:[],
            favButtonAtion: false
        },
     fields: [
        { key: 'patientName', sortable: true, label: this.$t('Shared.PatientName'), class: 'text-rigth' },
        { key: 'nickname', sortable: true, label: this.$t('Clinical.NickName'), class: 'text-rigth' },
        { key: 'patientCode', sortable: true, label: this.$t('Shared.PatientCode'), class: 'text-rigth' },
        { key: 'dob', sortable: true, label: this.$t('Shared.Dob'), class: 'text-rigth' },
        { key: 'programName', sortable: true, label: this.$tc('Shared.Program'), class: 'text-rigth' },
        { key: 'programCode', sortable: true, label: this.$t('Shared.ProgramCode'), class: 'text-center hidden' },
        { key: 'dateFrom', sortable: true, label: this.$t('Shared.datefrom'), class: 'text-rigth' },
        { key: 'dateTo', sortable: true, label: this.$t('Shared.dateTo'), class: 'text-rigth' },
        { key: 'transactionType', sortable: true, label: this.$t('Clinical.TransactionType'), class: 'text-rigth' },
        { key: 'status', sortable: true, label: this.$t('Shared.Status'), class: 'text-rigth' },
        { key: 'recordId', sortable: true, label: this.$t('Clinical.RecordId'), class: 'text-center hidden' } ,
        { key: 'chartNo', sortable: true, label: this.$t('Clinical.ChartNo'), class: 'text-center' },       
        { key: 'isInpatient', sortable: true, label: this.$t('Shared.Inpatient'), class: 'text-center' },
        { key: 'roomName', sortable: true, label: this.$t('Clinical.RoomName'), class: 'text-center' },
        { key: 'buildingName', sortable: true, label: this.$t('Shared.BuildingName'), class: 'text-center' },
                
        ],

      pageOptions: [5, 10, 15 ],
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      filterOn: [],
       items: [],

      selected: [],  
      ClinicalFilter: {
        Status:'',
        Type:'',
        TreatmentPlan:'',
        Asam:'',
        Program:[],
        TeamMember:[]
      },
      OptionTypes:EnumService.Types,
      TypeVModel:  {value:'',name: this.$t('Shared.All')}, 
      OptionStatus:EnumService.Status, 
      StatusVModel: {value:'Active',name: 'Active'},
      OptionTreatmentPlan:EnumService.TreatmentPlan, 
      TreatmentPlanVModel:  {value:'',name: this.$t('Shared.All')}, 
      OptionAsam:EnumService.Asam,
      AsamVModel:  {value:'',name: this.$t('Shared.All')}, 
      OptionProgram:EnumService.Program,    
      OptionTeamMember:[],
      ShowMessageIsEmpty: true,
      refTable: 'selectableTable',
      selectPlaceholder: this.$t('Clinical.SelectOptions')
      }
    },

    async created(){
      await this.getPermissionLeftMenu(this.Menu,this.$route.path,this.permission);
      if(this.permission.length == 0){
        this.$emit('load', false);
        this.$router.push({name: "Main"});    
          setTimeout(() => 
            this.showPermissionMessage()
          ,10);  
      }

      await this.getAllEmployee();
    },

     mounted() {      
       this.parameter.PageNumber = this.currentPage;  
       //this.getTeamsMember();       
        this.getPrograms();   
      if(this.$store.state.clinicalDashboardProgramsSelected.length > 0){
        this.ProgramVmodel = this.$store.state.clinicalDashboardProgramsSelected;      
        EventBus.$emit('ChangedDrop', this.ProgramVmodel);  
        this.ChangeDropDownPrograms(this.ProgramVmodel);       
      }else{
        this.getListing();
      }   
      
      
       this.OptionsRightMenu.ItemsList = EnumService.ClinicalItems
       let layoutStatus = this.$store.state.layoutStatus;
       switch(layoutStatus) {
        case 'L0R1':
          this.OptionsRightMenu.favButtonAtion = true;
          this.$emit('getVisibleMenu', this.OptionsRightMenu);
        break;
        case 'L1R0':
        case 'L1R1':
            this.OptionsRightMenu.btnvisible = false;
            this.$emit('getVisibleMenu', this.OptionsRightMenu);
        break;
        default:
            this.OptionsRightMenu.favButtonAtion = true;
            this.$emit('getVisibleMenu', this.OptionsRightMenu);
      }     
    },

    methods:{
     pageChanged (page) {            
      this.currentPage = page;
      this.parameter.PageNumber = this.currentPage;         
      this.getListing();
    },

    CanView(){
      return this.permission[0].roleCanView;
    },

    PerPageFunction(perpage){
      this.perPage = perpage;    
      this.parameter.PageNumber = 1;
      this.getListing();
    },

    ChangeDropDownEmployee(employees){
      this.EmployeeVModel = employees;
     var l = employees.length;
     var employeecodes = '';
     if(l>0){
       var i = 0;
       employees.forEach(function(e) {  
        if(i<l-1){
          employeecodes += 'EmployeeCodes='+e.teamMemberCode+"&";         
        }else{
          employeecodes += 'EmployeeCodes='+e.teamMemberCode;         
        }
        i++;                    
        });
        this.parameter.employees = employeecodes;       
    }else{
      this.parameter.employees = '';     
    }  
    this.parameter.PageNumber = this.currentPage;  
      
      this.getListing();
    },

    async getAllEmployee() {
      await ClinicalService.getTeamsMembers()
        .then((response) => { 
        if(response.data && response.data.length > 0){
          this.OptionEmployee = response.data;
        }             
        })
        .catch((error) => {
        if (error.response) {
          this.showMessage('Daily Service Log',error,"error");
        }
        });
    },

    ChangePrograms(programs){      
     var l = programs.length;
     var programcodes = '';    
     if(l>0){
       var i = 0;
        programs.forEach(function(e) {           
        if(i<l-1){
          programcodes += 'ProgramCodes='+e.code+"&";
         
        }else{
          programcodes += 'ProgramCodes='+e.code;        
        }
        i++;                    
        });
        this.parameter.Programs = programcodes;        
    }else{
      this.parameter.Programs = '';      
    }  
      this.parameter.PageNumber = this.currentPage; 
    },

    //EmployeeCodes

     ChangeDropDownPrograms(programs){
      this.ProgramVmodel = programs;
     var l = programs.length;
     var programcodes = '';
     var Pcodes = [];
     if(l>0){
       var i = 0;
        programs.forEach(function(e) {   
          Pcodes.push(e.code);      
        if(i<l-1){
          programcodes += 'ProgramCodes='+e.code+"&";
         // Pcodes +=e.code+",";
        }else{
          programcodes += 'ProgramCodes='+e.code;
         // Pcodes +=e.code;
        }
        i++;                    
        });
        this.parameter.Programs = programcodes;
        this.ProgramsSelected = Pcodes;
    }else{
      this.parameter.Programs = '';
      this.ProgramsSelected = [];
    }  
      this.parameter.PageNumber = this.currentPage; 
      
      if(this.ProgramVmodel.length > 0){
          this.$store.commit('setclinicalDashboardProgramsSelected',this.ProgramVmodel);
        }else{
          this.$store.commit('setclinicalDashboardProgramsSelected',[]);
        }
      
      this.getListing();
    },

     ChangeDropDownStatus(status) {
        this.StatusVModel.value = status ? status.value : '';
        this.parameter.PageNumber = this.currentPage;         
        this.getListing();
     },

     ChangeDropDownType(type) {
       this.TypeVModel.value = type ? type.value : '';
       this.parameter.PageNumber = this.currentPage;         
       this.getListing();
     },

     ChangeDropDownTreatmentPlan(treatmentPlan) {
       this.TreatmentPlanVModel.value = treatmentPlan ? treatmentPlan.value : '';
       this.parameter.PageNumber = this.currentPage;         
       this.getListing();
     },

     ChangeDropDownASAM(ASAM) {
       this.AsamVModel.value = ASAM ? ASAM.value : ''; 
       this.parameter.PageNumber = this.currentPage;         
       this.getListing();
     },

     getListing(){
      this.Loading = true;
      this.$emit('load', this.Loading)        
      this.parameter.PageSize = this.perPage;
      this.parameter.Status = this.StatusVModel.value;
      this.parameter.IsInPatient = this.TypeVModel.value;  

      if(this.permission.length > 0 && this.permission[0].roleCanView){
       ClinicalService.getAll(this.parameter)
                .then((response) => {
                     if(response.status == '200' && response.data){
                       this.items = response.data;
                       if(response.data.length > 0){                        
                        this.items.forEach(function(element) {
                          if (element.dob)	
                              element.dob = Utils.formatterDateToString(element.dob);
                          if (element.dateFrom)	
                              element.dateFrom = Utils.formatterDateToString(element.dateFrom);        
                          if (element.dateTo)
                              element.dateTo =  Utils.formatterDateToString(element.dateTo);        
                        });
                        let headerString = response.headers["x-pagination"];
                        this.Header = JSON.parse(headerString);
                        this.totalRows = this.Header.TotalCount;
                       }
                     }  
                      this.Loading = false;
                      this.$emit('load', this.Loading);
                })
                .catch((error) => {
                    this.Loading = false;
                    this.$emit('load', this.Loading);            
                    if (!error.response) {
                      this.showMessage(this.$t('Clinical.ClinicalListing'),this.$t('Msg.retrieveInfo'),"error");
                    }                      
                    else if(error.response.status === 400 ){
                      this.showMessage(this.$t('Clinical.ClinicalListing'),error.response.data.errorMessage,"error");
                    }                    
                    else if(error.response.status === 500){
                        this.showMessage(this.$t('Clinical.ClinicalListing'),this.$t('Msg.ThereWereSomeError'),"error");
                    } else{
                      this.showMessage(this.$t('Clinical.ClinicalListing'),error.response.data.errorMessage,"error");
                    } 
                    this.$emit('load', false);     
                }); 
    
     }
    
    },

    SearchPatients(){
      this.Loading = true;
      this.$emit('load', this.Loading);
      this.items = []
      this.currentPage = 1;
      this.totalRows = this.items.length;
      this.parameter.PageNumber = 1;
      this.getListing();
    },

    onRowSelected(items) {
      this.selected = items
    },

    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
    },

    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },

    selectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.selectRow(2)
    },

    async getPrograms(){
      await ClinicalService.getPrograms()
                .then((response) => {
                     if(response.status == '200' && response.data && response.data.length > 0){
                       this.ClinicalFilter.Program = response.data;                                          
                     }               
                })
                .catch((error) => {
                    if (error.response) {
                        this.showMessage(this.$t('Clinical.ClinicalProgramFilter'),error,"error");
                    }
                });  
    },

   /* getTeamsMember(){
       ClinicalService.getTeamsMembers()
                .then((response) => {
                     if(response.status == '200' && response.data && response.data.length > 0){
                       this.ClinicalFilter.TeamMember = response.data;
                     }               
                })
                .catch((error) => {
                    if (error.response) {
                        this.showMessage(this.$t('Clinical.ClinicalTeamMemberFilter'),error,"error");
                    }
                });  
    },*/


    GotoClinicalDashboard(item){
        this.OptionsRightMenu.visible = true;
        this.OptionsRightMenu.btnvisible = true;
        this.OptionsRightMenu.ItemsList = EnumService.ClinicalItems
        
        let layoutStatus = this.$store.state.layoutStatus;
      
        switch(layoutStatus) {
          case 'L0R0':
              this.$store.commit('setlayoutStatus', 'L0R1');
          break;
          default:
              this.$store.commit('setlayoutStatus', 'L1R1');
        }
        this.$emit('getVisibleMenu', this.OptionsRightMenu);   
        this.$router.push({ name: "Clinical-Dashboard", params: { id: item.patientCode,recordid:item.recordId } });       
        
    },

    unselectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.unselectRow(2)
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

    destroyed(){
      this.OptionsRightMenu.ItemsList = [];
      this.OptionsRightMenu.Mode = false;
      this.OptionsRightMenu.visible = false; 
      this.$emit('getVisibleMenu', this.OptionsRightMenu);
    }
    }
};
</script>

<style src="@/assets/css/clinical/multiselect.css">
@import "../../../assets/css/flex.css";
</style>



