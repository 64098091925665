import axios from 'axios'


const ShippingAPIS = {

    getCityStateLookup(zipCode, zipCodeApiUrl, zipCodeApiUser) {
        const requestData = {
            method: 'get',
            url: zipCodeApiUrl,
            headers: {},
            params: { 
                API: 'CityStateLookup',
                XML: `<CityStateLookupRequest USERID="${zipCodeApiUser}"><ZipCode ID= "0"><Zip5>${zipCode}</Zip5></ZipCode></CityStateLookupRequest>`,          
            }
          }
          return axios(requestData);
    },
}

export default ShippingAPIS
