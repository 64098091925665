import ApiService from '@/services/api.service'


const UserAssignmentToCalResService = {
    getAll(parameter) { 
         
      const requestData = {
        method: 'get',
        url: "/UserAssignmentToCalRes/",
        headers: {
        },
        params: { 
          UserID: parameter.UserID,
        }
      }     
      return ApiService.customRequest(requestData);         
    },
  }
  
  export default UserAssignmentToCalResService;