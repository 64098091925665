import ApiService from '@/services/api.service'

const PlaceOfServiceService = {
    getAll( parameter) { 
      const requestData = {
        method: 'get',
        url: "/PlaceOfServices/",
        headers: {
        },
        params: { 
          SearchCriteria: parameter.SearchCriteria,
          PageSize: parameter.PageSize,          
          PageNumber : parameter.PageNumber,
          PatientCode : parameter.PatientCode
        }
      }     
      return ApiService.customRequest(requestData);         
    },

    getPlaceOfServiceByCode(parameter){
      const requestData = {
        method: 'get',
        url: "/PlaceOfServices/",
        headers: {
        },
        params: {          
          code: parameter.code           
        }
      }     
      return ApiService.customRequest(requestData);       
    },
  
    get(Code) {
      return ApiService.get(`/PlaceOfServices/${Code}`);
    },

    AddPlaceOfService(data){
      return ApiService.post(`/PlaceOfServices`, data);
    },
    DeletePlaceOfService(Id){      
      return ApiService.delete(`/PlaceOfServices/${Id}`);
    },

    UpdatePlaceOfService(Id,data){
      return ApiService.put(`/PlaceOfServices/${Id}`,data);
    },  


    addAllergy(data){
      return ApiService.post(`/PlaceOfServices`,data)
    },

    deleteAllergy(Code){
      return ApiService.delete(`/PlaceOfServices/${Code}`)
    },

    update(Code,Allergy){
      return ApiService.put(`/PatientAllergies/${Code}`,Allergy)
    }
}
  export default PlaceOfServiceService;