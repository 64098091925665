<template>
  <div>
    <b-modal :id="ModalId" size="md" :no-close-on-backdrop="true" :no-close-on-esc="true" scrollable centered :visible="true" :title="TitleWindow" @ok="this.emitEventOK" @hide="this.emitEventHide"> 
         <div class="mt-3">
          <MdTitle v-bind:Text="this.$t('MdPatientDiagCodes.Diagnoses')"/>
         </div>
      <b-row>
        <b-col lg="6" md="6" sm="12">
            <MdLookup
               v-bind:Label="$t('MdPatientDiagCodes.Diagnosis1')"
               v-bind:ModelCode="itemsDiagnosis.diagcode1"
               v-bind:ModelDescription="itemsDiagnosis.diagCode1Description"
               v-bind:RefCode="'Diagnosis1'"
               v-bind:Api="'DiagnosisICD10Providers'"
               v-bind:Fields="BaseFields"
               v-bind:FieldCode="'code'"
               v-bind:FieldDescription="'name'"
               :SearchTitle="$t('MdPatientDiagCodes.Diagnosis')"
               :GetValues="onCodeConditionOk"
               :InvalidFeedback="$t('MdPatientDiagCodes.InvalidCodeMsg')"
               :SearchFieldCode="'Code'"                     
            />
        </b-col>
        <b-col lg="6" md="6" sm="12">
            <MdLookup
               v-bind:Label="$t('MdPatientDiagCodes.Diagnosis2')"
               v-bind:ModelCode="itemsDiagnosis.diagcode2"
               v-bind:ModelDescription="itemsDiagnosis.diagCode2Description"
               v-bind:RefCode="'Diagnosis2'"
               v-bind:Api="'DiagnosisICD10Providers'"
               v-bind:Fields="BaseFields"
               v-bind:FieldCode="'code'"
               v-bind:FieldDescription="'name'"
               :SearchTitle="$t('MdPatientDiagCodes.Diagnosis')"
               :GetValues="onCodeConditionOk"
               :InvalidFeedback="$t('MdPatientDiagCodes.InvalidCodeMsg')"
               :SearchFieldCode="'Code'"                     
            />
        </b-col>
      </b-row>
      <b-row>
         <b-col lg="6" md="6" sm="12">
            <MdLookup
               v-bind:Label="$t('MdPatientDiagCodes.Diagnosis3')"
               v-bind:ModelCode="itemsDiagnosis.diagcode3"
               v-bind:ModelDescription="itemsDiagnosis.diagCode3Description"
               v-bind:RefCode="'Diagnosis3'"
               v-bind:Api="'DiagnosisICD10Providers'"
               v-bind:Fields="BaseFields"
               v-bind:FieldCode="'code'"
               v-bind:FieldDescription="'name'"
               :SearchTitle="$t('MdPatientDiagCodes.Diagnosis')"
               :GetValues="onCodeConditionOk"
               :InvalidFeedback="$t('MdPatientDiagCodes.InvalidCodeMsg')"
               :SearchFieldCode="'Code'"                     
            />
        </b-col>
        <b-col lg="6" md="6" sm="12">
            <MdLookup
               v-bind:Label="$t('MdPatientDiagCodes.Diagnosis4')"
               v-bind:ModelCode="itemsDiagnosis.diagcode4"
               v-bind:ModelDescription="itemsDiagnosis.diagCode4Description"
               v-bind:RefCode="'Diagnosis4'"
               v-bind:Api="'DiagnosisICD10Providers'"
               v-bind:Fields="BaseFields"
               v-bind:FieldCode="'code'"
               v-bind:FieldDescription="'name'"
               :SearchTitle="$t('MdPatientDiagCodes.Diagnosis')"
               :GetValues="onCodeConditionOk"
               :InvalidFeedback="$t('MdPatientDiagCodes.InvalidCodeMsg')"
               :SearchFieldCode="'Code'"                     
            />
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="6" md="6" sm="12">
            <MdLookup
               v-bind:Label="$t('MdPatientDiagCodes.Diagnosis5')"
               v-bind:ModelCode="itemsDiagnosis.diagcode5"
               v-bind:ModelDescription="itemsDiagnosis.diagCode5Description"
               v-bind:RefCode="'Diagnosis5'"
               v-bind:Api="'DiagnosisICD10Providers'"
               v-bind:Fields="BaseFields"
               v-bind:FieldCode="'code'"
               v-bind:FieldDescription="'name'"
               :SearchTitle="$t('MdPatientDiagCodes.Diagnosis')"
               :GetValues="onCodeConditionOk"
               :InvalidFeedback="$t('MdPatientDiagCodes.InvalidCodeMsg')"
               :SearchFieldCode="'Code'"                     
            />
        </b-col>
        <b-col lg="6" md="6" sm="12">
            <MdLookup
               v-bind:Label="$t('MdPatientDiagCodes.Diagnosis6')"
               v-bind:ModelCode="itemsDiagnosis.diagcode6"
               v-bind:ModelDescription="itemsDiagnosis.diagCode6Description"
               v-bind:RefCode="'Diagnosis6'"
               v-bind:Api="'DiagnosisICD10Providers'"
               v-bind:Fields="BaseFields"
               v-bind:FieldCode="'code'"
               v-bind:FieldDescription="'name'"
               :SearchTitle="$t('MdPatientDiagCodes.Diagnosis')"
               :GetValues="onCodeConditionOk"
               :InvalidFeedback="$t('MdPatientDiagCodes.InvalidCodeMsg')"
               :SearchFieldCode="'Code'"                     
            />
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="6" md="6" sm="12">
            <MdLookup
               v-bind:Label="$t('MdPatientDiagCodes.Diagnosis7')"
               v-bind:ModelCode="itemsDiagnosis.diagcode7"
               v-bind:ModelDescription="itemsDiagnosis.diagCode7Description"
               v-bind:RefCode="'Diagnosis7'"
               v-bind:Api="'DiagnosisICD10Providers'"
               v-bind:Fields="BaseFields"
               v-bind:FieldCode="'code'"
               v-bind:FieldDescription="'name'"
               :SearchTitle="$t('MdPatientDiagCodes.Diagnosis')"
               :GetValues="onCodeConditionOk"
               :InvalidFeedback="$t('MdPatientDiagCodes.InvalidCodeMsg')"
               :SearchFieldCode="'Code'"                     
            />
        </b-col>
        <b-col lg="6" md="6" sm="12">
            <MdLookup
               v-bind:Label="$t('MdPatientDiagCodes.Diagnosis8')"
               v-bind:ModelCode="itemsDiagnosis.diagcode8"
               v-bind:ModelDescription="itemsDiagnosis.diagCode8Description"
               v-bind:RefCode="'Diagnosis8'"
               v-bind:Api="'DiagnosisICD10Providers'"
               v-bind:Fields="BaseFields"
               v-bind:FieldCode="'code'"
               v-bind:FieldDescription="'name'"
               :SearchTitle="$t('MdPatientDiagCodes.Diagnosis')"
               :GetValues="onCodeConditionOk"
               :InvalidFeedback="$t('MdPatientDiagCodes.InvalidCodeMsg')"
               :SearchFieldCode="'Code'"                     
            />
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="6" md="6" sm="12">
            <MdLookup
               v-bind:Label="$t('MdPatientDiagCodes.Diagnosis9')"
               v-bind:ModelCode="itemsDiagnosis.diagcode9"
               v-bind:ModelDescription="itemsDiagnosis.diagCode9Description"
               v-bind:RefCode="'Diagnosis9'"
               v-bind:Api="'DiagnosisICD10Providers'"
               v-bind:Fields="BaseFields"
               v-bind:FieldCode="'code'"
               v-bind:FieldDescription="'name'"
               :SearchTitle="$t('MdPatientDiagCodes.Diagnosis')"
               :GetValues="onCodeConditionOk"
               :InvalidFeedback="$t('MdPatientDiagCodes.InvalidCodeMsg')"
               :SearchFieldCode="'Code'"                     
            />
        </b-col>
        <b-col lg="6" md="6" sm="12">
            <MdLookup
               v-bind:Label="$t('MdPatientDiagCodes.Diagnosis10')"
               v-bind:ModelCode="itemsDiagnosis.diagcode10"
               v-bind:ModelDescription="itemsDiagnosis.diagCode10Description"
               v-bind:RefCode="'Diagnosis10'"
               v-bind:Api="'DiagnosisICD10Providers'"
               v-bind:Fields="BaseFields"
               v-bind:FieldCode="'code'"
               v-bind:FieldDescription="'name'"
               :SearchTitle="$t('MdPatientDiagCodes.Diagnosis')"
               :GetValues="onCodeConditionOk"
               :InvalidFeedback="$t('MdPatientDiagCodes.InvalidCodeMsg')"
               :SearchFieldCode="'Code'"                     
            />
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="6" md="6" sm="12">
            <MdLookup
               v-bind:Label="$t('MdPatientDiagCodes.Diagnosis11')"
               v-bind:ModelCode="itemsDiagnosis.diagcode11"
               v-bind:ModelDescription="itemsDiagnosis.diagCode11Description"
               v-bind:RefCode="'Diagnosis11'"
               v-bind:Api="'DiagnosisICD10Providers'"
               v-bind:Fields="BaseFields"
               v-bind:FieldCode="'code'"
               v-bind:FieldDescription="'name'"
               :SearchTitle="$t('MdPatientDiagCodes.Diagnosis')"
               :GetValues="onCodeConditionOk"
               :InvalidFeedback="$t('MdPatientDiagCodes.InvalidCodeMsg')"
               :SearchFieldCode="'Code'"                     
            />
        </b-col>
        <b-col lg="6" md="6" sm="12">
            <MdLookup
               v-bind:Label="$t('MdPatientDiagCodes.Diagnosis12')"
               v-bind:ModelCode="itemsDiagnosis.diagcode12"
               v-bind:ModelDescription="itemsDiagnosis.diagCode12Description"
               v-bind:RefCode="'Diagnosis12'"
               v-bind:Api="'DiagnosisICD10Providers'"
               v-bind:Fields="BaseFields"
               v-bind:FieldCode="'code'"
               v-bind:FieldDescription="'name'"
               :SearchTitle="$t('MdPatientDiagCodes.Diagnosis')"
               :GetValues="onCodeConditionOk"
               :InvalidFeedback="$t('MdPatientDiagCodes.InvalidCodeMsg')"
               :SearchFieldCode="'Code'"                     
            />
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="6" md="6" sm="12">
            <MdLookup
               v-bind:Label="$t('MdPatientDiagCodes.Diagnosis13')"
               v-bind:ModelCode="itemsDiagnosis.diagcode13"
               v-bind:ModelDescription="itemsDiagnosis.diagCode13Description"
               v-bind:RefCode="'Diagnosis13'"
               v-bind:Api="'DiagnosisICD10Providers'"
               v-bind:Fields="BaseFields"
               v-bind:FieldCode="'code'"
               v-bind:FieldDescription="'name'"
               :SearchTitle="$t('MdPatientDiagCodes.Diagnosis')"
               :GetValues="onCodeConditionOk"
               :InvalidFeedback="$t('MdPatientDiagCodes.InvalidCodeMsg')"
               :SearchFieldCode="'Code'"                     
            />
        </b-col>
        <b-col lg="6" md="6" sm="12">
            <MdLookup
               v-bind:Label="$t('MdPatientDiagCodes.Diagnosis14')"
               v-bind:ModelCode="itemsDiagnosis.diagcode14"
               v-bind:ModelDescription="itemsDiagnosis.diagCode14Description"
               v-bind:RefCode="'Diagnosis14'"
               v-bind:Api="'DiagnosisICD10Providers'"
               v-bind:Fields="BaseFields"
               v-bind:FieldCode="'code'"
               v-bind:FieldDescription="'name'"
               :SearchTitle="$t('MdPatientDiagCodes.Diagnosis')"
               :GetValues="onCodeConditionOk"
               :InvalidFeedback="$t('MdPatientDiagCodes.InvalidCodeMsg')"
               :SearchFieldCode="'Code'"                     
            />
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="6" md="6" sm="12">
            <MdLookup
               v-bind:Label="$t('MdPatientDiagCodes.Diagnosis15')"
               v-bind:ModelCode="itemsDiagnosis.diagcode15"
               v-bind:ModelDescription="itemsDiagnosis.diagCode15Description"
               v-bind:RefCode="'Diagnosis15'"
               v-bind:Api="'DiagnosisICD10Providers'"
               v-bind:Fields="BaseFields"
               v-bind:FieldCode="'code'"
               v-bind:FieldDescription="'name'"
               :SearchTitle="$t('MdPatientDiagCodes.Diagnosis')"
               :GetValues="onCodeConditionOk"
               :InvalidFeedback="$t('MdPatientDiagCodes.InvalidCodeMsg')"
               :SearchFieldCode="'Code'"                     
            />
        </b-col>
        <b-col lg="6" md="6" sm="12">
            <MdLookup
               v-bind:Label="$t('MdPatientDiagCodes.Diagnosis16')"
               v-bind:ModelCode="itemsDiagnosis.diagcode16"
               v-bind:ModelDescription="itemsDiagnosis.diagCode16Description"
               v-bind:RefCode="'Diagnosis16'"
               v-bind:Api="'DiagnosisICD10Providers'"
               v-bind:Fields="BaseFields"
               v-bind:FieldCode="'code'"
               v-bind:FieldDescription="'name'"
               :SearchTitle="$t('MdPatientDiagCodes.Diagnosis')"
               :GetValues="onCodeConditionOk"
               :InvalidFeedback="$t('MdPatientDiagCodes.InvalidCodeMsg')"
               :SearchFieldCode="'Code'"                     
            />
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>

import ShowMessage from '@/components/messages/ShowMessage.js'
import PatientService from '@/services/patient.service' 
import DiagnosisService from '@/services/diagnosis.service'
import { EventBus } from '@/event-bus.js';
export default {
    mixins: [ShowMessage],
    props: {
     ModalId: String,
     PatientCode:String,
     TitleWindow: String,
     DateFrom:String
   },
    data() {
      return {           
        BaseFields:[{ key: 'name', sortable: true, label: this.$t('Shared.Description') },{ key: 'code', sortable: true, label: this.$t('Shared.Code')}],
        itemsDiagnosis:{
    "recordId": this.$route.params.recordid,
    "patientCode":this.$route.params.id,
    "programName": "",
    "dateFrom": this.DateFrom ? this.DateFrom : null,
    "dateTo": null,
    "diagcode1": "",
    "diagcode2": "",
    "diagcode3": "",
    "diagcode4": "",
    "diagcode5": "",
    "diagcode6": "",
    "diagcode7": "",
    "diagcode8": "",
    "diagcode9": "",
    "diagcode10": "",
    "diagcode11": "",
    "diagcode12": "",
    "diagcode13": "",
    "diagcode14": "",
    "diagcode15": "",
    "diagcode16": "",
    "diagCode1Description": "",
    "diagCode2Description": "",
    "diagCode3Description": "",
    "diagCode4Description": "",
    "diagCode5Description": "",
    "diagCode6Description": "",
    "diagCode7Description": "",
    "diagCode8Description": "",
    "diagCode9Description": "",
    "diagCode10Description": "",
    "diagCode11Description": "",
    "diagCode12Description": "",
    "diagCode13Description": "",
    "diagCode14Description": "",
    "diagCode15Description": "",
    "diagCode16Description": ""
        },
        
        pendingChanges: false,
        clickOK: false,
        haveDiagnoses: false,
      }
    },
    
    methods:{
      emitEventOK(){ 
        this.clickOK = true;    
        if(this.pendingChanges){
            this.Save();
        }
      },

      emitEventHide(handled){
        if (this.pendingChanges) {
        handled.preventDefault()
        this.$bvModal.msgBoxConfirm(this.$t('Msg.LeavingWithoutSaving'), {
          title: this.$t('MdPatientDiagCodes.Diagnoses'),
          okTitle: this.$t('Shared.Save'),
          cancelTitle: this.$t('Shared.BtnDismiss'),
          footerClass: 'p-2',
          titleClass: 'modal-title modal-type-warning'
        })
          .then(value => {
            if(!value){
              this.$emit('getCancel');
              this.ResetProps();
            }
          })		
        } else {
            if(this.clickOK) {
                this.$emit('getCodeConditionOK', this.getTextResult());
            } else {
                this.$emit('getCancel');
                this.ResetProps();
            }
        }
      },

      ResetProps(){
        for(var i = 1; i <= 16; i++){
            this.itemsDiagnosis['diagCode'+i] = '';
            this.itemsDiagnosis['diagCode'+i+'Description'] = '';              
        }
      },

      getTextResult() {
        let textResult = '';
        for(var i = 1; i <= 16; i++){
         if(this.itemsDiagnosis['diagcode'+i])
          textResult = textResult + this.itemsDiagnosis['diagcode'+i]+ ' - ' + this.itemsDiagnosis['diagCode'+i+'Description']+"\n";
         }
        return textResult
      },

    getData() {
      PatientService.getPatientDiag(this.$route.params.id)
            .then((response) => {
                if(response.status == '200' && response.data){
                    this.itemsDiagnosis = response.data;
                    this.itemsDiagnosis.patientCode = this.$route.params.id;
                    this.itemsDiagnosis.recordId = this.$route.params.recordid;
                    this.itemsDiagnosis.dateFrom = this.DateFrom;
                }
                 this.Loading = false;     
                 this.$emit('load', this.Loading); 
            })
            .catch((error) => {
              if (!error.response) {
                // network error
                this.showMessage(this.$t('MdPatientDiagCodes.Diagnoses'),this.$t('Msg.RetrieveInformation'),"error");
              } else {
                this.showMessage(this.$t('MdPatientDiagCodes.Diagnoses'),error,"error");
              }
              this.Loading = false;
              this.$emit('load', this.Loading);
            });  
    },

    onCodeConditionOk(result){   
            var type = result.FieldName;
            if(type.indexOf('Diagnosis') >= 0)  {
                switch (type) {
                    case "Diagnosis1": 
                        this.itemsDiagnosis.diagcode1 = result.code;   
                        this.itemsDiagnosis.diagCode1Description = result.description;  
                    break;
                    case "Diagnosis2":
                        this.itemsDiagnosis.diagcode2 = result.code;  
                        this.itemsDiagnosis.diagCode2Description = result.description; 
                    break;
                    case "Diagnosis3":
                        this.itemsDiagnosis.diagcode3 = result.code;  
                        this.itemsDiagnosis.diagCode3Description = result.description; 
                    break;
                    case "Diagnosis4":
                        this.itemsDiagnosis.diagcode4 = result.code;  
                        this.itemsDiagnosis.diagCode4Description = result.description; 
                    break;
                    case "Diagnosis5":
                        this.itemsDiagnosis.diagcode5 = result.code;  
                        this.itemsDiagnosis.diagCode5Description = result.description; 
                    break;
                    case "Diagnosis6":
                        this.itemsDiagnosis.diagcode6 = result.code;  
                        this.itemsDiagnosis.diagCode6Description = result.description; 
                    break;
                    case "Diagnosis7":
                        this.itemsDiagnosis.diagcode7 = result.code;  
                        this.itemsDiagnosis.diagCode7Description = result.description; 
                    break;
                    case "Diagnosis8":
                        this.itemsDiagnosis.diagcode8 = result.code;  
                        this.itemsDiagnosis.diagCode8Description = result.description; 
                    break;
                    case "Diagnosis9":
                        this.itemsDiagnosis.diagcode9 = result.code;  
                        this.itemsDiagnosis.diagCode9Description = result.description; 
                    break;
                    case "Diagnosis10":
                        this.itemsDiagnosis.diagcode10 = result.code;  
                        this.itemsDiagnosis.diagCode10Description = result.description; 
                    break;
                    case "Diagnosis11":
                        this.itemsDiagnosis.diagcode11 = result.code;  
                        this.itemsDiagnosis.diagCode11Description = result.description; 
                    break;
                    case "Diagnosis12":
                        this.itemsDiagnosis.diagcode12 = result.code;  
                        this.itemsDiagnosis.diagCode12Description = result.description; 
                    break;
                    case "Diagnosis13":
                        this.itemsDiagnosis.diagcode13 = result.code;  
                        this.itemsDiagnosis.diagCode13Description = result.description; 
                    break;
                    case "Diagnosis14":
                        this.itemsDiagnosis.diagcode14 = result.code;  
                        this.itemsDiagnosis.diagCode14Description = result.description; 
                    break;
                    case "Diagnosis15":
                        this.itemsDiagnosis.diagcode15 = result.code;  
                        this.itemsDiagnosis.diagCode15Description = result.description; 
                    break;
                    default:
                        this.itemsDiagnosis.diagcode16 = result.code;  
                        this.itemsDiagnosis.diagCode16Description = result.description; 
                }
            }
        },

    Insert(){
        this.pendingChanges = false;
            DiagnosisService.Post(this.itemsDiagnosis)
             .then((response) => {
                 if(response.status == '200' && response.data){
                    this.showMessage(this.$t('Msg.Saving'), this.$t('Msg.SavedSuccessfully'), "success");                 
                 }
                  this.$emit('load', false);   
             })
            .catch((error) => {
                 this.$emit('load', false);
                 if (!error.response) {
                   this.showMessage(this.$tc('Shared.Patient'),this.$t('Msg.NotAbleToUpdate'),"error");
                 } else {
                   this.showMessage(this.$tc('Shared.Patient'),error.response.data.errorMessage,"error");
                 }
        }); 
    },

    Update(){
        this.pendingChanges = false;
            DiagnosisService.Put(this.$route.params.recordid,this.itemsDiagnosis)
             .then((response) => {
                 if(response.status == '200' && response.data){
                    this.showMessage(this.$t('Msg.Saving'), this.$t('Msg.SavedSuccessfully'), "success");                 
                 }
                  this.$emit('load', false);   
             })
            .catch((error) => {
                 this.$emit('load', false);
                 if (!error.response) {
                   this.showMessage(this.$tc('Shared.Patient'),this.$t('Msg.NotAbleToUpdate'),"error");
                 } else {
                   this.showMessage(this.$tc('Shared.Patient'),error.response.data.errorMessage,"error");
                 }
        }); 
    },

    getDiagnosesByOutCome() {
      PatientService.getDiagnosesByOutCome(this.$route.params.recordid)
            .then((response) => {
                if(response.status == '200' && response.data && response.data.length > 0 ){
                    this.haveDiagnoses = true
                } else {
                    this.haveDiagnoses = false
                }
                 this.Loading = false;     
                 this.$emit('load', this.Loading); 
            })
            .catch((error) => {
              if (!error.response) {
                // network error
                this.showMessage(this.$t('MdPatientDiagCodes.Diagnoses'),this.$t('Msg.RetrieveInformation'),"error");
              } else {
                this.showMessage(this.$t('MdPatientDiagCodes.Diagnoses'),error,"error");
              }
              this.Loading = false;
              this.$emit('load', this.Loading);
            });
    },

    Save(){
      this.haveDiagnoses ? this.Update() : this.Insert()
    },

    beforeWindowUnload(e) {
        if (this.pendingChanges) {
				e.preventDefault()
				e.returnValue = ''	
			}
		}
    },

  mounted() {
    this.getData();
    this.getDiagnosesByOutCome()
  },

  created () {
       EventBus.$on("onChanged", function (e) {       
          this.pendingChanges = e.Changed
     }.bind(this));
     window.addEventListener('beforeunload', this.beforeWindowUnload)
    },

    beforeDestroy () {
      window.removeEventListener('beforeunload', this.beforeWindowUnload)
    },
}

</script>