import ApiService from '@/services/api.service'

const AdmissionService = {
    
    getAdmissionSources(ModeEdit){        
        return ModeEdit ? ApiService.get(`/AdmissionSources?ShowOnlyActive=false&SourceType=Admission`) : ApiService.get(`/AdmissionSources?ShowOnlyActive=true&SourceType=Admission`);
    },

    getAdmissionTypes(){
        return ApiService.get(`/AdmissionTypes?AdmissionType=Admission`); 
    },

    getAdmissionClasses(){
        return ApiService.get(`/AdmissionClasses`); 
    },

    getAdmissionClassesByCode(Code){
        return ApiService.get(`/AdmissionClasses/${Code}`); 
    },

    getVocationalCategory(){
        return ApiService.get(`/AdmissionVocations`); 
    },

    getVocationalCategoryByCode(Code){
        return ApiService.get(`/AdmissionVocations/${Code}`); 
    },

    getStatus(){
        return ApiService.get(`/AdmissionStatuses?StatusForNewAdmission=true`); 
    },
    getStatusDischarge(){
        return ApiService.get(`/AdmissionStatuses?AdmissionStatus=Discharge`);        
    },
    getStatusByCode(Code){
        return ApiService.get(`/AdmissionStatuses/${Code}`); 
    },

    getVocationalEffort(){
        return ApiService.get(`/AdmissionVocationEfforts`); 
    },

    getVocationalEffortByCode(Code){
        return ApiService.get(`/AdmissionVocationEfforts/${Code}`); 
    },

    getAdmissionFrom(){
        return ApiService.get(`/AdmissionFroms`);     
    },

    getAdmissionFromByCode(Code){
        return ApiService.get(`/AdmissionFroms/${Code}`);     
    },

    getLivingWith(){
        return ApiService.get(`/AdmissionLivingWiths`); 
    },

    getLivingWithByCode(Code){
        return ApiService.get(`/AdmissionLivingWiths/${Code}`); 
    },

    getAssessment(){
        return ApiService.get(`/AdmissionAssessments?AdmissionAssessment=Admission`); 
    },

    getAssessmentByCode(Code){
        return ApiService.get(`/AdmissionAssessments/${Code}`); 
    },

    getTrack(){
        return ApiService.get(`/ProviderCodes?CodeType=TRACK`); 
    },

    getReferrals(){
        return ApiService.get(`/Referrals`); 
    },

    getProgramsByCode(Code){
        return ApiService.get(`/Programs/${Code}`); 
    },

    getBuildings(){
        return ApiService.get(`/Buildings`);
    },

    getRooms(Buildingcode){
        return ApiService.get(`/Rooms?Buildingcode=${Buildingcode}`);
    },
   
    getListingTeamMember(parameter){
        const requestData = {
            method: 'get',
            url: "/AdmissionTeams/",
            headers: {
              
            },
            params: {
              SearchCriteria: parameter.SearchCriteria,
              PageSize: parameter.PageSize,
              PageNumber: parameter.PageNumber,           
              AdmissionRecordId:parameter.AdmissionRecordId
            }
          }
          return ApiService.customRequest(requestData);
    },

    AddAdmission(admission){
        return ApiService.post(`/ProgramAdmissions`, admission);
    },

    DeleteAndInsert(admission){
        return ApiService.post(`/AdmissionDiagnoses/DeleteAndInsert`, admission);
    },

    DeleteAdmission(RecordId){      
        return ApiService.delete(`/ProgramAdmissions/${RecordId}`);
    },

    UpdateAdmission(RecordId,Admission){
        return ApiService.put(`/ProgramAdmissions/${RecordId}`,Admission);
    },

    GetAdmission(RecordId){     
        return ApiService.get(`/ProgramAdmissions/${RecordId}`);
    },
    getAssessmentDischarge(){
        return ApiService.get(`/AdmissionAssessments?AdmissionAssessment=Discharge`); 
    },
    
  
    
  }
  
  export default AdmissionService;