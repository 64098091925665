<template>
    <div id="ContainerAddPatient">
      <div class="row page-height">
            <div class="col-12">
                <!--<MdTitle v-if="this.edit_mode && this.CountSave >= 0"
                   v-bind:Text="'PATIENT INFO - ' + patient.firstname +' '+ patient.lastname + ' - ' + patient.patientcode"
                   v-bind:Name="'IdPatient'"
                />
                <MdTitle v-if="!this.edit_mode && this.CountSave === 0"
                   v-bind:Text="'Add Patient'"
                />-->

                <h4 class="page-title text-truncate" v-if="this.edit_mode && this.CountSave >= 0">
					{{ $t('Patient.patientInfo') }}  - 
                <span v-if="this.PatientTitle.fullName">{{this.PatientTitle.fullName + ' - '}}</span>
                <span v-if="this.$route.params.id">{{this.$route.params.id}}</span>
                <span v-if="this.PatientTitle.dob"> - {{ $t('Shared.Dob') }} {{this.PatientTitle.dob}}</span>
                <span v-if="this.PatientTitle.ageFormatted"> {{ $t('Shared.Age') }} {{this.PatientTitle.ageFormatted}}</span>
                <span v-if="this.PatientTitle.programName"> - {{this.PatientTitle.programName}}  </span>
                <span v-if="this.PatientTitle.dateFrom">- {{this.PatientTitle.dateFrom}}  </span>
                <span v-if="this.PatientTitle.dateTo">- {{this.PatientTitle.dateTo}}  </span>
                <span v-else v-show="IsClinical">- {{ $t('Shared.Active') }}  </span>
				</h4>

                <h4 class="page-title text-truncate" v-if="!this.edit_mode && this.CountSave === 0">
                    {{ $t('PatientsList.addPatient') }}
                </h4>
                    <div class="row">
						<div class="col-md-12 mx-auto">
                            <!-- Tab -->
                        <MdTabList v-bind:TabList="tablist" v-bind:ShowTabList="false">
                        <div class="tab-content tab-content-custom-pill" id="pills-tabContent-custom">
                          <div class="tab-pane fade show active" id="contentGeneral" role="tabpanel">
                             <b-row>
                   <b-col lg="3" md="3" sm="12">             
                            <MdEdit 
                                 v-bind:Label="$t('Shared.PatientCode')"
                                 v-bind:TypeProp="'text'" 
                                 v-bind:Name="'patientcode'"
                                 v-bind:MaxLengthProp="10"
                                 v-model="patient.patientcode"
                                 v-bind:isReadOnly="autoAssignCode"
                                 v-bind:isValid="this.isAutoAssignCodeValid"
                                 v-bind:Id="'input_patient_code'">                                     
                            </MdEdit>
                    </b-col>
                    <b-col lg="3" md="3" sm="12">
                    <MdSelect
                        v-bind:Label="$t('Patient.Title')"
                        MaxLength="3"
                        v-bind:Options="title_list"
                        v-bind:FieldDescription="'text'"
                        v-bind:FieldCode="'value'"
                        v-bind:Model="patient.title"                       
                        v-bind:Multiple="false"
                        :IsDisabled=this.isReadOnly
                        :Input="ChangeDropDownTitle"
                        />
                    </b-col>
                    <b-col lg="3" md="3" sm="12">
                            <MdEdit 
                                 v-bind:Label="$t('Shared.FirstName')"
                                 v-bind:TypeProp="'text'" 
                                 v-bind:Name="'firstname'"
                                 v-bind:MaxLengthProp="50"
                                 v-bind:isRequired="true"
                                 v-model="patient.firstname"
                                 v-bind:isValid="this.isFirstNameValid"
                                 v-bind:isReadOnly="this.isReadOnly"
                                 v-bind:Id="'input_patient_first_name'">                                     
                            </MdEdit>
                    </b-col>
                    <b-col lg="3" md="3" sm="12">
                            <MdEdit 
                                 v-bind:Label="$t('Patient.Initial')"
                                 v-bind:TypeProp="'text'" 
                                 v-bind:Name="'Initial'"
                                 v-bind:MaxLengthProp="1"
                                 v-model="patient.initial"
                                 v-bind:isReadOnly=this.isReadOnly
                                 v-bind:Id="'input_patien_initial_id'">                                     
                            </MdEdit>
                    </b-col>
                             </b-row>
                             <b-row>
                   <b-col lg="3" md="3" sm="12">             
                            <MdEdit 
                                 v-bind:Label="$t('Shared.LastName')"
                                 v-bind:TypeProp="'text'" 
                                 v-bind:Name="'lastname'"
                                 v-bind:MaxLengthProp="50"
                                 v-bind:isRequired="true"
                                 v-model="patient.lastname"
                                 v-bind:isValid="this.isLastNameValid"
                                 v-bind:isReadOnly="this.isReadOnly"
                                 v-bind:Id="'input_patient_last_name'">                                     
                            </MdEdit>
                    </b-col>
                    <b-col lg="3" md="3" sm="12">
                            <MdEdit 
                                 v-bind:Label="$t('Patient.Sufix')"
                                 v-bind:TypeProp="'text'" 
                                 v-bind:Name="'Sufix'"
                                 v-bind:MaxLengthProp="4"
                                 v-model="patient.suffix"
                                 v-bind:isReadOnly=this.isReadOnly
                                 v-bind:Id="'input_patient_sufix'">                                     
                            </MdEdit>
                    </b-col>
                    <b-col lg="3" md="3" sm="12">
                            <MdEdit 
                                 v-bind:Label="$t('Patient.NickName')"
                                 v-bind:TypeProp="'text'" 
                                 v-bind:Name="'nickname'"
                                 v-bind:MaxLengthProp="100"
                                 v-model="patient.nickname"
                                 v-bind:isReadOnly=this.isReadOnly
                                 v-bind:Id="'input_patient_nick'">                                     
                            </MdEdit>
                    </b-col>
                    <b-col lg="3" md="3" sm="12">
                            <MdEdit 
                                 v-bind:Label="$t('Shared.Address1')"
                                 v-bind:TypeProp="'text'" 
                                 v-bind:Name="'address1'"
                                 v-bind:MaxLengthProp="30"
                                 v-model="patient.address1"
                                 v-bind:isReadOnly=this.isReadOnly
                                 v-bind:Id="'input_patient_addr1'">                                     
                            </MdEdit>
                    </b-col>
                    </b-row>
                    <b-row>
                   <b-col lg="3" md="3" sm="12">             
                            <MdEdit 
                                 v-bind:Label="$t('Shared.Address2')"
                                 v-bind:TypeProp="'text'" 
                                 v-bind:Name="'address2'"
                                 v-bind:MaxLengthProp="30"
                                 v-model="patient.address2"
                                 v-bind:isReadOnly=this.isReadOnly
                                 v-bind:Id="'input_patient_addr2'">                                     
                            </MdEdit>
                    </b-col>
                    <b-col lg="3" md="3" sm="12">
                            <MdEdit 
                                 v-bind:Label="$t('Shared.Zip')"
                                 v-bind:TypeProp="'text'" 
                                 v-bind:Name="'zip'"
                                 v-bind:MaxLengthProp="10"
                                 v-model="patient.zip"
                                 v-bind:isReadOnly=this.isReadOnly
                                 v-on:blur="patientZipBlur(patient.zip)"
                                 v-on:change="patientZipChange($event)"
                                 v-bind:Id="'input_patient_zip'">                                     
                            </MdEdit>
                    </b-col>
                    <b-col lg="3" md="3" sm="12">
                            <MdEdit 
                                 v-bind:Label="$t('Shared.City')"
                                 v-bind:TypeProp="'text'" 
                                 v-bind:Name="'city'"
                                 v-bind:MaxLengthProp="25"
                                 v-model="patient.city"
                                 v-bind:isReadOnly=this.isReadOnly
                                 v-bind:Id="'input_patient_city'">                                     
                            </MdEdit>
                    </b-col>
                    <b-col lg="3" md="3" sm="12">
                            <MdEdit 
                                 v-bind:Label="$t('Shared.State')"
                                 v-bind:TypeProp="'text'" 
                                 v-bind:Name="'state'"
                                 v-bind:MaxLengthProp="2"
                                 v-model="patient.state"
                                 v-bind:isReadOnly=this.isReadOnly
                                 v-bind:Id="'input_patient_state'">                                     
                            </MdEdit>
                    </b-col>
                             </b-row>
                             <b-row>
                   <b-col lg="3" md="3" sm="12">             
                            <MdEdit 
                                 v-bind:Label="$t('Shared.Country')"
                                 v-bind:TypeProp="'text'" 
                                 v-bind:Name="'country'"
                                 v-bind:MaxLengthProp="25"
                                 v-model="patient.country"
                                 v-bind:isReadOnly=this.isReadOnly
                                 v-bind:Id="'input_patient_country'">                                     
                            </MdEdit>
                    </b-col>
                    <b-col lg="3" md="3" sm="12">
                            <MdEdit 
                                 v-bind:Label="$t('Patient.SSN')"
                                 v-bind:TypeProp="'text'" 
                                 v-bind:Name="'ssn'"
                                 v-bind:MaxLengthProp="11"
                                 v-model="patient.ssn"
                                 v-bind:isReadOnly=this.isReadOnly
                                 v-bind:Mask="'###-##-####'"
                                 v-bind:Id="'input_patient_ssn'">                                     
                            </MdEdit>
                    </b-col>
                       <b-col lg="3" md="3" sm="12">
                            <MdEdit 
                                 v-bind:Label="$t('Shared.Dob')"
                                 v-bind:TypeProp="'date'" 
                                 v-bind:Name="'dob'"
                                 v-bind:Min="'1900-01-01'"
                                 v-bind:Max="'2200-12-31'"
                                 v-model="patient.dob"
                                 v-bind:isRequired="true"
                                 v-bind:isValid="this.isDOBValid"
                                 v-bind:isReadOnly="this.isReadOnly"
                                 v-bind:Id="'input_patient_date'"
                                 v-on:blur="ChangeDoB">                                     
                            </MdEdit>
                    </b-col>
                    <b-col lg="3" md="3" sm="12">             
                            <MdEdit 
                                 v-bind:Label="$t('Shared.Age')"
                                 v-bind:TypeProp="'text'" 
                                 v-bind:Name="'age'"
                                 v-model="age"
                                 v-bind:isReadOnly="true"
                                 v-bind:Id="'input_patient_age'">                                     
                            </MdEdit>
                    </b-col>
                    </b-row>
                    <b-row>
                    <b-col lg="3" md="3" sm="12">
                            <MdEdit 
                                 v-bind:Label="$t('Patient.PatientId')"
                                 v-bind:TypeProp="'text'" 
                                 v-bind:Name="'patientid'"
                                 v-bind:MaxLengthProp="15"
                                 v-model="patient.patientid"
                                 v-bind:isReadOnly=this.isReadOnly
                                 v-bind:Id="'input_patient_pid'">                                     
                            </MdEdit>
                    </b-col>
                    <b-col lg="3" md="3" sm="12">
                            <MdEdit 
                                 v-bind:Label="$t('Patient.Deceased')"
                                 v-bind:TypeProp="'date'" 
                                 v-bind:Name="'dateDeceased'"
                                 v-bind:Min="'1900-01-01'"
                                 v-bind:Max="'2200-12-31'"
                                 v-model="patient.dateDeceased"
                                 v-bind:isReadOnly=this.isReadOnly>                                     
                            </MdEdit>
                    </b-col>
                    <b-col lg="3" md="3" sm="12">
                            <MdEdit 
                                 v-bind:Label="$t('Patient.ReasonOfDeath')" 
                                 v-bind:TypeProp="'text'" 
                                 v-bind:Name="'reasonofdeath'"
                                 v-bind:MaxLengthProp="100"
                                 v-model="patient.deathReason"
                                 v-bind:isReadOnly=!patient.dateDeceased
                                 v-bind:Id="'input_patient_reasondeath'">                                     
                            </MdEdit>
                    </b-col>                    
                             </b-row>
                             <b-row>
                   <b-col lg="3" md="3" sm="12">
                       <MdSelect
                            v-bind:Label="$t('Patient.Sex')"
                            v-bind:Options="sex_list"
                            v-bind:FieldDescription="'text'"
                            v-bind:FieldCode="'value'"
                            v-bind:Model="patient.sex"                       
                            v-bind:Multiple="false"
                            :IsDisabled=this.isReadOnly
                            :Input="ChangeDropDownSex"
                        />
                    </b-col>
                    <b-col lg="3" md="3" sm="12">
                        <MdSelect
                            v-bind:Label="$t('Shared.Gender')"
                            v-bind:Options="gender_list"
                            v-bind:FieldDescription="'description'"
                            v-bind:FieldCode="'code'"
                            v-bind:Model="patient.gender"                       
                            v-bind:Multiple="false"
                            :IsDisabled=this.isReadOnly
                            :Input="ChangeDropDownGender"
                        />
                    </b-col>
                    <b-col lg="3" md="3" sm="12">
                       <MdSelect
                            v-bind:Label="$t('Patient.Ethnicity')"
                            v-bind:Options="ethnicity_list"
                            v-bind:FieldDescription="'description'"
                            v-bind:FieldCode="'code'"
                            v-bind:Model="patient.ethnicityCode"                       
                            v-bind:Multiple="false"
                            :IsDisabled=this.isReadOnly
                            :Input="ChangeDropDownEthnicity"
                        />
                    </b-col>
                    <b-col lg="3" md="3" sm="12">
                        <MdSelect
                            v-bind:Label="$t('Patient.Race')"
                            data-field="AdditionalInformation_Race"
                            v-bind:Options="race_list"
                            v-bind:FieldDescription="'description'"
                            v-bind:FieldCode="'code'"
                            v-bind:Model="patient.ethcode"                       
                            v-bind:Multiple="false"
                            :IsDisabled=this.isReadOnly
                            :Input="ChangeDropDownRace"
                        />
                    </b-col>
                </b-row>
                <b-row>
                   <b-col lg="3" md="3" sm="12">             
                       <MdSelect
                            v-bind:Label="$t('Patient.EyeColor')"
                            data-field="AdditionalInformation_EyeColor"
                            v-bind:Options="eye_color_list"
                            v-bind:FieldDescription="'description'"
                            v-bind:FieldCode="'code'"
                            v-bind:Model="patient.eyeColor"                       
                            v-bind:Multiple="false"
                            :IsDisabled=this.isReadOnly
                            :Input="ChangeDropDownEyeColor"
                        />
                    </b-col>
                    <b-col lg="3" md="3" sm="12">
                        <MdSelect
                            v-bind:Label="$t('Patient.HairColor')"
                            data-field="AdditionalInformation_HairColor"
                            v-bind:Options="hair_color_list"
                            v-bind:FieldDescription="'description'"
                            v-bind:FieldCode="'code'"
                            v-bind:Model="patient.hairColor"                       
                            v-bind:Multiple="false"
                            :IsDisabled=this.isReadOnly
                            :Input="ChangeDropDownHairColor"
                        />
                    </b-col>
                    <b-col lg="3" md="3" sm="12">
                            <MdEdit 
                                 v-bind:Label="$t('Patient.Weight')"
                                 v-bind:TypeProp="'number'" 
                                 v-bind:Name="'weight'"
                                 v-bind:Min="'0'"
                                 v-bind:StepProp="'1'"
                                 v-model="patient.weight"
                                 v-bind:isReadOnly=this.isReadOnly
                                 @keypress="onlyFloat">                                     
                            </MdEdit>
                    </b-col>
                    <b-col lg="3" md="3" sm="12">
                            <MdEdit 
                                 v-bind:Label="$t('Patient.Height')"
                                 v-bind:TypeProp="'number'" 
                                 v-bind:Name="'heights'"
                                 v-bind:Min="'0'"
                                 v-bind:StepProp="'1'"
                                 v-model="patient.heights"
                                 v-bind:isReadOnly=this.isReadOnly
                                 @keypress="onlyFloat">                                     
                            </MdEdit>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col lg="3" md="3" sm="12">             
                       <MdSelect
                            v-bind:Label="$t('Patient.MaritalStatus')"
                            data-field="AdditionalInformation_MaritalStatus"
                            v-bind:Options="marital_status_list"
                            v-bind:FieldDescription="'text'"
                            v-bind:FieldCode="'value'"
                            v-bind:Model="patient.married"                       
                            v-bind:Multiple="false"
                            :IsDisabled=this.isReadOnly
                            :Input="ChangeDropDownMarried"
                        />
                    </b-col>
                    <b-col lg="3" md="3" sm="12">
                        <MdSelect
                            v-bind:Label="$t('Patient.Employment')"
                            data-field="AdditionalInformation_Employment"
                            v-bind:Options="employment_list"
                            v-bind:FieldDescription="'text'"
                            v-bind:FieldCode="'value'"
                            v-bind:Model="patient.employed"                       
                            v-bind:Multiple="false"
                            :IsDisabled=this.isReadOnly
                            :Input="ChangeDropDownEmployment"
                        />
                    </b-col>
                        <b-col lg="3" md="3" sm="12">
                            <MdEdit 
                                 v-bind:Label="$t('Patient.DateFirstChartOpened')"
                                 v-bind:TypeProp="'date'" 
                                 v-bind:Name="'sgDate'"
                                 v-bind:Min="'1900-01-01'"
                                 v-bind:Max="'2200-12-31'"
                                 v-model="patient.sgDate"
                                 v-bind:isReadOnly=this.isReadOnly>                                     
                            </MdEdit>
                    </b-col>
                    <b-col lg="3" md="3" sm="12">
                        <MdSelect
                            v-bind:Label="$t('Patient.Student')"
                            data-field="AdditionalInformation_Student"
                            v-bind:Options="student_list"
                            v-bind:FieldDescription="'text'"
                            v-bind:FieldCode="'value'"
                            v-bind:Model="patient.student"                       
                            v-bind:Multiple="false"
                            :IsDisabled=this.isReadOnly
                            :Input="ChangeDropDownStudent"
                        />
                    </b-col>                
                </b-row>
                <b-row class="mt-2">
                    <b-col lg="3" md="3" sm="12">             
                        <MdRadioGroup
                            v-bind:Label="$t('Patient.ActiveRecord')"
                            v-model="patient.notActive"
                            v-bind:Options="inactive_recods_list"
                            v-bind:Name="'Active_Record'"
                            v-bind:isReadOnly=this.isReadOnly
                        />
                    </b-col>     
                    <b-col md="3" lg="3" sm="12" v-if="IsVip">
                        <MdCheckBox
                           v-bind:Label="'Is Restricted'"
                           v-bind:Name="'checkbox-IsVip'"
                           v-bind:ClassProp="'AlignCheck'"
                           v-bind:CheckedValue="true"
                           v-bind:UncheckedValue="false"
                           v-model="patient.isVIP"
                           v-bind:isReadOnly="this.isReadOnly"
                         />
                    </b-col>           
                            </b-row>
                          <MdTitle v-bind:Text="$t('Patient.ContactInformation')"  v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
                            <b-row>
                   <b-col lg="3" md="3" sm="12">             
                            <MdEdit 
                                 v-bind:Label="$t('Shared.DayPhone')"
                                 v-bind:TypeProp="'text'" 
                                 v-bind:Name="'dayphone'"
                                 v-bind:MaxLengthProp="15"
                                 v-model="patient.dayPhone"
                                 v-bind:isReadOnly=this.isReadOnly
                                 v-bind:Id="'input_patient_dayphone'">                                     
                            </MdEdit>
                    </b-col>
                    <b-col lg="3" md="3" sm="12">
                            <MdEdit 
                                 v-bind:Label="$t('Shared.NightPhone')"
                                 v-bind:TypeProp="'text'" 
                                 v-bind:Name="'nightphone'"
                                 v-bind:MaxLengthProp="15"
                                 v-model="patient.nightPhone"
                                 v-bind:isReadOnly=this.isReadOnly
                                 v-bind:Id="'input_patient_nphone'">                                     
                            </MdEdit>
                    </b-col>
                    <b-col lg="3" md="3" sm="12">
                            <MdEdit 
                                 v-bind:Label="$t('Shared.CellPhone')"
                                 v-bind:TypeProp="'text'" 
                                 v-bind:Name="'cellphone'"
                                 v-bind:MaxLengthProp="15"
                                 v-model="patient.cellPhone"
                                 v-bind:isReadOnly=this.isReadOnly
                                 v-bind:Id="'input_patient_cellphone'">                                     
                            </MdEdit>
                    </b-col>
                    <b-col lg="3" md="3" sm="12">
                            <MdEdit 
                                 v-bind:Label="$t('Shared.Email')"
                                 v-bind:TypeProp="'email'" 
                                 v-bind:Name="'email'"
                                 v-bind:MaxLengthProp="100"
                                 v-model="patient.email"
                                 v-bind:isReadOnly=this.isReadOnly
                                 v-bind:isRequired="true"
                                 v-bind:isValid="this.emailValid"
                                 v-bind:Id="'input_patient_email'"
                                >                                     
                            </MdEdit>
                    </b-col>
                </b-row>
                <b-row>
                   <b-col lg="3" md="3" sm="12">              
                       <MdSelect
                            v-bind:Label="$t('Patient.CommunicationPreference')"
                            data-field="ContactInformation_commPreference"
                            v-bind:Options="comm_preference_list"
                            v-bind:FieldDescription="'description'"
                            v-bind:FieldCode="'code'"
                            v-bind:Model="patient.commPreference"                       
                            v-bind:Multiple="false"
                            :IsDisabled=this.isReadOnly
                            :Input="ChangeDropDownCommPreference"
                        />
                    </b-col>
                    <b-col lg="3" md="3" sm="12" >
                        <MdLookup
                          v-bind:Label="$t('Patient.CommunicationLiaison')"
                          v-bind:ModelCode="patient.commLiason"
                          v-bind:ModelDescription="CommunicationLiaison.description"
                          v-bind:RefCode="'CommunicationLiaison'"
                          v-bind:Api="'CommLiason'"
                          v-bind:FieldCode="'code'"
                          v-bind:FieldDescription="'name'"
                          :IsDisabled="this.isReadOnly"    
                          :SearchTitle="$t('Patient.CommunicationLiaison')"
                          :GetValues="onCodeConditionOk"
                          :InvalidFeedback="$t('Patient.InvalidCommunicationLiaisonCode')"
                          :SearchFieldCode="'Code'"                      
                        />
                      </b-col>
                      <b-col lg="3" md="3" sm="12">
                        <MdLookup
                          v-bind:Label="$t('Patient.ReferralFrom')"
                          v-bind:ModelCode="patient.referal"
                          v-bind:ModelDescription="ReferralFrom.description"
                          v-bind:RefCode="'ReferralFrom'"
                          v-bind:Api="'Referrals'"
                          v-bind:FieldCode="'code'"
                          v-bind:FieldDescription="'name'"
                          :IsDisabled="this.isReadOnly"    
                          :SearchTitle="$t('Patient.ReferralFrom')"
                          :GetValues="onCodeConditionOk"
                          :InvalidFeedback="$t('Patient.InvalidReferralFromCode')"
                          :SearchFieldCode="'Code'"                      
                        />
                      </b-col>
                    <b-col lg="3" md="3" sm="12">
                        <MdLookup
                          v-bind:Label="$t('Patient.ReferralTo')"
                          v-bind:ModelCode="patient.referalTo"
                          v-bind:ModelDescription="ReferralTo.description"
                          v-bind:RefCode="'ReferralTo'"
                          v-bind:Api="'Referrals'"
                          v-bind:FieldCode="'code'"
                          v-bind:FieldDescription="'name'"
                          :IsDisabled="this.isReadOnly"    
                          :SearchTitle="$t('Patient.ReferralTo')"
                          :GetValues="onCodeConditionOk"
                          :InvalidFeedback="$t('Patient.InvalidReferralToCode')"
                          :SearchFieldCode="'Code'"                     
                        />
                      </b-col>
                </b-row>
                          </div>
                          <div class="tab-pane fade" id="contentAdditional" role="tabpanel">
                            <b-row>
                   <b-col lg="3" md="3" sm="12">             
                            <MdEdit 
                                 v-bind:Label="$t('Patient.PatientBirthName')"
                                 v-bind:TypeProp="'text'" 
                                 v-bind:Name="'patientbirthname'"
                                 v-bind:MaxLengthProp="15"
                                 v-model="patient.previousFirstName"
                                 v-bind:isReadOnly=this.isReadOnly
                                 v-bind:Id="'input_patient_BirthName'">                                     
                            </MdEdit>
                    </b-col>
                    <b-col lg="3" md="3" sm="12">
                            <MdEdit 
                                 v-bind:Label="$t('Patient.FullMiddleName')"
                                 v-bind:TypeProp="'text'" 
                                 v-bind:Name="'fullMiddleName'"
                                 v-bind:MaxLengthProp="50"
                                 v-model="patient.fullMiddleName"
                                 v-bind:isReadOnly=this.isReadOnly
                                 v-bind:Id="'input_patient_FullMiddleName'">                                     
                            </MdEdit>
                    </b-col>
                    <b-col lg="3" md="3" sm="12">
                            <MdEdit 
                                 v-bind:Label="$t('Patient.MaidenName')"
                                 v-bind:TypeProp="'text'" 
                                 v-bind:Name="'maidenName'"
                                 v-bind:MaxLengthProp="15"
                                 v-model="patient.maidenName"
                                 v-bind:isReadOnly=this.isReadOnly
                                 v-bind:Id="'input_patient_MaidenName'">                                     
                            </MdEdit>
                    </b-col>
                    <b-col lg="3" md="3" sm="12">
                      <MdRadioGroup
                            v-bind:Label="$t('Patient.FemaleHeadOfHouse')"
                            v-model="patient.femaleHeadHousehold"
                            v-bind:Options="YesNoOptions"
                            v-bind:Name="'radio-inlineFemaleHeadofHouse'"
                            v-bind:isReadOnly=this.isReadOnly
                        />
                    </b-col>
                </b-row>
                <b-row>
                   <b-col lg="3" md="3" sm="12">             
                            <MdEdit 
                                 v-bind:Label="$t('Patient.NumberOfDependents')"
                                 v-bind:TypeProp="'number'" 
                                 v-bind:Name="'numberDependents'"
                                 v-bind:Min="'0'"
                                 v-bind:StepProp="'1'"
                                 v-model.number="patient.numberDependents"
                                 v-bind:isReadOnly=this.isReadOnly
                                 @keypress="onlyInteger">                                     
                            </MdEdit>
                    </b-col>
                    <b-col lg="3" md="3" sm="12">
                        <MdSelect
                            v-bind:Label="$t('Patient.FamilyIncome')"
                            data-field="Additional_FamilyIncome"
                            v-bind:Options="family_income_list"
                            v-bind:FieldDescription="'description'"
                            v-bind:FieldCode="'code'"
                            v-bind:Model="patient.familyIncome"                       
                            v-bind:Multiple="false"
                            :IsDisabled=this.isReadOnly
                            :Input="ChangeDropDownFamilyIncome"
                        />
                    </b-col>
                    <b-col lg="3" md="3" sm="12">
                            <MdEdit 
                                 v-bind:Label="$t('Patient.FamilyIncomeAmount')"
                                 v-bind:TypeProp="'text'" 
                                 v-bind:Name="'formattedCurrencyValue'"
                                 v-model="formattedCurrencyValue"
                                 v-on:blur="focusOut"
                                 v-on:focus="focusIn"
                                 v-bind:isReadOnly="patient.familyIncome!='OTHER'"
                                 v-bind:Id="'input_patient_fincamount'"
                                 >                                     
                            </MdEdit>
                    </b-col>
                    <b-col lg="3" md="3" sm="12">
                        <MdSelect
                            v-bind:Label="$t('Patient.PersonalIncome')"
                            data-field="Additional_PersonalIncome"
                            v-bind:Options="personal_income_list"
                            v-bind:FieldDescription="'description'"
                            v-bind:FieldCode="'code'"
                            v-bind:Model="patient.personalIncome"                       
                            v-bind:Multiple="false"
                            :IsDisabled=this.isReadOnly
                            :Input="ChangeDropDownPersonalIncome"
                        />
                    </b-col>
                </b-row>
                <b-row>
                   <b-col lg="3" md="3" sm="12">             
                            <MdEdit 
                                 v-bind:Label="$t('Patient.PersonalIncomeAmount')"
                                 v-bind:TypeProp="'text'" 
                                 v-bind:Name="'formattedCurrencyValue'"
                                 v-model="formattedCurrencyValue1"
                                 v-on:blur="focusOut1"
                                 v-on:focus="focusIn1"
                                 v-bind:isReadOnly="patient.personalIncome!='OTHER'"
                                 v-bind:Id="'input_patient_PersonalIncomeAmount'"
                                 >                                     
                            </MdEdit>
                    </b-col>
                    <b-col lg="3" md="3" sm="12">
                        <MdRadioGroup
                            v-bind:Label="$t('Patient.Homeless')"
                            v-model="patient.homeless"
                            v-bind:Options="YesNoOptions"
                            v-bind:Name="'radio-inline-Homeless'"
                            v-bind:isReadOnly=this.isReadOnly
                        />
                    </b-col> 
                </b-row>
                <b-row>
                    <b-col lg="3" md="3" sm="12">
                        <MdSelect
                            v-bind:Label="$t('Patient.MajorLanguage')"
                            data-field="Additional_MajorLanguage"
                            v-bind:Options="major_language_list"
                            v-bind:FieldDescription="'description'"
                            v-bind:FieldCode="'code'"
                            v-bind:Model="patient.majorLanguage"                       
                            v-bind:Multiple="false"
                            :IsDisabled=this.isReadOnly
                            :Input="ChangeDropDownMajorLanguage"
                        />
                    </b-col>
                        <b-col lg="3" md="3" sm="12">
                        <MdSelect
                            v-bind:Label="$t('Patient.MajorLanguageAbility')" 
                            data-field="Additional_MajorLanguageAbility"
                            v-bind:Options="major_language_ability_list"
                            v-bind:FieldDescription="'description'"
                            v-bind:FieldCode="'code'"
                            v-bind:Model="patient.majorLanguageAbility"                       
                            v-bind:Multiple="false"
                            :IsDisabled=this.isReadOnly
                            :Input="ChangeDropDownMajorLanguageAbility"
                        />
                    </b-col>
                    <b-col lg="3" md="3" sm="12">
                        <MdSelect
                            v-bind:Label="$t('Patient.MajorReligion')"
                            data-field="Additional_MajorReligion"
                            v-bind:Options="major_religion_list"
                            v-bind:FieldDescription="'description'"
                            v-bind:FieldCode="'code'"
                            v-bind:Model="patient.majorReligion"                       
                            v-bind:Multiple="false"
                            :IsDisabled=this.isReadOnly
                            :Input="ChangeDropDownMajorReligion"
                        />
                    </b-col>
                    <b-col lg="3" md="3" sm="12">
                        <MdSelect
                            v-bind:Label="$t('Patient.Disability')"
                            data-field="Additional_Disability"
                            v-bind:Options="disability_list"
                            v-bind:FieldDescription="'description'"
                            v-bind:FieldCode="'code'"
                            v-bind:Model="patient.disability"                       
                            v-bind:Multiple="false"
                            :IsDisabled=this.isReadOnly
                            :Input="ChangeDropDownDisability"
                        />
                    </b-col>
                </b-row>
                <b-row>
                    <b-col lg="3" md="3" sm="12">
                        <MdSelect
                            v-bind:Label="$t('Patient.LegalStatus')"
                            data-field="Additional_LegalStatus"
                            v-bind:Options="legal_status_list"
                            v-bind:FieldDescription="'description'"
                            v-bind:FieldCode="'code'"
                            v-bind:Model="patient.legalStatus"                       
                            v-bind:Multiple="false"
                            :IsDisabled=this.isReadOnly
                            :Input="ChangeDropDownLegalStatus"
                        />
                    </b-col>
                    <b-col lg="3" md="3" sm="12">
                        <MdSelect
                            v-bind:Label="$t('Patient.EducationStatus')"
                            data-field="Additional_EducationStatus"
                            v-bind:Options="education_status_list"
                            v-bind:FieldDescription="'description'"
                            v-bind:FieldCode="'code'"
                            v-bind:Model="patient.educationStatus"                       
                            v-bind:Multiple="false"
                            :IsDisabled=this.isReadOnly
                            :Input="ChangeDropDownEducationStatus"
                        />
                    </b-col>  
                        <b-col lg="3" md="3" sm="12">
                        <MdRadioGroup
                            v-bind:Label="$t('Patient.MilitaryHistory')"
                            v-model="patient.militaryHistory"
                            v-bind:Options="YesNoOptions"
                            v-bind:Name="'radio-inlineMilitaryHistory'"
                            v-bind:isReadOnly=this.isReadOnly
                        />
                    </b-col>
                    <b-col lg="3" md="3" sm="12">
                        <MdRadioGroup
                            v-bind:Label="$t('Patient.VaCoverage')"
                            v-model="patient.vacoverage"
                            v-bind:Options="YesNoOptions"
                            v-bind:Name="'radio-inlineVaCoverage'"
                            v-bind:isReadOnly=this.isReadOnly
                        />
                    </b-col>
                </b-row>
                <b-row>
                    <b-col lg="3" md="3" sm="12">
                        <MdRadioGroup
                            v-bind:Label="$t('Patient.IVUse')"
                            v-model="patient.ivuse"
                            v-bind:Options="YesNoOptions"
                            v-bind:Name="'radio-inlineIVUse'"
                            v-bind:isReadOnly=this.isReadOnly
                        />
                    </b-col>
                </b-row>
                <MdTitle v-bind:Text="$t('Patient.LegalRepresentative')"  v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
                <b-row>
                   <b-col lg="3" md="3" sm="12">             
                            <MdEdit 
                                 v-bind:Label="$t('Patient.LegalFirstName')"
                                 v-bind:TypeProp="'text'" 
                                 v-bind:Name="'legalFirstname'"
                                 v-bind:MaxLengthProp="15"
                                 v-model="patient.legalFirstname"
                                 v-bind:isReadOnly=this.isReadOnly
                                 v-bind:Id="'input_patient_LegalFirstName'">                                     
                            </MdEdit>
                    </b-col>
                    <b-col lg="3" md="3" sm="12">
                            <MdEdit 
                                 v-bind:Label="$t('Patient.LegalLastName')"
                                 v-bind:TypeProp="'text'" 
                                 v-bind:Name="'legalLastname'"
                                 v-bind:MaxLengthProp="15"
                                 v-model="patient.legalLastname"
                                 v-bind:isReadOnly=this.isReadOnly
                                 v-bind:Id="'input_patient_LegallastName'">                                     
                            </MdEdit>
                    </b-col>
                    <b-col lg="3" md="3" sm="12">
                            <MdEdit 
                                 v-bind:Label="$t('Shared.Address1')"
                                 v-bind:TypeProp="'text'" 
                                 v-bind:Name="'legalAddress1'"
                                 v-bind:MaxLengthProp="30"
                                 v-model="patient.legalAddress1"
                                 v-bind:isReadOnly=this.isReadOnly
                                 v-bind:Id="'input_patient_address1'">                                     
                            </MdEdit>
                    </b-col>
                    <b-col lg="3" md="3" sm="12">
                            <MdEdit 
                                 v-bind:Label="$t('Shared.Address2')"
                                 v-bind:TypeProp="'text'" 
                                 v-bind:Name="'legalAddress2'"
                                 v-bind:MaxLengthProp="30"
                                 v-model="patient.legalAddress2"
                                 v-bind:isReadOnly=this.isReadOnly
                                 v-bind:Id="'input_patient_address2'">                                     
                            </MdEdit>
                    </b-col>
                           </b-row>
                           <b-row>
                   <b-col lg="3" md="3" sm="12">             
                            <MdEdit 
                                 v-bind:Label="$t('Patient.LegalZip')"
                                 v-bind:TypeProp="'text'" 
                                 v-bind:Name="'legalZip'"
                                 v-bind:MaxLengthProp="10"
                                 v-model="patient.legalZip"
                                 v-bind:isReadOnly=this.isReadOnly
                                 v-on:blur="patientLegalZipBlur(patient.legalZip)"
                                 v-on:change="patientLegalZipChange($event)"
                                 v-bind:Id="'input_patient_pzip'">                                     
                            </MdEdit>
                    </b-col>
                    <b-col lg="3" md="3" sm="12">
                            <MdEdit 
                                 v-bind:Label="$t('Patient.LegalCity')"
                                 v-bind:TypeProp="'text'" 
                                 v-bind:Name="'legalCity'"
                                 v-bind:MaxLengthProp="25"
                                 v-model="patient.legalCity"
                                 v-bind:isReadOnly=this.isReadOnly
                                 v-bind:Id="'input_patient_Legalcity'">                                     
                            </MdEdit>
                    </b-col>
                    <b-col lg="3" md="3" sm="12">
                            <MdEdit 
                                 v-bind:Label="$t('Shared.State')"
                                 v-bind:TypeProp="'text'" 
                                 v-bind:Name="'legalState'"
                                 v-bind:MaxLengthProp="2"
                                 v-model="patient.legalState"
                                 v-bind:isReadOnly=this.isReadOnly
                                 v-bind:Id="'input_patient_LegalState'">                                     
                            </MdEdit>
                    </b-col>
                    <b-col lg="3" md="3" sm="12">
                            <MdEdit 
                                 v-bind:Label="$t('Shared.Country')"
                                 v-bind:TypeProp="'text'" 
                                 v-bind:Name="'legalCountry'"
                                 v-bind:MaxLengthProp="25"
                                 v-model="patient.legalCountry"
                                 v-bind:isReadOnly=this.isReadOnly
                                 v-bind:Id="'input_patient_Legalcountry'">                                     
                            </MdEdit>
                    </b-col>
                           </b-row>
                           <b-row>
                   <b-col lg="3" md="3" sm="12">             
                            <MdEdit 
                                 v-bind:Label="$tc('Contact.Contact')"
                                 v-bind:TypeProp="'text'" 
                                 v-bind:Name="'legalContact'"
                                 v-bind:MaxLengthProp="30"
                                 v-model="patient.legalContact"
                                 v-bind:isReadOnly=this.isReadOnly
                                 v-bind:Id="'input_patient_contact'">                                     
                            </MdEdit>
                    </b-col>
                    <b-col lg="3" md="3" sm="12">
                            <MdEdit 
                                 v-bind:Label="$t('Patient.LegalDayPhone')"
                                 v-bind:TypeProp="'text'" 
                                 v-bind:Name="'legalPhone'"
                                 v-bind:MaxLengthProp="15"
                                 v-model="patient.legalPhone"
                                 v-bind:isReadOnly=this.isReadOnly
                                 v-bind:Id="'input_patient_LegalPhone'">                                     
                            </MdEdit>
                    </b-col>
                    <b-col lg="3" md="3" sm="12">
                            <MdEdit 
                                 v-bind:Label="$t('Patient.LegalFax')"
                                 v-bind:TypeProp="'text'" 
                                 v-bind:Name="'legalFax'"
                                 v-bind:MaxLengthProp="15"
                                 v-model="patient.legalFax"
                                 v-bind:isReadOnly=this.isReadOnly
                                 v-bind:Id="'input_patient_Legalfax'">                                     
                            </MdEdit>
                    </b-col>                    
                          </b-row>
                          </div>
                           <div class="tab-pane fade" id="contentCurrentTreatmentInfo" role="tabpanel" > 
                               <b-row>                                 
                        <b-col lg="3" md="3" sm="12" v-if="!UseBlankDoctorCode">
                            <MdLookup
                                v-bind:Label="$t('Patient.AttendingDoctor')"
                                v-bind:ModelCode="patient.doctorcode"
                                v-bind:ModelDescription="AttendingDoctor.description"
                                v-bind:RefCode="'AttendingDoctor'"
                                v-bind:Api="'Doctors'"
                                v-bind:FieldCode="'code'"
                                v-bind:FieldDescription="'name'"
                                :IsDisabled="this.isReadOnly"    
                                :SearchTitle="$t('Shared.Doctors')"
                                :GetValues="onCodeConditionOk"
                                :InvalidFeedback="$t('Patient.InvalidAttendingDoctor')"
                                :SearchFieldCode="'Code'"                       
                            />
                          </b-col>
                                    <b-col lg="3" md="3" sm="12">
                                        <MdLookup
                                            v-bind:Label="$t('Patient.ReferringDoctor')"
                                            v-bind:ModelCode="patient.privdoctorcode"
                                            v-bind:ModelDescription="ReferringDoctor.description"
                                            v-bind:RefCode="'ReferringDoctor'"
                                            v-bind:Api="'Doctors'"
                                            v-bind:FieldCode="'code'"
                                            v-bind:FieldDescription="'name'"
                                            :IsDisabled="this.isReadOnly"    
                                            :SearchTitle="$t('Shared.Doctors')"
                                            :GetValues="onCodeConditionOk"
                                            :InvalidFeedback="$t('Patient.InvalidReferringDoctor')" 
                                            :SearchFieldCode="'Code'"                     
                                        />
                                      </b-col>
                                      <b-col lg="3" md="3" sm="12">
                                    <MdLookup
                                       v-bind:Label="$t('Patient.ServiceLocation')"
                                       v-bind:ModelCode="patient.facilitycode"
                                       v-bind:ModelDescription="ServiceLocation.description"
                                       v-bind:RefCode="'ServiceLocation'"
                                       v-bind:Api="'Facilities'"
                                       v-bind:FieldCode="'code'"
                                       v-bind:FieldDescription="'company'"
                                       :IsDisabled="this.isReadOnly"    
                                       :SearchTitle="$t('Patient.ServiceLocation')"
                                       :GetValues="onCodeConditionOk"
                                       :InvalidFeedback="$t('Patient.InvalidServiceLocation')"
                                       :SearchFieldCode="'Code'"                    
                                    />
                                </b-col>
                                <b-col lg="3" md="3" sm="12">
                                    <MdLookup
                                       v-bind:Label="$t('Patient.CaseManager')"
                                       v-bind:ModelCode="patient.employeecode"
                                       v-bind:ModelDescription="CaseManager.description"
                                       v-bind:RefCode="'CaseManager'"
                                       v-bind:Api="'Employees?Active=true'"
                                       v-bind:FieldCode="'code'"
                                       v-bind:FieldDescription="'name'"
                                       v-bind:Fields="CaseManagerFields"
                                       :IsDisabled="this.isReadOnly"    
                                       :SearchTitle="$t('Patient.CaseManager')"
                                       :GetValues="onCodeConditionOk"
                                       :InvalidFeedback="$t('Patient.InvalidCaseManager')"
                                       :SearchFieldCode="'Code'"                     
                                    />
                                </b-col>
                   </b-row>
               
                          
                                <MdTitle v-bind:Text="$t('Patient.Conditions')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>


                               <b-row>   
                                                    <b-col lg="3" md="3" sm="12">
                                                        <MdLookup
                                                             v-bind:Label="$t('Patient.Condition1')"
                                                            v-bind:ModelCode="patient.conditioncode1"
                                                            v-bind:ModelDescription="condition1"
                                                            v-bind:RefCode="'Condition1'"
                                                            v-bind:Api="'conditions'"
                                                            v-bind:FieldCode="'code'"
                                                            v-bind:FieldDescription="'shortDescription'"
                                                            :IsDisabled="this.isReadOnly"    
                                                            :SearchTitle="$t('Patient.Conditions')"
                                                            :GetValues="onCodeConditionOk"
                                                            :InvalidFeedback="$t('Patient.InvalidConditionCode1')"
                                                            :SearchFieldCode="'Code'"                     
                                                        />
                                                    </b-col>                              
                                     <b-col lg="3" md="3" sm="12">
                                        <MdLookup
                                          v-bind:Label="$t('Patient.Condition2')"
                                           v-bind:ModelCode="patient.conditioncode2"
                                           v-bind:ModelDescription="this.condition2"
                                           v-bind:RefCode="'Condition2'"
                                           v-bind:Api="'conditions'"
                                           v-bind:FieldCode="'code'"
                                           v-bind:FieldDescription="'shortDescription'"
                                           :IsDisabled="this.isReadOnly"    
                                           :SearchTitle="$t('Patient.Conditions')"
                                           :GetValues="onCodeConditionOk"
                                           :InvalidFeedback="$t('Patient.InvalidConditionCode2')"
                                           :SearchFieldCode="'Code'"                     
                                        />
                                    </b-col> 
                                    <b-col lg="3" md="3" sm="12">
                                                        <MdLookup
                                                          v-bind:Label="$t('Patient.Condition3')"
                                                            v-bind:ModelCode="patient.conditioncode3"
                                                            v-bind:ModelDescription="this.condition3"
                                                            v-bind:RefCode="'Condition3'"
                                                            v-bind:Api="'conditions'"
                                                            v-bind:FieldCode="'code'"
                                                            v-bind:FieldDescription="'shortDescription'"
                                                            :IsDisabled="this.isReadOnly"    
                                                            :SearchTitle="$t('Patient.Conditions')"
                                                            :GetValues="onCodeConditionOk"
                                                            :InvalidFeedback="$t('Patient.InvalidConditionCode3')"
                                                            :SearchFieldCode="'Code'"                     
                                                        />
                                                    </b-col> 
                                     <b-col lg="3" md="3" sm="12">
                                        <MdLookup
                                          v-bind:Label="$t('Patient.Condition4')"
                                          v-bind:ModelCode="patient.conditioncode4"
                                          v-bind:ModelDescription="this.condition4"
                                          v-bind:RefCode="'Condition4'"
                                          v-bind:Api="'conditions'"
                                          v-bind:FieldCode="'code'"
                                          v-bind:FieldDescription="'shortDescription'"
                                          :IsDisabled="this.isReadOnly"    
                                          :SearchTitle="$t('Patient.Conditions')"
                                          :GetValues="onCodeConditionOk"
                                          :InvalidFeedback="$t('Patient.InvalidConditionCode4')"
                                          :SearchFieldCode="'Code'"                     
                                        />
                                    </b-col> 
                                                 </b-row>
                                                  <b-row>                                 
                                                    <b-col lg="3" md="3" sm="12">
                                                        <MdLookup
                                                          v-bind:Label="$t('Patient.Condition5')"
                                                            v-bind:ModelCode="patient.conditioncode5"
                                                            v-bind:ModelDescription="this.condition5"
                                                            v-bind:RefCode="'Condition5'"
                                                            v-bind:Api="'conditions'"
                                                            v-bind:FieldCode="'code'"
                                                            v-bind:FieldDescription="'shortDescription'"
                                                            :IsDisabled="this.isReadOnly"    
                                                            :SearchTitle="$t('Patient.Conditions')"
                                                            :GetValues="onCodeConditionOk"
                                                            :InvalidFeedback="$t('Patient.InvalidConditionCode5')"
                                                            :SearchFieldCode="'Code'"
                                                        />
                                                    </b-col> 
                                     <b-col lg="3" md="3" sm="12">
                                        <MdLookup
                                          v-bind:Label="$t('Patient.Condition6')"
                                          v-bind:ModelCode="patient.conditioncode6"
                                          v-bind:ModelDescription="this.condition6"
                                          v-bind:RefCode="'Condition6'"
                                          v-bind:Api="'conditions'"
                                          v-bind:FieldCode="'code'"
                                          v-bind:FieldDescription="'shortDescription'"
                                          :IsDisabled="this.isReadOnly"    
                                          :SearchTitle="$t('Patient.Conditions')"
                                          :GetValues="onCodeConditionOk"
                                          :InvalidFeedback="$t('Patient.InvalidConditionCode6')"
                                          :SearchFieldCode="'Code'"                     
                                        />
                                    </b-col> 
                                    <b-col lg="3" md="3" sm="12">
                                                        <MdLookup
                                                          v-bind:Label="$t('Patient.Condition7')"
                                                            v-bind:ModelCode="patient.conditioncode7"
                                                            v-bind:ModelDescription="this.condition7"
                                                            v-bind:RefCode="'Condition7'"
                                                            v-bind:Api="'conditions'"
                                                            v-bind:FieldCode="'code'"
                                                            v-bind:FieldDescription="'shortDescription'"
                                                            :IsDisabled="this.isReadOnly"    
                                                            :SearchTitle="$t('Patient.Conditions')"
                                                            :GetValues="onCodeConditionOk"
                                                            :InvalidFeedback="$t('Patient.InvalidConditionCode7')"
                                                            :SearchFieldCode="'Code'"                     
                                                        />
                                                    </b-col> 
                                                 </b-row>
                           
                           

                            </div>
                             <div class="tab-pane fade" id="cotentBillingFlags" role="tabpanel" > 
                                <b-row>
                                    <b-col md="3" lg="3" sm="12">
                                    <MdSelect
                                        v-bind:Label="$t('Patient.InfoRelease')"
                                        data-field="infoRelease"
                                        v-bind:Options="infoRelease_list"
                                        v-bind:FieldDescription="'text'"
                                        v-bind:FieldCode="'value'"
                                        v-bind:Model="patient.infoRelease"                       
                                        v-bind:Multiple="false"
                                        :IsDisabled=this.isReadOnly
                                        :Input="ChangeDropDownInfoRelease"
                                    />
                                    </b-col>
                                    <b-col md="3" lg="3" sm="12">
                                             <MdSelect
                                                v-bind:Label="$t('Patient.SignedAssignment')"
                                                data-field="acceptAssign"
                                                v-bind:Options="acceptAssign_list"
                                                v-bind:FieldDescription="'text'"
                                                v-bind:FieldCode="'value'"
                                                v-bind:Model="patient.acceptAssign"                       
                                                v-bind:Multiple="false"
                                                :IsDisabled=this.isReadOnly
                                                :Input="ChangeDropDownAcceptAssign"
                                            />
                                    </b-col>
                                    <b-col md="3" lg="3" sm="12">
                                         <MdCheckBox
                                           v-bind:Label="$t('Patient.EarlyAndPeriodic')" 
                                           v-bind:Name="'checkbox-EPSDT'"
                                           v-bind:ClassProp="'AlignCheck'"
                                           v-bind:CheckedValue="true"
                                           v-bind:UncheckedValue="false"
                                           v-model="patient.epsdt"
                                           v-bind:isReadOnly=this.isReadOnly
                                         />
                                    </b-col>
                                    <b-col md="3" lg="3" sm="12" >
                                        <MdCheckBox
                                           v-bind:Label="$t('Patient.Emergency')"
                                           v-bind:Name="'checkbox-Emergency'"
                                           v-bind:ClassProp="'AlignCheck'"
                                           v-bind:CheckedValue="true"
                                           v-bind:UncheckedValue="false"
                                           v-model="patient.emergency"
                                           v-bind:isReadOnly=this.isReadOnly
                                         />
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col md="3" lg="3" sm="12" >
                                        <MdCheckBox
                                           v-bind:Label="$t('Patient.RelatedToEmployment')"
                                           v-bind:Name="'checkbox-RelatedtoEmployment'"
                                           v-bind:ClassProp="'AlignCheck'"
                                           v-bind:CheckedValue="true"
                                           v-bind:UncheckedValue="false"
                                           v-model="patient.relatedToEmp"
                                           v-bind:isReadOnly=this.isReadOnly
                                         />
                                    </b-col>
                                    <b-col md="3" lg="3" sm="12" >
                                        <MdCheckBox
                                           v-bind:Label="$t('Patient.BillToCompany')"
                                           v-bind:Name="'checkbox-BillToCompany'"
                                           v-bind:ClassProp="'AlignCheck'"
                                           v-bind:CheckedValue="true"
                                           v-bind:UncheckedValue="false"
                                           v-model="patient.billToCompany"
                                           v-bind:isReadOnly=this.isReadOnly
                                         />
                                    </b-col>
                                    <b-col md="3" lg="3" sm="12" >
                                        <MdCheckBox
                                           v-bind:Label="$t('Patient.RelatedToAutoAccident')"
                                           v-bind:Name="'checkbox-RelatedToAuto'"
                                           v-bind:ClassProp="'AlignCheck'"
                                           v-bind:CheckedValue="true"
                                           v-bind:UncheckedValue="false"
                                           v-model="patient.relatedToAuto"
                                           v-bind:isReadOnly=this.isReadOnly
                                         />
                                    </b-col>
                                    <b-col md="3" lg="3" sm="12" >
                                        <MdCheckBox
                                           v-bind:Label="$t('Patient.RelatedToOtherAccident')"
                                           v-bind:Name="'checkbox-RelatedToOther'"
                                           v-bind:ClassProp="'AlignCheck'"
                                           v-bind:CheckedValue="true"
                                           v-bind:UncheckedValue="false"
                                           v-model="patient.relatedToOther"
                                           v-bind:isReadOnly=this.isReadOnly
                                         />
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col md="3" lg="3" sm="12" >
                                        <MdCheckBox
                                           v-bind:Label="$t('Patient.RelatedToMilitaryDuty')"
                                           v-bind:Name="'checkbox-RelatedToMilitary'"
                                           v-bind:ClassProp="'AlignCheck'"
                                           v-bind:CheckedValue="true"
                                           v-bind:UncheckedValue="false"
                                           v-model="patient.military"
                                           v-bind:isReadOnly=this.isReadOnly
                                         />
                                    </b-col>                                    
                                    <b-col md="6">
                                        <div class="form-inline"> 
                                            <b-col md="5">                 
                                                <div class="input-group">
                                                    <MdLookup
                                                        v-bind:Label="$t('Patient.Matrix')"
                                                        v-bind:ModelCode="patient.matrix"
                                                        v-bind:ModelDescription="Matrix.description"
                                                        v-bind:RefCode="'Matrix'"
                                                        v-bind:Api="'Matrixes'"
                                                        v-bind:FieldCode="'code'"
                                                        v-bind:FieldDescription="'name'"
                                                        :IsDisabled="this.isReadOnly"    
                                                        :SearchTitle="$t('Patient.Matrix')"
                                                        :GetValues="onCodeConditionOk"
                                                        :InvalidFeedback="$t('Patient.InvalidMatrixCode')"
                                                        :SearchFieldCode="'Code'"                      
                                                    />  
                                                </div>
                                            </b-col> 
                                        </div>
                                    </b-col>
                                    
                                </b-row>
                            </div>
                        </div>
                        </MdTabList>
                    </div>
                    </div>                    
          </div>
       </div>

       <div class="space-actions"></div>
            <div class="page-actions">
                <MdButton
                v-bind:Label="$t('Shared.Back')"
                v-bind:ClassProp="this.editMode ? 'secondary mx-2 hidden':'secondary mx-2 btn-login'"
                v-on:click="goPatientList"
            />
            <MdButton
            v-bind:Label="$t('Shared.Save')"
            v-bind:ClassProp="(this.isReadOnly)?'primary mx-2':'primary btn-login mx-2'"
            v-on:click="Save"
            v-bind:isReadOnly=this.isReadOnly
            v-bind:Name="'btn_save_patient'"
        />
            </div>
 </div>
</template>

<script>

import moment from 'moment'
import PatientService from '@/services/patient.service' 
import EnumService from '@/services/enum.service' 
import Condition from '@/services/condition.service'
import Utils from '../../../common/utils'
import RaceService from '@/services/race.service'
import ModalService from '@/services/modal.service'
import ShowMessage from '@/components/messages/ShowMessage.js'
import DBFunctions from '@/common/DBFunctions.js'
import { EventBus } from '@/event-bus.js';
import GlobalService from '@/services/global.service'
import ProviderService from '@/services/provider.service'


let elemSaveBtn = '';
export default {
    //components: { Multiselect },

  beforeRouteEnter(to, from, next) {
        next(async vm => {
            const iPI = await vm.CanView();
            if(iPI) next()
            else next('/accessdenied');
        }) 
  },

  beforeRouteLeave(to, from, next) {
	if (!this.isReadOnly && this.pendingChanges) {
		this.$bvModal.msgBoxConfirm(this.$t('Msg.LeavingWithoutSaving'), {
          title: this.$tc('Shared.Patient'),
          okTitle: this.$t('Shared.BtnSave'),
          cancelTitle: this.$t('Shared.BtnDismiss'),
          footerClass: 'p-2',
          titleClass: 'modal-title modal-type-warning'
        })
          .then(value => {
            if(!value){
                /** Add for Fovorite button states */
              this.checkExistFavorite('/app/patients');  
              next();
            } else {
             // return;
             this.Save();
             next();
            }
          })		
	} else {
		next()
	}
    },

  mixins: [ShowMessage, DBFunctions],  
  name: 'PatientInfo',
  props: ["editMode","HaveChanges"],
  data() {
    return {
        UseBlankDoctorCode:false,
        IsVip:false,
        TitleWindow:"",
        formattedCurrencyValue: "$ 0.00",
        formattedCurrencyValue1: "$ 0.00",
         IsClinical:false,
        CountSave:0,
        RegistrationInfo:{},
        patientZipChanged:false,
        patientLegalZipChanged:false,
        lookupFieldChanged:false,
         fields: [
        { key: 'show_details', sortable: true},
        { key: 'programName', sortable: true, label: this.$t('Patient.ProgramName'), class: 'text-rigth' },
        { key: 'code', sortable: true, label: this.$t('Shared.Code'), class: 'hidden' },
        { key: 'dateFrom', sortable: true, label: this.$t('Shared.datefrom'), class: 'text-rigth' },
        { key: 'dateTo', sortable: true, label: this.$t('Shared.dateTo'), class: 'text-rigth' },       
        ],
        pageOptions: [5, 10, 15 ],
        totalRows: 0,
        currentPage: 1,
        perPage: 10,
        filterOn: [],
        items: [],
        refTable: 'selectableTable',
        ShowMessageIsEmpty: true,

        Loading:true,
        shippingData:[],
        lookupResult:[],
        modalMessage:'',
        pendingChanges:this.HaveChanges,
        PatientTitle:{      
            programName:'',
            fullName:'',
            admitDate:'',
            dischargeDate:'',
            dob:''
        },
        InfoPatient:{
            Dob:'',
            FirstName:'',
            LastName:'',
            Sex:'',
            Age:'',
            Title:'',
            SexExtend:'',
            Email:'',
            CellPhone:''

        },
        permission:[],
        Menu : this.$store.state.leftMenu,
       OptionsRightMenu:{
            visible:true,
            ItemsList:[],
            btnvisible:true,
            itemdisabled: false,
            PatientCode:''         
        },
        condition1: '',  
        condition2: '',   
        condition3: '',  
        condition4: '',
        condition5: '',
        condition6: '',
        condition7: '',       
        diagnosis1: { "description": '' }, 
        diagnosis2: { "description": '' }, 
        diagnosis3: { "description": '' }, 
        diagnosis4: { "description": '' }, 
        diagnosis5: { "description": '' }, 
        diagnosis6: { "description": '' }, 
        diagnosis7: { "description": '' }, 
        diagnosis8: { "description": '' },  
        diagnosis9: { "description": '' },  
        diagnosis10: { "description": '' }, 
        diagnosis11: { "description": '' }, 
        diagnosis12: { "description": '' }, 
        diagnosis13: { "description": '' }, 
        diagnosis14: { "description": '' }, 
        diagnosis15: { "description": '' }, 
        diagnosis16: { "description": '' },  
        ResourceParameter:'',  
        TypeOption:"",
        edit_mode: this.editMode, 
        patient:  {
            accState: '',
            isVIP:false,
            acceptAssign: '',
            address1: '',
            address2: '',
            autho: '',
            billToCompany: '',
            billfortrans: '',
            branch: '',
            city: "",
            companyAddress1: '',
            companyAddress2: '',
            companyCity: '',
            companyContact: '',
            companyCountry: '',
            companyFax: '',
            companyName: '',
            companyPhone: '',
            companyState: '',
            companyZip: "",
            conditioncode1: '',
            conditioncode2: '',
            conditioncode3: '',
            conditioncode4: '',
            conditioncode5: '',
            conditioncode6: '',
            conditioncode7: '',
            consultDate: null,
            country: '',
            dateDeceased: null,
            dayPhone: '',
            deceased: '',
            diagcode1: '',
            diagcode2: '',
            diagcode3: '',
            diagcode4: '',
            diagcode5: '',
            diagcode6: '',
            diagcode7: '',
            diagcode8: '',
            dob: null,
            doctorcode: '',
            emergency: '',
            employed: '',
            employeecode: '',
            enablerepeat: '',
            ethcode: '',
            facilitycode: '',
            facilityname: '',
            firstname: '',
            formSigned: '',
            heights: null,
            infoRelease: '',
            initial: "",
            injuryDate: null,
            lastname: '',
            legalAddress1: '',
            legalAddress2: '',
            legalCity: '',
            legalContact: '',
            legalCountry: '',
            legalFax: '',
            legalFirstname: '',
            legalLastname: '',
            legalPhone: '',
            legalRep: '',
            legalState: '',
            legalZip: '',
            married: '',
            matrix: '',
            medigapRelease: '',
            military: '',
            nightPhone: '',
            patientid: '',
            patientsignature: "",
            primaryins: '',
            privdoctorcode: '',
            referal: '',
            relatedToAuto: '',
            relatedToEmp: '',
            relatedToOther: '',
            retireddate: null,
            returnWork: null,
            sex: '',
            sgDate: null,
            ssn: '',
            state: '',
            student: '',
            suffix: '',
            title: '' ,
            validity: null,
            weight: null,
            zip: '',
            notActive: '',
            referalTo: '',
            espdt: '',
            epsdt: '',
            sysDate: moment().format('YYYY-MM-DD'),
            sysUser: '',
            commLiason: '',
            familyIncome: '',
            majorLanguage: '',
            majorReligion: '',
            disability: '',
            importId: '',
            diagcode9: '',
            diagcode10: '',
            diagcode11: '',
            diagcode12: '',
            diagcode13: '',
            diagcode14: '',
            diagcode15: '',
            diagcode16: '',
            email: '',
            cellPhone: '',
            cellCompany: '',
            textAddress: '',
            deathReason: '',
            ethnicityCode: '',
            commPreference: '',
            isCriminal: '',
            financialSupport: '',
            educationLevel: '',
            isVoluntary: '',
            isAdmitted: '',
            icd9: '',
            previousFirstName: '',
            previousLastName: '',
            previousInitial: '',
            ethcodeGranularity: null,
            majorLanguageAbility: '',
            fullMiddleName: '',
            teamCode: '',
            isExpat: '',
            county: '',
            township: '',
            maidenName: '',
            homeless: '',
            femaleHeadHousehold: '',
            ivuse: '',
            militaryHistory: '',
            vacoverage: '',
            numberDependents: null,
            legalStatus: '',
            educationStatus: '',
            personalIncome: '',
            personalIncomeAmt: null,
            familyIncomeAmt: null,
            ctnPatientId: '',
            eyeColor: '',
            hairColor: '',
            gender: '',
            pptoken: '',
            pptokenSysDate: null,
            nickname: '',
            employeddesc: '',
        },
        id: null,
        ReferralFrom:{  "description": ''  },
        ReferralTo:{ "description": '' },
        AttendingDoctor:{ "description": '' },
        ReferringDoctor:{ "description": '' },
        ServiceLocation:{ "description": '' },
        CaseManager:{ "description": '' },
        CommunicationLiaison:{ "description": '' },
        Matrix:{},
        title_list: EnumService.Titles, 
        sex_list: EnumService.Sexs,
        TempConditionByCode:[],
        marital_status_list: EnumService.MaritalStatus,   
        employment_list: EnumService.Employment,
        student_list: EnumService.Student,
        inactive_recods_list: EnumService.YesNoOptionsInverted,
        YesNoOptions: EnumService.YesNoOptions,



       
       
        gender_list: [],
        ethnicity_list: [],     
        race_list: [],
        eye_color_list: [],
        hair_color_list: [], 
        comm_preference_list: [],
        family_income_list: [],
        personal_income_list: [],
        major_language_list: [],
        major_language_ability_list: [],
        major_religion_list: [],
        disability_list: [],
        legal_status_list: [],
        education_status_list: [],



        FieldsParameter : [],
        acceptAssign_list: EnumService.AcceptAssign,
        infoRelease_list: EnumService.InfoRelease,
        modelBillToCompany:[],    
        value: '',
        formatted: '',
        selected: '',
        isReferralFromValid: true,
        isReferralToValid: true,
        isCommunicationLiaisonValid: true,
        isAttendingDoctorValid: true,
        isReferringDoctorValid: true,
        isServiceLocationValid: true,
        isCaseManagerValid: true,
        isMatrixValid: true,
        isDiagnosis1Valid: true,
        isDiagnosis2Valid: true,
        isDiagnosis3Valid: true,
        isDiagnosis4Valid: true,
        isDiagnosis5Valid: true,
        isDiagnosis6Valid: true,
        isDiagnosis7Valid: true,
        isDiagnosis8Valid: true,
        isDiagnosis9Valid: true,
        isDiagnosis10Valid: true,
        isDiagnosis11Valid: true,
        isDiagnosis12Valid: true,
        isDiagnosis13Valid: true,
        isDiagnosis14Valid: true,
        isDiagnosis15Valid: true,
        isDiagnosis16Valid: true,
        isCondition1Valid: true,
        isCondition2Valid: true,
        isCondition3Valid: true,
        isCondition4Valid: true,
        isCondition5Valid: true,
        isCondition6Valid: true,
        isCondition7Valid: true,
        isDOBValid: true,
        isFirstNameValid: true,
        isLastNameValid: true,
        isReadOnly: false,
        fieldCode: '',
        fieldDescription: '',
        tablist: [
            { title: this.$t('Shared.General'), link: '#contentGeneral', active: true},
            { title: this.$t('Patient.TabAdditional'), link: '#contentAdditional'},
            { title: this.$t('Patient.TabCurrentTreatmentInfo'), link: '#contentCurrentTreatmentInfo'},
            { title: this.$t('Patient.TabBillingFlags'), link: '#cotentBillingFlags'}
        ],
        tablist2: [
            /*{ title: 'Diag 1-4', link: '#contentDiagnosis1_4', active: true},
            { title: 'Diag 5-8', link: '#contentDiagnosis5_8'},
            { title: 'Diag 9-12', link: '#contentDiagnosis9_12'},
            { title: 'Diag 13-16', link: '#contentDiagnosis13_16'},
			{ title: 'Cond 1-4', link: '#contentCondition1_4',active: true},
			{ title: 'Cond 5-7', link: '#contentCondition5_7'},*/
        ],
        //eslint-disable-next-line
        reg: /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/,
       // pendingChanges: false,
        emailValid: false,
        age: '',
        CaseManagerFields:[
            { key: 'name', sortable: true, label: 'Name' },
            { key: 'code', sortable: true, label: 'Code' },
            { key: 'employeeType', sortable: true, label: 'Employee Type' },
            { key: 'employeeTypeCode', sortable: true, label: 'Employee Type Code' },
            { key: 'employeeId', sortable: true, label: 'Employee Id' },
            { key: 'cellPhone', sortable: true, label: 'CellPhone' },
            { key: 'dayphone', sortable: true, label: 'DayPhone' },
            { key: 'nightphone', sortable: true, label: 'NightPhone' },
            { key: 'fax', sortable: true, label: 'Fax' },
            { key: 'textAddress', sortable: true, label: 'Text Address' },
            { key: 'eMail', sortable: true, label: 'EMail' },
            { key: 'credentials', sortable: true, label: 'Credentials' },
            { key: 'license', sortable: true, label: 'License' },
            { key: 'npi', sortable: true, label: 'NPI' },
            { key: 'address1', sortable: true, label: 'Address1' },
            { key: 'address2', sortable: true, label: 'Address2' },
            { key: 'city', sortable: true, label: 'City' },
            { key: 'zip', sortable: true, label: 'Zip' },
            { key: 'state', sortable: true, label: 'State' },
            { key: 'userid', sortable: true, label: 'User Id' }
        ],
        autoAssignCode:true,
        isAutoAssignCodeValid:true

  }
 },
    computed: {
        referralFromState() {
            return this.isReferralFromValid ? null : false
        },

        referralToState() {
            return this.isReferralToValid ? null : false 
        },

        communicationLiaisonState() {
            return this.isCommunicationLiaisonValid ? null : false
        },

        attendingDoctorState() {
            return this.isAttendingDoctorValid ? null : false
        },

        referringDoctorState() {
            return this.isReferringDoctorValid ? null : false
        },

        serviceLocationState() {
            return this.isServiceLocationValid ? null : false
        },

        caseManagerState() {
            return this.isCaseManagerValid ? null : false
        },

        matrixState() {
            return this.isMatrixValid ? null : false
        },

        diagnosis1State() {
            return this.isDiagnosis1Valid ? null : false
        },

        diagnosis2State() {
            return this.isDiagnosis2Valid ? null : false
        },

        diagnosis3State() {
            return this.isDiagnosis3Valid ? null : false
        },

        diagnosis4State() {
            return this.isDiagnosis4Valid ? null : false
        },

        diagnosis5State() {
            return this.isDiagnosis5Valid ? null : false
        },

        diagnosis6State() {
            return this.isDiagnosis6Valid ? null : false
        },

        diagnosis7State() {
            return this.isDiagnosis7Valid ? null : false
        },

        diagnosis8State() {
            return this.isDiagnosis8Valid ? null : false
        },

        diagnosis9State() {
            return this.isDiagnosis9Valid ? null : false
        },

        diagnosis10State() {
            return this.isDiagnosis10Valid ? null : false
        },

        diagnosis11State() {
            return this.isDiagnosis11Valid ? null : false
        },

        diagnosis12State() {
            return this.isDiagnosis12Valid ? null : false
        },

        diagnosis13State() {
            return this.isDiagnosis13Valid ? null : false
        },

        diagnosis14State() {
            return this.isDiagnosis14Valid ? null : false
        },

        diagnosis15State() {
            return this.isDiagnosis15Valid ? null : false
        },

        diagnosis16State() {
            return this.isDiagnosis16Valid ? null : false
        },

        condition1State() {
            return this.isCondition1Valid ? null : false
        },

        condition2State() {
            return this.isCondition2Valid ? null : false
        },

        condition3State() {
            return this.isCondition3Valid ? null : false
        },

        condition4State() {
            return this.isCondition4Valid ? null : false
        },

        condition5State() {
            return this.isCondition5Valid ? null : false
        },

        condition6State() {
            return this.isCondition6Valid ? null : false
        },

        condition7State() {
            return this.isCondition7Valid ? null : false
        },
    },

    methods : {
        CanView(){
            return this.permission[0].roleCanView;
        },
        ChangeDropDownTitle(obj){
            this.patient.title = obj ? obj.value : '';         
            /*if(obj !== null && obj.value !== null){
                //this.patient.sex = value ? value.value : '';
               if(obj.value === "Mr."){
                    this.patient.sex = "M";
                    // this.$set(this.patient, 'sex', "M");
                }
                if(obj.value === "Mrs." || obj.value === "Ms."){
                    this.patient.sex = "F";
                }
                if(obj.value === "Dr."){
                    this.patient.sex = "U";
                    // this.$set(this.patient, 'sex', "M");
                }
            }else{
                this.patient.sex = "U";
            }*/
        },
        ChangeDropDownSex(value){
            this.patient.sex = value ? value.value : '';
        },
        ChangeDropDownGender(value){
            this.patient.gender = value ? value.code : '';
        },
        ChangeDropDownEthnicity(value){
            this.patient.ethnicityCode = value ? value.code : '';
        },
        ChangeDropDownRace(value){
            this.patient.ethcode = value ? value.code : '';
        },
        ChangeDropDownEyeColor(value){
            
            this.patient.eyeColor = value ? value.code : '';
        },
        ChangeDropDownHairColor(value){
            this.patient.hairColor = value ? value.code : '';
        },
        ChangeDropDownMarried(value){
            this.patient.married = value ? value.value : '';
        },
        ChangeDropDownEmployment(value){
            this.patient.employed = value ? value.value : '';
        },
        ChangeDropDownStudent(value){
            this.patient.student = value ? value.value : '';
        },
        ChangeDropDownCommPreference(value){
            this.patient.commPreference = value ? value.code : ''; 
        },
        ChangeDropDownFamilyIncome(value){
            
            this.patient.familyIncome = value ? value.code : '';
           
            if(this.patient.familyIncome!="OTHER"){
                this.patient.familyIncomeAmt = "";
                this.formattedCurrencyValue = "$ 0.00";
                this.focusOut();
            }
        },
        ChangeDropDownPersonalIncome(value){
            this.patient.personalIncome = value ? value.code : '';

            if(this.patient.personalIncome!="OTHER"){
                this.patient.personalIncomeAmt = "";
                this.formattedCurrencyValue1 = "$ 0.00";
                this.focusOut1();
            }
        },
        ChangeDropDownMajorLanguage(value){
            this.patient.majorLanguage = value ? value.code : '';
        },
        ChangeDropDownMajorLanguageAbility(value){
            this.patient.majorLanguageAbility = value ? value.code : '';
        },
        ChangeDropDownMajorReligion(value){
            this.patient.majorReligion = value ? value.code : '';
        },
        ChangeDropDownDisability(value){
            this.patient.disability = value ? value.code : '';
        },
        ChangeDropDownLegalStatus(value){
            this.patient.legalStatus = value ? value.code : '';
        },
        ChangeDropDownEducationStatus(value){
            this.patient.educationStatus = value ? value.code : '';
        },
        ChangeDropDownInfoRelease(value){
            this.patient.infoRelease = value ? value.value : '';
        },
        ChangeDropDownAcceptAssign(value){
            this.patient.acceptAssign = value ? value.value : '';
        },

pageChanged (page) {            
      this.currentPage = page;
   
    },

    PerPageFunction(perpage){
      this.perPage = perpage;    
    
    
    },
    onRowSelected(items) {
      this.selected = items
    },

     onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },



    populateSelects(){  
        
        if(this.$store.state.genderwasloaded){
            this.gender_list = this.$store.state.genderList;
        }else{
            GlobalService.getGender()
            .then((response) => {
                 if(response.status == '200'){
                  this.gender_list = response.data;                     
                  this.$store.commit('initGenderList')
                  this.$store.commit('setGenderwasloaded',true);  
                  response.data.forEach(element => {
                    this.$store.commit('setGenderList',element);
                  });
                 }
          })          
           .catch((error) => {
               if (error.response) {
                 this.showMessage(this.$tc('Shared.Patient'),error,"error");
                }
           });   
        }    
        
        if(this.$store.state.ethnicitywasloaded){
            this.ethnicity_list = this.$store.state.ethnicityList;
        }else{
            GlobalService.getEthnicity()
            .then((response) => {
               if(response.status == '200'){ 
                this.ethnicity_list = response.data;              
                this.$store.commit('initEthnicityList');
                this.$store.commit('setethnicitywasloaded',true);                
                  response.data.forEach(element => {
                    this.$store.commit('setEthnicityList',element);
                  });   
                  return response.data;
               }
           })
           .catch((error) => {
                if (error.response) {
                 this.showMessage(this.$tc('Shared.Patient'),error,"error");
                }
           });
        }
        
        if(this.$store.state.racewasloaded){
            this.race_list = this.$store.state.raceList;
        }else{
            RaceService.getAll()
            .then((response) => {
               if(response.status == '200'){
                this.race_list = response.data;
                this.$store.commit('initRaceList');
                this.$store.commit('setracewasloaded',true); 
                response.data.forEach(element => {
                  this.$store.commit('setRaceList',element);
                });
               }         
           })
           .catch((error) => {
              if (error.response) {
                 this.showMessage(this.$tc('Shared.Patient'),error,"error");
                }
           });
        }   
      

       if(this.$store.state.eyeColorwasloaded){
        this.eye_color_list = this.$store.state.eyeColorList;
       }else{
        GlobalService.getEyeColor()
           .then((response) => {
               if(response.status == '200'){
                this.eye_color_list = response.data;
                this.$store.commit('initEyeColorList');
                this.$store.commit('seteyeColorwasloaded',true); 
                response.data.forEach(element => {
                  this.$store.commit('setEyeColorList',element);
                });
               }
               })
           .catch((error) => {
               if (error.response) {
                 this.showMessage(this.$tc('Shared.Patient'),error,"error");
                }
           });
       }
    
       if(this.$store.state.hairColorwasloaded){
        this.hair_color_list = this.$store.state.hairColorList;
       }else{
        GlobalService.getHairColor()
            .then((response) => {
               if(response.status == '200'){ 
                this.hair_color_list = response.data;                 
                this.$store.commit('initHairColorList');
                this.$store.commit('sethairColorwasloaded',true); 
                response.data.forEach(element => {
                  this.$store.commit('setHairColorList',element);
                });
               }           
           })
           .catch((error) => {
              if (error.response) {
                 this.showMessage(this.$tc('Shared.Patient'),error,"error");
                }
           });
       }
       
       if(this.$store.state.commPreferencewasloaded){
        this.comm_preference_list = this.$store.state.commPreferenceList;
       }else{
        GlobalService.getCommPreference()
           .then((response) => {
               if(response.status == '200'){   
                this.comm_preference_list = response.data;                
                this.$store.commit('initCommPreferenceList');
                this.$store.commit('setcommPreferencewasloaded',true);
                response.data.forEach(element => {
                  this.$store.commit('setCommPreferenceList',element);
                });
               }            
           })
           .catch((error) => {
              if (error.response) {
                 this.showMessage(this.$tc('Shared.Patient'),error,"error");
                }
           });
       }
      
       if(this.$store.state.familyIncomewasloaded){
        this.family_income_list = this.$store.state.familyIncomeList;
       }else{
        GlobalService.getFamilyIncome()
           .then((response) => {
               if(response.status == '200'){
                this.family_income_list = response.data;
                this.$store.commit('initFamilyIncomeList');
                this.$store.commit('setfamilyIncomewasloaded',true);
                response.data.forEach(element => {
                  this.$store.commit('setFamilyIncomeList',element);
                });
               }             
           })
           .catch((error) => {
               if (error.response) {
                 this.showMessage(this.$tc('Shared.Patient'),error,"error");
                }
           });
       }
      
       if(this.$store.state.personalIncomewasloaded){
        this.personal_income_list = this.$store.state.personalIncomeList;
       }else{
        GlobalService.getPersonalIncome()
           .then((response) => {
               if(response.status == '200'){
                this.personal_income_list = response.data;
                this.$store.commit('initPersonalIncomeList');
                this.$store.commit('setpersonalIncomewasloaded',true);
                response.data.forEach(element => {
                  this.$store.commit('setPersonalIncomeList',element);
                });
               }             
           })
           .catch((error) => {
               if (error.response) {
                 this.showMessage(this.$tc('Shared.Patient'),error,"error");
                }
           });
       }
       
       if(this.$store.state.majorLanguagewasloaded){ 
        this.major_language_list = this.$store.state.majorLanguageList;
       }else{
        GlobalService.getMajorLanguage()
           .then((response) => {
               if(response.status == '200'){
                this.major_language_list = response.data;
                this.$store.commit('initMajorLanguageList');
                this.$store.commit('setmajorLanguagewasloaded',true);
                response.data.forEach(element => {
                  this.$store.commit('setMajorLanguageList',element);
                });
               }               
           })
           .catch((error) => {
               if (error.response) {
                 this.showMessage(this.$tc('Shared.Patient'),error,"error");
                }
           });
       }
       
       if(this.$store.state.majorLanguageAbilitywasloaded){
        this.major_language_ability_list = this.$store.state.majorLanguageAbilityList;
       }else{
        GlobalService.getMajorLanguageAbility()
           .then((response) => {
               if(response.status == '200'){
                this.major_language_ability_list = response.data;
                this.$store.commit('initMajorLanguageAbilityList');
                this.$store.commit('setmajorLanguageAbilitywasloaded',true);
                response.data.forEach(element => {
                  this.$store.commit('setMajorLanguageAbilityList',element);
                });
               }              
           })
           .catch((error) => {
               if (error.response) {
                 this.showMessage(this.$tc('Shared.Patient'),error,"error");
                }
           });
       }

       if(this.$store.state.majorReligionwasloaded){
        this.major_religion_list = this.$store.state.majorReligionList;
       }else{
        GlobalService.getMajorReligion()
           .then((response) => {
               if(response.status == '200'){
                this.major_religion_list = response.data;
                this.$store.commit('initMajorReligionList');
                this.$store.commit('setmajorReligionwasloaded',true);              
                response.data.forEach(element => {
                  this.$store.commit('setMajorReligionList',element);
                });
               }                
           })
           .catch((error) => {
               if (error.response) {
                 this.showMessage(this.$tc('Shared.Patient'),error,"error");
                }
           });
       }

       if(this.$store.state.disabilitywasloaded){
        this.disability_list = this.$store.state.disabilityList;
       }else{
        GlobalService.getDisability()
           .then((response) => {
               if(response.status == '200'){
                this.disability_list = response.data;
                this.$store.commit('initDisabilityList');
                this.$store.commit('setdisabilitywasloaded',true);  
                response.data.forEach(element => {
                  this.$store.commit('setDisabilityList',element);
                });
               }                 
           })
           .catch((error) => {
               if (error.response) {
                 this.showMessage(this.$tc('Shared.Patient'),error,"error");
                }
           });
       }

       if(this.$store.state.legalStatuswasloaded){
        this.legal_status_list = this.$store.state.legalStatusList;
       }else{
        GlobalService.getLegalStatus()
           .then((response) => {
               if(response.status == '200'){
                this.legal_status_list = response.data;
                this.$store.commit('initLegalStatusList');
                this.$store.commit('setlegalStatuswasloaded',true);  
                response.data.forEach(element => {
                  this.$store.commit('setLegalStatusList',element);
                });
               }                
           })
           .catch((error) => {
               if (error.response) {
                 this.showMessage(this.$tc('Shared.Patient'),error,"error");
                }
           });
       }

       if(this.$store.state.educationStatuswasloaded){
        this.education_status_list = this.$store.state.educationStatusList;
       }else{
        GlobalService.getEducationStatus()
           .then((response) => {
               if(response.status == '200'){
                this.education_status_list = response.data;
                this.$store.commit('initEducationStatusList');
                this.$store.commit('seteducationStatuswasloaded',true); 
                response.data.forEach(element => {
                  this.$store.commit('setEducationStatusList',element);
                });
               }          
           })
           .catch((error) => {
               if (error.response) {
                 this.showMessage(this.$tc('Shared.Patient'),error,"error");
                }
           });
       }

      if (this.edit_mode) {        
            this.id = this.$route.params.id;
            this.$emit('load', this.Loading); 
            this.getPatient(this.id);
      }
    },

    validate_required() {
        this.isLastNameValid = true;
        this.isFirstNameValid = this.patient.firstname && this.patient.firstname.trim() != "" && this.patient.firstname.length > 0 ? true : false;
        this.isLastNameValid = this.patient.lastname && this.patient.lastname.trim() != "" && this.patient.lastname.length > 0 ? true : false;
        this.isDOBValid = Utils.isValidDate(this.patient.dob) ? true : false;        
        this.isAutoAssignCodeValid = (this.autoAssignCode)? true : this.patient.patientcode && this.patient.patientcode.trim() != "" && this.patient.patientcode.length > 0 ? true : false;
        return this.isFirstNameValid && this.isLastNameValid && this.isDOBValid && this.isReferralFromValid && this.isReferralToValid && this.isAutoAssignCodeValid

      },

      validate_entries() {
        this.isDOBValid = Utils.validateBirthDate(this.patient.dob) ? true : false;
        /*if(!this.isDOBValid){
            EventBus.$emit('onCheckValid', this.patient.dob,"date","input_patient_date","DOB");
        }*/
        this.emailValid = (this.patient.email == "" || this.patient.email == null)? true : (this.reg.test(this.patient.email)) ? true : false;

        EventBus.$emit('onCheckValid', this.patient.email,"email","input_patient_email","EMAIL");

       if( this.isReferralFromValid && this.isReferralToValid && this.isCommunicationLiaisonValid &&
           this.isAttendingDoctorValid && this.isReferringDoctorValid && this.isServiceLocationValid &&
           this.isCaseManagerValid && this.isMatrixValid && this.isDiagnosis1Valid && 
           this.isDiagnosis2Valid && this.isDiagnosis3Valid && this.isDiagnosis4Valid &&
           this.isDiagnosis5Valid && this.isDiagnosis6Valid && this.isDiagnosis7Valid &&
           this.isDiagnosis8Valid && this.isDiagnosis9Valid && this.isDiagnosis10Valid &&
           this.isDiagnosis11Valid && this.isDiagnosis12Valid && this.isDiagnosis13Valid &&
           this.isDiagnosis14Valid && this.isDiagnosis15Valid && this.isDiagnosis16Valid &&
           this.isCondition1Valid && this.isCondition2Valid && this.isCondition3Valid &&
           this.isCondition4Valid && this.isCondition5Valid && this.isCondition6Valid &&
           this.isCondition7Valid  && this.emailValid && this.isDOBValid) {
            return true;
           } else {
            return false;   
           }
      },
        
        focusOut() {
            this.patient.familyIncomeAmt = this.formattedCurrencyValue ? parseFloat(this.formattedCurrencyValue.toString().replace("$ ", "")): null
            if (isNaN(this.patient.familyIncomeAmt)) {
                this.patient.familyIncomeAmt = 0
            }
            this.formattedCurrencyValue = this.formattedCurrencyValue ? "$ " + this.patient.familyIncomeAmt.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,"): null
        },

        focusIn() {
            this.formattedCurrencyValue = this.patient.familyIncomeAmt;
        },

        focusOut1() {
            this.patient.personalIncomeAmt = this.formattedCurrencyValue1 ? parseFloat(this.formattedCurrencyValue1.replace("$ ", "")): null
            if (isNaN(this.patient.personalIncomeAmt)) {
                this.patient.personalIncomeAmt = 0
            }
            this.formattedCurrencyValue1 = this.formattedCurrencyValue1 ? "$ " + this.patient.personalIncomeAmt.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,"): null
        },

        focusIn1() {
            this.formattedCurrencyValue1 = this.patient.personalIncomeAmt;
        },

        formatterDate(value){
            return Utils.formatterDate(value);
        },

        formatterDateToString(date){
            return Utils.formatterDateToString(date);
        },

        formatterStringToDate(stringDate){                   
            if(stringDate){
                var dateArray = stringDate.split("/");
                var dd= dateArray[1];
                var mm= "";
                if(dateArray[0].lenght == 1){
                    mm= "0"+dateArray[0];
                }else
                    mm= dateArray[0];
                var yyyy= dateArray[2];
                var newDate =  new Date(yyyy, mm, dd).toISOString();                
                return newDate;
            }
        },

        EnabledItemRightMenu(){
            this.OptionsRightMenu.PatientCode = this.patient.patientcode;
            this.OptionsRightMenu.ItemsList = EnumService.PatientItems;
            this.OptionsRightMenu.visible = true;    
            this.OptionsRightMenu.btnvisible = true;
            this.OptionsRightMenu.itemdisabled = false;
            let layoutStatus = this.$store.state.layoutStatus;
            switch(layoutStatus) {
                case 'L0R0':
                    this.$store.commit('setlayoutStatus', 'L0R1');
                break;
                default:
                    this.$store.commit('setlayoutStatus', 'L1R1');
            }
            this.$emit('getVisibleMenu', this.OptionsRightMenu); 
        },

        UpdatePatient(){   
              if(this.id == '' || this.id == null || this.id == undefined){
                 this.id = this.patient.patientcode;
             }
            PatientService.update( this.id, this.patient)
             .then((response) => {
                 if(response.status == '200' && response.data){
                      this.patient = response.data;
                      this.patient.dob = this.formatterDate(this.patient.dob);
                      this.patient.dateDeceased = this.formatterDate(this.patient.dateDeceased);
                      this.patient.sgDate = this.formatterDate(this.patient.sgDate);                     
                      this.PupulateInfoHeaderRightMenu();
                      this.EmitEventInfoHeaderRightMenu();                     
                      this.$bvToast.toast(this.$t('Msg.SavedSuccessfully'), {
                         title: this.$t('Patient.SavingPatient'), 
                          autoHideDelay: 5000,
                          variant: "success"
                      })

                      this.GetPatientByCode();
                      EventBus.$emit('UpdateMarqueeRightBar',null);
                 }
                  this.$emit('load', false);   
             })
            .catch((error) => {
                 this.$emit('load', false);
                  elemSaveBtn.removeAttribute("disabled");
                 if (!error.response) {
                   this.showMessage(this.$tc('Shared.Patient'),this.$t('Msg.NotAbleToUpdate'),"error");
                 } else {
                   this.showMessage(this.$tc('Shared.Patient'),error.response.data.errorMessage,"error");
                 }
                 this.$route.router.go('/');
            }); 
        },

        InsertPatient(){        
        PatientService.addPatient(this.patient)
            .then((response) => {
                if(response.status == '200'){
                    this.patient = response.data;                  
                    this.CountSave = 1;    
                    this.edit_mode = true;
                    this.autoAssignCode = true;
                    this.EnabledItemRightMenu();                
                    this.patient.dob = this.formatterDate(this.patient.dob);
                    this.patient.dateDeceased = this.formatterDate(this.patient.dateDeceased);
                    this.patient.sgDate = this.formatterDate(this.patient.sgDate);                   
                    if(this.$route.name !== 'Clinical'){
                        this.$router.push({ name: "patient-details", params: { id: this.patient.patientcode } });  
                    }                    
                    this.PupulateInfoHeaderRightMenu();
                    this.EmitEventInfoHeaderRightMenu();
                    this.$bvToast.toast(this.$t('Msg.SavedSuccessfully'), {
                        title: this.$t('Patient.SavingPatient'),
                        autoHideDelay: 5000,
                        variant: "success"
                    })
                }
                  this.$emit('load', false);
            })
            .catch((error) => {
                 this.$emit('load', false);
                 elemSaveBtn.removeAttribute("disabled");
                 if (!error.response) {
                   this.showMessage(this.$tc('Shared.Patient'),this.$t('Msg.NotAbleToInsert'),"error");
                 } 
                 else {
                   this.showMessage(this.$tc('Shared.Patient'),error.response.data.errorMessage,"error");
                 }
                 this.$route.router.go('/');
            }); 
        },

         ValidateAllDate(){           
            if(this.patient.dob == ""){
                this.patient.dob = null;
            }
            if(this.patient.dateDeceased == ""){
                this.patient.dateDeceased = null;
             }
            if(this.patient.sgDate == ""){
                this.patient.sgDate = null;
             }
        },

        Save(){
         if(this.validate_entries()){
           if(this.validate_required()){           
           this.getModelBillToCompany();
           this.ValidateEmptyFieldsNmeric();
           this.ValidateAllDate();

           
            elemSaveBtn.setAttribute("disabled",'');
           this.pendingChanges = false;
           EventBus.$emit('HaveChanges', false);
            if(this.edit_mode || this.CountSave > 0){
                this.$emit('load', true);
                this.UpdatePatient();
              }else{
                const patientData = {
                    LastName: this.patient.lastname,
                    FirstName: this.patient.firstname,
                    DOB: this.patient.dob
                }     
                PatientService.getExistPatient(patientData)
                    .then((response) => {
                        if (response.data.length > 0) {                            
                            this.$bvModal.msgBoxConfirm(this.$t('Patient.SimilarPatientAlreadyExists', { patientcode: response.data[0].patientcode })
                                , {
                                title: this.$t('Patient.MatchingPatient'),
                                okTitle: this.$t('Shared.BtnCreate'),
                                cancelTitle: this.$t('Shared.BtnCancel'),
                                footerClass: 'p-2',
                                titleClass: 'modal-title modal-type-warning',
                                id: 'modal_patient_exist'
                            })
                            .then(value => {
                                if(value){
                                    this.$emit('load', true); 
                                    this.InsertPatient();                                  
                                } else {
                                    return;
                                }
                            });
                        }else{
                            this.$emit('load', true); 
                            this.InsertPatient();                            
                        }
                    });  
              }   
           } else
           {
               this.showMessage(this.$tc('Shared.Patient'),this.$t('Msg.CheckRedLabeledFields'),"errorAutoHide");
               this.$route.router.go('/');
           } 
         } else {
               this.showMessage(this.$tc('Shared.Patient'),this.$t('Msg.InvalidFieldValuesDetected'),"errorAutoHide");
               //this.$route.router.go('/');
         }          
        },

        onCodeConditionOk(result){   
            var type = result.FieldName;
            if(type.indexOf('Condition') >= 0) {
                switch (type) {
                    case "Condition1":  
                        this.patient.conditioncode1 = result.code;
                        this.condition1 = result.description;  
                    break;
                    case "Condition2":
                        this.patient.conditioncode2 = result.code;
                        this.condition2 = result.description; 
                    break;
                    case "Condition3":
                        this.patient.conditioncode3 = result.code;
                        this.condition3 = result.description; 
                    break;
                    case "Condition4":
                        this.patient.conditioncode4 = result.code;
                        this.condition4 = result.description; 
                    break;
                    case "Condition5":
                        this.patient.conditioncode5 = result.code;
                        this.condition5 = result.description; 
                    break;
                    case "Condition6":
                        this.patient.conditioncode6 = result.code;
                        this.condition6 = result.description; 
                    break;
                    default:
                        this.patient.conditioncode7 = result.code;
                        this.condition7 = result.description; 
                }
            }else if(type.indexOf('Diagnosis') >= 0)  {
                switch (type) {
                    case "Diagnosis1": 
                        this.patient.diagcode1 = result.code;   
                        this.diagnosis1.description = result.description;  
                    break;
                    case "Diagnosis2":
                        this.patient.diagcode2 = result.code;  
                        this.diagnosis2.description = result.description; 
                    break;
                    case "Diagnosis3":
                        this.patient.diagcode3 = result.code;  
                        this.diagnosis3.description = result.description; 
                    break;
                    case "Diagnosis4":
                        this.patient.diagcode4 = result.code;  
                        this.diagnosis4.description = result.description; 
                    break;
                    case "Diagnosis5":
                        this.patient.diagcode5 = result.code;  
                        this.diagnosis5.description = result.description; 
                    break;
                    case "Diagnosis6":
                        this.patient.diagcode6 = result.code;  
                        this.diagnosis6.description = result.description; 
                    break;
                    case "Diagnosis7":
                        this.patient.diagcode7 = result.code;  
                        this.diagnosis7.description = result.description; 
                    break;
                    case "Diagnosis8":
                        this.patient.diagcode8 = result.code;  
                        this.diagnosis8.description = result.description; 
                    break;
                    case "Diagnosis9":
                        this.patient.diagcode9 = result.code;  
                        this.diagnosis9.description = result.description; 
                    break;
                    case "Diagnosis10":
                        this.patient.diagcode10 = result.code;  
                        this.diagnosis10.description = result.description; 
                    break;
                    case "Diagnosis11":
                        this.patient.diagcode11 = result.code;  
                        this.diagnosis11.description = result.description; 
                    break;
                    case "Diagnosis12":
                        this.patient.diagcode12 = result.code;  
                        this.diagnosis12.description = result.description; 
                    break;
                    case "Diagnosis13":
                        this.patient.diagcode13 = result.code;  
                        this.diagnosis13.description = result.description; 
                    break;
                    case "Diagnosis14":
                        this.patient.diagcode14 = result.code;  
                        this.diagnosis14.description = result.description; 
                    break;
                    case "Diagnosis15":
                        this.patient.diagcode15 = result.code;  
                        this.diagnosis15.description = result.description; 
                    break;
                    default:
                        this.patient.diagcode16 = result.code;  
                        this.diagnosis16.description = result.description; 
                }
            }else if(type.indexOf('Referral') >= 0){
                switch (type) {
                    case "ReferralFrom":  
                        this.ReferralFrom.description = result.description;
                        this.patient.referal = result.code; 
                    break;
                    default:
                        this.ReferralTo.description = result.description;
                        this.patient.referalTo = result.code;  
                }
            }else if(type.indexOf('Doctor') >= 0){
                switch (type) {
                    case "AttendingDoctor":  
                        this.AttendingDoctor.description = result.description;
                        this.patient.doctorcode = result.code;  
                    break;
                    default:
                        this.ReferringDoctor.description = result.description;
                        this.patient.privdoctorcode = result.code;  
                }
            }
                else if(type.indexOf('ServiceLocation') >= 0){           
                        this.ServiceLocation.description = result.description;
                        this.patient.facilitycode = result.code;
                } else if(type.indexOf('CaseManager') >= 0){           
                        this.CaseManager.description = result.description;
                        this.patient.employeecode = result.code;
                }else if(type.indexOf('CommunicationLiaison') >= 0){           
                        this.CommunicationLiaison.description = result.description;
                        this.patient.commLiason = result.code;
                }else if(type.indexOf('Matrix') >= 0){           
                        this.Matrix.description = result.description;
                        this.patient.matrix = result.code;
                }
        },

        onCodeConditionCancel(){
            this.Condition1 ? this.Condition1.code = '' : null
            this.Condition1 ? this.Condition1.description = '' : null
        },  

        CallModalTable: async function (message) {
            this.$emit('load', true);
            if(message.indexOf('Condition') >= 0){  
                this.TitleWindow=this.$t('Patient.TitleConditions');
                this.TypeOption = message;   
                this.ResourceParameter = '/conditions';
                this.fieldCode = 'code'; 
                this.fieldDescription = 'shortDescription';               
            }else if(message.indexOf('Referral') >= 0){ 
                this.TitleWindow=this.$t('Patient.TitleReferrals');       
                this.FieldsParameter = ['name','code','address1','email'];
                this.ResourceParameter = '/Referrals',  
                this.TypeOption = message;
                this.fieldCode = 'code'; 
                this.fieldDescription = 'name';       
            }else if(message.indexOf('Doctor') >= 0){
                this.TitleWindow=this.$t('Shared.Doctors');
                this.FieldsParameter = ['lastName','firstName','code'];
                this.ResourceParameter = '/Doctors',  
                this.TypeOption = message;      
                this.$bvModal.show('bv-modal-table-paginationServer');
                this.fieldCode = 'code'; 
                this.fieldDescription = 'name';           
            }else if(message.indexOf('ServiceLocation') >= 0){
                this.TitleWindow=this.$t('Patient.TitleServiceLocations');
                this.FieldsParameter = ['address1','address2','code','company'];
                this.ResourceParameter = '/Facilities',  
                this.TypeOption = message;
                this.fieldCode = 'code'; 
                this.fieldDescription = 'company';   
            }else if(message.indexOf('CaseManager') >= 0){ 
                this.TitleWindow=this.$tc('Shared.Employee',2);           
                this.FieldsParameter = ['address1','address2','code','lastName','fisrtName'];
                this.ResourceParameter = '/Employees',  
                this.TypeOption = message;
                this.fieldCode = 'code'; 
                this.fieldDescription = 'name';     
            }else if(message.indexOf('CommunicationLiaison') >= 0){ 
                this.TitleWindow=this.$t('Patient.TitleCommunicationsLiason');           
                this.FieldsParameter = ['address1','address2','code','lastName','fisrtName'];
                this.ResourceParameter = '/CommLiason',  
                this.TypeOption = message;
                this.fieldCode = 'code'; 
                this.fieldDescription = 'name';
            }else if(message.indexOf('Matrix') >= 0){   
                this.TitleWindow=this.$t('Patient.TitleMatrixes');        
                this.FieldsParameter = ['code','name'];
                this.ResourceParameter = '/Matrixes',  
                this.TypeOption = message;
                this.fieldCode = 'code'; 
                this.fieldDescription = 'name';
            }
            await this.$bvModal.show('bv-modal-table-paginationServer');
            this.$emit('load', false);
        },

       getDescriptionByConditionCode(code,ConditionNumber){

           if(code != null && code != ''){
           Condition.getConditionByCode(code)
                .then((response) => {
                    if(response.status == '200' && response.data && response.data.length > 0){
                        this.TempConditionByCode = response.data;
                      switch (ConditionNumber) {
                            case "Condition1":  
                                this.condition1 = this.TempConditionByCode[0] ? this.TempConditionByCode[0].shortDescription : null; 
                                break;
                            case "Condition2":
                                this.condition2 = this.TempConditionByCode[0] ? this.TempConditionByCode[0].shortDescription : null; 
                                break;
                            case "Condition3":
                                this.condition3 = this.TempConditionByCode[0] ? this.TempConditionByCode[0].shortDescription : null;  
                                break;
                            case "Condition4":
                                this.condition4 = this.TempConditionByCode[0] ? this.TempConditionByCode[0].shortDescription : null; 
                                break;
                            case "Condition5":
                                this.condition5 = this.TempConditionByCode[0] ? this.TempConditionByCode[0].shortDescription : null;  
                                break;
                            case "Condition6":
                                this.condition6 = this.TempConditionByCode[0] ? this.TempConditionByCode[0].shortDescription : null;  
                                break;
                            default:
                                this.condition7 = this.TempConditionByCode[0] ? this.TempConditionByCode[0].shortDescription : null; 
                      }
                    }                     
                 })
                .catch((error) => {
                    if (error.response) {
                        this.showMessage(this.$tc('Shared.Patient'),error,"error");
                    }
                });
           }

        },

        getAllDescription(){   
            if(this.patient.conditioncode1 != '' || this.patient.conditioncode1 != null){                
                this.getDescriptionByConditionCode(this.patient.conditioncode1,'Condition1');              
            }if(this.patient.conditioncode2 != ''){               
                this.getDescriptionByConditionCode(this.patient.conditioncode2,'Condition2');     
            }if(this.patient.conditioncode3 != ''){               
                this.getDescriptionByConditionCode(this.patient.conditioncode3,'Condition3');     
            }if(this.patient.conditioncode4 != ''){               
                this.getDescriptionByConditionCode(this.patient.conditioncode4,'Condition4');     
            }if(this.patient.conditioncode5 != ''){               
                this.getDescriptionByConditionCode(this.patient.conditioncode5,'Condition5');     
            }if(this.patient.conditioncode6 != ''){               
                this.getDescriptionByConditionCode(this.patient.conditioncode6,'Condition6');     
            }if(this.patient.conditioncode7 != ''){               
                this.getDescriptionByConditionCode(this.patient.conditioncode7,'Condition7');     
            }
        },

        PupulateInfoHeaderRightMenu(){
            this.InfoPatient.Dob = this.patient.dob;
            this.InfoPatient.FirstName = this.patient.firstname;
            this.InfoPatient.LastName = this.patient.lastname;
            this.InfoPatient.Sex = this.patient.sex;
            this.InfoPatient.Age = this.patient.age;
            this.InfoPatient.Title = this.patient.title;
            this.InfoPatient.Email = this.patient.email;
            this.InfoPatient.CellPhone = this.patient.cellPhone;
            if(this.InfoPatient.Sex == 'F'){
                 this.InfoPatient.SexExtend = this.$t('Shared.Female')
            }
            if(this.InfoPatient.Sex == 'M'){
                 this.InfoPatient.SexExtend = this.$t('Shared.Male')
            }
        },

        EmitEventInfoHeaderRightMenu(){
            this.$emit('getInfoPatient', this.InfoPatient); 
        },

        getPatient( id ) {   
            // Get patient data
            PatientService.get( id)
            .then((response) => {
                if(response.status == '200' && response.data){
                    this.patient = response.data;
                    this.getLookupFieldsDesc();  
                 //   this.getDiagnoses();             
                    this.getAllDescription();
                    this.PupulateInfoHeaderRightMenu();
                    this.EmitEventInfoHeaderRightMenu();
                    this.patient.dob = this.formatterDate(this.patient.dob);
                    //this.patient.dob = moment( this.patient.dob).format('MM/DD/YYYY');
                    this.patient.dateDeceased = this.formatterDate(this.patient.dateDeceased);
                    this.patient.sgDate = this.formatterDate(this.patient.sgDate);
                    this.formattedCurrencyValue = this.patient.familyIncomeAmt ? "$ "+ this.patient.familyIncomeAmt : "";
                    this.formattedCurrencyValue1 = this.patient.personalIncomeAmt ? "$ "+ this.patient.personalIncomeAmt : "";
                    this.focusOut();
                    this.focusOut1();
                    //var valueOption="value";
                    //var descOption="text";
                    //this.valueTitle =this.patient.title;
                   // this.valueTitle[descOption] =this.patient.title;
                    //this.valueTitle = { [valueOption ? valueOption : 'value']: this.patient.title, [descOption ? descOption : 'text']: this.patient.title};
                    this.getAgeFormatted()
                   
                }
                 this.Loading = false;     
                 this.$emit('load', this.Loading); 
            })
            .catch((error) => {
              if (!error.response) {
                // network error
                this.showMessage(this.$tc('Shared.Patient'),this.$t('Msg.retrieveInfo'),"error");
              } else {
                this.showMessage(this.$tc('Shared.Patient'),error,"error");
              }
              this.Loading = false;
              this.$emit('load', this.Loading);
            });  
        },

        getLookupFieldsDesc(){
             if(this.patient.referal!=null && this.patient.referal!="" ){
                // Get patient data
                PatientService.getReferal(this.patient.referal)
                .then((response) => {
                     if(response.status == '200' && response.data && response.data.length > 0){
                        this.ReferralFrom.description = response.data[0].name;   
                     }             
                })
                .catch((error) => {
                    if (error.response) {
                      this.showMessage(this.$tc('Shared.Patient'),error,"error");
                    }
                });  
             }

            if(this.patient.referalTo!=null && this.patient.referalTo!="" ){
                // Get patient data
                PatientService.getReferal(this.patient.referalTo)
                .then((response) => {
                     if(response.status == '200' && response.data && response.data.length > 0){
                        this.ReferralTo.description = response.data[0].name;  
                     }              
                })
                .catch((error) => {
                    if (error.response) {
                        this.showMessage(this.$tc('Shared.Patient'),error,"error");
                    }
                });  
            }

            if(this.patient.commLiason!=null && this.patient.commLiason!="" ){
                // Get patient data
                PatientService.getCommunicationLiaison(this.patient.commLiason)
                .then((response) => {
                    if(response.status == '200' && response.data && response.data.length > 0){
                        this.CommunicationLiaison.description = response.data[0].name;      
                    }          
                })
                .catch((error) => {
                    if (error.response) {
                        this.showMessage(this.$tc('Shared.Patient'),error,"error");
                    }
                });  
            }

            if(this.patient.matrix!=null && this.patient.matrix!="" ){
                // Get patient data
                PatientService.getMatrix(this.patient.matrix)
                .then((response) => {
                    if(response.status == '200' && response.data && response.data.length > 0){
                        this.Matrix.description = response.data[0].name;    
                    }            
                })
                .catch((error) => {
                    if (error.response) {
                        this.showMessage(this.$tc('Shared.Patient'),error,"error");
                    }
                });  
            }
            
            if(this.patient.doctorcode!=null && this.patient.doctorcode!="" ){
                // Get patient data
                PatientService.getDoctor(this.patient.doctorcode)
                .then((response) => {
                     if(response.status == '200' && response.data && response.data.length > 0){
                        this.AttendingDoctor.description = response.data[0].name; 
                     }               
                })
                .catch((error) => {
                    if (error.response) {
                        this.showMessage(this.$tc('Shared.Patient'),error,"error");
                    }
                });  
            }

            if(this.patient.privdoctorcode!=null && this.patient.privdoctorcode!="" ){
                // Get patient data
                PatientService.getDoctor(this.patient.privdoctorcode)
                .then((response) => {
                     if(response.status == '200' && response.data && response.data.length > 0){
                        this.ReferringDoctor.description = response.data[0].name; 
                     }               
                })
                .catch((error) => {
                    if (error.response) {
                        this.showMessage(this.$tc('Shared.Patient'),error,"error");
                    }
                });  
            }

            if(this.patient.facilitycode!=null && this.patient.facilitycode!="" ){
                // Get patient data
                PatientService.getFacilities(this.patient.facilitycode)
                .then((response) => {
                     if(response.status == '200' && response.data && response.data.length > 0){
                        this.ServiceLocation.description = response.data[0].company; 
                     }               
                })
                .catch((error) => {
                    if (error.response) {
                        this.showMessage(this.$tc('Shared.Patient'),error,"error");
                    }
                });  
            }

            if(this.patient.employeecode!=null && this.patient.employeecode!="" ){
                // Get patient data
                PatientService.getEmployees(this.patient.employeecode)
                .then((response) => {
                     if(response.status == '200' && response.data && response.data.length > 0){
                        this.CaseManager.description = response.data[0].name;  
                     }                            
                })
                .catch((error) => {
                    if (error.response) {
                        this.showMessage(this.$tc('Shared.Patient'),error,"error");
                    }
                });  
            }

            if(this.patient.diagcode1!=null && this.patient.diagcode1!="" ){
                PatientService.getDiagnosis(this.patient.diagcode1)
                .then((response) => {
                    if(response.status == '200' && response.data && response.data.length > 0){
                        this.diagnosis1.description = response.data[0].description;  
                    }                            
                })
                .catch((error) => {
                    if (error.response) {
                        this.showMessage(this.$tc('Shared.Patient'),error,"error");
                    }
                });  
            }

            if(this.patient.diagcode2!=null && this.patient.diagcode2!="" ){
                PatientService.getDiagnosis(this.patient.diagcode2)
                .then((response) => {
                    if(response.status == '200' && response.data && response.data.length > 0){
                        this.diagnosis2.description = response.data[0].description;    
                    }                          
                })
                .catch((error) => {
                    if (error.response) {
                        this.showMessage(this.$tc('Shared.Patient'),error,"error");
                    }
                });  
            }

            if(this.patient.diagcode3!=null && this.patient.diagcode3!="" ){
                PatientService.getDiagnosis(this.patient.diagcode3)
                .then((response) => {
                    if(response.status == '200' && response.data && response.data.length > 0){
                        this.diagnosis3.description = response.data[0].description; 
                    }                             
                })
                .catch((error) => {
                    if (error.response) {
                        this.showMessage(this.$tc('Shared.Patient'),error,"error");
                    }
                });  
            }

            if(this.patient.diagcode4!=null && this.patient.diagcode4!="" ){
                PatientService.getDiagnosis(this.patient.diagcode4)
                .then((response) => {
                    if(response.status == '200' && response.data && response.data.length > 0){
                        this.diagnosis4.description = response.data[0].description;  
                    }                            
                })
                .catch((error) => {
                    if (error.response) {
                        this.showMessage(this.$tc('Shared.Patient'),error,"error");
                    }
                });  
            }

            if(this.patient.diagcode5!=null && this.patient.diagcode5!="" ){
                PatientService.getDiagnosis(this.patient.diagcode5)
                .then((response) => {
                    if(response.status == '200' && response.data && response.data.length > 0){
                        this.diagnosis5.description = response.data[0].description; 
                    }                             
                })
                .catch((error) => {
                    if (error.response) {
                        this.showMessage(this.$tc('Shared.Patient'),error,"error");
                    }
                });  
            }

            if(this.patient.diagcode6!=null && this.patient.diagcode6!="" ){
                PatientService.getDiagnosis(this.patient.diagcode6)
                .then((response) => {
                    if(response.status == '200' && response.data && response.data.length > 0){
                        this.diagnosis6.description = response.data[0].description; 
                    }                             
                })
                .catch((error) => {
                    if (error.response) {
                        this.showMessage(this.$tc('Shared.Patient'),error,"error");
                    }
                });  
            }

            if(this.patient.diagcode7!=null && this.patient.diagcode7!="" ){
                PatientService.getDiagnosis(this.patient.diagcode7)
                .then((response) => {
                    if(response.status == '200' && response.data && response.data.length > 0){
                        this.diagnosis7.description = response.data[0].description;
                    }                              
                })
                .catch((error) => {
                    if (error.response) {
                        this.showMessage(this.$tc('Shared.Patient'),error,"error");
                    }
                });  
            }

            if(this.patient.diagcode8!=null && this.patient.diagcode8!="" ){
                PatientService.getDiagnosis(this.patient.diagcode8)
                .then((response) => {
                    if(response.status == '200' && response.data && response.data.length > 0){
                        this.diagnosis8.description = response.data[0].description;
                    }                              
                })
                .catch((error) => {
                    if (error.response) {
                        this.showMessage(this.$tc('Shared.Patient'),error,"error");
                    }
                });  
            }

            if(this.patient.diagcode9!=null && this.patient.diagcode9!="" ){
                PatientService.getDiagnosis(this.patient.diagcode9)
                .then((response) => {
                    if(response.status == '200' && response.data && response.data.length > 0){
                        this.diagnosis9.description = response.data[0].description;  
                    }                            
                })
                .catch((error) => {
                    if (error.response) {
                        this.showMessage(this.$tc('Shared.Patient'),error,"error");
                    }
                });  
            }

            if(this.patient.diagcode10!=null && this.patient.diagcode10!="" ){
                PatientService.getDiagnosis(this.patient.diagcode10)
                .then((response) => {
                    if(response.status == '200' && response.data && response.data.length > 0){
                        this.diagnosis10.description = response.data[0].description;
                    }                              
                })
                .catch((error) => {
                    if (error.response) {
                        this.showMessage(this.$tc('Shared.Patient'),error,"error");
                    }
                });  
            }

            if(this.patient.diagcode11!=null && this.patient.diagcode11!="" ){
                PatientService.getDiagnosis(this.patient.diagcode11)
                .then((response) => {
                    if(response.status == '200' && response.data && response.data.length > 0){
                        this.diagnosis11.description = response.data[0].description;
                    }                              
                })
                .catch((error) => {
                    if (error.response) {
                        this.showMessage(this.$tc('Shared.Patient'),error,"error");
                    }
                });  
            }

            if(this.patient.diagcode12!=null && this.patient.diagcode12!="" ){
                PatientService.getDiagnosis(this.patient.diagcode12)
                .then((response) => {
                    if(response.status == '200' && response.data && response.data.length > 0){
                        this.diagnosis12.description = response.data[0].description; 
                    }                             
                })
                .catch((error) => {
                    if (error.response) {
                        this.showMessage(this.$tc('Shared.Patient'),error,"error");
                    }
                });  
            }

            if(this.patient.diagcode13!=null && this.patient.diagcode13!="" ){
                PatientService.getDiagnosis(this.patient.diagcode13)
                .then((response) => {
                    if(response.status == '200' && response.data && response.data.length > 0){
                        this.diagnosis13.description = response.data[0].description; 
                    }                             
                })
                .catch((error) => {
                    if (error.response) {
                        this.showMessage(this.$tc('Shared.Patient'),error,"error");
                    }
                });  
            }

            if(this.patient.diagcode14!=null && this.patient.diagcode14!="" ){
                PatientService.getDiagnosis(this.patient.diagcode14)
                .then((response) => {
                    if(response.status == '200' && response.data && response.data.length > 0){
                        this.diagnosis14.description = response.data[0].description;
                    }                              
                })
                .catch((error) => {
                    if (error.response) {
                        this.showMessage(this.$tc('Shared.Patient'),error,"error");
                    }
                });  
            }

            if(this.patient.diagcode15!=null && this.patient.diagcode15!="" ){
                PatientService.getDiagnosis(this.patient.diagcode15)
                .then((response) => {
                    if(response.status == '200' && response.data && response.data.length > 0){
                        this.diagnosis15.description = response.data[0].description;   
                    }                           
                })
                .catch((error) => {
                    if (error.response) {
                        this.showMessage(this.$tc('Shared.Patient'),error,"error");
                    }
                });  
            }

            if(this.patient.diagcode16!=null && this.patient.diagcode16!="" ){
                PatientService.getDiagnosis(this.patient.diagcode16)
                .then((response) => {
                    if(response.status == '200' && response.data && response.data.length > 0){
                        this.diagnosis16.description = response.data[0].description; 
                    }                             
                })
                .catch((error) => {
                    if (error.response) {
                        this.showMessage(this.$tc('Shared.Patient'),error,"error");
                    }
                });  
            }
        },

        patientZipBlur: function (value) {
            if (this.patientZipChanged && value.trim()!== '') {
            this.$emit('load', true); 
            GlobalService.getCityAndStateByZipCode(value)
                .then((response) => {
                    if(response.status == '200' && response.data){
                        var obj = response.data
                       if(obj.city) {
                            this.patient.city = obj.city;
                            this.patient.state = obj.state;
                        }
                        this.patientZipChanged = false;
                    }  
                     this.$emit('load', false);                          
                })
                .catch((error) => {
                    this.$emit('load', false); 
                    this.patient.city = '';
                    this.patient.state = '';
                  if(error.response.status !== 404 && error.response.status !== 500){
                      if(error.response) {
                        this.showMessage(this.$t('Msg.GetCityAndState'),error,"error");
                    }
                  }
                    
                });  
            }
        },

        patientZipChange: function() {
                this.patientZipChanged = true;
        },

        patientLegalZipBlur: function (value) {
            if (this.patientLegalZipChanged && value.trim()!== '') {
             this.$emit('load', true); 
            GlobalService.getCityAndStateByZipCode(value)
                .then((response) => {
                    if(response.status == '200' && response.data){
                        var obj = response.data
                       if(obj.city) {
                            this.patient.legalCity = obj.city;
                            this.patient.legalState = obj.state;
                        }
                    this.patientZipChanged = false;
                    }  
                    this.$emit('load', false);                          
                })
                .catch((error) => {
                    this.$emit('load', false); 
                    this.patient.legalCity = '';
                    this.patient.legalState = '';
                   if(error.response.status !== 404 && error.response.status !== 500){
                      if(error.response) {
                        this.showMessage(this.$t('Msg.GetCityAndState'),error,"error");
                    }
                  }
                });  
            }
        },

        patientLegalZipChange: function() {
                this.patientLegalZipChanged = true;
        },

        onlyFloat (evento) {
            let keyCode = (evento.keyCode ? evento.keyCode : evento.which);
            // only allow number and one dot
            if ((keyCode < 48 || keyCode > 57) && ((keyCode == 44 ))) { // 46 is dot, 44 is the comma
                evento.preventDefault();
            }
        },

        ValidateEmptyFieldsNmeric(){
            if(this.patient.heights == ''){
                this.patient.heights = null;
            }
             if(this.patient.weight == ''){
                this.patient.weight = null;
            }
             if(this.patient.EthcodeGranularity == ''){
                this.patient.EthcodeGranularity = null;
            }
             if(this.patient.numberDependents == ''){
                this.patient.numberDependents = null;
            }
             if(this.patient.personalIncomeAmt == ''){
                this.patient.personalIncomeAmt = null;
            }
            if(this.patient.familyIncomeAmt == ''){
                this.patient.familyIncomeAmt = null;
            }
        },

        onlyInteger (evento) {
            let keyCode = (evento.keyCode ? evento.keyCode : evento.which);
            // only allow number
            if ((keyCode < 48 || keyCode > 57)) {
                evento.preventDefault();
            }
        },

        defaultAddValues(){
            this.patient.primaryins = "Y";
            this.patient.epsdt = false;
            this.patient.infoRelease = "Y";
            this.patient.medigapRelease = "N";
            this.patient.sex  = "U";
            this.patient.employed  = "8";
            this.patient.acceptAssign  = "C";
            this.patient.deceased  = "N";
            this.patient.married  = "O";
            this.patient.student  = "N";
            this.patient.legalRep  = "N";
            this.patient.billToCompany  = false;
            this.patient.relatedToEmp  = false;
            this.patient.relatedToAuto  = false;
            this.patient.relatedToOther  = false;
            this.patient.emergency  = false;
            this.patient.military  = false;
            this.patient.enablerepeat  = "N";
            this.patient.billfortrans  = "N";
            this.patient.notActive  = "N";
            const now = new Date()
            const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
            this.patient.sgDate  = Utils.formatterDate(today);
            this.patient.icd9  = "N";     
            this.patient.country = this.$store.state.companies.Country

        },

        getModelBillToCompany(){
            if(this.modelBillToCompany.includes('A')){
                this.patient.billToCompany = true;
            }
            if(this.modelBillToCompany.includes('B')){
                this.patient.relatedToAuto = true;
            }
            if(this.modelBillToCompany.includes('C')){
                this.patient.relatedToOther = true;
            }
            if(this.modelBillToCompany.includes('D')){
                this.patient.military = true;
            }
            if(this.modelBillToCompany.includes('E')){
                this.patient.billfortrans = "Y";
            }
        },

        ChangeDoB() {
            this.getAgeFormatted()
        },

        getAgeFormatted() {
            if ( this.patient.dob && Utils.IsValidFieldDate(this.patient.dob)) {
                PatientService.getAgeFormatted(this.patient.dob)
                      .then((response) => {
                        if(response.status == '200'){
                            this.age = response.data;                          	
                        }
                      })
                      .catch((error) => {
                        if(error.response) {
                            this.showMessage("Get Age Formatted",error,"error");
                        }
                      });
            }
        },

        lookupNameByCode: function (control,value) {            
             if (this.lookupFieldChanged) {
                this.getLookup(control,value);
                this.lookupFieldChanged = false;
             }
        },

        lookupFieldChange: function() {
           this.lookupFieldChanged = true;
        },

        lookupEnterEvent: function(control,value) {
            this.lookupNameByCode(control,value);
        },

        getLookup(fieldName,code) {
            let resource = '';
            if(fieldName === 'Condition1'){           
                resource = '/conditions';
                this.condition1 = '';
                this.isCondition1Valid = true;
                this.modalMessage = this.$t('Patient.InvalidConditionCode1');
            }else if(fieldName === 'Condition2'){           
                resource = '/conditions';
                this.condition2 = '';
                this.isCondition2Valid = true;
                this.modalMessage = this.$t('Patient.InvalidConditionCode2');
            }else if(fieldName === 'Condition3'){           
                resource = '/conditions';
                this.condition3 = '';
                this.isCondition3Valid = true;
                this.modalMessage = this.$t('Patient.InvalidConditionCode3'); 
            }else if(fieldName === 'Condition4'){           
                resource = '/conditions';
                this.condition4 = '';
                this.isCondition4Valid = true;
                this.modalMessage = this.$t('Patient.InvalidConditionCode4');
            }else if(fieldName === 'Condition5'){           
                resource = '/conditions';
                this.condition5 = '';
                this.isCondition5Valid = true;
                this.modalMessage = this.$t('Patient.InvalidConditionCode5');
            }else if(fieldName === 'Condition6'){           
                resource = '/conditions';
                this.condition6 = '';
                this.isCondition6Valid = true;
                this.modalMessage = this.$t('Patient.InvalidConditionCode6');
            }else if(fieldName === 'Condition7'){           
                resource = '/conditions';
                this.condition7 = '';
                this.isCondition7Valid = true;
                this.modalMessage = this.$t('Patient.InvalidConditionCode7');                       
            }else if(fieldName === 'ReferralFrom'){        
                resource = '/Referrals';
                this.ReferralFrom.description = '';
                this.isReferralFromValid = true;
                this.modalMessage = this.$t('Patient.InvalidReferralFromCode');
            }else if(fieldName === 'ReferralTo'){        
                resource = '/Referrals';
                this.ReferralTo.description = '';
                this.isReferralToValid = true;
                this.modalMessage = this.$t('Patient.InvalidReferralToCode');        
            }else if(fieldName === 'AttendingDoctor'){
                resource = '/Doctors';
                this.AttendingDoctor.description = '';
                this.isAttendingDoctorValid = true;
                this.modalMessage = this.$t('Patient.InvalidAttendingDoctor');
            }else if(fieldName === 'ReferringDoctor'){
                resource = '/Doctors';
                this.ReferringDoctor.description = '';
                this.isReferringDoctorValid = true;
                this.modalMessage = this.$t('Patient.InvalidReferringDoctor');                 
            }else if(fieldName.indexOf('ServiceLocation') >= 0){
                resource = '/Facilities';
                this.ServiceLocation.description = '';
                this.isServiceLocationValid = true;
                this.modalMessage = this.$t('Patient.InvalidServiceLocation');   
            }else if(fieldName.indexOf('CaseManager') >= 0){            
                resource = '/Employees';
                this.CaseManager.description = '';
                this.isCaseManagerValid = true;
                this.modalMessage = this.$t('Patient.InvalidCaseManager');     
            }else if(fieldName.indexOf('CommunicationLiaison') >= 0){            
                resource = '/CommLiason'; 
                this.CommunicationLiaison.description = '';
                this.isCommunicationLiaisonValid = true;
                this.modalMessage = this.$t('Patient.InvalidCommunicationLiaisonCode') ; 
            }else if(fieldName.indexOf('Matrix') >= 0){            
                resource = '/Matrixes';
                this.Matrix.description = '';
                this.isMatrixValid = true;
                this.modalMessage = this.$t('Patient.InvalidMatrixCode');   
            }

          if (code.length > 0) {
            ModalService.get(resource,code)
             .then((response) => {             
               this.lookupResult = response.data;
               if(this.lookupResult.length > 0) {
                    if(fieldName === 'Condition1'){         
                        this.condition1 = this.lookupResult[0].shortDescription;
                    }else if(fieldName === 'Condition2'){
                        this.condition2 = this.lookupResult[0].shortDescription;
                    }else if(fieldName === 'Condition3'){
                        this.condition3 = this.lookupResult[0].shortDescription;
                    }else if(fieldName === 'Condition4'){
                        this.condition4 = this.lookupResult[0].shortDescription;
                    }else if(fieldName === 'Condition5'){
                        this.condition5 = this.lookupResult[0].shortDescription;
                    }else if(fieldName === 'Condition6'){
                        this.condition6 = this.lookupResult[0].shortDescription;
                    }else if(fieldName === 'Condition7'){
                        this.condition7 = this.lookupResult[0].shortDescription;                                       
                    }else if(fieldName === 'Diagnosis1'){
                        this.diagnosis1.description = this.lookupResult[0].description;
                    }else if(fieldName === 'Diagnosis2'){
                        this.diagnosis2.description = this.lookupResult[0].description;    
                    }else if(fieldName === 'Diagnosis3'){
                        this.diagnosis3.description = this.lookupResult[0].description;
                    }else if(fieldName === 'Diagnosis4'){
                        this.diagnosis4.description = this.lookupResult[0].description;
                    }else if(fieldName === 'Diagnosis5'){
                        this.diagnosis5.description = this.lookupResult[0].description;
                    }else if(fieldName === 'Diagnosis6'){
                        this.diagnosis6.description = this.lookupResult[0].description;
                    }else if(fieldName === 'Diagnosis7'){
                        this.diagnosis7.description = this.lookupResult[0].description;
                    }else if(fieldName === 'Diagnosis8'){
                        this.diagnosis8.description = this.lookupResult[0].description;
                    }else if(fieldName === 'Diagnosis9'){
                        this.diagnosis9.description = this.lookupResult[0].description;
                    }else if(fieldName === 'Diagnosis10'){
                        this.diagnosis10.description = this.lookupResult[0].description;
                    }else if(fieldName === 'Diagnosis11'){
                        this.diagnosis11.description = this.lookupResult[0].description;
                    }else if(fieldName === 'Diagnosis12'){
                        this.diagnosis12.description = this.lookupResult[0].description;
                    }else if(fieldName === 'Diagnosis13'){
                        this.diagnosis13.description = this.lookupResult[0].description;
                    }else if(fieldName === 'Diagnosis14'){
                        this.diagnosis14.description = this.lookupResult[0].description;
                    }else if(fieldName === 'Diagnosis15'){
                        this.diagnosis15.description = this.lookupResult[0].description;
                    }else if(fieldName === 'Diagnosis16'){
                        this.diagnosis16.description = this.lookupResult[0].description;
                    }else if(fieldName === 'ReferralFrom') {
                        this.ReferralFrom.description = this.lookupResult[0].name;
                    }else if(fieldName === 'ReferralTo'){
                        this.ReferralTo.description = this.lookupResult[0].name; 
                    }else if(fieldName === 'AttendingDoctor'){
                        this.AttendingDoctor.description = 
                            `${this.lookupResult[0].lastName} ${this.lookupResult[0].firstName}`; 
                    }else if(fieldName === 'ReferringDoctor'){
                        this.ReferringDoctor.description = 
                            `${this.lookupResult[0].lastName} ${this.lookupResult[0].firstName}`;                  
                    }else if(fieldName.indexOf('ServiceLocation') >= 0){
                        this.ServiceLocation.description = this.lookupResult[0].company;  
                    }else if(fieldName.indexOf('CaseManager') >= 0){            
                        this.CaseManager.description = 
                            `${this.lookupResult[0].lastName} ${this.lookupResult[0].firstName}`;  
                    }else if(fieldName.indexOf('CommunicationLiaison') >= 0){            
                        this.CommunicationLiaison.description = this.lookupResult[0].name;
                        this.isCommunicationLiaisonValid = true;  
                    }else if(fieldName.indexOf('Matrix') >= 0){            
                        this.Matrix.description = this.lookupResult[0].name;
                        this.isMatrixValid = true;  
                    }
               } else {
                    this.showMessage(this.$tc('Shared.Patient'),this.modalMessage,"error");
                    if(fieldName ==='Condition1'){               
                        this.isCondition1Valid = false;
                    }else if(fieldName ==='Condition2'){               
                        this.isCondition2Valid = false;
                    }else if(fieldName ==='Condition3'){               
                        this.isCondition3Valid = false;
                    }else if(fieldName ==='Condition4'){               
                        this.isCondition4Valid = false;
                    }else if(fieldName ==='Condition5'){               
                        this.isCondition5Valid = false;
                    }else if(fieldName ==='Condition6'){               
                        this.isCondition6Valid = false;
                    }else if(fieldName ==='Condition7'){               
                        this.isCondition7Valid = false;                                       
                    }else if(fieldName ==='Diagnosis1'){
                        this.isDiagnosis1Valid = false;
                    }else if(fieldName ==='Diagnosis2'){
                        this.isDiagnosis2Valid = false;      
                    }else if(fieldName ==='Diagnosis3'){
                        this.isDiagnosis3Valid = false; 
                    }else if(fieldName ==='Diagnosis4'){
                        this.isDiagnosis4Valid = false; 
                    }else if(fieldName ==='Diagnosis5'){
                        this.isDiagnosis5Valid = false; 
                    }else if(fieldName ==='Diagnosis6'){
                        this.isDiagnosis6Valid = false; 
                    }else if(fieldName ==='Diagnosis7'){
                        this.isDiagnosis7Valid = false; 
                    }else if(fieldName ==='Diagnosis8'){
                        this.isDiagnosis8Valid = false; 
                    }else if(fieldName ==='Diagnosis9'){
                        this.isDiagnosis9Valid = false; 
                    }else if(fieldName ==='Diagnosis10'){
                        this.isDiagnosis10Valid = false; 
                    }else if(fieldName ==='Diagnosis11'){
                        this.isDiagnosis11Valid = false; 
                    }else if(fieldName ==='Diagnosis12'){
                        this.isDiagnosis12Valid = false; 
                    }else if(fieldName ==='Diagnosis13'){
                        this.isDiagnosis13Valid = false; 
                    }else if(fieldName ==='Diagnosis14'){
                        this.isDiagnosis14Valid = false; 
                    }else if(fieldName ==='Diagnosis15'){
                        this.isDiagnosis15Valid = false; 
                    }else if(fieldName ==='Diagnosis16'){
                        this.isDiagnosis16Valid = false; 
                    }else if(fieldName === 'ReferralFrom') {
                        this.isReferralFromValid = false;
                    }else if(fieldName === 'ReferralTo'){
                        this.isReferralToValid = false;   
                    }else if(fieldName === 'AttendingDoctor'){
                        this.isAttendingDoctorValid = false;
                    }else if(fieldName === 'ReferringDoctor'){
                        this.isReferringDoctorValid = false;               
                    }else if(fieldName.indexOf('ServiceLocation') >= 0){
                        this.isServiceLocationValid = false;  
                    }else if(fieldName.indexOf('CaseManager') >= 0){            
                        this.isCaseManagerValid = false;    
                    }else if(fieldName.indexOf('CommunicationLiaison') >= 0){            
                        this.isCommunicationLiaisonValid = false;   
                    }else if(fieldName.indexOf('Matrix') >= 0){            
                        this.isMatrixValid = false;  
                    } 
               }
            })
             .catch(error => {
                 if (error.response) {
                  this.showMessage(this.$tc('Shared.Patient'),error,"error");
                 }
             });
          }
        },

        goPatientList() {
            if(!this.pendingChanges)
                this.checkExistFavorite('/app/patients');
            this.$router.push({ name: "patients-list" });
        },

        async getProvider(){
      await ProviderService.get(this.$store.state.providerCode)
            .then((response) => {      
               if (response.status == '200' && response.data) { 
                var result = response.data;
                this.UseBlankDoctorCode = result.useBlankDoctorCode;                         
               }  
         })
         .catch(error => { 
          if (!error.response) {                      
                this.showMessage(this.$tc('Shared.Patient'),this.$t('Msg.retrieveInfo'),"error");
                    }                      
                    else if(error.response.status === 400 ){
                      this.showMessage(this.$tc('Shared.Patient'),error.response.data.errorMessage,"error");
                    } else if(error.response.status === 500){
                        this.showMessage(this.$tc('Shared.Patient'),this.$t('Msg.ThereWereSomeError'),"error");
                     } else{
                         this.showMessage(this.$tc('Shared.Patient'),error.response.data.errorMessage,"error");
                     }                  
         });    
    },

    async GetPatientByCode(){
      var infoList = await this.getInfoTitle(this.$route.params.id);
      if(infoList.length > 0){
          this.PatientTitle.programName = '';
          this.PatientTitle.admitDate = '';
          this.PatientTitle.dischargeDate = '';
          this.PatientTitle.age = infoList[0].age;//adding age
          this.PatientTitle.ageFormatted = infoList[0].ageFormatted;
          if(infoList[0].dob){
              this.PatientTitle.dob = moment(infoList[0].dob).format('MM/DD/YYYY');
          }         
          this.PatientTitle.fullName = infoList[0].firstname + ' '+ infoList[0].lastname;
        
      }
    },
    PopulatePatientTitle(){
        if(this.RegistrationInfo.dateFrom)
            this.RegistrationInfo.dateFrom = moment(this.RegistrationInfo.dateFrom).format('MM/DD/YYYY');
        if(this.RegistrationInfo.dateTo)
            this.RegistrationInfo.dateTo = moment(this.RegistrationInfo.dateTo).format('MM/DD/YYYY');	
      if(this.$route.name.includes('Clinical-PatientInfo')){ 
          this.PatientTitle.programName = this.RegistrationInfo.programName;
          this.PatientTitle.dateFrom = this.RegistrationInfo.dateFrom;
          this.PatientTitle.dateTo = this.RegistrationInfo.dateTo;
          this.PatientTitle.fullName = this.RegistrationInfo.patientName;
          this.PatientTitle.dob = moment(this.RegistrationInfo.dob).format('MM/DD/YYYY');
          this.PatientTitle.age = this.RegistrationInfo.age;
          this.PatientTitle.ageFormatted = this.RegistrationInfo.ageFormatted;
      }
      if(this.$route.name.includes('patient-details')){         
          this.PatientTitle.programName = '';
          this.PatientTitle.dateFrom = '';
          this.PatientTitle.dateTo = '';
          this.PatientTitle.dob = this.patient.dob;             
          this.PatientTitle.fullName = this.patient.firstname + ' '+ this.patient.lastname;
      }
    },
    isEmailValid: function() {
      return (this.patient.email == "")? "" : (this.reg.test(this.patient.email)) ? true : false;
    },

    beforeWindowUnload(e) {
			if (this.pendingChanges) {
				e.preventDefault()
				e.returnValue = ''	
			}
	}
    ,
    checkUserAction() {
      if (elemSaveBtn.disabled) {
        elemSaveBtn.removeAttribute('disabled');
      }
    },
    getUseAutoAssignCode(){
        
       PatientService.getUseAutoAssignCode("TPatientForm")
        .then((response) => {      
            if (response.status == '200') { 
                this.autoAssignCode = response.data
            }  
         })
         .catch(error => { 
          if (!error.response) {                      
                this.showMessage(this.$tc('Shared.Patient'),this.$t('Msg.retrieveInfo'),"error");
                    }                      
                    else if(error.response.status === 400 ){
                      this.showMessage(this.$tc('Shared.Patient'),error.response.data.errorMessage,"error");
                    } else if(error.response.status === 500){
                        this.showMessage(this.$tc('Shared.Patient'),this.$t('Msg.ThereWereSomeError'),"error");
                     } else{
                         this.showMessage(this.$tc('Shared.Patient'),error.response.data.errorMessage,"error");
                     }                  
         });    
    },

},

   async mounted() {
   
   this.populateSelects();

    this.IsVip = this.$store.state.IsVip;
    moment.suppressDeprecationWarnings = true;  
    this.isReadOnly = await this.$store.state.isPatientInfoEHRReadOnly;
    this.pendingChanges = false;
    this.OptionsRightMenu.ItemsList = EnumService.PatientItems;
    this.OptionsRightMenu.Mode = this.edit_mode;
    this.OptionsRightMenu.visible = true;
    
    if (this.edit_mode) {
            this.OptionsRightMenu.btnvisible = true;
            this.OptionsRightMenu.itemdisabled = false;
            this.$emit('getVisibleMenu', this.OptionsRightMenu);
    }
    else{        
            this.OptionsRightMenu.btnvisible = false;
            this.defaultAddValues();
            this.getUseAutoAssignCode();
    }

    this.$emit('getBtnRightMenu', this.OptionsRightMenu);
    
    if(this.$route.name.includes('patient-details')){  
        this.GetPatientByCode();
     } 

    if(this.$route.name.includes('Clinical-PatientInfo')){      
        this.RegistrationInfo = await this.getRegistrationInfoTitle(this.$route.params.recordid);
         this.IsClinical = this.RegistrationInfo.dateTo?false:true;
         if(this.RegistrationInfo.dob)
			this.RegistrationInfo.dob =  moment(this.RegistrationInfo.dob).format('MM/DD/YYYY');

      this.RegistrationInfo.age = this.RegistrationInfo.age === 0 ? "0" : this.RegistrationInfo.age;

    }

    this.PopulatePatientTitle();

    setTimeout( function() {
        elemSaveBtn = document.getElementById('btn_save_patient');
        elemSaveBtn.setAttribute('disabled','');
    }, 50, this );

    },

    destroyed(){
      this.OptionsRightMenu.ItemsList = [];
      this.OptionsRightMenu.Mode = false;
      this.OptionsRightMenu.visible = false; 
      this.$emit('getVisibleMenu', this.OptionsRightMenu);
    },

    async created () {        
     this.getPermissionLeftMenu(this.Menu,this.$route.path,this.permission);
     await this.getProvider();
     //this.populateSelects();
    
    EventBus.$on("onChanged", function (e) { 
          this.pendingChanges = e.Changed;
          this.checkUserAction();
          EventBus.$emit('HaveChanges', e.Changed);
     }.bind(this));
   
     EventBus.$on("onSave", function () {   
          this.Save();
     }.bind(this));

     window.addEventListener('beforeunload', this.beforeWindowUnload)
    },

    beforeDestroy () {
      window.removeEventListener('beforeunload', this.beforeWindowUnload);
      //EventBus.$off('onChanged');
      EventBus.$off('onSave');
    },
} 
</script>
