import ApiService from '@/services/api.service'


const BioSetupService = {
    getAll( parameter) { 
         
      const requestData = {
        method: 'get',
        url: "/Mdbiopsychosocialsetuphdr",
        headers: {
        },
        params: { 
          SearchCriteria: parameter.SearchCriteria,
          PageSize: parameter.PageSize,          
          PageNumber : parameter.PageNumber,
          NoteType: parameter.NoteType
        }
      }     
      return ApiService.customRequest(requestData);         
    },
  
    get(id) {
      return ApiService.get(`/Mdbiopsychosocialsetuphdr/${id}`);
    },

    add(data){
      return ApiService.post(`/Mdbiopsychosocialsetuphdr`,data)
    },

    delete(id){
      return ApiService.delete(`/Mdbiopsychosocialsetuphdr/${id}`)
    },

    update(code,bio){
      return ApiService.put(`/Mdbiopsychosocialsetuphdr/${code}`,bio)
  }
    
  }
  
  export default BioSetupService;