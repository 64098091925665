<template>
    <b-modal :id="ModalId" size="lg" :no-close-on-backdrop="true" hide-header scrollable ok-only ok-title="Close" :no-close-on-esc="true" centered :visible="true" @ok="this.emitEventOK" @hide="emitEventHide">
    <div id="ContainerIncident">
        <div>
          <IncidentWorkflowSummary 
            v-bind:IncidentId="IncidentId">
          </IncidentWorkflowSummary>
        </div>
     </div>
   </b-modal>
</template>
 
 <script>
  import DBFunctions from '@/common/DBFunctions.js'
 import ShowMessage from '@/components/messages/ShowMessage.js'
 import { EventBus } from '@/event-bus.js';

 export default {
    mixins: [ShowMessage, DBFunctions],  
    props: {
        IncidentId: String
    },
    name: 'IncidentWorkflowSummaryModal',
     data() {
       return { 
        ModalId: 'IWFSummaryModal',
       }
     },
 
 
    methods:{

        emitEventOK() {      
        },

        emitEventHide() {
            EventBus.$emit('CloseIWFSummary');
        },
    },

    created () {
        EventBus.$on("showSummary", function () { 
            EventBus.$emit('CloseIWFSummary');
        }.bind(this));
    },
  } 
 </script> 
 