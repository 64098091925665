import ApiService from '@/services/api.service'

const DrugService = {

    getAll(parameter){
        const requestData = {
            method: 'get',
            url: "/Drugs/",       
            params: {
              SearchCriteria: parameter.SearchCriteria,
              PageSize: parameter.PageSize,
              PageNumber: parameter.PageNumber,  
            }
          }       
          return ApiService.customRequest(requestData);
    }, 

    Add(data){
        return ApiService.post(`/Drugs`, data);
    },
    Delete(Id){      
        return ApiService.delete(`/Drugs/${Id}`);
    },
    Update(Id,data){
        return ApiService.put(`/Drugs/${Id}`,data);
    },   
    Get(Id){     
        return ApiService.get(`/Drugs/${Id}`);
    },
    getDrugsByCode(parameter){
        const requestData = {
          method: 'get',
          url: "/Drugs/",
          headers: {
          },
          params: {          
            code: parameter.code           
          }
        }     
        return ApiService.customRequest(requestData);       
      }
}

export default DrugService;