<template>
<div id="ContainerMain" :class="MainLogo">

  <!-- <div class="HPageProvider" v-if="providerList && providerList.length > 1">
    <a href="#" data-text="Change provider" v-on:click="GoToProvidersList">{{this.provider}}</a>
  </div>
  <div class="HPageProvider" v-if="providerList && providerList.length == 1">
    <span>{{this.provider}}</span>
  </div>
  //-->

  <div id="containerBox" class="flex-container cutescroll">
    <b-row>
      <MdAppointmentByDay v-if="ApptVisible"/>
      <MdAdmissionByDay v-if="AdmVisible"/>
    </b-row>
  </div>

  <div class="footer">
      <div class="d-sm-flex justify-content-center justify-content-sm-between">
        <span class="text-muted text-center text-sm-left d-block d-sm-inline-block"><span class="italic">{{$t('Main.ComplexEasyMsg')}}</span></span>
        <span class="float-none text-muted float-sm-right d-block mt-1 mt-sm-0 text-center"><small>{{$t('Main.Poweredby')}}</small> <a class="text-corporate" href="https://www.medez.com/" target="_blank">MedEZ<sup>®</sup></a></span>
      </div>
    </div>
    
</div>
</template>

<script>
import MainService from '@/services/main.service'
import LocalStorage from '@/services/storage.service'
export default {
  name: 'Main',
  data(){
    return{
       OptionsRightMenu:{
            visible:false,
             btnvisible:false,
            ItemsList:[]
        },
        provider: '',
        providerList:[],
        Boxes:[],
        ApptVisible:false,
        AdmVisible:false,
        MainLogo:""
    }

    
  },
   methods:{
     GoToProvidersList() {
      this.$bvModal
        .msgBoxConfirm(this.$t('Main.ChangeProvMsg'), {
          title: this.$t('Main.ChangeProvTitle'),
          okTitle: this.$t('Main.CHANGE'),
          cancelTitle: this.$t('Shared.BtnCancel'),
          footerClass: "p-2",
          titleClass: "modal-title modal-type-warning",
        })
        .then((value) => {
          if (value) {
            this.$router.push("/provider").catch(() => {});
          }
        });
    },


    //

    isInList(element){
      var result = false;
      var arr = this.Boxes;
      for(var i = 0; i < arr.length; i++){
        if(arr[i].description.trim().toUpperCase() === element.trim().toUpperCase()){
          result = true;
          break;
        }
      }
      return result;
    },

    async getBoxes(){
      var id = LocalStorage.getUserId();
      await MainService.getBoxes(id)
                .then((response) => {
                     if(response.status == '200' && response.data ){
                       this.Boxes = response.data; 
                       this.MainLogo = this.Boxes.length > 0 ? "":"mainlogo";                       
                       this.ApptVisible = this.isInList("Today's Appointments") ? true : false;
                       this.AdmVisible = this.isInList("Today's Admissions") ? true : false;                                                   
                     }               
                })
                .catch((error) => {
                    if (error.response) {
                        this.showMessage('Error',error,"error");
                    }
                });  
    },
  },
 
  mounted(){
      this.OptionsRightMenu.ItemsList = [];
      this.OptionsRightMenu.Mode = false;
      this.OptionsRightMenu.visible = false; 
      this.OptionsRightMenu.btnvisible = false;
      this.$emit('getVisibleMenu', this.OptionsRightMenu);
      this.$emit('getBtnRightMenu', this.OptionsRightMenu); 
      this.provider = this.$store.state.provider;
      this.providerList = this.$store.state.ProvidersList;
      this.getBoxes();
    }
};

</script>

