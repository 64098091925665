<template>
    <div id="treatmentplan">
      <div class="row">
        <div class="col-12">
          <h4 class="page-title text-truncate">
                {{ $t('treatmentplan.Treatmentplan') }} - 
                <span v-if="this.RegistrationInfo.patientName">{{this.RegistrationInfo.patientName+' - '}}</span>
                <span v-if="this.$route.params.id">{{this.$route.params.id + ' - '}}</span>
                <span v-if="this.RegistrationInfo.dob"> {{ $t('Shared.Dob') }} {{this.RegistrationInfo.dob}}</span>
                <span v-if="this.RegistrationInfo.ageFormatted"> {{ $t('Shared.Age') }} {{this.RegistrationInfo.ageFormatted}}</span>
                <span v-if="this.RegistrationInfo.programName"> - {{this.RegistrationInfo.programName}}  </span>
                <span v-if="this.RegistrationInfo.dateFrom">- {{this.RegistrationInfo.dateFrom}}  </span>
                <span v-if="this.RegistrationInfo.dateTo">- {{this.RegistrationInfo.dateTo}}  </span>
                <span v-else>- {{ $t('Shared.Active') }}  </span>
            </h4> 
          </div>
        </div>
      <vue-good-wizard 
      ref="wizard"
      :steps="steps"
      :finalStepLabel="$t('treatmentplan.finish')" 
      :nextStepLabel="$t('treatmentplan.Next')"
      :previousStepLabel="$t('Shared.Back')"     
      :onNext="nextClicked" 
      :onBack="backClicked"
      >    
      <div slot="Diagnoses">
        <MdTitle v-bind:Text="DiagnoseLabel" v-bind:isSubtitle="true" v-bind:Name="'Diagnosis'" class="subtitleWizard"/> 
        <b-row>
          <div class="col-md-12">
           <MdLookup
                 v-bind:ModelCode="diagCode"
                 v-bind:ModelDescription="diagDesc"
                 v-bind:Label="$t('MdPatientDiagCodes.Diagnosis')"
                 v-bind:RefCode="'Diagnosis'"  
                 v-bind:Fields="BaseFields"               
                 v-bind:Api="'WileyTxDiagnoses?DSM=5'"
                 v-bind:FieldCode="'icd10code'"                 
                 v-bind:FieldDescription="'description'"                 
                 :GetValues="onCodeConditionOk"
                 :SearchFieldCode="'icd10code'"
              />
          </div>
          <div class="col-md-12 text-muted px-4 mt-3">
            <p>{{$t('treatmentplan.Text1')}}</p>
            <p>{{$t('treatmentplan.Text2')}}</p>
            <p>{{$t('treatmentplan.Text3')}}</p>
            <hr class="mt-5">
            <p class="wiley fadeIn">{{$t('treatmentplan.Text4')}}<a href="https://www.wiley.com/learn/practice_planners/index.html" target="_blank"><img src="../../../../src/assets/images/wiley-practice_planners_logo.svg" alt="Wiley's PracticePlanners"></a></p>
          </div>
       </b-row>  
      </div>
      <div slot="Problems">
        <MdTitle v-bind:Text="ProblemLabel" v-bind:isSubtitle="true" v-bind:Name="'Problem'" class="subtitleWizard"/>
        <b-row>
          <b-col lg="6" md="6" sm="12">            
               <MdSelect
               v-bind:Label="$t('treatmentplan.proGroup')"
               v-bind:Options="ProblemGroupOptions"
               v-bind:FieldDescription="'description'"
               v-bind:FieldCode="'groupId'"
               v-bind:AllowEmpty="false"
               v-bind:Multiple="false"
               v-bind:Model="ProblemGroupModel"  
               :Input="ChangeProblemGroup"/>
         </b-col>
         <div class="col-md-6 text-muted mt-3">
            <p>{{$t('treatmentplan.proGroupText')}}</p>
          </div>         
        </b-row>
        <b-row v-if="this.itemsProblems.length > 0">
          <b-col md="12">
            <RadioGroup 
              v-model="Problem"
              v-bind:Options="itemsProblems"
              v-bind:LabelProp="$t('treatmentplan.Problems')"
              v-bind:OnChangeProp="OnChangeProblem"
              v-bind:columnCount="3"
              :textField="'description'"
              :valueField="'problemNumber'"/> 
          </b-col>
        </b-row> 
      </div>
      <div slot="Behavioral">
        <MdTitle v-bind:Text="BehavioralLabel" v-bind:isSubtitle="true" v-bind:Name="'Behavioral'" class="subtitleWizard"/>
        <b-row>
          <b-col lg="12" md="12" sm="12">           
            <MdCheckListBox2 
              v-model="behavioralModel"
              v-bind:Options="BehavioralList"
              v-bind:OnChangeProp="OnChangeBehavioral"
              v-bind:columnCount="1"           
              :textField="'description'"
              :valueField="'definitionNumber'"/> 
          </b-col>                                   
        </b-row>  
      </div>
      <div slot="Goals">
        <MdTitle v-bind:Text="GoalLabel" v-bind:isSubtitle="true" v-bind:Name="'Goal'" class="subtitleWizard"/>
        <b-row>
          <b-col lg="12" md="12" sm="12">           
            <MdCheckListBox2 
              v-model="GoalsModel"
              v-bind:Options="GoalsList"            
              v-bind:OnChangeProp="OnChangeGoals"
              v-bind:columnCount="1"
              :textField="'description'"
              :valueField="'goalNumber'"/> 
          </b-col>                                   
        </b-row>
      </div>
      <div slot="Objectives">
        <MdTitle v-bind:Text="ObjectiveLabel" v-bind:isSubtitle="true" v-bind:Name="'Objective'" class="subtitleWizard"/>
        <div class="ObjWizardLegend"><b-icon icon="exclamation-circle" style="color: #090;"></b-icon> {{$t('treatmentplan.Text5')}}</div>
        <!--<b-row>
          <b-col lg="12" md="12" sm="12">           
            <MdCheckListBoxByCycle
              v-model="ObjectiveModel"
              v-bind:Options="ObjectiveList"
              v-bind:OnChangeProp="OnChangeObjectives"             
              v-bind:columnCount="1"
              :textField="'description'"
              :valueField="'objectiveNumber'"/> 
          </b-col>                                   
        </b-row>-->

        <b-row v-for="(item, index) in ObjectiveModel" :key="index">
             <span class="InterventionByObjWizard">{{$t('modalAddGoal.goals')}} {{index+1}} - {{item.description}}</span>
            <b-col lg="12" md="12" sm="12" class="mb-3">
                <MdCheckListBoxByCycle
                    v-model="item.selectedCode"
                    v-bind:Options="item.objectives"
                    v-bind:OnChangeProp="OnChangeObjectives"             
                    v-bind:columnCount="1"
                    :textField="'description'"
                    :valueField="'objectiveNumber'"
                /> 
             </b-col>
        </b-row>
       <!-- <div v-if="this.HaveObjectiveEvidenceBased()" class="ObjWizardLegend" v-b-tooltip.hover title="Presented in green labels"><b-icon icon="circle-fill" font-scale=".8"></b-icon> evidence based treatment</div>-->
      </div>
      <div slot="Interventions">
        <MdTitle v-bind:Text="InterventionLabel" v-bind:isSubtitle="true" v-bind:Name="'Intervention'" class="subtitleWizard"/>
        <div class="ObjWizardLegend"><b-icon icon="exclamation-circle" style="color: #090;"></b-icon> {{$t('treatmentplan.Text5')}}</div>
        <b-row v-for="(item, index) in InterventionsModel" :key="index">
            <span class="InterventionByObjWizard rounded" v-if="item.objectives.length > 0">{{$t('modalAddGoal.goals')}} {{index+1}} - {{item.description}}</span>
                <div v-for="(obj, i) in item.objectives" :key="i" class="w-100">
                    <div class="ObjsWizard">{{$t('modalEditObjective.Objective')}} {{i+1}} - {{obj.description}}</div>
                    <MdCheckListBoxByCycle 
                        class="ml-3 mx-4"
                        v-model="obj.interventionsSelected"
                        v-bind:Options="obj.interventions"
                        v-bind:OnChangeProp="OnChangeInterventions"
                        v-bind:columnCount="1"
                        :textField="'description'"
                        :valueField="'interventionNumber'"/> 
                </div>
        
        </b-row>
      </div>     
      <div slot="Summary">
        <MdTitle v-bind:Text="$t('dailyServiceCharge.Summary')" v-bind:isSubtitle="true" v-bind:Name="'Summary'" class="subtitleWizard"/>
       
        <div id="summaryDiagnose" class="wizardSummary">
          <div class="wizardSumLabel"><span class="wizardSumNum"><b-icon icon="check"></b-icon></span>{{$t('MdPatientDiagCodes.Diagnosis')}}</div>
          <div class="ml-3 mt-2">{{this.diagDesc}} - {{this.diagCode}}</div>
        </div>
        <br> 
        <div id="summaryProblem" class="wizardSummary">
          <div class="wizardSumLabel"><span class="wizardSumNum"><b-icon icon="check"></b-icon></span>{{$t('modalEditProblem.Problem')}}</div>
          <div class="ml-3 mt-2">{{this.ProblemD}}</div>
        </div>  
        <br>
          <div id="summarybehavioral" class="wizardSummary">
            <div class="wizardSumLabel"><span class="wizardSumNum"><b-icon icon="check"></b-icon></span>{{$t('treatmentplan.Behavioral')}}</div>
          <div class="ml-3 mt-2"><ul>							
              <li v-for="(item, index) in this.SelectedBehavioral" :key="index">{{item}}</li>						
            </ul>
          </div>
        </div>
        <br>
        <!--
        <div id="summarygoals" class="wizardSummary">
          <div class="wizardSumLabel"><span class="wizardSumNum"><b-icon icon="check"></b-icon></span>Goals</div>
          <div class="ml-3 mt-2">
            <ul>							
                <li v-for="(item, index) in this.SelectedGoals" :key="index">{{item}}</li>						
            </ul>
          </div>
        </div>-->
        <div id="summarygoals" class="wizardSummary">
            <div v-for="(item, index) in this.InterventionsModel" :key="index">

                <div class="wizardSumLabel">
                    <span class="wizardSumNum">
                        <b-icon icon="check"></b-icon>
                    </span>{{$t('modalEditGoal.goal')}} {{index +1}}               
                </div>
                <div class="wizardGoalsDesc">
                    {{item.description}} 
                </div>

                <div class="ml-3 mt-2 mb-4">
                    <div class="wizObjectives" v-for="(obj, indexobj) in item.objectives" :key="indexobj">
                        <b-icon icon="check" class="wizardCheckObj"></b-icon> <span>{{$t('modalEditObjective.Objective')}} {{indexobj+1}} - {{obj.description}}</span>
                        <div class="ml-3 mt-2">
                            <ul>							
                                <li v-for="(itv, indexitv) in obj.itvDdescriptionSelected" :key="indexitv">{{$t('Intervention.interV')}} {{indexitv+1}} - {{itv.description}}</li>						
                            </ul>
                          </div>
                    </div>
                </div>
            </div>
        </div>
        <br>

        <MdTitle v-bind:Text="$t('modalAddSignature.sign', {'label': ''})" v-bind:isSubtitle="true" v-bind:Name="'Signature'" class="subtitleWizard"/>
        <div class="card bg-light">
            <div class="card-body">                   
                <b-row>        
                    <b-col lg="3" md="3" sm="12">         
                        <MdSelect
                            v-bind:Label="$t('modalProviderSelector.Provider')"
                            v-bind:Options="ProviderList"
                            v-bind:FieldDescription="'therapistName'"
                            v-bind:FieldCode="'therapistCode'"
                            v-bind:Model="ProviderModel"                                 
                            v-bind:Multiple="false"
                            :Input="ChangeDropDownProvider"
                        />                           
                    </b-col>
                    <b-col lg="1" sm="1" md="12" >
                        <MdCheckBox
                        v-bind:Label="$t('MdNote.Sign')"
                        v-bind:Name="'checkbox-Sign'" 
                        v-bind:ClassProp="'AlignCheck'"
                        v-bind:CheckedValue="true"
                        v-bind:UncheckedValue="false"              
                        v-model="SignProvider"
                        v-on:change="onSignatureTP"
                        v-bind:Tag="'SignTreatmentPlanSignature'"          
                        />
                    </b-col>                         
                    <b-col lg="3" md="3" sm="12">         
                        <MdSelect
                            v-bind:Label="$t('MdNote.Cosignedby')"
                            v-bind:Options="CoProviderList"
                            v-bind:FieldDescription="'coTherapistName'"
                            v-bind:FieldCode="'coTherapistCode'"
                            v-bind:Model="CoProviderModel"                                 
                            v-bind:Multiple="false"
                            :Input="ChangeDropDownCoProvider"
                        />
                    </b-col>
                    <b-col lg="1" sm="1" md="12" >
                        <MdCheckBox
                        v-bind:Label="$t('MdNote.Sign')"
                        v-bind:Name="'Co-checkbox-Sign'" 
                        v-bind:ClassProp="'AlignCheck'"
                        v-bind:CheckedValue="true"
                        v-bind:UncheckedValue="false"              
                        v-model="SignCoProvider"
                        v-on:change="onCoSignatureTP"
                        v-bind:Tag="'CoSignTreatmentPlanSignature'"          
                        />
                    </b-col> 
              
                    <b-col lg="3" md="3" sm="12">         
                        <MdSelect
                            v-bind:Label="$tc('Incident.IncPartClient')"
                            v-bind:Options="ClientList"
                            v-bind:FieldDescription="'name'"
                            v-bind:FieldCode="'code'"
                            v-bind:Model="ClientModel"                                 
                            v-bind:Multiple="false"
                            :Input="ChangeDropDownClient"
                        />
                    </b-col>
                    <b-col>
                        <MdCheckBox
                        v-bind:Label="$t('MdNote.Sign')"
                        v-bind:Name="'checkbox-Client'" 
                        v-bind:ClassProp="'AlignCheck'"
                        v-bind:CheckedValue="true"   
                        v-bind:isReadOnly="disabledCheckClient"                      
                        v-model="ClientCheckModel"                  
                        v-bind:UncheckedValue="false" 
                        v-on:change="onSignatureClient($event)"
                        v-bind:Tag="'TreatmentPlanSignatureClient'"          
                        /> 
                    </b-col>               
                </b-row>              
            </div>
        </div>


        <hr class="mt-3">
        <p class="wiley fadeIn">{{$t('treatmentplan.Text6')}} <a href="https://www.wiley.com/learn/practice_planners/index.html" target="_blank"><img src="../../../../src/assets/images/wiley-practice_planners_logo.svg" alt="Wiley's PracticePlanners"></a></p>
        <!--     
        <div id="summaryinterventions" class="wizardSummary">
          <div class="wizardSumLabel"><span class="wizardSumNum"><b-icon icon="check"></b-icon></span>Interventions</div>
          <div class="ml-3 mt-2"><div class="wizObjectives" v-for="(parent, index) in this.InterventionsModel" :key="index">
            <div class="wizardSumObj" v-if="parent.itvDdescriptionSelected.length > 0">{{$t('modalEditObjective.Objective')}}</div>
            <div v-if="parent.itvDdescriptionSelected.length > 0" :class="'ml-2 mt-2 '+parent.classSummary">{{parent.description}}</div>	
            <div v-if="parent.itvDdescriptionSelected.length > 0" class="ml-2">
              <ul>
                <li v-for="(child, index) in parent.itvDdescriptionSelected" :key="index"><span :class="child.class">{{child.description}}</span></li>						
              </ul>
            </div>
            </div>
          </div>
        </div>
        <div class="wiley">
          <small>This wizard is built based on:</small> <a href="https://www.wiley.com/learn/practice_planners/index.html" target="_blank"><img src="@/assets/images/wiley-practice_planners_logo.svg" height="18"></a>
        </div>
        -->
        <!--<div v-if="this.HaveSummaryEvidenceBased()" class="ObjWizardLegend" v-b-tooltip.hover title="Presented in green labels"><b-icon icon="circle-fill" font-scale=".8"></b-icon> evidence based treatment</div>-->
      </div> 
    </vue-good-wizard>
      <div class="page-actions">
                <MdButton
                v-bind:Label="$t('Shared.Back')"
                v-bind:ClassProp="'secondary mx-2 btn-login'"
                v-on:click="goTreatmentList"
            />            
    </div>

    <MdPasswordDlg v-if="showSignPassword" 
    v-bind:Type="TypePassWord"
    v-bind:EmployeeCode="EmployeeCodeSelected"
    @getModalPassword="onModalPasswordResult($event)"
/>
<modalSignPatientGuardian
    @ModalTPSignPatientGuardian="OnReturnSign($event)" 
    @ModalTPSignPatientGuardianWizardOK="OnReturnOKWizard($event)"
    v-if="showModalSignPatientGuardian" 
    v-bind:Option="option"
    v-bind:ModalId="'modalIdSignPatientGuardian'" 
    v-bind:ItemChecked="itemChecked" 
    v-bind:TitleWindow="labelSign"
    v-bind:ShowGuardianDrop="ShowGuardianDrop"
/>

    </div>
</template>

<script>
import EmployeeService from '@/services/employee.service'
import LocalStorage from '@/services/storage.service'
import EnumService from '@/services/enum.service' 
import DBFunctions from '@/common/DBFunctions.js'
import TreatmentPlanService from '@/services/treatmentplan.service' 
import moment from "moment";
export default {
    name: 'TreatmentPlan',    
    mixins: [DBFunctions],
    data() {
        return { 
            EmployeeCode:"",          
            option:"",
            labelSign:"",
            DataModalGuardianSig:{},
            showSignPassword: false, 
            EmployeeCodeSelected:"",
            ProviderList:[],
            itemChecked:{},
            CoProviderList:[],
            ContactList:[],
            ClientModel:{code:"",name:""},
            ClientList:[
            {code:"PatientSigned",name:this.$t('modalAddSignature.pSigned')},
            {code:"PatientRefused",name:this.$t('modalAddSignature.pRefused')},
            {code:"GuardianSigned",name:this.$t('modalAddSignature.gSigned')},
        ],         
            ContactModel:"",
            CoProviderModel:{coTherapistCode:"",coTherapistName:""},
            showModalSignPatientGuardian:false,
            ProviderModel:{therapistCode:"",therapistName:""},
            SignProvider:false,
            SignCoProvider:false,
            ClientCheckModel:false,
            disabledCheckClient:false,
            SignPatient:false,
            SignRefused:false,
            SignContact:false,
            BaseFields: [
                { key: 'description', sortable: true, label: this.$t('Shared.Description') },
                { key: 'icd10code', sortable: true, label: this.$t('Shared.Code') }
            ],
            ObjData:{
                admissionId:this.$route.params.recordid,
                patientCode:this.$route.params.id,
                customFrame:"WileyTreatmentPlanNotes",
                problem: {
                    probCode: "",
                    probDesc: "",
                    dsm5: "",
                    dsm5description: "",
                    problemGroup: "",
                    groupId: 0
                },
               
                problemDefinitions:[

                ],
                goals:[],
            },

            RegistrationInfo: {},
            diagCode: "",
            diagDesc: "",
            DiagnoseIndexSelected: 0,
            ProblemIndexSelected: 0,
            ProblemD: "",
            ProblemGroupModel: { "description": "", "groupId": 0 },
            behavioralModel: [],
            BehavioralList: [],
            ObjectiveList: [],
            ObjectiveModel: [],
            GoalNumberList:[],
            ObjectiveNumberList:[],
            GoalsList: [],
            Problem: -1,
            GoalsModel: [],
            SelectedDiagnosis: { "description": "", "icd10code": "" },
            SelectedProblem: { "description": "", "problemNumber": "", "groupId": 0, "customProblem": "" },
            SelectedBehavioral: [],
            SelectedGoals: [],
            SelectedIntervention: [],
            ProblemGroupOptions: [],
            InterventionsList: [],
            InterventionsModel: [],
            InterventionsModelCopy: [],
            HeaderDiag: {},
            HeaderProb: {},
            pageOptionsDiagnoses: [5, 10, 15],
            totalRowsDiagnoses: 1,
            currentPageDiagnoses: 1,
            perPageDiagnoses: 5,
            ShowMessageIsEmptyDiagnoses: false,
            refTableDiagnoses: 'RefTableTreatmentDiagnoses',
            pageOptionsProblems: [5, 10, 15, 20, 25, 30],
            totalRowsProblems: 1,
            currentPageProblems: 1,
            perPageProblems: 5,
            ShowMessageIsEmptyProblems: false,
            refTableProblems: 'RefTableTreatmentProblems',
            parameterDiag: {
                SearchCriteria: '',
                PageNumber: 0,
                PageSize: 0,
            },
            parameterProb: {
                SearchCriteria: '',
                PageNumber: 0,
                PageSize: 0,
                GroupId: 0,
            },
            DiagnoseLabel: this.$t('treatmentplan.labelSelect',{'label': this.$t('MdPatientDiagCodes.Diagnosis')}),
            ProblemLabel: this.$t('treatmentplan.labelSelect',{'label': this.$t('modalEditProblem.Problem')}),
            BehavioralLabel: this.$t('treatmentplan.labelSelect',{'label': this.$t('treatmentplan.Behavioral')}),
            ObjectiveLabel: this.$t('treatmentplan.labelSelect',{'label': this.$t('modalAddObjective.objectives')}),
            InterventionLabel: this.$t('treatmentplan.labelSelect',{'label': this.$t('modalAddIntervention.intervention')}),
            GoalLabel: this.$t('treatmentplan.labelSelect',{'label': this.$t('modalAddGoal.goals')}),
            itemsDiagnoses: [],
            itemsProblems: [],
            OptionsRightMenu: {
                visible: true,
                ItemsList: [],
                btnvisible: true,
                itemdisabled: false,
            },
            ShowGuardianDrop:false,
            steps: [
                {
                    label: this.$t('MdPatientDiagCodes.Diagnosis'),
                    slot: 'Diagnoses',
                },
                {
                    label: this.$t('modalEditProblem.Problem'),
                    slot: 'Problems',
                },
                {
                    label: this.$t('treatmentplan.Behavioral'),
                    slot: 'Behavioral',
                },
                {
                    label: this.$t('modalAddGoal.goals'),
                    slot: 'Goals',
                },
                {
                    label: this.$t('modalAddObjective.objectives'),
                    slot: 'Objectives',
                },
                {
                    label: this.$t('modalAddIntervention.intervention'),
                    slot: 'Interventions',
                },
                {
                    label: this.$t('dailyServiceCharge.Summary'),
                    slot: 'Summary',
                }
            ],
        };
    },
    methods: {

        ChangeDropDownClient(value){ 
            this.ClientModel = value ? value : {code:"",name:""};      
            this.showModalSignPatientGuardian = false;       
            if(value){
                if(value.code === "PatientRefused"){                  
                    this.disabledCheckClient = true;
                    this.option = this.$tc('Shared.Patient');
                    this.labelSign = this.$t('modalAddSignature.sign', {'label': this.$tc('Shared.Patient')});
                    this.ClientCheckModel = false;
                }else{
                    this.disabledCheckClient = false;
                } 

                if(value.code === "GuardianSigned"){
                    this.option = this.$t('modalAddSignature.guardian');
                    this.labelSign = this.$t('modalAddSignature.sign', {'label': this.$t('modalAddSignature.guardian')});
                    this.ShowGuardianDrop = true;
                }

                if(value.code === "PatientSigned"){
                    this.option = this.$tc('Shared.Patient');
                    this.labelSign = this.$t('modalAddSignature.sign', {'label': this.$tc('Shared.Patient')});
                    this.ShowGuardianDrop = false;
                }
                
                if(value.code === "GuardianSigned" && this.ClientCheckModel){
                    this.showModalSignPatientGuardian = true;
                }

                else if(value.code === "PatientSigned" && this.ClientCheckModel){
                    this.showModalSignPatientGuardian = true;
                }
                
                else{
                    this.showModalSignPatientGuardian = false;
                }



            }
        },

        onModalPasswordResult(result){ 
          if(result.type === 'Sign'){
            if(result.Event === "Ok"){
                if(result.status !== true) {
                    this.showMessage(this.$t('MdNote.VerifyPassword'),result.message,"error");   
                    this.SignProvider = false;                  
                }                   
            }else{
                this.SignProvider = false;
            }
                  
        }
        if(result.type === 'Cosign'){
            if(result.Event === "Ok"){
                if(result.status !== true) {
                    this.showMessage(this.$t('MdNote.VerifyPassword'),result.message,"error");  
                    this.SignCoProvider = false;          
                }               
            }
            else{
                this.SignCoProvider = false;       
            }
        } 

        this.showSignPassword = false;
    },

    OnReturnOKWizard(data){        
        this.DataModalGuardianSig = data;
    },

    OnReturnSign(data){       
        if(data.option === this.$tc('Shared.Patient')){
            data.item.isPatientSigned = false; 
            data.item.CheckGuardianReadOnly = false;
            //CheckPatientReadOnly
            data.item.CheckRefuseReadOnly = false;
            data.item.SelectGuardianReadOnly = false;
        }
        if(data.option === this.$t('modalAddSignature.guardian')){
            data.item.guardianSigned = false;            
            data.item.CheckPatientReadOnly = false;
            data.item.CheckRefuseReadOnly = false;
            data.item.SelectGuardianReadOnly = false;
        }
       
        this.showModalSignPatientGuardian = false;
    },

    onCoSignatureTP(value){
        this.TypePassWord = 'Cosign'; 
        this.EmployeeCodeSelected = this.CoProviderModel.coTherapistCode;
        this.showSignPassword = value;
    },

    onSignatureTP(value){  
        this.TypePassWord = 'Sign';       
        this.EmployeeCodeSelected = this.ProviderModel.therapistCode;
        this.showSignPassword = value;
    },

        onSignatureClient(value){          
            this.showModalSignPatientGuardian = value && (this.ClientModel.code === "GuardianSigned" || this.ClientModel.code === "PatientSigned") ? true : false;
        },

        ChangeDropDownProvider(value){
            this.ProviderModel = value ? value : {therapistCode:"",therapistName:""};
        },

        onSignatureGuardian(){

        },

        ChangeDropDownGuardian(){

        },

        onSignatureRefused(){

        },

        onSignaturePatient(){

        },

        ChangeDropDownCoProvider(value){
            this.CoProviderModel = value ? value : {coTherapistCode:"",coTherapistName:""};
        },

        AddTX() {                    
           this.PrepareData();
           this.$emit('load', true);  
           if(this.ObjData.signature.therapistCode !== "" && this.ObjData.signature.therapistCode !== null){
                if(this.ObjData.signature.isSigned){
                    TreatmentPlanService.Add(this.ObjData)
                        .then((response) => {
                        if (response.status == '200') {
                            this.$router.push({
                                name: "WyTreatmentplan-Clinical",
                                params: { id:  this.$route.params.id,recordid:this.$route.params.recordid },
                                });

                                setTimeout(() => 
                                this.$bvToast.toast(this.$t('Msg.SavedSuccessfully'), {
                                title: this.$t('modalEditProblem.Problem'),
                                autoHideDelay: 5000,
                                variant: "success"
                                })
                                , 50); 
                        }
                        
                        this.$emit('load', false);
                    })
                        .catch(error => {
                this.$emit('load', false);
                if (!error.response) {
                    this.showMessage(this.$t('treatmentplan.Treatmentplan'), this.$t('Msg.retrieveInfo'), "error");
                }
                else if (error.response.status === 400) {
                    this.showMessage(this.$t('treatmentplan.Treatmentplan'), error.response.data.errorMessage, "error");
                }
                else if (error.response.status === 500) {
                    this.showMessage(this.$t('treatmentplan.Treatmentplan'), this.$t('Msg.ThereWereSomeError'), "error");
                }
                else {
                    this.showMessage(this.$t('treatmentplan.Treatmentplan'), error.response.data.errorMessage, "error");
                }
            });
                }else{
                    this.$emit('load', false);  
                    this.showMessage(this.$t('treatmentplan.Treatmentplan'), this.$t('treatmentplan.pSignMsg'), "error");
                    return;
                }
           }else{
                    this.$emit('load', false);  
                    this.showMessage(this.$t('treatmentplan.Treatmentplan'), this.$t('treatmentplan.pSelectMsg'), "error");
                    return;
           }
           
        },

        PrepareData(){
            this.ObjData.problem.probCode = this.Problem.toString();
            this.ObjData.problem.dsm5 = this.diagCode;
            this.ObjData.problem.probDesc = this.ProblemD;
            this.ObjData.problem.dsm5description = this.diagDesc;
            this.ObjData.problem.problemGroup = this.ProblemGroupModel.description;
            this.ObjData.problem.groupId = this.ProblemGroupModel.groupId;
           

            this.ObjData.problemDefinitions = [];    
            
            this.ObjData.signature = {
                therapistCode : this.ProviderModel.therapistCode,
                coTherapistCode : this.CoProviderModel.coTherapistCode,
                patientCode : this.$route.params.id,
                isSigned : this.SignProvider,
                isCosigned : this.SignCoProvider,
                isPatientSigned : this.ClientCheckModel === true && this.ClientModel.code === "PatientSigned" ? true : false,
                guardianSigned : this.ClientCheckModel === true && this.ClientModel.code === "GuardianSigned" ? true : false,
                refuseSigned : this.ClientModel.code === "PatientRefused" ? true : false,
                image : this.DataModalGuardianSig.base64,
                contactRecNo : this.DataModalGuardianSig.guardianCode !== "" && this.DataModalGuardianSig.guardianCode !== null  ? parseInt(this.DataModalGuardianSig.guardianCode) :null,
            };
     
            for(var i = 0; i < this.behavioralModel.length; i++){
                this.ObjData.problemDefinitions.push(
                {                   
                    definitionNumber:this.behavioralModel[i].toString(),
                    definitionDescription:this.SelectedBehavioral[i],                   
                });
            }

            this.ObjData.goals = [];
            let Dataobjectives = [];
            let Datainterventions = [];

            for(var j = 0; j < this.InterventionsModel.length; j++){
                let goalCode = this.InterventionsModel[j].goalNumber.toString();
                let goalDesc = this.InterventionsModel[j].description;
                for(var k = 0; k < this.InterventionsModel[j].objectives.length; k++){
                    let objCode = this.InterventionsModel[j].objectives[k].objectiveNumber.toString();
                    let objDesc = this.InterventionsModel[j].objectives[k].description;
                    let isEvidenceBasedObj = this.InterventionsModel[j].objectives[k].isEvidenceBased;
                   
                    for(var l = 0; l < this.InterventionsModel[j].objectives[k].interventionsSelected.length; l++){
                        let intCode = this.InterventionsModel[j].objectives[k].interventionsSelected[l].toString();
                        let intDesc = this.InterventionsModel[j].objectives[k].itvDdescriptionSelected[l].description;
                        let isEvidenceBasedInt = this.InterventionsModel[j].objectives[k].itvDdescriptionSelected[l].isEvidenceBased;

                        Datainterventions.push({intCode:intCode,intDesc:intDesc,isEvidenceBased:isEvidenceBasedInt})
                    
                    }
                    Dataobjectives.push({objCode:objCode,objDesc:objDesc,isEvidenceBased:isEvidenceBasedObj,interventions:Datainterventions});
                    Datainterventions = [];
                }
                this.ObjData.goals.push({goalCode:goalCode,goalDesc:goalDesc,objectives:Dataobjectives});
                Dataobjectives = [];               
            }

            
      
           
        },

        nextClicked(currentPage) {           
           if (currentPage === 0) {
                if (this.diagCode !== "") {
                    this.getProblemsGroups();
                    return true;
                }
                else {
                    this.showMessage(this.$t('treatmentplan.Treatmentplan'), this.$t('treatmentplan.dSelectMsg'), "warning");
                    return false;
                }
            }
            else if (currentPage === 1) {
                if (this.Problem !== -1) {
                    this.getWileyTxBehavioralDefinitions();
                    return true;
                }
                else {
                    this.showMessage(this.$t('treatmentplan.Treatmentplan'), this.$t('treatmentplan.proSelectMsg'), "warning");
                    return false;
                }
            }          
            else if (currentPage === 2) {
                if (this.behavioralModel.length > 0) {
                    this.getWileyTxGoals();
                    return true;
                }
                else {
                    this.showMessage(this.$t('treatmentplan.Treatmentplan'), this.$t('treatmentplan.bSelectMsg'), "warning");
                    return false;
                }
            }
            else if (currentPage === 3) {
                if (this.GoalsModel.length > 0) {
                    this.getWileyTxObjectives();
                    return true;
                }
                else {
                    this.showMessage(this.$t('treatmentplan.Treatmentplan'), this.$t('treatmentplan.gSelectMsg'), "warning");
                    return false;
                }
            }
            else if (currentPage === 4) {
                if (this.IsSelectedObjectives()) {
                    this.getWileyTxInterventions();
                    return true;
                }
                else {
                    this.showMessage(this.$t('treatmentplan.Treatmentplan'), this.$t('treatmentplan.oSelectMsg'), "warning");
                    return false;
                }
            }
            else if (currentPage === 5) {             
                if (this.IsSelectedInterventions()) {                  
                    return true;
                }
                else {
                    this.showMessage(this.$t('treatmentplan.Treatmentplan'), this.$t('treatmentplan.iSelectMsg'), "warning");
                    return false;
                }
            }
            else if (currentPage === 6) {
                this.SaveProblem();
                return true;
            }
        },


        IsSelectedInterventions(){
            let result = true;
            for(var i = 0; i < this.InterventionsModel.length; i++){
                for(var j = 0; j < this.InterventionsModel[i].objectives.length; j++){
                    if(this.InterventionsModel[i].objectives[j].interventionsSelected.length === 0){
                        result = false;
                    }
                }
            }
            return result;
        },

        IsSelectedObjectives(){
            //let count = 0;
            let result = true;
            this.ObjectiveModel.forEach(element => { 
               // count += element.selectedCode.length;
               if(element.selectedCode.length === 0){
                    result = false;
               }
            })
            //return count > 0;
            return result;
        },
        EmitSetRowProblem() {
            setTimeout(() => this.$refs.RefTableTreatmentProblems.selectRow(this.ProblemIndexSelected), 300);
        },
        EmitSetRowDiagnoses() {
            setTimeout(() => this.$refs.RefTableTreatmentDiagnoses.selectRow(this.DiagnoseIndexSelected), 300);
        },
        SaveProblem() {
           // alert("Saving data, waiting to deploy");
            this.AddTX();
        },
        backClicked() {
            /*if(currentPage === 2){
              this.EmitSetRowProblem();
              return true;
            }else if(currentPage === 1){
              this.EmitSetRowDiagnoses();
              return true;
            }  */
            return true; //return false if you want to prevent moving to previous page
        },
        GoNext() {
            this.$refs.wizard.goNext(true);
        },
        GoNextDiagnose() {
            this.$refs.wizard.goNext(true);
        },
        onCodeConditionOk(item) {

            if(this.diagCode !== item.code){
                this.behavioralModel = [];
                this.GoalsModel = [];
                this.ObjectiveModel = [];
                this.InterventionsModel = [];
            }

            if (item.code) {
                this.diagCode = item.code;
                this.diagDesc = item.description;
                this.DiagnoseLabel = 'Diagnosis - ' + this.diagDesc + ' - ' + this.diagCode;
            }
            else {
                this.diagCode = "";
                this.diagDesc = "";
                this.DiagnoseLabel = this.$t('treatmentplan.labelSelect',{'label': this.$t('MdPatientDiagCodes.Diagnosis')});
            }
        },

        HaveObjectiveEvidenceBased(){
            let count = 0;
            for(var i = 0; i < this.ObjectiveList.length; i++){
                if(this.ObjectiveList[i].isEvidenceBased){
                    count++;
                }
            }
            return count > 0;
        },

        HaveSummaryEvidenceBased(){
            let count = 0;
            for(var i = 0; i < this.InterventionsModel.length; i++){
                if(this.InterventionsModel[i].itvDdescriptionSelected.length > 0){
                    if(this.InterventionsModel[i].isEvidenceBased){
                        count++;
                    }
                }             
                for(var j = 0; j < this.InterventionsModel[i].itvDdescriptionSelected.length; j++){
                    if(this.InterventionsModel[i].itvDdescriptionSelected[j].isEvidenceBased){
                        count++;
                    }
                }
               
            }
            return count > 0;
        },


        OnChangeProblem(code) {
            for (var i = 0; i < this.itemsProblems.length; i++) {
                if (this.itemsProblems[i].problemNumber === code) {
                    this.ProblemD = this.itemsProblems[i].description;
                }
            }
            if (this.Problem !== -1) {
                this.ProblemLabel = 'Problem № ' + this.Problem + ' - ' + this.ProblemD;
            }
            else {
                this.ProblemLabel = this.$t('treatmentplan.labelSelect',{'label': this.$t('modalEditProblem.Problem')});
            }

            this.behavioralModel = [];
            this.GoalsModel = [];
            this.ObjectiveModel = [];
            this.InterventionsModel = [];
        },
        GoNextProblem() {
            this.getWileyTxBehavioralDefinitions();
            this.$refs.wizard.goNext(true);
        },
        Save() {
        },
        onRowClickedDiagnoses(item, index) {
            this.DiagnoseIndexSelected = index;
        },
        onRowClickedProblem(item, index) {
            this.ProblemIndexSelected = index;
        },
        getBehavioralDescriptionByCode(code) {
            for (var i = 0; i < this.BehavioralList.length; i++) {
                if (this.BehavioralList[i].definitionNumber === code) {
                    return this.BehavioralList[i].description;
                }
            }
            return "";
        },
        getGoalsDescriptionByCode(code) {
            for (var i = 0; i < this.GoalsList.length; i++) {
                if (this.GoalsList[i].goalNumber === code) {
                    return this.GoalsList[i].description;
                }
            }
            return "";
        },

        getInteventionDescriptionByCode(arr, code) {
            for (var i = 0; i < arr.length; i++) {
                if (arr[i].interventionNumber === code) {
                    return arr[i].description;
                }
            }
            return "";
        },

        getObjectiveDescriptionByCode(arr, code) {
            for (var i = 0; i < arr.length; i++) {
                if (arr[i].objectiveNumber === code) {
                    return arr[i].description;
                }
            }
            return "";
        },

        OnChangeBehavioral(items) {
            if (items.length > 0) {
                this.SelectedBehavioral = [];
                for (var i = 0; i < items.length; i++) {
                    this.SelectedBehavioral.push(this.getBehavioralDescriptionByCode(items[i]));
                }
            }
            else {
                this.SelectedBehavioral = [];
            }
            if (items.length === 0) {
                this.BehavioralLabel = this.$t('treatmentplan.labelSelect',{'label': this.$t('modalAddGoal.Behavioral')});
            }
            else if (items.length === 1) {
                this.BehavioralLabel = 'Behavioral - ' + items.length + ' item selected';
            }
            else {
                this.BehavioralLabel = 'Behavioral - ' + items.length + ' items selected';
            }
        },
        OnChangeGoals(items) {
            if (items.length > 0) {
                this.SelectedGoals = [];
                for (var i = 0; i < items.length; i++) {
                    this.SelectedGoals.push(this.getGoalsDescriptionByCode(items[i]));
                }
            }
            else {
                this.SelectedGoals = [];
            }
            if (items.length === 0) {
                this.GoalLabel = this.$t('treatmentplan.labelSelect',{'label': this.$t('modalAddGoal.goals')});
            }
            else if (items.length === 1) {
                this.GoalLabel = 'Goals - ' + items.length + ' item selected';
            }
            else {
                this.GoalLabel = 'Goals - ' + items.length + ' items selected';
            }
        },
        OnChangeObjectives() {             
            var l = this.ObjectiveModel.length;
            let count = 0;
            for (var i = 0; i < l; i++) {
                count += this.ObjectiveModel[i].selectedCode.length;
                if (this.ObjectiveModel[i].selectedCode.length > 0) {
                    this.ObjectiveModel[i].selectedDesc = [];
                    for (var j = 0; j < this.ObjectiveModel[i].selectedCode.length; j++) {
                        let result = this.getObjSelectedObjective(i,j);                       
                        this.ObjectiveModel[i].selectedDesc.push(
                           {description:this.getObjectiveDescriptionByCode(this.ObjectiveModel[i].objectives, 
                            this.ObjectiveModel[i].selectedCode[j]),
                            class:result.class,
                            isEvidenceBased:result.isEvidenceBased
                           }  
                        );
                    }
                }
                else {
                    this.ObjectiveModel[i].selectedDesc = [];
                }
            }

           
            if (count === 0) {
                this.ObjectiveLabel = this.$t('treatmentplan.labelSelect',{'label': this.$t('modalAddObjective.objectives')});
            }
            else if (count === 1) {
                this.ObjectiveLabel = 'Objectives - ' + count + ' item selected';
            }
            else {
                this.ObjectiveLabel = 'Objectives - ' + count + ' items selected';
            }
        },

        getObjSelected(i,j,k){
            let code = this.InterventionsModel[i].objectives[j].interventionsSelected[k];
            let result = {class:"",isEvidenceBased:false};
            for(var n = 0; n < this.InterventionsModel[i].objectives[j].interventions.length; n++){
                if(this.InterventionsModel[i].objectives[j].interventions[n].interventionNumber === code){
                    result.class = this.InterventionsModel[i].objectives[j].interventions[n].class;
                    result.isEvidenceBased = this.InterventionsModel[i].objectives[j].interventions[n].isEvidenceBased;
                }
            }
            return result;
        },

        getObjSelectedObjective(i,pos){
            let code = this.ObjectiveModel[i].selectedCode[pos];
            let result = {class:"",isEvidenceBased:false};
            for(var j = 0; j < this.ObjectiveModel[i].objectives.length; j++){
                if(this.ObjectiveModel[i].objectives[j].objectiveNumber === code){
                    result.class = this.ObjectiveList[i].objectives[j].class;
                    result.isEvidenceBased = this.ObjectiveList[i].objectives[j].isEvidenceBased;
                }
            }
            return result;
        },

        OnChangeInterventions() {
            var l = this.InterventionsModel.length;
            let count = 0;
            for(var i = 0; i < l; i++){
                for(var j = 0; j < this.InterventionsModel[i].objectives.length; j++){
                    count += this.InterventionsModel[i].objectives[j].interventionsSelected.length;
                    if(this.InterventionsModel[i].objectives[j].interventionsSelected.length > 0){
                        this.InterventionsModel[i].objectives[j].itvDdescriptionSelected = [];
                        for(var k = 0; k < this.InterventionsModel[i].objectives[j].interventionsSelected.length; k++){
                            let result = this.getObjSelected(i,j,k);                        
                            this.InterventionsModel[i].objectives[j].itvDdescriptionSelected.push(
                                {description:this.getInteventionDescriptionByCode(this.InterventionsModel[i].objectives[j].interventions, 
                                    this.InterventionsModel[i].objectives[j].interventionsSelected[k]),
                                    class:result.class,
                                    isEvidenceBased:result.isEvidenceBased
                                });
                        }
                    }
                    else {
                        this.InterventionsModel[i].objectives[j].itvDdescriptionSelected = [];
                    }
                }
            }

            if (count === 0) {
                this.InterventionLabel = this.$t('treatmentplan.labelSelect',{'label': this.$t('modalAddIntervention.intervention')});
            }
            else if (count === 1) {
                this.InterventionLabel = 'Interventions - ' + count + ' item selected';
            }
            else {
                this.InterventionLabel = 'Interventions - ' + count + ' items selected';
            }
        },
        /*onRowSelectedDiagnoses(item){
          if(item.length > 0){
            this.SelectedDiagnosis = item[0];
            this.DiagnoseLabel = "Diagnosis - " +item[0].description+" - "+item[0].icd10code
          }else{
            this.SelectedDiagnosis = {"description":"","icd10code":""};
            this.DiagnoseLabel = "Diagnosis - please, select";
          }
        },*/
        onFilteredDiagnoses() {
        },
        pageChangedDiagnoses(page) {
            this.currentPageDiagnoses = page;
            this.getDiagTx();
        },
        PerPageFunctionDiagnoses(perpage) {
            this.perPageDiagnoses = perpage;
            this.currentPageDiagnoses = 1;
            this.getDiagTx();
        },
        SearchDiagnoses() {
            this.itemsDiagnoses = [];
            this.currentPageDiagnoses = 1;
            this.parameterDiag.PageNumber = 1;
            this.totalRowsDiagnoses = this.itemsDiagnoses.length;
            this.getDiagTx();
        },
        onRowSelectedProblems(item) {
            if (item.length > 0) {
                this.SelectedProblem = item[0];
                this.ProblemLabel = "Problem - " + item[0].description;
            }
            else {
                this.SelectedProblem = { "description": "", "problemNumber": "", "groupId": 0, "customProblem": "" };
                this.ProblemLabel = this.$t('treatmentplan.labelSelect',{'label': this.$t('modalEditProblem.Problem')});
            }
        },
        onFilteredProblems() {
        },
        pageChangedProblems(page) {
            this.currentPageProblems = page;
            this.getWileyTxProblems();
        },
        PerPageFunctionProblems(perpage) {
            this.perPageProblems = perpage;
            this.currentPageProblems = 1;
            this.getWileyTxProblems();
        },
        goTreatmentList() {
            this.$router.push({ name: "WyTreatmentplan-Clinical" });
        },
        SearchProblems() {
            this.itemsProblems = [];
            this.currentPageProblems = 1;
            this.parameterProb.PageNumber = 1;
            this.totalRowsProblems = this.itemsProblems.length;
            this.getWileyTxProblems();
        },
        ChangeProblemGroup(problem) {          
            this.behavioralModel = [];
            this.GoalsModel = [];
            this.ObjectiveModel = [];
            this.InterventionsModel = [];
            this.DiagnoseLabel = this.$t('treatmentplan.labelSelect',{'label': this.$t('MdPatientDiagCodes.Diagnosis')});
            this.ProblemLabel = this.$t('treatmentplan.labelSelect',{'label': this.$t('modalEditProblem.Problem')});
            this.BehavioralLabel = this.$t('treatmentplan.labelSelect',{'label': this.$t('treatmentplan.Behavioral')});
            this.ObjectiveLabel = this.$t('treatmentplan.labelSelect',{'label': this.$t('modalAddObjective.objectives')});
            this.InterventionLabel = this.$t('treatmentplan.labelSelect',{'label': this.$t('modalAddIntervention.intervention')});
            this.GoalLabel = this.$t('treatmentplan.labelSelect',{'label': this.$t('modalAddGoal.goals')});
            this.Problem = -1;
            this.ProblemLabel = this.$t('treatmentplan.labelSelect',{'label': this.$t('modalEditProblem.Problem')});
            for (var i = 0; i < this.InterventionsModel.length; i++) {
                this.InterventionsModel[i].interventionsSelected = [];
                this.InterventionsModel[i].itvDdescriptionSelected = [];
            }
            if (problem !== null && problem !== "") {
                this.ProblemGroupModel = problem;
                this.parameterProb.GroupId = problem.groupId;
                this.getWileyTxProblems();
            }
            else {
                this.ProblemGroupModel = "";
                this.parameterProb.GroupId = 0;
                this.itemsProblems = [];
            }
        },
        getWileyTxProblems() {
            this.$emit('load', true);
            /* this.parameterProb.PageNumber = this.currentPageProblems;
             this.parameterProb.PageSize = this.perPageProblems;  */
            var parameter = {
                GroupId: this.ProblemGroupModel.groupId,
                AdmissionId:this.$route.params.recordid
            };
            TreatmentPlanService.getWileyTxProblems(parameter)
                .then((response) => {
                if (response.status == '200' && response.data) {
                    this.itemsProblems = response.data;
                    let headerString = response.headers["x-pagination"];
                    this.HeaderProb = JSON.parse(headerString);
                    this.totalRowsProblems = this.HeaderProb.TotalCount;
                }
                if (this.itemsProblems.length === 0) {
                    this.ShowMessageIsEmptyProblems = true;
                }
                this.$emit('load', false);
            })
                .catch(error => {
                this.$emit('load', false);
                if (!error.response) {
                    this.showMessage(this.$t('treatmentplan.Treatmentplan'), this.$t('Msg.retrieveInfo'), "error");
                }
                else if (error.response.status === 400) {
                    this.showMessage(this.$t('treatmentplan.Treatmentplan'), error.response.data.errorMessage, "error");
                }
                else if (error.response.status === 500) {
                    this.showMessage(this.$t('treatmentplan.Treatmentplan'), this.$t('Msg.ThereWereSomeError'), "error");
                }
                else {
                    this.showMessage(this.$t('treatmentplan.Treatmentplan'), error.response.data.errorMessage, "error");
                }
            });
        },
        getDiagTx() {
            this.$emit('load', true);
            this.parameterDiag.PageNumber = this.currentPageDiagnoses;
            this.parameterDiag.PageSize = this.perPageDiagnoses;
            TreatmentPlanService.getDiagnosestx(this.parameterDiag)
                .then((response) => {
                if (response.status == '200' && response.data) {
                    this.itemsDiagnoses = response.data;
                    let headerString = response.headers["x-pagination"];
                    this.Header = JSON.parse(headerString);
                    this.totalRowsDiagnoses = this.Header.TotalCount;
                }
                if (this.itemsDiagnoses.length === 0) {
                    this.ShowMessageIsEmptyDiagnoses = true;
                }
                this.$emit('load', false);
            })
                .catch(error => {
                this.$emit('load', false);
                if (!error.response) {
                    this.showMessage(this.$t('treatmentplan.Treatmentplan'), this.$t('Msg.retrieveInfo'), "error");
                }
                else if (error.response.status === 400) {
                    this.showMessage(this.$t('treatmentplan.Treatmentplan'), error.response.data.errorMessage, "error");
                }
                else if (error.response.status === 500) {
                    this.showMessage(this.$t('treatmentplan.Treatmentplan'), this.$t('Msg.ThereWereSomeError'), "error");
                }
                else {
                    this.showMessage(this.$t('treatmentplan.Treatmentplan'), error.response.data.errorMessage, "error");
                }
            });
        },
        getProblemsGroups() {
            this.$emit('load', true);
            TreatmentPlanService.getWileyTxProblemGroups(this.diagCode)
                .then((response) => {
                if (response.status == '200' && response.data) {
                    this.ProblemGroupOptions = response.data;
                }
                this.$emit('load', false);
            })
                .catch(error => {
                this.$emit('load', false);
                if (!error.response) {
                    this.showMessage(this.$t('treatmentplan.tPlanGroup'), this.$t('Msg.retrieveInfo'), "error");
                }
                else if (error.response.status === 400) {
                    this.showMessage(this.$t('treatmentplan.tPlanGroup'), error.response.data.errorMessage, "error");
                }
                else if (error.response.status === 500) {
                    this.showMessage(this.$t('treatmentplan.tPlanGroup'), this.$t('Msg.ThereWereSomeError'), "error");
                }
                else {
                    this.showMessage(this.$t('treatmentplan.tPlanGroup'), error.response.data.errorMessage, "error");
                }
            });
        },
        getWileyTxBehavioralDefinitions() {
            this.$emit('load', true);
            var parameter = {
                GroupId: this.ProblemGroupModel.groupId,
                ProblemNumber: this.Problem,
                HeaderId: null
            };
            TreatmentPlanService.getWileyTxBehavioralDefinitions(parameter)
                .then((response) => {
                if (response.status == '200' && response.data) {
                    this.BehavioralList = response.data;
                }
                this.$emit('load', false);
            })
                .catch(error => {
                this.$emit('load', false);
                if (!error.response) {
                    this.showMessage(this.$t('treatmentplan.tPlanBeh'), this.$t('Msg.retrieveInfo'), "error");
                }
                else if (error.response.status === 400) {
                    this.showMessage(this.$t('treatmentplan.tPlanBeh'), error.response.data.errorMessage, "error");
                }
                else if (error.response.status === 500) {
                    this.showMessage(this.$t('treatmentplan.tPlanBeh'), this.$t('Msg.ThereWereSomeError'), "error");
                }
                else {
                    this.showMessage(this.$t('treatmentplan.tPlanBeh'), error.response.data.errorMessage, "error");
                }
            });
        },
        getWileyTxGoals() {
            this.$emit('load', true);
            var parameter = {
                GroupId: this.ProblemGroupModel.groupId,
                ProblemNumber: this.Problem,
            };
            TreatmentPlanService.getWileyTxGoals(parameter)
                .then((response) => {
                if (response.status == '200' && response.data) {
                    this.GoalsList = response.data;
                }
                this.$emit('load', false);
            })
                .catch(error => {
                this.$emit('load', false);
                if (!error.response) {
                    this.showMessage(this.$t('treatmentplan.tPlanGoals'), this.$t('Msg.retrieveInfo'), "error");
                }
                else if (error.response.status === 400) {
                    this.showMessage(this.$t('treatmentplan.tPlanGoals'), error.response.data.errorMessage, "error");
                }
                else if (error.response.status === 500) {
                    this.showMessage(this.$t('treatmentplan.tPlanGoals'), this.$t('Msg.ThereWereSomeError'), "error");
                }
                else {
                    this.showMessage(this.$t('treatmentplan.tPlanGoals'), error.response.data.errorMessage, "error");
                }
            });
        },
        getWileyTxObjectives() {
            this.$emit('load', true);
            var parameter = {
                IsWizard:true,
                GroupId: this.ProblemGroupModel.groupId,
                ProblemNumber: this.Problem,
                GoalNumberList : this.GoalsModel
            };
            TreatmentPlanService.getWileyTxObjectives(parameter)
                .then((response) => {
                if (response.status == '200' && response.data) {
                    this.ObjectiveList = response.data;

                    let arrpos = [];
                    for (var i = 0; i < this.ObjectiveModel.length; i++) {                       
                        if (!this.ExistObjetiveinGoalsList(this.ObjectiveModel[i].goalNumber)) {
                            arrpos.push(i);
                        }
                    }                    
                    arrpos.reverse();                    
                    for (var j = 0; j < arrpos.length; j++) { 
                        this.ObjectiveModel.splice(arrpos[j], 1);                          
                    }

                    this.ObjectiveList.forEach(element => {                      
                        element.class = element.isEvidenceBased ? "InterventionByObjWizard EvidenceBasedTitle":"InterventionByObjWizard";
                        element.classSummary = element.isEvidenceBased ? "EvidenceBased":"";

                        for(var j = 0; j < element.objectives.length; j++){
                            element.objectives[j].class = element.objectives[j].isEvidenceBased ? "EvidenceBased":"";
                        }

                      if (!this.ExistObjectiveInGoals(element.goalNumber)) {
                            this.ObjectiveModel.push({
                                description: element.description,
                                goalNumber: element.goalNumber,
                                objectives: element.objectives,
                                selectedCode: element.selectedCode = [],
                                selectedDesc: element.selectedDesc = []                               
                            });
                        
                       }
                    });
                  
                    this.OnChangeObjectives();
                    this.$emit('load', false);
                }
                this.$emit('load', false);
            })
                .catch(error => {
                this.$emit('load', false);
                if (!error.response) {
                    this.showMessage(this.$t('treatmentplan.tPlanGoals'), this.$t('Msg.retrieveInfo'), "error");
                }
                else if (error.response.status === 400) {
                    this.showMessage(this.$t('treatmentplan.tPlanGoals'), error.response.data.errorMessage, "error");
                }
                else if (error.response.status === 500) {
                    this.showMessage(this.$t('treatmentplan.tPlanGoals'), this.$t('Msg.ThereWereSomeError'), "error");
                }
                else {
                    this.showMessage(this.$t('treatmentplan.tPlanGoals'), error.response.data.errorMessage, "error");
                }
            });
        },

        EmployeeCodeIsinList(EmpCode){
            let result = false;
            for(var i = 0; i < this.ProviderList.length; i++){
                if(this.ProviderList[i].therapistCode === EmpCode){
                    result = true;
                    break;
                }                    
            }
            return result;
        },

        getEmployeeProvider(){
      const UserId = LocalStorage.getUserId();
      EmployeeService.getEmployees(UserId)
            .then((response) => { 
              if(response.data){              
                let result = response.data;                    
                for(var i = 0; i < result.length; i++){
                    this.ProviderList.push({therapistCode:result[i].code,therapistName:result[i].name});
                }
               
                if(this.EmployeeCodeIsinList(this.EmployeeCode)){
                    this.ProviderModel.therapistCode = this.EmployeeCode;
                    this.ProviderModel.therapistName = LocalStorage.getName();
                }                
                 
              }             
            })
            .catch((error) => {
                this.$emit('load', false);
                    if (!error.response) {
                        this.showMessage(this.$t('modalAddSignature.eProvider'), this.$t('Msg.retrieveInfo'), "error");
                    }
                    else if (error.response.status === 400) {
                        this.showMessage(this.$t('modalAddSignature.eProvider'), error.response.data.errorMessage, "error");
                    } else if (error.response.status === 500) {
                        this.showMessage(this.$t('modalAddSignature.eProvider'), this.$t('Msg.ThereWereSomeError'), "error");
                    } else {
                        this.showMessage(this.$t('modalAddSignature.eProvider'), error.response.data.errorMessage, "error");
                    }
            });
    },

    getEmployeeCoProvider(){      
      EmployeeService.getEmployeeCosigner()
            .then((response) => { 
              if(response.data){               
                let result = response.data;                    
                for(var i = 0; i < result.length; i++){
                    this.CoProviderList.push({coTherapistCode:result[i].code,coTherapistName:result[i].name});
                }
                 
              }             
            })
            .catch((error) => {
                this.$emit('load', false);
                    if (!error.response) {
                        this.showMessage(this.$t('modalAddSignature.eProvider'), this.$t('Msg.retrieveInfo'), "error");
                    }
                    else if (error.response.status === 400) {
                        this.showMessage(this.$t('modalAddSignature.eProvider'), error.response.data.errorMessage, "error");
                    } else if (error.response.status === 500) {
                        this.showMessage(this.$t('modalAddSignature.eProvider'), this.$t('Msg.ThereWereSomeError'), "error");
                    } else {
                        this.showMessage(this.$t('modalAddSignature.eProvider'), error.response.data.errorMessage, "error");
                    }
            });
    },

        ExistObjectiveInGoals(objGoal) {          
            var l = this.ObjectiveModel.length;
            for (var i = 0; i < l; i++) {            
                if (this.ObjectiveModel[i].goalNumber === objGoal) {
                    return true;
                }
                
            }
            return false;
        },

        ExistGoal(goalNumber) {
            var l = this.InterventionsModel.length;
            for (var i = 0; i < l; i++) {
                if (this.InterventionsModel[i].goalNumber === goalNumber) {
                    return true;
                }
            }
            return false;
        },
        ExistObjetiveinInterventionsList(ObjNumber) {
            let result = false;
            var l = this.ObjectiveModel.length;
            for (var i = 0; i < l; i++) {
                for(var j = 0; j < this.ObjectiveModel[i].selectedCode.length; j++){
                    if (this.ObjectiveModel[i].selectedCode[j] === ObjNumber) {
                        result = true;
                    }     
                }
                /*if (this.ObjectiveModel[i] === ObjNumber) {
                    return true;
                }*/
            }
            return result;
        },

        ExistObjetiveinGoalsList(ObjGoal) {
            var l = this.GoalsModel.length;
            for (var i = 0; i < l; i++) {
                if (this.GoalsModel[i] === ObjGoal) {
                    return true;
                }
            }
            return false;
        },

        PopulateListRequestIntervention(){
            this.GoalNumberList = [];
            this.ObjectiveNumberList = [];
            this.ObjectiveModel.forEach(element => {
                if(element.selectedCode.length > 0){
                    for(var i = 0; i < element.selectedCode.length; i++){
                        this.GoalNumberList.push(element.goalNumber);
                        this.ObjectiveNumberList.push(element.selectedCode[i]);
                    }
                }
            })
        },

        ExistGoalinIntervention(goalNumber){        
            var arr = this.GoalsModel;
            let l = arr.length;
            for(var i = 0; i < l; i++){
                if(arr[i] === goalNumber){
                    return true;
                }
            }
            return false;
        },

        RemoveEmptyObjective(){
            var arrGoals = [];          
            for(var j = 0 ; j < this.InterventionsModel.length; j++){
                if(this.InterventionsModel[j].objectives.length == 0){
                    arrGoals.push(this.InterventionsModel[j].goalNumber);            
                }
            }          
            var temp = [];
            for(var i = 0; i < arrGoals.length; i++){               
                temp =  this.InterventionsModel.filter(item => item.goalNumber !== arrGoals[i]);
            }           
            this.InterventionsModel = temp.length > 0 ?  temp : this.InterventionsModel;
        },

        esCereza(fruta) {
            return fruta.nombre === "cerezas";
        },



        IsIntInGoals(goalNumber){
            for(var j = 0; j < this.GoalsModel.length; j++){
                if(this.GoalsModel[j] === goalNumber){
                    return true;
                }
            }
            return false;  
        },
        RemoveGoalNotChecked(){
            let arrtemp = [];          
            for(var i = 0; i < this.InterventionsModel.length; i++){
                if(this.IsIntInGoals(this.InterventionsModel[i].goalNumber)){
                    arrtemp.push(this.InterventionsModel[i]);
                }
            }

            this.InterventionsModel = arrtemp.length > 0 ?  arrtemp : this.InterventionsModel;
        },

        IsIntInObjectives(goalNumber,objectiveNumber){        
            var objectives = this.ObjectiveModel.filter(item => item.goalNumber === goalNumber);          
            if(objectives.length > 0){
                var codes = objectives[0].selectedCode;
                for(var i = 0; i < codes.length; i++){
                    if(codes[i] === objectiveNumber)
                        return true;
                }
            }           
            return false;            
        },


        FindObjectiveInintevList(goalNumber,objNumber){
            var itv = this.InterventionsList.filter(item => item.goalNumber === goalNumber);
            var objtemp = {};
            if(itv.length > 0){
                let itvtmp = itv[0];
                for(var i = 0; i < itvtmp.objectives.length; i++){
                    if(itvtmp.objectives[i].objectiveNumber === objNumber){
                        objtemp = itvtmp.objectives[i];
                    }
                }
            }         

            return objtemp
        },

        AddObj(goalNumber,obj){      
            if(obj !== null){
                for(var i = 0; i < this.InterventionsModel.length; i++){
                    if(this.InterventionsModel[i].goalNumber === goalNumber){
                        this.InterventionsModel[i].objectives.push(obj);
                    }
                }
            }
          
        },

        ObjectiveIsInInterv(goalNumber,objNumber){
            var objectives = this.InterventionsModel.filter(item => item.goalNumber === goalNumber);
            if(objectives.length > 0){
                var objs = objectives[0].objectives;
                for(var i = 0; i < objs.length; i++){
                    if(objs[i].objectiveNumber === objNumber)
                        return true;
                }
            }
            return false;
        },


        AddNewObjectives(){            
            for(var i = 0; i < this.ObjectiveModel.length; i++){
                for(var j = 0; j < this.ObjectiveModel[i].selectedCode.length; j++){                    
                    if(!this.ObjectiveIsInInterv(this.ObjectiveModel[i].goalNumber,this.ObjectiveModel[i].selectedCode[j])){
                        var obj = this.FindObjectiveInintevList(this.ObjectiveModel[i].goalNumber,this.ObjectiveModel[i].selectedCode[j]);
                        if(obj !== null){
                            this.AddObj(this.ObjectiveModel[i].goalNumber,obj);
                        }
                    }
                }
            }
        },

        RemoveObjectiveNotChecked(){
            let arrtemp = [];
            let arrposdelete = [];
            for(var i = 0; i < this.InterventionsModel.length; i++){
                for(var j = 0; j < this.InterventionsModel[i].objectives.length; j++){ 
                    if(this.IsIntInObjectives(this.InterventionsModel[i].goalNumber,this.InterventionsModel[i].objectives[j].objectiveNumber)){
                        arrtemp.push(this.InterventionsModel[i].objectives[j]);
                    }else{
                        arrposdelete.push({goalpos:i,objectivepos:j}); 
                    }
                }               
                this.InterventionsModel[i].objectives = arrtemp.length > 0 ?  arrtemp : this.InterventionsModel[i].objectives;               
                arrposdelete.reverse();
                for(var k = 0; k < arrposdelete.length; k++){                   
                    this.InterventionsModel[arrposdelete[k].goalpos].objectives.splice(arrposdelete[k].objectivepos, 1);    
                }
                arrtemp = [];
                arrposdelete = [];
            }
            
        },

        getWileyTxInterventions() {
            this.PopulateListRequestIntervention();
            this.$emit('load', true);
            var parameter = {
                GroupId: this.ProblemGroupModel.groupId,
                ProblemNumber: this.Problem,
                GoalNumberList:this.GoalNumberList,
                ObjectiveNumberList: this.ObjectiveNumberList,
                IsWizard:true
            };
            TreatmentPlanService.getWileyTxInterventions(parameter)
                .then((response) => {
                if (response.status == '200' && response.data) {
                    this.InterventionsList = response.data;

                    this.InterventionsList.forEach(element => { 

                        for(var i = 0; i < element.objectives.length; i++){

                            element.objectives[i].class = element.objectives[i].isEvidenceBased ? "InterventionByObjWizard EvidenceBasedTitle":"InterventionByObjWizard";
                            element.objectives[i].classSummary = element.objectives[i].isEvidenceBased ? "EvidenceBased":"";
                            element.objectives[i].interventionsSelected = element.objectives[i].interventionsSelected = [];
                            element.objectives[i].itvDdescriptionSelected = element.objectives[i].itvDdescriptionSelected = [];

                            for(var j = 0; j < element.objectives[i].interventions.length; j++){
                                element.objectives[i].interventions[j].class = element.objectives[i].interventions[j].isEvidenceBased ? "EvidenceBased":"";
                            }
                        }

                        if (!this.ExistGoal(element.goalNumber)) {                           
                            this.InterventionsModel.push({
                                goalNumber:element.goalNumber,
                                description:element.description,
                                objectives:element.objectives
                            });
                        }
                    })

                    this.RemoveGoalNotChecked();
                    this.RemoveObjectiveNotChecked();
                    this.AddNewObjectives();
                   
                }
                this.$emit('load', false);
            })
                .catch(error => {
                this.$emit('load', false);
                if (!error.response) {
                    this.showMessage(this.$t('treatmentplan.tPlanInter'), this.$t('Msg.retrieveInfo'), "error");
                }
                else if (error.response.status === 400) {
                    this.showMessage(this.$t('treatmentplan.tPlanInter'), error.response.data.errorMessage, "error");
                }
                else if (error.response.status === 500) {
                    this.showMessage(this.$t('treatmentplan.tPlanInter'), this.$t('Msg.ThereWereSomeError'), "error");
                }
                else {
                    this.showMessage(this.$t('treatmentplan.tPlanInter'), error.response.data.errorMessage, "error");
                }
            });
        },
    },
    created() {
        this.EmployeeCode = this.$store.state.employeeCode; 
        this.getEmployeeProvider();
        this.getEmployeeCoProvider();
    },
    async mounted() {
        this.OptionsRightMenu.visible = true;
        this.OptionsRightMenu.ItemsList = EnumService.ClinicalItems;
        this.$emit('getVisibleMenu', this.OptionsRightMenu);
        this.$emit('getBtnRightMenu', this.OptionsRightMenu);
        this.RegistrationInfo = await this.getRegistrationInfoTitle(this.$route.params.recordid);
        if (this.RegistrationInfo.dateFrom)
            this.RegistrationInfo.dateFrom = moment(this.RegistrationInfo.dateFrom).format('MM/DD/YYYY');
        if (this.RegistrationInfo.dateTo)
            this.RegistrationInfo.dateTo = moment(this.RegistrationInfo.dateTo).format('MM/DD/YYYY');
        if (this.RegistrationInfo.dob)
            this.RegistrationInfo.dob = moment(this.RegistrationInfo.dob).format('MM/DD/YYYY');
        this.RegistrationInfo.age = this.RegistrationInfo.age === 0 ? "0" : this.RegistrationInfo.age;
        //this.getDiagTx();   
    },
    
};
</script>


