<template>
    <div class="notes ">
        <h4 class="note-title text-truncate" style="background: #7be3fd;" v-b-tooltip.hover >
            <span> {{this.getTitle()}}</span>
        </h4>
        <b-icon
            icon="x-circle-fill"
            v-on:click="Close"
            class="rounded-circle notes-close"
            scale=".5"
            font-scale="1.8">
        </b-icon>
        <div class="row page-height">
            <div class="col-12">            
                <div class="alert alert-fill-warning" role="alert" v-if="IncidentItems.header.isVoid">
                    <i class="mdi mdi-alert-circle"></i>
                    {{ $t('Incident.IncIsVoidMsg') }}
                </div>       
            </div>
        </div>
    <div class="notesContent cutescroll" :id="'ContainerSummary'">
    <b-row>
        <IncidentReportHeaderBox
            v-bind:Header="IncidentItems.header"
            v-bind:RoomList="this.RoomList"
            v-bind:LocationList="this.LocationList">
        </IncidentReportHeaderBox>
    </b-row>
    <MdTitle v-bind:Text="$t('ClinicalNotes.CATEGORIES')" v-bind:isSubtitle="true"/>
    <div class="row">
        <div class="col-12">                         
         <!-- <div v-if="showEmptyRecords" v-bind:style="emptyRecordsStyle" role="alert" aria-live="polite"><div class="text-center my-2">There are no records to show</div></div>-->
            <!-- div Container Boxs -->
            <div v-for="(item,index) in searchCategories(this.CategoriesItems, this.parameter.SearchCriteria)" :key="index" class="IncidentContainerBox">
                <div class="row">
                    <div class="col-md-12">
                        <div class="grid-margin stretch-card">
                            <div class="card activeCard">
                                <div class="card-body">
                                    <div class="d-flex align-items-start justify-content-between">
                                    <h6 class="card-title flex-grow lower-line text-truncate">{{ item.categoryName }}</h6>                                        
                                    </div>							
                                    <div class="flex-container">
                                        <div class="row">
                                            <div class="col-md-3" >
                                                <div class="float-left col text-muted p-item">
                                                    <small>{{ $t('Incident.IncPrimary') }}</small><br>
                                                    <b>{{item.isPrimary ? $t('Shared.TitleYes') : 'No'}}</b>                                                     
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="float-left col text-muted p-item">
                                                    <small>{{ $t('Incident.IncSeverity') }}</small><br>
                                                    <b v-if="item.severityName === ''">{{ $t('Incident.IncNoSeverityMsg') }}</b>   
                                                    <b>{{ item.severityName }}</b>                                                     
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="float-left col text-muted p-item">                                                        
                                                    <b><small>{{ $t('Incident.IncType') }}</small></b><br>                                                   
                                                    <ul>							
                                                        <li v-for="(it, index) in item.incidentTypes" :key="`it.code-${index}`">{{it}}</li>						
                                                    </ul>   
                                                </div>
                                            </div>                                              								
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                                   
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="grid-margin stretch-card">
                            <div class="card activeCard">
                                <div class="card-body">
                                    <div class="d-flex align-items-start justify-content-between">
                                    <h6 class="card-title flex-grow lower-line text-truncate">{{ $t('Shared.Description') }}</h6>                                        
                                    </div>							
                                    <div class="text-muted p-fixed cutescroll autoH">
                                        <p v-if="item.incidentDescription.length === 0">{{ $t('Incident.IncNoDescMsg') }}</p>
                                        <b><div v-if="item.incidentDescription.length != 0" v-html="item.incidentDescription"></div></b>     
                                    </div>
                                </div>
                            </div>
                        </div>                                   
                    </div>
                    <div class="col-md-6">
                        <div class="grid-margin stretch-card">
                            <div class="card activeCard">
                                <div class="card-body">   
                                    <div class="d-flex align-items-start justify-content-between">
                                        <h6 class="card-title flex-grow lower-line text-truncate">{{ $t('Incident.IncInter') }}</h6>                                        
                                        </div>                               
                                    <div class="text-muted p-fixed cutescroll autoH">
                                        <p v-if="item.interventions.length === 0">{{ $t('Incident.IncNoIntercMsg') }}</p>
                                            <ul>							
                                                <li v-for="(it, index) in item.interventions" :key="`it.code-${index}`">{{it.name}}</li>
                                                <li v-if="item.intervention">{{getOtherIntervention(item.intervention)}}</li>						
                                            </ul>
                                    </div>
                                </div>
                            </div>
                        </div>                                   
                    </div>
                </div>
                
            </div>                          
        </div>
    </div>

    <MdTitle v-bind:Text="$tc('Incident.IncParticipant',2)+' - '+ $tc('Incident.IncPartClient',2)"  v-bind:isSubtitle="true" />
            <div class="row">
                <div class="col-12">  
                         <!-- div Container Boxs -->
                    <div v-for="(item,index) in this.searchParticipant(this.ClientItems, participantClientSearch)" :key="index" class="IncidentContainerBox">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="grid-margin stretch-card">
                                    <div class="card activeCard">
                                        <div class="card-body">
                                            <div class="d-flex align-items-start justify-content-between">
                                            <h6 class="card-title flex-grow lower-line text-truncate">{{ item.entityName }}</h6>                                        
                                            </div>							
                                            <div class="flex-container">
                                                <div class="row">
                                                    <div class="col-md-3">
                                                        <div class="float-left col text-muted p-item">
                                                            <small>{{ $t('Incident.IncPartType') }}</small><br>
                                                            <b>{{ item.participationDescription }}</b>                                                     
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3" >
                                                        <div class="float-left col text-muted p-item">
                                                            <small>{{ $tc('Shared.Program') }}</small><br>
                                                            <b v-if="item.programName === null || item.programName === ''"> No Program </b>     
                                                            <b> {{item.programName}} </b>                                                     
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3" >
                                                        <div class="float-left col text-muted p-item">
                                                            <small>{{ $t('Incident.IncPartArrest') }}</small><br>
                                                            <b>{{item.arrestMade ? $t('Shared.TitleYes') : 'No'}}</b>                                                     
                                                        </div>
                                                    </div>                                                   
                                                    <div class="col-md-3">
                                                        <div class="float-left col text-muted p-item">                                                        
                                                            <small>{{ $t('Incident.IncVisiToER') }}</small><br>
                                                            <b>{{item.ervisit ? $t('Shared.TitleYes') : 'No'}}</b>   
                                                        </div>
                                                    </div>                                              								
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>                                   
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="grid-margin stretch-card">
                                    <div class="card activeCard">
                                        <div class="card-body">
                                            <div class="d-flex align-items-start justify-content-between">
                                            <h6 class="card-title flex-grow lower-line text-truncate">{{ $t('Incident.IncPartPersonalSta') }} - {{item.statementDate}}</h6>                                        
                                            </div>							
                                            <div class="flex-container">
                                                <div class="row">                                     
                                                    <div class="col-md-12">
                                                        <div class="float-left col text-muted p-item">   
                                                            <b v-if="item.personalStatement === null">{{ $t('Incident.IncNoPersStaMsg') }}</b>                                                    
                                                            <b>{{ item.personalStatement }}</b>                                                     
                                                        </div>
                                                    </div>                               								
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>                                   
                            </div>  
                            <div class="col-md-6">
                                <div class="grid-margin stretch-card">
                                    <div class="card activeCard">
                                        <div class="card-body">
                                            <div class="d-flex align-items-start justify-content-between">
                                            <h6 class="card-title flex-grow lower-line text-truncate">{{ $t('Incident.IncPartComments') }}</h6>                                        
                                            </div>							
                                            <div class="flex-container">
                                                <div class="row">                                     
                                                    <div class="col-md-12">
                                                        <div class="float-left col text-muted p-item">                                                          
                                                            <b v-if="item.observation === null"> {{ $t('Incident.IncNoObserMsg') }}</b>                                                
                                                            <b>{{ item.observation }}</b>                                                     
                                                        </div>
                                                    </div>                               								
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>                                   
                            </div>                        
                        </div>                        
                    </div>                          
                </div>
            </div>

        <MdTitle v-if="this.EmployeeItems && this.EmployeeItems.length > 0" v-bind:Text="$tc('Incident.Participants',2)+' - '+ $tc('Shared.Employee',2)"  v-bind:isSubtitle="true" />
            <div v-if="this.EmployeeItems && this.EmployeeItems.length > 0" class="row">
            <div class="col-sm-12 col-md-12 mb-12">
            <div class="textlabel" :data-label="this.$t('Shared.SearchElements')">
                <div class="input-group">
                <input 
                    type="text" 
                    class="form-control"
                    v-model="participantEmployeeSearch">

                <div class="input-group-append">
                    <MdButton 
                    v-bind:Name="'btnsearchinput'"
                    v-bind:VariantProp="'btn btn-sm search-attached'"
                    v-bind:IconClass="'mdi mdi-magnify'">
                    </MdButton>
                </div>
                </div>
            </div>
            </div>
            </div>
            <div v-if="this.EmployeeItems && this.EmployeeItems.length > 0" class="row">
                <div class="col-12">  
                     <!-- div Container Boxs -->  
                    <div v-for="(item,index) in this.searchParticipant(this.EmployeeItems,participantEmployeeSearch)" :key="index" class="IncidentContainerBox">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="grid-margin stretch-card">
                                    <div class="card activeCard">
                                        <div class="card-body">
                                            <div class="d-flex align-items-start justify-content-between">
                                            <h6 class="card-title flex-grow lower-line text-truncate">{{ item.entityName }}</h6>                                        
                                            </div>							
                                            <div class="flex-container">
                                                <div class="row">
                                                    <div class="col-md-3">
                                                        <div class="float-left col text-muted p-item">
                                                            <small>{{ $t('Incident.IncPartType') }}</small><br>
                                                            <b>{{ item.participationDescription }}</b>                                                     
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3" >
                                                        <div class="float-left col text-muted p-item">
                                                            <small>{{ $t('Incident.IncPartArrest') }}</small><br>
                                                            <b>{{item.arrestMade ? $t('Shared.TitleYes') : 'No'}}</b>                                                     
                                                        </div>
                                                    </div>                                                    
                                                    <div class="col-md-6">
                                                        <div class="float-left col text-muted p-item">                                                        
                                                            <small>{{ $t('Incident.IncVisiToER') }}</small><br>
                                                            <b>{{item.ervisit ?  $t('Shared.TitleYes')  : 'No'}}</b>   
                                                        </div>
                                                    </div>                                              								
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>                                   
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="grid-margin stretch-card">
                                    <div class="card activeCard">
                                        <div class="card-body">
                                            <div class="d-flex align-items-start justify-content-between">
                                            <h6 class="card-title flex-grow lower-line text-truncate">{{ $t('Incident.IncPartPersonalSta') }} - {{item.statementDate}}</h6>                                        
                                            </div>							
                                            <div class="flex-container">
                                                <div class="row">                                     
                                                    <div class="col-md-12">
                                                        <div class="float-left col text-muted p-item">   
                                                            <b v-if="item.personalStatement === null"> {{ $t('Incident.IncNoPersStaMsg') }}</b>                                                    
                                                            <b>{{ item.personalStatement }}</b>                                                     
                                                        </div>
                                                    </div>                               								
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>                                   
                            </div>  
                            <div class="col-md-6">
                                <div class="grid-margin stretch-card">
                                    <div class="card activeCard">
                                        <div class="card-body">
                                            <div class="d-flex align-items-start justify-content-between">
                                            <h6 class="card-title flex-grow lower-line text-truncate">{{ $t('Incident.IncPartComments') }}</h6>                                        
                                            </div>							
                                            <div class="flex-container">
                                                <div class="row">                                     
                                                    <div class="col-md-12">
                                                        <div class="float-left col text-muted p-item">                                                          
                                                            <b v-if="item.observation === null"> {{ $t('Incident.IncNoObserMsg') }}</b>                                                
                                                            <b>{{ item.observation }}</b>                                                     
                                                        </div>
                                                    </div>                               								
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>                                   
                            </div>                        
                        </div>                        
                    </div>                          
                </div>
            </div>
           
        <MdTitle v-if="this.ContactItems && this.ContactItems.length > 0" v-bind:Text="$tc('Incident.Participants',2)+' - '+ $tc('Contact.Contact',2)"  v-bind:isSubtitle="true" />
            <div v-if="this.ContactItems && this.ContactItems.length > 0" class="row">
            <div class="col-sm-12 col-md-12 mb-12">
            <div class="textlabel" :data-label="this.$t('Shared.SearchElements')">
                <div class="input-group">
                <input 
                    type="text" 
                    class="form-control"
                    v-model="participantContactSearch">

                <div class="input-group-append">
                    <MdButton 
                    v-bind:Name="'btnsearchinput'"
                    v-bind:VariantProp="'btn btn-sm search-attached'"
                    v-bind:IconClass="'mdi mdi-magnify'">
                    </MdButton>
                </div>
                </div>
            </div>
            </div>
            </div>


            <div v-if="this.ContactItems && this.ContactItems.length > 0" class="row">
                <div class="col-12">  
                     <!-- div Container Boxs -->    
                    <div v-for="(item,index) in this.searchParticipant(this.ContactItems,participantContactSearch)" :key="index" class="IncidentContainerBox">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="grid-margin stretch-card">
                                    <div class="card activeCard">
                                        <div class="card-body">
                                            <div class="d-flex align-items-start justify-content-between">
                                            <h6 class="card-title flex-grow lower-line text-truncate">{{ item.entityName }}</h6>                                        
                                            </div>							
                                            <div class="flex-container">
                                                <div class="row">
                                                    <div class="col-md-3">
                                                        <div class="float-left col text-muted p-item">
                                                            <small>{{ $t('Incident.IncPartType') }}</small><br>
                                                            <b>{{ item.participationDescription }}</b>                                                     
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3" >
                                                        <div class="float-left col text-muted p-item">
                                                            <small>{{ $t('Incident.IncPartArrest') }}</small><br>
                                                            <b>{{item.arrestMade ? $t('Shared.TitleYes') : 'No'}}</b>                                                     
                                                        </div>
                                                    </div>
                                                   
                                                    <div class="col-md-6">
                                                        <div class="float-left col text-muted p-item">                                                        
                                                            <small>{{ $t('Incident.IncVisiToER') }}</small><br>
                                                            <b>{{item.ervisit ? $t('Shared.TitleYes') : 'No'}}</b>   
                                                        </div>
                                                    </div>                                              								
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>                                   
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="grid-margin stretch-card">
                                    <div class="card activeCard">
                                        <div class="card-body">
                                            <div class="d-flex align-items-start justify-content-between">
                                            <h6 class="card-title flex-grow lower-line text-truncate">{{ $t('Incident.IncPartPersonalSta') }} - {{item.statementDate}}</h6>                                        
                                            </div>							
                                            <div class="flex-container">
                                                <div class="row">                                     
                                                    <div class="col-md-12">
                                                        <div class="float-left col text-muted p-item">   
                                                            <b v-if="item.personalStatement === null"> {{ $t('Incident.IncNoPersStaMsg') }}</b>                                                    
                                                            <b>{{ item.personalStatement }}</b>                                                     
                                                        </div>
                                                    </div>                               								
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>                                   
                            </div>  
                            <div class="col-md-6">
                                <div class="grid-margin stretch-card">
                                    <div class="card activeCard">
                                        <div class="card-body">
                                            <div class="d-flex align-items-start justify-content-between">
                                            <h6 class="card-title flex-grow lower-line text-truncate">{{ $t('Incident.IncPartConRecommendations') }}</h6>                                        
                                            </div>							
                                            <div class="flex-container">
                                                <div class="row">                                     
                                                    <div class="col-md-12">
                                                        <div class="float-left col text-muted p-item">                                                          
                                                            <b v-if="item.observation === null"> {{ $t('Incident.IncNoRecomMsg') }}</b>                                                
                                                            <b>{{ item.observation }}</b>                                                     
                                                        </div>
                                                    </div>                               								
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>                                   
                            </div>                        
                        </div>                        
                    </div>                          
                </div>
            </div>

        <MdTitle v-if="this.VisitorItems && this.VisitorItems.length > 0" v-bind:Text="$tc('Incident.Participants',2)+' - '+ $t('Incident.IncVisitorsTap')"  v-bind:isSubtitle="true" />
            <div v-if="this.VisitorItems && this.VisitorItems.length > 0" class="row">
            <div class="col-sm-12 col-md-12 mb-12">
            <div class="textlabel" :data-label="this.$t('Shared.SearchElements')">
                <div class="input-group">
                <input 
                    type="text" 
                    class="form-control"
                    v-model="participantVisitorSearch">

                <div class="input-group-append">
                    <MdButton 
                    v-bind:Name="'btnsearchinput'"
                    v-bind:VariantProp="'btn btn-sm search-attached'"
                    v-bind:IconClass="'mdi mdi-magnify'">
                    </MdButton>
                </div>
                </div>
            </div>
            </div>
            </div>

            <div v-if="this.VisitorItems && this.VisitorItems.length > 0" class="row">
                <div class="col-12">   
                     <!-- div Container Boxs -->   
                    <div v-for="(item,index) in this.searchParticipant(this.VisitorItems,participantVisitorSearch)" :key="index" class="IncidentContainerBox">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="grid-margin stretch-card">
                                    <div class="card activeCard">
                                        <div class="card-body">
                                            <div class="d-flex align-items-start justify-content-between">
                                            <h6 class="card-title flex-grow lower-line text-truncate">{{ item.entityName }}</h6>                                        
                                            </div>							
                                            <div class="flex-container">
                                                <div class="row">
                                                    <div class="col-md-3">
                                                        <div class="float-left col text-muted p-item">
                                                            <small>{{ $t('Incident.IncPartType') }}</small><br>
                                                            <b>{{ item.participationDescription }}</b>                                                     
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3" >
                                                        <div class="float-left col text-muted p-item">
                                                            <small>{{ $t('Incident.IncPartArrest') }}</small><br>
                                                            <b>{{item.arrestMade ? $t('Shared.TitleYes')  : 'No'}}</b>                                                     
                                                        </div>
                                                    </div>                                                    
                                                    <div class="col-md-6">
                                                        <div class="float-left col text-muted p-item">                                                        
                                                            <small>{{ $t('Incident.IncVisiToER') }}</small><br>
                                                            <b>{{item.ervisit ? $t('Shared.TitleYes') : 'No'}}</b>   
                                                        </div>
                                                    </div>                                              								
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>                                   
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="grid-margin stretch-card">
                                    <div class="card activeCard">
                                        <div class="card-body">
                                            <div class="d-flex align-items-start justify-content-between">
                                            <h6 class="card-title flex-grow lower-line text-truncate">{{ $t('Incident.IncPartPersonalSta') }} - {{item.statementDate}}</h6>                                        
                                            </div>							
                                            <div class="flex-container">
                                                <div class="row">                                     
                                                    <div class="col-md-12">
                                                        <div class="float-left col text-muted p-item">   
                                                            <b v-if="item.personalStatement === null"> {{ $t('Incident.IncNoPersStaMsg') }}</b>                                                    
                                                            <b>{{ item.personalStatement }}</b>                                                     
                                                        </div>
                                                    </div>                               								
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>                                   
                            </div>  
                            <div class="col-md-6">
                                <div class="grid-margin stretch-card">
                                    <div class="card activeCard">
                                        <div class="card-body">
                                            <div class="d-flex align-items-start justify-content-between">
                                            <h6 class="card-title flex-grow lower-line text-truncate">{{ $t('Incident.IncPartConRecommendations') }}</h6>                                        
                                            </div>							
                                            <div class="flex-container">
                                                <div class="row">                                     
                                                    <div class="col-md-12">
                                                        <div class="float-left col text-muted p-item">                                                          
                                                            <b v-if="item.observation === null"> {{ $t('Incident.IncNoRecomMsg') }}</b>                                                
                                                            <b>{{ item.observation }}</b>                                                     
                                                        </div>
                                                    </div>                               								
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>                                   
                            </div>                        
                        </div>                        
                    </div>                          
                </div>
            </div>

    <MdTitle v-if="this.IncidentItems.police && this.IncidentItems.police.isPoliceInvolved" v-bind:Text="$t('Incident.IncPolice')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
    <b-row v-if="this.IncidentItems.police && this.IncidentItems.police.isPoliceInvolved">
        <IncidentReportPoliceBox
            v-bind:Police="IncidentItems.police">
        </IncidentReportPoliceBox>
    </b-row>

    <MdTitle v-if="this.IncidentItems.cps && this.IncidentItems.cps.isCpsinvolved" v-bind:Text="$t('Incident.IncChild')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
    
    <b-row v-if="this.IncidentItems.cps && this.IncidentItems.cps.isCpsinvolved">
        <IncidentReportCPSBox
            v-bind:CPS="IncidentItems.cps">
        </IncidentReportCPSBox>
    </b-row>

    <MdTitle v-if="this.IncidentItems.header.ervisit" v-bind:Text="$t('Incident.IncEmeRoom')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
    <b-row v-if="this.IncidentItems.header.ervisit">
        <IncidentReportEmergencyBox
            v-bind:Header="IncidentItems.header">
        </IncidentReportEmergencyBox>
    </b-row>

    <MdPasswordDlg v-if="showSignPassword" 
        v-bind:Type="TypePassWord"
        v-bind:Operation="Operation"
        v-bind:VerificationType="'SupervisorPassword'"
        @getModalPassword="onModalPasswordResult($event)"
    />
    </div>
</div>
</template>
 
 <script>
 
 import Utils from '@/common/utils'
 import DBFunctions from '@/common/DBFunctions.js'
 import IncidentReport from '@/services/incidentreport.service'
 import ShowMessage from '@/components/messages/ShowMessage.js'
 import IncidentReportHeadersService from '@/services/incidentreportheaders.service'
 import RoomsService from '@/services/rooms.service'
 import { EventBus } from '@/event-bus.js';
 import moment from 'moment'

 export default {
    mixins: [ShowMessage, DBFunctions],  
    props: {
        IncidentId: String,
    },
    name: 'IncidentWorkflowSummary',
     data() {
       return { 
        icon:"",
        DisablePolice:true,
        DisableChildProtective:true,
        CategoryName:"",
        IncidentDateHeader:null,
        incidentTimeFormatted: null,
        LocationList: [],
        CategoriesItems: [],
        Version: 1,
        HeaderItems:{
            addlLocation:'',
            draftCompleteDate:null,
            draftCompleteUser:'',
            ervisit:'N',
            finalizeDate:null,
            finalizeUser:null,
            incidentDate:null,
            incidentId:'',
            incidentTime:null,
            isDraftComplete:'',
            isFinalize:null,
            isSigned:'N',
            isVoid:null,
            lastEditDateTime:null,
            location:'',
            reportedDate:null,
            reporter:'',
            reporterName:'',
            roomNo:'',
            severity:'',
            severityName:'',
            signedDate:null,
            sysUser:'',
            version:1,
            voidDate:null,
            voidUser:null,
            wasPoliceNotified:'N'
        },

        parameter:{
            SearchCriteria:'',
            PageNumber : 0,
            PageSize:0,
        },
        OptionsRightMenu:{
			visible:true,
			ItemsList:[],
			btnvisible:false,
			itemdisabled: false,
			PatientCode:''         
		},
        CategoryItems: {},
        ClientItems: [],
        EmployeeItems: [],
        ContactItems: [],
        VisitorItems: [],
        paramsParticipants:{
            SearchCriteria:'',
            PageNumber : 0,
            PageSize:0 ,
            EntityType: '',
            Version: this.$route.params.Version,
            IncidentId: this.IncidentID
        },
        IncidentItems: {
                header:{
                    reporter:this.$store.state.employeeCode,
                    incidentDate:null,                    
                    addlLocation:"",
                    incidentTime:null,
                    reportedDate:null,
                    roomNo:"",
                    location:"",                    
                },
                categories:[],
                participants:[],
                police:{
                    policeName:"",
                    badgeNumber:"",
                    reportNumber:"",
                    stationName:"",
                    phoneNumber:"",
                    arrestMade:false,
                    isPoliceInvolved:false,
                    comments:"",
                    WasPoliceNotified:false
                },
                cps:{
                    screenIn:false,
                    screenOut:false,
                    representativeName:"",
                    phoneNumber:"",
                    isCpsinvolved:false,
                    reportNumber:"",
                    comments:""
                },
            },
        RoomList: [],
        participantClientSearch: '',
        participantEmployeeSearch: '',
        participantContactSearch: '',
        participantVisitorSearch: '',
        header: {},
        isVoided: false,
        btnVoidIncidentLabel: 'VOID',
        showSignPassword: false,
        TypePassWord:'Sign',
        UserIdSelected: '',
        Operation: "",
        ModalId: 'IWFSummaryModal',
       }

     },
 
 
     methods:{

        emitEventOK() {      
        },

        emitEventHide() {
            EventBus.$emit('CloseIWFSummary');
        },

        getTitle() {
            let base = this.$t('IncidentWorkflowSummary.InWorkFloSumTitle')
            let dateheader = this.IncidentDateHeader ? ` - ${this.IncidentDateHeader}` : '' 
            let categoryname = this.CategoryName.length > 0 ? `- ${this.CategoryName}` : ''
            return `${base} ${dateheader} ${categoryname}`
        },

        getIncidentDate() {
            return this.IncidentItems.header.incidentDate
        },

        voidIncident() {
            this.showSignPassword = true;
            this.Operation = "Void";  
        },
        
        forceCompletion() {
            this.showSignPassword = true;
            this.Operation = "Completion";  
        },
        
        getOtherIntervention(value) {
            return Utils.HTML2PlainText(value)
        },
        
        searchCategories(arr, value = '') {
            if (arr && arr.length) {
                if (value == '') {
                    return arr;
                }
                value = value.toLowerCase()
                return arr.filter(e => (e.incidentDescription ? e.incidentDescription.toLowerCase().includes(value) : null) 
                                    || (e.categoryName ? e.categoryName.toLowerCase().includes(value) : null)
                                    || (e.intervention ? e.intervention.toLowerCase().includes(value) : null));
            }
            return null;
        },

        searchParticipant(arr, value = '') {
            if (arr && arr.length) {
                if (value == '') {
                    return arr;
                }
                value = value.toLowerCase()
                return arr.filter(e => (e.entityName ? e.entityName.toLowerCase().includes(value) : null) 
                                    || (e.participationDescription ? e.participationDescription.toLowerCase().includes(value) : null)
                                    || (e.personalStatement ? e.personalStatement.toLowerCase().includes(value) : null)
                                    || (e.statementDate ? e.statementDate.toLowerCase().includes(value) : null)
                                    || (e.participationType ? e.participationType.toLowerCase().includes(value) : null)
                                    || (e.observation ? e.observation.toLowerCase().includes(value) : null)
                                    || (e.programName ? e.programName.toLowerCase().includes(value) : null));
            }
            return null;
        },

        GetCategoryNameifPrimary(){
           for(var i = 0; i<this.CategoriesItems.length; i++){
             if(this.CategoriesItems[i].isPrimary == true){
                this.CategoryName = this.CategoriesItems[i].categoryName;
                break;
             }
           }
        },

        loadParticipants() {
            this.IncidentItems.participants.forEach(element => {
                element.statementDate = Utils.formatterDateToString(element.statementDate);
                if (element.entityType == 'P')
                    this.ClientItems.push(element)
                else if (element.entityType == 'C')
                    this.ContactItems.push(element)
                else if (element.entityType == 'E')
                    this.EmployeeItems.push(element)
                 else if (element.entityType == 'V')
                    this.VisitorItems.push(element)
            });
        },

        loadCategories() {
            this.IncidentItems.categories.forEach(element => {
                this.CategoriesItems.push(element)
            });
        },

        async getLocation() {
            await IncidentReport.getLocation()
                .then((response) => {
                if(response.status == '200') {  
                    this.LocationList = response.data;
                }                             
                })
                .catch((error) => {
                if (error.response) {
                    this.showMessage(this.$t('IncidentWorkflowSummary.Msgtitle'),error,"error");
                }
                });
        },

        async getIncidentReport() {            
            await IncidentReport.Get(this.IncidentId,this.Version)
                .then((response) => { 
                    if(response.data){
                        this.IncidentItems = response.data
                        this.IncidentItems.header.incidentDate = Utils.formatterDateToString(this.IncidentItems.header.incidentDate)
                        this.IncidentItems.header.reportedDate = Utils.formatterDateToString(this.IncidentItems.header.reportedDate)
                        this.IncidentItems.header.signedDate = Utils.formatterDate(this.IncidentItems.header.signedDate)
                        this.incidentTimeFormatted = Utils.formatTimeHm(this.IncidentItems.header.incidentTime)
                        this.loadParticipants();
                        this.loadCategories();
                    }                             
                })
                .catch((error) => {
                 
                    if (error.response) {
                        this.showMessage(this.$t('IncidentWorkflowSummary.Msgtitle'),error,"error");
                    }
                });
        },

        async getVersionNo() {            
            await IncidentReportHeadersService.getVersionNo(this.IncidentId)
                .then((response) => { 
                    if(response.data){
                        this.Version = response.data - 1
                    }                             
                })
                .catch((error) => {
                 
                    if (error.response) {
                        this.showMessage(this.$t('IncidentWorkflowSummary.Msgtitle'),error,"error");
                    }
                });
        },

        async ExecuteAllCalls(){
            this.getLocation();
            this.getRooms();
            await this.getVersionNo()            
            await this.getIncidentReport();
            EventBus.$emit("stopWheel");
        },

    async getRooms() { 
        await RoomsService.getAllRooms()
            .then((response) => {      
                if (response.data) {
                    this.RoomList = response.data;
                }  
            })
            .catch(error => {
                if(error.response.status === 400 ){
                    this.showMessage(this.$t('IncidentWorkflowSummary.Msgtitle'),error.response.data.errorMessage,"error");
                }else{
                this.showMessage(this.$t('IncidentWorkflowSummary.Msgtitle'),error,"error");
                }
            }); 
    },

    Close() {
        EventBus.$emit('showSummary',false);
        EventBus.$emit('showIWFSummary');
    }
},

 async mounted(){
    const allPromise = Promise.all([await this.ExecuteAllCalls()]);
    await allPromise.then(() => {
       this.$emit("load", false);
    }).catch(error => {       
        this.showMessage(this.$t('IncidentWorkflowSummary.Msgtitle'),error,"error");
    });

    this.GetCategoryNameifPrimary();
    this.IncidentDateHeader = moment(this.IncidentItems.header.incidentDate).format("ddd, ll");

    if(this.IncidentItems.police.isPoliceInvolved == false){
        this.DisablePolice = true;
    } else {
        this.DisablePolice = false;
    }

    if(this.IncidentItems.cps.isCpsinvolved == false){
        this.DisableChildProtective = true;
    } else {
        this.DisableChildProtective = false;
    }

    let layoutStatus = this.$store.state.layoutStatus;
    switch(layoutStatus) {
      case 'L0R1':
        this.OptionsRightMenu.favButtonAtion = true;
        this.$emit('getVisibleMenu', this.OptionsRightMenu);
      break;
      case 'L1R0':
      case 'L1R1':
          this.OptionsRightMenu.btnvisible = false;
          this.$emit('getVisibleMenu', this.OptionsRightMenu);
      break;
      default:
          this.OptionsRightMenu.favButtonAtion = true;
          this.$emit('getVisibleMenu', this.OptionsRightMenu);
    }
 },
    destroyed(){
        this.OptionsRightMenu.visible = false;
        this.OptionsRightMenu.btnIncidentWorkflowvisible = false; 
        this.$emit('onMenuRightBarIncidentWorkflow', this.OptionsRightMenu);
        EventBus.$off('ShowMdNote');
    },
  } 
 </script>

<style>
  #IWFSummaryModal___BV_modal_body_ {
    overflow-x: hidden;
    padding: 5px;
  }

  #ContainerSummary{
    overflow-x: hidden;
  }
</style>
 
 