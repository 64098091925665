<template>
    <div id="ContainerAppoinmentType">
           <h4 class="page-title text-truncate">
                {{$t('Shared.AppointmentType') }}       
            </h4>
            <b-row>
              <b-col lg="3" md="3" sm="12">
                <MdEdit
                  v-model="AppoinmentType.code"                    
                  v-bind:Label="$t('Shared.Code')"  
                  v-bind:isRequired="false"
                  v-bind:MaxLengthProp="10"
                  v-bind:Id="'input_a_code'"/>
              </b-col>
              <b-col lg="3" md="3" sm="12">
                <MdEdit
                  v-model="AppoinmentType.descrition"                    
                  v-bind:Label="$t('Shared.AppointmentType')"  
                  v-bind:isRequired="false"
                  v-bind:MaxLengthProp="35"
                  v-bind:Id="'input_a_desc'"/>
              </b-col>
              <b-col lg="3" md="3" sm="12">
                <MdEdit
                v-bind:TypeProp="'number'"
                  v-model.number="AppoinmentType.duration"                    
                  v-bind:Label="$t('Shared.AppointmentType')"  
                  v-bind:isRequired="false"
                  v-bind:MaxLengthProp="35"/>
              </b-col>
              <b-col lg="3" md="3" sm="12">
                <MdLookup
                v-bind:Label="$t('insuranceSetup.POnList.h')"
                v-bind:ModelCode="AppoinmentType.tcategory"
                v-bind:ModelDescription="dtherapycategory"
                v-bind:RefCode="'Users'"
                v-bind:Api="'Users'"
                v-bind:FieldCode="'code'"
                v-bind:FieldDescription="'name'"                              
                :SearchTitle="$t('menu.Users')"
                :GetValues="onCodeConditionOk"
                :InvalidFeedback="$t('Msg.InvalidUCode')"
                :SearchFieldCode="'Code'" />
            </b-col>                               
            </b-row>

            <b-row>
              <b-col lg="3" md="3" sm="12">
                <MdLookup
                v-bind:Label="$t('appoType.dTherapy')"
                v-bind:ModelCode="AppoinmentType.defaultTherapy"
                v-bind:ModelDescription="ddefaultTherapy"
                v-bind:RefCode="'Programs'"
                v-bind:Api="'Programs'"
                v-bind:FieldCode="'code'"
                v-bind:FieldDescription="'name'"                              
                :SearchTitle="$tc('Shared.Program',2)"
                :GetValues="onCodeConditionOk"
                :InvalidFeedback="$t('Msg.InvalidUCode')"
                :SearchFieldCode="'Code'" />
              </b-col>
              <b-col lg="3" md="3" sm="12">
                <MdCheckBox
                  v-bind:Label="$t('appoType.sShowing')"
                  v-bind:Name="'checkbox-StopShowing'"
                  v-bind:ClassProp="'AlignCheck'"
                  v-bind:CheckedValue="true"
                  v-bind:UncheckedValue="false"
                  v-model="AppoinmentType.a"/>
              </b-col>
              <b-col lg="3" md="3" sm="12">
                <MdCheckBox
                  v-bind:Label="$t('appoType.pScreening')"
                  v-bind:Name="'checkbox-Screening'"
                  v-bind:ClassProp="'AlignCheck'"
                  v-bind:CheckedValue="true"
                  v-bind:UncheckedValue="false"
                  v-model="AppoinmentType.b"/>
              </b-col>
              <b-col lg="3" md="3" sm="12">
                <MdCheckBox
                  v-bind:Label="$t('appoType.eCheckNo')"
                  v-bind:Name="'checkbox-EnableCheckInNotifications'"
                  v-bind:ClassProp="'AlignCheck'"
                  v-bind:CheckedValue="true"
                  v-bind:UncheckedValue="false"
                  v-model="AppoinmentType.c"/>
              </b-col>
            </b-row>

            <b-row>
              <b-col lg="3" md="3" sm="12">
                <MdCheckBox
                  v-bind:Label="$t('appoType.cBiAppt')"
                  v-bind:Name="'checkbox-CreateBillableEntriesFromCheckedInAppt'"
                  v-bind:ClassProp="'AlignCheck'"
                  v-bind:CheckedValue="true"
                  v-bind:UncheckedValue="false"
                  v-model="AppoinmentType.d"/>
              </b-col>
              <b-col lg="3" md="3" sm="12">
                <MdCheckBox
                v-bind:Label="$t('appoType.iTelehealth')"
                v-bind:Name="'checkbox-IsTelehealth'"
                v-bind:ClassProp="'AlignCheck'"
                v-bind:CheckedValue="true"
                v-bind:UncheckedValue="false"
                v-model="AppoinmentType.f"/>
              </b-col>
            </b-row>
            <MdTitle v-bind:Text="$t('appoType.csAppointments')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
            <b-row>
              <b-col lg="3" md="3" sm="12">
                <MdColor v-bind:Title="$t('appoType.cAppointments')" v-model="ColorCheckedinAppointments"/>                
             </b-col> 
             <b-col lg="3" md="3" sm="12">
              <MdColor v-bind:Title="$t('appoType.nAppointments')" v-model="ColorNewAppointments"/>
           </b-col>     
           <b-col lg="3" md="3" sm="12">
            <MdColor v-bind:Title="$t('appoType.mAppointments')" v-model="ColorMissedinAppointments"/>          
         </b-col>    
         <b-col lg="3" md="3" sm="12">
          <MdColor v-bind:Title="$t('appoType.coAppointments')" v-model="ColorConfirmedAppointments"/>
       </b-col>      
            </b-row>   
    
        <div class="page-actions">      
         <MdButton
                   v-bind:Label="$t('appoType.atLIST')"
                   v-bind:Name="'btnbtn_save_AppoinmentTypeList'"
                   v-bind:ClassProp="'secondary mx-2 btn-login'"
                   v-on:click="DiscardChanges"/>    
            <MdButton
                v-bind:Label="$t('Shared.Save')"
                v-bind:ClassProp="(this.isReadOnlyAppoinmentType)?'primary mx-2':'primary btn-login mx-2'"
                v-on:click="SaveOrEdit"
                v-bind:isReadOnly="isReadOnlyAppoinmentType"
                v-bind:Name="'btn_save_AppoinmentType'"/>
        </div>
    
    </div>
</template>
    
<script>
    
    
    import DBFunctions from '@/common/DBFunctions.js';
    import ShowMessage from '@/components/messages/ShowMessage.js'
    import AppoinmentTypeService from '@/services/appointmenttypes.service'
    import { EventBus } from '../../../../../event-bus.js';
    //import Utils from '@/common/utils'
    
    let elemSaveBtn = '';
    export default {
      name: 'AppoinmentType',
     mixins: [ShowMessage, DBFunctions/*,EventBus*/],
      data(){
        return{
          ColorCheckedinAppointments:"#000000",
          ColorNewAppointments:"#000000",
          ColorMissedinAppointments:"#000000",
          ColorConfirmedAppointments:"#000000",
            parameter:{
                SearchCriteria:'',
                PageNumber : 1,
                PageSize:0,         
            },
            dtherapycategory:"",
            ddefaultTherapy:"",
            AppoinmentType:{
            code:"",
            descrition:"",  
            duration:5,
            tcategory:"", 
            defaultTherapy:"",           
           },
           pendingChanges:false,
           isReadOnlyAppoinmentType:false,
          isCodeValid:true,
            OptionsRightMenu:{
              visible:false,
              ItemsList:[],
              btnvisible:false,
              itemdisabled: false,
              PatientCode:'',
              Mode:this.edit_mode,
              favButtonAtion:false          
            },
    
        }
      },
    
      methods:{
    
        DiscardChanges(){
           this.checkExistFavorite('/app/appointmenttypes');
           this.$router.push({ name: "appointmenttypesList"});
        },

        onCodeConditionOk(){

        },
    
        AddAppoinmentType(){
          this.$emit('load', true);
          AppoinmentTypeService.Add(this.PlaceOfService)
                    .then((response) => {
                        if(response.status == '200'){
                            this.EmployeeType = response.data; 
                            this.pendingChanges = false;   
                            this.$emit('load', false);
                            /*if(!this.OffRoute){
                               this.GotoAdmissionList();
                            }*/
                            this.$router.push({name: "appointmenttypesList"}).catch(()=>{});
                          
                            setTimeout(() => 
                            this.$bvToast.toast(this.$t('Msg.SavedSuccessfully'), {
                             title: this.$t('Msg.Saving'),
                              autoHideDelay: 5000,
                              variant: "success"
                            })
                            , 50); 
                        }                             
                    })				
                    .catch((error) => {   
                        if (!error.response) {
                       this.showMessage(this.$t('Shared.AppointmentType'),this.$t('Msg.NotAbleToInsert'),"error");
                          }                      
                         else if(error.response.status === 400 ){
                            this.showMessage(this.$t('Shared.AppointmentType'),error.response.data.errorMessage,"error");
                         } else if(error.response.status === 500){
                            this.showMessage(this.$t('Shared.AppointmentType'),this.$t('Msg.ThereWereSomeError'),"error");
                         } else{
                             this.showMessage(this.$t('Shared.AppointmentType'),error.response.data.errorMessage,"error");
                         }
                        elemSaveBtn.removeAttribute("disabled");  
                        this.$emit('load', false);
                    }); 
        },
    
    
        EditAppoinmentType(){
          this.$emit('load', true);
          AppoinmentTypeService.Update(this.$route.params.id,this.EmployeeType)
                    .then((response) => {
                        if(response.status == '200'){
                            this.EmployeeType = response.data; 
                            this.pendingChanges = false;   
                            this.$emit('load', false);
                            /*if(!this.OffRoute){
                               this.GotoAdmissionList();
                            }*/
                            this.$router.push({name: "appointmenttypesList"}).catch(()=>{});
                          
                            setTimeout(() => 
                            this.$bvToast.toast(this.$t('Msg.SavedSuccessfully'), {
                             title: this.$t('Msg.Saving'),
                              autoHideDelay: 5000,
                              variant: "success"
                            })
                            , 50); 
                        }                             
                    })
                    .catch((error) => {   
                        if (!error.response) {
                       this.showMessage(this.$t('Shared.AppointmentType'),this.$t('Msg.NotAbleToInsert'),"error");
                          }                      
                         else if(error.response.status === 400 ){
                            this.showMessage(this.$t('Shared.AppointmentType'),error.response.data.errorMessage,"error");
                         } else if(error.response.status === 500){
                            this.showMessage(this.$t('Shared.AppointmentType'),this.$t('Msg.ThereWereSomeError'),"error");
                         } else{
                             this.showMessage(this.$t('Shared.AppointmentType'),error.response.data.errorMessage,"error");
                         }
                         elemSaveBtn.removeAttribute("disabled");  
                        this.$emit('load', false);
                    }); 
        },
    
      getAppoinmentType(){    
          if(this.$attrs.editMode){           
          this.$emit('load', true);     
          AppoinmentTypeService.Get(this.$route.params.id)
            .then((response) => {
                if(response.status == '200' && response.data){
                    /*if(response.data.showInNotes != null && response.data.showInNotes != "" && response.data.showInNotes.length > 0){
                        if(response.data.showInNotes == "Y"){
                            ShNotes = true;
                        }
                    }*/
                  this.EmployeeType = response.data;  
                  //this.EmployeeType.showInNotes = ShNotes;
                }   
                  this.$emit('load', false);                     
            })
            .catch((error) => {                    
                if (!error.response) {
                  this.showMessage(this.$t('Shared.AppointmentType'),this.$t('Msg.NotAbleToSave'),"error");
                }                      
                else if(error.response.status === 400 ){
                  this.showMessage(this.$t('Shared.AppointmentType'),error.response.data.errorMessage,"error");
                }
                else if(error.response.status === 405 ){             
                      this.$router.push({name: "Main"});    
                        setTimeout(() => 
                            this.showPermissionMessage()
                            , 50);            
                } 
                else if(error.response.status === 500){
                  this.showMessage(this.$t('Shared.AppointmentType'),this.$t('Msg.ThereWereSomeError'),"error");
                } else{
                  this.showMessage(this.$t('Shared.AppointmentType'),error.response.data.errorMessage,"error");
                } 
                this.$emit('load', false);            
            }); 
        
          }
        },
    
          DoAction(){     
            !this.$attrs.editMode ? this.AddAppoinmentType() : this.EditAppoinmentType(); 
          },
    
          SaveOrEdit(){ 
             if (this.validate_required()) {
                this.DoAction();
             } else {
                this.showMessage(this.$t('Shared.AppointmentType'), this.$t('Msg.CheckRedLabeledFields'), "errorAutoHide");          
             }
    
          },
    
          validate_required() {
            this.isCodeValid = this.EmployeeType.code.length > 0 ? true : false;   
            return this.isCodeValid;
          },
           checkUserAction() {
            if (elemSaveBtn.disabled) {
              elemSaveBtn.removeAttribute('disabled');
            }
          }
      },
    
      created(){
        EventBus.$on("onChanged", function () {       
              this.checkUserAction();
        }.bind(this));
      },
    
      mounted(){
              this.OptionsRightMenu.ItemsList = [];
           let layoutStatus = this.$store.state.layoutStatus;
           switch(layoutStatus) {
            case 'L0R1':
              this.OptionsRightMenu.favButtonAtion = false;
              this.$emit('getVisibleMenu', this.OptionsRightMenu);
            break;
            case 'L1R0':
            case 'L1R1':
                this.OptionsRightMenu.btnvisible = false;
                this.$emit('getVisibleMenu', this.OptionsRightMenu);
            break;
            default:
                this.OptionsRightMenu.favButtonAtion = false;
                this.$emit('getVisibleMenu', this.OptionsRightMenu);
          }
          this.getAppoinmentType();
          setTimeout( function() {
            elemSaveBtn = document.getElementById('btn_save_AppoinmentType');
            elemSaveBtn.setAttribute('disabled','');
          }, 50, this );  
      },
    
        destroyed(){
          this.OptionsRightMenu.ItemsList = [];
          this.OptionsRightMenu.Mode = false;
          this.OptionsRightMenu.visible = false; 
          this.$emit('getVisibleMenu', this.OptionsRightMenu);
        }
       
    };
    </script>
    
    
    