<template>
   <div>
    <b-modal :id="ModalId" :visible="true" no-close-on-backdrop no-close-on-esc size="md" :title="TitleWindow" ref="modalparticipantemployee" @close="this.emitEventCancel" @ok="this.emitEventOK" @cancel="this.emitEventCancel"> 
      <b-row>
        <b-col md="6" lg="6" sm="12">
          <MdSelect
            v-bind:Label="$t('Incident.IncPartType')"
            v-bind:Options="ParticipantList"
            v-bind:FieldDescription="'description'"
            v-bind:FieldCode="'code'"
            v-bind:Model="modelParticipantType"                       
            v-bind:Multiple="false"
            :Input="ChangeParticipantType"
            v-bind:isRequired="true" 
            v-bind:isValid="isParticipantTypeValid"/>
        </b-col>
        <b-col md="6" lg="6" sm="12">
          <MdEdit 
            v-bind:Label="$t('Incident.IncPartStatementDate')"
            v-bind:TypeProp="'date'" 
            v-bind:Name="'date'"
            v-model="employeeItems.statementDate"
            v-bind:Min="'1900-01-01'"
            v-bind:Max="'2200-12-31'"
            v-bind:isRequired="true" 
            v-bind:isValid="isStatementDateValid">                                     
          </MdEdit>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12" md="12" sm="12">         
          <MdLookup 
            v-bind:ModelCode="employeeItems.entityCode"
            v-bind:ModelDescription="employeeItems.entityName"
            v-bind:Label="$tc('Shared.Employee')"
            v-bind:RefCode="'code'"   
            v-bind:Fields="FieldsEmployees"   
            v-bind:Api="'Employees/?Active=true'"
            v-bind:FieldCode="'code'"
            v-bind:FieldDescription="'name'"
            v-bind:isRequired="true"
            :SearchTitle="$tc('Shared.Employee',2)"
            :GetValues="getValues"
            :InvalidFeedback="$t('Incident.IncPartInvEmplMsg')"
            :SearchFieldCode="'code'"
            :AuxFields="AuxFields"
            :isValid="isValidEmployee"/>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6" lg="6" sm="12">
          <MdCheckBox
            v-bind:Label="$t('Incident.IncPartArrest')"
            v-bind:Name="'PoliceArrest3'"
            v-bind:ClassProp="'AlignCheck'"
            v-bind:CheckedValue="true"
            v-bind:UncheckedValue="false"
            v-model="employeeItems.arrestMade"
            v-on:change="ChangePoliceArrest"/>
        </b-col>
        <b-col md="6" lg="6" sm="12">
          <MdCheckBox
            v-bind:Label="$t('Incident.IncPartVisit')"
            v-bind:Name="'VisittoER3'"
            v-bind:ClassProp="'AlignCheck'"
            v-bind:CheckedValue="true"
            v-bind:UncheckedValue="false"
            v-model="employeeItems.ervisit"
            v-on:change="ChangeVisittoER"/>
        </b-col>
      </b-row>
      <MdTitle v-bind:Text="$t('Incident.IncPartPersonalSta')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
      <b-row>
        <b-col md="12" lg="12" sm="12">
          <MdMemo
            v-bind:Name="'textarea_personalstatement'"
            v-bind:Rows="'8'"
            v-bind:MaxRows="'6'"
            v-bind:isRequired="true"
            v-bind:isValid="isPersonalStatementValid"
            v-model="employeeItems.personalStatement"/>
        </b-col>
      </b-row>
      <MdTitle v-bind:Text="$t('Incident.IncPartConRecommendations')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
      <b-row>
        <b-col md="12" lg="12" sm="12">
          <MdMemo
            v-bind:Name="'textarea_resourcecomments'"
            v-bind:Rows="'8'"
            v-bind:MaxRows="'6'"
            v-model="employeeItems.observation"/>
        </b-col>
      </b-row>
    </b-modal>   
   </div>
</template>

<script>
import ShowMessage from '@/components/messages/ShowMessage.js'
import IncidentReport from '@/services/incidentreport.service'
import GlobalService from '@/services/global.service'
import Utils from '@/common/utils'

export default {
  name: 'ModalParticipantEmployee',
    mixins: [ShowMessage],  
   props: {
     ModalId: String,
     TitleWindow: String,
     IncidentID: String,    
     ModeEdit: Boolean,
     EmployeeItems: Object, 
     minObsevationStatement:Number,
     minPersonalStatement:Number
   },
   data(){
       return {
        ParticipantList: [],
        participantType: '',
        personalstatementdate: null,
        modelParticipantType:{code:"",description:""},
        personalstatementdatenotes: '',
        resourcecomments: '',
        FieldsEmployees:[{ key: 'name', sortable: true, label: this.$t('Incident.IncPartEmplName')},{ key: 'employeeType', sortable: true, label: this.$t('Incident.IncPartEmplType')}, { key: 'code', sortable: true, label: this.$t('Shared.Code') }],
        AuxFields:['name'],
        EmployeeCode: '',
        EmployeeDescription: '',
        employeeItems: {
          arrestMade: false,
          ervisit: false,
          observation: '',
          participationType: '',
          entityName: '',
          personalStatement: '',
          statementDate: null,
          entityCode: '',
          entityType: '',
          incidentId: '',
          id: 0,
          recordId: ''
        },
        isParticipantTypeValid: true,
        isStatementDateValid: true,
        isValidEmployee: true,
        isPersonalStatementValid: true,
        APIDateTime: null
       }
   },

   methods:{

      Message(min,text1,text2){
        return text1+" "+min+" "+text2; 
      },
      
      validateFields() {
        let personalStatement = this.employeeItems.personalStatement ? this.employeeItems.personalStatement.replace(/<[^>]*>?/g, '') : "";
        let observation = this.employeeItems.observation ? this.employeeItems.observation.replace(/<[^>]*>?/g, '') : "";
        this.isParticipantTypeValid =  this.employeeItems.participationType != '' && this.employeeItems.participationType != undefined ? true : false
        this.isStatementDateValid = Utils.ValidateDate(this.employeeItems.statementDate) ? true : false
        this.isValidEmployee = this.employeeItems.entityCode ? true : false
        this.isPersonalStatementValid = this.employeeItems.personalStatement && this.employeeItems.personalStatement.trim() != ''? true : false

        if (this.isPersonalStatementValid == false)
          this.showMessage(this.$t('Incident.IncReportTitle'),this.$t('Incident.IncPartProvidPerStaMsg'),"errorAutoHide");

        let validpersonalStatement = false;
        let validobservation = false;
      
      if(this.minPersonalStatement !== null && this.minPersonalStatement !== ""){
        validpersonalStatement = personalStatement.length >= this.minPersonalStatement;
      }else{
        validpersonalStatement = true;
      }

      if(this.minObsevationStatement !== null && this.minObsevationStatement !== ""){
        validobservation = observation.length >= this.minObsevationStatement;
      }else{
        validobservation = true;
      }

      if(validpersonalStatement == false){        
        this.showMessage(this.$t('Incident.IncReportTitle'),this.Message(this.minPersonalStatement,this.$t('Incident.IncPartProvidPerStaC1Msg'),this.$t('Incident.IncPartProvidPerStaC2Msg')) ,"errorAutoHide");
      }

      if(validobservation == false){        
        this.showMessage(this.$t('Incident.IncReportTitle'),this.Message(this.minObsevationStatement,this.$t('Incident.IncPartProvidRecoC1Msg'),this.$t('Incident.IncPartProvidRecoC2Msg')) ,"errorAutoHide");
      }


        return this.isParticipantTypeValid && this.isStatementDateValid && this.isValidEmployee && this.isPersonalStatementValid && validpersonalStatement && validobservation
      },
      
      ChangePoliceArrest() {
      },

      ChangeVisittoER() {
      },

      ChangeParticipantType(value) {
        this.modelParticipantType = value !== null ? value : {code:'',description:''};
        this.isParticipantTypeValid = value && value.code ? true : false; 
        this.employeeItems.participationDescription = value ? value.description : null;
        this.employeeItems.participationType = value ? value.code : null
      },
      
      emitEventOK(bvModalEvent){
        if (this.validateFields()) {        
          if (this.ModeEdit == false) {
              this.employeeItems.entityType = 'E'
              this.employeeItems.id = Utils.generateRecID()
            }        
            this.$emit('getCodeConditionOK',this.employeeItems);
        } else {
          bvModalEvent.preventDefault()
        }
      },
      
      emitEventCancel(){
        this.$emit('getCodeConditionCANCEL');
      }, 

      getParticipationTypeEmployees() {
        IncidentReport.getParticipationTypeEmployees()
          .then((response) => {
            if(response.status == '200') {  
              this.ParticipantList = response.data;
            }                             
          })
          .catch((error) => {
            if (error.response) {
              this.showMessage(this.$t('Incident.IncPartEmplTypeMsg'),error,"error");
            }
          });
      },

      getValues: function (value) {
        if(value.code) {
          this.employeeItems.entityName = value.description
          this.employeeItems.entityCode = value.code
        } else {
          this.employeeItems.entityName = ''
        }
      },

       getDateTime() { 
         GlobalService.getDateTime()
            .then((response) => {      
               if (response.data) {
                this.APIDateTime = Utils.formatterDateTimeToString(response.data);
                this.employeeItems.statementDate = Utils.formatterDate(response.data)
               }  
            })
            .catch(error => {
             if(error.response.status === 400 ){
                 this.showMessage(this.$t('Incident.IncReportTitle'),error.response.data.errorMessage,"error");
              }else{
                this.showMessage(this.$t('Incident.IncReportTitle'),error,"error");
              }
            });   
      },
   },

  mounted(){ 
    this.$refs.modalparticipantemployee.$on("hidden.bs.modal");
    this.getParticipationTypeEmployees()
    if (this.ModeEdit) {
      let employeeItems = {...this.EmployeeItems}
      this.employeeItems.arrestMade = employeeItems.arrestMade
      this.employeeItems.ervisit = employeeItems.ervisit
      this.employeeItems.observation = employeeItems.observation
      this.employeeItems.participationType = employeeItems.participationType
      this.employeeItems.entityName = employeeItems.entityName
      this.employeeItems.personalStatement = employeeItems.personalStatement
      this.employeeItems.participationDescription = employeeItems.participationDescription
      this.employeeItems.statementDate = Utils.formatterDate(employeeItems.statementDate)
      this.employeeItems.entityCode = employeeItems.entityCode
      this.employeeItems.entityType = employeeItems.entityType
      this.employeeItems.incidentId = employeeItems.incidentId
      this.employeeItems.id = employeeItems.id
      this.employeeItems.recordId = employeeItems.recordId
      this.modelParticipantType = {code:this.employeeItems.participationType,description:employeeItems.participationDescription}
    }else{
      this.getDateTime();  
    }
  }
};
</script>