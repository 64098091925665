import ApiService from '@/services/api.service'


const IncidentReportSeveritySetupService = {
    getAll( parameter) { 
         
      const requestData = {
        method: 'get',
        url: "/IncidentReportSeveritySetups/",
        headers: {
        },
        params: { 
          SearchCriteria: parameter.SearchCriteria,
          PageSize: parameter.PageSize,          
          PageNumber : parameter.PageNumber,
          Code : parameter.Code,
         }
      }     
      return ApiService.customRequest(requestData);         
    },

    getSeverityAll() {
      return ApiService.get(`/IncidentReportSeveritySetups`);
    },

  }

  
  
  export default IncidentReportSeveritySetupService;