<template>
    <div id="ContainerPatient">
      <div class="row">
          <div class="col-12">
            <MdTitle v-bind:Text="$t('dcsList.dpOfChildrenD')"/>
            <div class="row">
                <div class="col-sm-12 col-md-3">                      
                    <MdButton
                      v-bind:Label="$t('dcsList.addexport')"
                      v-bind:ClassProp="false ? 'btn btn-primary my-3 btn-block' : 'btn btn-primary my-3 btn-block btn-login'"
                      v-on:click="GetAddDCS"
                      v-bind:IconClass="'ti-plus pr-1'"
                      v-bind:isReadOnly="false"
                      v-bind:Name="'btn_add_dcs'">
                    </MdButton>
                </div>
                <div class="col-sm-12 col-md-9 mb-4">
                    <div class="textlabel" :data-label="this.$t('Shared.SearchElements')">
                        <div class="input-group">
                            <input 
                              type="text" 
                              class="form-control"
                              v-model="parameter.SearchCriteria"
                              @keyup.enter="SearchDCS()"
                            >
                            <div class="input-group-append">
                                <MdButton 
                                  v-bind:Name="'btnsearchinput'"
                                  v-bind:VariantProp="'btn btn-sm search-attached'"
                                  v-bind:IconClass="'mdi mdi-magnify'"
                                  v-on:click="SearchDCS">
                                </MdButton>
                            </div>
                        </div>
                    </div>
                </div>    
            </div>
            <b-toast id="error-toast" title="Error" static no-auto-hide>
              {{ error_toast }}  
            </b-toast>
          </div>
      </div>
      <div class="row">
          <div class="col-12">
              <div>
                <MdTitle v-bind:Text="$t('dcsList.list')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/> 
                        <MdTable 
                        v-bind:fields="fields"
                        v-bind:items="items"
                        v-bind:totalRows="totalRows"
                        v-bind:showMessageIsEmpty="ShowMessageIsEmpty"
                        v-bind:currentPage="currentPage"
                        v-bind:perPage="perPage"
                        v-bind:pageOptions="pageOptions"
                        v-bind:refTable="'tablePatientdcs'"                      
                        v-bind:VisibleDownloadIcon="true"
                        v-bind:VisibleViewIcon="true"
                        v-bind:VisibleDeleteIcon="true"                      
                        :downloadIconEvent="DownloadIcon"            
                        :viewIconEvent="ViewIcon"
                        :deleteIconEvent="DeleteIcon"           
                        :onRowSelected="onRowSelected"
                        :onFiltered="onFiltered"
                        :pageChanged="pageChanged"
                        :perPageFunction="PerPageFunction">
                      </MdTable>                    
              </div>
          </div>
      </div>
      <DxPoPupTable v-if="isVisible" 
              :Title="$tc('DCS Review',2)"
              :closeWindow="closeRepWindow"
              :isVisible="isVisible"
              :items = "spreadsheet"
      />
      <b-modal ref="delete-modal" size="lg" hide-footer title="Delete DCS">
        <div class="d-block text-center">
          <h4>This action will delete the report permanently from the database and reset all the claims. Continue deleting?</h4>
          <h5>Report ID: {{ selectedRow.groupCreationID }}</h5>
          <h5>Version: {{ selectedRow.version }}</h5>
        </div>
        <b-button class="mt-3" variant="outline-danger" block @click="confirmPassword">DELETE</b-button>
        <b-button class="mt-2" variant="outline-warning" block @click="hideModal">CANCEL</b-button>
      </b-modal>

      <MdPasswordDlg v-if="showSignPassword" 
        v-bind:Type="TypePassWord"
        v-bind:VerificationType="'SupervisorPassword'"
        @getModalPassword="onModalPasswordResult($event)"
      />
    </div>
</template>
  
  <script>
  import EnumService from '@/services/enum.service' 
  import ShowMessage from '@/components/messages/ShowMessage.js'
  import DBFunctions from '@/common/DBFunctions.js'
  import DCSService from '@/services/dcs.service'
  import { EventBus } from '@/event-bus.js';
  
  export default {
    beforeRouteEnter(to, from, next) {
          next(async vm => {
              const iPI = await vm.CanView();
              if(iPI) next()
              else next('/accessdenied');
          }) 
    },
    mixins: [ShowMessage, DBFunctions],
    name: 'DcsList',
    components: {
     // Patient
    },
    data() {
      return { 
        search_criteria: "",
        error_toast: "",
        ShowMessageIsEmpty:false,
        Menu : this.$store.state.leftMenu,
        permission: [],
        Loading:true,
        Mode:'',  
        FieldsDateArr:['dob'],
        status:{
          selected:true,
          value:true,
          text:this.$t('Shared.Active')
        },    
        parameter:{
          SearchCriteria:'',
          PageNumber : 0,
          PageSize:0,
          Status: true,       
        },
        OptionsRightMenu:{
              visible:true,
              ItemsList:[],
              btnvisible:false,
              itemdisabled: false,
              PatientCode:'',
              Mode:this.edit_mode,
              favButtonAtion:false          
          },
       
        fields: [
          { key: 'year', sortable: true, label: 'Year', class: 'text-rigth' },  
          { key: 'month', sortable: true, label: 'Month', class: 'text-rigth' },
          { key: 'version', sortable: true, label: 'Version', class: 'text-rigth' },
          { key: 'totalCharges', sortable: true, label: 'Total Charges', class: 'text-rigth' },  
          { key: 'noTransactions', sortable: true, label: 'Transactions', class: 'text-rigth' },
          { key: 'groupCreationID', sortable: true, label: 'Group Creation ID', class: 'text-rigth' }, 
          ],
  
        pageOptions: [5, 10, 15 ],
        totalRows: 4,
        currentPage: 1,
        perPage: 10,
        filter: null,
        filterOn: [],
        items: [],
        selected: [],
        Header:{},
        isReadOnly: false,
        refTable: 'selectableTable',
        selectedRow: '',
        isVisible: false,
        spreadsheet: [],
        UserIdSelected: '',
        showSignPassword: false,
        TypePassWord:'Sign'
      }
    },
  
    computed: {
        sortOptions() {
          // Create an options list from our fields
          return this.fields
            .filter(f => f.sortable)
            .map(f => {
              return { text: f.label, value: f.key }
            });
        }
    },
      
    methods: {
  
      pageChanged (page) {            
        this.currentPage = page;
        //this.getPatients();
      },
  
      CanView(){
       // return this.permission[0].roleCanView;
       return true;
    },
  
      PerPageFunction(perpage){
        this.perPage = perpage;
        this.currentPage = 1;
       // this.getPatients();
      },
      async getDCSList(){
        this.parameter.PageSize = this.perPage;
        this.parameter.PageNumber = this.currentPage;
        this.$emit("load", true)
        await DCSService.listing(this.parameter).then((response) => {
          this.items = response.data;
          if(response.status == '200' && response.data && response.data.length > 0){
            this.$emit('load', false);
            let headerString = response.headers["x-pagination"];
            this.Header = JSON.parse(headerString);
            this.totalRows = this.Header.TotalCount;
          }
          if(this.items.length === 0){
            this.$emit('load', false);
            this.ShowMessageIsEmpty = true;
          } 
        }).catch(error => {
          this.$emit('load', false);
          if (!error.response) {
              // network error
              this.showMessage(this.$tc('DCS List',2),this.$t('Msg.retrieveInfo'),"error");
          } else {
              this.showMessage(this.$tc('DCS List',2),error,"error");
          }
        })
      },

      focusInput() {
        this.$refs.SearchDCS.focus();
      },

      hideModal() {
        this.$refs['delete-modal'].hide()
      },

      onModalPasswordResult(result){      
          if(result.Event === 'Ok'){   
            if(result.status === true) {
            this.$emit('load', true);  
            if(this.selectedRow){
              this.DeleteDCS(this.selectedRow)
                    .then(() => {
                      this.$emit('load', false);  
                    })
                    .catch((error) => {
                      this.$emit('load', false);  
                        if(error.response.status === 400 ){
                        this.showMessage(this.$t('DCSListing.DeleteDocument'),error.response.data.errorMessage,"error");
                        }else{
                            this.showMessage(this.$t('DCSListing.DeleteDocument'),error,"error");
                        }               
                    });
            }
          }else{
            this.showMessage(this.$t('DCSListing.DeleteDocument'),this.$t('Msg.InvalidSupervisorPass'),"error");
          }
        }
        this.showSignPassword = false;
    },

      closeRepWindow(){
        this.isVisible = false
      },

      ViewIcon(row) {
        this.generateTable(row.xcelReport)
        this.isVisible = true
      },

      generateTable(content){
          content = content.replace(/(['"])/g, "")
          let headerElements = content.slice(0, content.indexOf('\r')).split(",")
          let generalRows = content.slice(content.indexOf('\n') + 1, content.length - 2).split('\r\n')
          let rowElements = []
          generalRows.forEach(element => {
            let subElement = element.split(',')
            rowElements.push(subElement)
          });
          let rows = []
          for(var y = 0; y < rowElements.length; y++){
            let thing = {};
            for(var i = 0; i < headerElements.length; i++){
              thing[headerElements[i]] = rowElements[y][i];
            }
            rows.push(thing)
          }
          this.spreadsheet = rows
      },

      DownloadIcon(row){  
        this.generateTable(row.xcelReport)
        this.DownloadDCS(row)
    //  this.getDocument(row);
      },

      jsonToCsv(jsonData) {
          let csv = '';
          // Get the headers
          let headers = Object.keys(jsonData[0]);
          csv += headers.join(',') + '\n';
          // Add the data
          jsonData.forEach(function (row) {
              let data = headers.map(header => JSON.stringify(row[header])).join(','); // Add JSON.stringify statement
              csv += data + '\n';
          });
          csv = csv.replace(/\\"/g, "")
          return csv;
      },

      DownloadDCS(row) {
        try{
          let csv = this.jsonToCsv(this.spreadsheet)
          const dataUri = window.URL.createObjectURL(
            new Blob( [csv], { type: "text/csv" } )
          )
          //window.open(dataUri)
          const anchor = document.createElement('a');
          anchor.href = dataUri
          anchor.target = '_blank';
          anchor.download = 'DCS' + row.month + row.year + 'ver' + row.version + '.csv';
          anchor.click();
        }catch(error) {
          this.$emit('load', false);
          console.log(error)
          if (!error.response) {
              // network error
              this.showMessage(this.$tc('DCS Download',2),this.$t('Download failed'),"error");
          } else {
              this.showMessage(this.$tc('DCS Download',2),error,"error");
          }
        }
      },

      DeleteIcon(row){
        this.selectedRow = row
        this.$refs['delete-modal'].show()
      },

      confirmPassword(){
        this.showSignPassword = true
        this.$refs['delete-modal'].hide()
      },

      async DeleteDCS(row){
        this.$emit('load', true)
        let params = {
          "month": row.month,
          "year": row.year,
          "version": row.version
        }
        await DCSService.dcsDelete(params).then(() => {
          this.getDCSList()
          this.$emit('load', false)
          this.$refs['delete-modal'].hide()
        }).catch(error => {
          this.$emit('load', false);
          if (!error.response) {
              // network error
              this.showMessage(this.$tc('DCS Delete',2),this.$t('Fail To Delete'),"error");
          } else {
              this.showMessage(this.$tc('DCS Delete',2),error,"error");
          }
        })
      },

      onRowSelected(items) {
        this.selected = items
      },
  
      selectAllRows() {
        this.$refs.selectableTable.selectAllRows()
      },

      Error(row){
        console.log(row); 
    },

    Warning(row){
        console.log(row);
    },
  
      clearSelected() {
        this.$refs.selectableTable.clearSelected()
      },
  
      selectThirdRow() {
        // Rows are indexed from 0, so the third row is index 2
        this.$refs.selectableTable.selectRow(2)
      },
  
      unselectThirdRow() {
        // Rows are indexed from 0, so the third row is index 2
        this.$refs.selectableTable.unselectRow(2)
      },
  
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },
  
      GetAddDCS(){
        this.Mode="Add"; 
        this.OptionsRightMenu.btnvisible = true;
        this.OptionsRightMenu.ItemsList = EnumService.PatientItems;
        this.OptionsRightMenu.Mode = "Add";
        this.$emit('getBtnRightMenu', this.OptionsRightMenu);
        EventBus.$emit('shwoHideFavorite', false);
        this.$store.commit('setBtnFavoriteState', false);         
        this.$router.push({ name: "dcs-add"}).catch(()=>{});
      },
  
      info(item, index) {
        alert(item.FirstName,index);
      },
  
      SearchDCS(){
        this.items = []
        this.currentPage = 1;
        this.parameter.PageNumber = 1;
        this.totalRows = this.items.length;
        this.getDCSList();
      },
  
      ChangeStatus(status){
       // this.status.value = status ? status.value : '';
        this.status = { value:''};
        if(status){
             this.status.value = status.value;
             this.status.text = status.text;
             this.status.selected = status.selected;
        }
        this.items = [];
        this.totalRows = 0;
        this.getPatients();
      }
    },
  
    created(){
      this.getDCSList()
     /*await this.getPermissionLeftMenu(this.Menu,this.$route.path,this.permission);
        if(this.permission.length == 0){
          this.$emit('load', false);
          this.$router.push({name: "Main"});    
            setTimeout(() => 
              this.showPermissionMessage()
            ,10);  
        }*/
    },
  
    mounted() {
     // this.getPatients();
      this.OptionsRightMenu.ItemsList = []
      let layoutStatus = this.$store.state.layoutStatus;
      switch(layoutStatus) {
        case 'L0R1':
          this.OptionsRightMenu.favButtonAtion = true;
          this.$emit('getVisibleMenu', this.OptionsRightMenu);
        break;
        case 'L1R0':
        case 'L1R1':
            this.OptionsRightMenu.btnvisible = false;
            this.$emit('getVisibleMenu', this.OptionsRightMenu);
        break;
        default:
            this.OptionsRightMenu.favButtonAtion = true;
            this.$emit('getVisibleMenu', this.OptionsRightMenu);
      }
     
     // this.isReadOnly = this.$store.state.isPatientInfoEHRReadOnly
    //  this.disableAddPatient = this.$store.state.isUsePatientInfo
    },
  
    updated() {
      this.OptionsRightMenu.btnvisible = false;
      this.OptionsRightMenu.ItemsList = [];
      this.$emit('getBtnRightMenu', this.OptionsRightMenu);    
    },
  
  };
  </script>
  
  <style>
   @import '../../../assets/css/Patients/PatientList.css';
  </style>
  