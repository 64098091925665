<template>
    <b-modal  id="bv-modal-ChangePassWord" 
      @hide="hideModal" 
      @ok="this.emitEventOK" scrollable centered :title="$t('ChangePassWord.ChangePassTitle')"
      :no-close-on-esc="true" 
      :hide-header-close="true"
      :no-close-on-backdrop="true"
      @shown="focusMyElement">
    <form autocomplete="off" role="form">
      <b-row>
        <b-col md="12">
          <MdPassword
            v-bind:Label="$t('AccountExpired.OldPassword')"
            v-bind:TypeProp="'password'" 
            v-bind:Name="'OldPassword'"           
            v-bind:MaxLengthProp="45"
            ref="inputOldpassword"
            v-bind:TabIndex="'1'"
            v-bind:isRequired="true"
            v-bind:isValid="isOldPassvalid"
            v-bind:isEndControl="false"           
            v-model="oldpassword"            
            v-bind:Autocomplete="false">                                     
          </MdPassword>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
           <MdPassword
            v-bind:Label="$t('ChangePassWord.NewPassword')"
            v-bind:TypeProp="'password'" 
            v-bind:Name="'newPassword'"           
            v-bind:MaxLengthProp="45"
            ref="inputnewpassword"           
            v-bind:TabIndex="'2'"
            v-bind:isRequired="true"
            v-bind:isValid="isNewPassvalid"
            v-bind:isEndControl="false"           
            v-model="newpassword"            
            v-bind:Autocomplete="false">                                     
          </MdPassword>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
           <MdPassword
            v-bind:Label="$t('ChangePassWord.ConfirmPass')"
            v-bind:TypeProp="'password'" 
            v-bind:Name="'confirmPassword'"           
            v-bind:MaxLengthProp="45"
            ref="inputconfirmpassword"           
            v-bind:TabIndex="'3'"
            v-bind:isRequired="true"
            v-bind:isValid="isConfirmPassvalid"
            v-bind:isEndControl="true"           
            v-model="confirmpassword"  
            :EndControlFunction="EndFunction"          
            v-bind:Autocomplete="false">                                     
          </MdPassword>
        </b-col>
      </b-row>
    </form>
    <Loading v-bind:IsLoading="this.Loading" />
  </b-modal>
</template>

<script>
import UserService from '@/services/user.service'
import StorageService from '@/services/storage.service'
import ShowMessage from '@/components/messages/ShowMessage.js'
import Loading from '@/components/template/loading/Loading'


export default {
  components: { 
    Loading
  },
  mixins: [ShowMessage],
  name: 'ChangePassWord',  
  data() {
      return {  
          Loading:false,
          oldpassword:'',
          newpassword:'',
          confirmpassword:'', 
          isOldPassvalid:true,
          isNewPassvalid:true,
          isConfirmPassvalid:true
      }
  },
  methods: {
      emitEventOK(evt){
         if (this.validate_required()) {   
          evt.preventDefault();
          this.changePassword(evt)
         }else{
          this.showMessage(this.$t('ChangePassWord.ChangePassTitle'), this.$t('Msg.CheckRedLabeledFields'), "errorAutoHide");
         }
      },

    hideModal() {
      this.oldpassword = '';
      this.newpassword = '';
      this.confirmpassword = '';
    },

    comparePassword() {
      if(this.newpassword === this.confirmpassword) {
        return true
      }
      return false
      },

      EndFunction(){
        this.changePassword();
      },


      validate_required() {
        this.isOldPassvalid = this.oldpassword.length > 0 ? true : false;
        this.isNewPassvalid = this.newpassword.length > 0 ? true : false;      
        this.isConfirmPassvalid =  this.confirmpassword.length > 0 ? true : false; 
        return this.isOldPassvalid  && this.isNewPassvalid && this.isConfirmPassvalid;
      },

    changePassword() {
      if(this.comparePassword()) {
        const clientId = StorageService.getClientId();
        const userId = StorageService.getUserId();
        const password = this.oldpassword;
        const newPassword = this.newpassword;         
        if( clientId !== '' && userId !== '' && password !== '' && newPassword !== '' ){
           this.Loading = true;
           this.$emit('load', this.Loading);
         UserService.changePassword( clientId, userId, password, newPassword)
            .then((response) => {
              if(response.status == '200'){ 
                this.Loading = false;              
                this.$emit('load', this.Loading);
                this.showMessage(this.$t('ChangePassWord.ChangePassTitle'),this.$t('ChangePassWord.PassChangeMsg'));
                this.$nextTick(() => {
                  this.$bvModal.hide('bv-modal-ChangePassWord');
                });
              }
            })
            .catch((error) => {    
              this.Loading = false;             
                this.$emit('load', this.Loading);
                if (!error.response) {
                  this.showMessage(this.$t('ChangePassWord.ChangePassTitle'),this.$t('ChangePassWord.NoAbleToChange'),"error");
                } else {
                    this.showMessage(this.$t('ChangePassWord.ChangePassTitle'),error.response.data.message,"error");
                }
            });
        }
      } else {   
        this.Loading = false;      
          this.$emit('load', this.Loading);
          this.showMessage(this.$t('ChangePassWord.ChangePassTitle'),this.$t('ChangePassWord.DontMatch'),"error");
      }
      
    },
    focusMyElement() {
      this.$nextTick(() => {
        setTimeout(() =>  this.$refs.inputOldpassword.setFocus(), 500);
      });
    },

  }
}
</script>


