<template>
        <div v-if="this.$attrs.control.controlCaption"
        v-bind:style="style"
        v-b-tooltip.hover :title="Hint" 
        >
        {{this.$attrs.control.controlCaption}}          
        </div>
</template>

<script>

export default {
  name: 'Label',
   props: {
     Text:  String     
   }, 
   data(){
     return{
      controlFont:[],
      style: {
        position:'absolute',
        top: this.$attrs.control.controlTop+'px',
        width:this.$attrs.control.controlWidth+'px',
        height:this.$attrs.control.controlHeight+'px',
        left:this.$attrs.control.controlLeft+'px',
        fontSize:'',
        fontWeight: '',
        fontFamily:'',
        color:'',
        background:'',
        textDecorationLine:'',
        fontStyle:'',
        textAlign: 'left',
        lineHeight: '1.2',
        padding: '0 4px'
      },
      Hint: this.$attrs.control.controlHint,
     }
   },

   methods:{
     InitializeVariables(){
       if(this.$attrs.control.controlColorForHtml){
          //this.style.background = this.$attrs.control.controlColorForHtml;
          this.style.background = 'rgb(25,190,232)';
       }
       if(this.$attrs.control.controlFontForHtml){
          this.controlFont = this.$attrs.control.controlFontForHtml.split(',');
          var size = parseInt(this.controlFont[1]);
          this.style.fontWeight = this.controlFont[0];
          this.fontFamily = this.controlFont[0];
          this.style.fontSize = size+'pt';
          this.style.color = this.controlFont[3];
          var Decorations = this.controlFont[2];
          this.style.fontWeight = Decorations.charAt(0) === '1'?'bold':'';
          this.style.fontStyle = Decorations.charAt(1) === '1'?'italic':'';
          this.style.textDecorationLine = Decorations.charAt(2) === '1'?'underline':'';
          this.style.textDecorationLine = Decorations.charAt(3) === '1'?this.style.textDecorationLine+ ' line-through':this.style.textDecorationLine;
       }
       this.setAlignment()
     },

     setAlignment() {
       if(this.$attrs.control.alignment) {
          if(this.$attrs.control.alignment === 'taCenter')
            this.style.textAlign = 'center'
          if(this.$attrs.control.alignment === 'taLeftJustify')
            this.style.textAlign = 'left'
          if(this.$attrs.control.alignment === 'taRightJustify')
            this.style.textAlign = 'right'
       }
     },
   },

   mounted(){
     this.InitializeVariables();
   }
};
</script>


