import ApiService from '@/services/api.service'

const UsersService = {

    getAll(parameter){
      var param = {}
        if(parameter.Status != 'All'){
            param.SearchCriteria = parameter.SearchCriteria,
            param.PageSize = parameter.PageSize,
            param.PageNumber = parameter.PageNumber,
            param.Active = parameter.Status
        }else{
            param.SearchCriteria = parameter.SearchCriteria,
            param.PageSize = parameter.PageSize,
            param.PageNumber = parameter.PageNumber       
        }
        const requestData = {
            method: 'get',
            url: "/Users/",
            headers: {
            },
            params: param
          }     
          return ApiService.customRequest(requestData);       
    },

    get(id) {
      return ApiService.get(`/Users/${id}`);
    },

    getRols(){
      return ApiService.get('/UserRoleHeaders');      
    },

    delete(id){
      return ApiService.delete(`/Users/${id}`)
    },

    add(data){
      return ApiService.post(`/Users`,data)
    },

    update(id,data){
      return ApiService.put(`/Users/${id}`,data)
    },

    getUserByCode(id){
      //return ApiService.get(`/Users/${id}`);     
      const requestData = {
        method: 'get',
        url: "/Users/",
        headers: {
        },
        params: {          
          Code: id        
        }
      }     
      return ApiService.customRequest(requestData);        
    }
}

export default UsersService;