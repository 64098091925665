<template>
    <div >
      <DxPopup
        :visible="isReportVisible"
        :drag-enabled="true"
        :close-on-outside-click="false"
        :show-close-button="true"
        :show-title="true"
        :hide-on-parent-scroll="true"
        :title="$t('notesRep.ReportTitle')"
        @hidden="onHiddenReport"
        :resize-enabled="true"
        shading-color="rgba(0, 0, 0, 0.5)"
        height="94vh"
        width="97vw"
        @showing="onShowing"
      >
        <ReportViewer
          ref="notesReport"
          :ReportName="'NotesReport'"
          :ReportTitle="ReportTitle"
          :IsANotesReport="true"
        />
    </DxPopup>
    </div>
</template>

<script>
    import Vue from 'vue'
    import { DxPopup } from 'devextreme-vue/popup';
    import ShowMessage from '@/components/messages/ShowMessage.js'
    import StorageService from '@/services/storage.service'

    export default Vue.extend({
        name: "NotesRep",
        mixins: [ ShowMessage ],
        components: { DxPopup },
        props: {
          closeWindow: Function,
          FormId: String,
          HeaderId: String,
          PatientCode: String,
          isReportVisible: Boolean,
          ReportTitle: String,
          Transdate: String,
          Notetime: String,
          Void: Boolean,
          NoteName: String,
          IsGroupNote: Boolean,
          Ver:[String,Number]
        },
        data() {
            return {
              token: `Bearer ${StorageService.getOnlyToken()}`
            }
        },
        methods: {

            onShowing(e) {
              let contentElement = e.component.content();
              let titleElement = e.component._$title[0]
              contentElement.style.padding = '0px';
              titleElement.style.padding = '0px 20px'
            },

            onHiddenReport() {
              this.closeWindow()
            },

            onClickClose() {
              this.closeWindow()
              this.isParamVisible = false;
            },

            onShowReport() {
              this.reportParams = []
              this.reportParams.push({ paramName: 'formid', paramValue: this.FormId})
              this.reportParams.push({ paramName: 'headerid', paramValue: this.HeaderId})
              this.reportParams.push({ paramName: 'patientcode', paramValue: this.PatientCode})
              this.reportParams.push({ paramName: 'token', paramValue: this.token})
              this.reportParams.push({ paramName: 'title', paramValue: this.ReportTitle})
              this.reportParams.push({ paramName: 'transdate', paramValue: this.Transdate})
              this.reportParams.push({ paramName: 'notetime', paramValue: this.Notetime})
              this.reportParams.push({ paramName: 'void', paramValue: this.Void})
              this.reportParams.push({ paramName: 'notename', paramValue: this.NoteName})
              this.reportParams.push({ paramName: 'isgroupnote', paramValue: this.IsGroupNote})
              this.reportParams.push({ paramName: 'Ver', paramValue: this.Ver})

              this.$refs.notesReport.buildDocument(this.reportParams)
            }
        },
        
        mounted () {          
          this.onShowReport()
        },
    })
</script>
<style>
  .dxrd-report-preview-holder {  
      height: 94% !important; 
  } 
</style>
