import ApiService from '@/services/api.service'


const MdDrugFreqSetupHdrsService = {
    getAll( parameter) {          
      const requestData = {
        method: 'get',
        url: "/MdDrugFreqSetupHdrs/",
        headers: {
        },
        params: { 
          SearchCriteria: parameter.SearchCriteria,
          PageSize: parameter.PageSize,          
          PageNumber : parameter.PageNumber        
        }
      }     
      return ApiService.customRequest(requestData);         
    },
  
    get(id) {
      return ApiService.get(`/MdDrugFreqSetupHdrs/${id}`);
    },

    add(data){
      return ApiService.post(`/MdDrugFreqSetupHdrs`,data)
    },

    delete(id){
      return ApiService.delete(`/MdDrugFreqSetupHdrs/${id}`)
    },
    
    update(id,data){
      return ApiService.put(`/MdDrugFreqSetupHdrs/${id}`,data)
    },
   
}
  
  export default MdDrugFreqSetupHdrsService;