<template>
   <div>
   <!-- <b-modal :id="modalId" size="xl" centered scrollable  :title="TitleWindow" @ok="this.emitEventOK" @cancel="this.emitEventCancel" @hide="resetModal"> -->
     <b-modal :id="modalId" no-close-on-backdrop no-close-on-esc size="xl" centered scrollable  :title="TitleWindow" ref="modaloccurrance" @close="Close" @ok="handleOk" @cancel="this.emitEventCancel"> 
     <form ref="form" @submit.stop.prevent="handleSubmit">
      <b-row>
         <b-col lg="3" md="3" sm="12">
                  <MdLookup
                     v-bind:ModelCode="Obj.Occurrencecode"
                     v-bind:ModelDescription="Obj.Occurrencedescription"
                     v-bind:RefCode="'Occurrence'"
                     v-bind:Api="'Occurrences'"
                     v-bind:Fields="BaseFieldOccurrence"
                     v-bind:Label="$tc('Admission.Occurrence')"
                     v-bind:FieldCode="'code'"
                     v-bind:isRequired="true" 
                     v-bind:FieldDescription="'description'"  
                     v-bind:Stacked="false"                  
                     :SearchTitle="$tc('Admission.Occurrence')"
                     :GetValues="onCodeConditionOk"
                     :InvalidFeedback="$t('ModalAdmissionOccurrence.InvalidOccurrenceCode')"
                     :SearchFieldCode="'Code'"                     
                  />
               </b-col>
                 <b-col lg="3" md="3" sm="12">
               <MdEdit 
                     v-bind:Label="$t('Shared.datefrom')"
                     v-bind:TypeProp="'date'" 
                     v-bind:Name="'datefrom'"
                     v-bind:Min="'1900-01-01'"
                     v-bind:Max="'2200-12-31'"
                     v-model="Obj.DateFrom">                                     
               </MdEdit>
            </b-col>
               <b-col lg="3" md="3" sm="12">
                <MdEdit 
                     v-bind:Label="$t('Shared.dateTo')"
                     v-bind:TypeProp="'date'" 
                     v-bind:Name="'dateto'"
                     v-bind:Min="'1900-01-01'"
                     v-bind:Max="'2200-12-31'"
                     v-model="Obj.DateTo">                                     
               </MdEdit>
               </b-col>
                <b-col lg="3" md="3" sm="12">
                <MdEdit 
                     v-bind:Label="$t('ModalAdmissionOccurrence.DateOccurrence')"
                     v-bind:TypeProp="'date'" 
                     v-bind:Name="'dateocurrence'"
                     v-bind:Min="'1900-01-01'"
                     v-bind:Max="'2200-12-31'"
                     v-model="Obj.DateOcurrence">                                     
               </MdEdit>
               </b-col>
      </b-row>
      <b-row>
           <b-col lg="3" md="3" sm="12">
                  <MdLookup
                     v-bind:ModelCode="Obj.RevCode"
                     v-bind:ModelDescription="this.Obj.RevCodedescription"
                     v-bind:RefCode="'RevCode'"
                     v-bind:Api="'CPTs?Type=Revenue'"
                     v-bind:Fields="BaseFieldsRevCode"
                     v-bind:Stacked="false"      
                     v-bind:Label="$t('ModalAdmissionOccurrence.RevCode')"
                     v-bind:FieldCode="'code'"
                     v-bind:FieldDescription="'name'"                    
                     :SearchTitle="$t('ModalAdmissionOccurrence.RevCode')"
                     :GetValues="onCodeConditionOk"
                     :InvalidFeedback="$t('ModalAdmissionOccurrence.InvalidRevCode')"
                     :SearchFieldCode="'Code'"                     
                  />
               </b-col>
      </b-row>
     </form>
    </b-modal>   
   </div>
</template>

<script>
import GlobalService from '@/services/global.service' 
import Utils from "@/common/utils";
import ShowMessage from '@/components/messages/ShowMessage.js'
import { EventBus } from '@/event-bus.js';

export default {
  name: 'ModalAdmissionOccurrence',
    mixins: [ShowMessage],  
   props: {
     ModalId: String,
     TitleWindow:String,
   //  ModeEdit:[Boolean],
     DataEdit:[Object],     
   },
   data(){
       return {
           rowSelected:[],
           ModeEdit:false,
           modalId:this.ModalId,
           currentPage:1,
           perPage:10,
           count:0,          
           Obj:{
               Occurrencecode:"",
               Occurrencedescription:"",
               RevCode:"",
               RevCodedescription:"",
               DateFrom:"",
               DateTo:"",
               DateOcurrence:"",
           },
           BaseFieldOccurrence:[{ key: 'description', sortable: true, label: this.$t('Shared.Description') },{ key: 'code', sortable: true, label: this.$t('Shared.Code')}],
            BaseFieldsRevCode:[{ key: 'name', sortable: true, label: this.$t('Shared.Description') },{ key: 'code', sortable: true, label: this.$t('Shared.Code')}],
          selectedItems:[],
        Therapies:[
        {value:'A',name: this.$t('Shared.Admissions')},
        {value:'B',name: this.$t('ModalAdmissionOccurrence.TherapiesDischargeInformation')},
        {value:'C',name: this.$t('ModalAdmissionOccurrence.TherapiesGroupNotes')},
        {value:'D',name: this.$t('ModalAdmissionOccurrence.TherapiesNursing')},
        {value:'E',name: this.$t('ModalAdmissionOccurrence.TherapiesMiscellanous')},
        {value:'F',name: this.$t('ModalAdmissionOccurrence.TherapiesIntakeForms')},
    ],


       }
   },

   methods:{

     checkFormValidity() {
       const valid = this.Obj.Occurrencecode === '' && this.Obj.DateFrom === '' && this.Obj.DateTo === '';
       return valid
      },

      getUniqueID(){
        return Date.now() + ( (Math.random()*100000).toFixed());
      },

      handleOk(bvModalEvt) {      
        bvModalEvt.preventDefault();      
        this.handleSubmit()
      },
      handleSubmit() {
        // Exit when the form isn't valid
        if (!this.checkFormValidity()) {
          this.emitEventOK();
          return
        }
        this.emitEventOK();
      },
     emitEventOK(){        
       if(this.Obj.Occurrencecode === ''){
         this.showMessage(this.$t('Admission.AddOccurrence'),this.$t('ModalAdmissionOccurrence.OccurrenceFieldRequired'),"error");
       }else if(this.Obj.DateFrom === ''){
         this.showMessage(this.$t('Admission.AddOccurrence'),this.$t('ModalAdmissionOccurrence.DateFromFieldRequired'),"error");
       }else if(this.Obj.DateTo === ''){
           this.showMessage(this.$t('Admission.AddOccurrence'),this.$t('ModalAdmissionOccurrence.DateToFieldRequired'),"error");
       }else{
          var send = {};
       send.Occurrencecode = this.Obj.Occurrencecode;
       send.Occurrencedescription = this.Obj.Occurrencedescription;
       send.RevCode = this.Obj.RevCode;
       send.RevCodedescription = this.Obj.RevCodedescription;
       send.DateFrom = this.Obj.DateFrom;
       send.DateTo = this.Obj.DateTo;
       send.DateOcurrence = this.Obj.DateOcurrence;
       send.ModeEdit = this.ModeEdit;
      // send.recId = !this.ModeEdit ? this.getUniqueID() : this.DataEdit.recId;
       send.recId = this.DataEdit.recId;
       this.$emit('getCodeConditionOK', send);
         this.$nextTick(() => {
          this.$bvModal.hide(this.ModalId);
        })
      
      setTimeout(() => this.ResetObjOccurrence(),200); 
       } 
      },
      
      onCodeConditionOk(result){
         var type = result.FieldName;
         if(type.indexOf('Occurrence') >= 0){
             switch (type) {
               default:
                  if(result.code){
                  this.Obj.Occurrencecode = result.code;  
                  this.Obj.Occurrencedescription = result.description;               
                  }else{
                    this.Obj.Occurrencecode = "";
                    this.Obj.Occurrencedescription = "";
                  }
             }
         }else if(type.indexOf('RevCode')  >= 0 ){
              switch (type) {               
               default:
                 if(result.code){
                  this.Obj.RevCode = result.code;  
                  this.Obj.RevCodedescription = result.description;               
                  }else{
                    this.Obj.RevCode = "";
                    this.Obj.RevCodedescription = "";
                  }
             }
         }
     },

      emitEventCancel(){
        setTimeout(() => this.ResetObjOccurrence(),200); 
      }, 

      funcDestination(value){
        this.selectedItems = value;
      },

      pageChanged (page) {         
        this.currentPage = page;    
      },

      PerPageFunction(perpage){
       this.perPage = perpage;
       this.currentPage = 1;
      },

      ChangeDropDownDoctors(doctors){
        this.Doctors = [];
        var d = [];
       doctors.forEach(function(e) {   
            d.push(e);               
        });

        this.Doctors = d;
      },

      ChangeDropDownReferrals(referals){
        this.Referrals = [];
        var r = [];
       referals.forEach(function(e) {   
            r.push(e);               
        });

        this.Referrals = r;
      },

      ChangeDropDownEmployees(employees){
        this.Employees = [];
        var em = [];
       employees.forEach(function(e) {   
            em.push(e);               
        });

        this.Employees = em;
      },

      ChangeDropDownContacts(contacts){
        this.Contacts = [];
        var c = [];
       contacts.forEach(function(e) {   
            c.push(e);               
        });

        this.Contacts = c;
      },

      Search(){
        this.currentPage = 1; 
      },

       formatterDate(value){
         return Utils.formatterDate(value);
      },

        getDefaultDateTime(data) {
          this.ModeEdit = data.ModeEdit;       
          if(!data.ModeEdit) {
            GlobalService.getDateTime()
            .then((response) => {      
               if (response.data) {                
                  var currentDate = this.formatterDate(response.data);                         
                  /*if(!this.ModeEdit && this.count === 0){
                    this.ResetObjOccurrence();
                  }*/
                  this.count++;
                  this.Obj.DateOcurrence = currentDate;
                  this.Obj.DateFrom = currentDate;               
               }  
         })
         .catch(error => {
            if (error.response) {
               this.showMessage(this.$t('Admission.AdmissionGetDateTime'),error,"error");
            }
         }); 
        }else{         
           this.Obj.Occurrencecode = data.dataEdit.occurrenceCode;
           this.Obj.Occurrencedescription = data.dataEdit.occurrenceDescription;
           this.Obj.RevCode = data.dataEdit.revenueCode;
           this.Obj.RevCodedescription = data.dataEdit.revenueDescription;
           this.Obj.DateFrom = data.dataEdit.dateFrom ? this.formatterDate(data.dataEdit.dateFrom):"";
           this.Obj.DateTo = data.dataEdit.dateTo ? this.formatterDate(data.dataEdit.dateTo):"";
           this.Obj.DateOcurrence = data.dataEdit.occurrenceDate ? this.formatterDate(data.dataEdit.occurrenceDate):"";
        }
      },

      ResetObjOccurrence(){ 
           this.Obj.Occurrencecode = "";
           this.Obj.Occurrencedescription = "";
           this.Obj.RevCode = "";
           this.Obj.RevCodedescription = "";
           this.Obj.DateFrom = "";
           this.Obj.DateTo = "";
           this.Obj.DateOcurrence = "";
      },

      Close(){
        this.ResetObjOccurrence();
      }
   },

   mounted(){ 
   
     //if(this.modalId === 'ModaladmissionOccurrence')   {
        /*this.$refs.modaloccurrance.$on('bv::modal::show', () => {     
          this.getDefaultDateTime();          
        });*/

        EventBus.$on("EmitDefaultDate", function (data) {           
            this.getDefaultDateTime(data);   
        }.bind(this));

      
    // }

    /* EventBus.$on("bv::modal::shown", function () {   
         this.getDefaultDateTime();
     }.bind(this));*/
    
    //  this.$refs.modaloccurrance.$on("bv::modal::shown", this.getDateTime());
     // this.$refs.modaloccurrance.$on("bv::modal::shown", this.getDateTime());
      this.$refs.modaloccurrance.$on("hidden.bs.modal", this.ResetObjOccurrence);
    
     /* this.$root.$on('bv::modal::show', () => {
    
      });*/
   },

    beforeDestroy () {     
      EventBus.$off('EmitDefaultDate');  
    },
 
   
};
</script>