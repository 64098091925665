<template>
    <div>
     <b-modal size="TP_Problem" :id="ModalId" :visible="true" centered no-close-on-backdrop no-close-on-esc :title="TitleWindow" ref="modalEditProblemTP" @close="this.emitEventCancel"  @ok="handleOk" :ok-title="$t('Shared.Save')"  @cancel="this.emitEventCancel"> 
      <form ref="form_ProblemEdit" @submit.stop.prevent="handleSubmit">
            <b-row>                  
              <b-col md="12">
                <MdEdit v-bind:Label="$t('modalEditGoal.dIdentified')" v-bind:Name="'DateIndentified'" v-bind:TypeProp="'text'"
                  v-bind:Mask="'##/##/####'" v-bind:Min="'1900-01-01'" v-bind:Max="'2200-12-31'"
                  v-model="dateidentified" 
                />
              </b-col>
          </b-row>    
        </form>            
               
          
       
     </b-modal>  
    </div>
</template>
 
 <script>
 import Utils from '@/common/utils'
 import ShowMessage from '@/components/messages/ShowMessage.js'
 //import IncidentReportInterventionsSetups from '@/services/incidentreportinterventionssetups.service'
 //import IncidentReportSeveritySetup from '@/services/incidentreportseveritysetup.service'
 import TreatmentPlan from '@/services/treatmentplan.service'
 import moment from 'moment'


 export default {
   name: 'ModalEditProblemTP',
   mixins: [ShowMessage],  
   props: {
     ModalId: String,
     ProbDesc:String,
     DiagDesc:String,
     DiagCode:String,
     TitleWindow: String,
     ProblemRecId:String,
     ProblemDateIdentified:String,
     Signatures:Array 
   },
   
   data(){
     return {
        dateidentified:this.ProblemDateIdentified,
     }
   },
 
   methods:{
     emitEventOK(/*bvModalEvent*/){
       /*if (this.validateFields()) {
         this.categoryItems.incidentTypes = this.IncidentCodes
         this.setInterventions()
         if (this.ModeEdit == false) {
           this.categoryItems.id = Utils.generateRecID()
         }               
         this.$emit('getCodeConditionOK',this.categoryItems);
       } else {       
         bvModalEvent.preventDefault()
       }    */  
       this.UpdateProblem();
     },

     checkFormValidity() {    
       return Utils.ValidateDate(this.dateidentified) ? true : false; 
      },

     handleOk(bvModalEvt) {      
        bvModalEvt.preventDefault();      
        this.handleSubmit()
      },

     handleSubmit() {       
        !this.checkFormValidity() ? this.showMessage(this.$t('modalEditTPProblem.updPro'),this.$t('modalEditTPProblem.invalMsg'),"error"):this.emitEventOK();
      },


      UpdateProblem(){
        //if(this.IsValidFieldDate(this.dateidentified)){
        var data = {
            probDate:this.dateidentified ? moment(this.dateidentified).format():null
        }
         TreatmentPlan.UpdateProblem(this.ProblemRecId,data)
            .then((response) => { 
               if(response.data){
                this.$emit('RefreshGetTP');
                setTimeout(() => 
                        this.$bvToast.toast(this.$t('treatmentplan.ProblemSaved'), {
                         title: this.$t('Msg.Saving'),
                          autoHideDelay: 5000,
                          variant: "success"
                        })
                        , 20); 
               }             
            })
            .catch((error) => {
                if (!error.response) {
                        this.showMessage(this.$t('modalEditTPProblem.updPro'),this.$t('Msg.NotAbleToUpdate'),"error");
                }     
                   else if(error.response.status === 400 ){
                     this.showMessage(this.$t('modalEditTPProblem.updPro'),error.response.data.errorMessage,"error");
                     }   
                     else if(error.response.status === 500){
                        this.showMessage(this.$t('modalEditTPProblem.updPro'),this.$t('Msg.ThereWereSomeError'),"error");
                     } else{
                         this.showMessage(this.$t('modalEditTPProblem.updPro'),error.response.data.errorMessage,"error");
                     }    
            });
       
          
          },

       
     emitEventCancel(){
      this.$emit('ModalTPEditProblemCANCEL');
    },    
   },
 
  mounted(){
    
   },
 };
 </script>

