<template>
 <div id="screensaver" v-bind:class="this.screensaver_active ? 'background' : 'background hidden'">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <div class="mainlogo sfx"></div>
      <div class="vignette"></div>
    </div>
</template>

<script>
export default {
  name: 'Screensaver',
  data() {
    return {
      mousetimeout:null,
      screensaver_active:false,
      idletime:600
    }
  },
  methods:{
    OnKeyPress(){
      document.getElementById("screensaver")?.focus();
      this.MouseMove();
    },

    MouseMove() {
      clearTimeout(this.mousetimeout);
      if (this.screensaver_active) {
        this.stop_screensaver();
      }
      this.mousetimeout = setTimeout(this.show_screensaver, 1000 * this.idletime);
    },

    stop_screensaver(){   
      this.screensaver_active = false;
    },

    show_screensaver(){  
      this.screensaver_active = true;   
    },  
  },
  mounted() {
    window.addEventListener('mousemove', this.MouseMove);
    window.addEventListener("keypress", this.OnKeyPress);
    window.addEventListener("touchmove",this.MouseMove);  
    window.addEventListener("click",this.MouseMove); 
    window.addEventListener("wheel",this.MouseMove);  
    window.addEventListener("keyup",this.MouseMove);   
    window.addEventListener("keydown",this.MouseMove); 
  },
};
</script>
