import ApiService from '@/services/api.service'

const PatientService = {  
  getAll(parameter) {  
    var param = {}
    if(parameter.Status != 'All'){
        param.SearchCriteria = parameter.SearchCriteria,
        param.PageSize = parameter.PageSize,
        param.PageNumber = parameter.PageNumber,
        param.Active = parameter.Status,
        param.IsInpatient = parameter.IsInpatient
    }else{
        param.SearchCriteria = parameter.SearchCriteria,
        param.PageSize = parameter.PageSize,
        param.PageNumber = parameter.PageNumber       
    }

    const requestData = {
      method: 'get',
      url: "/patients/",
      headers: {
        
      },
      params: param
    }
    return ApiService.customRequest(requestData);
  },

  getPatientAll(parameter){
    return ApiService.get(`/patients/?Active=${parameter.Status}`);
  },

  
  getPatientList(){
    return ApiService.get(`/patients`);
  },



  getInfoList(PatientCode){
    var param = {};
    param.PatientCode = PatientCode;
    const requestData = {
      method: 'get',
      url: "/patients/",
      headers: {
        
      },
      params: param
    }
    return ApiService.customRequest(requestData);
  },

  get(id) {
    return ApiService.get(`/patients/${id}`);
  },

  getDiagnoses(PatientCode) {
    return ApiService.get(`/AdmissionDiagnoses?PatientCode=${PatientCode}`);
  },

  getDiagnosesByOutCome(RecordId) {
    return ApiService.get(`/AdmissionDiagnoses/ByAdmission?Code=${RecordId}`);
  },
  
  getPatientDiag(id) {
    return ApiService.get(`/Patients/PatientDiagnosis?id=${id}`);
  },
  
  getPatientAllergy(PatientCode){
    const requestData = {
      method: 'get',
      url: "/PatientAllergies/",     
      params: {
        PatientCode: `${PatientCode}`,
        IsActive:`true`
      }
    }
    return ApiService.customRequest(requestData);
  },

  getPatientDiagnosis(PatientCode){
    const requestData = {
      method: 'get',
      url: "/PatientDiagnosisSummaries/",     
      params: {
        PatientCode: `${PatientCode}`       
      }
    }
    return ApiService.customRequest(requestData);
  },

  addPatient(data) {
    return ApiService.post(`/patients`, data)
  },

  update(id, patient) {
    /* const requestData = {
       method: 'put',
       // url: "/patients/",
       url: `/patients/${id}`,
       headers: {},
       data: patient
     }*/
    return ApiService.put(`/patients/${id}`, patient);
  },

  getReferal(referalCode) {

    
    const requestData = {
      method: 'get',
      url: "/Referrals/",
      headers: {
        
      },
      params: {
        Code: `${referalCode}`,

      }
    }
    return ApiService.customRequest(requestData);



  },
  getCommunicationLiaison(commLiason) {

    const requestData = {
      method: 'get',
      url: "/CommLiason/",
      headers: {
        
      },
      params: {
        Code: `${commLiason}`,

      }
    }
    return ApiService.customRequest(requestData);

  },

  getMatrix(matrix) {

    
    const requestData = {
      method: 'get',
      url: "/Matrixes/",
      headers: {
        
      },
      params: {
        Code: `${matrix}`,

      }
    }
    return ApiService.customRequest(requestData);
  },

  
  getDoctor(doctorCode) {

    
    const requestData = {
      method: 'get',
      url: "/Doctors/",
      headers: {
        
      },
      params: {
        Code: `${doctorCode}`,

      }
    }
    return ApiService.customRequest(requestData);



  },

  getFacilities(facilitycode) {

    
    const requestData = {
      method: 'get',
      url: "/Facilities/",
      headers: {
        
      },
      params: {
        Code: `${facilitycode}`,

      }
    }
    return ApiService.customRequest(requestData);

  },

  getEmployees(employeecode) {

    
    const requestData = {
      method: 'get',
      url: "/Employees/",
      headers: {
        
      },
      params: {
        Code: `${employeecode}`,

      }
    }
    return ApiService.customRequest(requestData);

  },

  getDiagnosis(diagnosiscode) {

    
    const requestData = {
      method: 'get',
      url: "/DiagnosisICD10Providers/",
      headers: {
        
      },
      params: {
        Code: `${diagnosiscode}`,

      }
    }
    return ApiService.customRequest(requestData);

  },
  getDataHeaderRightMenu(PatientCode){    
    const requestData = {
      method: 'get',
      url: "/PatientSummaries/",     
      params: {
        PatientCode: `${PatientCode}`,

      }
    }
    return ApiService.customRequest(requestData);
  },

  getExistPatient(patientData){
    var param = {};
    param.LastName = patientData.LastName;
    param.FirstName = patientData.FirstName;
    param.DOB = patientData.DOB;
    param.PageSize = 1;
    
    const requestData = {
      method: 'get',
      url: "/patients/",
      headers: {
        
      },
      params: param
    }
    return ApiService.customRequest(requestData);
  },

  getAgeFormatted(date) {
    return ApiService.get(`/Utils/AgeFormatted?dateOfBirth=${date}`);
  },

  getUseAutoAssignCode(form) {
    return ApiService.get(`/Utils/UseAutoAssignCode?ClassName=${form}`);
  },

}
  


export default PatientService;