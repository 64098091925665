import ApiService from '@/services/api.service'

const ConditionService = {

    getAll(Resource){
        return ApiService.get(Resource);
    },

    getConditionByCode(code){
      return ApiService.get(`/conditions/?code=${code}`);  
    }

}

export default ConditionService;