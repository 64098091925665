<template>
    <div class="vld-parent">
        <loading :active.sync="this.IsLoading" 
        :can-cancel="false" 
        :enforce-focus="false"       
        :is-full-page="fullPage"></loading>
    </div>
</template>

<script>
    // Import component
    import Loading from 'vue-loading-overlay';
    // Import stylesheet
    import 'vue-loading-overlay/dist/vue-loading.css';
    
    export default {
        props: {
        IsLoading: Boolean,
        },
        data() {
            return { 
                //isLoading: this.Loading,               
                fullPage: true
            }
        },
        components: {
            Loading
        },
        methods: {
         
        }
    }
</script>