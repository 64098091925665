<template> 
      <fragment>  
        <div :class="this.classValue" :data-label="Label + this.invalidFeedback">
                <div class="input-group">
                    <input
                        :type="showPassword ? 'text' : 'password'"  
                        :placeholder="Placeholder"
                         v-on:keyup.enter="onEnter"
                        :aria-describedby="Name"
                        v-bind:value="value"
                        v-on:keypress="$emit('keypress', $event)"
                        v-on:input="onInput"
                       
                        v-mask="this.Mask" 
                        :tabindex="this.TabIndex"          
            :maxlength="MaxLengthProp"          
            :readonly="isReadOnly"
            :min="Min"
            :max="Max"
            :step="StepProp"
            :ref="Name"
            :id="Id"
            class="form-control"            
            :autocomplete="autocomplete"
            v-on:blur="onBlur"
            v-on:change="OnChange"
                      >
                    
                  
                     <div class="input-group-append">
                        <button 
                            tabindex="-1"
                            @click="showPassword = !showPassword"                           
                            class="btn btn-sm search-attached-password"
                            type="button"
                             >
                          <b-icon-eye v-if="!showPassword"/>
                          <b-icon-eye-fill v-if="showPassword"/>
                        </button>
                    </div>
                    <!-- <b-form-invalid-feedback
                        :id="RefCode"
                    >{{ invalidFeedback }}</b-form-invalid-feedback> -->
                </div>
            </div>
       
     </fragment>         
</template>

<script>
import Utils from '../../../common/utils'
import { EventBus } from '@/event-bus.js';
export default {
  name: 'MdEdit',
   props: {     
     TypeProp:String,
     StepProp:String,
     MaxLengthProp: Number,
     isReadOnly:Boolean,
     Min:String,
     Max:String,
     isRequired:Boolean,
     value:[String, Number, Date],
     Name:String,
     Id:String,
     isValid:Boolean,
     Label:String,
     Placeholder:String,
     Autocomplete:Boolean,
     Mask: String,
     Tag: String,
     TabIndex:String,
     isEndControl:Boolean,
     EndControlFunction:Function,
     NoValidateChanges: Boolean,
   },

   data(){
     return {
      Count:0,
      isEndControlLocal:this.isEndControl,
      showPassword: false,
      showPointer: false,
      classValue:"textlabel",
      autocomplete:this.Autocomplete ? 'on' : 'off',
      invalidFeedback:""
     }
   },

watch: {
  isValid: function (newValue) {
      this.classValue= newValue ? "textlabel" : "textlabel-invalid"
      this.invalidFeedback = newValue ? "": ": "+ this.$t('Msg.RequiredField')
    }
  },

methods:{
    checkIsRequired(evt){     
      let Invalid = false;
      if(evt.target.value){
         if(this.TypeProp == 'text') {
        Invalid = evt.target.value.trim() == "" && this.isRequired ? true : false;
        this.invalidFeedback = Invalid ? ": "+ this.$t('Msg.RequiredField'): ""
      }
      else if(this.TypeProp == 'date') {
        Invalid = !Utils.isValidDate(evt.target.value) && this.isRequired ? true : false;
        this.invalidFeedback = Invalid ? ": "+ this.$t('Msg.InvalidField'): ""
      }
      else if(this.TypeProp == 'email') {
        //eslint-disable-next-line
        const reg = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
        if (evt.target.value.length > 0) {
          Invalid = (reg.test(evt.target.value)) ? false : true
        }
        this.invalidFeedback = Invalid ? ": "+this.$t('Msg.InvalidField'): ""
      }
      }

      this.classValue= Invalid ? "textlabel-invalid" : "textlabel"
     
    },

    onEnter(){
      if(this.isEndControlLocal)
        this.EndControlFunction();
    },


    OnChange(evt){
       this.checkIsRequired(evt)
       this.$emit('change',evt.target.value)
     },

    onBlur(evt) {
      this.checkIsRequired(evt)
      this.$emit('blur', evt.target.value)
    },

    setFocus(){
       this.$refs[this.Name].focus();
    },

    onInput(evt){
      this.Count++;
     if(!this.Mask || this.Count > 1){
        this.$emit('input', evt.target.value)
      var changed = {
        "Changed":true,
        "ComponentName":this.Name,
        "Tag": this.Tag
      }
      if(!this.NoValidateChanges){
        EventBus.$emit('onChanged', changed);
      }
     }
    }
  }
};

</script>

<style>
 @import '../../../assets/css/Patients/Patient.css';
</style>


