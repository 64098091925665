<template>
    <div class="form-group">
        <b-row v-if="LabelDescription">
            <div class="col-md-4 textlabel" :data-label="LabelCode">
                <div class="input-group">
                    <b-form-input
                        type="text"
                        :ref="refCode"
                        maxlength="MaxLengthCode"
                        v-model="modelCode"
                        class="form-control"
                        @change="lookupFieldChange($event)"
                        @blur="lookupNameByCode($event.target.value)"
                        :state="CodeState"
                        v-on:keyup.enter="lookupEnterEvent(refCode, $event.target.value)"
                        :disabled="IsDisabled"
                    ></b-form-input>
                </div>
            </div>

            <div class="col-md-8 textlabel" :data-label="LabelDescription">
                <div class="input-group">
                    <b-form-input
                        type="text"
                        class="form-control"
                        maxlength="30"
                        :disabled="true"
                        v-model="modelDescription"
                    ></b-form-input>

                    <div class="input-group-append">
                        <button
                            :disabled="IsDisabled"
                            class="btn btn-sm search-attached"
                            type="button"
                            v-on:click="CallModalTable()"
                        >
                            <i class="mdi mdi-magnify"></i>
                        </button>
                    </div>
                </div>
            </div>
            <b-form-invalid-feedback
                id="input-referringInsuranceCode-feedback"
            >{{ invalidFeedback }}</b-form-invalid-feedback>
        </b-row>

        <div v-if="!LabelDescription" class="textlabel" :data-label="LabelCode">
            <div class="input-group">
                <b-form-input
                    type="text"
                    :ref="refCode"
                    maxlength="MaxLengthCode"
                    v-model="modelCode"
                    class="form-control"
                    @change="lookupFieldChange($event)"
                    @blur="lookupNameByCode($event.target.value)"
                    :state="CodeState"
                    v-on:keyup.enter="lookupEnterEvent(refCode, $event.target.value)"
                    :disabled="IsDisabled"
                ></b-form-input>
                <div class="input-group-append">
                    <button
                        :disabled="IsDisabled"
                        class="btn btn-sm search-attached"
                        type="button"
                        v-on:click="CallModalTable()"
                    >
                        <i class="mdi mdi-magnify"></i>
                    </button>
                </div>
            </div>
        </div>

        <ModalTablePaginationServer
            v-bind:Resource="this.ResourceParameter"
            v-bind:TypeOption="this.TypeOption"
            @getCodeConditionOK="onCodeConditionOk($event)"
            v-bind:Fields="this.FieldsParameter"
            v-bind:FieldCode="this.fieldCode"
            v-bind:FieldDescription="this.fieldDescription"
            v-bind:TitleWindow="this.SearchTitle"
            v-bind:ModalId="this.modalId"
        ></ModalTablePaginationServer>
    </div>
</template>

<script>

import ModalService from '@/services/modal.service'
import ShowMessage from "@/components/messages/ShowMessage.js";

export default {

    mixins: [ShowMessage],
    props: {
        ModelCode: String,
        ModelDescription: String,
        Placeholder: String,
        LabelCode: String,
        LabelDescription: String,
        FieldDescription: String,
        FieldCode: String,
        IsDisabled: Boolean,
        MaxLengthCode: String,
        RefCode: String,
        Api: String,
        GetValues: Function,
        InvalidFeedback: String,
        SearchTitle: String,
    },
    computed: {
        CodeState() {
            return this.isCodeInvalid ? false : null;
        }
    },
    watch: {
        ModelCode: function(newModel) {
            if (newModel) {
                this.modelCode = newModel;
            }

        },
        ModelDescription: function(newModel) {

            if (newModel) {
                this.modelDescription = newModel;
            }

        },

    },
    data() {
        return {
            modelCode: this.ModelCode,
            modelDescription: this.ModelDescription,
            fieldCode: this.FieldCode ? this.FieldCode : "code",
            fieldDescription: this.FieldDescription ? this.FieldDescription : "description",
            invalidFeedback: this.InvalidFeedback ? this.InvalidFeedback : this.$t('Msg.InvalidCode'),
            lookupFieldChanged: false,
            isCodeInvalid: false,
            FieldsParameter: [],
            ResourceParameter: "",
            TypeOption: "",
            refCode: this.RefCode ? this.RefCode : "",

            modalId: this.RefCode,
            parameter: {
                SearchCriteria: '',
                PageNumber: 1,
                PageSize: 1,
            },


        }
    },

    methods: {


        lookupFieldChange: function() {
            this.lookupFieldChanged = true;
            this.isCodeInvalid = this.modelCode && this.modelCode.length > 0 ? false : true;
        },
        lookupNameByCode: function(value) {

            if (this.lookupFieldChanged) {
                this.getLookup(value);
                this.lookupFieldChanged = false;
            }
            this.isCodeInvalid = this.modelCode && this.modelCode.length > 0 ? false : true;
        },
        getLookup(value) {

            if (value.length > 0) {
                var result = {};
                this.parameter.SearchCriteria = value;

                ModalService.getAll("/" + this.Api, this.parameter)
                    .then((response) => {
                        if (response.status == '200' && response.data) {
                            this.modelCode = response.data[0][this.fieldCode];
                            result.code = this.modelCode;
                            result.FieldName = this.refCode;


                            if (this.LabelDescription) {
                                this.modelDescription = response.data[0][this.fieldDescription];
                                result.description = this.modelDescription;
                            }
                            if (this.GetValues) {
                                this.GetValues(result);
                            }
                        }
                    })
                    .catch(error => {
                        if (!error.response) {
                            // network error
                            this.showMessage(this.SearchTitle, this.$t('Msg.RetrieveInformation'), "error");
                            //this.modelCode = this.ModelCode;
                        } else {
                            this.showMessage(this.SearchTitle, error, "error");
                        }
                    });

            } else {
                this.showMessage(this.SearchTitle, "Please, type a valid code.", "error");
            }

        },
        lookupEnterEvent: function(control, value) {

            this.lookupNameByCode(value);

        },

        CallModalTable: async function() {


            if (this.refCode) {

                this.$emit('load', true);

                this.FieldsParameter = [
                    this.fieldCode,
                    this.fieldDescription
                ];
                this.ResourceParameter = "/" + this.Api;
                this.TypeOption = this.refCode;

                await this.$bvModal.show(this.modalId);
                this.$emit('load', false);
            }
        },

        onCodeConditionOk(result) {

            var type = result.FieldName;
            if (type.indexOf(this.refCode) !== -1 && result.code) {
                this.modelCode = result.code;
                this.modelDescription = result.description;
                if (this.GetValues) {
                    this.GetValues(result);
                }
            }
            this.$bvModal.hide(this.modalId);

        },



    },

    mounted() {
    }
}

</script>
<style src="@/assets/css/clinical/multiselect.css">
@import "../../../assets/css/flex.css";
</style>