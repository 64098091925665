<template>
   <div id="ContainerBuildings">
      <div class="row">
          <div class="col-12">
            <h4 class="page-title text-truncate"> {{$t('buildingList.BuildSetupTitle')}}</h4>
            <div class="row">
                <div class="col-sm-12 col-md-3"  v-if="permission.length > 0 && permission[0].roleCanAdd">                      
                    <MdButton
                      v-bind:Label="$t('building.Add')" 
                      v-bind:ClassProp="'btn btn-primary my-3 btn-block btn-login'"
                      v-bind:IconClass="'ti-plus pr-1'"
                      v-on:click="AddBuilding"                   
                      v-bind:Name="'btn_add_building'">
                    </MdButton>
                </div>
                <div v-bind:class="permission.length > 0 && permission[0].roleCanAdd ? 'col-sm-12 col-md-9 mb-4':'col-sm-12 col-md-12 mb-4'">
                    <div class="textlabel" :data-label="this.$t('Shared.SearchElements')">
                        <div class="input-group">
                            <input 
                              type="text" 
                              class="form-control"
                              v-model="parameter.SearchCriteria"   
                              @keyup.enter="SearchBuilding"                            
                            >
                            <div class="input-group-append">
                                <div class="input-group-append">
                                <MdButton 
                                  v-bind:Name="'btnsearchinput'"
                                  v-bind:VariantProp="'btn btn-sm search-attached'"
                                  v-bind:IconClass="'mdi mdi-magnify'"
                                  v-on:click="SearchBuilding">
                                </MdButton>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>              
        </div>
      </div>
      <div class="row">
        <div class="col-12 mt-2">
        <MdTitle v-bind:Text="$t('Shared.SearchResult')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
          <div class="row">
            <div class="col-md-12">
              <MdTable 
                v-bind:fields="fields"
                v-bind:items="items"
                v-bind:totalRows="totalRows"
                v-bind:isReadOnly="isReadOnly"
                v-bind:showMessageIsEmpty="ShowMessageIsEmpty"
                v-bind:currentPage="currentPage"
                v-bind:perPage="perPage"
                v-bind:pageOptions="pageOptions"
                :editIconEvent="EditBuilding"
                v-bind:VisibleEditIcon="this.permission.length && this.permission[0].roleCanEdit ? true : false"
                :deleteIconEvent="RemoveBuilding"
                v-bind:VisibleDeleteIcon="this.permission.length && this.permission[0].roleCanDelete ? true : false"
                :onRowSelected="onRowSelected"
                :onFiltered="onFiltered"
                :pageChanged="pageChanged"
                :perPageFunction="PerPageFunction">
              </MdTable>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import BuildingService from '@/services/building.service'
//import EnumService from '@/services/enum.service' 
import ShowMessage from '@/components/messages/ShowMessage.js'
import DBFunctions from '@/common/DBFunctions.js'


export default {

  beforeRouteEnter(to, from, next) {
        next(async vm => {
            const iPI = await vm.CanView();
            if(iPI) next()
            else next('/accessdenied');
        }) 
  },

  mixins: [ShowMessage, DBFunctions],
  name: 'BUILDINGLIST',
  data() {
    return {
         OptionsRightMenu:{
          visible:false,
          ItemsList:[],
          btnvisible:false,
          itemdisabled: false,
          PatientCode:'',
          Mode:this.edit_mode,
          favButtonAtion:false          
        },
      patient:{},
      permission:[],
      Menu : this.$store.state.leftMenu, 
      code:"",
      ShowMessageIsEmpty:false,
      Loading:true,
      PatientTitle:{      
       programName:'',
       fullName:'',
       admitDate:'',
       dischargeDate:'',
       dob:''
     }, 
      Mode:'',         
      parameter:{
        SearchCriteria:'',
        PageNumber : 0,
        PageSize:0 ,
        PatientCode:''             
      },
      fields: [
        { key: 'name', sortable: true, label: this.$t('Shared.Name'), class: 'text-rigth' },       
        { key: 'code', sortable: true, label: this.$t('Shared.Code'), class: 'text-rigth'},       
        { key: 'address1', sortable: true, label: this.$t('Shared.Address1'), class: 'text-rigth' },      
        { key: 'address2', sortable: true, label: this.$t('Shared.Address2'), class: 'text-rigth' },
        { key: 'city', sortable: true, label: this.$t('Shared.City'), class: 'text-rigth' }, 
        { key: 'state', sortable: true, label: this.$t('Shared.State'), class: 'text-rigth',formatter: this.formatPhone },    
        { key: 'zipcode', sortable: true, label: this.$t('Shared.Zip'), class: 'text-rigth' },     
               
        ],
      pageOptions: [5, 10, 15 ],
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      filter: null,
      filterOn: [],
      items: [],
      selected: [],
      Header:{}, 
      isReadOnly: false,
      destination:[],
      disableAddAllergies: false
    }
  },

  computed: {
      sortOptions() {       
        return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          });
      }    
  },
    
  methods: {

    CanView(){
      return this.permission[0].roleCanView;
    },

    formatPhone(value){
      if(value && value.length >= 10){
         var cleaned = ('' + value).replace(/\D/g, '')
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
        if (match) {
          return '(' + match[1] + ')' + match[2] + '-' + match[3]
        }
      }else{
        return "";
      }
     
    },

    AddBuilding(){
      this.Mode = "Add";
      this.emitDataforFavorites();    
      this.$router.push({ name: "buildings-add"}).catch(()=>{});
    },

    SearchBuilding(){
      this.$emit('load', true);
      this.items = [];
      this.currentPage = 1;
      this.parameter.PageNumber = 1;
      this.totalRows = this.items.length;
      this.getBuildingListing();
    },

    EditBuilding(row){
       this.code = row.code;
       this.Mode = "Edit";
       this.$router.push({ name: "buildings-details", params: { id: this.code } });       
    },


    RemoveBuilding(row){
      const Id = row.code
      this.$bvModal.msgBoxConfirm(this.$t('Msg.DeleteRecord'), {
					title: this.$tc('Shared.DelRecord'),
					okTitle: this.$t('Shared.TitleDelete'),
					cancelTitle: this.$t('Shared.BtnCancel'),
					footerClass: 'p-2',
					titleClass: 'modal-title modal-type-warning',
          id:'modal_delete_Building'
				})
          .then(value => {
            if(value){
              this.$emit('load', true);
                BuildingService.Delete(Id)
                .then(() => {
                  const index = this.items.findIndex(item => item.code === Id) // find the post index 
                  if (~index) {// if the post exists in array 
                    this.items.splice(index, 1) //delete the post and refresh view
                    this.getBuildingListing(); //refresh listing
                  }
                })
                .catch((error) => {            
                   if (!error.response) {
                      this.showMessage(this.$t('Shared.Building'),this.$t('Msg.retrieveInfo'),"error");
                    }                      
                    else if(error.response.status === 400 ){
                      this.showMessage(this.$t('Shared.Building'),error.response.data.errorMessage,"error");
                    } else if(error.response.status === 500){
                        this.showMessage(this.$t('Shared.Building'),this.$t('Msg.ThereWereSomeError'),"error");
                     } else{
                         this.showMessage(this.$t('Shared.Building'),error.response.data.errorMessage,"error");
                     } 
                    this.$emit('load', false);
                });
            }
          })
    },

    getBuildingListing(){
      this.$emit('load', true); 
      this.parameter.PageNumber = this.currentPage;           
      this.parameter.PageSize = this.perPage;      
      BuildingService.getAll(this.parameter)
        .then((response) => {
            if(response.status == '200' && response.data){
              this.items = response.data;                        
              this.$emit('load', false);
              let headerString = response.headers["x-pagination"];
              this.totalRows = JSON.parse(headerString).TotalCount;
              }               
        })
        .catch((error) => {                    
            if (!error.response) {
              this.showMessage(this.$t('Shared.Building'),this.$t('Msg.retrieveInfo'),"error");
            }                      
            else if(error.response.status === 400 ){
              this.showMessage(this.$t('Shared.Building'),error.response.data.errorMessage,"error");
            }
            else if(error.response.status === 405 ){             
                  this.$router.push({name: "Main"});    
                    setTimeout(() => 
                        this.showPermissionMessage()
                        , 50);            
            } 
            else if(error.response.status === 500){
              this.showMessage(this.$t('Shared.Building'),this.$t('Msg.ThereWereSomeError'),"error");
            } else{
              this.showMessage(this.$t('Shared.Building'),error.response.data.errorMessage,"error");
            } 
            this.$emit('load', false);            
        });  
    },

    pageChanged(page) {            
      this.currentPage = page;    
      this.getBuildingListing();  
    },

    PerPageFunction(perpage){
      this.perPage = perpage;  
      this.currentPage = 1;
      this.getBuildingListing();  
    },

    onRowSelected(items) {    
     this.selected = items;
    },

    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
    },

    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },

    selectThirdRow() {
      this.$refs.selectableTable.selectRow(2)
    },

    unselectThirdRow() {
      this.$refs.selectableTable.unselectRow(2)
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    }
  },

  created(){    
    this.getPermissionLeftMenu(this.Menu,this.$route.path,this.permission);  
  },

   mounted() {
    // this.$emit('load', true);     
      this.OptionsRightMenu.ItemsList = [];
       let layoutStatus = this.$store.state.layoutStatus;
       switch(layoutStatus) {
        case 'L0R1':
          this.OptionsRightMenu.favButtonAtion = false;
          this.$emit('getVisibleMenu', this.OptionsRightMenu);
        break;
        case 'L1R0':
        case 'L1R1':
            this.OptionsRightMenu.btnvisible = false;
            this.$emit('getVisibleMenu', this.OptionsRightMenu);
        break;
        default:
            this.OptionsRightMenu.favButtonAtion = false;
            this.$emit('getVisibleMenu', this.OptionsRightMenu);
      }
    this.getBuildingListing();   
  },
};

</script>