<template>
   <div>
    <b-modal :id="modalId" size="md" centered scrollable  :title="TitleWindow" @ok="this.emitEventOK" @cancel="this.emitEventCancel" @hide="resetModal"> 
      <b-row style="width:450px;">      
        <b-col lg="6" md="6" sm="12">
             <MdSelect
                v-bind:Label="$t('modalEmployeeDocument.DocName')"
                v-bind:Options="DocumentNameList"
                v-bind:Model="DocumentNameModel"
                v-bind:FieldDescription="'name'"
                v-bind:FieldCode="'code'"
                :IsDisabled="false"
                v-bind:Multiple="false"             
                :Input="ChangeDropDownDocumentName"/>
        </b-col> 
        <b-col lg="6" md="6" sm="12">
            <MdEdit 
                v-bind:Label="$t('PatientDocument.ExpirationDate')"
                v-bind:TypeProp="'date'" 
                v-bind:Name="'ExpirationDate'"
                v-bind:Min="'1900-01-01'"
                v-bind:Max="'2200-12-31'"
                v-model="ExpirationDate"
                v-bind:isReadOnly="false"
                v-bind:Id="'employee_expirationDate'">                                     
            </MdEdit>
        </b-col>       
      </b-row>
      <b-row style="width:450px;">      
        <b-col lg="12" md="12" sm="12">
            <MdMemo
                  v-bind:Name="$t('Shared.Description')"
                  v-bind:Rows="'3'"
                  v-bind:MaxRows="'6'"
                  v-bind:MaxLength="'500'"
                  v-model="Description"
                  v-bind:isReadOnly="false"
                  />   
        </b-col> 
            
      </b-row>
    </b-modal>
   </div>
</template>

<script>
export default {
  name: 'ModalEmployeeDocument',
   props: {
     ModalId: String,
     TitleWindow:String
   },
   data(){
       return { 
          modalId:this.ModalId, 
          DocumentNameList:[],
          DocumentNameModel:"", 
          ExpirationDate:"", 
          Description:"",        
       }
   },

   methods:{

    emitEventOK(){      
       this.$emit('getCodeConditionOK', {Document:this.DocumentNameModel,Description:this.Description});
    },
  
    emitEventCancel(){

    },

    ChangeDropDownDocumentName(){

    },

    resetModal(){

    },

   },

   mounted(){
 
      this.$root.$on('bv::modal::show', () => {     
        this.$nextTick(() => {
          setTimeout(500);
        });
      });
   }
   
};
</script>