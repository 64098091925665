import ApiService from '@/services/api.service'


const IncidentSetupService = {
    getAll( parameter) { 
         
      const requestData = {
        method: 'get',
        url: "/IncidentSetups/",
        headers: {
        },
        params: { 
          SearchCriteria: parameter.SearchCriteria,
          PageSize: parameter.PageSize,          
          PageNumber : parameter.PageNumber,
          Code : parameter.Code
        }
      }     
      return ApiService.customRequest(requestData);         
    },
  
    get(id) {
      return ApiService.get(`/IncidentSetups/${id}`);
    },

    add(data){
      return ApiService.post(`/IncidentSetups`,data)
    },

    delete(id){
      return ApiService.delete(`/IncidentSetups/${id}`)
    },
  
    update(Code,IncidentSetup){
      return ApiService.put(`/IncidentSetups/${Code}`,IncidentSetup)
    },
    
    getIncidentReportReporter() {
      return ApiService.get(`/IncidentSetups/IncidentReportReporter`);
    },

    getIncidentReportSeverity() {
      return ApiService.get(`/IncidentSetups/IncidentReportSeverity`);
    },

    getIncidentReportBuilding() {
      return ApiService.get(`/IncidentSetups/IncidentReportBuilding`);
    },
  }
  
  export default IncidentSetupService;