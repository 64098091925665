import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
var ls = new SecureLS({ encodingType: "base64", isCompression: true });
Vue.use(Vuex)

const store = new Vuex.Store({

    state: {
      isUseASAM: false,
      isUsePatientInfo: false,
      isUsePatientInsurance: false,
      isUsePatientDocument: false,
      isUsePatientContact: false,
      isUseClinicalNotes: false,
      isUseVitalSign: false,
      isUsePatientAllergies: false,
      isUsePatientAdmission:false,
      isUseChartReview:false,
      isUseTransfer:false,
      isUseMedications:false,
      isUseDischarge:false,
      isUsePatientDiagnosis:false,
      isUseDailyNotes:false,
      isUseLogNotes:false,
      isUsepharmacyPatientNewCrop:false,
      isUseCompleteDraft:false,
      isUseSchedulerClinical:false,
      isAppointments:false,
      isUseTreatmentPlanClinical:false,
      isEPrescribingClinical:false,

      isASAMEHRReadOnly: false,
      isPatientInfoEHRReadOnly: false,
      isPatientInsuranceEHRReadOnly: false,
      isPatientContactsEHRReadOnly: false,
      isClinicalNotesEHRReadOnly: false,
      isVitalSignEHRReadOnly: false,
      isPatientAllergiesEHRReadOnly: false,
      isPatientAdmissionEHRReadOnly: false,
      isClinicalTransferEHRReadOnly:false,
      isClinicalMedicationsEHRReadOnly:false,
      isClinicalDischargeEHRReadOnly:false,
      isPatientDiagnosisEHRReadOnly:false,
      isDailyNotesEHRReadOnly:false,
      isPatientDocumentEHRReadOnly:false,
      isLogNotesEHRReadOnly:false,
      isSchedulerClinicalEHRReadOnly:false,
      isAppointmentsEHRReadOnly:false,
      isTreatmentPlanClinicalEHRReadOnly:false,
      isEPrescribingClinicalEHRReadOnly:false,

      ethnicityList: [],
      ethnicitywasloaded:false,
      genderList: [],
      genderwasloaded:false,
      raceList: [],
      racewasloaded:false,      
      eyeColorList: [],
      eyeColorwasloaded:false,
      hairColorList: [],
      hairColorwasloaded:false,
      commPreferenceList:[],
      commPreferencewasloaded:false,
      familyIncomeList: [],
      familyIncomewasloaded:false,
      personalIncomeList: [],
      personalIncomewasloaded:false,
      majorLanguageList: [],
      majorLanguagewasloaded:false,
      majorLanguageAbilityList: [],
      majorLanguageAbilitywasloaded:false,
      majorReligionList: [],
      majorReligionwasloaded:false,
      disabilityList: [],
      disabilitywasloaded:false,
      legalStatusList: [],
      legalStatuswasloaded:false,
      educationStatusList: [],
      educationStatuswasloaded:false,

      ProvidersList:[],
      reasonDataSource:[],

      clinicalDashboardProgramsSelected:[],
      itemListGroupNotes:[],
      itemListCNContainer:[],

      sourceList:[],
      sourcewasloaded:false,
      classList:[],
      classwasloaded:false,
      typeList:[],  
      typewasloaded:false, 
      vocationalEffortList:[],
      vocationalEffortwasloaded:false, 
      vocationalCategoryList:[],
      vocationalCategorywasloaded:false,
      assessmentList:[],
      assessmentwasloaded:false,
      livingWithList:[],   
      livingWithwasloaded:false,
      referralsList:[],
      referralswasloaded:false,
      fromList:[],
      fromwasloaded:false,
      trackList:[],
      trackwasloaded:false,

      GroupNotesFilters:[],
      
      statusList:[],

      leftMenu:[],
      companies: '',
      employeeCode: '',
      providerCode: '',
      provider: '',
      access_token: '',
      IsVip:false,
      apiurl:'',
      layoutStatus: '',
      isMovile: false,
      m24Format: true,
      mDateFormat: 1,

      //IncidentDB
      incidentDBDateFrom: null,
      incidentDBDateTo: null,
      incidentDBCategory: {},
      incidentDBReporter: {},
      incidentDBSeverity: {},
      incidentDBRoom: {},
      incidentDBStatus: {},

      //Group Notes
      groupNotesProgramSelected: {},
      // Favorites
      menuItemSelected:{},
      btnFavoriteState: false,

      // Scheduler Editor vars
      schEdipatientCode: '',
      schEdiActivityId: ''
    },

    mutations: {
      setCompanies (state,value) { state.companies = value },
      setEmployeeCode (state,value) { state.employeeCode = value },
      setProviderCode (state,value) { state.providerCode = value },
      setProvider (state,value) { state.provider = value },
      setAccessToken (state,value) { state.access_token = value },
      setVip (state,value) { state.IsVip = value },
      setApiUrl (state,value) { state.apiurl = value },
      setlayoutStatus (state,value) { state.layoutStatus = value },
      setMovile (state,value) { state.isMovile = value },
      setClockTime (state,value) { state.m24Format = value },
      setClockDate (state,value) { state.mDateFormat = value },
      
      //EHR visibility of the components
      setUseASAM (state,value) { state.isUseASAM = value },
      setUseEPharmacyNewCrop (state,value) { state.isUsepharmacyPatientNewCrop = value },     
      setUsePatientInfo (state,value) { state.isUsePatientInfo = value },
      setUsePatientInsurance (state,value) { state.isUsePatientInsurance = value },
      setUsePatientDocument (state,value) { state.isUsePatientDocument = value },
      setUsePatientContact(state,value) { state.isUsePatientContact = value },
      setUseClinicalNotes (state,value) { state.isUseClinicalNotes = value },
      setUseVitalSign (state,value) { state.isUseVitalSign = value },
      setUsePatientAllergies (state,value) { state.isUsePatientAllergies = value },
      setUsePatientAdmission (state,value) { state.isUsePatientAdmission = value },
      setUseChartReview (state,value) { state.isUseChartReview = value },
      setUseTransfer (state,value) { state.isUseTransfer = value },
      setUseMedications (state,value) { state.isUseMedications = value },
      setUseDischarge (state,value) { state.isUseDischarge = value },
      setUsePatientDiagnosis (state,value) { state.isUsePatientDiagnosis = value },
      setDailyNotes (state,value) { state.isUseDailyNotes = value },
      setLogNotes (state,value) { state.isUseLogNotes = value },
      setCompleteDraft (state,value) { state.isUseCompleteDraft = value },
      setSchedulerClinical (state,value) { state.isUseSchedulerClinical = value },
      setAppointments (state,value) { state.isAppointments = value },
      setTreatmentPlanClinical (state,value) { state.isUseTreatmentPlanClinical = value },
     
      
      //EHR Read-only of the components
      setASAMEHRReadOnly (state,value) { state.isASAMEHRReadOnly = value },
      setPatientInfoEHRReadOnly (state,value) { state.isPatientInfoEHRReadOnly = value },
      setPatientInsuranceEHRReadOnly (state,value) { state.isPatientInsuranceEHRReadOnly = value },
      setPatientContactsEHRReadOnly (state,value) { state.isPatientContactsEHRReadOnly = value },
      setClinicalNotesEHRReadOnly (state,value) { state.isClinicalNotesEHRReadOnly = value },
      setVitalSignEHRReadOnly (state,value) { state.isVitalSignEHRReadOnly = value },
      setPatientAllergiesEHRReadOnly (state,value) { state.isPatientAllergiesEHRReadOnly = value },
      setPatientAdmissionEHRReadOnly (state,value) { state.isPatientAdmissionEHRReadOnly = value },
      setClinicalTransferEHRReadOnly (state,value) { state.isClinicalTransferEHRReadOnly = value },
      setClinicalMedicationsEHRReadOnly (state,value) { state.isClinicalMedicationsEHRReadOnly= value },
      setClinicalDischargeEHRReadOnly (state,value) { state.isClinicalDischargeEHRReadOnly = value },
      setPatientDiagnosisEHRReadOnly (state,value) { state.isPatientDiagnosisEHRReadOnly = value },
      setDailyNotesEHRReadOnly (state,value) { state.isDailyNotesEHRReadOnly = value },
      setPatientDocumentEHRReadOnly (state,value) { state.isPatientDocumentEHRReadOnly = value },
      setLogNotesEHRReadOnly (state,value) { state.isLogNotesEHRReadOnly = value },
      setSchedulerClinicalEHRReadOnly (state,value) { state.isSchedulerClinicalEHRReadOnly = value },
      setAppointmentsEHRReadOnly (state,value) { state.isAppointmentsEHRReadOnly = value },
      setTreatmentPlanEHRReadOnly (state,value) { state.isTreatmentPlanClinicalEHRReadOnly = value },
      setEPrescribingEHRReadOnly (state,value) { state.isEPrescribingClinicalEHRReadOnly = value },
      
      //IncidentDB
      setIncidentDBDateFrom (state,value) { state.incidentDBDateFrom = value },
      setIncidentDBDateTo (state,value) { state.incidentDBDateTo = value },
      setIncidentDBCategory (state,value) { state.incidentDBCategory = value },
      setIncidentDBReporter (state,value) { state.incidentDBReporter = value },
      setIncidentDBSeverity (state,value) { state.incidentDBSeverity = value },
      setIncidentDBRoom (state,value) { state.incidentDBRoom = value },
      setIncidentDBStatus (state,value) { state.incidentDBStatus = value },

      setGroupNotesFilters(state,value){      
        state.GroupNotesFilters = value
      },
      setGroupNotesFiltersInitialize(state,value){      
        state.GroupNotesFilters = value;
      },

      //Ethnicity List
      initEthnicityList(state){
        state.ethnicityList = []
      },
      setEthnicityList (state,value) { 
        state.ethnicityList.push(value)
      },

      setethnicitywasloaded(state,value){
        state.ethnicitywasloaded = value;
      },
      //Gender List
      initGenderList(state){
        state.genderList = []
      },
      setGenderList (state,value) { 
        state.genderList.push(value)
      },
      setGenderwasloaded(state,value){
        state.genderwasloaded = value;
      },
      //Race List
      initRaceList(state){
        state.raceList = []
      },
      setRaceList (state,value) { 
        state.raceList.push(value)
      },     
      setracewasloaded(state,value){
        state.racewasloaded = value;
      },
      //Eye Color List
      initEyeColorList(state){
        state.eyeColorList = []
      },
      setEyeColorList (state,value) { 
        state.eyeColorList.push(value)
      },     
      seteyeColorwasloaded(state,value){
        state.eyeColorwasloaded = value;
      },
      //Hair Color List
      initHairColorList(state){
        state.hairColorList = []
      },
      setHairColorList (state,value) { 
        state.hairColorList.push(value)
      },      
      sethairColorwasloaded(state,value){
        state.hairColorwasloaded = value;
      },
      //Comm Preference List
      initCommPreferenceList(state){
        state.commPreferenceList = []
      },
      setCommPreferenceList (state,value) { 
        state.commPreferenceList.push(value)
      },      
      setcommPreferencewasloaded(state,value){
        state.commPreferencewasloaded = value;
      },
      //Family Income List
      initFamilyIncomeList(state){
        state.familyIncomeList = []
      },
      setFamilyIncomeList (state,value) { 
        state.familyIncomeList.push(value)
      },
      //familyIncomewasloaded
      setfamilyIncomewasloaded(state,value){
        state.familyIncomewasloaded = value;
      },
      //Personal Income List
      initPersonalIncomeList(state){
        state.personalIncomeList = []
      },
      setPersonalIncomeList (state,value) { 
        state.personalIncomeList.push(value)
      },
      //
      setpersonalIncomewasloaded(state,value){
        state.personalIncomewasloaded = value;
      },
      //Major Language List
      initMajorLanguageList(state){
        state.majorLanguageList = []
      },
      setMajorLanguageList (state,value) { 
        state.majorLanguageList.push(value)
      },      
      setmajorLanguagewasloaded(state,value){
        state.majorLanguagewasloaded = value;
      },
      //Major Language Ability List
      initMajorLanguageAbilityList(state){
        state.majorLanguageAbilityList = []
      },
      setMajorLanguageAbilityList (state,value) { 
        state.majorLanguageAbilityList.push(value)
      },
      //
      setmajorLanguageAbilitywasloaded(state,value){
        state.majorLanguageAbilitywasloaded = value;
      },
      //Major Religion List
      initMajorReligionList(state){
        state.majorReligionList = []
      },
      setMajorReligionList (state,value) { 
        state.majorReligionList.push(value)
      },     
      setmajorReligionwasloaded(state,value){
        state.majorReligionwasloaded = value;
      },
      //Disability List
      initDisabilityList(state){
        state.disabilityList = []
      },
      setDisabilityList (state,value) { 
        state.disabilityList.push(value)
      },
      //
      setdisabilitywasloaded(state,value){
        state.disabilitywasloaded = value;
      },
      //Legal Status List
      initLegalStatusList(state){
        state.legalStatusList = []
      },
      setLegalStatusList (state,value) { 
        state.legalStatusList.push(value)
      },
      //
      setlegalStatuswasloaded(state,value){
        state.legalStatuswasloaded = value;
      },
      //Education Status List
      initEducationStatusList(state){
        state.educationStatusList = []
      },
      setEducationStatusList (state,value) { 
        state.educationStatusList.push(value)
      },
      //
      seteducationStatuswasloaded(state,value){
        state.educationStatuswasloaded = value;
      },
      setProviderList (state, value){
        state.ProvidersList = value;
      },
      setReasonDataSource (state,value) { 
        state.reasonDataSource = value 
      },

      setclinicalDashboardProgramsSelected(state,value){
        state.clinicalDashboardProgramsSelected = value;
      },

      setItemListGroupNotes(state,list){       
        state.itemListGroupNotes = list;
      },

      setItemListCNContainer(state,list){
        state.itemListCNContainer = list;
      },


      // Admission       
      initSourcesList(state){
        state.sourceList = []
      },
      setSourceList(state,value){ 
        state.sourceList.push(value)
      },      
      setsourcewasloaded(state,value){
        state.sourcewasloaded = value;
      },

      initTypeList(state){
        state.typeList = []
      },
      setTypeList(state,value){ 
        state.typeList.push(value)
      },      
      settypewasloaded(state,value){
        state.typewasloaded = value;
      },

      initClassList(state){
        state.classList = []
      },
      setClassList(state,value){ 
        state.classList.push(value)
      },    
      setclasswasloaded(state,value){
        state.classwasloaded = value;
      },

      initVocationalEffortList(state){
        state.vocationalEffortList = []
      },
      setVocationalEffortList(state,value){ 
        state.vocationalEffortList.push(value)
      },      
      setvocationalEffortwasloaded(state,value){
        state.vocationalEffortwasloaded = value;
      },

      initVocationalCategoryList(state){
        state.vocationalCategoryList = []
      },
      setVocationalCategoryList(state,value){ 
        state.vocationalCategoryList.push(value)
      },      
      setvocationalCategorywasloaded(state,value){
        state.vocationalCategorywasloaded = value;
      },
    
     initFromList(state){
        state.fromList = []
      },
      setFromList(state,value){ 
        state.fromList.push(value)
      },      
      setfromwasloaded(state,value){
        state.fromwasloaded = value;
      },
      
      initLivingWithList(state){
        state.livingWithList = []
      },
      setLivingWithList(state,value){ 
        state.livingWithList.push(value)
      },
      //
      setlivingWithwasloaded(state,value){
        state.livingWithwasloaded = value;
      },
     
      initStatusList(state){
        state.statusList = []
      },
      setStatusList(state,value){ 
        state.statusList.push(value)
      },
     
      initAssessmentList(state){
        state.assessmentList = []
      },
      setAssessmentList(state,value){ 
        state.assessmentList.push(value)
      }, 
      setassessmentwasloaded(state,value){
        state.assessmentwasloaded = value;
      },

      initTrackList(state){
        state.trackList = []
      },
      setTrackList(state,value){ 
        state.trackList.push(value)
      },  
      settrackwasloaded(state,value){
        state.trackwasloaded = value;
      },      
      initReferralsList(state){
        state.referralsList = []
      },
      setReferralsList(state,value){ 
        state.referralsList.push(value)
      },     
      setreferralswasloaded(state,value){
        state.referralswasloaded = value;
      },


      initLeftMenu(state){
        state.leftMenu = []
      },
      setLeftMenu(state,value){ 
        state.leftMenu = value;
      },

      setgroupNotesProgramSelected(state,value){
        state.groupNotesProgramSelected = value;
      },

      // Favorites
      setMenuItemSelected(state,value){      
        state.menuItemSelected = value;
      },
          
      setBtnFavoriteState(state,value){
        state.btnFavoriteState = value;
      },

      //Scheduler Editor Sets
      setSchEdipatientCode(state,value){
        state.schEdipatientCode = value;
      },
      setSchEdiActivityId(state,value){
        state.schEdiActivityId = value;
      },  
    },

    plugins: [
      createPersistedState({
        storage: {
          getItem: (key) => ls.get(key),
          setItem: (key, value) => ls.set(key, value),
          removeItem: (key) => ls.remove(key),
        },
      }),
    ],
  })

  export default store