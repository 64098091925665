import ApiService from '@/services/api.service'

const PharmacyOptionService = {

    getAll() {          
        const requestData = {
            method: 'get',
            url: "/samPharmacyOptions",
            headers: {
                
              },
            params: { 
               
            }
        }
        return ApiService.customRequest(requestData);
    },    

    getPharmacyOptions(){
        return ApiService.get(`/samPharmacyOptions`);
    },
    
}
  
  export default PharmacyOptionService;