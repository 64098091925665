<template>
    <fragment>
        <b-form-group :label="Label">
            <b-form-radio-group
                :options="Options"
                :name="Name"
                :disabled="isReadOnly"
                v-bind:checked=value
                v-on:change="OnChange"
                :stacked="Stacked"
                >
            </b-form-radio-group>
        </b-form-group>
    </fragment>
</template>

<script>
import { EventBus } from '@/event-bus.js';
export default {
  name: 'MdRadioGroup',
   props: {
     Label:     String,
     Name:      String,
     Options:   Array,
     isReadOnly:Boolean,
     value:String,
     Stacked:Boolean,
     Tag: String
   }, 
   data(){
     return{
     }
   },

    methods:{
    OnChange(value){
      this.$emit('change', value);
      this.$emit('input', value);
      var changed = {
        "Changed":true,
        "ComponentName":this.Name,
        "Tag": this.Tag
      }
      EventBus.$emit('onChanged', changed);
    },
  },
};
</script>


