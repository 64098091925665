<template>
    <div>
        <a :class="{ 'isDisabled': itemdisabled, rmActiveItem : active_el == it.value  }" v-for="it in this.ItemsListAuthorized" :key="it.value" @click="triggerClick(it.value,it.text,it.href, $event)">        
                 {{it.text}}    
        </a>        
    </div>   
</template>

<script>
//import Enum from '@/services/enum.service'
import ShowMessage from '@/components/messages/ShowMessage.js'
import LocalStorage from '@/services/storage.service'
import AppointmentService from '@/services/appointment.service'
import ApptCheckinsService from '@/services/appointmentcheckins.service'

import DBFunctions from '@/common/DBFunctions.js'
import { EventBus } from '@/event-bus.js';

export default {
  name: 'Item',
  mixins: [ShowMessage, DBFunctions],
 data(){
    return{
        ItemsList:[],      
        Class:'',       
        itemdisabled : false,
        Appointment:{},
        CheckInsAppt:{
            timeCheckIn:"",
        },
      
        PatientCode : '',
        ItemsListAuthorized:[],
        parameter: {
          UserId: "",
        },
        active_el:'',
        message:this.$t('Layout.displayedScreen')
    }
  },
 
  methods: {    
  
    triggerClick: function (eventName,text,href, event){        
        if(event.target.className != 'isDisabled'){
            this.active_el = eventName;
             // Favorites Actions
            this.checkExistFavorite(href);
            EventBus.$emit('onUpdatedMenu', '');

            // Mapping Functions
            const actionsMap = {
                'PatientSearch': this.GotoPatientList.bind(this),
                'AppointmentList': () => this.launchPage(this.$t('MdNotesByAppt.Appointment'), 'appointments', true),
                'SchedulerNotes': this.GotoSchedulerNotes.bind(this),
                'Activities': this.GotoActivities.bind(this),
                'ShowUpAppt': () => this.ShowUpAppt(text),
                'ConfirmAppt': () => this.ConfirmAppt(text),
                'CancelAppt': () => this.OpenAppointmentReason(text),
                'PatientInfo': () => {
                    this.GotoPatientInfo();
                    this.isActive = !this.isActive;
                },
                'GeneralNotes': this.GotoGeneralNotes.bind(this),
                'PatientAsam': () => this.launchPage(this.$t('Asam.ASAM'), 'patient-asam', true),
                'PatientContact': () => this.launchPage(this.$tc('Contact.PatientContact', 2), 'patient-contacts', true),
                'PatientInsurance': () => this.launchPage(this.$tc('Insurance.Insurance'), 'patient-insurance', true),
                'PatientDocument': () => this.launchPage(this.$t('employee.Document'), 'patient-document', true),
                'ClinicalNotes': () => this.launchPage(this.$t('ClinicalNotes.ClinicalNotes'), 'Clinical-Notes'),
                'PatientListClinical': this.GotoClinical.bind(this),
                'ClinicalAsam': () => this.launchPage(this.$t('Asam.ASAM'), 'Clinical-Asam'),
                'PatientAllergy': () => this.launchPage(this.$t('Allergy.PatientAllergies'), 'patient-allergies', true),
                'ClinicalVitalSign': () => this.launchPage(this.$t('vitalSignsRep.ReportTitle'), 'Clinical-vital-sign'),
                'Medications': () => this.launchPage(this.$t('Medication.Medications'), 'Clinical-Medications'),
                'Admission': () => this.launchPage(this.$t('Admission.Admission'), 'patient-admissionList', true),
                'CHARTREVIEWCLINICAL': () => this.launchPage(this.$t('ChartReview.CHARTREVIEW'), 'Clinicalchartreview'),
                'ePharmacyPatient': () => this.launchPage(this.$t('ePharmacyBase.ePharmacyTitle'), 'patientePharmacy', true),
                'ePharmacyClinical': () => this.launchPage(this.$t('ePharmacyBase.ePharmacyTitle'), 'clinicalePharmacy', false),
                'CHARTREVIEWPATIENT': () => this.launchPage(this.$t('ChartReview.CHARTREVIEW'), 'patientchartreview', true),
                'Transfer': () => this.launchPage(this.$t('transfer.Transfer'), 'Clinical-Transfer'),
                'Discharge': () => this.launchPage(this.$t('discharge.Discharge'), 'Clinical-Discharge'),
                'Diagnosis': () => this.launchPage(this.$t('MdPatientDiagCodes.Diagnosis'), 'patient-diagnosis', true),
                'DailyNotes': () => this.launchPage(this.$t('DailyNoteList.DailyNotesTitle'), 'Clinical-daily-note', true),
                'LogNotes': () => this.launchPage(this.$t('LogNoteList.LogNotesTitle'),'Clinical-log-note', true),
                'ClaimBatchList': this.GotoClaimBatchList.bind(this),
                'DiagnosisClinical': () =>  this.launchPage(this.$t('MdPatientDiagCodes.Diagnosis'),'Clinical-diagnosis', false),
                'InsuranceClinical': () =>  this.launchPage(this.$tc('Insurance.Insurance'),'Clinical-insurance', false),
                'AllergyClinical': () =>  this.launchPage(this.$t('Allergy.PatientAllergies'),'Clinical-allergies', false),
                'incident-categories': () =>  this.launchPage(this.$t('ClinicalNotes.CATEGORIES'), 'incident-categories'),
                'DocumentClinical': () =>   this.launchPage(this.$t('employee.Document'),'Clinical-document', false),
                'ContactClinical': () =>   this.launchPage(this.$tc('Contact.Contact'),'Clinical-cont', false),
                'ADMISSIONINFOCLINICAL': () =>   this.launchPage(text,'admission-clinical', true),
                'WYTREATMENTPLAN': () =>    this.launchPage(text,'WyTreatmentplan-Clinical', false),
                'MDTREATMENTPLAN': () =>    this.launchPage(text,'MdTreatmentplan-Clinical', false),
                'SchedulerClinical': () =>    this.launchPage(text,'SchedulerClinical', false),
                'Appointments': () =>    this.launchPage(text,'patient-appoint', false),
                'ClinicalAppointments': () =>    this.launchPage(text,'clinical-appoint', false),
            };
            // Execute mapped actions, if exist
            const menuAction = actionsMap[eventName];
            if (menuAction) {
                menuAction(); // call action
            }
        }
    },
    // Helper function to display toast
    showToast(titleKey, msgKey, variant = "success") {
        this.$bvToast.toast( msgKey, {
            title: titleKey,
            autoHideDelay: 4000,
            variant: variant
        });
    },

    handleError(error) {
        if (!error.response) {               
            this.showMessage(this.$t('MdNotesByAppt.Appointment'),this.$t('Msg.RetrieveInformation'),"error");
        } else {
            this.showMessage(this.$t('MdNotesByAppt.Appointment'),error,"error");
        }
    },

    async GetPermission(){  
        if(this.$route.name.includes('appointment')){
            this.getCheckinsByActivityId();
            this.Appointment = await this.getAppointmentByActId(this.$route.params.actId); 
            !this.Appointment.private ? EventBus.$emit('onVisibleApptType', false) : EventBus.$emit('onVisibleApptType', true);           
        }
        this.GetItemsListAuthorized(); 
    },
          
    GotoPatientList(){
       this.$router.push('/app/patients').catch(()=>{});
    },

    GotoActivities(){        
        this.$router.push({ name: "schedulerdb-activitiesList", params: { id: this.$route.params.id,actId:this.$route.params.actId } }).catch(()=>{});
    },

    OpenAppointmentReason(text){    
        if(text === this.$t('Appointments.cancelAppt')){
            if(!this.Appointment.private){
                EventBus.$emit('onAppointmentReasonModal', text);   
            }else{
                this.showToast(this.$t('MdNotesByAppt.Appointment'),this.$t('Appointments.noCancelMsg'));
            }
        } else{
            this.CancelAppt(text,'');
        }
    },

    GotoGeneralNotes(){
      if(this.$route.params.id != ''){
        this.PatientCode = this.$route.params.id;
      }
      this.$router.push({ name: "patient-generalnotes", params: { id: this.PatientCode } }).catch(()=>{});
    },

    GotoSchedulerNotes(){   
      this.$router.push({ name: "scheduler-appointment-notes", params: { id: this.$route.params.id,actId:this.$route.params.actId } }).catch(()=>{});
    },

    GotoPatientInfo() {
        const { id, recordid } = this.$route.params;

        if (id) { this.PatientCode = id; }// Assign PatientCode only if id exists

        const pRouteNames = ['patient', 'contact', 'allergy'];
        const cRouteNames = ['Clinical', 'vs', 'lognote-add', 'dailynote-add'];
        // Handle patient-related routes
        if (pRouteNames.some(route => this.$route.name.includes(route))) {
            if (this.$router.currentRoute.name === 'patient-details') {
                this.showToast(
                    this.$t('Shared.Details'),
                    this.message
                );
                return; // Early return to prevent further processing
            } 
            this.$router.push({ name: 'patient-details', params: { id: this.PatientCode } });
            return; // Early return
        }
        // Handle clinical-related routes
        if (cRouteNames.some(route => this.$route.name.includes(route))) {
            if (this.$router.currentRoute.name === "Clinical-PatientInfo") {
                this.showToast(
                    this.$t('MdPatientInfoBox.PatientInfo'),
                    this.message
                );
                return; // Early return
            }
            this.$router.push({ name: "Clinical-PatientInfo", 
                params: { 
                    id: this.PatientCode, 
                    recordid: recordid 
                } 
            }).catch(() => {});
            return; // Early return
        }
    },

    GotoSchedulerDB(){       
        this.$router.push('/app/appointments').catch(()=>{});
    },

    GotoClinical(){
       this.$router.push('/app/clinical').catch(()=>{});
    },

    ExecuteShowUp(param,text){
        AppointmentService.ShowUp(param)
            .then((response) => {
                if(response.status == '200'){
                    this.GetPermission();
                    if(text === this.$t('Appointments.uShowUp')){ 
                        this.showToast(
                            this.$t('MdNotesByAppt.Appointment'),
                            this.$t('Appointments.uShowUpMsg')
                        );
                    } else {
                        this.showToast(
                            this.$t('MdNotesByAppt.Appointment'),
                            this.$t('Appointments.showUpMsg')
                        );
                    }
                }
            })
            .catch(error => {
                this.handleError(error);
            });      
    },

    getCheckinsByActivityId(){
        var param = {
            Code:this.$route.params.actId
        }
        ApptCheckinsService.getAll(param)
            .then((response) => {
                if(response.status == '200' && response.data.length > 0){
                    this.CheckInsAppt = response.data[0];                   
                }
            })
            .catch(error => {
                this.handleError(error);
            });
    },

    ShowUpAppt(text){
        var param = {
            PatientCode : this.$route.params.id,
            ActivityId : this.$route.params.actId,
            Action:text,
            DoctorCode:this.Appointment.therapistcode,
        };

        if(text === this.$t('Appointments.uShowUp')){           
            param.State = "F";
            if( (this.CheckInsAppt.timeCheckIn === "") || (this.CheckInsAppt.timeCheckIn === null)){
                this.ExecuteShowUp(param,text);
            }else{
                 this.showToast(
                    this.$t('MdNotesByAppt.Appointment'),
                    this.$t('Appointments.pAlreadyMsg')
                );
            }            
        }
        else{
            param.State = "T";
            if( !this.Appointment.reason ) {// This checks for "", null, undefined, etc.
                this.ExecuteShowUp(param,text);
            } else {
                 this.showToast(
                    this.$t('MdNotesByAppt.Appointment'),
                    this.$t('Appointments.aCancelMsg'),
                    "danger"
                );
            }
        }
    },

    ConfirmAppt(text){
        var param = {           
            ActivityIdList: this.$route.params.actId, // must be List          
        };

        if(text === this.$t('Appointments.uconfirm')){           
            param.IsConfirmed = "F";
        }
        else{
            param.IsConfirmed = "T";
        }

        AppointmentService.ConfirmAppt(param)
            .then((response) => {
                if(response.status == '200'){
                    this.GetPermission();
                    if(text === this.$t('Appointments.uconfirm')){ 
                        this.showToast(
                            this.$t('MdNotesByAppt.Appointment'),
                            this.$t('Appointments.nConfirmMsg')
                        );
                    } else {
                        this.showToast(
                            this.$t('MdNotesByAppt.Appointment'),
                            this.$t('Appointments.confirmMsg')
                        );
                    }
                }
            })
            .catch(error => {
                this.handleError(error);
        });
    },

    CancelAppt(text,reason){
        var param = {           
            ActivityIdList: this.$route.params.actId, // must be List          
        };
        param.Reason = "";
        if(text === "Cancel"){           
            param.Reason = reason;
        }

        if (param.ActivityIdList) {
            AppointmentService.CancelAppt(param)
                .then((response) => {
                    if(response.status == '200'){
                        this.GetPermission();
                        if(text === this.$t('Appointments.uCancel')){ 
                            this.showToast(
                                this.$t('MdNotesByAppt.Appointment'),
                                this.$t('Appointments.cancelUnDoneMsg')
                            );
                        } else {
                            this.showToast(
                                this.$t('MdNotesByAppt.Appointment'),
                                this.$t('Appointments.cancelMsg')
                            );
                        }
                    }
                })
                .catch(error => {
                    this.handleError(error);
                });
        }
    },

    GotoClaimBatchList(){
      this.$router.push('/app/claimbatch').catch(()=>{});
    },

    launchPage(v_title, v_name, v_patient) {
        const { id, recordid } = this.$route.params; // Destructure for clarity

        if (id) { this.PatientCode = id; }

        if (this.$router.currentRoute.name === v_name) {
            // show toast if on the same route
            this.$bvToast.toast(this.message, {
                title: v_title,
                autoHideDelay: 4000,
                variant: "success",
            });
            return; // Early return
        } 
        // Construct params based on v_patient value
        const params = {
            id: this.PatientCode,
            ...(v_patient ? {} : { recordid }), // Include recordid only if v_patient is false
        };
        // Navigate to the new route
        this.$router.push({ name: v_name, params }).catch(() => {});
    },
    
    GetItemsListAuthorized(){
        this.ItemsListAuthorized = [];
        this.parameter.UserId = LocalStorage.getUserId(); 

        const addMenuItemConditionally = (element, condition = true, path) => {
            if (condition) {
                this.addMenuItem(element.text, element.value, path);
            }
        };
        this.ItemsList.forEach(element => { 
            switch (element.value) {
                case 'PatientSearch':
                    addMenuItemConditionally(element, true, '/app/patients');
                    break;
                case 'SchedulerNotes':
                    addMenuItemConditionally(element, this.Appointment.private);
                    break;
                case 'Activities':
                case 'AppointmentList':
                    addMenuItemConditionally(element, true);
                    break;
                case 'ShowUpAppt':
                    element.text = this.Appointment.private ?
                        this.$t('Appointments.uShowUp') :
                        this.$t('Appointments.showUp');
                    addMenuItemConditionally(element);
                    break;
                case 'CancelAppt':
                    element.text = this.Appointment.reason ?
                        this.$t('Appointments.uCancel') :
                        this.$t('Appointments.cancelAppt');
                    addMenuItemConditionally(element);
                    break;
                case 'ConfirmAppt':
                    element.text = this.Appointment.isConfirmed ?
                        this.$t('Appointments.uconfirm') :
                        this.$t('Appointments.confirm');
                    addMenuItemConditionally(element);
                    break;
                case 'PatientInfo':
                    addMenuItemConditionally(element, this.$store.state.isUsePatientInfo);
                    break;
                case 'PatientInsurance':
                    addMenuItemConditionally(element, this.$store.state.isUsePatientInsurance);
                    break;
                case 'PatientDocument':
                    addMenuItemConditionally(element, this.$store.state.isUsePatientDocument);
                    break;
                case 'PatientContact':
                    addMenuItemConditionally(element, this.$store.state.isUsePatientContact);
                    break;
                case 'PatientAsam':
                case 'ClinicalAsam':
                    addMenuItemConditionally(element, this.$store.state.isUseASAM);
                    break;
                case 'PatientListClinical':
                    addMenuItemConditionally(element, true, '/app/clinical');
                    break;
                case 'ClinicalNotes':
                    addMenuItemConditionally(element, this.$store.state.isUseClinicalNotes);
                    break;
                case 'PatientAllergy':
                    addMenuItemConditionally(element, this.$store.state.isUsePatientAllergies);
                    break;
                case 'ClinicalVitalSign':
                    addMenuItemConditionally(element, this.$store.state.isUseVitalSign);
                    break;
                case 'Medications':
                    addMenuItemConditionally(element, this.$store.state.isUseMedications);
                    break;
                case 'Admission':
                    addMenuItemConditionally(element, this.$store.state.isUsePatientAdmission);           
                    break;
                case 'CHARTREVIEWCLINICAL':
                case 'CHARTREVIEWPATIENT':
                    addMenuItemConditionally(element, this.$store.state.isUseChartReview);  
                    break; 
                case 'ePharmacyPatient':
                case 'ePharmacyClinical':
                    addMenuItemConditionally(element, this.$store.state.isUsepharmacyPatientNewCrop);  
                    break; 
                case 'DailyNotes':
                    addMenuItemConditionally(element, this.$store.state.isUseDailyNotes); 
                    break;
                case 'LogNotes':
                    addMenuItemConditionally(element, this.$store.state.isUseLogNotes);
                    break;
                case 'Transfer':
                    addMenuItemConditionally(element, this.$store.state.isUseTransfer);
                    break;   
                case 'Discharge':
                    addMenuItemConditionally(element, this.$store.state.isUseDischarge);
                    break;  
                case 'Diagnosis':
                    addMenuItemConditionally(element, this.$store.state.isUsePatientDiagnosis);
                    break;  
                case 'ClaimBatchList':
                case 'PatientInfoClaimBatch':    
                case 'DiagnosisWorksSheet':
                case 'Doctors':
                case 'Insurances':
                case 'incident-report':
                case 'incident-dashboard':
                case 'incident-report-general-info':
                case 'incident-categories':
                case 'incident-involved':
                case 'incident-participants':
                    addMenuItemConditionally(element);
                    break;                 
                case 'DiagnosisClinical':
                    addMenuItemConditionally(element, this.$store.state.isUsePatientDiagnosis); 
                    break; 
                case 'InsuranceClinical':
                    addMenuItemConditionally(element, this.$store.state.isUsePatientInsurance); 
                    break;
                case 'AllergyClinical':
                    addMenuItemConditionally(element, this.$store.state.isUsePatientAllergies); 
                    break;
                case 'ContactClinical':
                    addMenuItemConditionally(element, this.$store.state.isUsePatientContact); 
                    break;
                case 'DocumentClinical':
                    addMenuItemConditionally(element, this.$store.state.isUsePatientDocument); 
                    break; 
                case 'ADMISSIONINFOCLINICAL':
                    addMenuItemConditionally(element, this.$store.state.isUsePatientAdmission); 
                    break;             
                case 'WYTREATMENTPLAN':
                case 'MDTREATMENTPLAN':
                    addMenuItemConditionally(element, this.$store.state.isUseTreatmentPlanClinical); 
                    break;
                case 'SchedulerClinical':
                    addMenuItemConditionally(element, this.$store.state.isUseSchedulerClinical); 
                    break;
                case 'Appointments':
                case 'ClinicalAppointments':
                    addMenuItemConditionally(element, this.$store.state.isAppointments); 
                    break;  
                default:
                    break;
            }
      });
    },
    addMenuItem(text, value, href) {
        const item = { text, value, href };
        this.ItemsListAuthorized.push(item);
    }
},

created(){
    EventBus.$on("onSelectAppointmentReason", function (param) { 
		this.CancelAppt(param.Text,param.Reason);
    }.bind(this));
}, 

updated(){
    this.itemdisabled = this.$attrs.itemdisabled;
    this.PatientCode = this.$attrs.PatientCode;
},

beforeCreate(){
    this.itemdisabled = this.$attrs.itemdisabled;
    this.PatientCode = this.$attrs.PatientCode;
},

 mounted() {
    this.itemdisabled = this.$attrs.itemdisabled;
    this.PatientCode = this.$attrs.PatientCode;   
    this.ItemsList = this.$attrs.ItemList;   
     this.GetPermission();
 }
}
    

</script>

<style>
.isDisabled {
  cursor: not-allowed!important;
  opacity: 0.5!important;
}
.isDisabled > a {
  color: currentColor!important;
  display: inline-block!important;  /* For IE11/ MS Edge bug */
  pointer-events: none!important;
  text-decoration: none!important;
}
.rmActiveItem {
  background-position: -230px !important;
  color: #FFF !important;
}
</style>


