<template>
 <div id="ContainerPatientAsam">
  <Asam v-bind:PatientCode="this.$route.params.id" v-bind:RecordId="this.$route.params.recordid"/>
</div>

</template>
<script>
import Asam from "@/components/asam/Asam";
import DBFunctions from '@/common/DBFunctions.js'
import EnumService from '@/services/enum.service'
export default {

  /*beforeRouteEnter(to, from, next) {
        next(async vm => {
            const iUA = await vm.$store.state.isUseASAM;
            if(iUA) next()
            else next('/accessdenied');
        }) 
  },*/
  beforeRouteEnter(to, from, next) {
        next(async vm => {
            const iPI = await vm.CanView();
            if(iPI) next()
            else next('/accessdenied');
        }) 
  },

  components: { Asam },
  mixins: [DBFunctions],
  data() {
    return {
      permission:[],
      Menu : this.$store.state.leftMenu,
      OptionsRightMenu:{
			visible:true,
			ItemsList:[],
			btnvisible:true,
			itemdisabled: false,		
		}
    }
  },
 
methods:{
  CanView(){
      return this.permission[0].roleCanView;
  },
MenuVisible(){
    this.OptionsRightMenu.visible = true; 
    this.OptionsRightMenu.btnvisible = true;      
    this.OptionsRightMenu.ItemsList = EnumService.ClinicalItems;
    this.$emit('getVisibleMenu', this.OptionsRightMenu);
    this.$emit('getBtnRightMenu', this.OptionsRightMenu); 
}
},

created(){
  this.getPermissionLeftMenu(this.Menu,this.$route.path,this.permission);
},

  mounted() {
   this.MenuVisible();
  },
  
  updated(){
  this.MenuVisible();
  },
  destroyed(){

  }
}

</script>





