<template>
    <div>
        <div class="textlabel" :data-label="Label">
        <b-progress 
            height="24px" 
            :max="Max"
            :show-progress="ShowProgress"
            :show-value="ShowValue"
        >
            <b-progress-bar :value="value" :label="`${((value / Max) * 100).toFixed(0)}%`" striped animated></b-progress-bar>
        </b-progress>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'MdProgress',
        props: {
            value: Number,
            Max: Number,
            ShowProgress: Boolean,
            ShowValue: Boolean,
            Label: String,
            valueErr: Number
        },
        data() {
            return {
            }
        },
    }
</script>
