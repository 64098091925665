import ApiService from '@/services/api.service'

const FacilitiesService = {
    getAll( parameter) { 
      const requestData = {
        method: 'get',
        url: "/Facilities/",
        headers: {
        },
        params: { 
          SearchCriteria: parameter.SearchCriteria,
          PageSize: parameter.PageSize,          
          PageNumber : parameter.PageNumber        
        }
      }     
      return ApiService.customRequest(requestData);         
    },
  
   
}
  
  export default FacilitiesService;