import ApiService from '@/services/api.service'


const IncidentReportHeadersService = {
    getAll( parameter) { 
         
      const requestData = {
        method: 'get',
        url: "/IncidentReportHeaders/",
        headers: {
        },
        params: { 
          Version : parameter.Version,
          IncidentId : parameter.IncidentId,
         }
      }     
      return ApiService.customRequest(requestData);         
    },

    get(IncidentId,Version){     
      return ApiService.get(`/IncidentReportHeaders/${IncidentId}:${Version}`);
    },

    add(data){
      return ApiService.post(`/IncidentReportHeaders`, data);
    },

    getVersionNo(IncidentId){     
      return ApiService.get(`/IncidentReportHeaders/GetVersionNo/${IncidentId}`);
    },
  }
  
  export default IncidentReportHeadersService;