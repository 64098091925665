<template>
   <div id="ContainerUserTplDetails">
      <div class="row">
          <div class="col-12">
            <h4 class="page-title text-truncate"> {{$tc('userRoleDetails.rSetup',2)}}</h4>
            <div class="row">
                <div class="col-sm-12 col-md-3">                      
                    <MdButton
                      v-bind:Label="$t('userRoleDetails.Add')" 
                      v-bind:ClassProp="'btn btn-primary my-3 btn-block btn-login'"
                      v-bind:IconClass="'ti-plus pr-1'"
                      v-on:click="AddRole"
                      v-bind:Name="'btn_add_tpl'">
                    </MdButton>
                </div>
                <div class="col-sm-12 col-md-9 mb-4">
                    <div class="textlabel" :data-label="this.$t('Shared.SearchElements')">
                        <div class="input-group">
                            <input 
                              type="text" 
                              class="form-control"
                              v-model="parameter.SearchCriteria"   
                              @keyup.enter="SearchRole"                            
                            >
                            <div class="input-group-append">
                                <div class="input-group-append">
                                  <MdButton 
                                    v-bind:Name="'btnsearchinput'"
                                    v-bind:VariantProp="'btn btn-sm search-attached'"
                                    v-bind:IconClass="'mdi mdi-magnify'"
                                    v-on:click="SearchRole">
                                  </MdButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>              
        </div>
      </div>
      <div class="row">
        <div class="col-12 mt-2">
        <MdTitle v-bind:Text="$t('Shared.SearchResult')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
          <div class="row">
            <div class="col-md-12">
              <MdTable 
                v-bind:fields="fields"
                v-bind:items="items"
                v-bind:totalRows="totalRows"
                v-bind:isReadOnly="isReadOnly"
                v-bind:showMessageIsEmpty="ShowMessageIsEmpty"
                v-bind:currentPage="currentPage"
                v-bind:perPage="perPage"
                v-bind:pageOptions="pageOptions"
                :editIconEvent="EditRole"
                v-bind:VisibleEditIcon="true"
                :deleteIconEvent="RemoveRole"
                v-bind:VisibleDeleteIcon="true"
                :onRowSelected="onRowSelected"
                :onFiltered="onFiltered"
                :pageChanged="pageChanged"
                :perPageFunction="PerPageFunction">
              </MdTable>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import UserTplService from '@/services/users.tpl.service'
import ShowMessage from '@/components/messages/ShowMessage.js'
import DBFunctions from '@/common/DBFunctions.js'

export default {

 beforeRouteEnter(to, from, next) {
        next(async vm => {
            const iPI = await vm.CanView();
            if(iPI) next()
            else next('/accessdenied');
        }) 
  },

  mixins: [ShowMessage, DBFunctions],
  name: 'UserRole',
  data() {
    return {
      patient:{},
      code:"",
      ShowMessageIsEmpty:false,
      Loading:true,
      permission:[],
      Menu : this.$store.state.leftMenu,
      Mode:'',         
      parameter:{
        SearchCriteria:'',
        PageNumber : 0,
        PageSize:0 ,
        PatientCode:''             
      },
      fields: [
        { key: 'description', sortable: true, label: this.$t('userHeader.Role'), class: 'text-rigth' },       
        ],
      pageOptions: [5, 10, 15 ],
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      filter: null,
      filterOn: [],
      items: [],
      selected: [],
      isReadOnly: false
    }
  },

  computed: {
      sortOptions() {       
        return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          });
      }    
  },
    
  methods: {

    AddRole(){
      this.Mode = "Add";
      this.emitDataforFavorites();   
      this.$router.push({ name: "role-add", params: { id: this.$route.params.id } }).catch(()=>{});
    },
    CanView(){
      return this.permission[0].roleCanView;
  },

    EditRole(row){
       this.code = row.code;
       this.Mode = "Edit";
       this.$router.push({ name: "role-details", params: { id: this.code } });       
    },

    RemoveRole(row){
      const Id = row.code
      this.$bvModal.msgBoxConfirm(this.$t('Msg.DeleteRecord'), {
					title: this.$tc('Shared.DelRecord'),
					okTitle: this.$t('Shared.TitleDelete'),
					cancelTitle: this.$t('Shared.BtnCancel'),
					footerClass: 'p-2',
					titleClass: 'modal-title modal-type-warning',
          id:'modal_delete_tpl'
				})
          .then(value => {
            if(value){
                UserTplService.delete(Id)
                .then(() => {
                  const index = this.items.findIndex(item => item.code === Id) // find the post index 
                  if (~index) {// if the post exists in array 
                    this.items.splice(index, 1) //delete the post and refresh view
                    this.getTplListing(); //refresh listing
                  }
                })
                .catch((error) => {
                  if (!error.response) {
                    // network error
                    this.showMessage(this.$t('userHeader.Role'),this.$t('Msg.NotAbleToDelete'),"error");
                  } else {
                    this.showMessage(this.$t('userHeader.Role'),error,"error");
                  }
                });
            }
          })
    },

    getTplListing(){
      this.parameter.PageNumber = this.currentPage;           
      this.parameter.PageSize = this.perPage;
      this.$emit('load', true);       
      UserTplService.getAll(this.parameter)
        .then((response) => {
            if(response.status == '200' && response.data){
              this.items = response.data;                        
              this.$emit('load', false);
              let headerString = response.headers["x-pagination"];
              this.totalRows = JSON.parse(headerString).TotalCount;
            }
            this.$emit('load', false);                
        })
        .catch((error) => {
            this.$emit('load', false);
            if (error.response) {
                this.showMessage(this.$t('userHeader.Role'),error,"error");
            }
        });  
    },
    pageChanged (page) {            
      this.currentPage = page;
      this.getTplListing();
    },

    PerPageFunction(perpage){
      this.perPage = perpage;
      this.currentPage = 1;
      this.getTplListing();
    },

    SearchRole(){
      this.$emit('load', true);
      this.items = [];
      this.currentPage = 1;
      this.parameter.PageNumber = 1;
      this.totalRows = this.items.length;
      this.getTplListing();
    },
    onRowSelected(items) {    
     this.selected = items;
    },

    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
    },

    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },

    selectThirdRow() {
      this.$refs.selectableTable.selectRow(2)
    },

    unselectThirdRow() {
      this.$refs.selectableTable.unselectRow(2)
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    }
  },

  created(){
    this.getPermissionLeftMenu(this.Menu,this.$route.path,this.permission);  
  },

   mounted() {
    this.getTplListing();   
  },
};

</script>