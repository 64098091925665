<template>
<div>
    <div id="viewer" ref="viewer" style="position: absolute; left: 0; right: 0" data-bind="dxReportViewer: $data">
    </div>
</div>
</template>

<script>
import ko from "knockout";
import { ActionId, PreviewElements } from 'devexpress-reporting/dx-webdocumentviewer'
import 'devexpress-reporting/dx-webdocumentviewer'
import ApiService from '@/services/api.service'
import StorageService from '@/services/storage.service'
import { ajaxSetup } from "@devexpress/analytics-core/analytics-utils"
import { on } from 'devextreme/events';
import $ from "jquery";

export default {
name: "ReportViewer",
props: {
    ReportName: String,
    ReportTitle: String,
    IsAnAPIReport: Boolean,
    IsANotesReport: Boolean,
    HideSearch: Boolean
},
data() {
    return {
        previewModel: ko.observable(),
        paramsStr: '',
        ModalId: 'reportviewer',
        TitleWindow: this.ReportTitle,
    }
},
methods: {
    buildDocument(params) {
        this.buildParameters(params)
        this.previewModel().OpenReport(`${this.ReportName}${this.paramsStr}`);
        this.previewModel().reportPreview.zoom(1)
    },

    buildParameters(params) {
        let ixd = 0
        params.forEach(param => {
            if (ixd === 0) {
                this.paramsStr = `?${param.paramName}=${param.paramValue}` 
            }
            else 
            {
                this.paramsStr = `${this.paramsStr}&${param.paramName}=${param.paramValue}`
            }
            ixd++
        });
        if (this.IsAnAPIReport) {
            if (params != null || params != undefined) {
                this.paramsStr = `${this.paramsStr}&IsAnAPIReport=true`
            }
            else {
                this.paramsStr = `?IsAnAPIReport=true`
            }
        }
        if (this.IsANotesReport) {
            if (params != null || params != undefined) {
                this.paramsStr = `${this.paramsStr}&IsANotesReport=true`
            }
            else {
                this.paramsStr = `?IsANotesReport=true`
            }
        }
    },
},

created: function() {
    this.$parent.$on('buildDocument');
},

mounted() {    
    ajaxSetup.ajaxSettings = { headers: { 'Authorization': `Bearer ${StorageService.getOnlyToken()}` } };
    var root = this
    var viewerOptions = {
        requestOptions: {
            host: ApiService.APIURL,
            invokeAction: "/DocumentViewer/Invoke"
        },
        exportSettings: {
            showPrintNotificationDialog: false
        },
        isMobile: window.innerWidth <= 780 ? true: false,
        callbacks: {  
            DocumentReady: function(s) {
               s.GetReportPreview().zoom(1);
               const parent = document.getElementById("viewer");
               on(parent, "dxmousewheel", null, function (evt) {
                    evt.stopPropagation();
               });
            },
            BeforeRender(s, e) {
                e.reportPreview.showMultipagePreview(true);
            },
            CustomizeMenuActions: function(s, e) {
                if (root.HideSearch) {
                    let actionSearch = e.GetById(ActionId.Search);
                    if (actionSearch) actionSearch.visible = false;
                }

                let actionPrintPage = e.GetById(ActionId.PrintPage);
                    if (actionPrintPage) actionPrintPage.visible = false;
                
                let actionMultipageToggle = e.GetById(
                    ActionId.MultipageToggle
                );
                if (actionMultipageToggle) actionMultipageToggle.visible = false;
            },
            CustomizeParameterEditors: function(s,e) {
                if (e.parameter.type === 'System.DateTime') {
                    e.info.editor = $.extend({}, e.info.editor);
                    e.info.editor.extendedOptions = $.extend(e.info.editor.extendedOptions || {}, { type: 'date' });
                }
            },
            CustomizeElements: function(s,e) {
                if (root.HideSearch) {
                    var panelPart = e.GetById(PreviewElements.RightPanel);
                    var searchPnlIndex = 2;
                    panelPart.model.tabPanel.tabs.splice(searchPnlIndex, 1);
                }
            }
        },
        viewerModel: this.previewModel,
    };
    ko.applyBindings(viewerOptions, this.$refs.viewer);
},

beforeDestroy() {
    ko.cleanNode(this.$refs.viewer);
}
};
</script>
<style>
    .dxrd-preview.dxrd-designer-wrapper .dxrd-preview-wrapper .dxrd-report-preview-brick-selected {
        visibility: hidden;
    }
</style>