<template>
<div v-bind:style="style" class="cutescroll">
  <b-input-group>
    <b-form-checkbox-group 
        v-model="selected"
        :options="Options"
        :style="this.columnCountStyle"
        name="flavour-2a"
       
        stacked 
        @change="OnChange"
        :text-field="textField"
        :value-field="valueField"
      >
    
    </b-form-checkbox-group>

    <b-form-checkbox-group>
      <template v-slot="{ options }">
        <div v-for="option in options" :key="option.description">
          <slot :option="option">aaaaaaa</slot>
        </div>
      </template>
    </b-form-checkbox-group>

    </b-input-group>
  </div>
</template>

<script>
import { EventBus } from '@/event-bus.js';
export default {
  name: 'MdCheckListBox2',
   props: {
     isReadOnly:    Boolean,
     value:         Array,
     TransDate:     String,
     Options:       Array,
     Tag:           String,
     isRequired:    Boolean,
     isValid:       Boolean,
     textField:     String,
     valueField:    String,
     Height:        String,
     columnCount:   Number,
     OnChangeProp:Function
   },

  data(){
      return{
        ControlMax:1,
        selected: this.value,    
        style:{
            overflowY:'auto',
            overflowX: 'hidden',
            height: this.Height,
            padding: '5px 10px',
            border: 'solid 1px #e0e0e0 !important',
            borderColor: '#e0e0e0 !important',
            lineHeight: '1.55rem',
        },
        columnCountStyle:this.columnCount != null ? "column-count: "+this.columnCount+";" : "column-count: 1;"
      }
  },

  watch: {
    value: function (newValue) {
      this.selected = newValue ? newValue : [];
    },
    
    isValid: function (newValue) {
        this.setValid(newValue)
    }
  },

  methods:{
    OnChange(value){  
      this.$emit('change', value);    
      this.$emit('input', value);
      this.style.borderColor = value.length == 0 && this.isRequired ? '#dc3545 !important' : '#e0e0e0 !important';
      this.OnChangeProp(value); 
    },

    setValid(state){
      this.style.borderColor = state && this.isRequired ? '#e0e0e0 !important' : '#dc3545 !important' 
    },

    onInput(){
      var changed = {
        "Changed":true,
        "ComponentName":this.Label,
        "Tag": this.Tag
      }
      EventBus.$emit('onChanged', changed);
    }
  },

  mounted(){}
};
</script>


